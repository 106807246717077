import React, { Component } from 'react';
import { HorizontalLoaderProps } from './HorizontalLoaderModel';

import styles from './HorizontalLoader.module.scss';

class HorizontalLoader extends Component<HorizontalLoaderProps> {
    render() {
        return (
            <div className={styles.HorizontalLoaderContainer}>
                <div className={styles.firstDot} />
                <div className={styles.secondDot} />
                <div />
            </div>
        );
    }
}

export default HorizontalLoader;
