import { translatableFieldFactory } from '@services/FormUtils/FormFieldFactory';
import { SimpleFieldTypes } from '@services/FormUtils/FormFields';
import { ContentTranslationManager } from '@stores/contentTranslation/ContentTranslationManager';
import { MobXProviderContext } from 'mobx-react';
import React, { useContext, useState } from 'react';

export default function withFieldTranslation<T extends { fieldData: SimpleFieldTypes }>
    (WrappedComponent: React.ComponentType<T>) {

    function WithFieldTranslation(props: T) {
        function initializeContentTranslationManagerIfNeeded(translationManager: ContentTranslationManager) {
            return () => {
                if (translationManager) {
                    props.fieldData.setDisabled(true);
                    props.fieldData.setInTranslation(true);
                }
                if (translationManager && (props.fieldData as any)['translatable']) {
                    // tslint:disable-next-line: no-shadowed-variable
                    const fieldTranslationManager =
                        translationManager.getFieldTranslation(props.fieldData.pathString);
                    if (fieldTranslationManager) {
                        const { field, locale, parentId } = props.fieldData;
                        const fieldTranslation = fieldTranslationManager.getField();

                        const translationFieldData =
                            translatableFieldFactory(field, locale, fieldTranslation.value, parentId);
                        if (translationFieldData) {
                            // tslint:disable-next-line: no-shadowed-variable
                            const fieldTranslationFieldData = translationFieldData;
                            fieldTranslationFieldData.listenForSetValue(
                                (value: any) => fieldTranslationManager!.setValue(value));
                            return {
                                fieldTranslationManager,
                                fieldTranslationFieldData,
                                contentLocale: translationManager.contentLocale,
                                contentTranslationLocale: translationManager.contentTranslationLocale
                            };
                        } else {
                            console.error(
                                `Failed to create field for ${field.type} with path ${props.fieldData.pathString}`
                            );
                        }
                    }
                }
                return {
                    fieldTranslationManager: null,
                    fieldTranslationFieldData: null,
                    contentLocale: null,
                    contentTranslationLocale: null
                };
            };
        }
        const { contentTranslationManager } =
            useContext(MobXProviderContext) as { contentTranslationManager: ContentTranslationManager };
        const [{ fieldTranslationFieldData, fieldTranslationManager, contentLocale, contentTranslationLocale }] =
            useState(initializeContentTranslationManagerIfNeeded(contentTranslationManager));

        return (
            <WrappedComponent
                translationFieldData={fieldTranslationFieldData}
                fieldTranslationManager={fieldTranslationManager}
                contentLocale={contentLocale}
                contentTranslationLocale={contentTranslationLocale}
                {...props}
            />
        );
    }

    return WithFieldTranslation;
}
