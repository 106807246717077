import Button from '@components/Button';
import { LocaleItemOriginal } from '@components/ContentTranslationStatus/LocaleItem';
import Typography from '@components/Typography';
import { ContentResponse } from '@contentchef/contentchef-types';
import { languageByLocale } from '@services/languages';
import { ConfigProvider, Icon, List, Modal, Popconfirm, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';

interface Props extends InjectedIntlProps {
    locales: string[];
    content: ContentResponse;
    onChangeLocale(locale: string): Promise<boolean>;
}

const componentLabels = defineMessages({
    editLabel: {
        id: 'ContentLocale.editLabel',
        defaultMessage: 'edit'
    },
    changeLocaleTitle: {
        id: 'ContentLocale.changeLocaleTitle',
        defaultMessage: `Change content's locale from {locale} to:`
    },
    default: {
        id: 'ContentLocale.default',
        defaultMessage: 'Default locale'
    },
    warningLabel: {
        id: 'ContentLocale.warningLabel',
        defaultMessage: `Content's locale will be updated immediately. Do you wish to proceed?`
    },
    warningWithTrans: {
        id: 'ContentLocale.warningWithTrans',
        defaultMessage: `Content's locale will be updated immediately and any existing translations will be deleted.
        Do you wish to proceed?`
    },
    cancelLabel: {
        id: 'ContentLocale.cancelLabel',
        defaultMessage: 'Cancel'
    },
    confirmLabel: {
        id: 'ContentLocale.confirmLabel',
        defaultMessage: 'OK'
    },
    noAdditionLocalesAvailable: {
        id: 'ContentLocale.noAdditionLocalesAvailable',
        defaultMessage: 'No additional locales available. Please contact your space manager to add a new one.'
    }
});

function ContentLocale({ content, locales, intl, onChangeLocale }: Props) {
    const [visible, setVisible] = useState(false);
    const [selectedLocale, setSelectedLocale] = useState<string | null>(null);
    const [changeLocaleLoading, setChangeLocaleLoading] = useState(false);

    async function onConfirm() {
        if (!selectedLocale) {
            return;
        }
        setChangeLocaleLoading(true);
        const success = await onChangeLocale(selectedLocale);
        setChangeLocaleLoading(false);
        if (success) {
            toggleModal(false)();
            return;
        }
    }

    function toggleModal(value: boolean) {
        return () => {
            if (!value) {
                setSelectedLocale(null);
            }
            setVisible(value);
        };
    }

    function onChangeNewDefaultLocale(e: RadioChangeEvent) {
        const value = e.target.value;
        if (value) {
            setSelectedLocale(value);
        }
    }

    return (
        <React.Fragment>
            <ConfigProvider
                renderEmpty={() => (
                    <div>
                        <Icon type="info-circle" style={{ fontSize: 64 }} />
                        <Typography style={{ paddingTop: 10, textAlign: 'center' }}>
                            {intl.formatMessage(componentLabels.noAdditionLocalesAvailable)}
                        </Typography>
                    </div>
                )}
            >
                <LocaleItemOriginal
                    locale={content.locale}
                    onClickEdit={toggleModal(true)}
                />
                <Modal
                    title={intl.formatMessage(componentLabels.changeLocaleTitle, { locale: content.locale })}
                    centered={true}
                    visible={visible}
                    onCancel={toggleModal(false)}
                    destroyOnClose={true}
                    footer={[
                        <Button key="back" onClick={toggleModal(false)}>
                            {intl.formatMessage(componentLabels.cancelLabel)}
                        </Button>,
                        <Popconfirm
                            key="confirm"
                            onConfirm={onConfirm}
                            title={content.translations.length > 0 ?
                                intl.formatMessage(componentLabels.warningWithTrans) :
                                intl.formatMessage(componentLabels.warningLabel)
                            }
                            okText={intl.formatMessage(componentLabels.confirmLabel)}
                            cancelText={intl.formatMessage(componentLabels.cancelLabel)}
                            getPopupContainer={() => document.getElementById('content-locale-modal') || document.body}
                        >
                            <Button
                                key="submit"
                                type="primary"
                                disabled={selectedLocale === null}
                                loading={changeLocaleLoading}
                            >
                                {intl.formatMessage(componentLabels.confirmLabel)}
                            </Button>
                        </Popconfirm>
                    ]}
                    {...{ id: 'content-locale-modal' }}
                >
                    <List
                        itemLayout="horizontal"
                        dataSource={locales}
                        bordered={true}
                        renderItem={
                            (locale) => {
                                return (
                                    <List.Item
                                        style={locale === content?.locale ? {
                                            textDecoration: 'underline',
                                            fontWeight: 'bold'
                                        } : {}}
                                    >
                                        <div>
                                            <Radio
                                                checked={locale === selectedLocale}
                                                value={locale}
                                                onChange={onChangeNewDefaultLocale}
                                            >
                                                <Typography
                                                    variant="span"
                                                    gutter={false}
                                                    title={locale === content?.locale ?
                                                        intl.formatMessage(componentLabels.default) : undefined
                                                    }
                                                >
                                                    {languageByLocale.getLanguageNameByLocale(locale) ? (
                                                        `${languageByLocale
                                                            .getLanguageNameByLocale(locale)} (${locale})`
                                                    ) : `${locale}`}
                                                </Typography>
                                            </Radio>
                                        </div>
                                    </List.Item>
                                );
                            }
                        }
                    />
                </Modal>
            </ConfigProvider>
        </React.Fragment>
    );
}

export default injectIntl(observer(ContentLocale));
