import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DynamicContentFieldProps, DynamicContentFieldState } from './DynamicContentFieldModel';
import { ArrayField, FragmentField } from '../../';
import { injectIntl } from 'react-intl';
import { createDynamicFieldRules } from '../../../services/FormUtils/FormFieldRules';
import {
    FieldArray,
    FieldFragment,
    FieldOneFragmentOf,
    SimpleFieldTypes,
} from '../../../services/FormUtils/FormFields';
import {
    ArraySchemaType,
    Field,
    SchemaTypeIds
} from '@contentchef/contentchef-types';
import OneFragmentOfField from '../OneFragmentOfField/OneFragmentOfField';
import styles from './DynamicContentField.module.scss';
import { withForm } from '../../../hoc';
import FieldManager from '../FieldManager';

@observer
class DynamicContentField extends Component<DynamicContentFieldProps, DynamicContentFieldState> {

    createArrayField = (fieldMetaData: FieldArray<Field<ArraySchemaType>>) => {
        const { parentFieldId } = this.props;
        return (
            <ArrayField
                arrayFieldMeta={fieldMetaData}
                parentFieldId={parentFieldId}
            />
        );
    }

    createFragmentField = (fieldMetaData: FieldFragment) => {
        const { parentFieldId } = this.props;
        return (
            <FragmentField
                fragmentMetaData={fieldMetaData}
                parentFieldId={parentFieldId}
            />
        );
    }

    createOneFragmentOfField = (fieldMetaData: FieldOneFragmentOf) => {
        const { parentFieldId } = this.props;
        return (
            <OneFragmentOfField
                fieldMetaData={fieldMetaData}
                parentFieldId={parentFieldId}
            />
        );
    }

    createDynamicField = (fieldData: SimpleFieldTypes) => {
        const { parentFieldId, intl: { locale, formatMessage } } = this.props;

        const formItemId = parentFieldId ? `${parentFieldId}.${fieldData.id}` : fieldData.id;

        return (
            <FieldManager
                fieldData={fieldData}
                formItemId={formItemId}
                intlLocale={locale}
                formatMessage={formatMessage}
                formRules={createDynamicFieldRules(fieldData, formatMessage, locale)}
            />
        );
    }

    handleFields = () => {
        const { genericFieldMeta } = this.props;
        if (genericFieldMeta.extension) {
            return this.createDynamicField(genericFieldMeta as SimpleFieldTypes);
        }
        switch (genericFieldMeta.type) {
            case SchemaTypeIds.ARRAY: {
                return this.createArrayField(genericFieldMeta as FieldArray<Field<ArraySchemaType>>);
            }
            case SchemaTypeIds.FRAGMENT: {
                return this.createFragmentField(genericFieldMeta as FieldFragment);
            }
            case SchemaTypeIds.ONE_FRAGMENT_OF: {
                return this.createOneFragmentOfField(genericFieldMeta as FieldOneFragmentOf);
            }
            default:
                return this.createDynamicField(genericFieldMeta as SimpleFieldTypes);
        }
    }

    render() {
        return (
            <div className={styles.DynamicFieldContainer}>
                {this.handleFields()}
            </div>
        );
    }
}

export default withForm(injectIntl(DynamicContentField));
