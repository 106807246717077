import {
    AllSchemaTypes, ArrayAttributeViolation,
    AttributeViolation, ListItem,
    SchemaTypeIds,
    TranslatableSchemaTypes
} from '@contentchef/contentchef-types';
import { action, IObservableArray, observable, runInAction } from 'mobx';
import { getErrorByErrorCode } from '../../services/FormUtils/DefinitionFormErrorMessages';
import { SerializedArraySchema } from './FieldSerializer/fields/array';
import { SerializedSlugSchema } from './FieldSerializer/fields/slug';
import { SerializedField } from './FieldSerializer/types';

type Translatable = keyof Pick<SerializedField, 'hint' | 'labels' | 'placeholder'>;

const listOfValuesKey = 'listOfValues';
const itemsKey = 'items';
const translatableKey = 'translatable';

type SerializedTypeWithListOfValues = SerializedField & {
    constraints: {
        listOfValues: ListItem<string | number>[];
    };
};

type SerializedArrayWithListOfValues = SerializedArraySchema & {
    constraints: {
        items: {
            constraints: {
                listOfValues: ListItem<string | number>[];
            };
        };
    };
};
// SerializedArraySchema<SUBTYPE = AllSchemaTypes> = SerializedField<ArrayConstraints<SUBTYPE>, {}>;
type TranslatableSerializedField<T = TranslatableSchemaTypes> = SerializedField<T> & {
    translatable: boolean;
};

export type TabErrorsCount = {
    detailsTab: number
    validationTab: number
    uiCustomizationTab: number
};

export default class FieldEditorStore<C = any, S = any> {
    @observable
    public serializedField: SerializedField<C, S> | null = null;
    @observable
    validationErrors: IObservableArray<AttributeViolation | ArrayAttributeViolation> = observable.array([]);
    @observable tabErrorsCount: TabErrorsCount = {
        detailsTab: 0,
        validationTab: 0,
        uiCustomizationTab: 0
    };

    /*START TYPE GUARDS*/

    public hasListOfValuesKey(arg: SerializedField): arg is SerializedTypeWithListOfValues {
        return listOfValuesKey in arg.constraints;
    }

    public isArrayWithListOfValuesKey(arg: SerializedField): arg is SerializedArrayWithListOfValues {
        return listOfValuesKey in arg.constraints.items.constraints;
    }

    public isArrayField<T = AllSchemaTypes>(arg: SerializedField): arg is SerializedArraySchema<T> {
        return itemsKey in arg.constraints;
    }

    public isSlugField(arg: SerializedField): arg is SerializedSlugSchema {
        return arg.type === SchemaTypeIds.SLUG;
    }

    // tslint:disable-next-line:max-line-length
    public isAttributeViolation = (violation: AttributeViolation | ArrayAttributeViolation): violation is AttributeViolation => {
        return 'details' in violation;
    }

    // tslint:disable-next-line:max-line-length
    public isArrayAttributeViolation = (violation: AttributeViolation | ArrayAttributeViolation): violation is ArrayAttributeViolation => {
        return 'itemsDetails' in violation;
    }

    public isTranslatableField<T = AllSchemaTypes>(arg: SerializedField): arg is TranslatableSerializedField<T> {
        return translatableKey in arg;
    }

    /*END TYPE GUARDS*/

    @action.bound
    public setField(field: SerializedField) {
        this.cleanFieldValidationErrors();
        this.serializedField = field;
    }

    @action.bound
    public updateName(name: string) {
        runInAction(() => this.serializedField!.name = name);
    }

    @action.bound
    public updateEnableFacet = (value: boolean) => {
        if (this.isArrayField(this.serializedField!)) {
            (this.serializedField!.constraints.items as any)['enableFacet'] = value;
        } else {
            this.serializedField!['enableFacet'] = value;
        }
    }

    @action.bound
    public updateExtension(extension?: string) {
        runInAction(() => {
            this.serializedField!.extension = extension;
        });
    }

    @action.bound
    public updateTranslatableAttribute = (value: boolean) => {
        if (this.isArrayField(this.serializedField!)) {
            (this.serializedField.constraints.items as any)['translatable'] = value;
        } else {
            this.serializedField!['translatable'] = value;
        }
    }

    @action.bound
    public updateCompactAttribute = (value: boolean) => {
        this.serializedField!['compact'] = value;
    }

    @action.bound
    public removeExtension() {
        runInAction(() => {
            this.serializedField!.extension = undefined;
        });
    }

    @action.bound
    public createI18n(key: Translatable, culture: string) {
        if (!!this.serializedField!![key][culture]) {
            return;
        }

        this.serializedField![key][culture] = '';
    }

    @action.bound
    public removeI18n(key: Translatable, locale: string) {
        this.removeI18NError(key);
        delete this.serializedField![key][locale];
    }

    @action.bound
    public updateI18n(key: Translatable, culture: string, value: string) {
        this.serializedField![key][culture] = value;
        this.removeI18NError(key);
    }

    @action.bound
    removeI18NError(key: Translatable) {
        this.validationErrors.forEach((error, index) => {
            if (error.key === key) {
                this.validationErrors.splice(index, 1);
                if (key === 'labels') {
                    this.tabErrorsCount.detailsTab = 0;
                } else {
                    this.tabErrorsCount.uiCustomizationTab = 0;
                }
            }
        });
    }

    @action.bound
    public updateConstraint(constraintKey: keyof C, constraintValue: unknown) {
        runInAction(() =>
            (this.serializedField!.constraints as any)[constraintKey] = constraintValue
        );
    }

    @action.bound
    public updateArrayItemsConstraint<T extends AllSchemaTypes>(
        constraintKey: keyof T['constraints'], constraintValue: any
    ) {
        runInAction(() => {
            if (this.isArrayField(this.serializedField!)) {
                (this.serializedField.constraints.items.constraints as any)[constraintKey] = constraintValue;
            }
        });
    }

    @action.bound
    public createI18nListOfValueItem(value: string = '') {
        if (this.isArrayField(this.serializedField!) && this.isArrayWithListOfValuesKey(this.serializedField)) {
            this.serializedField.constraints.items.constraints.listOfValues.push({ labels: {}, code: value });
        }
        if (this.hasListOfValuesKey(this.serializedField!)) {
            this.serializedField.constraints.listOfValues.push({ labels: {}, code: value });
        }
    }

    @action.bound
    public createI18nItemForListOfValue(index: number, culture: string) {
        this.removeI18nListOfValueError();
        if (this.isArrayField(this.serializedField!) && this.isArrayWithListOfValuesKey(this.serializedField)) {
            this.serializedField.constraints.items.constraints.listOfValues[index].labels[culture] = '';
        }
        if (this.hasListOfValuesKey(this.serializedField!)) {
            this.serializedField.constraints.listOfValues[index].labels[culture] = '';
        }
    }

    @action.bound
    public removeI18nListOfValuesValue(listItemIndex: number) {
        this.removeI18nListOfValueError();
        if (this.isArrayField(this.serializedField!) && this.isArrayWithListOfValuesKey(this.serializedField)) {
            this.serializedField.constraints.items.constraints.listOfValues.splice(listItemIndex, 1);
        }
        if (this.hasListOfValuesKey(this.serializedField!)) {
            this.serializedField.constraints.listOfValues.splice(listItemIndex, 1);
        }
    }

    @action.bound
    public updateI18nListOfValuesValue(listItemIndex: number, value: string | number) {
        this.removeI18nListOfValueError();
        if (this.isArrayField(this.serializedField!) && this.isArrayWithListOfValuesKey(this.serializedField)) {
            this.serializedField.constraints.items.constraints.listOfValues[listItemIndex].code = value;
        }
        if (this.hasListOfValuesKey(this.serializedField!)) {
            this.serializedField.constraints.listOfValues[listItemIndex].code = value;
        }
    }

    @action.bound
    public updateI18nItemForListOfValue(index: number, culture: string, label: string) {
        this.removeI18nListOfValueError();
        if (this.isArrayField(this.serializedField!) && this.isArrayWithListOfValuesKey(this.serializedField)) {
            this.serializedField.constraints.items.constraints.listOfValues[index].labels[culture] = label;
        }
        if (this.hasListOfValuesKey(this.serializedField!)) {
            this.serializedField.constraints.listOfValues[index].labels[culture] = label;
        }
    }

    @action.bound
    removeI18nListOfValueError() {
        this.validationErrors.map((value, index) => {
            if (value.key.includes('constraints.listOfValues')) {
                this.validationErrors.splice(index, 1);
                this.tabErrorsCount.detailsTab = 0;
            }
            return value;
        });
    }

    @action.bound
    setFieldValidationErrors(violations: (AttributeViolation | ArrayAttributeViolation)[]) {
        this.validationErrors.replace(violations);
    }

    @action.bound
    cleanFieldValidationErrors() {
        this.validationErrors.clear();
        this.tabErrorsCount.detailsTab = 0;
        this.tabErrorsCount.uiCustomizationTab = 0;
        this.tabErrorsCount.validationTab = 0;
    }

    @action.bound
    updateErrorsCount(tabKey: keyof TabErrorsCount, count: number) {
        this.tabErrorsCount[tabKey] = this.tabErrorsCount[tabKey] + count;
    }

    retrieveErrorsByErrorCodes = (errorCodes: string[], attr?: string) => {
        return errorCodes.map(errorCode => getErrorByErrorCode(errorCode), { attr });
    }
}
