import React from 'react';
import classNames from './index.module.scss';

export interface TextEllipsisProps extends Partial<React.ComponentPropsWithRef<'div'>> { }

export function TextEllipsis({ className, ...props }: TextEllipsisProps) {
    return (
        <div
            className={
                [classNames.TextEllipsis, className || ''].join(' ')
            }
            {
            ...props
            }
        />
    );
}

export default TextEllipsis;
