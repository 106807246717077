import { SchemaTypeIds } from '@contentchef/contentchef-types';
import { DynamicFieldTypes } from '../FormFields';

export enum ConstraintsToMatch {
    listOfValues = 'listOfValues'
}

const generateFieldPlaceholder = (field: DynamicFieldTypes, userLocale: string): string | undefined => {
    return !!field.placeholder
        ? field.placeholder[userLocale] || field.placeholder[field.locale]
        : undefined;
};

function typeMatcher(field: DynamicFieldTypes, typeToMatch: SchemaTypeIds[]): boolean;
function typeMatcher(field: DynamicFieldTypes, typeToMatch: SchemaTypeIds): boolean;
function typeMatcher(field: DynamicFieldTypes, typeToMatch: SchemaTypeIds | SchemaTypeIds[]): boolean {
    if (Array.isArray(typeToMatch)) {
        return typeToMatch.includes(field.type);
    }
    return field.type === typeToMatch;
}

function typeAndConstraintMatcher(field: DynamicFieldTypes, typeToMatch: SchemaTypeIds, constraint: string): boolean {
    return field.type === typeToMatch && !!field.constraints[constraint as keyof DynamicFieldTypes['constraints']];
}

export const fieldMatchersUtils = {
    generateFieldPlaceholder,
    typeMatcher,
    typeAndConstraintMatcher
};
