import React, { Component, CSSProperties } from 'react';
import { FloatingActionsProps, FloatingActionsState } from './FloatingActionsModel';
import styles from './index.module.scss';

class FloatingActions extends Component<FloatingActionsProps, FloatingActionsState> {

    render() {
        const {
            children,
            verticalPosition = 'bottom',
            verticalDistance = 20,
            horizontalPosition = 'right',
            horizontalDistance = 40
        } = this.props;
        const componentStyle: CSSProperties = {
            [verticalPosition]: `${verticalDistance}px`,
            [horizontalPosition]: `${horizontalDistance}px`
        };
        return (
            <div className={styles.Container} style={componentStyle}>
                {children}
            </div>
        );
    }
}

export default FloatingActions;
