import React, { Component } from 'react';

import styles from './RoundAvatar.module.scss';
import { nameToInitials } from '../../services/utils';
import { RoundAvatarProps } from './RoundAvatarModel';

class RoundAvatar extends Component<RoundAvatarProps> {
    render() {
        const {
            name,
            style = {},
            size,
            fullName = false
        } = this.props;

        const dynamicAvatarStyle = {
            ...style,
            width: size ? size : 'inherit',
            height: size ? size : 'inherit',
            '::after': fullName ? {
                content: name,
                display: 'inline-block',
                width: 25,
                height: 25
            } : {}
        };
        const dynamicInitialStyle = {
            fontSize: !!size ? size / 2 : 'inherit'
        };

        return (
            <div
                className={styles.avatarWrapper}
                style={dynamicAvatarStyle}
            >
                <span
                    className={styles.initialWrapper}
                    style={dynamicInitialStyle}
                >
                    {nameToInitials(name)}
                </span>
            </div>
        );
    }
}

export default RoundAvatar;
