// import { WithStoresComponentProps } from '../../constants/common-types';
import { inject, observer, Provider } from 'mobx-react';
import React from 'react';
import OnboardingStore, { OnboardingStoreModel } from '../../stores/onboardingStore';
import { WithStoresComponentProps } from '../../constants/common-types';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';
import UsersStore from '../../stores/usersStore';

type WithOnboardingInjectedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
    usersStore: UsersStore;
};

export default function withOnboarding<TProps>(
    WrappedComponent: React.ComponentType<TProps>
): React.ComponentType<TProps> {
    @inject('apiStore', 'usersStore')
    @observer
    class WithOnboarding extends React.Component<TProps> {
        get injected() {
            return this.props as WithOnboardingInjectedProps<TProps>;
        }

        protected onboardingStore: OnboardingStoreModel;

        public constructor(props: TProps) {
            super(props);

            const { apiStore: { api }, usersStore } = this.injected;

            this.onboardingStore = new OnboardingStore(api, usersStore);
        }

        public render() {
            return (
                <Provider onboardingStore={this.onboardingStore}>
                    <WrappedComponent {... this.props} />
                </Provider>
            );
        }
    }

    return WithOnboarding;
}
