import {
    MediaGallery as MediaGalleryType
} from '@contentchef/contentchef-types';
import React, { Component } from 'react';
import { List, ConfigProvider, Icon } from 'antd';
import { observer } from 'mobx-react';
import { MediaGalleryListItem } from '..';
import { MediaGalleryListProps, InjectedProps } from './MediaGalleryListModel';
import CustomEmpty from '@components/CustomEmpty/CustomEmpty';
import Typography from '@components/Typography';
import { FormattedMessage } from 'react-intl';

import styles from './MediaGalleryList.module.scss';

@observer
class MediaGalleryList extends Component<MediaGalleryListProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    render() {
        const { overrideClick } = this.props;
        return (
            <React.Fragment>
                <div className={styles.MediaGalleryListContainer}>
                    <ConfigProvider
                        getPopupContainer={() => document.body}
                        renderEmpty={() => (<CustomEmpty
                            image={<Icon type="picture" className={styles.MediaGalleryListEmptyIcon} />}
                            description={<Typography
                                variant="label"
                                className={styles.MediaGalleryListEmptyDescription}
                            >
                                <FormattedMessage
                                    id="MediaGalleryList.EmptyList.Description"
                                    defaultMessage="Media Galleries not found"
                                />
                            </Typography>}
                        />)}
                    >
                        <List
                            loading={this.props.isLoading}
                            dataSource={this.props.listMediaGalleries}
                            renderItem={(mediaGallery: MediaGalleryType) => (
                                <MediaGalleryListItem
                                    mediaGallery={mediaGallery}
                                    overrideClick={overrideClick}
                                />
                            )}
                        />
                    </ConfigProvider>
                </div>
            </React.Fragment>
        );
    }
}

export default MediaGalleryList;
