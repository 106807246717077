export const VALID_RESPONSE_STATUS: string = 'OK';
export const REPOSITORY = {
    DEFAULT_PAGE: 1,
    LIST: {
        DEFAULT_TAKE: 100,
        DEFAULT_SKIP: 0
    }
};

export const PUBLISHING_REQUESTS = {
    POLLING_INTERVAL: 60000
};
