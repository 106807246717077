export interface DataAttributesModel {
    [key: string]: string;
}

export class DataAttributes {
    dataAttributes: DataAttributesModel;

    constructor(builder: DataAttributesBuilder) {
        this.dataAttributes = builder.dataAttributes;
    }

    get Attributes() {
        return this.dataAttributes;
    }
}

export class DataAttributesBuilder {
    dataAttributePrefix: string;
    dataAttributes: DataAttributesModel = {};

    constructor(dataAttributePrefix: string) {
        this.dataAttributePrefix = `data-${dataAttributePrefix}`;
    }

    setNameAttribute(name: string) {
        this.dataAttributes[`${this.dataAttributePrefix}-name`] = name;
        return this;
    }

    setIdAttribute(id: string) {
        this.dataAttributes[`${this.dataAttributePrefix}-id`] = id;
        return this;
    }

    setSearchAttribute(search: string) {
        this.dataAttributes[`${this.dataAttributePrefix}-search`] = search;
        return this;
    }

    setAddFormAttribute(addFormName: string) {
        this.dataAttributes[`${this.dataAttributePrefix}-addform`] = addFormName;
        return this;
    }

    setEditFormAttribute(editFormName: string) {
        this.dataAttributes[`${this.dataAttributePrefix}-editform`] = editFormName;
        return this;
    }

    setListAttribute(listType: string) {
        this.dataAttributes[`${this.dataAttributePrefix}-list`] = listType;
        return this;
    }

    build(): DataAttributes {
        return new DataAttributes(this);
    }
}
