import React from 'react';
import { Col, Row, Form } from 'antd';
import DateField from '../Fields/Date';
import { FormattedMessage } from 'react-intl';
import { isFunction } from '../../../../services/utils';
import Segment from '../../../Segment/Segment';
import
withDefinitionErrors,
{ WithDefinitionErrorsReturnProps }
    from '../../../../hoc/withDefinitionErrors/withDefinitionErrors';
import { InjectedIntlProps, injectIntl } from 'react-intl';

export type DateRangeKey = 'from' | 'to';

export interface DateRangeProps extends WithDefinitionErrorsReturnProps, InjectedIntlProps {
    dateFrom?: Date;
    dateTo?: Date;
    onChange?(key: DateRangeKey, date: Date): any;
}

function createHandler<T extends (arg: DateRangeKey, value: Date) => any>(key: DateRangeKey, maybeFn?: T) {
    return (value: Date) => {
        if (isFunction(maybeFn)) {
            maybeFn(key as any, value);
        }
    };
}

function DateRange(props: DateRangeProps) {
    const {
        dateFrom,
        dateTo,
        onChange,
    } = props;
    return (
        <Segment
            divider={true}
            titleVariant="label"
            title={<FormattedMessage
                defaultMessage="Permitted date range"
                id="ContentDefinitionFieldEditing.Constraint.DateRange.Title"
            />}
        >
            <Form.Item
                help={props.hasErrors ? props.errors.map(e => props.intl.formatMessage(e)).join(', ') : undefined}
                validateStatus={props.hasErrors ? 'error' : undefined}
                style={{ width: '100%' }}
            >
                <Row gutter={12}>
                    <Col xs={24} md={12}>
                        <DateField onChange={createHandler('from', onChange)} value={dateFrom}>
                            <FormattedMessage
                                defaultMessage="From"
                                id="ContentDefinitionFieldEditing.Constraint.DateRange.from"
                            />
                        </DateField>
                    </Col>
                    <Col xs={24} md={12}>
                        <DateField onChange={createHandler('to', onChange)} value={dateTo}>
                            <FormattedMessage
                                defaultMessage="To"
                                id="ContentDefinitionFieldEditing.Constraint.DateRange.to"
                            />
                        </DateField>
                    </Col>
                </Row>
            </Form.Item>
        </Segment>
    );
}

export default withDefinitionErrors(injectIntl(DateRange));
