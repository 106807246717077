import { UserProfileIds } from '@contentchef/contentchef-types';
import { TreeSelect } from 'antd';
import React from 'react';
import UserRole from '../UserRole';

export interface UserProfileTreeSelectProps {
    required?: boolean;
    value?: UserProfileIds[];
    onChange(roles: UserProfileIds[]): any;
}

function createSelectableUserRoles(value: UserProfileIds) {
    const title = UserRole({ role: value });

    return {
        title,
        value,
    };
}

const selectableUserRoles = [
    createSelectableUserRoles(UserProfileIds.CONTENT_DESIGNER),
    createSelectableUserRoles(UserProfileIds.CONTENT_EDITOR),
    createSelectableUserRoles(UserProfileIds.LIVE_PUBLISHING_MANAGER),
    createSelectableUserRoles(UserProfileIds.REPOSITORY_MANAGER),
    createSelectableUserRoles(UserProfileIds.SPACE_BILLING_MANAGER),
    createSelectableUserRoles(UserProfileIds.SPACE_MANAGER),
    createSelectableUserRoles(UserProfileIds.SPACE_PROFILES_MANAGER),
];

function UserProfileTreeSelect(props: UserProfileTreeSelectProps) {
    return (
        <TreeSelect
            onChange={props.onChange}
            treeCheckable={true}
            treeData={selectableUserRoles}
            value={props.value}
        />
    );
}

export default UserProfileTreeSelect;
