import { withDisabledPropertyInContentForm } from '@hoc/withDisabledPropertyInContentForm';
import { withPermissionsToInteract } from '@providers/UserPermissionsProvider';
import { Select } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './FieldRepository.module.scss';
import { FieldRepositoryProps, FieldRepositoryState, InjectedProps } from './FieldRepositoryModel';

const Option = Select.Option;

@inject('repositoryListStore')
@observer
class FieldRepository extends Component<FieldRepositoryProps, FieldRepositoryState> {

    get injected() {
        return this.props as InjectedProps;
    }

    onChangeSelectedRepository = (value: number) => {
        this.props.repositoryField.setFieldValue(value);
        this.injected.onChange(value);
    }

    render() {
        const { repositoryField, hasPermissions, archived, ...others } = this.props;
        const { pagedRepositoryList } = this.injected.repositoryListStore;
        const disabled = archived || !hasPermissions;
        return (
            <Select
                {...others}
                disabled={disabled}
                allowClear={true}
                value={repositoryField.value}
                placeholder={<FormattedMessage
                    id="FieldRepository.placeholder"
                    defaultMessage="Select a repository"
                />}
                onChange={this.onChangeSelectedRepository}
            >
                {pagedRepositoryList.items.map((repository) => (
                    <Option key={repository.id.toString()} value={repository.id}>
                        <div className={styles.FieldRepositoryOptionContainer}>
                            <div
                                className={styles.FieldRepositoryOptionIcon}
                                style={{ backgroundColor: repository.color }}
                            />
                            <span className={styles.FieldRepositoryOptionLabel}>{repository.name}</span>
                        </div>
                    </Option>
                ))}
            </Select>
        );
    }
}

export default withDisabledPropertyInContentForm(withPermissionsToInteract(FieldRepository));
