import AsideHeader from '@components/AsideHeader/AsideHeader';
import { labels } from '@constants/content-translation-labels';
import { ContentTranslation, ContentTranslationStatus } from '@contentchef/contentchef-types';
import { Icon } from 'antd';
import React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import Button from '../Button';

function TranslationAsideHeader(props: {
    translations: ContentTranslation[],
    intl: InjectedIntl,
    onClickInfo: () => any,
    onToggleMode: () => any, transMode: boolean
}) {
    const { intl: { formatMessage }, onClickInfo, translations, transMode, onToggleMode } = props;

    function hasNotMissingTranslations(items: ContentTranslation[]) {
        return items.some(translation => translation.status !== ContentTranslationStatus.MISSING);
    }
    return (
        <AsideHeader
            label={formatMessage(labels.formSidebarHeader)}
            icon={
                <Icon
                    type="info-circle"
                    theme="filled"
                    onClick={onClickInfo}
                />
            }
            actions={
                hasNotMissingTranslations(translations) ? [
                    <Button
                        key="clean-trans-mode"
                        type={
                            transMode ? 'default' : 'primary'
                        }
                        onClick={onToggleMode}
                        style={{ textTransform: 'uppercase' }}
                    >
                        {formatMessage(labels.cleanLabel)}
                    </Button>
                ] : []}
        />
    );
}

export default injectIntl(TranslationAsideHeader);
