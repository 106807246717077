import {
    AllSchemaTypes,
    ArraySchemaType,
    Field,
    SchemaTypeIds
} from '@contentchef/contentchef-types';

export function listOfItemsSnippet(
    defaultLanguage: string, name: string, items: AllSchemaTypes
): Field<ArraySchemaType> {
    return {
        name,
        type: SchemaTypeIds.ARRAY,
        labels: {
            [defaultLanguage]: name,
        },
        placeholder: undefined,
        hint: undefined,
        constraints: {
            required: false,
            minLength: undefined,
            maxLength: undefined,
            items
        },
        compact: undefined
    };
}
