import React from 'react';
import { DrawerItemProps } from './DrawerItemModel';

import styles from './DrawerItem.module.scss';

const DrawerItem = (props: DrawerItemProps) => {
    const { title, titleIcon, children } = props;
    return (
        <div className={styles.DrawerItemContainer}>
            <p
                className={styles.DrawerItemTitle}
            >
                {title}
                {titleIcon}
            </p>
            <div className={styles.DrawerItemContent}>
                {children}
            </div>
        </div>
    );
};

export default DrawerItem;
