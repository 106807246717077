import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { NavStoreModel, NAVIGATION_PRIORITY } from '../../../stores/navStore/navStoreModel';
import { defineMessages, injectIntl, InjectedIntlProps } from 'react-intl';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { NavigationItem } from '../../../stores/navStore/navStore';
import {
    createContentDefinitionPath,
    NEW_CONTENT_DEFINITION,
    NEW_CONTENT_DEFINITION_CUSTOM_FIELD_EDIT,
    NEW_CONTENT_DEFINITION_CUSTOM_FIELD_LIST,
    NEW_CONTENT_DEFINITION_CUSTOM_FIELD_NEW
} from '../../../constants/routing-constants';
import { NAVIGATION_LABELS } from '../../../constants/navigation-internationalized-labels';
import ContentDefinitionCustomFieldList from '../ContentDefinitionCustomField/List';
import ContentDefinitionCustomFieldEdit from '../ContentDefinitionCustomField/Edit';
import CreateContentDefinition from '../CreateContentDefinition/CreateContentDefinition';
import { withContentDefinitionFormStores } from '../../../hoc';
import {
    ContentDefinitionSchemaStoreModel
} from '../../../stores/ContentDefinitionEditorStore/ContentDefinitionSchemaStore';
import { NAVIGATION_ITEM_IDENTIFIERS } from '../../../constants/navigation-item-identifier';
import { MissingPermissions } from '../../../components';
import {
    withPermissionsToInteract,
    WithPermissionsToInteractReturnType
} from '../../../providers/UserPermissionsProvider';
import {
    ContentDefinitionFormStoreModel
} from '../../../stores/contentDefinitionFormStore/contentDefinitionFormStoreModel';

interface CreateContentDefinitionRouteProps extends RouteComponentProps<{ spaceId: string }>, InjectedIntlProps,
    WithPermissionsToInteractReturnType { }

interface InjectedProps extends CreateContentDefinitionRouteProps {
    navStore: NavStoreModel;
    contentDefinitionSchemaStore: ContentDefinitionSchemaStoreModel;
    contentDefinitionFormStore: ContentDefinitionFormStoreModel;
}

const createDefinitionLabels = defineMessages({
    changeRouteText: {
        id: 'CreateContentDefinitionRouter.onChangeRoute.Text',
        defaultMessage: 'Changing page you will lose your unsaved changes. Continue?'
    }
});

@inject('navStore', 'contentDefinitionSchemaStore', 'contentDefinitionFormStore')
@observer
class CreateContentDefinitionRouter extends Component<CreateContentDefinitionRouteProps> {
    unregisterBlockRouteChange = this.props.history.block((location, action) => {
        if (this.shouldAskBeforeToChangeRoute(location.pathname)) {
            return this.props.intl.formatMessage(createDefinitionLabels.changeRouteText);
        } else {
            return;
        }
    });

    get injected() { return this.props as InjectedProps; }

    shouldAskBeforeToChangeRoute = (newPathName: string) => {
        const { spaceId } = this.props.match.params;
        if (this.injected.contentDefinitionFormStore.isContentDefinitionSavedSuccessfully) {
            return false;
        }
        const isCreateDefinitionPaths = newPathName.includes(createContentDefinitionPath(spaceId));
        return !isCreateDefinitionPaths && this.injected.contentDefinitionSchemaStore.isDefinitionSchemaModified();
    }

    componentDidMount(): void {
        this.injected.contentDefinitionSchemaStore.initStoreSchemas({
            lang: this.props.intl.locale, fragments: {}, fields: []
        });
        const {
            intl: { formatMessage },
            match: { params: { spaceId } }
        } = this.props;
        this.injected.navStore.pushToPath(
            new NavigationItem(
                NAVIGATION_ITEM_IDENTIFIERS.NEW_DEFINITION,
                createContentDefinitionPath(spaceId),
                formatMessage(NAVIGATION_LABELS.newContentDefinition),
                NAVIGATION_PRIORITY.SECOND
            ));
    }

    componentWillUnmount() {
        this.unregisterBlockRouteChange();
        this.injected.navStore.removeItem(NAVIGATION_ITEM_IDENTIFIERS.NEW_DEFINITION);
    }

    render() {

        if (!this.props.hasPermissions) {
            return <MissingPermissions />;
        }
        return (
            <Switch>
                <Route
                    exact={true}
                    path={NEW_CONTENT_DEFINITION_CUSTOM_FIELD_LIST}
                    component={ContentDefinitionCustomFieldList}
                />
                <Route
                    exact={true}
                    path={NEW_CONTENT_DEFINITION_CUSTOM_FIELD_NEW}
                    component={ContentDefinitionCustomFieldEdit}
                />
                <Route
                    exact={true}
                    path={NEW_CONTENT_DEFINITION_CUSTOM_FIELD_EDIT}
                    component={ContentDefinitionCustomFieldEdit}
                />
                <Route
                    exact={true}
                    path={NEW_CONTENT_DEFINITION}
                    component={CreateContentDefinition}
                />
            </Switch>
        );
    }
}

export default withPermissionsToInteract(withRouter(withContentDefinitionFormStores(
    injectIntl(CreateContentDefinitionRouter)
)));
