import React from 'react';
import { inject, observer, Provider } from 'mobx-react';
import { InviteStoreModel, InviteStore } from '../../stores/inviteStore';
import { WithStoresComponentProps } from '../../constants/common-types';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';

type InjectedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
};

export default function withInvites<T>(Component: React.ComponentType<T>): React.ComponentType<T> {
    @inject('apiStore')
    @observer
    class WithInvites extends React.Component<T> {
        protected inviteStore: InviteStoreModel;

        public constructor(props: T) {
            super(props);

            const {
                apiStore,
            } = this.props as InjectedProps<T>;

            this.inviteStore = new InviteStore(apiStore.api);
        }

        public render() {
            return (
                <Provider inviteStore={this.inviteStore}>
                    <Component {... this.props} />
                </Provider>
            );
        }
    }

    return WithInvites;
}
