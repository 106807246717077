import { action } from 'mobx';
import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import {
    MediaGallery,
    UpdateMediaGalleryRequest
} from '@contentchef/contentchef-types';
import { UpdateMediaGalleryStoreModel } from './updateMediaGalleryStoreModel';
import { MediaGalleryFormDataModel } from '../../components/Forms/MediaGalleryForm/MediaGalleryFormModel';

class UpdateMediaGalleryStore implements UpdateMediaGalleryStoreModel {
    api: ContentChefClient;

    constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action
    async update(mediaGalleryData: MediaGallery, formData: MediaGalleryFormDataModel) {
        const mediaGalleryUpdateData: UpdateMediaGalleryRequest = {
            id: mediaGalleryData.id,
            rootFolderName: mediaGalleryData.rootFolderName,
            name: formData.name,
            description: formData.description || '',
            color: formData.color
        };
        try {
            await this.api.mediaGallery.update(mediaGalleryUpdateData);
        } catch (e) {
            console.log('Error while updating the mediaGallery', e);
        }
    }
}

export default UpdateMediaGalleryStore;
