import React, { Component } from 'react';
import { PublishRouterProps, InjectedProps } from './PublishRouterModel';
import { Route, Switch } from 'react-router';
import Publish from '../Publish/Publish';
import { PUBLISH_ROUTE, publishToLiveBasePath } from '../../../constants/routing-constants';
import { inject } from 'mobx-react';
import { NavigationItem } from '../../../stores/navStore/navStore';
import { injectIntl } from 'react-intl';
import { NAVIGATION_LABELS } from '../../../constants/navigation-internationalized-labels';
import { NAVIGATION_ITEM_IDENTIFIERS } from '../../../constants/navigation-item-identifier';
import { NAVIGATION_PRIORITY } from '../../../stores/navStore/navStoreModel';

@inject('navStore')
class PublishRouter extends Component<PublishRouterProps> {
    get injected() {
        return this.props as InjectedProps;
    }
    componentDidMount() {
        const { formatMessage } = this.props.intl;
        const { match: { params: { spaceId } } } = this.props;
        this.injected.navStore.setNavigationPath(
            new NavigationItem(
                NAVIGATION_ITEM_IDENTIFIERS.PUBLISH_TO_LIVE,
                publishToLiveBasePath(spaceId),
                formatMessage(NAVIGATION_LABELS.publish),
                NAVIGATION_PRIORITY.FIRST
            )
        );
    }

    componentWillUnmount() {
        this.injected.navStore.removeItem(NAVIGATION_ITEM_IDENTIFIERS.PUBLISH_TO_LIVE);
    }

    render() {
        return (
            <Switch>
                <Route path={PUBLISH_ROUTE} component={Publish} />
            </Switch>
        );
    }
}

export default injectIntl(PublishRouter);
