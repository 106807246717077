import { ArchivedContentContext, ArchivedContentProviderData } from '@providers/ArchivedContentProvider';
import { ContentTranslationManager } from '@stores/contentTranslation/ContentTranslationManager';
import { MobXProviderContext } from 'mobx-react';
import React, { useContext, useState } from 'react';

export type ReturnType = {
    archived?: boolean;
};

export type Props<T> = Pick<T, Exclude<keyof T, keyof ArchivedContentProviderData>>;

export const withDisabledPropertyInContentForm =
    <T extends ReturnType>
        (WrappedComponent: React.ComponentType<T>): React.ComponentType<Props<T>> => {
        return function WithDisabledPropertyInContentForm(props: Props<T>) {
            const archivedContext = useContext(ArchivedContentContext);
            const { contentTranslationManager } =
                useContext(MobXProviderContext) as { contentTranslationManager: ContentTranslationManager };
            const [disabled] = useState(() => {
                return (archivedContext && archivedContext.archived) || !!contentTranslationManager;
            });
            return (
                <WrappedComponent
                    archived={disabled}
                    {...props as T}
                />
            );
        };
    };
