import React from 'react';
import classes from './index.module.scss';

export interface OnboardingTitleProps {
    children?: React.ReactNode;
}

export default function OnboardingTitle(props: OnboardingTitleProps) {
    return (
        <h1 className={classes.OnboardingTitle}>
            {
                props.children
            }
        </h1>
    );
}
