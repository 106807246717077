import React, { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Avatar, ConfigProvider, Icon, List } from 'antd';
import Button from '@components/Button';
import { defineMessages, FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { WebhooksStoreModel } from '@stores/webhooksStore/WebhooksStore';
import { Webhook } from '@contentchef/contentchef-types';
import Typography from '@components/Typography';
import CustomEmpty from '@components/CustomEmpty/CustomEmpty';
import { WebhookSvg } from '@assets/custom_icons/components/webhook';
import { getAvatarStyle } from '@services/utils';
import { FormComponentProps } from 'antd/lib/form';
import { NotificationStoreModel } from '@stores/notificationStore/notificationStoreModel';
import { Link } from 'react-router-dom';
import { viewWebhookPath, createWebhooksListPath, createWebhookPath } from '@constants/routing-constants';
import { NavStoreModel, NAVIGATION_PRIORITY } from '@stores/navStore/navStoreModel';
import { NAVIGATION_ITEM_IDENTIFIERS } from '@constants/navigation-item-identifier';
import { NAVIGATION_LABELS } from '@constants/navigation-internationalized-labels';
import { NavigationItem } from '@stores/navStore/navStore';
import FloatingActions from '@components/FloatingActions/FloatingActions';
import AddWebookButton from './AddWebookButton';

interface WebhookListProps extends RouteComponentProps<{ spaceId: string }>, InjectedIntlProps, FormComponentProps { }

interface DecoratedProps extends WebhookListProps {
    webhooksStore: WebhooksStoreModel;
    notificationStore: NotificationStoreModel;
    navStore: NavStoreModel;
}

const labels = defineMessages({
    title: {
        id: 'WebhookList.labels.signingKey',
        defaultMessage: 'Click here to download public key for webhook validation'
    }
});

@inject('webhooksStore', 'navStore')
@observer
class WebhookList extends Component<WebhookListProps> {

    get decoratedProps() { return this.props as DecoratedProps; }

    async componentDidMount() {
        const { setList } = this.decoratedProps.webhooksStore;
        const { navStore, match: { params: { spaceId } } } = this.decoratedProps;

        navStore.setNavigationPath(new NavigationItem(
            NAVIGATION_ITEM_IDENTIFIERS.WEBHOOKS,
            createWebhooksListPath(spaceId),
            this.props.intl.formatMessage(NAVIGATION_LABELS.webhooks),
            NAVIGATION_PRIORITY.FIRST
        ));

        await setList(spaceId, {});
    }

    fetchAndDownloadSigningKey = async () => {
        await this.decoratedProps.webhooksStore.downloadSigningKey();
    }

    render() {
        const {
            match: { params: { spaceId } },
            webhooksStore: { loading, webhookList }
        } = this.decoratedProps;

        return (
            <div style={{ padding: '0 1em 1em 1em' }}>
                <ConfigProvider
                    renderEmpty={this.renderCustomizedEmpty}
                >
                    <List
                        loading={loading.list}
                        header={
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <a
                                    className="ant-dropdown-link"
                                    onClick={(e) => { e.preventDefault(); return this.fetchAndDownloadSigningKey(); }}
                                >
                                    {this.props.intl.formatMessage(labels.title)}
                                </a>
                            </div>}
                        dataSource={webhookList}
                        renderItem={(webhook: Webhook) => (
                            <List.Item
                                actions={[
                                    <Link
                                        key="edit"
                                        to={viewWebhookPath(spaceId, webhook.id)}
                                    >
                                        <Button
                                            icon="edit"
                                            type="primary"
                                        />
                                    </Link>
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Avatar
                                            children={<Icon component={WebhookSvg} />}
                                            style={getAvatarStyle(webhook.id)}
                                        />
                                    }
                                    title={
                                        <Fragment>
                                            <Typography gutter={false} variant="h6" component={'span'}>
                                                {webhook.name}
                                            </Typography>
                                        </Fragment>
                                    }
                                    description={
                                        <Typography variant="small">
                                            {webhook.webhookType}
                                        </Typography>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </ConfigProvider>
                <FloatingActions>
                    <AddWebookButton spaceId={spaceId} />
                </FloatingActions>
            </div>
        );
    }

    renderCustomizedEmpty = () => {
        return (
            <CustomEmpty
                description={<Typography variant="label" >
                    <FormattedMessage
                        id="SettingsWebhook.WebhookList.EmptyList.Description"
                        defaultMessage="Webhooks not found for this space"
                    />
                </Typography>}
                addButton={this.renderWebhookAddButton()}
            />
        );
    }

    renderWebhookAddButton = () => {
        const {
            match,
        } = this.decoratedProps;
        const { spaceId } = match.params;

        return (
            <Link to={createWebhookPath(spaceId)}>
                <Button type="primary" icon="plus" >
                    <FormattedMessage
                        id="SettingsWebhook.WebhookList.EmptyList.AddButton"
                        defaultMessage="Add new webhook"
                    />
                </Button>
            </Link>
        );
    }
}

export default withRouter(injectIntl(WebhookList));
