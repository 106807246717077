import React from 'react';
import { Checkbox, Col, Icon, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ChannelCheckboxProps } from './ChannelCheckboxModel';

import styles from './ChannelCheckbox.module.scss';
import { FormattedMessage } from 'react-intl';

function ChannelCheckbox(props: ChannelCheckboxProps) {

    const onChangeCheckboxState = (event: CheckboxChangeEvent) => {
        const { checked } = event.target;
        return props.onChangeHandler(checked, props.channel);
    };

    function renderCheckbox(archived: boolean) {
        return (
            <Checkbox
                disabled={props.disabled}
                value={props.channel.id}
                onChange={onChangeCheckboxState}
                checked={props.isChecked}
                className={archived ? styles.ChannelArchived : undefined}
            >
                <span>{props.channel.name} ({props.channel.mnemonicId}) {archived && <Icon type="warning" />}</span>
                <span style={{ background: props.channel.color }} className={styles.ChannelCheckboxChannelColor} />
            </Checkbox>
        );
    }

    function renderTooltipIfArchived() {

        return (
            <Tooltip
                title={(
                    <FormattedMessage
                        id="ChannelCheckbox.tooltip.archived"
                        defaultMessage="Channel is archived"
                    />
                )}
            >
                {renderCheckbox(true)}
            </Tooltip>
        );
    }

    return (
        <Col xs={24} sm={12} md={8} lg={6} className={styles.ChannelCheckboxContainer}>
            {props.channel.archived ? renderTooltipIfArchived() : renderCheckbox(false)}
        </Col>
    );
}

export default ChannelCheckbox;
