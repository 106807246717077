import React from 'react';
import { FieldEditTabProps } from '../../types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { OneLinkedContentOfConstraints } from '../../FieldSerializer/fields/oneLinkedContentOf';
import DefinitionMnemonicIds from '../Constraints/DefinitionMnemonicIds';
import {
    definitionMnemonicIdsMappedErrors,
    linkMappedErrors
} from '../../../../services/FormUtils/DefinitionFieldMappedErrors';
import LinkType from '../Constraints/LinkType';

function LinkedContentsDetails(props: FieldEditTabProps<OneLinkedContentOfConstraints>) {
    return (
        <>
            <LinkType
                relatedTabKey={'detailsTab'}
                mappedErrors={linkMappedErrors}
                onChange={linkType => props.onChangeConstraint('linkType', linkType)}
                value={props.constraints.linkType!}
            />
            <DefinitionMnemonicIds
                relatedTabKey={'detailsTab'}
                mappedErrors={definitionMnemonicIdsMappedErrors}
                selectionType="multiple"
                value={props.constraints.definitionMnemonicIds}
                label={<FormattedMessage
                    id="LinkedContentsDetails.DefinitionMnemonicIds.Label"
                    defaultMessage="Referable content definitions"
                />}
            />
        </>
    );
}

export default observer(LinkedContentsDetails);
