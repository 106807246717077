import React from 'react';
import classes from './index.module.scss';
import { FormattedMessage } from 'react-intl';
import Button from '@components/Button';

export interface UserAlreadyInSpaceProps {
    onClick?(): any;
}

export default function UserAlreadyInSpace(props: UserAlreadyInSpaceProps) {
    return (
        <div className={classes.Invite}>
            <h1 className={classes.InviteTitle}>
                <FormattedMessage
                    defaultMessage="Uhm"
                    id="scenes.AcceptInvite.UserAlreadyInSpace.title"
                />
            </h1>
            <h2 className={classes.InviteSubTitle}>
                <FormattedMessage
                    defaultMessage="It seems you cannot accept this invite"
                    id="scenes.AcceptInvite.UserAlreadyInSpace.subTitle"
                />
            </h2>
            <p className={classes.InviteText}>
                <FormattedMessage
                    defaultMessage="Surely you have already been granted privileges to this space"
                    id="scenes.AcceptInvite.UserAlreadyInSpace.text"
                />
            </p>
            <Button onClick={props.onClick} type="primary">
                <FormattedMessage
                    defaultMessage="Bring me back home"
                    id="scenes.AcceptInvite.UserAlreadyInSpace.backToHome"
                />
            </Button>
        </div>
    );
}
