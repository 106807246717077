import { labels } from '@constants/content-translation-labels';
import { ContentTranslationStatus } from '@contentchef/contentchef-types';
import { Checkbox, Collapse, Icon } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { observer } from 'mobx-react';
import React from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import styles from './index.module.scss';

interface Props extends InjectedIntlProps {
    translationStatus: ContentTranslationStatus | null;
    onChange(value: string | null): void;
}

const Panel = Collapse.Panel;

const componentLabels = defineMessages({
    header: {
        id: 'TranslationFilter.header',
        defaultMessage: 'Translations'
    },
});

function TranslationFilter({ translationStatus, intl: { formatMessage }, onChange }: Props) {

    function onChangeCheckbox(e: CheckboxChangeEvent) {
        const value = e.target.value as ContentTranslationStatus;
        if (translationStatus === value) {
            onChange(null);
        } else {
            onChange(value.toString());
        }
    }

    return (
        <Collapse
            className={styles.Container}
            expandIcon={
                ({ isActive }) => isActive ?
                    <a><Icon style={{ fontSize: 14 }} type="minus" /></a> :
                    <a><Icon style={{ fontSize: 14 }} type="plus" /></a>}
            defaultActiveKey={translationStatus !== null ? ['translationStatus'] : undefined}
            bordered={false}
        >
            <Panel
                header={formatMessage(componentLabels.header)}
                key="translationStatus"
            >
                {Object.keys(ContentTranslationStatus)
                    .filter(key => !isNaN(Number(key))).map((key, index) => {
                        const value = parseInt(key, 10);
                        return (
                            <React.Fragment key={`translation-filter-${key}-${index}`}>
                                {index !== 0 && <br />}
                                <Checkbox
                                    checked={translationStatus === value}
                                    value={value}
                                    onChange={onChangeCheckbox}
                                >
                                    {formatMessage(labels[value as keyof typeof labels])}
                                </Checkbox>
                            </React.Fragment>
                        );
                    })}
            </Panel>
        </Collapse>
    );

}

export default injectIntl(observer(TranslationFilter));
