import React from 'react';
import { Affix } from 'antd';
import { AffixContentProps } from './AffixContentModel';

function AffixContent(props: AffixContentProps) {

    const {
        offsetTop = 10,
        offsetBottom,
        style = {},
    } = props;

    return (
        <Affix
            offsetTop={offsetTop}
            offsetBottom={offsetBottom}
            style={style}
        >
            {props.children}
        </Affix>
    );
}

export default AffixContent;
