import React from 'react';
import Required from '../Constraints/RequiredField';
import { FieldEditTabProps } from '../../types';
import { observer } from 'mobx-react';
import DateRange from '../Constraints/DateRange';
import { DateConstraints } from '../../FieldSerializer/fields/date';
import {
    dateRangeMappedErrors,
    requiredMappedErrors
} from '../../../../services/FormUtils/DefinitionFieldMappedErrors';

function SimpleDateConstraints(props: FieldEditTabProps<DateConstraints>) {
    return (
        <>
            {!props.isArrayChildren && <Required
                relatedTabKey={'validationTab'}
                mappedErrors={requiredMappedErrors}
                onChange={required => props.onChangeConstraint('required', required)}
                value={props.constraints.required}
            />}
            <DateRange
                relatedTabKey={'validationTab'}
                mappedErrors={dateRangeMappedErrors}
                dateFrom={props.constraints.from}
                dateTo={props.constraints.to}
                onChange={(key, value) => props.onChangeConstraint(key, value)}
            />
        </>
    );
}

export default observer(SimpleDateConstraints);
