import React, { Component } from 'react';
import {
    CloudinaryPublicIdModalPreviewProps,
    CloudinaryPublicIdModalPreviewState
} from './CloudinaryPublicIdModalPreviewModel';
import { Col, Input, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import Button from '@components/Button';
import styles from './CloudinaryPublicIdModalPreview.module.scss';
import { previewComponent } from '../../services/Media/CloudinaryTypePreview';

class CloudinaryPublicIdModalPreview extends Component<
    CloudinaryPublicIdModalPreviewProps, CloudinaryPublicIdModalPreviewState
    > {
    render() {
        const { publicId, onConfirmSelection, type } = this.props;
        const userFriendlyPublicId = publicId.split('/').pop();
        return (
            <Row
                type="flex"
                align="middle"
                className={styles.CloudinaryPublicIdModalPreviewContainer}
            >
                <Col xs={24} lg={7} className={styles.CloudinaryPublicIdModalPreviewColumns}>
                    <Col xs={24} className={styles.CloudinaryPublicIdModalPreviewInputContainer}>
                        <p>
                            <FormattedMessage
                                id="CloudinaryPublicIdModalPreview.publicIdLabel"
                                defaultMessage="Cloudinary publicId: "
                            />
                        </p>
                        <Input
                            placeholder="Cloudinary publicId"
                            value={userFriendlyPublicId}
                            disabled={true}
                        />
                    </Col>
                </Col>
                <Col xs={24} lg={17} className={styles.CloudinaryPublicIdModalPreviewColumns}>
                    {
                        previewComponent(type, publicId)
                    }
                </Col>
                <Col xs={24} className={styles.CloudinaryPublicIdModalPreviewCTAContainer}>
                    <Button
                        type="primary"
                        size="large"
                        onClick={onConfirmSelection}
                        className={styles.CloudinaryPublicIdModalPreviewCTAButton}
                    >
                        <FormattedMessage
                            id="CloudinaryPublicIdModalPreview.Confirm"
                            defaultMessage="Confirm"
                        />
                    </Button>
                </Col>
            </Row>
        );
    }
}

export default CloudinaryPublicIdModalPreview;
