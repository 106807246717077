import React, { Component, createRef, RefObject } from 'react';
import { isInViewport } from '../../services/viewPort';
import styles from './LazyImage.module.scss';
import { LazyImageProps, LazyImageState } from './LazyImageModel';

class LazyImage extends Component<LazyImageProps, LazyImageState> {
    state = {
        visible: false
    };
    lazyImageRef: RefObject<HTMLDivElement> = createRef();
    scrollableElement: HTMLElement | null = null;
    mutationObserver: MutationObserver | null = null;

    getScrollableParent = (node: HTMLElement | null): HTMLElement => {
        if (node === null) {
            return document.body;
        }

        if (node.scrollHeight > node.clientHeight) {
            return node;
        } else {
            return this.getScrollableParent(node.parentElement);
        }
    }

    loadImage = () => {
        const { visible } = this.state;
        if (this.lazyImageRef.current) {
            if (isInViewport(this.lazyImageRef.current as HTMLDivElement) && !visible) {
                this.setState(
                    {
                        visible: true
                    }
                );
            }
        }
    }

    mutationCallback = () => {
        const { visible } = this.state;
        if (!visible) {
            this.loadImage();
        }
    }

    componentDidMount() {
        this.scrollableElement = this.getScrollableParent((this.lazyImageRef.current as HTMLElement).parentElement);
        (this.scrollableElement as HTMLElement).addEventListener('scroll', this.loadImage);
        this.mutationObserver = new MutationObserver(this.mutationCallback);
        this.mutationObserver.observe((this.scrollableElement as HTMLElement), {
            childList: true,
            subtree: true
        });
        this.loadImage();
    }

    componentWillUnmount() {
        (this.scrollableElement as HTMLElement).removeEventListener('scroll', this.loadImage);
        if (this.mutationObserver) {
            this.mutationObserver.disconnect();
        }
    }

    render() {
        const { visible } = this.state;
        return (
            <div
                ref={this.lazyImageRef}
                data-in-view={visible}
                className={styles.LazyImageContainer}
            >
                {
                    visible && (
                        <img
                            {...this.props}
                            alt=''
                        />
                    )
                }
            </div>
        );
    }
}

export default LazyImage;
