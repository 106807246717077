import { PublishingChannelResponse } from '@contentchef/contentchef-types';
import { withDisabledPropertyInContentForm } from '@hoc/withDisabledPropertyInContentForm';
import { Row } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withPermissionsToInteract } from '../../../providers/UserPermissionsProvider';
import { ChannelCheckbox } from '../../index';
import { FieldChannelsSelectorProps, FieldChannelsSelectorState, InjectedProps } from './FieldChannelsSelectorModel';

@inject('channelsStore')
@observer
class FieldChannelsSelector extends Component<FieldChannelsSelectorProps, FieldChannelsSelectorState> {
    get injected() {
        return this.props as InjectedProps;
    }

    componentDidMount() {
        const { contentCreationFlow, fieldChannels } = this.props;
        const { channels } = this.injected.channelsStore;
        if (contentCreationFlow && fieldChannels.value.length === 0) {
            channels.map((channel) => {
                fieldChannels.setFieldValue(true, channel);
                return channel;
            });
        }
        if (!contentCreationFlow) {
            this.injected.channelsStore.filterOutNonActiveArchivedChannels(fieldChannels.value);
        }
    }

    onChangeSelectedChannels = (checked: boolean, channel: PublishingChannelResponse) => {
        this.props.fieldChannels.setFieldValue(checked, channel);
    }

    render() {
        const { fieldChannels, hasPermissions, archived } = this.props;
        const { channels } = this.injected.channelsStore;
        const disabled = archived || !hasPermissions;
        return (
            <Row>{
                channels.map((channel, index) => (
                    <ChannelCheckbox
                        key={index}
                        disabled={disabled}
                        isChecked={!!fieldChannels.value.find(
                            selectedChannel => selectedChannel.id === channel.id
                        )}
                        channel={channel}
                        onChangeHandler={this.onChangeSelectedChannels}
                    />
                ))
            }</Row>
        );
    }
}

export default withDisabledPropertyInContentForm(withPermissionsToInteract(FieldChannelsSelector));
