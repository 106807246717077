import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import { CreateContentRequest, GetContentDefinitionResponse } from '@contentchef/contentchef-types';
import { ExtensionManager } from '@stores/extensionManager';
import { action, observable, runInAction } from 'mobx';
import { routerStoreHelper } from '..';
import {
    ContentErrorFactory,
    ContentErrorTypes,
    ContentValidationError
} from '../../services/Error/ContentErrorManager';
import { ChannelsStoreModel } from '../channelsStore/channelsStoreModel';
import { ContentDefinitionStoreModel } from '../contentDefinitionStore/contentDefinitionStoreModel';
import { ContentStoreModel } from '../contentStore/contentStoreModel';
import { FormMetaStoreModel } from '../formMetaStore/formMetaStoreModel';
import { ListPublicIdStoreModel } from '../listPublicIdStore/listPublicIdStoreModel';
import { RepositoryListStoreModel } from '../repositoryListStore/repositoryListStoreModel';
import { AddContentDataModel, AddContentStoreDependencies, AddContentStoreModel } from './addContentStoreModel';

class AddContentStore implements AddContentStoreModel {
    api: ContentChefClient;
    repositoryListStore: RepositoryListStoreModel;
    contentDefinitionStore: ContentDefinitionStoreModel;
    listPublicIdStore: ListPublicIdStoreModel;
    channelsStore: ChannelsStoreModel;
    formMetaStore: FormMetaStoreModel;
    contentStore: ContentStoreModel;
    extensionManager: ExtensionManager;

    @observable addContentData: AddContentDataModel = {
        contentDefinition: {} as GetContentDefinitionResponse,
    };

    constructor(api: ContentChefClient, storeDependencies: AddContentStoreDependencies) {
        this.api = api;
        this.repositoryListStore = storeDependencies.repositoryListStore;
        this.contentDefinitionStore = storeDependencies.contentDefinitionStore;
        this.channelsStore = storeDependencies.channelsStore;
        this.formMetaStore = storeDependencies.formMetaStore;
        this.contentStore = storeDependencies.contentStore;
        this.listPublicIdStore = storeDependencies.listPublicIdStore;
        this.extensionManager = storeDependencies.extensionManager;
    }

    @action
    async initializeAddContent(contentDefId: string) {
        try {
            await Promise.all([
                this.repositoryListStore.setRepositories(30),
                this.contentDefinitionStore.setContentDefinition(contentDefId),
                this.listPublicIdStore.initialize(+contentDefId),
                this.channelsStore.setChannels(),
                this.extensionManager.setSpaceExtensions()
            ]);

            runInAction(() => {
                if (this.contentDefinitionStore.contentDefinition) {
                    this.addContentData.contentDefinition = this.contentDefinitionStore.contentDefinition;
                    this.formMetaStore.initializeStore(this.contentDefinitionStore.contentDefinition);
                }
            });
        } catch (e) {
            console.log('Impossible to inizialize add content form', e);
        }

    }

    @action
    async saveContent() {
        const contentData = this.createSaveContentData();
        if (contentData) {
            try {
                const contentId = await this.contentStore.saveContent(contentData);
                if (contentId) {
                    routerStoreHelper.onSuccessfulContentCreate(await this.api.spaceId, contentId);
                }
                return Promise.resolve();
            } catch (error: any) {
                error.maybeHandleViolations();
                return Promise.reject(error);
            }
        } else {
            console.log('Something went wrong trying to create the new content');
            return Promise.reject(ContentErrorFactory.createContentError(
                { type: ContentErrorTypes.UnexpectedError } as ContentValidationError
            ));
        }

    }

    private createSaveContentData(): CreateContentRequest | undefined {
        const { contentDefinition } = this.addContentData;
        if (contentDefinition) {
            const { onlineDate, offlineDate, ...others } = this.formMetaStore.getCommonFieldsValue();
            const payloadValues = this.formMetaStore.getDynamicFieldValues();
            return {
                name: others.name,
                repositoryId: others.repository,
                publicId: others.publicId,
                tags: others.tags,
                payload: {
                    ...payloadValues
                },
                definitionId: contentDefinition.id,
                channelIds: others.channels,
                onlineDate,
                offlineDate
            };
        }
        return undefined;
    }
}

export default AddContentStore;
