import Button from '@components/Button';
import Typography from '@components/Typography';
import { withDisabledPropertyInContentForm } from '@hoc/withDisabledPropertyInContentForm';
import { useListPublicIdStore } from '@hooks/mobx';
import { Col, Input, List, Modal, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { ChangeEvent, FocusEvent, useCallback, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withPermissionsToInteract } from '../../providers/UserPermissionsProvider';
import { debounce } from '../../services/utils';
import { ContentPublicIdProps, InjectedProps } from './ContentPublicIdModel';
import styles from './index.module.scss';

function ContentPublicId(props: ContentPublicIdProps) {
    const store = useListPublicIdStore();
    const { publicIdField, intl, hasPermissions, archived, ...others } = props;
    const { listPublicId } = store;
    const disabled = archived || !hasPermissions;
    const injected = props as InjectedProps;
    const [visible, setVisible] = useState(false);
    const [filterValue, setFilterValue] = useState('');
    const [loading, setLoading] = useState(false);
    const updatePublicIdCallback = debounce(updatePublicId, 500);
    const debounceSearch = useCallback((value: string) => updatePublicIdCallback(value), [updatePublicIdCallback]);

    function onClick() {
        setFilterValue(publicIdField.value || '');
        setVisible(true);
        updatePublicId(publicIdField.value || '');
    }

    function closeModal() {
        setVisible(false);
    }

    async function updatePublicId(value: string) {
        setLoading(true);
        await store.setListPublicId(value);
        setLoading(false);
    }

    async function onChangeFilter(event: ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        setFilterValue(value);
        await debounceSearch(value);
    }

    function onChange(event: ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        props.publicIdField.setFieldValue(value as string);
        injected.onChange(value);
    }

    function onBlur(event: FocusEvent<HTMLInputElement>) {
        const value = event.target.value;
        const trimmedValue = props.publicIdField.trimValue(value as string);
        return injected.onChange(trimmedValue);
    }

    function onClickListItem(value: string) {
        return () => {
            setFilterValue(value);
            props.publicIdField.setFieldValue(value as string);
            injected.onChange(value);
            setVisible(false);
        };
    }

    return (
        <Row type="flex" align="middle" gutter={10}>
            <Col xs={24} className={styles.Container}>
                <Input
                    {...others}
                    disabled={disabled}
                    type="text"
                    value={publicIdField.value}
                    onChange={onChange}
                    onBlur={onBlur}
                />
                <Button
                    disabled={disabled}
                    icon="search"
                    onClick={onClick}
                    size="large"
                />
                <Modal
                    visible={visible}
                    destroyOnClose={true}
                    onCancel={closeModal}
                    centered={true}
                    className={styles.ModalContainer}
                    footer={null}
                    title={
                        <FormattedMessage
                            id="ContentPublicId.title"
                            defaultMessage="Search public id"
                        />
                    }
                >
                    <Input
                        type="text"
                        value={filterValue}
                        onChange={onChangeFilter}
                    />
                    <List
                        dataSource={listPublicId}
                        bordered={true}
                        size="small"
                        className={styles.ListContainer}
                        loading={loading}
                        renderItem={(item) => (
                            <List.Item className={styles.ListItem} onClick={onClickListItem(item)}>
                                <Typography gutter={false}>{item}</Typography>
                            </List.Item>
                        )}
                    />
                </Modal>
            </Col>
        </Row>
    );
}

export default withDisabledPropertyInContentForm(withPermissionsToInteract(injectIntl(observer(ContentPublicId))));
