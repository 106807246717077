import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import { action } from 'mobx';
import { CreateMediaGalleryRequest } from '@contentchef/contentchef-types';
import { routerStoreHelper } from '..';
import { CreateMediaGalleryStoreModel } from './createMediaGalleryStoreModel';
import {
    // goToUpdateMediaGallery 
    editMediaGalleryPath
} from '../../constants/routing-constants';

class CreateMediaGalleryStore implements CreateMediaGalleryStoreModel {
    api: ContentChefClient;

    constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action
    async save(params: CreateMediaGalleryRequest) {
        try {
            const response = await this.api.mediaGallery.save(params);
            if (response) {
                routerStoreHelper.routerStore.history.push(editMediaGalleryPath(await this.api.spaceId, response.id));

            }
        } catch (e) {
            console.log('Error while creating a mediaGallery', e);
        }
    }
}

export default CreateMediaGalleryStore;
