import { UserPermissionIds } from '@contentchef/contentchef-types';

const createPermissionsTable = (...perms: UserPermissionIds[]) => perms;

export const userPermissionsTable = {
    Forms: {
        AddContentForm: createPermissionsTable(UserPermissionIds.MANAGE_CONTENT),
        EditContentForm: createPermissionsTable(UserPermissionIds.MANAGE_CONTENT),
        CreateContentDefinitionForm: createPermissionsTable(UserPermissionIds.MANAGE_DEFINITIONS),
        UpdateContentDefinitionForm: createPermissionsTable(UserPermissionIds.MANAGE_DEFINITIONS),
        CreateRepositoryForm: createPermissionsTable(UserPermissionIds.MANAGE_CONTENT_REPOSITORY),
        UpdateRepositoryForm: createPermissionsTable(UserPermissionIds.MANAGE_CONTENT_REPOSITORY),
        CreatePublishingChannelForm: createPermissionsTable(UserPermissionIds.MANAGE_PUBLISHING_CHANNELS),
        UpdatePublishingChannelForm: createPermissionsTable(UserPermissionIds.MANAGE_PUBLISHING_CHANNELS),
        CreateMediaGalleryForm: createPermissionsTable(UserPermissionIds.MANAGE_MEDIA_GALLERY),
        UpdateMediaGalleryForm: createPermissionsTable(UserPermissionIds.MANAGE_MEDIA_GALLERY),
    },
    ListItemActions: {
        ContentCardActions: createPermissionsTable(UserPermissionIds.MANAGE_CONTENT),
        ContentDefinitionListItem: createPermissionsTable(UserPermissionIds.MANAGE_DEFINITIONS),
        RepositoryListItemActions: createPermissionsTable(UserPermissionIds.MANAGE_CONTENT_REPOSITORY),
        MediaGalleryListItemActions: createPermissionsTable(UserPermissionIds.MANAGE_MEDIA_GALLERY),
        PublishingChannelListItemActions: createPermissionsTable(UserPermissionIds.MANAGE_PUBLISHING_CHANNELS)
    },
    FloatingActions: {
        Content: createPermissionsTable(UserPermissionIds.MANAGE_CONTENT),
        ContentDefinition: createPermissionsTable(UserPermissionIds.MANAGE_DEFINITIONS),
        Channel: createPermissionsTable(UserPermissionIds.MANAGE_PUBLISHING_CHANNELS),
        MediaGallery: createPermissionsTable(UserPermissionIds.MANAGE_MEDIA_GALLERY),
        Repository: createPermissionsTable(UserPermissionIds.MANAGE_CONTENT_REPOSITORY),
    },
    FormActions: {
        content: createPermissionsTable(UserPermissionIds.MANAGE_CONTENT),
        definition: createPermissionsTable(UserPermissionIds.MANAGE_DEFINITIONS),
        repository: createPermissionsTable(UserPermissionIds.MANAGE_CONTENT_REPOSITORY),
        mediaGallery: createPermissionsTable(UserPermissionIds.MANAGE_MEDIA_GALLERY),
        channel: createPermissionsTable(UserPermissionIds.MANAGE_PUBLISHING_CHANNELS)
    },
    MediaActions: {
        addFolderPermissions: createPermissionsTable(UserPermissionIds.MANAGE_MEDIA_GALLERY),
        uploadPermissions: createPermissionsTable(UserPermissionIds.MANAGE_MEDIA_GALLERY),
        deletePermissions: createPermissionsTable(
            UserPermissionIds.MANAGE_MEDIA_GALLERY,
            UserPermissionIds.MANAGE_CONTENT
        ),
    },
    MediaDetails: {
        modifyTagsPermissions: createPermissionsTable(UserPermissionIds.MANAGE_MEDIA_GALLERY),
    },
    PublishActions: {
        actions: createPermissionsTable(UserPermissionIds.MANAGE_LIVE_PUBLISHING)
    },
    SideBar: {
        usersAndPermissions: createPermissionsTable(UserPermissionIds.MANAGE_SPACE_PROFILES),
        webhooks: createPermissionsTable(UserPermissionIds.MANAGE_SPACE_WEBHOOKS),
        others: createPermissionsTable(UserPermissionIds.READ_SPACE)
    }
};
