import React from 'react';

import classes from './index.module.scss';
import Paper from '../Paper';
import Entry from '../Entry';

interface EntryListProps {
    entries: { label: React.ReactNode, value: React.ReactNode, alignValue: 'right' | 'center' | 'left' }[];
}

function EntryList(props: EntryListProps) {
    return (
        <Paper
            gutter={true}
        >
            <div className={classes.EntryListContainer}>
                {props.entries.map(entry => (
                    <Entry
                        alignValue={entry.alignValue}
                        label={entry.label}
                        value={entry.value}
                    />
                ))}
            </div>
        </Paper>
    );
}

export default EntryList;
