import Button from '@components/Button';
import { ContentTranslation, ContentTranslationStatus } from '@contentchef/contentchef-types';
import { languageByLocale } from '@services/languages';
import { ConfigProvider, Icon, Popconfirm, Tag } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

const labels = defineMessages({
    inSync: {
        id: 'LocaleItem.sync',
        defaultMessage: 'Custom'
    },
    toSync: {
        id: 'LocaleItem.notSync',
        defaultMessage: 'To update'
    },
    originalTitle: {
        id: 'LocaleItem.original',
        defaultMessage: 'Original'
    },
    editTitle: {
        id: 'LocaleItem.editTile',
        defaultMessage: 'Edit translation'
    },
    confirmDelete: {
        id: 'LocaleItem.confirmDelete',
        defaultMessage: 'Translation will be cleaned and selection will be removed. Proceed?'
    },
    confirmCreate: {
        id: 'LocaleItem.confirmCreate',
        defaultMessage: 'Translation will be configured for this content. Proceed?'
    },
});

interface Props extends InjectedIntlProps {
    key?: string;
    locale: string;
    contentTranslation?: ContentTranslation;
    showSelection?: boolean;
    fullName?: boolean;
    to?: string;
    original?: boolean;
    showEdit?: boolean;
    disabled?: boolean;
    toSave?: boolean;
    onSelect?(loc: string): void;
    onDelete?(loc: string): Promise<any>;
    onClickEdit?(): void;
}

function LocaleItem({
    locale, to, intl, contentTranslation, onClickEdit, original = false,
    onSelect, onDelete, showSelection = true, fullName = false, showEdit, disabled = false, toSave = false
}: Props) {
    const [selected, setSelected] = useState(() => !!contentTranslation);
    const [waiting, setWaiting] = useState(false);
    const [visibleConfirm, setVisibleConfirm] = useState(false);
    const syncLabel = intl.formatMessage(labels.inSync);
    const notSyncLabel = intl.formatMessage(labels.toSync);
    const editTitle = intl.formatMessage(labels.editTitle);
    const originalTitle = intl.formatMessage(labels.originalTitle);

    useEffect(
        () => {
            setSelected(!!contentTranslation);
        },
        [contentTranslation]
    );

    function getStatusProperties() {
        if (original) {
            return {
                title: originalTitle,
                className: styles.Original
            };
        }
        switch (contentTranslation?.status) {
            case ContentTranslationStatus.UPTODATE:
                return {
                    title: syncLabel,
                    className: styles.Synchronized
                };
            case ContentTranslationStatus.INCOMPLETE:
            case ContentTranslationStatus.TO_VERIFY:
                return {
                    title: notSyncLabel,
                    className: styles.NotSynchronized
                };
            default:
                return null;
        }
    }

    function renderTag() {
        const tagProperties = getStatusProperties();

        if (!tagProperties) {
            return null;
        }

        return (
            <Tag className={tagProperties.className}>{tagProperties.title}</Tag>
        );
    }

    function renderEditAction(loc: string) {
        const button = (
            <Button
                title={editTitle}
                size="small"
                className={styles.EditAction}
                onClick={onClickEdit ? onClickEdit : undefined}
                disabled={original}
            >
                <Icon type="edit" theme="filled" />
            </Button>
        );
        if (onClickEdit) {
            return button;
        }
        if (to) {
            return (
                <Link
                    to={to}
                    key={loc}
                >
                    {button}
                </Link>
            );
        }
        return button;
    }

    const localeName = languageByLocale.getLanguageNameByLocale(locale as Parameters<typeof languageByLocale.getLanguageNameByLocale>[0]);

    const regexpResult = languageByLocale.getRegion(locale as Parameters<typeof languageByLocale.getRegion>[0]);
    const labelToUse = regexpResult && regexpResult[1] && !fullName ?
        regexpResult[1] : localeName ? localeName : locale;

    const selectedClassName = classNames(styles.Selection, {
        [styles.Selected]: (selected && !waiting) || original,
        [styles.ToSave]: toSave
    });

    const containerClassName = classNames(styles.LocaleItemContainer);

    async function onConfirmDelete() {
        if (onDelete) {
            setWaiting(true);
            await onDelete(locale);
            setWaiting(false);
        }
    }

    const shouldEditBeVisible = typeof showEdit !== 'undefined' ? showEdit : (!original && selected);

    const tag = renderTag();

    function renderIcon() {
        if (waiting) {
            return (
                <Icon
                    className={styles.IconWaiting}
                    type="loading"
                />
            );
        }

        if (selected || original) {
            return (
                <Icon
                    className={styles.IconCheck}
                    type="check"
                />
            );
        }
        if (toSave) {
            return (
                <Icon
                    className={styles.IconToSave}
                    type="minus"
                />
            );
        }

        return null;
    }

    return (
        <ConfigProvider
            getPopupContainer={() => document.body}
        >
            <div className={containerClassName} key={locale}>
                {showSelection && <Popconfirm
                    title={intl.formatMessage(labels.confirmDelete)}
                    onVisibleChange={(visible) => {
                        if (!selected && onSelect) {
                            onSelect(locale);
                            return;
                        }
                        setVisibleConfirm(visible);
                    }}
                    onConfirm={onConfirmDelete}
                    onCancel={() => setWaiting(false)}
                    disabled={original || disabled}
                    visible={visibleConfirm}

                >
                    <div className={selectedClassName}>
                        {renderIcon()}
                    </div>
                </Popconfirm>}
                <div className={styles.LocaleLabel}>
                    <p title={`${labelToUse} (${locale})`}>
                        {labelToUse}
                        <span
                            style={{
                                marginLeft: 5
                            }}
                        >
                            ({locale})
                        </span>
                    </p>
                </div>
                {(tag || shouldEditBeVisible) &&
                    <div className={styles.ActionsContainer}>
                        {tag && <div className={styles.TagContainer}>
                            {tag}
                        </div>}
                        {shouldEditBeVisible && <div>
                            {renderEditAction(locale)}
                        </div>}
                    </div>}
            </div>
        </ConfigProvider>
    );
}

export const LocaleItemOriginal = injectIntl((props: Pick<Props, 'locale' | 'onClickEdit'> & InjectedIntlProps) => {
    return (
        <LocaleItem
            {...props}
            fullName={true}
            showSelection={false}
            showEdit={true}
        />
    );
});

export const LocaleItemWithToggle = injectIntl(
    (props: Props & { onToggle: (locale: string) => any; toggled: boolean; }) => {
        const { onToggle, toggled } = props;
        const toggle = () => {
            onToggle(props.locale);
        };

        const className = classNames(styles.ToggleContainer, {
            [styles.Toggled]: toggled
        });
        return (
            <div key={props['key']} onClick={toggle} className={className}>
                <div className={styles.ToggleSelection}>
                    <Icon
                        className={styles.DeleteIcon}
                        type="delete"
                    />
                </div>
                <LocaleItem
                    {...props}
                    showSelection={false}
                />
            </div>
        );
    });

export default injectIntl(LocaleItem);
