import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

export default function LabelWithIcon(
    { label, icon, iconClassName }: { label: string, icon?: React.ReactNode, iconClassName?: string }
) {
    const statusClassName = classNames(styles.Spacing, iconClassName);
    return (
        <span>
            {label}
            {icon && <span className={statusClassName}>{icon}</span>}
        </span>
    );
}
