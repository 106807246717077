import { useApiStore } from '@hooks/mobx';
import { ContentTranslationManager } from '@stores/contentTranslation/ContentTranslationManager';
import { Provider } from 'mobx-react';
import React, { useState } from 'react';
import { WithStoresComponentProps, WithStoresWrappedComponent } from '../../constants/common-types';

const withContentTranslationManager = <T extends {}>(WrappedComponent: WithStoresWrappedComponent<T>) => {

    function WithContentTranslationManager(props: WithStoresComponentProps<T>) {
        const apiStore = useApiStore();
        const [contentTranslationManager] = useState(() => new ContentTranslationManager(apiStore.api));

        return (
            <Provider
                contentTranslationManager={contentTranslationManager}
            >
                <WrappedComponent {...props} />
            </Provider>
        );
    }

    return WithContentTranslationManager;
};

export default withContentTranslationManager;
