import React from 'react';
import { SchemaTypeIds } from '@contentchef/contentchef-types';
import { DynamicFieldTypes, FieldOneLinkedContentOf } from '../FormFields';
import { LinkedContent } from '../../../components';
import { fieldMatchersUtils } from './FieldMatchersUtils';
import { FieldMatcher } from './FormFieldMatchersFactory';
import { defaultFieldWrapper } from './DefaultFieldWrapper';

export class OneLinkedContentOfTypeMatcher implements FieldMatcher {
    maybeCreateComponent(
        id: string, userLocale: string, onChange: (e: any) => void, field: DynamicFieldTypes
    ): JSX.Element | null {
        if (!fieldMatchersUtils.typeMatcher(field, SchemaTypeIds.ONE_LINKED_CONTENT_OF)) { return null; }
        const fieldOneLinkedContent = field as FieldOneLinkedContentOf;

        function elementToRender(disabled: boolean) {
            return (
                <LinkedContent
                    id={id}
                    disableActions={disabled || fieldOneLinkedContent.disabled}
                    linkedField={fieldOneLinkedContent}
                    onChange={onChange}
                />
            );
        }

        return defaultFieldWrapper(elementToRender);
    }
}
