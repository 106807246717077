import { SchemaTypeIds } from '@contentchef/contentchef-types';
import { Form } from 'antd';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import withDefinitionErrors, {
    WithDefinitionErrorsReturnProps
} from '../../../../hoc/withDefinitionErrors/withDefinitionErrors';
import Segment from '../../../Segment/Segment';
import Typography from '../../../Typography';
import BooleanAttribute from '../Fields/Boolean';

interface TranslatableProps extends InjectedIntlProps, WithDefinitionErrorsReturnProps {
    type: SchemaTypeIds;
    value?: boolean;
    onChange(value: boolean): any;
}

const translatableLabels = defineMessages({
    translatableAttrName: {
        id: 'components.ContentDefinitionSchemaEditor.Translatable.Label',
        defaultMessage: 'Translatable'
    },
    translatableSelectedHelper: {
        id: 'components.ContentDefinitionSchemaEditor.Translatable.Helper.True',
        defaultMessage: 'Make this field translatable'
    },
    translatableUnselectedHelper: {
        id: 'components.ContentDefinitionSchemaEditor.Translatable.Helper.False',
        defaultMessage: 'Don\'t make this field translatable'
    },
    translatableNotAvailable: {
        id: 'components.ContentDefinitionSchemaEditor.Translatable.Helper.NotAvailable',
        defaultMessage: 'This field cannot be localized.'
    },
});

export function hasTranslatableAttribute(type: SchemaTypeIds) {
    switch (type) {
        case SchemaTypeIds.TEXT:
        case SchemaTypeIds.LONG_TEXT:
        case SchemaTypeIds.CLOUDINARY_PUBLICID:
        case SchemaTypeIds.MEDIA:
        case SchemaTypeIds.CLOUDINARY_IMAGE:
        case SchemaTypeIds.CLOUDINARY_RAW:
        case SchemaTypeIds.CLOUDINARY_VIDEO:
        case SchemaTypeIds.NUMBER:
            return true;
        default:
            return false;
    }
}

function TranslatableAttribute(props: TranslatableProps) {
    return (hasTranslatableAttribute(props.type)
        ? (
            <BooleanAttribute
                attributeName={props.intl.formatMessage(translatableLabels.translatableAttrName)}
                helperTrue={props.intl.formatMessage(translatableLabels.translatableSelectedHelper)}
                helperFalse={props.intl.formatMessage(translatableLabels.translatableUnselectedHelper)}
                onChange={props.onChange}
                value={props.value}
                hasErrors={props.hasErrors}
                errors={props.errors.map(error => props.intl.formatMessage(error))}
            />
        ) : (
            <Segment
                divider={true}
                titleVariant="label"
                title={props.intl.formatMessage(translatableLabels.translatableAttrName)}
            >
                <Form.Item
                    help={props.hasErrors
                        ? props.errors.map(error => props.intl.formatMessage(error)).join(', ')
                        : undefined
                    }
                    validateStatus={props.hasErrors ? 'error' : undefined}
                    style={{ width: '100%' }}
                >
                    <Typography variant="small">
                        {props.intl.formatMessage(translatableLabels.translatableNotAvailable)}
                    </Typography>
                </Form.Item>
            </Segment>
        )
    );
}

export default withDefinitionErrors(injectIntl(TranslatableAttribute));
