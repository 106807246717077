import React, { Component } from 'react';
import { MediaGalleriesRouterProps, InjectedProps } from './MediaGalleriesRouterModel';
import { Route, Switch } from 'react-router';
import CreateMediaGallery from '../CreateMediaGallery/CreateMediaGallery';
import MediaGalleryScene from '../MediaGalleryScene/MediaGalleryScene';
import MediaGalleries from '../MediaGalleries/MediaGalleries';
import {
    MEDIA_GALLERIES_ROUTE,
    MEDIA_GALLERY_DETAIL,
    NEW_MEDIA_GALLERY,
    mediaGalleryBasePath
} from '../../../constants/routing-constants';
import { inject } from 'mobx-react';
import { NavigationItem } from '../../../stores/navStore/navStore';
import { NAVIGATION_LABELS } from '../../../constants/navigation-internationalized-labels';
import { injectIntl } from 'react-intl';
import { NAVIGATION_ITEM_IDENTIFIERS } from '../../../constants/navigation-item-identifier';
import { NAVIGATION_PRIORITY } from '../../../stores/navStore/navStoreModel';

@inject('navStore')
class MediaGalleriesRouter extends Component<MediaGalleriesRouterProps> {
    get injected() {
        return this.props as InjectedProps;
    }
    componentDidMount() {
        const { formatMessage } = this.props.intl;
        const { match: { params: { spaceId } } } = this.props;
        this.injected.navStore.setNavigationPath(
            new NavigationItem(
                NAVIGATION_ITEM_IDENTIFIERS.MEDIAGALLERY,
                mediaGalleryBasePath(spaceId),
                formatMessage(NAVIGATION_LABELS.mediaGalleries),
                NAVIGATION_PRIORITY.FIRST
            )
        );
    }

    componentWillUnmount() {
        this.injected.navStore.removeItem(NAVIGATION_ITEM_IDENTIFIERS.MEDIAGALLERY);
    }

    render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route
                        exact={true}
                        path={MEDIA_GALLERIES_ROUTE}
                        component={MediaGalleries}
                    />
                    <Route
                        exact={true}
                        path={NEW_MEDIA_GALLERY}
                        component={CreateMediaGallery}
                    />
                    <Route
                        path={MEDIA_GALLERY_DETAIL}
                        component={MediaGalleryScene}
                    />
                </Switch>
            </React.Fragment>
        );
    }
}

export default injectIntl(MediaGalleriesRouter);
