import React from 'react';
import { UserPermission } from '@contentchef/contentchef-types';
import styles from './SpaceSelector.module.scss';
import SpaceList from '../SpaceList/SpaceList';
import { FormattedMessage } from 'react-intl';

interface SpaceSelectorProps {
    spaceList: UserPermission[];
}

function SpaceSelector(props: SpaceSelectorProps) {
    const { spaceList } = props;
    return (
        <div className={styles.SpaceSelectorWrapper}>
            <div
                id="space_list"
                className={styles.SpaceSelectorListWrapper}
            >
                <FormattedMessage
                    id="SpaceSelector.ChooseSpace"
                    defaultMessage="Choose a space"
                >
                    {txt => <p className={styles.SpaceSelectorListTitle}>{txt}</p>}
                </FormattedMessage>
                <SpaceList spaceList={spaceList} className={styles.SpaceSelectorListSpace} />
            </div>
        </div>
    );
}

export default SpaceSelector;
