import { FloatingActions, SceneContainer } from '@components';
import AddButton from '@components/atoms/AddButton';
import ExtensionListItemActions from '@components/Extension/ExtensionListItemActions';
import { getTypeLabel } from '@constants/fields-constants';
import { createExtensionPath, editExtensionPath } from '@constants/routing-constants';
import { userPermissionsTable } from '@constants/user-permissions-table';
import LinkableContent from '@hoc/LinkableContent/LinkableContent';
import withExtensionAPI from '@hoc/withExtensionAPI';
import { useStoreForExtension } from '@hooks/mobx';
import { ManageFieldProvider } from '@providers/UserPermissionsProvider';
import { Col, Icon, List, Tag } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

interface ExtensionListProps extends RouteComponentProps<{ spaceId: string; }>, InjectedIntlProps { }

const labels = defineMessages({
    title: {
        id: 'ExtensionsList.newButton',
        defaultMessage: 'Create new extension'
    },
    edit: {
        id: 'ExtensionsList.edit',
        defaultMessage: 'Edit'
    }
});

function ExtensionList(props: ExtensionListProps) {

    const { extensionAPI } = useStoreForExtension();

    function printCreateButton() {
        const { spaceId } = props.match.params;
        return (
            <Link to={createExtensionPath(spaceId)}>
                <AddButton
                    dataId="extension-new"
                    title={labels.title}
                />
            </Link>
        );
    }

    useEffect(
        () => {
            async function list() {
                await extensionAPI.setExtensions();
            }

            list();
        },
        [extensionAPI]
    );

    function onClick(id: string) {
        const spaceId = props.match.params.spaceId;
        return editExtensionPath(spaceId, id);
    }

    return (
        <SceneContainer>
            <List
                itemLayout="horizontal"
                loading={extensionAPI.loading.list}
                dataSource={extensionAPI.extensions}
                renderItem={item => (
                    <List.Item id={item.id}>
                        <LinkableContent to={onClick(item.id)}>
                            <div className={styles.ExtensionItemDataContainer}>
                                <Col xs={19} sm={20} md={21}>
                                    <Col
                                        xs={24}
                                        lg={16}
                                        className={styles.ExtensionItemNameMnemonicContainer}
                                    >
                                        <Icon type="layout" className={styles.ExtensionItemIcons} />
                                        <div className={styles.ExtensionItemInfoContainer}>
                                            <div
                                                className={styles.ExtensionItemNameWrapper}
                                            >
                                                {item.name}
                                            </div>
                                            <div
                                                className={styles.ExtensionItemMnemonicWrapper}
                                            >
                                                {item.mnemonicId}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24} lg={8} className={styles.ExtensionItemTagContainer}>
                                        <Col xs={24} className={styles.ExtensionItemTagWrapper}>
                                            {item.supportedTypes.map(type => (
                                                <Tag color={'#00AEEF'} key={`${item.id}_${type}`}>
                                                    {props.intl.formatMessage(getTypeLabel(type))}
                                                </Tag>
                                            ))}
                                        </Col>
                                    </Col>
                                </Col>
                                <ManageFieldProvider
                                    permissions={userPermissionsTable.ListItemActions.ContentDefinitionListItem}
                                >
                                    <Col
                                        xs={5}
                                        sm={4}
                                        md={3}
                                        className={styles.ExtensionItemActionsContainer}
                                    >
                                        <div>
                                            <ExtensionListItemActions
                                                extensionId={item.id}
                                            />
                                        </div>
                                    </Col>
                                </ManageFieldProvider>
                            </div>
                        </LinkableContent>
                    </List.Item>
                )}
            />
            <FloatingActions>
                {printCreateButton()}
            </FloatingActions>
        </SceneContainer>
    );
}

export default injectIntl(withExtensionAPI(observer(ExtensionList)));
