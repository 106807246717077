import TranslateIcon from '@assets/custom_icons/components/Translate';
import { languageByLocale } from '@services/languages';
import { Card, Col, Icon, Row, Tag } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { DATE_TIME_FORMAT } from '../../constants/date-constants';
import styles from './ContentCard.module.scss';
import { ContentCardProps, ContentCardState } from './ContentCardModel';

@observer
class ContentCard extends Component<ContentCardProps, ContentCardState> {

    onCardSelection = () => {
        if (!!this.props.onCardClick) {
            return this.props.onCardClick(this.props.content);
        }
        return null;
    }

    render() {
        const { content, onCardClick, selectable, actions } = this.props;
        // TODO Refactor tags wrapper style
        return (
            <Col
                onClick={this.onCardSelection}
                xs={24}
                key={content.id}
                className={styles.ContentCardWrapperCol}
            >
                <Card
                    className={styles.ContentCardWrapperCard}
                    hoverable={!!onCardClick}
                >
                    <div className={styles.ContentCardContainer}>
                        {selectable && (
                            <div className={styles.ContentCardSelectableContainer}>
                                {selectable}
                            </div>
                        )}
                        <div
                            className={styles.ContentCardRepositoryColorContainer}
                            style={{ backgroundColor: `${content.repository.color}` }}
                        />
                        <div className={styles.ContentCardBodyWrapper}>
                            <Col xs={24} md={14} className={styles.ContentCardLeftBodyContainer}>
                                <div className={styles.ContentCardContentNameWrapper}>{content.name}</div>
                                <div className={styles.ContentCardContentPublicIdWrapper}>
                                    <Icon type="link" className={styles.ContentCardIconStyleOverwrite} />
                                    {content.publicId}
                                </div>
                                <div className={styles.ContentCardContentLocaleWrapper}>
                                    <TranslateIcon className={styles.ContentCardIconStyleOverwrite} />
                                    {languageByLocale.labelByLocale(content.locale)}
                                </div>
                                <div className={styles.ContentCardContentTagsWrapper}>
                                    {content.tags.map((tag, index) => (
                                        <Tag key={`${tag}_${index}`} color={'#00AEEF'}>
                                            <Icon type="tag" className={styles.ContentCardTagsIconStyleOverwrite} />
                                            {tag}
                                        </Tag>
                                    ))}
                                </div>
                            </Col>
                            <Col xs={24} md={10} className={styles.ContentCardRightBodyContainer}>
                                <Row>
                                    <Col xs={24} className={styles.ContentCardDefinitionMnenonicWrapper}>
                                        <Icon type="book" className={styles.ContentCardIconStyleOverwrite} />
                                        {content.definition.mnemonicId}
                                    </Col>
                                    <Col xs={24} className={styles.ContentCardRepositoryNameWrapper}>
                                        <Icon type="appstore" className={styles.ContentCardIconStyleOverwrite} />
                                        {content.repository.name}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className={styles.ContentCardPublicationDateWrapper}>
                                        {content.onlineDate
                                            ? <div className={styles.ContentCardPublicationDateContainer}>
                                                <Icon
                                                    type="calendar"
                                                    className={styles.ContentCardIconStyleOverwrite}
                                                />
                                                {moment(content.onlineDate).format(DATE_TIME_FORMAT)}
                                            </div>
                                            : null
                                        }
                                    </Col>
                                    <Col xs={12} className={styles.ContentCardPublicationDateWrapper}>
                                        {content.offlineDate
                                            ? <div className={styles.ContentCardPublicationDateContainer}>
                                                <Icon
                                                    type="calendar"
                                                    className={styles.ContentCardIconStyleOverwrite}
                                                />
                                                {moment(content.offlineDate).format(DATE_TIME_FORMAT)}
                                            </div>
                                            : null
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        {actions && <div className={styles.ContentCardActionsContainer}>{actions}</div>}
                    </div>
                </Card>
            </Col>
        );
    }
}

export default injectIntl(ContentCard);
