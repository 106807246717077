import React from 'react';
import { Form } from 'antd';
import Button from '@components/Button';
import { FormattedMessage } from 'react-intl';
import { ChangeStepContext, FieldSelectionStep } from '../../ContentDefinitionEditorDrawer';
import styles from './index.module.scss';
import { inject, observer } from 'mobx-react';
import { ContentDefinitionSchemaStoreModel } from '@stores/ContentDefinitionEditorStore/ContentDefinitionSchemaStore';
import { FragmentsTable } from '@contentchef/contentchef-types';
import Segment from '../../../Segment/Segment';
import
withDefinitionErrors,
{ WithDefinitionErrorsReturnProps }
    from '../../../../hoc/withDefinitionErrors/withDefinitionErrors';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import {
    withPermissionsToInteract,
    WithPermissionsToInteractReturnType
} from '@providers/UserPermissionsProvider';

interface FragmentNamesProps extends WithDefinitionErrorsReturnProps, InjectedIntlProps,
    WithPermissionsToInteractReturnType {
    value?: string[];
}

interface InjectedProps extends FragmentNamesProps {
    contentDefinitionSchemaStore: ContentDefinitionSchemaStoreModel;
}

const retrieveFriendlyFragmentNames = (fragments: FragmentsTable, selectedFragments: string[], defaultLang: string) => {
    return selectedFragments
        .map(fragmentName => {
            const selectedFragment = fragments[fragmentName];
            if (selectedFragment.labels && selectedFragment.labels[defaultLang]) {
                return selectedFragment.labels[defaultLang];
            }
            return fragmentName;
        })
        .join(', ');
};

function FragmentNames(props: FragmentNamesProps) {
    const injected = props as InjectedProps;
    const { fragments, lang } = injected.contentDefinitionSchemaStore.schema;
    return (
        <Segment
            divider={true}
            title={<FormattedMessage id="FragmentNames.Constraint.Label" defaultMessage="Custom field names" />}
            titleVariant="label"
        >
            <Form.Item
                help={props.hasErrors ? props.errors.map(e => props.intl.formatMessage(e)).join(', ') : undefined}
                validateStatus={props.hasErrors ? 'error' : undefined}
                style={{ width: '100%' }}
            >
                <span className={styles.ValueWrapper}>
                    {!!props.value && props.value.length > 0
                        ? <FormattedMessage
                            id="FragmentNames.Constraint.Selected.Message"
                            /* tslint:disable-next-line:max-line-length */
                            defaultMessage="Field allows custom field of {count, plural, one {type} other {types}}: {names}"
                            values={{
                                count: props.value.length,
                                names: retrieveFriendlyFragmentNames(fragments, props.value, lang)
                            }}
                        />
                        : <FormattedMessage
                            id="FragmentNames.Constraint.NotSelected.Message"
                            defaultMessage="No custom fields set"
                        />
                    }
                </span>
                <span>
                    <ChangeStepContext.Consumer>
                        {context => (
                            <Button
                                disabled={!props.hasPermissions}
                                type="dashed"
                                icon={!!props.value && props.value.length > 0 ? 'edit' : 'plus'}
                                onClick={() => context.changeActiveStep(FieldSelectionStep.SelectCustomFields)}
                            >
                                {!!props.value && props.value.length > 0
                                    ? <FormattedMessage
                                        id="FragmentNames.Constraint.Selected.Button"
                                        defaultMessage="Update"
                                    />
                                    : <FormattedMessage
                                        id="FragmentNames.Constraint.NotSelected.Button"
                                        defaultMessage="Add"
                                    />
                                }
                            </Button>
                        )}
                    </ChangeStepContext.Consumer>
                </span>
            </Form.Item>
        </Segment>
    );
}

export default withPermissionsToInteract(withDefinitionErrors(
    inject('contentDefinitionSchemaStore')(observer(injectIntl(FragmentNames)))
));
