import AsideHeader from '@components/AsideHeader/AsideHeader';
import Button from '@components/Button';
import ContentTranslationStatus from '@components/ContentTranslationStatus';
import { labels } from '@constants/content-translation-labels';
import withContentTranslationManager from '@hoc/withContentTranslationManager';
import withSpaceDetailStore from '@hoc/withSpaceDetailStore';
import ContentStatusStore from '@stores/contentStatusStore';
import { ContentTranslationManager } from '@stores/contentTranslation/ContentTranslationManager';
import { ExtensionManager } from '@stores/extensionManager';
import { SpaceDetailStoreModel } from '@stores/spaceDetailsStore/spaceDetailStoreModel';
import { Icon, Popconfirm } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Prompt, Redirect, RouteComponentProps } from 'react-router';
import {
    Aside,
    AsideItem, ContainerWithAside, EditContentForm, FormActions, Loader
} from '../../../../components';
import {
    NOTIFICATION_DEFAULT_MESSAGES,
    NOTIFICATION_KEY_CONSTANTS
} from '../../../../constants/notifications-constants';
import { contentNotFoundPath, editContentPath } from '../../../../constants/routing-constants';
import { userPermissionsTable } from '../../../../constants/user-permissions-table';
import { withContentEditStores, withFormInitialization } from '../../../../hoc';
import { ManageFieldProvider } from '../../../../providers/UserPermissionsProvider';
import { DataAttributes, DataAttributesBuilder } from '../../../../services/DataAttributesBuilder';
import {
    createCustomNotification,
    createErrorNotification,
    createInfoNotification,
    createSuccessNotification
} from '../../../../services/Notification';
import { EditContentStoreModel } from '../../../../stores/editContentStore/editContentStoreModel';
import { FormMetaStoreModel } from '../../../../stores/formMetaStore/formMetaStoreModel';
import { NotificationStoreModel } from '../../../../stores/notificationStore/notificationStoreModel';

export interface EditContentProps extends
    RouteComponentProps<{ contentId: string, spaceId: string, locale: string }>, InjectedIntlProps { }

export interface EditContentState {
    openDrawer: boolean;
    loading: boolean;
    cleanTransLoading: boolean;
}

export interface InjectedProps extends EditContentProps {
    formMetaStore: FormMetaStoreModel;
    editContentStore: EditContentStoreModel;
    notificationStore: NotificationStoreModel;
    contentStatusStore: ContentStatusStore;
    extensionManager: ExtensionManager;
    contentTranslationManager: ContentTranslationManager;
    spaceDetailStore: SpaceDetailStoreModel;
}

@inject(
    'editContentStore',
    'formMetaStore',
    'notificationStore',
    'contentStatusStore',
    'extensionManager',
    'contentTranslationManager',
    'spaceDetailStore'
)
@observer
class EditContent extends Component<EditContentProps, EditContentState> {

    get injected() {
        return this.props as InjectedProps;
    }

    constructor(props: EditContentProps) {
        super(props);

        this.state = {
            openDrawer: false,
            loading: true,
            cleanTransLoading: false,
        };
    }

    componentWillUnmount() {
        const { notificationStore } = this.injected;
        const { notifications } = notificationStore;
        this.injected.formMetaStore.unsetGenericErrors();
        if (notifications.includes(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST)) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST);
        }

        this.injected.extensionManager.destroy();
    }

    async componentDidMount() {
        await this.initializeEditContent();
    }

    async componentDidUpdate(prevProps: EditContentProps) {
        if (((prevProps.location.search !== this.props.location.search) ||
            (prevProps.location.pathname !== this.props.location.pathname))
        ) {
            await this.initializeEditContent();
        }
    }

    initializeEditContent = async () => {
        this.setState({ loading: true });
        const { contentId, locale } = this.props.match.params;
        await Promise.all([
            this.injected.editContentStore.initializeEditContent(contentId, true),
            this.injected.contentStatusStore.setContentStatus(+contentId),
            this.injected.spaceDetailStore.setSpaceLocale()
        ]);
        const { editContentData: { initialContentData } } = this.injected.editContentStore;
        if (initialContentData) {
            await this.injected.contentTranslationManager.init(initialContentData.locale, locale, initialContentData);
        }
        this.setState({ loading: false });
    }

    onSubmitForm = async () => {
        const { notificationStore } = this.injected;
        const { formatMessage } = this.props.intl;
        const { locale } = this.props.match.params;
        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST,
            formatMessage,
            NOTIFICATION_DEFAULT_MESSAGES.updateAction
        );
        try {
            await this.injected.editContentStore.updateTranslations(
                locale, this.injected.contentTranslationManager.getFieldTranslations()
            );
            // this.injected.contentStatusStore.setContentStatus(+contentId);
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST);
            createSuccessNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.updateAction
            );
            await this.initializeEditContent();
        } catch (e: any) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST);
            e.handleError(formatMessage, NOTIFICATION_DEFAULT_MESSAGES.updateAction);
        }
    }

    onSubmitFormErrors = (errors: any) => {
        this.injected.formMetaStore.setClientSideErrors(errors);
    }

    confirmToggleArchive = async (archive: boolean) => {
        const { notificationStore } = this.injected;
        const { formatMessage } = this.props.intl;
        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST,
            formatMessage,
            archive
                ? NOTIFICATION_DEFAULT_MESSAGES.archiveAction
                : NOTIFICATION_DEFAULT_MESSAGES.unarchiveAction
        );
        try {
            await this.injected.editContentStore.toggleArchiveContent(archive);
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST);
            createSuccessNotification(
                formatMessage,
                archive
                    ? NOTIFICATION_DEFAULT_MESSAGES.archiveAction
                    : NOTIFICATION_DEFAULT_MESSAGES.unarchiveAction
            );
        } catch (e) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST);
            createErrorNotification(
                formatMessage,
                archive
                    ? NOTIFICATION_DEFAULT_MESSAGES.archiveAction
                    : NOTIFICATION_DEFAULT_MESSAGES.unarchiveAction
            );
        }
    }

    createDataAttributes = (contentDefinitionName: string): DataAttributes => {
        return new DataAttributesBuilder('repository')
            .setEditFormAttribute(contentDefinitionName)
            .build();
    }

    showContentDetail = () => {
        this.setState({
            openDrawer: true
        });
    }

    hideContentDetail = () => {
        this.setState({
            openDrawer: false
        });
    }

    onAddTranslation = async (locales: string[]) => {
        const { intl: { formatMessage } } = this.props;
        try {
            const response = await this.injected.editContentStore.createTranslations(locales);
            return response;
        } catch (e) {
            createCustomNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.deleteTranslationError,
                'error'
            );
            return Promise.reject(e);
        }
    }

    onClickInfoTranslation = () => {
        if (window.supportHeroWidget) {
            window.supportHeroWidget.show({ article: '142196-content-i18n' });
        }
    }

    cleanTranslation = async () => {
        const { intl: { formatMessage } } = this.props;
        try {
            const { params: { locale } } = this.props.match;
            this.setState({ cleanTransLoading: true });
            await this.injected.editContentStore.cleanTranslation([locale]);
            this.setState({ cleanTransLoading: false });
            await this.initializeEditContent();
            return;
        } catch (error) {
            createCustomNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.cleanTranslationError,
                'error'
            );
            return Promise.reject(error);
        }
    }

    render() {
        const {
            name, repository, publicId, onlineRange, tags, channels, dynamicFields
        } = this.injected.formMetaStore.formMeta;
        const { editContentData: { initialContentData },
            toggleContentTranslations, toggleContentTranslationsLoader } = this.injected.editContentStore;
        const { notifications } = this.injected.notificationStore;
        const { formatMessage } = this.props.intl;
        const { translationNeedsToBeSaved } = this.injected.contentTranslationManager;
        const { match: { params: { spaceId, contentId } } } = this.props;
        return !this.state.loading ? (
            !!initialContentData ?
                (
                    <ContainerWithAside
                        dataAttributes={this.createDataAttributes(initialContentData.name).Attributes}
                        disableContent={
                            notifications.includes(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST)
                        }
                        renderAside={() => (
                            <Aside>
                                <AsideItem>
                                    <FormActions
                                        onSubmit={this.onSubmitForm}
                                        onSubmitFormErrors={this.onSubmitFormErrors}
                                        permissions={userPermissionsTable.FormActions.content}
                                        underPermissionButtons={[
                                            <Popconfirm
                                                key="clean_translation"
                                                title={formatMessage(labels.confirmClean)}
                                                onConfirm={this.cleanTranslation}
                                                getPopupContainer={() => document.body}
                                            >
                                                <Button
                                                    key="clean_translation"
                                                    id="clean_translation"
                                                    type="danger"
                                                    block={true}
                                                    loading={this.state.cleanTransLoading}
                                                >
                                                    <FormattedMessage
                                                        id="EditContentTranslations.FormActions.clean"
                                                        defaultMessage="Clean"
                                                    />
                                                </Button>
                                            </Popconfirm>
                                        ]}
                                        goBackTo={editContentPath(spaceId, parseInt(contentId, 10))}
                                    />
                                </AsideItem>
                                <AsideItem
                                    key="content_translation_status"
                                    header={
                                        <AsideHeader
                                            label={formatMessage(labels.formSidebarHeader)}
                                            icon={
                                                <Icon
                                                    type="info-circle"
                                                    theme="filled"
                                                    onClick={this.onClickInfoTranslation}
                                                />
                                            }
                                        />
                                    }
                                >
                                    <ContentTranslationStatus
                                        changeLocSetting={toggleContentTranslations}
                                        loading={toggleContentTranslationsLoader}
                                        locales={
                                            this.injected.spaceDetailStore
                                                .retrieveSupportedLocales()}
                                        contentLocale={initialContentData.locale}
                                        translations={this.injected.contentTranslationManager.contentTranslations}
                                        onAddTranslation={this.onAddTranslation}
                                        locSetting={initialContentData.contentLocalizationSettings!}
                                        hideSelection={true}
                                        fallback={this.injected.spaceDetailStore.spaceLocale.fallbackLocale}
                                    />
                                </AsideItem>
                            </Aside>
                        )}
                    >
                        <ManageFieldProvider
                            permissions={userPermissionsTable.Forms.AddContentForm}
                        >
                            <Prompt
                                when={translationNeedsToBeSaved}
                                message={() =>
                                    this.props.intl.formatMessage(labels.blockRouteChange)
                                }
                            />
                            <EditContentForm
                                fieldName={name}
                                fieldRepository={repository}
                                fieldPublicId={publicId}
                                fieldOnlineRange={onlineRange}
                                fieldTags={tags}
                                fieldChannels={channels}
                                dynamicFields={dynamicFields}
                            />
                        </ManageFieldProvider>
                    </ContainerWithAside>
                ) : (
                    <Redirect to={contentNotFoundPath(this.props.match.params.spaceId)} />
                )
        ) : <Loader />;
    }
}

export default withContentEditStores(
    withContentTranslationManager(
        withSpaceDetailStore(
            withFormInitialization(
                injectIntl(EditContent)
            )
        )
    )
);
