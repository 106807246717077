export const isInViewport = (elem: HTMLDivElement) => {
    var bounding = elem.getBoundingClientRect();
    return (
        bounding.top + (5 * (elem.clientHeight)) >= 0 &&
        bounding.left >= 0 &&
        (bounding.bottom - (5 * (elem.clientHeight)))
        <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};
