import React, { Component } from 'react';
import classes from './index.module.scss';
import { RouteComponentProps } from 'react-router';
import { SubscriptionType, Interval } from '@contentchef/contentchef-types';
import { Col } from 'antd';
import Button from '@components/Button';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { Elements, StripeProvider } from 'react-stripe-elements';
import UpgradePlanForm from '@components/UpgradePlanForm';
import Paper from '@components/Paper';
import { labels } from '@services/subscriptions/labels';
import { Toggle } from '@components/Toggle';
import PlanCard from '@components/Plan/PlanCard/Plan';
import { toPlanInterval, getSubscriptionPlanProps } from '@services/plans';
import { stripeKey } from '@services/payments/StripeConfig';

interface SubscriptionUpgradeProps extends
    RouteComponentProps<{ subscriptionId: string, planId: string }>,
    InjectedIntlProps { }

interface SubscriptionUpgradeState {
    isWaitingConfirmResult: boolean;
    interval: Interval;
}

class SubscriptionUpgrade extends Component<SubscriptionUpgradeProps, SubscriptionUpgradeState> {

    state = {
        isWaitingConfirmResult: false,
        interval: Interval.year
    };

    handleContactUsForEnterprise = () => {
        this.setState({
            isWaitingConfirmResult: true,
        });
    }

    clearQueryParams = () => {
        this.props.history.push(this.props.location.pathname);
    }

    changeInterval = (value: Interval) => {
        this.clearQueryParams();
        this.setState({ interval: value });
    }

    render() {
        const { match: { params: { planId } }, intl } = this.props;

        const { isWaitingConfirmResult, interval } = this.state;

        const isEnterprise = parseInt(planId, 10) as SubscriptionType === SubscriptionType.Enterprise;
        return (
            <StripeProvider apiKey={stripeKey()}>
                <div className={classes.SubscriptionDetailPlanContent}>
                    {
                        !isEnterprise &&
                        <Toggle
                            left={{ value: Interval.month, label: intl.formatMessage(labels.monthlyLabel) }}
                            right={{ value: Interval.year, label: intl.formatMessage(labels.yearlyLabel) }}
                            selected={interval}
                            onChange={this.changeInterval}
                        />
                    }
                    <Col xs={24} md={0}>
                        <Paper className={classes.SubscriptionUpgradeContainer}>
                            <Col xs={24} className={classes.SubscriptionDetailPlanContentRiseAbovePlan}>
                                <PlanCard
                                    plan={getSubscriptionPlanProps(parseInt(planId, 10), intl)}
                                    paymentInterval={toPlanInterval(interval)}
                                    highlighted={true}
                                />
                            </Col>
                        </Paper>
                        <Paper className={classes.SubscriptionUpgradeContainer}>
                            <Col xs={24} className={classes.SubscriptionDetailPlanContentRiseAboveContent}>
                                {
                                    isEnterprise &&
                                    (
                                        <React.Fragment>
                                            <section>
                                                <p>
                                                    {intl.formatMessage(labels.upgradeConfirmViewEnterpriseText1)}
                                                </p>
                                                <p>
                                                    {intl.formatMessage(labels.upgradeConfirmViewEnterpriseText2)}
                                                </p>
                                            </section>
                                            <section>
                                                <Button
                                                    className={classes.SubscriptionDetailPlanContentRiseAboveButton}
                                                    disabled={isWaitingConfirmResult}
                                                    loading={isWaitingConfirmResult}
                                                    onClick={this.handleContactUsForEnterprise}
                                                    type="primary"
                                                >
                                                    {intl.formatMessage(
                                                        labels.upgradeConfirmViewActionEnterpriseConfirm)}
                                                </Button>
                                            </section>
                                        </React.Fragment>
                                    )
                                }
                                {
                                    !isEnterprise && (
                                        <section>
                                            <Elements>
                                                <UpgradePlanForm interval={interval} />
                                            </Elements>
                                        </section>
                                    )
                                }
                            </Col>
                        </Paper>
                    </Col>
                    <Col xs={0} md={24} lg={20}>
                        <Paper className={classes.SubscriptionUpgradeContainer}>
                            <Col md={12} className={classes.SubscriptionDetailPlanContentRiseAbovePlan}>
                                <PlanCard
                                    className={classes.PlanCardInUpgrade}
                                    plan={getSubscriptionPlanProps(parseInt(planId, 10), intl)}
                                    paymentInterval={toPlanInterval(interval)}
                                    highlighted={true}
                                />
                            </Col>

                            <Col md={12} className={classes.SubscriptionDetailPlanContentRiseAboveContent}>
                                {
                                    isEnterprise &&
                                    (
                                        <React.Fragment>
                                            <section>
                                                <p>
                                                    {intl.formatMessage(labels.upgradeConfirmViewEnterpriseText1)}
                                                </p>
                                                <p>
                                                    {intl.formatMessage(labels.upgradeConfirmViewEnterpriseText2)}
                                                </p>
                                            </section>
                                            <section>
                                                <Button
                                                    className={classes.SubscriptionDetailPlanContentRiseAboveButton}
                                                    disabled={isWaitingConfirmResult}
                                                    loading={isWaitingConfirmResult}
                                                    onClick={this.handleContactUsForEnterprise}
                                                    type="primary"
                                                >
                                                    {intl.formatMessage(
                                                        labels.upgradeConfirmViewActionEnterpriseConfirm)}
                                                </Button>
                                            </section>
                                        </React.Fragment>
                                    )
                                }
                                {
                                    !isEnterprise && (
                                        <section>
                                            <Elements>
                                                <UpgradePlanForm
                                                    interval={interval}
                                                />
                                            </Elements>
                                        </section>
                                    )
                                }
                            </Col>
                        </Paper>
                    </Col>
                </div>
            </StripeProvider>
        );
    }
}

export default injectIntl(SubscriptionUpgrade);
