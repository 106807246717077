export const sceneContainerDimensions: SceneContainerDimensionsModel = {
    columns: {
        xs: {
            span: 24,
        },
        sm: {
            span: 24,
        },
        md: {
            span: 24
        },
        lg: {
            span: 24
        },
        xl: {
            span: 24
        },
        xxl: {
            span: 24
        }
    },
    padding: {
        top: 40,
    },
};

export const layoutSizes: LayoutSizesModel = {
    topNavHeight: 64,
};

export const menuSideBarSizes: MenuSideBarSizesModel = {
    sideBarDefaultWidth: 200,
    sideBarCollapsedWidth: 64,
    collapseBreakPoint: 768,
    collapsedSmallScreen: 0,
};

interface SceneContainerDimensionsModel {
    columns: ColumnsDimensionsModel;
    padding: PaddingDimensionsModel;
}

interface LayoutSizesModel {
    topNavHeight: number;
}

interface MenuSideBarSizesModel {
    sideBarDefaultWidth: number;
    sideBarCollapsedWidth: number;
    collapseBreakPoint: number;
    collapsedSmallScreen: number;
}

interface ColumnsDimensionsModel {
    xs: DimensionsModel;
    sm: DimensionsModel;
    md: DimensionsModel;
    lg: DimensionsModel;
    xl: DimensionsModel;
    xxl: DimensionsModel;
}

interface DimensionsModel {
    span: number;
    offset?: number;
}

interface PaddingDimensionsModel {
    top: number;
}
