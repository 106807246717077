import React, { Component } from 'react';
import { Provider, inject, observer } from 'mobx-react';
import { WithStoresComponentProps, WithStoresWrappedComponent } from '../../constants/common-types';
import { notificationStore, PublishToLiveStore } from '../../stores';
import { PublishToLiveStoreModel } from '../../stores/publishToLiveStore/publishToLiveStoreModel';
import { NotificationStoreModel } from '../../stores/notificationStore/notificationStoreModel';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';

type InjectedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
};

const withPublishToLiveStores = <T extends {}>(WrappedComponent: WithStoresWrappedComponent<T>) => {
    @inject('apiStore')
    @observer
    class WithPublishToLiveStores extends Component<WithStoresComponentProps<T>> {
        get injected() {
            return this.props as InjectedProps<T>;
        }
        notificationStore: NotificationStoreModel;
        publishToLiveStore: PublishToLiveStoreModel;
        constructor(props: WithStoresComponentProps<T>) {
            super(props);

            const { api } = this.injected.apiStore;

            this.publishToLiveStore = new PublishToLiveStore(api);
            this.notificationStore = notificationStore;
        }
        render() {
            return (
                <Provider
                    publishToLiveStore={this.publishToLiveStore}
                    notificationStore={this.notificationStore}
                >
                    <WrappedComponent {...this.props} />
                </Provider>
            );
        }
    }

    return WithPublishToLiveStores;
};

export default withPublishToLiveStores;
