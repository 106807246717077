import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { ChooseSpace, SpaceBasedRoutes } from '../';
import {
    CHOOSE_SPACE_ROUTE,
    ONBOARDING_WIZARD_ROUTE,
    ROOT_ROUTE,
    SETTINGS_BASED_ROUTE,
    SPACE_ROUTE,
    ACCEPT_INVITE_ROUTE,
    ONBOARDING_UPDATE_CONSENTS_ROUTE,
    ROOT_SUBSCRIPTION_PATH
} from '../../constants/routing-constants';
import SettingsBasedRoutes from '../SettingsBasedRoutes';
import { BaseRoutingProps } from './BaseRoutingModel';
import SubscriptionCreationWizard from '../../scenes/SubscriptionCreationWizard';
import AcceptInvite from '../../scenes/AcceptInvite';
import UpdateConsents from '../Onboarding/UpdateConsents';
import SubscriptionBasedRoutes from '../SubscriptionBasedRoutes';

class BaseRouting extends Component<BaseRoutingProps> {
    render() {
        return (
            <Switch>
                <Route
                    path={SPACE_ROUTE}
                    component={SpaceBasedRoutes}
                />
                <Route
                    path={ROOT_SUBSCRIPTION_PATH}
                    component={SubscriptionBasedRoutes}
                />
                <Route
                    path={CHOOSE_SPACE_ROUTE}
                    component={ChooseSpace}
                />
                <Route
                    path={SETTINGS_BASED_ROUTE}
                    component={SettingsBasedRoutes}
                />
                <Route
                    component={AcceptInvite}
                    exact={true}
                    path={ACCEPT_INVITE_ROUTE}
                />
                <Route
                    component={SubscriptionCreationWizard}
                    exact={true}
                    path={ONBOARDING_WIZARD_ROUTE}
                />
                <Route
                    component={UpdateConsents}
                    exact={true}
                    path={ONBOARDING_UPDATE_CONSENTS_ROUTE}
                />
                <Redirect
                    from={ROOT_ROUTE}
                    to={CHOOSE_SPACE_ROUTE}
                />
            </Switch>
        );
    }
}

export default BaseRouting;
