export const AWSConfiguration = {
    Region: process.env.Region as string,
    IdentityPoolId: process.env.IdentityPoolId as string,
    ApiBaseUrl: process.env.RestApiAddress as string,
    UserPoolId: process.env.UserPoolId as string,
    ClientId: process.env.UserPoolClientId as string,
    AppWebDomain: process.env.AppWebDomain as string,
    TokenScopesArray: ['email', 'openid'],
    RedirectUriSignIn:
        process.env.NODE_ENV === 'development'
            ? 'http://localhost:3000'
            : process.env.RedirectUriSignIn as string,
    RedirectUriSignOut:
        process.env.NODE_ENV === 'development'
            ? 'http://localhost:3000'
            : process.env.RedirectUriSignOut as string
};
