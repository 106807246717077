import { Hub } from 'aws-amplify';
import { HubCapsule } from '@aws-amplify/core/lib/Hub';
import { history } from '../stores';
import ReactGA from 'react-ga';

enum AuthEvents {
    COGNITO_HOSTEDUI_FAILURE = 'cognitoHostedUI_failure',
    SIGNUP = 'signUp',
    SIGNIN = 'signIn',
}

export default function initAuthHub() {
    const listener = (data: HubCapsule) => {
        const { event } = data.payload;
        try {
            switch (event) {
                case AuthEvents.COGNITO_HOSTEDUI_FAILURE:
                    history.replace('/?google_error=authentication_error');
                    break;
                case AuthEvents.SIGNIN:
                    const { username: sub } = data.payload.data;
                    ReactGA.set({ sub });
                    break;
                case AuthEvents.SIGNUP:
                    const { user: { username }, userSub } = data.payload.data;
                    ReactGA.set({ email: username, sub: userSub });
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    };

    Hub.listen('auth', listener);
}
