import React from 'react';
import styles from './Statistic.module.scss';

const component: React.SFC<{
    title: string | React.ReactNode,
    value: number | React.ReactNode,
    suffix?: string | React.ReactNode
}> = ({ title, value, suffix }) => {
    return (
        <div className={styles.statistic}>
            <div>
                <span>{value}</span>
                {suffix && <span className={styles.suffix}> {suffix}</span>}
            </div>
            <label className={styles.title} >{title}</label>
        </div>
    );
};

export default component;
