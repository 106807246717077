import { Collapse } from 'antd';
import React, { Component } from 'react';
import styles from './Aside.module.scss';
import { AsideProps, AsideState } from './AsideModel';

class Aside extends Component<AsideProps, AsideState> {
    constructor(props: AsideProps) {
        super(props);

        this.state = {
            activeKeys: props.defaultActiveKeys || []
        };
    }

    onChangeActivePanel = (keysToActivate: string | string[]) => {
        const { defaultActiveKeys } = this.props;
        if (defaultActiveKeys) {
            const mergedKeys = defaultActiveKeys.concat(keysToActivate);
            const activeKeys = mergedKeys.filter((item: string, index: number) => mergedKeys.indexOf(item) === index);
            this.setState({
                activeKeys
            });
        } else {
            this.setState({
                activeKeys: keysToActivate as string[]
            });
        }
    }

    render() {
        const { children, defaultActiveKeys } = this.props;
        return (
            <Collapse
                defaultActiveKey={defaultActiveKeys}
                onChange={this.onChangeActivePanel}
                className={styles.AsideDefault}
                bordered={false}
            >
                {children}
            </Collapse>
        );
    }
}

export default Aside;
