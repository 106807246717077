import { Form, Row } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { DynamicContentField, MissingPermissions } from '../..';
import styles from './AddContentForm.module.scss';
import { AddContentFormProps, AddContentFormState, InjectedProps } from './AddContentFormModel';
import StaticContentField from '../StaticContentField/StaticContentField';
import { withForm } from '../../../hoc';
import { withPermissionsToInteract } from '../../../providers/UserPermissionsProvider';

@inject('addContentStore')
@observer
class AddContentForm extends Component<AddContentFormProps, AddContentFormState>  {
    get injected() {
        return this.props as InjectedProps;
    }

    createDynamicFields = () => {
        const { fields } = this.props.dynamicFields;
        const fieldsToRender: JSX.Element[] = [];
        for (const key in fields) {
            if (fields.hasOwnProperty(key)) {
                const fieldData = fields[key];
                if (fieldData) {
                    fieldsToRender.push(
                        <DynamicContentField
                            key={fieldData.id}
                            genericFieldMeta={fieldData}
                        />
                    );
                }
            }
        }
        return fieldsToRender;
    }

    render() {
        const {
            fieldName,
            fieldRepository,
            fieldPublicId,
            fieldOnlineRange,
            fieldTags,
            fieldChannels,
            hasPermissions
        } = this.props;
        const { contentDefinition } = this.injected.addContentStore.addContentData;
        if (!hasPermissions) {
            return (
                <MissingPermissions />
            );
        }
        return (
            contentDefinition && (
                <div id={contentDefinition.id.toString()} className={styles.AddFormContainer}>
                    <Form data-id="add_form">
                        <Row type="flex" justify="center" className={styles.AddContentFormWrapper}>
                            <StaticContentField
                                contentCreationFlow={true}
                                definition={contentDefinition}
                                fieldName={fieldName}
                                fieldRepository={fieldRepository}
                                fieldPublicId={fieldPublicId}
                                fieldOnlineRange={fieldOnlineRange}
                                fieldTags={fieldTags}
                                fieldChannels={fieldChannels}
                            />
                            {this.createDynamicFields()}
                        </Row>
                    </Form>
                </div>
            )
        );
    }
}

export default withPermissionsToInteract(withForm(injectIntl(AddContentForm)));
