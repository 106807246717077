import { Modal } from 'antd';
import { autorun } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Redirect } from 'react-router';
import {
    AddContentForm,
    Aside,
    AsideItem, ContainerWithAside, FormActions
} from '../../../components';
import { NOTIFICATION_DEFAULT_MESSAGES, NOTIFICATION_KEY_CONSTANTS } from '../../../constants/notifications-constants';
import {
    contentSelectDefinitionPath, definitionNotFoundPath
} from '../../../constants/routing-constants';
import { userPermissionsTable } from '../../../constants/user-permissions-table';
import { withContentCreationStores, withFormInitialization, WithLoadingData } from '../../../hoc';
import { ArchivedContentProvider } from '../../../providers/ArchivedContentProvider';
import { ManageFieldProvider } from '../../../providers/UserPermissionsProvider';
import { DataAttributes, DataAttributesBuilder } from '../../../services/DataAttributesBuilder';
import {
    createInfoNotification,
    createSuccessNotification
} from '../../../services/Notification';
import { CreateContentProps, CreateContentState, InjectedProps } from './CreateContentModel';

const CreateContentLabels = defineMessages({
    definitionNotFoundModalTitle: {
        id: 'CreateContent.ModalTitle',
        defaultMessage: 'Content Definition Not Found',
    },
    definitionNotFoundModalOkButton: {
        id: 'CreateContent.ModalOkButton',
        defaultMessage: 'Select Content Definition',
    }
});

@inject('addContentStore', 'formMetaStore', 'notificationStore', 'extensionManager')
@observer
class CreateContent extends Component<CreateContentProps, CreateContentState> {
    modalInstance: { destroy(): void } | undefined;

    openErrorModal = autorun(
        () => {
            const { formatMessage } = this.props.intl;
            const { genericErrors } = this.injected.formMetaStore;
            if (genericErrors.contentDefinitionNotFound) {
                const { spaceId } = this.props.match.params;
                this.modalInstance = Modal.error({
                    title: formatMessage(CreateContentLabels.definitionNotFoundModalTitle),
                    okText: formatMessage(CreateContentLabels.definitionNotFoundModalOkButton),
                    keyboard: false,
                    onOk: () => this.props.history.push(contentSelectDefinitionPath(spaceId)),
                    content: this.injected.intl.formatMessage(genericErrors.contentDefinitionNotFound)
                });
            }
        }
    );

    get injected() {
        return this.props as InjectedProps;
    }

    componentWillUnmount() {
        const { notificationStore, formMetaStore } = this.injected;
        const { notifications } = notificationStore;
        formMetaStore.unsetGenericErrors();
        this.openErrorModal();
        if (this.modalInstance) {
            this.modalInstance.destroy();
        }
        if (notifications.includes(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST)) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST);
        }
        this.injected.extensionManager.destroy();
    }

    initializeAddContent = () => {
        const { contentDefId } = this.props.match.params;
        return this.injected.addContentStore.initializeAddContent(contentDefId);
    }

    onSubmitForm = async () => {
        const { notificationStore } = this.injected;
        const { formatMessage } = this.props.intl;
        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST,
            formatMessage,
            NOTIFICATION_DEFAULT_MESSAGES.createAction
        );
        try {
            await this.injected.addContentStore.saveContent();
            createSuccessNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.createAction
            );
        } catch (e: any) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST);
            e.handleError(formatMessage, NOTIFICATION_DEFAULT_MESSAGES.createAction);
        }
    }

    onSubmitFormErrors = (errors: any) => {
        this.injected.formMetaStore.setClientSideErrors(errors);
    }

    createDataAttributes = (contentDefName: string): DataAttributes => {
        return new DataAttributesBuilder('repository')
            .setAddFormAttribute(contentDefName)
            .build();
    }

    render() {
        const {
            name, repository, publicId, onlineRange, tags, channels, dynamicFields
        } = this.injected.formMetaStore.formMeta;
        const { contentDefinition } = this.injected.addContentStore.addContentData;
        const { notifications } = this.injected.notificationStore;
        return (
            <WithLoadingData
                promise={this.initializeAddContent}
            >
                {contentDefinition ?
                    (
                        <ArchivedContentProvider archived={false}>
                            <ContainerWithAside
                                dataAttributes={this.createDataAttributes(contentDefinition.name).Attributes}
                                disableContent={
                                    notifications.includes(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST)
                                }
                                renderAside={() => (
                                    <Aside>
                                        <AsideItem>
                                            <FormActions
                                                onSubmit={this.onSubmitForm}
                                                onSubmitFormErrors={this.onSubmitFormErrors}
                                                permissions={userPermissionsTable.FormActions.content}
                                            />
                                        </AsideItem>
                                    </Aside>
                                )}
                            >
                                <ManageFieldProvider permissions={userPermissionsTable.Forms.AddContentForm}>
                                    <AddContentForm
                                        fieldName={name}
                                        fieldRepository={repository}
                                        fieldPublicId={publicId}
                                        fieldOnlineRange={onlineRange}
                                        fieldTags={tags}
                                        fieldChannels={channels}
                                        dynamicFields={dynamicFields}
                                    />
                                </ManageFieldProvider>
                            </ContainerWithAside>
                        </ArchivedContentProvider>
                    ) : (
                        <Redirect to={definitionNotFoundPath(this.props.match.params.spaceId)} />
                    )
                }
            </WithLoadingData >
        );
    }
}

export default withContentCreationStores(withFormInitialization(injectIntl(CreateContent)));
