import React from 'react';
import { LabelWithHintProps } from './LabelWithHintModel';
import { Icon, Tooltip } from 'antd';

import styles from './LabelWithHint.module.scss';

const LabelWithHint: React.ComponentType<LabelWithHintProps> = (props: LabelWithHintProps) => {
    const { label, labelClassName, hint } = props;
    const classesName: string = labelClassName
        ? `${styles.LabelWithHintLabel} ${labelClassName}`
        : `${styles.LabelWithHintLabel}`;
    return (
        <span
            className={styles.LabelWithHintWrapper}
        >
            <p className={classesName}>{label}</p>
            <Tooltip title={hint}>
                <Icon
                    className={styles.LabelWithHintIcon}
                    type="info-circle"
                    theme="outlined"
                />
            </Tooltip>
        </span>
    );
};

export default LabelWithHint;
