import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { defineMessages } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import { createContentDefinitionPath } from '../../../constants/routing-constants';
import {
    ContentDefinitionsFooter,
    FloatingActions,
    ContentDefinitionList,
    ContainerWithAside,
    DefinitionListFilters
} from '../../../components';
import { withContentDefinitionsStores, WithLoadingData } from '../../../hoc';
import { DataAttributes, DataAttributesBuilder } from '../../../services/DataAttributesBuilder';
import { ContentDefinitionsProps, InjectedProps } from './ContentDefinitionsModel';
import {
    ContentDefinitionListUrlParams
} from '../../../stores/contentDefinitionListStore/contentDefinitionListStoreModel';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../../constants/tables';
import { HasPermissions } from '../../../providers/UserPermissionsProvider';
import { userPermissionsTable } from '../../../constants/user-permissions-table';
import AddButton from '../../../components/atoms/AddButton';

const labels = defineMessages({
    buttonTitle: {
        id: 'ContentDefinition.Add',
        defaultMessage: 'Add new content definition'
    }
});

@inject('contentDefinitionListStore')
@observer
class ContentDefinitions extends Component<ContentDefinitionsProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    getContentDefinitionsForRepo = (prevProps: RouteComponentProps) => {
        if (prevProps && prevProps.location.search.length > 0 && this.props.location.search.length === 0) {
            this.injected.contentDefinitionListStore.searchParams.reset();
        }
        return this.injected.contentDefinitionListStore.initContentDefinitionList();
    }

    createDefinitionViewAttributes = (): DataAttributes => {
        return new DataAttributesBuilder('content-definitions')
            .setListAttribute('content-definition-list-view')
            .build();
    }

    createDefinitionWrapperAttributes = (): DataAttributes => {
        return new DataAttributesBuilder('content-definitions')
            .setListAttribute('content-definition-list-wrapper')
            .build();
    }

    printCreateButton = () => {
        const { spaceId } = this.props.match.params;
        return (
            <Link to={createContentDefinitionPath(spaceId)}>
                <AddButton
                    dataId="btn-create-contentDefinition"
                    title={labels.buttonTitle}
                />
            </Link>
        );
    }

    render() {
        const { usableSearchParams = {}, urlSearchParams } = this.injected.contentDefinitionListStore.searchParams;
        return (
            <React.Fragment>
                <ContainerWithAside
                    drawerIcon={'filter'}
                    dataAttributes={this.createDefinitionWrapperAttributes().Attributes}
                    renderAside={() => <DefinitionListFilters />}
                    footer={<ContentDefinitionsFooter
                        currentSize={urlSearchParams.has(ContentDefinitionListUrlParams.bySize)
                            ? parseInt(usableSearchParams[ContentDefinitionListUrlParams.bySize], undefined)
                            : DEFAULT_PAGE_SIZE
                        }
                        currentPage={urlSearchParams.has(ContentDefinitionListUrlParams.byPage)
                            ? parseInt(usableSearchParams[ContentDefinitionListUrlParams.byPage], undefined)
                            : DEFAULT_PAGE
                        }
                    />}
                >
                    <WithLoadingData
                        promise={this.getContentDefinitionsForRepo}
                        broadcastLoading={true}
                        rerenderWhenRouteChange={true}
                    >
                        <ContentDefinitionList
                            isContentCreationFlow={false}
                            dataAttribute={this.createDefinitionViewAttributes}
                        />
                    </WithLoadingData>
                </ContainerWithAside>
                <HasPermissions permissions={userPermissionsTable.FloatingActions.ContentDefinition}>
                    <FloatingActions>
                        {this.printCreateButton()}
                    </FloatingActions>
                </HasPermissions>
            </React.Fragment>
        );
    }
}

export default withContentDefinitionsStores(ContentDefinitions);
