import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import {
    AccessTokenResponse,
    CreateApiAccessTokenResponse
} from '@contentchef/contentchef-types';
import { action, IObservableArray, observable, runInAction } from 'mobx';

type AccessTokenStoreLoading = {
    accessTokenListLoading: boolean;
    createAccessTokenLoading: boolean;
    revokeAccessTokenLoading: {
        [key: string]: boolean;
    };
};

class AccessTokenStore {
    protected api: ContentChefClient;
    @observable accessToken: CreateApiAccessTokenResponse | undefined = undefined;
    @observable accessTokenList: IObservableArray<AccessTokenResponse> = observable.array([]);
    @observable loaders: AccessTokenStoreLoading = {
        accessTokenListLoading: false,
        createAccessTokenLoading: false,
        revokeAccessTokenLoading: {}
    };

    constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action
    async setAccessTokenList() {
        this.loaders.accessTokenListLoading = true;
        this.accessTokenList.clear();
        try {
            const response = await this.api.accessToken.list({});
            runInAction(() => {
                this.accessTokenList.replace(response.tokens);
                response.tokens.map((token) => {
                    this.loaders.revokeAccessTokenLoading[token.tokenHash] = false;
                    return token;
                });
                this.loaders.accessTokenListLoading = false;
            });
        } catch (e) {
            console.log('Error while retrieving access token list', e);
            runInAction(() => {
                this.loaders.accessTokenListLoading = false;
            });
        }
    }

    @action
    async createAccessToken(keyRefName: string) {
        this.loaders.createAccessTokenLoading = true;
        try {
            const response = await this.api.accessToken.create({ keyReferenceName: keyRefName });
            await this.setAccessTokenList();
            return runInAction(() => {
                this.loaders.createAccessTokenLoading = false;
                return Promise.resolve(response);
            });
        } catch (e) {
            console.log('Error while creating access token', e);
            return runInAction(() => {
                this.loaders.createAccessTokenLoading = false;
                return Promise.reject(e);
            });
        }
    }

    @action
    async revokeAccessToken(tokenHash: string) {
        this.loaders.revokeAccessTokenLoading[tokenHash] = true;
        try {
            await this.api.accessToken.revoke({ tokenHash });
            await this.setAccessTokenList();
            runInAction(() => {
                this.loaders.revokeAccessTokenLoading[tokenHash] = false;
            });
        } catch (e) {
            console.log('Error while revoking access token', e);
            runInAction(() => {
                this.loaders.revokeAccessTokenLoading[tokenHash] = false;
            });
        }
    }
}

export default AccessTokenStore;
