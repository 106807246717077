import { NumberConstraints } from '../../FieldSerializer/fields/number';
import { FieldEditTabProps } from '../../types';
import { observer } from 'mobx-react';
import RequiredField from '../Constraints/RequiredField';
import React from 'react';
import { requiredMappedErrors } from '../../../../services/FormUtils/DefinitionFieldMappedErrors';

function ListOfNumberValuesConstraints(props: FieldEditTabProps<NumberConstraints>) {
    if (props.isArrayChildren) {
        return null;
    }
    return (
        <RequiredField
            relatedTabKey={'validationTab'}
            mappedErrors={requiredMappedErrors}
            onChange={required => props.onChangeConstraint('required', required)}
            value={props.constraints.required}
        />
    );
}

export default observer(ListOfNumberValuesConstraints);
