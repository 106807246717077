import { Modal } from 'antd';
import { Component } from 'react';
import { CloudinaryPreview, MediaStepper, ModalTitle } from '..';
import {
    CloudinaryImageFieldProps,
    CloudinaryImageFieldState,
} from './CloudinaryImageFieldModel';

import { CLOUDINARY_IMAGE } from '../../services/cloudinaryUtils';
import styles from './CloudinaryImageField.module.scss';

class CloudinaryImageField extends Component<CloudinaryImageFieldProps, CloudinaryImageFieldState> {

    constructor(props: CloudinaryImageFieldProps) {
        super(props);

        this.state = {
            visible: false,
            publicId: this.props.fieldData.value ? this.props.fieldData.value.publicId : '',
            transformations: this.props.fieldData.value ? this.props.fieldData.value.transformations : '',
        };

        this.onConfirmSelection = this.onConfirmSelection.bind(this);
        this.toggleImageEditor = this.toggleImageEditor.bind(this);
        this.onReset = this.onReset.bind(this);

    }

    onConfirmSelection(publicId: string, transformations: string) {
        this.setState({
            publicId,
            transformations
        });
        const cloudinaryImageValue = {
            publicId,
            transformations
        };
        this.props.onChange(cloudinaryImageValue);
        this.toggleImageEditor();
    }

    toggleImageEditor() {
        const { visible } = this.state;
        if (!visible) {
            this.setState({
                publicId: this.props.fieldData.value ? this.props.fieldData.value.publicId : '',
                transformations: this.props.fieldData.value ? this.props.fieldData.value.transformations : '',
                visible: !visible
            });
        } else {
            this.setState({
                visible: !visible
            });
        }
    }

    onReset() {
        const resetImage = {
            publicId: '',
            transformations: ''
        };
        this.setState(resetImage);
        this.props.onChange(undefined);
    }

    render() {
        const { id, disableActions } = this.props;
        const { publicId, transformations } = this.state;
        const cloudinaryImageValue = {
            publicId,
            transformations
        };

        return (
            <div id={id}>
                <CloudinaryPreview
                    disableActions={disableActions}
                    value={cloudinaryImageValue}
                    type={CLOUDINARY_IMAGE}
                    toggleImageEditor={this.toggleImageEditor}
                    onReset={this.onReset}
                />
                <Modal
                    title={
                        <ModalTitle onCancel={this.toggleImageEditor}>
                            {this.props.fieldData.id}
                        </ModalTitle>}
                    closable={false}
                    visible={this.state.visible}
                    wrapClassName={styles.CloudinaryImageModalPreview}
                    destroyOnClose={true}
                    footer={false}
                >
                    <MediaStepper
                        field={this.props.fieldData}
                        type={CLOUDINARY_IMAGE}
                        onConfirmSelection={this.onConfirmSelection}
                    />
                </Modal>
            </div>
        );
    }
}

export default CloudinaryImageField;
