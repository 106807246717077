import React from 'react';
import { Switch, Route } from 'react-router';
import { USERS_ROUTE } from '../../../constants/routing-constants';
import UsersList from './UsersList';
import withInvites from '../../../hoc/withInvites';

export interface SettingsUsersProps {

}

function SettingsUsers(props: SettingsUsersProps) {
    return (
        <Switch>
            <Route
                component={UsersList}
                path={USERS_ROUTE}
            />
        </Switch>
    );
}

export default withInvites(SettingsUsers as any);
