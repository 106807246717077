import AsideHeader from '@components/AsideHeader/AsideHeader';
import ContentLocale from '@components/ContentLocale';
import ContentTranslationStatus from '@components/ContentTranslationStatus';
import TranslationAsideHeader from '@components/TranslationAsideHeader';
import { labels } from '@constants/content-translation-labels';
import {
    ContentTranslation,
    ContentTranslationStatus as ContentTranslationStatusType
} from '@contentchef/contentchef-types';
import withSpaceDetailStore from '@hoc/withSpaceDetailStore';
import {
    Icon,
    Modal
} from 'antd';
import { autorun } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Prompt, Redirect } from 'react-router';
import {
    Aside,
    AsideItem,
    AsidePanelItem, ContainerWithAside, EditContentForm, FormActions, PublishingRequestsSummary, PublishToStage
} from '../../../components';
import { NOTIFICATION_DEFAULT_MESSAGES, NOTIFICATION_KEY_CONSTANTS } from '../../../constants/notifications-constants';
import { contentNotFoundPath, contentsBasePath } from '../../../constants/routing-constants';
import { userPermissionsTable } from '../../../constants/user-permissions-table';
import { withContentEditStores, withFormInitialization, WithLoadingData } from '../../../hoc';
import { ArchivedContentProvider } from '../../../providers/ArchivedContentProvider';
import { HasPermissions, ManageFieldProvider } from '../../../providers/UserPermissionsProvider';
import { DataAttributes, DataAttributesBuilder } from '../../../services/DataAttributesBuilder';
import {
    createCustomNotification,
    createErrorNotification,
    createInfoNotification,
    createSuccessNotification
} from '../../../services/Notification';
import {
    EditContentProps, EditContentState, InjectedProps
} from './EditContentModel';

const EditContentLabels = defineMessages({
    confirmArchiveModalTitle: {
        id: 'EditContent.confirmArchiveModalTitle',
        defaultMessage: 'Archive content'
    },
    confirmUnarchiveModalTitle: {
        id: 'EditContent.confirmUnarchiveModalTitle',
        defaultMessage: 'Unarchive content'
    },
    confirmArchiveModalContent: {
        id: 'EditContent.confirmArchiveModalContent',
        defaultMessage: 'Are you sure you want to archive this content?'
    },
    confirmUnarchiveModalContent: {
        id: 'EditContent.confirmUnarchiveModalContent',
        defaultMessage: 'Are you sure you want to unarchive this content?'
    },
    confirmToggleArchiveModalOk: {
        id: 'EditContent.confirmToggleArchiveModalOk',
        defaultMessage: 'Yes'
    },
    confirmToggleArchiveModalCancel: {
        id: 'EditContent.confirmToggleArchiveModalCancel',
        defaultMessage: 'No'
    },
    definitionNotFoundModalTitle: {
        id: 'EditContent.ModalTitle',
        defaultMessage: 'Content Definition Not Found',
    },
    definitionNotFoundModalOkButton: {
        id: 'EditContent.ModalOkButton',
        defaultMessage: 'Select Content Definition',
    },
    blockRouteMessage: {
        id: 'EditContent.blockRouteMessage',
        defaultMessage: 'All unsaved changes will be lost. Do you wish to proceed?'
    }
});

@inject(
    'editContentStore',
    'formMetaStore',
    'notificationStore',
    'contentStatusStore',
    'extensionManager',
    'spaceDetailStore'
)
@observer
class EditContent extends Component<EditContentProps, EditContentState> {
    errorModalInstance: { destroy(): void } | undefined = undefined;
    confirmArchiveModalInstance: { destroy(): void } | undefined = undefined;
    confirmUnarchiveModalInstance: { destroy(): void } | undefined = undefined;

    openErrorModal = autorun(
        () => {
            const { genericErrors } = this.injected.formMetaStore;
            if (genericErrors.contentDefinitionNotFound) {
                const { spaceId } = this.props.match.params;
                const { formatMessage } = this.props.intl;
                this.errorModalInstance = Modal.error({
                    title: formatMessage(EditContentLabels.definitionNotFoundModalTitle),
                    okText: formatMessage(EditContentLabels.definitionNotFoundModalOkButton),
                    keyboard: false,
                    onOk: () => this.props.history.push(contentsBasePath(spaceId)),
                    content: this.injected.intl.formatMessage(genericErrors.contentDefinitionNotFound)
                });
            }
        }
    );

    get injected() {
        return this.props as InjectedProps;
    }

    constructor(props: EditContentProps) {
        super(props);

        this.state = {
            openDrawer: false,
            cleanTransMode: false,
        };
    }

    componentWillUnmount() {
        const { notificationStore } = this.injected;
        const { notifications } = notificationStore;
        this.injected.formMetaStore.unsetGenericErrors();
        this.openErrorModal();
        if (this.errorModalInstance) {
            this.errorModalInstance.destroy();
        }
        if (this.confirmArchiveModalInstance) {
            this.confirmArchiveModalInstance.destroy();
        }
        if (this.confirmUnarchiveModalInstance) {
            this.confirmUnarchiveModalInstance.destroy();
        }
        if (notifications.includes(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST)) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST);
        }

        this.injected.extensionManager.destroy();
    }

    initializeEditContent = () => {
        const { contentId } = this.props.match.params;
        return Promise.all([
            this.injected.editContentStore.initializeEditContent(contentId),
            this.injected.contentStatusStore.setContentStatus(+contentId),
            this.injected.spaceDetailStore.setSpaceLocale()
        ]);
    }

    onSubmitForm = async () => {
        const { notificationStore } = this.injected;
        const { formatMessage } = this.props.intl;
        const { contentId } = this.props.match.params;
        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST,
            formatMessage,
            NOTIFICATION_DEFAULT_MESSAGES.updateAction
        );
        try {
            await this.injected.editContentStore.updateContent();
            this.injected.contentStatusStore.setContentStatus(+contentId);
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST);
            createSuccessNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.updateAction
            );
        } catch (e: any) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST);
            e.handleError(formatMessage, NOTIFICATION_DEFAULT_MESSAGES.updateAction);
        }
    }

    onSubmitFormErrors = (errors: any) => {
        this.injected.formMetaStore.setClientSideErrors(errors);
    }

    confirmToggleArchive = async (archive: boolean) => {
        const { notificationStore } = this.injected;
        const { formatMessage } = this.props.intl;
        // const { match: { params: { spaceId } } } = this.props;
        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST,
            formatMessage,
            archive
                ? NOTIFICATION_DEFAULT_MESSAGES.archiveAction
                : NOTIFICATION_DEFAULT_MESSAGES.unarchiveAction
        );
        try {
            await this.injected.editContentStore.toggleArchiveContent(archive);
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST);
            createSuccessNotification(
                formatMessage,
                archive
                    ? NOTIFICATION_DEFAULT_MESSAGES.archiveAction
                    : NOTIFICATION_DEFAULT_MESSAGES.unarchiveAction
            );
            // this.props.history.push(contentsBasePath(spaceId));
        } catch (e) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST);
            createErrorNotification(
                formatMessage,
                archive
                    ? NOTIFICATION_DEFAULT_MESSAGES.archiveAction
                    : NOTIFICATION_DEFAULT_MESSAGES.unarchiveAction
            );
        }
    }

    onArchive = () => {
        const { formatMessage } = this.props.intl;
        this.confirmArchiveModalInstance = Modal.confirm({
            title: formatMessage(EditContentLabels.confirmArchiveModalTitle),
            content: formatMessage(EditContentLabels.confirmArchiveModalContent),
            onOk: () => this.confirmToggleArchive(true),
            okText: formatMessage(EditContentLabels.confirmToggleArchiveModalOk),
            cancelText: formatMessage(EditContentLabels.confirmToggleArchiveModalCancel)
        });
    }

    onUnarchive = () => {
        const { formatMessage } = this.props.intl;
        this.confirmUnarchiveModalInstance = Modal.confirm({
            title: formatMessage(EditContentLabels.confirmUnarchiveModalTitle),
            content: formatMessage(EditContentLabels.confirmUnarchiveModalContent),
            onOk: () => this.confirmToggleArchive(false),
            okText: formatMessage(EditContentLabels.confirmToggleArchiveModalOk),
            cancelText: formatMessage(EditContentLabels.confirmToggleArchiveModalCancel)
        });
    }

    requestPublish = (contentId: number, version: number) => {
        this.injected.editContentStore.createPublishingRequest(contentId, version);
        this.injected.contentStatusStore.pollUntilStatusChanges(contentId);
    }

    createDataAttributes = (contentDefinitionName: string): DataAttributes => {
        return new DataAttributesBuilder('repository')
            .setEditFormAttribute(contentDefinitionName)
            .build();
    }

    showContentDetail = () => {
        this.setState({
            openDrawer: true
        });
    }

    hideContentDetail = () => {
        this.setState({
            openDrawer: false
        });
    }

    changeContentLocale = async (locale: string) => {
        const { intl: { formatMessage } } = this.injected;
        try {
            await this.injected.editContentStore
                .updateContentLocale(this.injected.editContentStore.editContentData.initialContentData?.id!, locale);
            return true;
        } catch (error) {
            console.log(error);
            createCustomNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.translationModeError,
                'error'
            );
            return false;
        }
    }

    onAddTranslations = async (locales: string[]) => {
        const { intl: { formatMessage } } = this.injected;
        try {
            const response = await this.injected.editContentStore.createTranslations(locales);
            // this.props.history.push(contentTranslationPath(spaceId, parseInt(contentId, 10), locale));
            return response;
        } catch (e) {
            createCustomNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.deleteTranslationError,
                'error'
            );
            return Promise.reject(e);
        }
    }

    onDeleteTrans = async (locale: string) => {
        const { intl: { formatMessage } } = this.injected;
        try {
            const response = await this.injected.editContentStore.deleteTranslation(locale);
            return response;
        } catch (e) {
            createCustomNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.deleteTranslationError,
                'error'
            );
            return Promise.reject(e);
        }
    }

    onClickInfoIconInHeader = () => {
        if (window.supportHeroWidget) {
            window.supportHeroWidget.show({ article: '142196-content-i18n' });
        }
    }

    onClean = async (locales: string[]) => {
        const { intl: { formatMessage } } = this.injected;
        try {
            await this.injected.editContentStore.cleanTranslations(locales);
            this.setState({ cleanTransMode: false });
            return;
        } catch (error) {
            createCustomNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.cleanTranslationError,
                'error'
            );
            return Promise.reject(error);
        }
    }

    hasNotMissingTranslations = (translations: ContentTranslation[]) => {
        return translations.some(translation => translation.status !== ContentTranslationStatusType.MISSING);
    }

    toggleTransMode = () => {
        this.setState((state) => (
            { cleanTransMode: !state.cleanTransMode }
        ));
    }

    render() {
        const {
            name, repository, publicId, onlineRange, tags, channels, dynamicFields
        } = this.injected.formMetaStore.formMeta;
        const { editContentData: {
            initialContentData,
            contentHasDeletedProperties,
        }, isContentModified,
            toggleContentTranslations,
            toggleContentTranslationsLoader,
            contentTranslations
        } = this.injected.editContentStore;
        const { notifications } = this.injected.notificationStore;
        const { intl: { formatMessage } } = this.props;
        return (
            <WithLoadingData
                promise={this.initializeEditContent}
            >
                {!!initialContentData ?
                    (
                        <ArchivedContentProvider archived={initialContentData.archived}>
                            <ContainerWithAside
                                dataAttributes={this.createDataAttributes(initialContentData.name).Attributes}
                                disableContent={
                                    notifications.includes(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST)
                                }
                                renderAside={() => (
                                    <Aside defaultActiveKeys={['publishing_queue']}>
                                        <AsideItem>
                                            <FormActions
                                                disableSave={isContentModified}
                                                onSubmit={this.onSubmitForm}
                                                onSubmitFormErrors={this.onSubmitFormErrors}
                                                onArchive={this.onArchive}
                                                onUnarchive={initialContentData.archived ? this.onUnarchive : undefined}
                                                permissions={userPermissionsTable.FormActions.content}
                                            />
                                        </AsideItem>
                                        {!initialContentData.archived &&
                                            <HasPermissions permissions={userPermissionsTable.FormActions.content}>
                                                <AsideItem>
                                                    <PublishToStage />
                                                </AsideItem>
                                            </HasPermissions>
                                        }
                                        <AsideItem
                                            header={
                                                <AsideHeader
                                                    label={formatMessage(labels.locale)}
                                                    icon={
                                                        <Icon
                                                            type="info-circle"
                                                            theme="filled"
                                                            onClick={this.onClickInfoIconInHeader}
                                                        />
                                                    }
                                                />
                                            }
                                        >
                                            <ContentLocale
                                                content={initialContentData}
                                                locales={this.injected.spaceDetailStore
                                                    .retrieveSupportedLocales([initialContentData.locale])}
                                                onChangeLocale={this.changeContentLocale}
                                            />
                                        </AsideItem>
                                        {initialContentData.definition &&
                                            !this.injected.spaceDetailStore.isEmpty()
                                            && (initialContentData.definition as any)['translatableFields'] &&
                                            (initialContentData.definition as any)['translatableFields'].length > 0 &&
                                            <AsideItem
                                                key="content_translation_status"
                                                header={
                                                    <TranslationAsideHeader
                                                        onClickInfo={this.onClickInfoIconInHeader}
                                                        onToggleMode={this.toggleTransMode}
                                                        transMode={this.state.cleanTransMode}
                                                        translations={contentTranslations}
                                                    />
                                                }
                                            >
                                                <ContentTranslationStatus
                                                    changeLocSetting={toggleContentTranslations}
                                                    loading={toggleContentTranslationsLoader}
                                                    locales={
                                                        this.injected.spaceDetailStore
                                                            .retrieveSupportedLocales()}
                                                    contentLocale={initialContentData.locale}
                                                    translations={contentTranslations}
                                                    onAddTranslation={this.onAddTranslations}
                                                    locSetting={initialContentData.contentLocalizationSettings!}
                                                    onDelete={this.onDeleteTrans}
                                                    cleanTransMode={this.state.cleanTransMode}
                                                    onClean={this.onClean}
                                                    fallback={this.injected.spaceDetailStore.spaceLocale.fallbackLocale}
                                                />
                                            </AsideItem>}
                                        <AsidePanelItem
                                            key="publishing_queue"
                                            header={
                                                <FormattedMessage
                                                    id="EditContent.sidebarPublishingQueue"
                                                    defaultMessage="Publishing Queue"
                                                />
                                            }
                                        >
                                            <PublishingRequestsSummary />
                                        </AsidePanelItem>
                                    </Aside>
                                )}
                            >
                                <ManageFieldProvider
                                    permissions={userPermissionsTable.Forms.AddContentForm}
                                >
                                    <Prompt
                                        when={contentHasDeletedProperties ? false : !isContentModified}
                                        message={() =>
                                            this.props.intl.formatMessage(EditContentLabels.blockRouteMessage)
                                        }
                                    />
                                    <EditContentForm
                                        fieldName={name}
                                        fieldRepository={repository}
                                        fieldPublicId={publicId}
                                        fieldOnlineRange={onlineRange}
                                        fieldTags={tags}
                                        fieldChannels={channels}
                                        dynamicFields={dynamicFields}
                                    />
                                </ManageFieldProvider>
                            </ContainerWithAside>
                        </ArchivedContentProvider>
                    ) : (
                        <Redirect to={contentNotFoundPath(this.props.match.params.spaceId)} />
                    )
                }
            </WithLoadingData>
        );
    }
}

export default withContentEditStores(
    withSpaceDetailStore(
        withFormInitialization(
            injectIntl(EditContent)
        )
    )
);
