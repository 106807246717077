import { ActionDef } from '@components/atoms/ActionList';
import Button from '@components/Button';
import ContentStatus from '@components/ContentStatus';
import { envRelatedLabels } from '@constants/envinroment-related-labels';
import { ContentStatus as ContentStatusType } from '@contentchef/contentchef-types';
import { Badge, Modal } from 'antd';
import { BadgeProps } from 'antd/lib/badge';
import classNames from 'classnames';
import React, { Component } from 'react';
import { InjectedIntl, InjectedIntlProps, injectIntl } from 'react-intl';
import styles from './index.module.scss';

interface ContentStatusSectionProps extends InjectedIntlProps {
    contentLocale: string;
    currentVersion: number;
    currentChannels: string[];
    status?: ContentStatusType[];
    intl: InjectedIntl;
}

type ContentStatusSectionState = {
    isVisible: boolean;
};

class ContentStatusSection extends Component<ContentStatusSectionProps, ContentStatusSectionState> {
    state = {
        isVisible: false,
    };

    renderStatusByChannel = () => {
        const { isVisible } = this.state;
        const { currentChannels, currentVersion, contentLocale, status, intl } = this.props;
        return (
            <Modal
                visible={isVisible}
                title={intl.formatMessage(envRelatedLabels.environment)}
                destroyOnClose={true}
                centered={true}
                footer={null}
                onCancel={(e) => { e.stopPropagation(); e.preventDefault(); this.setState({ isVisible: false }); }}
                wrapClassName={styles.ContentStatusModal}
            >
                <ContentStatus
                    contentLocale={contentLocale}
                    currentVersion={currentVersion}
                    currentChannels={currentChannels}
                    status={status}
                />
            </Modal>
        );
    }

    onClickStatusButton = () => {
        this.setState({
            isVisible: true
        });
    }

    getButtonProps = () => {
        const { currentVersion, status } = this.props;
        let defaultProps: ActionDef = {
            clickHandler: this.onClickStatusButton,
            title: envRelatedLabels.versions,
            icon: 'cloud-upload',
            type: 'default'
        };
        if (!status || (status && status.length === 0)) {
            defaultProps = {
                ...defaultProps,
                type: 'dashed',
                title: envRelatedLabels.neverPublished
            };

            return defaultProps;
        }

        const statusToUse = status.find(item => item.isTranslation === false || item.isTranslation === undefined);

        if (statusToUse && ((currentVersion !== statusToUse.stageVersion) ||
            (currentVersion !== statusToUse.liveVersion) ||
            (statusToUse.stageVersion !== statusToUse.liveVersion))) {
            defaultProps = {
                ...defaultProps,
                title: envRelatedLabels.notSynchronized,
                badgeProps: {
                    dot: true,
                    className: styles.NotSynchronizedBadge
                } as BadgeProps
            };

            return defaultProps;
        }

        return {
            ...defaultProps,
            title: envRelatedLabels.synchronized,
            badgeProps: {
                dot: true,
                className: styles.Synchronized,
            }
        };
    }

    renderStatusButton = ({ title, type, clickHandler, icon }: ActionDef) => {
        const { intl } = this.props;
        return (
            <Button
                icon={icon}
                onClick={clickHandler}
                type={type}
                title={intl.formatMessage(title)}
                className={styles.EnvironmentStatusButton}
            >
                <span>
                    {intl.formatMessage(envRelatedLabels.environment)}
                </span>
            </Button>
        );
    }

    renderEnvironmentStatus = () => {
        const { badgeProps, title, type, clickHandler, icon } = this.getButtonProps();
        const { intl } = this.props;

        if (badgeProps) {
            const actionClassName = classNames(badgeProps ? badgeProps.className : undefined);
            return (
                <Badge
                    {...badgeProps}
                    className={actionClassName}
                    title={badgeProps.titleToFormat ?
                        intl.formatMessage(badgeProps.titleToFormat) : undefined
                    }
                >
                    {this.renderStatusButton({ title, type, clickHandler, icon })}
                </Badge>
            );
        }

        return this.renderStatusButton({ title, type, clickHandler, icon });
    }

    render() {
        return (
            <div className={styles.ContentStatusSection}>
                {this.renderEnvironmentStatus()}
                {this.renderStatusByChannel()}
            </div>
        );
    }
}

export default injectIntl(ContentStatusSection);
