import {
    AllSchemaTypes,
    ArraySchemaType,
    Field,
    SchemaTypeIds
} from '@contentchef/contentchef-types';
import { ListItemIds } from '../../types';
import { SerializedField } from '../types';
import { retrieveBooleanEditingType } from './boolean';
import { retrieveCloudinaryImageEditingType } from './cloudinary-image';
import { retrieveCloudinaryPublicIdEditingType } from './cloudinary-publicId';
import { retrieveCloudinaryVideoEditingType } from './cloudinary-video';
import { retrieveDateEditingType } from './date';
import { retrieveFragmentEditingType } from './fragment';
import { retrieveLinkedContentEditingType } from './linked-content';
import { retrieveLongTextEditingType } from './long-text';
import { retrieveEditingType } from './media';
import { retrieveNumberEditingType } from './number';
import { retrieveOneFragmentOfEditingType } from './oneFragmentOf';
import { retrieveOneLinkedContentOfEditingType } from './oneLinkedContentOf';
import { retrieveTextEditingType } from './text';

export interface ArrayConstraints<T = AllSchemaTypes> {
    items: T;
    maxLength?: number;
    minLength?: number;
    required: boolean;
}

export type SerializedArraySchema<SUBTYPE = AllSchemaTypes> = SerializedField<ArrayConstraints<SUBTYPE>, {}>;

function retrieveArrayEditingSubType(arrayItemConstraints: AllSchemaTypes): ListItemIds {
    switch (arrayItemConstraints.type) {
        case SchemaTypeIds.TEXT:
            return retrieveTextEditingType(arrayItemConstraints.constraints);
        case SchemaTypeIds.LONG_TEXT:
            return retrieveLongTextEditingType(arrayItemConstraints.constraints);
        case SchemaTypeIds.NUMBER:
            return retrieveNumberEditingType(arrayItemConstraints.constraints);
        case SchemaTypeIds.BOOLEAN:
            return retrieveBooleanEditingType();
        case SchemaTypeIds.DATE:
            return retrieveDateEditingType();
        case SchemaTypeIds.CLOUDINARY_VIDEO:
            return retrieveCloudinaryVideoEditingType();
        case SchemaTypeIds.CLOUDINARY_IMAGE:
            return retrieveCloudinaryImageEditingType();
        case SchemaTypeIds.CLOUDINARY_PUBLICID:
            return retrieveCloudinaryPublicIdEditingType(arrayItemConstraints.constraints);
        case SchemaTypeIds.MEDIA:
            return retrieveEditingType(arrayItemConstraints.constraints);
        case SchemaTypeIds.LINKED_CONTENT:
            return retrieveLinkedContentEditingType(arrayItemConstraints.constraints);
        case SchemaTypeIds.ONE_LINKED_CONTENT_OF:
            return retrieveOneLinkedContentOfEditingType(arrayItemConstraints.constraints);
        case SchemaTypeIds.FRAGMENT:
            return retrieveFragmentEditingType();
        case SchemaTypeIds.ONE_FRAGMENT_OF:
            return retrieveOneFragmentOfEditingType();
        default:
            throw new TypeError('array item type not recognized');
    }
}

export function deserialize(serialized: SerializedArraySchema): Field<ArraySchemaType> {
    return {
        constraints: {
            required: serialized.constraints.required!,
            maxLength: serialized.constraints.maxLength,
            minLength: serialized.constraints.minLength,
            items: serialized.constraints.items,
        },
        labels: serialized.labels,
        placeholder: Object.keys(serialized.placeholder).length > 0 ? serialized.placeholder : undefined,
        hint: Object.keys(serialized.hint).length > 0 ? serialized.hint : undefined,
        name: serialized.name,
        type: SchemaTypeIds.ARRAY,
        extension: serialized.extension,
        compact: serialized.compact
    };
}

export function serialize(deserialized: Field<ArraySchemaType>, initialIndex: number): SerializedArraySchema {
    return {
        settings: {},
        constraints: {
            items: deserialized.constraints.items,
            maxLength: deserialized.constraints.maxLength,
            minLength: deserialized.constraints.minLength,
            required: deserialized.constraints.required,
        },
        labels: deserialized.labels,
        hint: deserialized.hint || {},
        placeholder: deserialized.placeholder || {},
        name: deserialized.name,
        type: deserialized.type,
        editingType: ListItemIds.ListOfItems,
        editingSubType: retrieveArrayEditingSubType(deserialized.constraints.items),
        initialIndex,
        extension: deserialized.extension,
        compact: deserialized.compact
    };
}
