import { RepositorySummaryStoreModel, RepositoryStatsModel } from './repositorySummaryStoreModel';
import { observable, action, runInAction } from 'mobx';
import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import {
    ContentRepositoryId,
    ContentRepositorySummaryRequest
} from '@contentchef/contentchef-types';

class RepositorySummaryStore implements RepositorySummaryStoreModel {
    api: ContentChefClient;
    @observable repositoriesStats = {} as RepositoryStatsModel;

    constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action
    async setSummaryRepository(repositoryId: ContentRepositoryId) {
        const reqParam: ContentRepositorySummaryRequest = {
            id: +repositoryId
        };
        try {
            const payload = await this.api.contentRepositories.viewSummary(reqParam);
            runInAction(() => {
                this.repositoriesStats = {
                    ...this.repositoriesStats,
                    [repositoryId]: [{ name: 'Contents', count: payload.contents }],
                };
            });
        } catch (e) {
            runInAction(() => {
                console.log(e, 'Impossible to call contentRepositories.viewSummary');
            });
        }
    }

}

export default RepositorySummaryStore;
