import { ContentTagStoreModel, LoaderModel } from './contentTagStoreModel';
import { action, IObservableArray, observable, runInAction } from 'mobx';
import { ListContentTagsRequest } from '@contentchef/contentchef-types';
import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';

class ContentTagStore implements ContentTagStoreModel {
    api: ContentChefClient;
    @observable suggestedTags: IObservableArray<string> = observable.array([]);
    @observable loader: LoaderModel = {
        suggestedTags: false
    };

    constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action
    async setSuggestedTags(name?: string) {
        const requestParams: ListContentTagsRequest = {
            filters: {
                name
            }
        };
        this.loader.suggestedTags = true;
        try {
            const response = await this.api.contentTags.listSuggestedTags(requestParams);
            runInAction(() => {
                this.suggestedTags.replace(response);
                this.loader.suggestedTags = false;
            });
        } catch (e) {
            runInAction(() => {
                this.loader.suggestedTags = false;
                console.log('Error occurred when retrieving suggestedTags', e);
                throw e;
            });
        }
    }
}

export default ContentTagStore;
