import React, { Component } from 'react';
import { Drawer, Icon } from 'antd';
import Button from '@components/Button';
import { ContentOverview } from '..';
import { observer, inject } from 'mobx-react';
import { ContentInfoProps, ContentInfoState, InjectedProps } from './ContentInfoModel';

import styles from './ContentInfo.module.scss';
import { FormattedMessage } from 'react-intl';

@inject('editContentStore')
@observer
class ContentInfo extends Component<ContentInfoProps, ContentInfoState> {
    state = {
        showContentInfo: false
    };

    get injected() {
        return this.props as InjectedProps;
    }

    showContentDetail = () => {
        this.setState({
            showContentInfo: true
        });
    }

    hideContentDetail = () => {
        this.setState({
            showContentInfo: false
        });
    }
    render() {
        const { editContentData: { initialContentData } } = this.injected.editContentStore;
        return (
            <React.Fragment>
                <Button
                    type="ghost"
                    icon="info-circle"
                    onClick={this.showContentDetail}
                >
                    <FormattedMessage
                        id="ContentInfo.buttonLabel"
                        defaultMessage="Info"
                    />
                </Button>
                <Drawer
                    closable={false}
                    width={300}
                    className={styles.ContentInfoDrawer}
                    visible={this.state.showContentInfo}
                    onClose={this.hideContentDetail}
                >
                    <span onClick={this.hideContentDetail} className={styles.ContentInfoCloseDrawerIcon}>
                        <Icon
                            type="menu-unfold"
                        />
                    </span>
                    <ContentOverview
                        selectedContent={initialContentData}
                    />
                </Drawer>
            </React.Fragment>
        );
    }
}

export default ContentInfo;
