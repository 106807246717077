import {
    Field, LongTextFieldConstraints,
    LongTextSchemaType,
    LongTextSubTypes,
    SchemaTypeIds
} from '@contentchef/contentchef-types';
import { SerializedField } from '../types';
import { ListItemIds } from '../../types';

export interface LongTextConstraints {
    allowEmpty?: boolean;
    minLength?: number;
    maxLength?: number;
    subType?: LongTextSubTypes;
    required: boolean;
}

export type SerializedLongTextSchema = SerializedField<LongTextConstraints, {}>;

export function retrieveLongTextEditingType(constraints: LongTextFieldConstraints): ListItemIds {
    if (constraints.hasOwnProperty('subType')) {
        if (constraints.subType === LongTextSubTypes.MARKDOWN) {
            return ListItemIds.MarkdownPlainText;
        }
        if (constraints.subType === LongTextSubTypes.JSON) {
            return ListItemIds.JsonPlainText;
        }
        if (constraints.subType === LongTextSubTypes.HTML) {
            return ListItemIds.HtmlPlainText;
        }
    }
    return ListItemIds.SimplePlainText;
}

export function deserialize(serialized: SerializedLongTextSchema): Field<LongTextSchemaType> {
    return {
        constraints: {
            allowEmpty: serialized.constraints.allowEmpty,
            required: serialized.constraints.required!,
            maxLength: !!serialized.constraints.maxLength ? serialized.constraints.maxLength : undefined,
            minLength: !!serialized.constraints.minLength ? serialized.constraints.minLength : undefined,
            subType: !!serialized.constraints.subType ? serialized.constraints.subType : undefined,
        },
        labels: serialized.labels,
        placeholder: Object.keys(serialized.placeholder).length > 0 ? serialized.placeholder : undefined,
        hint: Object.keys(serialized.hint).length > 0 ? serialized.hint : undefined,
        name: serialized.name,
        enableFacet: serialized.enableFacet,
        type: SchemaTypeIds.LONG_TEXT,
        extension: serialized.extension,
        translatable: serialized.translatable,
        compact: serialized.compact
    };
}

export function serialize(deserialized: Field<LongTextSchemaType>, initialIndex: number): SerializedLongTextSchema {
    return {
        settings: {},
        constraints: {
            allowEmpty: deserialized.constraints.allowEmpty,
            maxLength: deserialized.constraints.maxLength,
            minLength: deserialized.constraints.minLength,
            subType: deserialized.constraints.subType,
            required: deserialized.constraints.required,
        },
        labels: deserialized.labels,
        hint: deserialized.hint || {},
        placeholder: deserialized.placeholder || {},
        name: deserialized.name,
        enableFacet: deserialized.enableFacet,
        type: deserialized.type,
        editingType: retrieveLongTextEditingType(deserialized.constraints),
        initialIndex,
        extension: deserialized.extension,
        translatable: deserialized.translatable,
        compact: deserialized.compact
    };
}
