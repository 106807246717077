import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { SUBSCRIPTION_MANAGEMENT_ROUTE, SUBSCRIPTION_DETAIL_ROUTE } from '../../../constants/routing-constants';
import withSubscriptions from '../../../hoc/withSubscription';
import SubscriptionListScene from './SubscriptionListScene';
import SubscriptionDetailScene from './SubscriptionDetailScene';

interface SubscriptionSettingsRouterProps { }

interface SubscriptionSettingsRouterState { }

class SubscriptionSettingsRouter extends Component<SubscriptionSettingsRouterProps, SubscriptionSettingsRouterState> {
    render() {
        return (
            <Switch>
                <Route
                    exact={true}
                    path={SUBSCRIPTION_MANAGEMENT_ROUTE}
                    component={SubscriptionListScene}
                />
                <Route
                    exact={true}
                    path={SUBSCRIPTION_DETAIL_ROUTE}
                    component={SubscriptionDetailScene}
                />
            </Switch>
        );
    }
}

export default withSubscriptions(SubscriptionSettingsRouter);
