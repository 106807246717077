import Button from '@components/Button';
import { SchemaTypeIds } from '@contentchef/contentchef-types';
import { nameMappedErrors } from '@services/FormUtils/DefinitionFieldMappedErrors';
import { ContentDefinitionSchemaStoreModel } from '@stores/ContentDefinitionEditorStore/ContentDefinitionSchemaStore';
import { ContentDefinitionFormStoreModel } from '@stores/contentDefinitionFormStore/contentDefinitionFormStoreModel';
import { Badge, Col, Icon, Tabs } from 'antd';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import {
    withPermissionsToInteract,
    WithPermissionsToInteractReturnType
} from '../../../providers/UserPermissionsProvider';
import Section from '../../Section/Section';
import Typography from '../../Typography';
import FieldEditorStore from '../FieldEditorStore';
import FieldSerializer from '../FieldSerializer';
import ConstraintTab from './ConstraintTab';
import DefinitionFieldName from './DefinitionFieldName/DefinitionFieldName';
import DetailsTab from './DetailsTab';
import styles from './index.module.scss';
import TranslationsTab from './TranslationsTab';

export interface ContentDefinitionFieldEditingProps extends InjectedIntlProps, WithPermissionsToInteractReturnType {
    onSave(): void;
    onBack(): void;
    onCheckNameAlreadyExist(value: string): boolean;
}

interface DecoratedContentDefinitionModalProps extends ContentDefinitionFieldEditingProps {
    fieldEditorStore: FieldEditorStore;
    contentDefinitionSchemaStore: ContentDefinitionSchemaStoreModel;
    contentDefinitionFormStore: ContentDefinitionFormStoreModel;
}

interface ContentDefinitionFieldEditingState {
    currentTab: string;
    showGoTopArrow: boolean;
    showGoBotArrow: boolean;
}

enum TabPaneContentIds {
    detailsTab = 'DetailsTabContent',
    constraintsTab = 'ConstraintsTabContent',
    uisTab = 'UiTabContent'
}

const labels = defineMessages({
    constraintsTab: {
        defaultMessage: 'Validation',
        id: 'components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.tabs.Validation',
    },
    customSettingsTab: {
        defaultMessage: 'Field additional settings',
        id: 'components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.tabs.additionalSettings',
    },
    fieldName: {
        defaultMessage: 'Field name',
        id: 'components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.field.name',
    },
    fieldNamei18n: {
        defaultMessage: 'Translated labels',
        id: 'components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.field.name.18n',
    },
    UICustomization: {
        defaultMessage: 'UI Customization',
        id: 'components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.field.name.UICustomization',
    },
    detailsTab: {
        defaultMessage: 'Details',
        id: 'components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.tabs.details',
    },
    confirmButton: {
        id: 'ContentDefinitionFieldEditing.button.confirm',
        defaultMessage: 'Confirm'
    },
    cancelButton: {
        defaultMessage: 'Cancel',
        id: 'ContentDefinitionFieldEditing.button.cancel'
    },
});
const Tab = Tabs.TabPane;

@inject('fieldEditorStore', 'contentDefinitionSchemaStore', 'contentDefinitionFormStore')
@observer
// tslint:disable-next-line:max-line-length
class ContentDefinitionFieldEditing extends React.Component<ContentDefinitionFieldEditingProps, ContentDefinitionFieldEditingState> {
    state: ContentDefinitionFieldEditingState = {
        currentTab: this.props.intl.formatMessage(labels.detailsTab),
        showGoTopArrow: false,
        showGoBotArrow: false,
    };

    public get decoratedProps(): DecoratedContentDefinitionModalProps {
        return this.props as DecoratedContentDefinitionModalProps;
    }

    public componentDidMount(): void {
        this.handleTabsGoToArrows(this.state.currentTab);
        this.createTabsScrollListener();
    }

    public componentWillUnmount() {
        this.removeTabsScrollListener();
    }

    retrieveFieldTypeLabel = () => {
        const { serializedField } = this.decoratedProps.fieldEditorStore;
        switch (serializedField!.type) {
            case SchemaTypeIds.FRAGMENT:
                return `${serializedField!.editingType} ${serializedField!.constraints.name}`;
            case SchemaTypeIds.ARRAY:
                return `${serializedField!.editingType} of ${serializedField!.editingSubType}`;
            default:
                return serializedField!.editingType;
        }
    }

    onSaveClick = async () => {
        try {
            this.decoratedProps.fieldEditorStore.cleanFieldValidationErrors();
            await this.decoratedProps.contentDefinitionFormStore.validateSchemaField(
                FieldSerializer.deserialize(this.decoratedProps.fieldEditorStore.serializedField!),
                this.decoratedProps.contentDefinitionSchemaStore.schema.lang,
                Object.keys(this.decoratedProps.contentDefinitionSchemaStore.schema.fragments)
            );
            this.props.onSave();
        } catch (e: any) {
            this.decoratedProps.fieldEditorStore.setFieldValidationErrors(e.retrieveValidationErrors());
        }
    }

    onChangeTab = (tab: string) => {
        this.setState({ currentTab: tab });
        this.handleTabsGoToArrows(tab);
    }

    createTabsScrollListener = () => {
        let isScrolling: number;
        const detailsTabEl = document.getElementById(TabPaneContentIds.detailsTab);
        const constraintsTabEl = document.getElementById(TabPaneContentIds.constraintsTab);
        const uiTabEl = document.getElementById(TabPaneContentIds.uisTab);
        const userStoppedManualScroll = (tab: string) => window.setTimeout(() => {
            this.handleTabsGoToArrows(tab);
            // tslint:disable-next-line
        }, 200);
        const hideArrowsOnManualScroll = (tab: string) => {
            this.setState({ showGoTopArrow: false, showGoBotArrow: false });
        };
        if (detailsTabEl) {
            detailsTabEl.addEventListener('scroll', () => {
                hideArrowsOnManualScroll(this.props.intl.formatMessage(labels.detailsTab));
                clearTimeout(isScrolling);
                isScrolling = userStoppedManualScroll(this.props.intl.formatMessage(labels.detailsTab));
            });
        }
        if (constraintsTabEl) {
            constraintsTabEl.addEventListener('scroll', () => {
                hideArrowsOnManualScroll(this.props.intl.formatMessage(labels.constraintsTab));
                clearTimeout(isScrolling);
                isScrolling = userStoppedManualScroll(this.props.intl.formatMessage(labels.constraintsTab));
            });
        }
        if (uiTabEl) {
            uiTabEl.addEventListener('scroll', () => {
                hideArrowsOnManualScroll(this.props.intl.formatMessage(labels.UICustomization));
                clearTimeout(isScrolling);
                isScrolling = userStoppedManualScroll(this.props.intl.formatMessage(labels.UICustomization));
            });
        }
    }

    removeTabsScrollListener = () => {
        const detailsTabEl = document.getElementById(TabPaneContentIds.detailsTab);
        const constraintsTabEl = document.getElementById(TabPaneContentIds.constraintsTab);
        const uiTabEl = document.getElementById(TabPaneContentIds.uisTab);
        if (detailsTabEl) {
            detailsTabEl.removeEventListener(
                'scroll', () =>
                this.handleTabsGoToArrows(this.props.intl.formatMessage(labels.detailsTab))
            );
        }
        if (constraintsTabEl) {
            constraintsTabEl.removeEventListener(
                'scroll', () =>
                this.handleTabsGoToArrows(this.props.intl.formatMessage(labels.constraintsTab))
            );
        }
        if (uiTabEl) {
            uiTabEl.removeEventListener(
                'scroll', () =>
                this.handleTabsGoToArrows(this.props.intl.formatMessage(labels.UICustomization))
            );
        }
    }

    showArrowScrollToArrows = (el: HTMLElement | null) => {
        if ((el && (el.scrollHeight - el.clientHeight) > 50)) {
            const realScrollHeight = el.scrollHeight - el.clientHeight;
            if (el.scrollTop >= 50 && el.scrollTop <= (realScrollHeight - 50)) {
                this.setState({ showGoTopArrow: true, showGoBotArrow: true });
            } else if (el.scrollTop <= 50) {
                this.setState({ showGoTopArrow: false, showGoBotArrow: true });
            } else if (el.scrollTop >= (realScrollHeight - 50)) {
                this.setState({ showGoTopArrow: true, showGoBotArrow: false });
            }
        } else {
            this.setState({ showGoTopArrow: false, showGoBotArrow: false });
        }
    }

    handleTabsGoToArrows = (tab: string) => {
        switch (tab) {
            case (this.props.intl.formatMessage(labels.detailsTab)):
                this.showArrowScrollToArrows(document.getElementById(TabPaneContentIds.detailsTab));
                break;
            case (this.props.intl.formatMessage(labels.constraintsTab)):
                this.showArrowScrollToArrows(document.getElementById(TabPaneContentIds.constraintsTab));
                break;
            case (this.props.intl.formatMessage(labels.UICustomization)):
                this.showArrowScrollToArrows(document.getElementById(TabPaneContentIds.uisTab));
                break;
            default:
                break;
        }
    }

    showScrollTopArrow = () => (
        <div className={classNames(styles.ScrollToArrow, { [styles.ScrollToTopArrow]: true })}>
            <Icon type="double-left" />
        </div>
    )

    showScrollBottomArrow = () => (
        <div className={classNames(styles.ScrollToArrow, { [styles.ScrollToBottomArrow]: true })}>
            <Icon type="double-left" />
        </div>
    )

    public render() {
        const { intl } = this.props;
        const { currentTab, showGoBotArrow, showGoTopArrow } = this.state;
        const {
            fieldEditorStore,
            contentDefinitionSchemaStore
        } = this.decoratedProps;
        return (
            !!this.decoratedProps.fieldEditorStore.serializedField && (
                <Section
                    contentWrapperClass={styles.ContentWrapper}
                    header={<>
                        <Col xs={24} style={{ paddingTop: 30 }}>
                            <DefinitionFieldName
                                mappedErrors={nameMappedErrors}
                                onCheckNameAlreadyExist={this.props.onCheckNameAlreadyExist}
                            />
                        </Col>
                        <Col xs={24}>
                            <Typography variant="caption">
                                {this.retrieveFieldTypeLabel()}
                            </Typography>
                        </Col>
                    </>}
                    footer={<>
                        <Button onClick={this.props.onBack} style={{ marginRight: '1em' }}>
                            {intl.formatMessage(labels.cancelButton)}
                        </Button>
                        <Button
                            type="primary"
                            onClick={this.onSaveClick}
                            loading={this.decoratedProps.contentDefinitionFormStore.loaders.validateField}
                        >
                            {intl.formatMessage(labels.confirmButton)}
                        </Button>
                    </>}
                >
                    <Tabs
                        className={styles.Tabs}
                        activeKey={currentTab}
                        onChange={this.onChangeTab}
                    >
                        <Tab
                            key={intl.formatMessage(labels.detailsTab)}
                            forceRender={true}
                            tab={<div>
                                <span style={{ marginRight: '0.3em' }}>
                                    {intl.formatMessage(labels.detailsTab)}
                                </span>
                                <Badge count={fieldEditorStore.tabErrorsCount.detailsTab} />
                            </div>}
                        >
                            {showGoTopArrow && this.showScrollTopArrow()}
                            <DetailsTab
                                id={TabPaneContentIds.detailsTab}
                                isArrayChildren={false}
                                fieldToRenderType={fieldEditorStore.serializedField!.editingType}
                                fieldEditorStore={fieldEditorStore}
                                defaultLang={contentDefinitionSchemaStore.schema.lang}
                                constraints={fieldEditorStore.serializedField!.constraints}
                                onChangeConstraint={fieldEditorStore.updateConstraint}
                            />
                            {showGoBotArrow && this.showScrollBottomArrow()}
                        </Tab>
                        <Tab
                            key={intl.formatMessage(labels.constraintsTab)}
                            forceRender={true}
                            tab={<div>
                                <span style={{ marginRight: '0.3em' }}>
                                    {intl.formatMessage(labels.constraintsTab)}
                                </span>
                                <Badge count={fieldEditorStore.tabErrorsCount.validationTab} />
                            </div>}
                        >
                            {showGoTopArrow && this.showScrollTopArrow()}
                            <ConstraintTab
                                id={TabPaneContentIds.constraintsTab}
                                isArrayChildren={false}
                                fieldToRenderType={fieldEditorStore.serializedField!.editingType}
                                fieldEditorStore={fieldEditorStore}
                                defaultLang={contentDefinitionSchemaStore.schema.lang}
                                constraints={fieldEditorStore.serializedField!.constraints}
                                onChangeConstraint={fieldEditorStore.updateConstraint}
                            />
                            {showGoBotArrow && this.showScrollBottomArrow()}
                        </Tab>
                        <Tab
                            key={intl.formatMessage(labels.UICustomization)}
                            forceRender={true}
                            tab={<div>
                                <span style={{ marginRight: '0.3em' }}>
                                    {intl.formatMessage(labels.UICustomization)}
                                </span>
                                <Badge count={fieldEditorStore.tabErrorsCount.uiCustomizationTab} />
                            </div>}
                        >
                            {showGoTopArrow && this.showScrollTopArrow()}
                            <TranslationsTab
                                id={TabPaneContentIds.uisTab}
                                fieldEditorStore={fieldEditorStore}
                                defaultLang={contentDefinitionSchemaStore.schema.lang}
                            />
                            {showGoBotArrow && this.showScrollBottomArrow()}
                        </Tab>
                    </Tabs>
                </Section>
            )
        );
    }
}

export default withPermissionsToInteract(injectIntl(ContentDefinitionFieldEditing));
