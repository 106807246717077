import { ContentChefClient, createChefSpaceClient } from '@contentchef/contentchef-management-js-client';
import { AuthenticationTokenResolver, SpaceIdResolver } from '@contentchef/contentchef-management-js-client/dist/api';
import { Auth } from 'aws-amplify';
import {
    action, observable
} from 'mobx';
import { routerStoreHelper } from '..';
import { AWSConfiguration } from '../../constants/api-init-options';
import { dashboardPath } from '../../constants/routing-constants';

export interface ApiStoreModel {
    api: ContentChefClient;
    currentSpaceId: string | undefined;
    switchToSpace(spaceId: string): void;
    changeCurrentSpace(spaceId: string): void;
    clearCurrentSpace(): void;
}

class ApiStore implements ApiStoreModel {
    @observable api: ContentChefClient;
    @observable currentSpaceId: string | undefined;
    apiTokenResolver: AuthenticationTokenResolver = {
        getToken: async () => {
            const token = (await Auth.currentSession()).getIdToken().getJwtToken();
            return token;
        }
    };
    spaceIdResolver: SpaceIdResolver = {
        getSpaceId: async () => {
            if (!this.currentSpaceId) {
                throw new Error('Current space not set');
            }
            return this.currentSpaceId;
        }
    };

    getCurrentSpaceId = {
        getSpaceId: () => {
            return this.currentSpaceId;
        }
    };

    constructor() {
        this.api = createChefSpaceClient(this.apiTokenResolver, this.spaceIdResolver, { apiRoot: AWSConfiguration.ApiBaseUrl });
    }

    initializeClient() {
        this.api = createChefSpaceClient(this.apiTokenResolver, this.spaceIdResolver, { apiRoot: AWSConfiguration.ApiBaseUrl });
    }



    @action
    async changeCurrentSpace(newSpace: string) {
        this.currentSpaceId = newSpace;
    }

    @action
    async clearCurrentSpace() {
        this.currentSpaceId = undefined;
    }

    @action
    async switchToSpace(spaceId: string) {
        await this.changeCurrentSpace(spaceId);
        routerStoreHelper.routerStore.history.push(dashboardPath(spaceId));
    }

}

const apiStore = new ApiStore();
export default apiStore;
