import { RouterStore } from 'mobx-react-router';
import { ContentId } from '@contentchef/contentchef-types';
import { ContentRepositoryId } from '@contentchef/contentchef-types';
import { RouterStoreHelperModel } from './routerStoreHelperModel';
import {
    editContentPath,
    editContentDefinitionPath,
    editRepositoryPath,
    mediaGalleryBasePath
} from '../../constants/routing-constants';
import { ContentDefinitionId } from '@contentchef/contentchef-types';

class RouterStoreHelper implements RouterStoreHelperModel {
    routerStore: RouterStore;
    constructor(routerStore: RouterStore) {
        this.routerStore = routerStore;
    }

    onSuccessfulContentCreate(spaceId: string, contentId: ContentId) {
        this.routerStore.history.replace(editContentPath(spaceId, contentId));
    }

    onSuccessfulContentDefinitionCreate(spaceId: string, contentDefinitionId: ContentDefinitionId) {
        this.routerStore.history.push(editContentDefinitionPath(spaceId, contentDefinitionId));
    }

    onSuccessfulRepositoryCreate(spaceId: string, repositoryId: ContentRepositoryId) {
        this.routerStore.history.push(editRepositoryPath(spaceId, repositoryId));
    }

    onSuccessfulDeleteMediaGallery(spaceId: string) {
        this.routerStore.history.push(mediaGalleryBasePath(spaceId));
    }
}

export default RouterStoreHelper;
