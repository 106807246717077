import React from 'react';
import { InjectedIntl, FormattedMessage } from 'react-intl';
import { ErrorObjectModel } from '../../stores/contentStore/contentStoreModel';
import { notificationStore, formMetaStore } from '../../stores';
import { NOTIFICATION_DEFAULT_MESSAGES } from '../../constants/notifications-constants';
import { FieldViolationModel } from '../../stores/formMetaStore/formMetaStoreModel';

export interface ContentValidationError {
    message: string;
    type: string;
    validation?: ErrorObjectModel['violations'];
}

export enum ContentErrorTypes {
    PublicIdNotExclusiveToDefinitionId = 'PublicIdNotExclusiveToDefinitionId',
    FailedContentPayloadValidation = 'FailedContentPayloadValidation',
    UnexpectedError = 'UnexpectedError'
}

export class ContentError {
    message: FormattedMessage.MessageDescriptor;
    type: string;
    violations?: ErrorObjectModel['violations'];

    constructor(
        message: FormattedMessage.MessageDescriptor,
        type: string,
        violations?: ErrorObjectModel['violations']
    ) {
        this.message = message;
        this.type = type;
        this.violations = violations;
    }

    handleError(formatMessage: InjectedIntl['formatMessage'], action?: FormattedMessage.MessageDescriptor) {
        notificationStore.openNotificationWithIcon('error', {
            message: (
                <p>
                    {formatMessage(this.message, action ? { action: formatMessage(action) } : {})}
                </p>
            )
        });
    }

    maybeHandleViolations() {
        if (this.hasViolations()) {
            formMetaStore.setValidationErrors(this.violations as FieldViolationModel[]);
        }
    }

    private hasViolations() {
        return this.violations && this.violations.length > 0;
    }

}

export class ContentErrorFactory {
    static createContentError(error: ContentValidationError) {
        switch (error.type) {
            case ContentErrorTypes.PublicIdNotExclusiveToDefinitionId:
                return new ContentError(
                    NOTIFICATION_DEFAULT_MESSAGES.publicIdNotExclusive,
                    error.type
                );
            case ContentErrorTypes.FailedContentPayloadValidation:
                return new ContentError(
                    NOTIFICATION_DEFAULT_MESSAGES.contentPayloadValidationFailed,
                    error.type,
                    error.validation
                );
            default:
                return new ContentError(
                    NOTIFICATION_DEFAULT_MESSAGES.errorNotificationMessage,
                    ContentErrorTypes.UnexpectedError
                );
        }
    }
}
