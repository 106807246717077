import React, { Component, FormEvent } from 'react';
import { withForm, withFormInitialization } from '../../hoc';
import { Row, Col, Form, Input, Switch, Icon } from 'antd';
import Button from '@components/Button';
import { FormattedMessage } from 'react-intl';
import { formFieldNormalize } from '../../services/FormUtils/FormFieldNormalize';
import CountryInput from '../CountryInput';
import classes from './index.module.scss';
import { FormComponentProps } from 'antd/lib/form';
import { BillingInformation } from '@contentchef/contentchef-types';
import { isBillingInformation } from '../../stores/subscriptionStore';

interface BillingFormProps extends FormComponentProps {
    existingBillingInformation?: Partial<BillingInformation>;
    onSubmit(values: BillingInformation): Promise<void>;
}

interface BillingFormState {
    isSubmitting: boolean;
}

const { Item } = Form;

const defaultBillingInformation: BillingInformation = {
    address_line1: '',
    address_line2: undefined,
    city: '',
    country: '',
    state: '',
    tax_id: undefined,
    zip: '',
    name: '',
};

function getBillingInformation(billingInformation?: Partial<BillingInformation>): BillingInformation {
    if (isBillingInformation(billingInformation)) {
        return billingInformation;
    }

    return defaultBillingInformation;
}

class BillingForm extends Component<BillingFormProps, BillingFormState> {

    state = {
        isSubmitting: false
    };

    onSubmit = (e: FormEvent) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values: BillingInformation) => {
            if (!err) {
                this.setState({
                    isSubmitting: true
                });
                await this.props.onSubmit(values);
                this.setState({
                    isSubmitting: false
                });
            }
        });
    }

    render() {
        const { existingBillingInformation, form: { getFieldDecorator } } = this.props;
        const { isSubmitting } = this.state;
        const billingInformation = getBillingInformation(existingBillingInformation);
        return (
            <Form id="billing-form" onSubmit={this.onSubmit}>
                <Row gutter={16}>
                    <Col xs={24}>
                        <Item
                            label={
                                <FormattedMessage
                                    id="BillingForm.labels.companyCheck"
                                    defaultMessage="Are you a company?"
                                />
                            }
                            className={classes.CompanyFormItem}
                        >
                            {getFieldDecorator('company', {
                                normalize: formFieldNormalize('emptyStringToUndefined'),
                                initialValue: billingInformation.company,
                                valuePropName: 'checked',
                                rules: [{
                                    required: false
                                }]
                            })(
                                <Switch
                                    checkedChildren={<Icon type="bank" />}
                                    unCheckedChildren={<Icon type="user" />}
                                />
                            )}
                        </Item>
                    </Col>
                    {this.props.form.getFieldValue('company') &&
                        <Col xs={24}>
                            <Item
                                label={
                                    <FormattedMessage
                                        id="BillingForm.labels.taxId"
                                        defaultMessage="Tax Id"
                                    />
                                }
                            >
                                {getFieldDecorator('tax_id', {
                                    normalize: formFieldNormalize('emptyStringToUndefined'),
                                    initialValue:
                                        billingInformation.tax_id ? billingInformation.tax_id.substr(2) : undefined,
                                    rules: [{
                                        required: false
                                    }]
                                })(<Input type="text" />)}
                            </Item>
                        </Col>
                    }
                    <Col xs={24}>
                        <Item
                            label={
                                <FormattedMessage
                                    id="BillingForm.labels.name"
                                    defaultMessage="Name/Company"
                                />
                            }
                        >
                            {getFieldDecorator('name', {
                                normalize: formFieldNormalize('emptyStringToUndefined'),
                                initialValue: billingInformation.name,
                                rules: [{
                                    required: true
                                }]
                            })(<Input type="text" />)}
                        </Item>
                    </Col>
                    <Col xs={24}>
                        <Item
                            label={
                                <FormattedMessage
                                    id="BillingForm.labels.address1"
                                    defaultMessage="Address 1"
                                />
                            }
                        >
                            {getFieldDecorator('address_line1', {
                                normalize: formFieldNormalize('emptyStringToUndefined'),
                                initialValue: billingInformation.address_line1,
                                rules: [{
                                    required: true
                                }]
                            })(<Input type="text" />)}
                        </Item>
                    </Col>
                    <Col xs={24}>
                        <Item
                            label={
                                <FormattedMessage
                                    id="BillingForm.labels.address2"
                                    defaultMessage="Address 2"
                                />
                            }
                        >
                            {getFieldDecorator('address_line2', {
                                normalize: formFieldNormalize('emptyStringToUndefined'),
                                initialValue: billingInformation.address_line2,
                                rules: [{
                                    required: false
                                }]
                            })(<Input type="text" />)}
                        </Item>
                    </Col>
                </Row>
                <Row>
                    <Row gutter={16}>
                        <Col xs={12}>
                            <Item
                                label={
                                    <FormattedMessage
                                        id="BillingForm.labels.state"
                                        defaultMessage="State/Province"
                                    />
                                }
                            >
                                {getFieldDecorator('state', {
                                    normalize: formFieldNormalize('emptyStringToUndefined'),
                                    initialValue: billingInformation.state,
                                    rules: [{
                                        required: true
                                    }]
                                })(<Input type="text" />)}
                            </Item>
                        </Col>
                        <Col xs={12}>
                            <Item
                                label={
                                    <FormattedMessage
                                        id="BillingForm.labels.city"
                                        defaultMessage="City"
                                    />
                                }
                            >
                                {getFieldDecorator('city', {
                                    normalize: formFieldNormalize('emptyStringToUndefined'),
                                    initialValue: billingInformation.city,
                                    rules: [{
                                        required: true
                                    }]
                                })(<Input type="text" />)}
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={12}>
                            <Item
                                label={
                                    <FormattedMessage
                                        id="BillingForm.labels.country"
                                        defaultMessage="Country"
                                    />
                                }
                            >
                                {getFieldDecorator('country', {
                                    normalize: formFieldNormalize('emptyStringToUndefined'),
                                    initialValue: billingInformation.country,
                                    rules: [{
                                        required: true
                                    }]
                                })(<CountryInput />)}
                            </Item>
                        </Col>
                        <Col xs={12}>
                            <Item
                                label={
                                    <FormattedMessage
                                        id="BillingForm.labels.zip"
                                        defaultMessage="ZIP"
                                    />
                                }
                            >
                                {getFieldDecorator('zip', {
                                    normalize: formFieldNormalize('emptyStringToUndefined'),
                                    initialValue: billingInformation.zip,
                                    rules: [{
                                        required: true
                                    }]
                                })(<Input type="text" />)}
                            </Item>
                        </Col>
                    </Row>
                    <div className={classes.BillingFormActionsContainer}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isSubmitting}
                            disabled={isSubmitting}
                        >
                            <FormattedMessage
                                id="Billing.labels.completeOrder"
                                defaultMessage="save"
                            />
                        </Button>
                    </div>
                </Row>
            </Form>
        );
    }
}

export default withFormInitialization(withForm(BillingForm));
