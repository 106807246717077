import { TypographyWithIcon } from '@components/Typography';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import styles from './index.module.scss';
interface Props extends InjectedIntlProps {
    label: string;
    icon?: JSX.Element;
    actions?: React.ReactNode[];
}

function AsideHeader({ label, icon, actions }: Props) {
    return (
        <div
            className={styles.Container}
        >
            <TypographyWithIcon
                gutter={false}
                className={styles.Heading}
                icon={icon}
            >
                {label}
            </TypographyWithIcon>

            {actions && actions.length > 0 && (
                <div className={styles.Actions}>
                    {actions}
                </div>
            )}
        </div>
    );
}

export default injectIntl(AsideHeader);
