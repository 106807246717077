import { inject } from 'mobx-react';
import React, { Component } from 'react';
import MonacoEditor from 'react-monaco-editor';
import styles from './index.module.scss';
import { InjectedProps, MonacoEditorJSONProps } from './MonacoEditorJSONModel';

@inject('contentDefinitionSchemaStore')
export default class MonacoEditorJSON extends Component<MonacoEditorJSONProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    editorWillMount = (monaco: any) => {
        const { schema } = this.props;
        if (schema) {
            monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
                validate: true,
                enableSchemaRequest: true,
                schemas: [
                    {
                        fileMatch: ['*'],
                        schema
                    }
                ]
            });
        }
    }

    onChange = (value: string) => {
        this.props.onChange(value);
    }

    render() {
        const { id, hasPermissions } = this.props;
        const { jsonEditorSchema } = this.injected.contentDefinitionSchemaStore;
        return (
            <MonacoEditor
                {...{ id }}
                editorWillMount={this.editorWillMount}
                language="json"
                options={{
                    automaticLayout: true,
                    formatOnPaste: true,
                    formatOnType: true,
                    readOnly: !hasPermissions,
                    extraEditorClassName: styles.EditorStyle
                }}
                defaultValue={JSON.stringify(jsonEditorSchema, undefined, 4)}
                onChange={this.onChange}
            />
        );
    }
}
