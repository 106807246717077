import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import { ContentInfo, Extension } from '@contentchef/contentchef-types';
import { DynamicFieldTypes } from '@services/FormUtils/FormFields';
import ExtensionStore from '@stores/extensionStore';
import { FormMetaStoreModel } from '@stores/formMetaStore/formMetaStoreModel';
import { observable, ObservableMap } from 'mobx';

export class ExtensionManager {
    @observable extensions: ObservableMap<string, ExtensionStore> = observable.map();
    private spaceExtensions: Map<string, Extension> = new Map();

    constructor(private api: ContentChefClient) { }

    add(field: DynamicFieldTypes, contentInfo: ContentInfo, formMetaStore: FormMetaStoreModel) {
        const extension = this.spaceExtensions.get(field.extension!);
        if (extension) {
            this.extensions.set(field.uniqueId, new ExtensionStore(
                this.api,
                field,
                extension,
                contentInfo,
                formMetaStore
            ));
        }
    }

    remove(uniqueId: string) {
        const extension = this.get(uniqueId);
        if (extension) {
            extension.deregister();
            this.extensions.delete(uniqueId);
        }

    }

    destroy() {
        this.extensions.forEach(extension => {
            extension.deregister();
        });
        this.extensions.clear();
    }

    get(uniqueId: string) {
        return this.extensions.get(uniqueId);
    }

    async setSpaceExtensions() {
        try {
            const result = await this.api.extension.list();
            result.forEach(extension => {
                this.spaceExtensions.set(extension.mnemonicId, extension);
            });
            return this.spaceExtensions;
        } catch (error) {
            console.error('Failed to retrieve extension');
            return Promise.reject(error);
        }
    }
}
