import React from 'react';
import { OnboardingStoreModel, OnboardingFlow } from '../../stores/onboardingStore';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';
import SubscriptionCreationWizard from '../../scenes/SubscriptionCreationWizard';
import withOnboarding from '../../hoc/withOnboarding';
import NavbarLayout from '../../hoc/NavbarLayout/NavbarLayout';
import HandleInvite from './HandleInvite';
import AcceptTermsAndConditions from './UpdateConsents';
import {
    ONBOARDING_UPDATE_CONSENTS_ROUTE,
    ACCEPT_INVITE_ROUTE,
    ONBOARDING_WIZARD_ROUTE
} from '../../constants/routing-constants';
import Loader from '../Loader/Loader';
import UsersStore from '../../stores/usersStore';

interface DecoratedProps extends RouteComponentProps {
    onboardingStore: OnboardingStoreModel;
    usersStore: UsersStore;
}

@inject('onboardingStore', 'usersStore')
@observer
class OnboardingHandler extends React.Component<RouteComponentProps> {
    protected get decoratedProps(): DecoratedProps {
        return this.props as DecoratedProps;
    }

    public async componentDidMount() {
        const { onboardingStore, usersStore } = this.decoratedProps;

        await usersStore.getCurrentUser();
        await onboardingStore.postSignIn();
        this.forceUpdate();
    }

    public componentDidUpdate(prevProps: DecoratedProps) {
        if (prevProps.onboardingStore.onboardingFlowState !== this.decoratedProps.onboardingStore.onboardingFlowState) {
            this.handleRouteRewrite();
        }
    }

    public handleRouteRewrite() {
        switch (this.decoratedProps.onboardingStore.onboardingFlowState) {
            case OnboardingFlow.MustAcceptConsents:
                this.props.history.replace(ONBOARDING_UPDATE_CONSENTS_ROUTE);
                break;
            case OnboardingFlow.Invite:
                this.props.history.replace(ACCEPT_INVITE_ROUTE);
                break;
            case OnboardingFlow.Wizard:
                this.props.history.replace(ONBOARDING_WIZARD_ROUTE);
                break;
            default:
                break;
        }
    }

    public render() {
        switch (this.decoratedProps.onboardingStore.onboardingFlowState) {
            case OnboardingFlow.MustAcceptConsents:
                return (
                    <AcceptTermsAndConditions />
                );
            case OnboardingFlow.NotDecided:
                return <Loader />;
            case OnboardingFlow.Invite:
                return <HandleInvite />;
            case OnboardingFlow.Wizard:
                return (
                    <NavbarLayout hideActions={true}>
                        <SubscriptionCreationWizard />
                    </NavbarLayout>
                );
            default:
                return this.props.children;
        }
    }
}

export default withOnboarding(withRouter(OnboardingHandler));
