export function stringToArrayOfInt(value: string) {
    return value.split(',').map(val => +val);
}

export function stringToArrayOfString(value: string) {
    return value.split(',');
}

export function complexStringToArrayOfIds(value: string) {
    const arrayOfStringFilters = value.split(',');
    return arrayOfStringFilters.map(result => {
        const arrayOfFilters = result.split('|');
        return +arrayOfFilters[0];
    });
}
