import { Col, Row } from 'antd';
import classNames from 'classnames';
import React, { Component } from 'react';
import { sceneContainerDimensions } from '../../constants/layout-sizes';
import styles from './SceneContainer.module.scss';
import { SceneContainerProps } from './SceneContainerModel';

const { columns } = sceneContainerDimensions;

class SceneContainer extends Component<SceneContainerProps> {
    render() {
        const {
            dataAttributes = {},
            children,
            flex = false
        } = this.props;
        const className = classNames(styles.SceneContainerBodyWrapper, {
            [styles.FlexColumnContainer]: flex
        });
        return (
            <Row {...dataAttributes} className={styles.sceneContainerRowWrapper}>
                <Col {...columns} className={className}>{children}</Col>
            </Row>
        );
    }
}
export default SceneContainer;
