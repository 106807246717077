import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'antd';
import classNames from 'classnames';
import classes from './index.module.scss';
import {
    withPermissionsToInteract,
    WithPermissionsToInteractReturnType
} from '../../providers/UserPermissionsProvider';
import Button from '@components/Button';

export interface ContentDefinitionAddElementProps extends Partial<React.ComponentPropsWithRef<'div'>>,
    WithPermissionsToInteractReturnType {
    disabled?: boolean;
    onClick?(): any;
}

function ContentDefinitionAddElement(
    {
        className,
        disabled,
        onClick,
        hasPermissions,
        ...props
    }: ContentDefinitionAddElementProps
) {
    return (
        <div
            className={
                classNames(
                    classes.ContentDefinitionAddElement,
                    {
                        [classes.ContentDefinitionAddElementDisabled]: disabled
                    },
                    className,
                )
            }
            {
            ...props
            }
        >
            <Button onClick={onClick} disabled={!hasPermissions}>
                <Icon type="plus" />
                <FormattedMessage
                    defaultMessage="Add field"
                    id="components.DefinitionSchemaEditor.ContentDefinitionAddElement.button.label"
                />
            </Button>
        </div>
    );
}

export default withPermissionsToInteract(ContentDefinitionAddElement);
