import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, Empty } from 'antd';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import moment from 'moment';
import { PublishingRequestsSummaryProps, InjectedProps } from './PublishingRequestsSummaryModel';
import { getPublishingRequestsStatusCode } from '../../constants/publishing-label-constants';
import { DATE_TIME_FORMAT } from '../../constants/date-constants';
import { Loader } from '..';

import styles from './PublishingRequestsSummary.module.scss';

const publishingRequestsSummaryLabels = defineMessages({
    requestDate: {
        id: 'PublishingRequestsSummary.requestDate',
        defaultMessage: 'Created on {formattedDate}'
    }
});

@inject('publishingStore')
@observer
class PublishingRequestsSummary extends Component<PublishingRequestsSummaryProps> {
    get injected() {
        return this.props as InjectedProps;
    }
    componentWillUnmount() {
        this.injected.publishingStore.clearPollingPublishingRequests();
    }

    render() {
        const { publishingRequests, isLoading } = this.injected.publishingStore;
        const { formatMessage } = this.props.intl;
        return (
            <Row className={styles.PublishingRequestsSummaryContainer}>
                <Col xs={24}>
                    {!isLoading ?
                        (
                            <Col xs={24} className={styles.PublishingRequestsSummaryRequestWrapper}>
                                {publishingRequests.length > 0 ? publishingRequests.map((publishingRequest) => {
                                    return (
                                        <Col
                                            key={publishingRequest.id}
                                            xs={24}
                                            className={styles.PublishingRequestsSummaryRequest}
                                        >
                                            <p className={styles.PublishingRequestsSummaryRequestInfo}>
                                                {
                                                    formatMessage(
                                                        publishingRequestsSummaryLabels.requestDate,
                                                        {
                                                            formattedDate:
                                                                moment(publishingRequest.createdDate)
                                                                    .format(DATE_TIME_FORMAT)
                                                        }
                                                    )
                                                }
                                            </p>
                                            <p
                                                className={
                                                    `${
                                                    styles.PublishingRequestsSummaryRequestStatus
                                                    } ${styles[publishingRequest.status]}`
                                                }
                                            >
                                                {formatMessage(
                                                    getPublishingRequestsStatusCode(publishingRequest.status))
                                                }
                                            </p>
                                        </Col>
                                    );
                                }) :
                                    <div
                                        className={styles.PublishingRequestsSummaryEmpty}
                                    >
                                        <Empty
                                            description={
                                                <FormattedMessage
                                                    id="PublishingRequestsSummary.emptyRequests"
                                                    defaultMessage="This content has not been published yet."
                                                />
                                            }
                                        />
                                    </div>
                                }
                            </Col>
                        ) :
                        <div
                            className={styles.PublishingRequestsSummaryLoader}
                        >
                            <Loader style={{ fontSize: 50 }} />
                        </div>
                    }
                </Col>
            </Row>
        );
    }
}

export default injectIntl(PublishingRequestsSummary);
