import { SchemaTypeIds } from '@contentchef/contentchef-types';

export const TEXT_SCHEMA: TextSchemaInterface = {
    type: 'object',
    properties: {
        maxlength: {
            type: 'number',
            title: 'TITLe',
            description: 'Text tipologies',
            enumNames: ['Short text. 250 characters', 'Long Text. 500 characters'],
            enum: [250, 500],
            default: 250,
        },
        finalFieldDisplay: {
            type: 'string',
            title: 'TITLe',
            description: 'Final field display',
            enum: ['One line text', 'URL'],
            default: 'One line text',
        },
        validations: {
            description: 'Validations',
            type: 'boolean',
            default: false,
        },
    },
};

interface TextSchemaInterface {
    type: string;
    properties: PropertiesInterface;
}

interface PropertiesInterface {
    maxlength: MaxLengthInterface;
    finalFieldDisplay: FinalFieldDisplayInterface;
    validations: ValidationsInterface;
}

interface MaxLengthInterface {
    type: string;
    title: string;
    description: string;
    enumNames: string[];
    enum: number[];
    default: number;
}

interface FinalFieldDisplayInterface {
    type: string;
    title: string;
    description: string;
    enum: string[];
    default: string;
}

interface ValidationsInterface {
    description: string;
    type: string;
    default: boolean;
}

export const TEXT_UI_SCHEMA: TextUiSchemaInterface = {
    maxlength: {
        'ui:widget': 'radio',
    },
    finalFieldDisplay: {
        'ui:widget': 'radio',
    },
    validations: {
        'ui:widget': 'checkbox',
    },
};

interface TextUiSchemaInterface {
    maxlength: UiWidgetInterface;
    finalFieldDisplay: UiWidgetInterface;
    validations: UiWidgetInterface;
}

export const NUMBER_SCHEMA = {
    type: 'object',
    properties: {
        range: {
            type: 'object',
            title: 'Range',
            properties: {
                minimum: {
                    type: 'number',
                },
                maximum: {
                    type: 'number',
                },
            },
        },
    },
};

export const NUMBER_UI_SCHEMA: NumberUiSchemaInterface = {
    range: {
        minimum: { 'ui:widget': 'range' },
        maximum: { 'ui:widget': 'range' },
    },
};

interface NumberUiSchemaInterface {
    range: RangeTypeInterface;
}

interface RangeTypeInterface {
    minimum: UiWidgetInterface;
    maximum: UiWidgetInterface;
}

interface UiWidgetInterface {
    'ui:widget': string;
}

export const TIME_SCHEMA: object = {};
export const EMBEDDED_SCHEMA: object = {};
export const MEDIA_SCHEMA: object = {};
export const schemaTypeArray = [
    SchemaTypeIds.TEXT,
    SchemaTypeIds.LONG_TEXT,
    SchemaTypeIds.CLOUDINARY_PUBLICID,
    SchemaTypeIds.CLOUDINARY_IMAGE,
    SchemaTypeIds.CLOUDINARY_VIDEO,
    SchemaTypeIds.CLOUDINARY_RAW,
    SchemaTypeIds.NUMBER,
    SchemaTypeIds.BOOLEAN,
    SchemaTypeIds.DATE,
    SchemaTypeIds.FRAGMENT,
    SchemaTypeIds.ONE_FRAGMENT_OF,
    SchemaTypeIds.ONE_LINKED_CONTENT_OF,
    SchemaTypeIds.ARRAY,
    SchemaTypeIds.LINKED_CONTENT,
];
