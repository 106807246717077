import {
    Field,
    NumberSchemaType,
    SchemaTypeIds
} from '@contentchef/contentchef-types';

function simpleNumber(defaultLanguage: string, name: string): Field<NumberSchemaType> {
    return {
        name,
        labels: {
            [defaultLanguage]: name
        },
        hint: undefined,
        placeholder: undefined,
        enableFacet: undefined,
        type: SchemaTypeIds.NUMBER,
        constraints: {
            required: false,
            min: undefined,
            max: undefined
        },
        compact: undefined
    };
}

function simpleNumberInArray(): NumberSchemaType {
    return {
        type: SchemaTypeIds.NUMBER,
        enableFacet: undefined,
        constraints: {
            min: undefined,
            max: undefined
        }
    };
}

function listOfNumberValues(defaultLanguage: string, name: string): Field<NumberSchemaType> {
    return {
        name,
        labels: {
            [defaultLanguage]: name
        },
        hint: undefined,
        placeholder: undefined,
        enableFacet: false,
        type: SchemaTypeIds.NUMBER,
        constraints: {
            required: false,
            listOfValues: []
        }
    };
}

function listOfNumberValuesInArray(): NumberSchemaType {
    return {
        type: SchemaTypeIds.NUMBER,
        enableFacet: undefined,
        constraints: {
            listOfValues: []
        }
    };
}

export const numberSnippets = {
    asField: {
        simpleNumber,
        listOfNumberValues,
    },
    asArray: {
        simpleNumberInArray,
        listOfNumberValuesInArray
    }
};
