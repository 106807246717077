import {
    ListMediaGalleryFilters,
    ListMediaGalleryRequest,
    ListMediaGalleryResponse
} from '@contentchef/contentchef-types';
import { SearchParamsStoreModel } from '@stores/searchParamsStore/searchParamsStoreModel';
import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import { action, computed, observable, runInAction } from 'mobx';
import SearchParamsStore from '@stores/searchParamsStore/searchParamsStore';
import { MEDIAGALLERY_CONSTANTS } from '@constants/media-gallery';
import { RepositoryListUrlParams } from '@stores/repositoryListStore/repositoryListStoreModel';

export interface MediaGalleryListStoreModel {
    pagedMediaGalleryList: ListMediaGalleryResponse;
    listFilters: ListMediaGalleryFilters;
    loaders: MediaGalleryListLoaders;
    searchParams: SearchParamsStoreModel;
    canResetAllFilters: boolean;
    setMediaGalleries(): void;
    setListFilterByName(value?: string): void;
    setListFilterByArchived(archived?: boolean): void;
    resetAllFilters(): void;
}

export enum MediaGalleryListUrlParams {
    byPage = 'page',
    bySize = 'size',
    byName = 'search',
    byArchived = 'archived'
}

export type MediaGalleryListLoaders = {
    list: boolean;
};

class MediaGalleryListStore implements MediaGalleryListStoreModel {
    api: ContentChefClient;
    searchParams: SearchParamsStoreModel = {} as SearchParamsStoreModel;
    @observable loaders: MediaGalleryListLoaders = {
        list: false
    };
    @observable listFilters: ListMediaGalleryFilters = {
        name: undefined,
        archived: undefined,
    };
    @observable pagedMediaGalleryList: ListMediaGalleryResponse = {
        skip: 0,
        take: 0,
        items: observable.array([]),
        total: 0
    };

    constructor(api: ContentChefClient) {
        this.api = api;
        this.searchParams = new SearchParamsStore();
        this.listFilters = {
            name: this.searchParams.usableSearchParams[RepositoryListUrlParams.byName],
            archived: !!this.searchParams.usableSearchParams[RepositoryListUrlParams.byArchived]
        };
    }

    @action.bound
    setListFilterByName = (value?: string) => {
        this.listFilters.name = value;
    }

    @action.bound
    setListFilterByArchived = (value?: boolean) => {
        this.listFilters.archived = value;
    }

    @action.bound
    resetAllFilters() {
        this.listFilters = {
            name: undefined,
            archived: undefined,
        };
    }

    @computed
    get canResetAllFilters() {
        return this.listFilters.name !== undefined ||
            !!this.listFilters.archived ||
            this.pagedMediaGalleryList.skip !== MEDIAGALLERY_CONSTANTS.DEFAULT_SKIP ||
            this.pagedMediaGalleryList.take !== MEDIAGALLERY_CONSTANTS.DEFAULT_TAKE;
    }

    @action.bound
    async setMediaGalleries() {
        const reqParams: ListMediaGalleryRequest = {
            skip: this.getSkipValue(this.getTakeValue()),
            take: this.getTakeValue(),
            filters: {
                name: this.listFilters.name,
                archived: this.listFilters.archived
            }
        };
        try {
            this.loaders.list = true;
            const response = await this.api.mediaGallery.list(reqParams);
            runInAction(() => {
                this.pagedMediaGalleryList.skip = response.skip;
                this.pagedMediaGalleryList.take = response.take;
                this.pagedMediaGalleryList.total = response.total;
                this.pagedMediaGalleryList.items = response.items;
                this.loaders.list = false;
            });
        } catch (e) {
            runInAction(() => {
                console.log('Error while retrieving list media gallery');
                this.loaders.list = false;
            });
        }
    }

    private getSkipValue = (takeValue: number) => {
        const { urlSearchParams, usableSearchParams } = this.searchParams;
        return urlSearchParams.has(MediaGalleryListUrlParams.byPage)
            ? (+usableSearchParams[MediaGalleryListUrlParams.byPage] - 1) * takeValue
            : MEDIAGALLERY_CONSTANTS.DEFAULT_SKIP;
    }

    private getTakeValue = () => {
        const { urlSearchParams, usableSearchParams } = this.searchParams;
        return urlSearchParams.has(MediaGalleryListUrlParams.bySize)
            ? (+usableSearchParams[MediaGalleryListUrlParams.bySize]) : MEDIAGALLERY_CONSTANTS.DEFAULT_TAKE;
    }
}

export default MediaGalleryListStore;
