import React, { Component } from 'react';
import { TableWrapperProps, TableWrapperState } from './TableWrapperModel';
import { Row, Col } from 'antd';
import { sceneContainerDimensions } from '../../constants/layout-sizes';

import styles from './TableWrapper.module.scss';

const { columns } = sceneContainerDimensions;

class TableWrapper extends Component<TableWrapperProps, TableWrapperState> {
    render() {
        const { header, children, footer, dataAttributes } = this.props;
        return (
            <Row
                {...dataAttributes}
                className={styles.TableWrapperContainer}
            >
                <Col {...columns} className={styles.TableWrapperHeader}>
                    {header}
                </Col>
                <Col {...columns} className={styles.TableWrapperContent}>
                    {children}
                </Col>
                <Col {...columns} className={styles.TableWrapperFooter}>
                    {footer}
                </Col>
            </Row>
        );
    }
}

export default TableWrapper;
