import {
    Field,
    SchemaTypeIds,
    SlugSchemaType,
    SlugConstraints as SlugFieldConstraints
} from '@contentchef/contentchef-types';
import { SerializedField } from '../types';
import { ListItemIds } from '../../types';

export interface SlugConstraints extends SlugFieldConstraints {
    required: boolean;
}

export type SerializedSlugSchema = SerializedField<SlugConstraints>;

export function retrieveSlugEditingType(constraints: SlugFieldConstraints): ListItemIds {
    return ListItemIds.Slug;
}

export function deserialize(serialized: SerializedSlugSchema): Field<SlugSchemaType> {
    return {
        constraints: {
            required: serialized.constraints.required!,
            suffix: serialized.constraints.suffix && serialized.constraints.suffix.length > 0 ?
                serialized.constraints.suffix : undefined,
            prefix: serialized.constraints.prefix && serialized.constraints.prefix.length > 0 ?
                serialized.constraints.prefix : undefined,
            allowSlash: serialized.constraints.allowSlash
        },
        labels: serialized.labels,
        placeholder: Object.keys(serialized.placeholder).length > 0 ? serialized.placeholder : undefined,
        hint: Object.keys(serialized.hint).length > 0 ? serialized.hint : undefined,
        name: serialized.name,
        type: SchemaTypeIds.SLUG,
        extension: serialized.extension,
        compact: serialized.compact
    };
}

export function serialize(deserialized: Field<SlugSchemaType>, initialIndex: number): SerializedSlugSchema {
    return {
        constraints: { ...deserialized.constraints },
        labels: deserialized.labels,
        hint: deserialized.hint || {},
        placeholder: deserialized.placeholder || {},
        name: deserialized.name,
        settings: {},
        type: deserialized.type,
        editingType: retrieveSlugEditingType(deserialized.constraints),
        initialIndex,
        extension: deserialized.extension,
        compact: deserialized.compact
    };
}
