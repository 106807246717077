import { defineMessages } from 'react-intl';

export const NAVIGATION_LABELS = defineMessages({
    dashboard: {
        id: 'Navigation.dashboard',
        defaultMessage: 'Dashboard'
    },
    repositories: {
        id: 'Navigation.repositories',
        defaultMessage: 'Repositories'
    },
    contents: {
        id: 'Navigation.contents',
        defaultMessage: 'Contents'
    },
    publish: {
        id: 'Navigation.publish',
        defaultMessage: 'Publish to live'
    },
    mediaGalleries: {
        id: 'Navigation.mediaGalleries',
        defaultMessage: 'Media Galleries'
    },
    publishingChannels: {
        id: 'Navigation.publishingChannels',
        defaultMessage: 'Publishing channels'
    },
    contentDefinitions: {
        id: 'Navigation.contentDefinitions',
        defaultMessage: 'Definitions'
    },
    newContentDefinition: {
        id: 'Navigation.contentDefinitions.new',
        defaultMessage: 'New'
    },
    editContentDefinition: {
        id: 'Navigation.contentDefinitions.edit',
        defaultMessage: 'Edit'
    },
    usersAndPermissions: {
        id: 'Navigation.usersAndPermissions',
        defaultMessage: 'Users and Permissions'
    },
    webhooks: {
        id: 'Navigation.webhooks',
        defaultMessage: 'Webhooks'
    },
    editWithValue: {
        id: 'Navigation.editWithValue',
        defaultMessage: 'Edit ({value})'
    },
    edit: {
        id: 'Navigation.edit',
        defaultMessage: 'Edit'
    },
    customFields: {
        id: 'Navigation.customFields',
        defaultMessage: 'Custom Fields'
    },
    new: {
        id: 'Navigation.new',
        defaultMessage: 'New'
    },
    apiKeys: {
        id: 'Navigation.apiKeys',
        defaultMessage: 'API Keys'
    },
    extensions: {
        id: 'Navigation.extensions',
        defaultMessage: 'Extensions'
    },
    spaceSettings: {
        id: 'Navigation.spaceSettings',
        defaultMessage: 'Space Settings'
    }
});
