import moment from 'moment';
import React from 'react';
import { DATE_TIME_FORMAT, TIME_FORMAT } from '../constants/date-constants';
// import { PAGINATION_SIZE } from '@/constants/tables';

const DEFAULT_INITIALS = 'CC';

export const isValidName = (name: string) => name.includes(' ');

export const nameToInitials = (name: string) => {
    if (!name || name.length < 1 || typeof name !== 'string') {
        return DEFAULT_INITIALS;
    } else if (!isValidName(name)) {
        return name.length === 2
            ? name.toUpperCase()
            : `${name[0]}${name[name.length - 1]}`.toUpperCase();
    }
    const [firstName, lastName] = name.toUpperCase().split(' ');
    return firstName[0] + lastName[0];
};

// DATE PARSERS
export const parseDate = (date: Date | string) => moment(date).format(DATE_TIME_FORMAT);
export const parseHour = (date: Date | string) => moment(date).format(TIME_FORMAT);

// TABLE OVERFLOW CALCULATOR
export const isPercentageWidth = (width: unknown) =>
    typeof width === 'string' && width.includes('%');

export function isFunction<T>(arg?: T): arg is T {
    return typeof arg === 'function';
}

// Creating repositories ID's
export const slugify = (text: string) =>
    text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of tex

export type Procedure = (...args: any[]) => void;

export type Options = {
    isImmediate: boolean,
};

export function debounce<F extends Procedure>(
    func: F,
    waitMilliseconds: number = 400,
    options: Options = {
        isImmediate: false
    },
): F {
    let timeoutId: number | null = null;

    return function(this: any, ...args: any[]) {
        const context = this;

        const doLater = function() {
            timeoutId = null;
            if (!options.isImmediate) {
                func.apply(context, args);
            }
        };

        const shouldCallNow = options.isImmediate && timeoutId === null;

        if (timeoutId !== null) {
            window.clearTimeout(timeoutId);
        }

        timeoutId = window.setTimeout(doLater, waitMilliseconds);
        if (shouldCallNow) {
            func.apply(context, args);
        }
    } as any;
}

export function createRange(start: number, end: number) {
    const result: number[] = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}

export function copyToClipboard(elementId: string, valueToCopy: string) {
    var dummyInput = document.createElement('input');
    dummyInput.setAttribute('id', elementId);
    document.body.appendChild(dummyInput);
    const inputElement = document.getElementById(elementId) as HTMLInputElement;
    inputElement.value = valueToCopy;
    dummyInput.select();
    document.execCommand('copy');
    document.body.removeChild(dummyInput);
}

export function getAvatarBackgroundColor(inputString: string, saturation: number, lightness: number) {
    let hash = 0;

    for (let i = 0; i < inputString.length; i++) {
        // tslint:disable-next-line:no-bitwise
        hash = inputString.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;

    return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';
}

export function getAvatarStyle(value: unknown): React.CSSProperties {
    return {
        backgroundColor: getAvatarBackgroundColor(
            getAvatarText(value),
            75,
            75,
        ),
    };
}

export function getAvatarText(value: unknown): string {
    return typeof value === 'string' ? value.substr(0, value.length - 1) : '';
}

export function downloadTxtFile(fileName: string, stringToDownload: string) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(stringToDownload));
    element.setAttribute('download', `${fileName}.txt`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export function waitFor(ms: number = 500) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
