import Button from '@components/Button';
import { Form, Input, Modal } from 'antd';
import { ValidationRule } from 'antd/lib/form';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './FloatingInput.module.scss';
import { FloatingInputProps, FloatingInputState } from './FloatingInputModel';


class FloatingInput extends Component<FloatingInputProps, FloatingInputState> {
    fieldId = 'value';
    validationRules: ValidationRule[] = [{
        whitespace: true,
        message: (
            <FormattedMessage
                id="FloatingInput.whitespaceMessage"
                defaultMessage="Value cannot contain only spaces"
            />
        )
    }, {
        required: true,
        message: (
            <FormattedMessage
                id="FloatingInput.RequiredMessage"
                defaultMessage="Value is required"
            />
        )
    }];

    constructor(props: FloatingInputProps) {
        super(props);
        const { patternValidation, maxLengthValidation, minLengthValidation } = props;
        if (patternValidation) { this.validationRules.push(patternValidation); }
        if (minLengthValidation) { this.validationRules.push(minLengthValidation); }
        if (maxLengthValidation) { this.validationRules.push(maxLengthValidation); }
    }

    printFieldErrors = (fieldName: string) => {
        const fieldError = this.props.form.getFieldError(fieldName);
        if (!fieldError) {
            return <span />;
        }
        return fieldError.map((error, index) => (
            <React.Fragment key={`error_${index}`}><span>{error}</span><br /></React.Fragment>
        ));
    }

    onConfirm = () => {
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    await this.props.onConfirm(values[this.fieldId]);
                } catch (e: any) {
                    if (e.errorLabel) {
                        this.props.form.setFields({
                            [this.fieldId]: {
                                errors: [e.errorLabel]
                            }
                        });
                    }
                }
            }
        });
    }

    render() {
        const {
            form: { getFieldDecorator, getFieldError },
            placeholder,
            modalTitle,
            visible,
            onCancel,
            loading
        } = this.props;
        return (
            <Modal
                destroyOnClose={true}
                footer={null}
                mask={false}
                title={modalTitle}
                visible={visible}
                onCancel={onCancel}
                wrapClassName={styles.FloatingInputModalWrap}
            >
                <Form
                    hideRequiredMark={true}
                    layout={'inline'}
                    className={styles.FloatingInputForm}
                >
                    <Form.Item
                        help={this.printFieldErrors(this.fieldId)}
                        wrapperCol={{ xs: 24 }}
                        className={styles.FloatingInputFormItem}
                    >
                        {getFieldDecorator(this.fieldId, {
                            rules: this.validationRules
                        })(<Input placeholder={placeholder} autoFocus={true} />)}
                    </Form.Item>
                    <Button
                        loading={loading}
                        className={styles.FloatingInputConfirm}
                        disabled={!!getFieldError(this.fieldId)}
                        onClick={this.onConfirm}
                        type="primary"
                    >
                        <FormattedMessage
                            id="FloatingInput.confirm"
                            defaultMessage="Ok"
                        />
                    </Button>
                </Form>
            </Modal>
        );
    }
}

export default Form.create<FloatingInputProps>({})(FloatingInput);
