import React from 'react';
import classnames from 'classnames';
import classes from './index.module.scss';

export interface PaperProps extends Partial<React.ComponentPropsWithRef<'div'>> {
    gutter?: boolean;
}

export default function Paper(props: PaperProps) {
    const {
        className,
        gutter,
        ...otherProps
    } = props;

    const paperClassName = classnames(
        classes.Paper,
        {
            [classes.PaperGutter]: gutter,
        },
        className
    );

    return <div className={paperClassName} {...otherProps} />;
}
