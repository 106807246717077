import { debounce } from '@services/utils';
import { Input, Tag } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
    DefaultFormFieldMatchersFactory
} from '../../../services/FormUtils/FormFieldMatchers/FormFieldMatchersFactory';
import styles from './DynamicField.module.scss';
import { DynamicFieldProps, DynamicFieldState, InjectedProps } from './DynamicFieldModel';

@observer
class DynamicField extends Component<DynamicFieldProps, DynamicFieldState> {

    debouncedOnChange: (value: any) => any;

    get injected() {
        return this.props as InjectedProps;
    }

    constructor(props: DynamicFieldProps) {
        super(props);

        this.debouncedOnChange = debounce(this.injected.onChange, 400);
    }

    onCustomChange = (e: any) => {
        const { verifyFieldHasClientError: hasClientErrors } = this.props;
        let value = e;
        if (e && e.target) {
            value = e.target.value;
        }
        this.props.fieldData.setFieldValue(value);
        this.props.fieldData.unsetValidationError();

        const onChangeResult = this.debouncedOnChange(this.props.fieldData.getSubmittableValue());
        this.props.fieldData.setHasClientErrors(hasClientErrors());

        return onChangeResult;
    }

    maybeCreateField = () => {
        const { fieldData } = this.props;
        const { locale } = this.props.intl;
        const { id } = this.injected;
        return DefaultFormFieldMatchersFactory
            .getInstance()
            .maybeCreateComponent(id, locale, this.onCustomChange, fieldData);
    }

    render() {

        if (!this.props.fieldData) {
            return null;
        }

        const className = styles.DynamicFieldContainer;

        return (
            <Input.Group className={className} compact={true}>
                {
                    (this.props.fieldData as any)['translatable'] &&
                    this.props.locale &&
                    <Tag
                        className={styles.LocaleTag}
                        style={{
                            ...this.props.inputGroupStyle
                        }}
                    >
                        {this.props.locale}
                    </Tag>
                }
                <span className={styles.InputContainer}>
                    {this.maybeCreateField()}
                </span>
            </Input.Group>
        );
    }
}

export default injectIntl(DynamicField);
