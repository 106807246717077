import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Repositories from '../Repositories/Repositories';
import CreateRepository from '../CreateRepository/CreateRepository';
import UpdateRepository from '../UpdateRepository/UpdateRepository';
import RepositoryOverview from '../RepositoryOverview/RepositoryOverview';
import { RepositoriesRouterPropsModel, InjectedProps } from './RepositoriesRouterModel';
import {
    REPOSITORY_ROUTE,
    CREATE_REPOSITORY_ROUTE,
    REPOSITORY_DASHBOARD_ROUTE,
    EDIT_REPOSITORY_ROUTE,
    baseRepositoriesPath
} from '../../../constants/routing-constants';
import { inject } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { NavigationItem } from '../../../stores/navStore/navStore';
import { NAVIGATION_LABELS } from '../../../constants/navigation-internationalized-labels';
import { withRepoDashboardStore } from '../../../hoc';
import { NAVIGATION_ITEM_IDENTIFIERS } from '../../../constants/navigation-item-identifier';
import { NAVIGATION_PRIORITY } from '../../../stores/navStore/navStoreModel';

@inject('navStore')
class RepositoriesRouter extends Component<RepositoriesRouterPropsModel> {
    get injected() {
        return this.props as InjectedProps;
    }
    componentDidMount() {
        const { formatMessage } = this.props.intl;
        const { match: { params: { spaceId } } } = this.props;
        this.injected.navStore.setNavigationPath(
            new NavigationItem(
                NAVIGATION_ITEM_IDENTIFIERS.REPOSITORIES,
                baseRepositoriesPath(spaceId),
                formatMessage(NAVIGATION_LABELS.repositories),
                NAVIGATION_PRIORITY.FIRST
            )
        );
    }

    componentWillUnmount() {
        this.injected.navStore.removeItem(NAVIGATION_ITEM_IDENTIFIERS.REPOSITORIES);
    }

    render() {
        return (
            <Switch>
                <Route
                    exact={true}
                    path={REPOSITORY_ROUTE}
                    component={Repositories}
                />
                <Route
                    exact={true}
                    path={CREATE_REPOSITORY_ROUTE}
                    component={CreateRepository}
                />
                <Route
                    exact={true}
                    path={EDIT_REPOSITORY_ROUTE}
                    component={UpdateRepository}
                />
                <Route
                    exact={true}
                    path={REPOSITORY_DASHBOARD_ROUTE}
                    component={RepositoryOverview}
                />
            </Switch>
        );
    }
}

export default withRepoDashboardStore(injectIntl(RepositoriesRouter));
