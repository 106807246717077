import React, { Component } from 'react';
import { Provider, inject, observer } from 'mobx-react';
import { WithStoresWrappedComponent, WithStoresComponentProps } from '../../constants/common-types';
import { ChannelsStore, notificationStore } from '../../stores';
import { ChannelsStoreModel } from '../../stores/channelsStore/channelsStoreModel';
import { NotificationStoreModel } from '../../stores/notificationStore/notificationStoreModel';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';

type InjectedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
};

const withChannelCreateStores = <T extends {}>(WrappedComponent: WithStoresWrappedComponent<T>) => {
    @inject('apiStore')
    @observer
    class WithChannelCreateStores extends Component<WithStoresComponentProps<T>> {
        get injected() {
            return this.props as InjectedProps<T>;
        }
        channelsStore: ChannelsStoreModel;
        notificationStore: NotificationStoreModel;
        constructor(props: WithStoresComponentProps<T>) {
            super(props);

            const { api } = this.injected.apiStore;

            this.channelsStore = new ChannelsStore(api);
            this.notificationStore = notificationStore;
        }
        render() {
            return (
                <Provider
                    channelsStore={this.channelsStore}
                    notificationStore={this.notificationStore}
                >
                    <WrappedComponent {...this.props} />
                </Provider>
            );
        }
    }

    return WithChannelCreateStores;
};

export default withChannelCreateStores;
