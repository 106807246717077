import { Icon } from 'antd';
import React from 'react';
import { MissingPermissionsProps } from './MissingPermissionsModel';
import styles from './MissingPermissions.module.scss';
import { FormattedMessage } from 'react-intl';

const MissingPermissions = (props: MissingPermissionsProps) => {
    return (
        <div className={styles.MissingPermissionsWrapper}>
            <div className={styles.MissingPermissionsContentWrapper}>
                <p className={styles.MissingPermissionsAccessDenied}>
                    <FormattedMessage
                        id="MissingPermissions.AccessDenied"
                        defaultMessage="ACCESS DENIED"
                    />
                </p>
                <hr className={styles.MissingPermissionsDivider} />
                <p className={styles.MissingPermissionsInformation}>
                    <FormattedMessage
                        id="MissingPermissions.Information"
                        defaultMessage="Seems like you are not allowed to access this page."
                    />
                </p>
                <p className={styles.MissingPermissionsContact}>
                    <FormattedMessage
                        id="MissingPermissions.Contact"
                        defaultMessage="Please contact your administrator."
                    />
                </p>
                <Icon type="lock" className={styles.MissingPermissionsIcon} />
            </div>
        </div>
    );
};

export default MissingPermissions;
