import React, { Component } from 'react';
import { Row, Col, Button as AntdButton } from 'antd';
import { FormattedMessage } from 'react-intl';

import { CloudinaryPreviewProps } from './CloudinaryPreviewModel';

import styles from './CloudinaryPreview.module.scss';
import {
    CLOUDINARY_IMAGE,
    CLOUDINARY_VIDEO,
} from '../../services/cloudinaryUtils';
import { previewComponent } from '../../services/Media/CloudinaryTypePreview';

class CloudinaryPreview extends Component<CloudinaryPreviewProps> {

    render() {
        const { type, value: { publicId, transformations }, disableActions } = this.props;
        return (
            <Row type="flex" className={styles.CloudinaryPreviewWrapper}>
                <Col xs={24} className={styles.CloudinaryPreviewContainer}>
                    {this.props.value && this.props.value.publicId ?
                        (
                            <React.Fragment>
                                <div className={styles.CloudinaryPreviewImage}>
                                    {previewComponent(type, publicId, transformations)}
                                </div>
                                <div className={styles.CloudinaryPreviewEdit}>
                                    <AntdButton
                                        icon="edit"
                                        disabled={disableActions}
                                        onClick={this.props.toggleImageEditor}
                                        type="primary"
                                    />
                                    <AntdButton
                                        icon="delete"
                                        disabled={disableActions}
                                        onClick={this.props.onReset}
                                        type="danger"
                                    />
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className={styles.CloudinaryPreviewAdd}>
                                <AntdButton
                                    disabled={disableActions}
                                    icon="edit"
                                    onClick={this.props.toggleImageEditor}
                                    type="primary"
                                >
                                    {this.props.type === CLOUDINARY_IMAGE && (
                                        <FormattedMessage
                                            id="CloudinaryPreview.SearchOrUploadImage"
                                            defaultMessage="Search or upload an image"
                                        />
                                    )}
                                    {this.props.type === CLOUDINARY_VIDEO && (
                                        <FormattedMessage
                                            id="CloudinaryPreview.SearchOrUploadVideo"
                                            defaultMessage="Search or upload a video"
                                        />
                                    )}
                                </AntdButton>
                            </div>
                        )}
                </Col>
            </Row>
        );
    }
}

export default CloudinaryPreview;
