import { SchemaTypeIds } from '@contentchef/contentchef-types';
import React from 'react';
import { CloudinaryPublicIdField } from '../../../components';
import { DynamicFieldTypes, FieldCloudinaryPublicId } from '../FormFields';
import { defaultFieldWrapper } from './DefaultFieldWrapper';
import { fieldMatchersUtils } from './FieldMatchersUtils';
import { FieldMatcher } from './FormFieldMatchersFactory';

export class CloudinaryPublicIdTypeMatcher implements FieldMatcher {
    maybeCreateComponent(
        id: string, userLocale: string, onChange: (e: any) => void, field: DynamicFieldTypes
    ): JSX.Element | null {
        if (!fieldMatchersUtils.typeMatcher(
            field, [SchemaTypeIds.CLOUDINARY_PUBLICID, SchemaTypeIds.MEDIA])) { return null; }
        const fieldCloudinaryPublicId = field as FieldCloudinaryPublicId;

        function elementToRender(disabled: boolean) {
            return (
                <CloudinaryPublicIdField
                    id={id}
                    disableActions={disabled || field.disabled}
                    fieldData={fieldCloudinaryPublicId}
                    onChange={onChange}
                />
            );
        }

        return defaultFieldWrapper(elementToRender);
    }
}
