import {
    Field,
    LongTextSchemaType,
    SchemaTypeIds
} from '@contentchef/contentchef-types';
import { TextSchemaType } from '@contentchef/contentchef-types';

interface FormFieldDefaultValueRetrieverInterface {
    getValue(field: Field): any;
}

export class FormFieldDefaultValueStrategy implements FormFieldDefaultValueRetrieverInterface {
    static field: Field;

    static textDefaultValueStrategy = (): string | undefined => {
        const text = FormFieldDefaultValueStrategy.field as Field<TextSchemaType>;
        if (text.constraints.allowEmpty) {
            return '';
        }

        return undefined;
    }

    static longTextDefaultValueStrategy = () => {
        const longText = FormFieldDefaultValueStrategy.field as Field<LongTextSchemaType>;
        if (longText.constraints.allowEmpty) {
            return '';
        }

        return undefined;
    }

    constructor(field: Field) {
        FormFieldDefaultValueStrategy.field = field;
    }

    getValue() {
        switch (FormFieldDefaultValueStrategy.field.type) {
            case SchemaTypeIds.TEXT:
                return FormFieldDefaultValueStrategy.textDefaultValueStrategy();
            case SchemaTypeIds.LONG_TEXT:
                return FormFieldDefaultValueStrategy.longTextDefaultValueStrategy();
            default:
                return undefined;
        }
    }
}
