import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { WebhooksApiErrorTypes, WebhooksStoreModel } from '@stores/webhooksStore/WebhooksStore';
import { NotificationStoreModel } from '@stores/notificationStore/notificationStoreModel';
import { defineMessages, InjectedIntlProps, FormattedMessage, injectIntl } from 'react-intl';
import { Redirect, RouteComponentProps } from 'react-router';
import { Tabs, List, Avatar, Icon } from 'antd';
import Button from '@components/Button';
import Paper from '@components/Paper';
import WebhookForm, { WebhookFormIds } from '@components/WebhookForm/WebhookForm';
import Loader from '@components/Loader/Loader';
import { FormComponentProps } from 'antd/lib/form';
import { inject, observer } from 'mobx-react';
import withFormInitialization from '@hoc/withFormInitialization/withFormInitialization';
import withForm from '@hoc/withForm/withForm';
import { getAvatarStyle } from '@services/utils';
import Typography from '@components/Typography';
import { DATE_FORMAT } from '@constants/date-constants';
import {
    Webhook, UpdateWebhookRequest, DeleteWebhookRequest
} from '@contentchef/contentchef-types';
import {
    createWebhooksListPath,
    webhookNotFound
} from '@constants/routing-constants';
import { createInfoNotification, createSuccessNotification } from '@services/Notification';
import {
    NOTIFICATION_DEFAULT_MESSAGES,
    NOTIFICATION_KEY_CONSTANTS
} from '@constants/notifications-constants';
import styles from './UpdateWebhook.module.scss';
import { NavStoreModel, NAVIGATION_PRIORITY } from '@stores/navStore/navStoreModel';
import { NavigationItem } from '@stores/navStore/navStore';
import { NAVIGATION_ITEM_IDENTIFIERS } from '@constants/navigation-item-identifier';
import { NAVIGATION_LABELS } from '@constants/navigation-internationalized-labels';

interface UpdateWebhookProps extends
    RouteComponentProps<{ spaceId: string, webhookId: string }>, InjectedIntlProps, FormComponentProps { }

interface UpdateWebhookState { }

interface DecoratedProps extends UpdateWebhookProps {
    webhooksStore: WebhooksStoreModel;
    notificationStore: NotificationStoreModel;
    navStore: NavStoreModel;
}

type WebhookEditingData = {
    action: 'create' | 'update',
    actionDate: string;
    actionUserName: string;
    actionUserId: string;
};

const updateWebhookLabels = defineMessages({
    createAction: {
        id: 'SettingsWebhook.UpdateWebhook.Audit.CreateAction.Label',
        defaultMessage: 'Created'
    },
    updateAction: {
        id: 'SettingsWebhook.UpdateWebhook.Audit.UpdateAction.Label',
        defaultMessage: 'Updated'
    }
});

@inject('webhooksStore', 'notificationStore', 'navStore')
@observer
class UpdateWebhook extends Component<UpdateWebhookProps, UpdateWebhookState> {
    get decoratedProps() { return this.props as DecoratedProps; }

    componentDidMount = async (): Promise<void> => {
        const { spaceId, webhookId } = this.props.match.params;
        await this.decoratedProps.webhooksStore.setWebhook(spaceId, { id: webhookId });

        const { navStore } = this.decoratedProps;

        navStore.replaceNavigationPaths([
            new NavigationItem(
                NAVIGATION_ITEM_IDENTIFIERS.WEBHOOKS,
                createWebhooksListPath(spaceId),
                this.props.intl.formatMessage(NAVIGATION_LABELS.webhooks),
                NAVIGATION_PRIORITY.FIRST
            ),
            new NavigationItem(
                NAVIGATION_ITEM_IDENTIFIERS.WEBHOOKS,
                undefined,
                webhookId,
                NAVIGATION_PRIORITY.FIRST
            )
        ]);

    }

    openSupportHero = (e: React.MouseEvent) => {
        e.preventDefault();
        if (window.supportHeroWidget) {
            window.supportHeroWidget.show();
        }
    }

    createHistoryData = (webhook: Webhook): WebhookEditingData[] => {
        return [{
            action: 'create',
            actionUserName: webhook.creator,
            actionUserId: webhook.creatorId,
            actionDate: webhook.creationDate,
        }, {
            action: 'update',
            actionUserName: webhook.latestEditor,
            actionUserId: webhook.latestEditorId,
            actionDate: webhook.lastModifiedDate,
        }];
    }

    onUpdateWebhookClick = () => {
        this.props.form.validateFields(async (err, values) => {
            if (err || !this.decoratedProps.webhooksStore.webhook) {
                return;
            }
            createInfoNotification(
                NOTIFICATION_KEY_CONSTANTS.WEBHOOK_API_REQUEST,
                this.props.intl.formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.updateAction
            );
            try {
                const updateParams: UpdateWebhookRequest = {
                    id: this.decoratedProps.webhooksStore.webhook.id,
                    name: values[WebhookFormIds.name],
                    url: values[WebhookFormIds.url],
                };
                await this.decoratedProps.webhooksStore.update(this.props.match.params.spaceId, updateParams);
                this.decoratedProps.notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.WEBHOOK_API_REQUEST);
                createSuccessNotification(this.props.intl.formatMessage, NOTIFICATION_DEFAULT_MESSAGES.createAction);
            } catch (e) {
                this.decoratedProps.notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.WEBHOOK_API_REQUEST);
                if (this.decoratedProps.webhooksStore.error.create === WebhooksApiErrorTypes.validationError) {
                    this.decoratedProps.notificationStore.openNotificationWithIcon('error', {
                        message: (
                            <FormattedMessage
                                id="SettingsWebhook.WebhookList.UpdateWebhook.ValidationErrorMessage"
                                /* tslint:disable-next-line:max-line-length */
                                defaultMessage="Validation error while updating your webhook, please check your data before to proceed"
                            />
                        ),
                        duration: 5,
                        placement: 'topRight'
                    });
                } else {
                    this.decoratedProps.notificationStore.openNotificationWithIcon('error', {
                        message: (
                            <FormattedMessage
                                id="SettingsWebhook.WebhookList.UpdateWebhook.UnexpectedErrorMessage"
                                /* tslint:disable-next-line:max-line-length */
                                defaultMessage="Unexpected error, if the problem persist please contact our support team."
                            />
                        ),
                        duration: 5,
                        placement: 'topRight'
                    });
                }
            }
        });
    }

    onDeleteWebhookClick = async () => {
        if (!this.decoratedProps.webhooksStore.webhook) {
            return;
        }

        const { match: { params: { spaceId } } } = this.decoratedProps;

        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.WEBHOOK_API_REQUEST,
            this.props.intl.formatMessage,
            NOTIFICATION_DEFAULT_MESSAGES.deleteAction
        );
        const params: DeleteWebhookRequest = {
            id: this.decoratedProps.webhooksStore.webhook.id
        };
        try {
            await this.decoratedProps.webhooksStore.delete(this.props.match.params.spaceId, params);
            this.decoratedProps.notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.WEBHOOK_API_REQUEST);
            this.props.history.push(createWebhooksListPath(spaceId));
        } catch (e) {
            this.decoratedProps.notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.WEBHOOK_API_REQUEST);
            this.decoratedProps.notificationStore.openNotificationWithIcon('error', {
                message: (
                    <FormattedMessage
                        id="SettingsWebhook.WebhookList.UpdateWebhook.DeleteAction.UnexpectedErrorMessage"
                        /* tslint:disable-next-line:max-line-length */
                        defaultMessage="Unexpected error, if the problem persist please contact our support team."
                    />
                ),
                duration: 5,
                placement: 'topRight'
            });
        }
    }

    render() {
        const { loading, error, webhook } = this.decoratedProps.webhooksStore;

        if (loading.get) {
            return <Loader />;
        }
        if (error.get || webhook === undefined) {
            return (
                <Redirect to={webhookNotFound(this.props.match.params.spaceId)} />
            );
        }
        const { formatMessage } = this.props.intl;
        return (
            <Fragment>
                <Paper gutter={true} >
                    <Tabs
                        className={styles.TabsContainer}
                        defaultActiveKey="1"
                        tabBarExtraContent={
                            <div className={styles.ExtraContentContainer}>
                                <a
                                    className="ant-dropdown-link"
                                    id="supporthero-container"
                                    onClick={this.openSupportHero}
                                >
                                    <FormattedMessage
                                        id="SettingsWebhook.UpdateWebhook.KeyKnowMore"
                                        defaultMessage="Click here to learn about webhook security"
                                    />
                                </a>
                            </div>}
                    >
                        <Tabs.TabPane
                            tab={<FormattedMessage
                                defaultMessage="Details"
                                id="SettingsWebhook.UpdateWebhook.DetailsTab.Title"
                            />}
                            key="1"
                        >
                            <WebhookForm
                                webhook={this.decoratedProps.webhooksStore.webhook}
                            />
                            <div className={styles.ActionButtonContainer}>
                                <Button
                                    type="danger"
                                    onClick={this.onDeleteWebhookClick}
                                    loading={loading.delete}
                                    disabled={loading.delete || loading.update}
                                >
                                    <FormattedMessage
                                        id="SettingsWebhook.UpdateWebhook.Button.Delete"
                                        defaultMessage="DELETE"
                                    />
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={this.onUpdateWebhookClick}
                                    loading={loading.update}
                                    disabled={loading.delete || loading.update}
                                >
                                    <FormattedMessage
                                        id="SettingsWebhook.UpdateWebhook.Button.Update"
                                        defaultMessage="UPDATE"
                                    />
                                </Button>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            className={styles.HistoryTabContainer}
                            tab={<FormattedMessage
                                defaultMessage="History"
                                id="SettingsWebhook.UpdateWebhook.HistoryTab.Title"
                            />}
                            key="2"
                        >
                            <List
                                dataSource={this.createHistoryData(webhook)}
                                renderItem={(editingData: WebhookEditingData) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={<Avatar
                                                children={<Icon type="user" />}
                                                style={getAvatarStyle(editingData.actionUserId)}
                                            />}
                                            title={<Typography gutter={false} variant="h6" component={'span'}>
                                                {editingData.actionUserName}
                                            </Typography>}
                                            description={<Fragment>
                                                <FormattedMessage
                                                    id="SettingsWebhook.UpdateWebhook.Audit.ActionDate_1"
                                                    defaultMessage="{ action } on"
                                                    values={{
                                                        action: editingData.action === 'create'
                                                            ? formatMessage(updateWebhookLabels.createAction)
                                                            : formatMessage(updateWebhookLabels.updateAction),
                                                    }}
                                                >
                                                    {txt => (<Fragment>
                                                        <span className={styles.ActionDateText}>
                                                            {txt}
                                                        </span>
                                                        <span>
                                                            {moment(editingData.actionDate).format(DATE_FORMAT)}
                                                        </span>
                                                    </Fragment>)}
                                                </FormattedMessage>
                                                <FormattedMessage
                                                    id="SettingsWebhook.UpdateWebhook.Audit.ActionDate_2"
                                                    defaultMessage=" at "
                                                >
                                                    {txt => (<Fragment>
                                                        <span className={styles.ActionDateText}>
                                                            {txt}
                                                        </span>
                                                        <span>
                                                            {moment(editingData.actionDate).format('hh:mm a')}
                                                        </span>
                                                    </Fragment>)}
                                                </FormattedMessage>
                                            </Fragment>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </Paper>
            </Fragment>
        );
    }
}

export default withFormInitialization(withForm(injectIntl(UpdateWebhook)));
