import React, { Component } from 'react';
import { Menu, Layout } from 'antd';
import { injectIntl } from 'react-intl';
import { InjectedIntlProps, defineMessages } from 'react-intl';
import { SPACE_SELECTOR_ROUTE } from '@constants/routing-constants';
import { PermissionLessMenuLink } from '@components/MenuLink/MenuLink';
import { SpaceSvg } from '@assets/custom_icons/components/Space';

import styles from './SideBarUserSettings.module.scss';

const { Sider } = Layout;
const labels = defineMessages({
    chooseSpace: {
        id: 'chooseSpace',
        defaultMessage: 'Choose space'
    }
});

export interface SideBarProps extends InjectedIntlProps { }

export enum SidebarItems {
    DASHBOARD = 'dashboard',
    CONTENTS = 'contents',
    CONTENT_DEFINITIONS = 'content-definitions',
    MEDIA_GALLERIES = 'media-galleries',
    PUBLISH_TO_LIVE = 'publishtolive',
    SETTINGS = 'settings',
    PUBLISHING_CHANNELS = 'publishing-channels',
    REPOSITORIES = 'repositories',
    USERS_AND_PERMISSIONS = 'users-and-permissions',
    WEBHOOKS = 'webhooks'
}

class SideBarUserSettings extends Component<SideBarProps> {
    render() {
        const { formatMessage } = this.props.intl;

        return (
            <Sider
                className={styles.SideBar}
                collapsed={true}
                collapsedWidth={64}
            >
                <Menu
                    className={styles.MenuContainer}
                    mode="horizontal"
                    selectable={true}
                    theme="dark"
                >
                    <PermissionLessMenuLink
                        hasPermissions={true}
                        data-eyelet="back"
                        className={styles.SideBarItemDefault}
                        iconComponent={SpaceSvg}
                        title={formatMessage(labels.chooseSpace)}
                        to={SPACE_SELECTOR_ROUTE}
                    />
                </Menu>
            </Sider>
        );
    }
}

export default injectIntl(SideBarUserSettings);
