import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import { ConsentTypes, GetCurrentUserResponse, UserBySpace } from '@contentchef/contentchef-types';
import { action, IObservableArray, observable, runInAction } from 'mobx';
import ReactGA from 'react-ga';

type UsersStoreLoading = {
    userList: boolean;
    getCurrentUser: boolean;
    acceptConsents: boolean;
    termsAndConditionsConsent: boolean;
    privacyConsent: boolean;
};

class UsersStore {
    protected api: ContentChefClient;
    @observable usersListBySpaceId: IObservableArray<UserBySpace> = observable.array([]);
    @observable currentUser: GetCurrentUserResponse | null = null;
    @observable loading: UsersStoreLoading = {
        userList: false,
        getCurrentUser: false,
        acceptConsents: false,
        termsAndConditionsConsent: false,
        privacyConsent: false,
    };

    constructor(api: ContentChefClient) {
        this.api = api;
    }

    async trackUserGA() {
        try {
            ReactGA.set({ email: this.currentUser!.userDetails.email, sub: this.currentUser!.userDetails.authorId });
            await ReactGA.event({ category: 'dashboard', action: 'signup' });
        } catch (error) {
            console.log(error);
        }
    }

    @action
    async getCurrentUser() {
        this.loading.getCurrentUser = true;
        try {
            const response = await this.api.users.getCurrentUser();
            runInAction(() => {
                this.currentUser = response;
                this.loading.getCurrentUser = false;
            });
        } catch (e) {
            console.log('Error while retrieving currentUser information', e);
            runInAction(() => {
                this.loading.getCurrentUser = false;
            });
        }
    }

    @action
    async acceptConsents(acceptedMarketingPolicy: boolean) {
        this.loading.acceptConsents = true;
        try {
            const currentUserConsents = this.currentUser!.consents;
            await this.api.users.acceptConsents({
                termsAndConditionsVersion: currentUserConsents.termsAndConditions.latestVersion,
                privacyPolicyVersion: currentUserConsents.privacy.latestVersion,
                marketingPolicyVersion: acceptedMarketingPolicy
                    ? currentUserConsents.marketing.latestVersion
                    : undefined
            });
            await this.getCurrentUser();
            return true;
        } catch (e) {
            console.log('Error while accepting consents for user', e);
            return false;
        }
    }

    @action
    async getUsersListBySpaceId(spaceId?: string) {
        this.loading.userList = true;
        this.usersListBySpaceId.clear();
        try {
            const response = await this.api.users.listBySpace({}, spaceId);
            runInAction(() => {
                this.usersListBySpaceId.replace(response);
                this.loading.userList = false;
            });
        } catch (e) {
            console.log('Error while retrieving repository', e);
            runInAction(() => {
                this.loading.userList = false;
            });
        }
    }

    @action
    async updateTermsAndConditionsConsent() {
        this.loading.termsAndConditionsConsent = true;
        try {
            await this.api.users.updateConsent({
                consentType: ConsentTypes.TermsAndConditionsPolicy,
                policyVersion: this.currentUser!.consents.termsAndConditions.latestVersion
            });
            runInAction(() => {
                this.loading.termsAndConditionsConsent = false;
            });
            return true;
        } catch (e) {
            console.log('Error while updating terms and conditions consent', e);
            runInAction(() => {
                this.loading.termsAndConditionsConsent = false;
            });
            return false;
        }
    }

    @action
    async updatePrivacyConsent() {
        this.loading.privacyConsent = true;
        try {
            await this.api.users.updateConsent({
                consentType: ConsentTypes.PrivacyPolicy,
                policyVersion: this.currentUser!.consents.privacy.latestVersion
            });
            runInAction(() => {
                this.loading.privacyConsent = false;
            });
            return true;
        } catch (e) {
            console.log('Error while updating privacy consent', e);
            runInAction(() => {
                this.loading.privacyConsent = false;
            });
            return false;
        }
    }

    hasAlreadyAcceptedMandatoryPolicies = () => {
        return !!this.currentUser!.consents.marketing.signedVersion && !!this.currentUser!.consents.privacy.signedVersion;
    }
}

export default UsersStore;
