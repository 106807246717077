import { LANGUAGE_BY_LOCALE } from './languages';

export class LanguageByLocale {
    mostCommon: string[] = ['en_US', 'en_GB', 'it_IT', 'fr_FR', 'de_DE', 'es_ES', 'ru_RU'];
    regexp = /\(([^)]+)\)/;
    getLanguageNameByLocale(locale: string) {
        return LANGUAGE_BY_LOCALE[locale as keyof typeof LANGUAGE_BY_LOCALE];
    }

    getCountryCode(locale: string) {
        const splitLocale = locale.split('_');

        return splitLocale.length > 1 ? splitLocale[1] : splitLocale[0];
    }

    retrieveList() {
        return (Object.keys(LANGUAGE_BY_LOCALE) as (keyof typeof LANGUAGE_BY_LOCALE)[]).map((key) => {
            return { code: key, language: LANGUAGE_BY_LOCALE[key], withoutRegion: key.length === 2 };
        });
    }

    retrieveMostCommon() {
        return this.retrieveList().filter(value => {
            return this.mostCommon.includes(value.code);
        });
    }

    labelByLocale(locale: string) {
        return this.getLanguageNameByLocale(locale) ? `${this.getLanguageNameByLocale(locale)} (${locale})` : locale;
    }

    getRegion(locale: string) {
        return this.regexp.exec(this.getLanguageNameByLocale(locale));
    }
}

export const languageByLocale = new LanguageByLocale();

export function sortByFallback(locales: string[], fallback: string = 'en') {
    return locales.reduce(
        (acc, value) => {
            if (value === fallback) {
                return [value, ...acc];
            }

            return [...acc, value];
        },
        [] as string[]);
}

export function groupByLocales(baseLocales: string[], locales: string[]) {
    const mapLocales = baseLocales.reduce(
        (acc, base) => {
            const localesWithBase = locales.filter(loc => loc.startsWith(`${base}_`));
            acc.set(base, localesWithBase);
            return acc;
        },
        new Map<string, string[]>()
    );
    return mapLocales;
}
