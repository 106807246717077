import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { RepositorySummaryProps, RepositorySummaryState } from './RepositorySummaryModel';
import {
    // TeamMembers,
    RepositoryStats
} from '..';

import styles from './RepositorySummary.module.scss';

class RepositorySummary extends Component<RepositorySummaryProps, RepositorySummaryState> {
    render() {
        const { repositoryId } = this.props;
        return (
            <Row className={styles.RepositorySummaryContainer} type="flex" align="middle" >
                <Col xs={24} md={16} className={styles.RepositorySummaryTeamMembers}>
                    {/* <TeamMembers teamMembers={[{ name: 'Nicola Bellotti' }, { name: 'Pierpaolo Iannone' }]} /> */}
                </Col>

                <Col xs={24} md={8} className={styles.RepositorySummaryStats}>
                    <RepositoryStats
                        classNames={{ count: styles.RepositorySummaryNumber }}
                        repositoryId={repositoryId}
                    />
                </Col>
            </Row>
        );
    }
}

export default RepositorySummary;
