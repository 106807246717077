import Button from '@components/Button';
import { withPermissionsToInteract } from '@providers/UserPermissionsProvider';
import developerMode from '@services/devMode';
import { debounce } from '@services/utils';
import { Col, Form, Input, Row } from 'antd';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import {
    isAnEmptyJsonObject, isAValidSchemaObject,
    isValidJsonObject
} from '../../../constants/json-editor-constants';
import { withForm } from '../../../hoc';
import ContentDefinitionSchemaEditor from '../../ContentDefinitionSchemaEditor';
import ContentDefinitionEditCustomFields from '../../ContentDefinitionSchemaEditor/ContentDefinitionEditCustomFields';
import MonacoEditorJSON from '../../MonacoEditorJSON';
import NotificationPopover from '../../NotificationPopover/NotificationPopover';
import Section from '../../Section/Section';
import ContentDefinitionDefaultLang from '../ContentDefinitionDefaultLang/ContentDefinitionDefaultLang';
import styles from './CreateContentDefinitionForm.module.scss';
import {
    CreateContentDefinitionFormProps,
    CreateContentDefinitionFormState,
    DecoratedProps
} from './CreateContentDefinitionFormModel';

const FormItem = Form.Item;
const CreateContentDefinitionFormLabels = defineMessages({
    nameField: {
        id: 'CreateContentDefinitionFormLabels.nameField',
        defaultMessage: 'Name'
    },
    mnemonicIdField: {
        id: 'CreateContentDefinitionFormLabels.mnemonicIdField',
        defaultMessage: 'Mnemonic Id'
    },
    defaultLanguage: {
        id: 'CreateContentDefinitionFormLabels.defaultLanguage',
        defaultMessage: 'Labels\' default language'
    },
    schemaField: {
        id: 'CreateContentDefinitionFormLabels.schemaField',
        defaultMessage: 'Content Definition Schema'
    },
    notValidJsonObjectError: {
        id: 'CreateContentDefinitionFormLabel.notValidJsonObjectError',
        defaultMessage: 'schema is not a valid JSON object'
    },
    invalidEmptyObject: {
        id: 'CreateContentDefinitionFormLabel.invalidEmptyObject',
        defaultMessage: 'schema cannot be an empty JSON object'
    },
    invalidSchemaObject: {
        id: 'CreateContentDefinitionFormLabel.invalidSchemaObject',
        defaultMessage: 'missing mandatory key lang, fields and fragments'
    },
    errorValidationMnemonicId: {
        id: 'CreateContentDefinitionFormLabel.errorValidationMnemonicId',
        defaultMessage: 'Mnemonic Id is in use already.'
    },
    errorPatternMnemonicId: {
        id: 'CreateContentDefinitionFormLabel.errorPatternMnemonicId',
        defaultMessage: 'Mnemonic Id has invalid characters.'
    },
    corruptSchemaTooltipTitle: {
        id: 'CreateContentDefinitionFormLabels.tooltip.title',
        defaultMessage: 'Corrupt json schema'
    },
    corruptSchemaTooltipMessage: {
        id: 'CreateContentDefinitionFormLabels.corruptSchemaTooltipMessage',
        defaultMessage: 'Fix all the problems before to switch back to custom editor.'
    },
});

@inject('contentDefinitionSchemaStore')
@observer
class CreateContentDefinitionForm extends
    Component<CreateContentDefinitionFormProps, CreateContentDefinitionFormState> {
    debouncedValidateMnemonicId: (rule: any, value: any, callback: any, source: any, options: any) => void;
    debouncedOnChangeMonacoEditorValue: (value: string) => void;
    state: CreateContentDefinitionFormState = {
        customEditor: true,
        isSchemaValid: true,
        isSchemaCorrupt: false,
        jsonSchemaError: ''
    };

    get decoratedProps() { return this.props as DecoratedProps; }

    constructor(props: CreateContentDefinitionFormProps) {
        super(props);
        this.debouncedValidateMnemonicId = debounce(this.validateMnemonicId);
        this.debouncedOnChangeMonacoEditorValue = debounce(this.onChangeMonacoEditorValue, 700);
    }

    retrieveLocalStorageValue = () => {
        return developerMode();
    }

    validateMnemonicId = async (rule: any, value: any, callback: any, source: any, options: any) => {
        const errors: string[] = [];

        const validMnemonicId = await this.props.validateMnemonicId(value);
        if (!validMnemonicId) {
            errors.push(rule.message);
        }

        callback(errors);
    }

    onClickChangeEditor = () => {
        if (!this.state.customEditor) {
            if (this.decoratedProps.contentDefinitionSchemaStore.isJsonSchemaValidForCustomEditorSchema()) {
                this.decoratedProps.contentDefinitionSchemaStore.setCustomEditorSchema();
                this.decoratedProps.contentDefinitionSchemaStore.cleanJsonEditorSchema();
                this.setState({ customEditor: !this.state.customEditor });
            } else {
                this.setState({ isSchemaCorrupt: true });
            }
        } else {
            this.decoratedProps.contentDefinitionSchemaStore.setJsonEditorSchema(
                this.decoratedProps.contentDefinitionSchemaStore.retrieveDeserializedDefinitionSchema()
            );
            this.setState({ customEditor: !this.state.customEditor });
        }
    }

    onChangeMonacoEditorValue = (value: string) => {
        if (!isValidJsonObject(value)) {
            return this.setState({
                isSchemaValid: false,
                jsonSchemaError: this.props.intl.formatMessage(
                    CreateContentDefinitionFormLabels.notValidJsonObjectError
                )
            });
        }
        const result = JSON.parse(value);
        if (isAnEmptyJsonObject(result)) {
            return this.setState({
                isSchemaValid: false,
                jsonSchemaError: this.props.intl.formatMessage(
                    CreateContentDefinitionFormLabels.invalidEmptyObject
                )
            });
        }
        if (!isAValidSchemaObject(result)) {
            return this.setState({
                isSchemaValid: false,
                jsonSchemaError: this.props.intl.formatMessage(
                    CreateContentDefinitionFormLabels.invalidSchemaObject
                )
            });
        }
        this.decoratedProps.contentDefinitionSchemaStore.setJsonEditorSchema(result);
        return this.setState({
            isSchemaValid: true,
            jsonSchemaError: ''
        });
    }

    onNotificationChangeState = (visible: boolean) => {
        if (!visible) {
            this.setState({ isSchemaCorrupt: false });
        }
    }

    printSwitchEditorButton = () => {
        return this.state.customEditor
            ? (
                <Button icon={'swap'} type="dashed" onClick={this.onClickChangeEditor}>
                    <FormattedMessage
                        id="CreateContentDefinitionFormLabels.Button.JSONEditor"
                        defaultMessage="JSON Editor"
                    />
                </Button>
            ) : (
                <NotificationPopover
                    visible={this.state.isSchemaCorrupt}
                    title={this.props.intl.formatMessage(CreateContentDefinitionFormLabels.corruptSchemaTooltipTitle)}
                    content={
                        this.props.intl.formatMessage(CreateContentDefinitionFormLabels.corruptSchemaTooltipMessage)
                    }
                    onVisibleChangeCallback={this.onNotificationChangeState}
                    placement={'bottomLeft'}
                    iconType="close-circle"
                >
                    <Button icon={'swap'} type="dashed" onClick={this.onClickChangeEditor}>
                        <FormattedMessage
                            id="CreateContentDefinitionFormLabels.Button.CustomEditor"
                            defaultMessage="Custom Editor"
                        />
                    </Button>
                </NotificationPopover>
            );
    }

    trimValue: React.FocusEventHandler<HTMLInputElement> = ({ target }) => {
        const { id, value } = target;
        this.props.form.setFieldsValue({ [id]: value.trim() });
    }

    render() {
        const { hasPermissions, intl: { formatMessage }, form: { getFieldDecorator }, jsonSchema } = this.props;
        const { schema } = this.decoratedProps.contentDefinitionSchemaStore;
        return (
            <Section
                additionalContainerClass={styles.FullHeight}
                header={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {this.retrieveLocalStorageValue() && this.printSwitchEditorButton()}
                    {this.state.customEditor && <ContentDefinitionEditCustomFields />}
                </div>}
            >
                <Form className={styles.FormContainer}>
                    <Row gutter={12}>
                        <Col xs={24} lg={10}>
                            <FormItem
                                label={formatMessage(CreateContentDefinitionFormLabels.nameField)}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                {getFieldDecorator('name', {
                                    initialValue: undefined,
                                    rules: [{ required: true }]
                                })(
                                    <Input disabled={!hasPermissions} onBlur={this.trimValue} />
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={24} lg={10}>
                            <FormItem
                                label={formatMessage(CreateContentDefinitionFormLabels.mnemonicIdField)}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                {getFieldDecorator('mnemonicId', {
                                    initialValue: undefined,
                                    rules: [
                                        {
                                            required: true,
                                            type: 'string'
                                        },
                                        {
                                            pattern: /^[A-Za-z0-9_-]+$/,
                                            message: formatMessage(
                                                CreateContentDefinitionFormLabels.errorPatternMnemonicId
                                            )
                                        },
                                        {
                                            validator: this.debouncedValidateMnemonicId,
                                            message: formatMessage(
                                                CreateContentDefinitionFormLabels.errorValidationMnemonicId
                                            )
                                        }
                                    ]
                                })(
                                    <Input disabled={!hasPermissions} onBlur={this.trimValue} />
                                )}
                            </FormItem>
                        </Col>
                        {this.state.customEditor && <Col xs={24} lg={4}>
                            <FormItem
                                label={formatMessage(CreateContentDefinitionFormLabels.defaultLanguage)}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                {
                                    getFieldDecorator('defaultLanguage', {
                                        initialValue: this.decoratedProps.contentDefinitionSchemaStore.schema.lang,
                                        rules: [{ required: true }]
                                    })(
                                        <ContentDefinitionDefaultLang hasPermissions={hasPermissions} />
                                    )
                                }
                            </FormItem>
                        </Col>}
                    </Row>

                    {this.state.customEditor && <Row gutter={12} style={{ flex: 1, overflowY: 'auto' }}>
                        <Col xs={{ span: 24 }} style={{ height: '100%' }}>
                            <ContentDefinitionSchemaEditor
                                gutter={true}
                                fields={schema.fields}
                            />
                        </Col>
                    </Row>}

                    {!this.state.customEditor && <Row gutter={12} className={styles.JsonEditorRowContainer}>
                        <Col xs={24} className={'ant-form-item-label'}>
                            <label htmlFor={'schema'} className={'ant-form-item-required'}>
                                {formatMessage(CreateContentDefinitionFormLabels.schemaField)}
                            </label>
                        </Col>
                        <Col
                            xs={24}
                            className={classNames(styles.SchemaEditorColContainer, 'ant-form-item-control-wrapper')}
                        >
                            <MonacoEditorJSON
                                id="schema"
                                onChange={this.debouncedOnChangeMonacoEditorValue}
                                hasPermissions={hasPermissions}
                                schema={jsonSchema}
                            />
                        </Col>
                        <Col
                            xs={24}
                            className={classNames(styles.SchemaEditorWithoutErrorContainer, {
                                [styles.SchemaEditorWithErrorContainer]: !this.state.isSchemaValid
                            })}
                        >
                            {!this.state.isSchemaValid && <label htmlFor={'schema'}>
                                {this.state.jsonSchemaError}
                            </label>}
                        </Col>
                    </Row>}
                </Form>
            </Section>
        );
    }
}

export default withPermissionsToInteract(withForm(injectIntl(CreateContentDefinitionForm)));
