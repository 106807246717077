import Button from '@components/Button';
import React, { useState } from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl, } from 'react-intl';
import { ListContentsItem } from '@contentchef/contentchef-types';
import { observer } from 'mobx-react';
import { Drawer, List } from 'antd';
import { NavLink, withRouter } from 'react-router-dom';
import ContentItem from '@components/ContentItem/ContentItem';
import { editContentPath } from '@constants/routing-constants';
import { RouteComponentProps } from 'react-router';

const labels = {
    title: {
        id: 'PublicIdInfo.title',
        defaultMessage: 'Content with same publicId'
    }
};

function PublicIdInfo(
    props:
        { contents: ListContentsItem[], total: number, loading: boolean }
        & InjectedIntlProps & RouteComponentProps<{ spaceId: string }>
) {

    const [visible, setVisible] = useState(false);

    const spaceId = props.match.params.spaceId;

    function onClick() {
        setVisible(true);
    }

    function closeDrawer() {
        setVisible(false);
    }
    return (
        <React.Fragment>
            <Button
                type="ghost"
                icon="link"
                onClick={onClick}
                title={props.intl.formatMessage(labels.title)}
            >
                <FormattedMessage
                    id="PublicIdInfo.buttonLabel"
                    defaultMessage={`{numberOfContents, number} {numberOfContents, plural,
                                    one {content has} other {contents have}} the same public id`}
                    values={{ numberOfContents: props.total }}
                />
            </Button>
            <Drawer
                closable={false}
                width={'50%'}
                visible={visible}
                onClose={closeDrawer}
            >
                <List
                    dataSource={props.contents}
                    renderItem={(item) => (
                        <NavLink
                            style={{ color: 'inherit' }}
                            to={editContentPath(spaceId, item.id)}
                        >
                            <ContentItem
                                content={{ ...item, status: [] }}
                                showStatus={false}
                                showActions={false}
                            />
                        </NavLink>
                    )}
                    pagination={props.total && props.total > 0 ? {
                        defaultCurrent: 1,
                        hideOnSinglePage: true,
                        pageSize: 5,
                        position: 'both',
                        total: props.total,
                    } : false}
                />
            </Drawer>
        </React.Fragment>
    );
}

export default withRouter(injectIntl(observer(PublicIdInfo)));
