import { Layout } from 'antd';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { NavBreadcrumbs } from '..';
import styles from './Navbar.module.scss';
import { InjectedProps, NavbarProps } from './NavbarModel';
import NavActions from '../NavActions/NavActions';
import SpaceSwitcher from '../SpaceSwitcher/SpaceSwitcher';
import { withRouter, Route } from 'react-router';
import {
    SPACE_ROUTE,
    SUBSCRIPTION_DETAIL_PATH,
    SPACE_SELECTOR_ROUTE,
    PROFILE_ROUTE,
    SUBSCRIPTION_MANAGEMENT_ROUTE,
    PROFILE_SECURITY_ROUTE,
    ACCESS_TOKEN_ROUTE,
    INVITES_ROUTE
} from '../../constants/routing-constants';
import { Switch, Link } from 'react-router-dom';
import Logo from '../Logo/Logo';

const { Header } = Layout;

class Navbar extends Component<NavbarProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    render() {
        const { hideActions } = this.props;
        return (
            <Header className={styles.HeaderContainer}>
                <Switch>
                    <Route
                        path={[
                            SPACE_ROUTE,
                            PROFILE_ROUTE,
                            SUBSCRIPTION_MANAGEMENT_ROUTE,
                            PROFILE_SECURITY_ROUTE,
                            ACCESS_TOKEN_ROUTE,
                            INVITES_ROUTE
                        ]}
                        render={() => (
                            <div className={styles.HeaderLogoContainer}>
                                <Link to={SPACE_SELECTOR_ROUTE}>
                                    <Logo isCollapsed={true} />
                                </Link>
                            </div>
                        )}
                    />
                    <Route
                        path={[
                            SPACE_SELECTOR_ROUTE,
                            SUBSCRIPTION_DETAIL_PATH
                        ]}
                        render={() => (
                            <div className={styles.HeaderLogoContainer}>
                                <Link to={SPACE_SELECTOR_ROUTE}>
                                    <Logo />
                                </Link>
                            </div>
                        )}
                    />
                </Switch>
                <Route
                    path={SPACE_ROUTE}
                    render={() => (
                        <NavBreadcrumbs />
                    )}
                />
                <div className={styles.NavbarActionsContainer}>
                    <Route
                        path={[SPACE_ROUTE]}
                        render={() => (
                            <SpaceSwitcher />
                        )}
                    />
                    <NavActions hideActions={hideActions} />
                </div>
            </Header>
        );
    }
}

export default withRouter(injectIntl(Navbar));
