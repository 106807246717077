import { Icon } from 'antd';
import React from 'react';

export function TranslateSvg() {
    return (
        // tslint:disable-next-line: max-line-length
        <svg version="1.1" id="Layer_1" x="0px" y="0px" fill="currentColor" viewBox="0 0 65 65" enableBackground="new 0 0 96 96" width="1em" height="1em">
            <g transform="translate(-15.5,-15.5)">
                {/* tslint:disable-next-line: max-line-length */}
                <path d="m 46.4,52.6 c -4.2,-4.1 -7.9,-7.7 -10,-16 H 51.1 V 30.4 H 36.5 v -8.2 h -6.3 v 8.3 H 15.5 v 6.2 h 15 c 0,0 -0.1,1.2 -0.3,2.1 -2.1,8.2 -4.6,13.4 -14.7,18.5 l 2.1,6.2 C 27.2,58.4 32.2,52 34.4,44.9 c 2.1,5.4 5.7,9.8 9.8,13.8 l 2.2,-6.1 z" />
                {/* tslint:disable-next-line: max-line-length */}
                <path d="M 65.8,32.5 H 57.4 L 42.7,73.8 H 49 L 53.2,61.4 H 70 l 4.2,12.4 h 6.3 L 65.8,32.5 z m -10.5,22.7 6.3,-16.5 6.3,16.6 -12.6,-0.1 0,0 z" />
            </g>
        </svg>
    );
}

export default function TranslateIcon(props: any) {
    return (
        <Icon component={TranslateSvg} {...props} />
    );
}
