import React, { Component } from 'react';
import { Provider, inject, observer } from 'mobx-react';
import {
    ActivityStore, SpaceDetailStore
} from '../../stores';
import { WithStoresComponentProps, WithStoresWrappedComponent } from '../../constants/common-types';
import { ActivityStoreModel } from '../../stores/activityStore/activityStoreModel';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';
import { SpaceDetailStoreModel } from '../../stores/spaceDetailsStore/spaceDetailStoreModel';

type InjectedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
};

const withDashboardStores = <T extends {}>(WrappedComponent: WithStoresWrappedComponent<T>) => {
    @inject('apiStore')
    @observer
    class WithDashboardStores extends Component<WithStoresComponentProps<T>> {
        activityStore: ActivityStoreModel;
        spaceDetailStore: SpaceDetailStoreModel;

        get injected() {
            return this.props as InjectedProps<T>;
        }

        constructor(props: WithStoresComponentProps<T>) {
            super(props);

            const { api } = this.injected.apiStore;

            this.activityStore = new ActivityStore(api);
            this.spaceDetailStore = new SpaceDetailStore(api);
        }
        render() {
            return (
                <Provider
                    activityStore={this.activityStore}
                    spaceDetailStore={this.spaceDetailStore}
                >
                    <WrappedComponent {...this.props} />
                </Provider>
            );
        }
    }

    return WithDashboardStores;
};

export default withDashboardStores;
