import Button from '@components/Button';
import withDefinitionErrors, { WithDefinitionErrorsReturnProps } from '@hoc/withDefinitionErrors/withDefinitionErrors';
import {
    withPermissionsToInteract,
    WithPermissionsToInteractReturnType
} from '@providers/UserPermissionsProvider';
import { Form, Input } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import Typography from '../../../Typography';
import FieldEditorStore from '../../FieldEditorStore';
import styles from '../index.module.scss';

interface DefinitionFieldNameProps extends InjectedIntlProps, WithDefinitionErrorsReturnProps,
    WithPermissionsToInteractReturnType {
    onCheckNameAlreadyExist(value: string): boolean;
}

interface DecoratedProps extends DefinitionFieldNameProps {
    fieldEditorStore: FieldEditorStore;
}

interface DefinitionFieldNameState {
    isEditingName: boolean;
    isInvalidName: boolean;
    invalidNameMessage: string;
}

const labels = defineMessages({
    emptyNameError: {
        id: 'ContentDefinitionFieldEditing.fieldName.error.empty.message',
        defaultMessage: 'A name is required'
    },
    invalidNameError: {
        id: 'ContentDefinitionFieldEditing.fieldName.error.pattern.message',
        defaultMessage: 'Only use letters, numbers, and _ '
    },
    nameAlreadyExistError: {
        id: 'ContentDefinitionFieldEditing.fieldName.error.alreadyExist.message',
        defaultMessage: 'An other field with the same name already exists'
    },
    editNameButton: {
        id: 'ContentDefinitionFieldEditing.fieldName.edit.button',
        defaultMessage: 'Edit name'
    },
    saveNameButton: {
        id: 'ContentDefinitionFieldEditing.fieldName.save.button',
        defaultMessage: 'Save name'
    }
});

function getInitialState(): DefinitionFieldNameState {
    return {
        isEditingName: false,
        isInvalidName: false,
        invalidNameMessage: '',
    };
}

@inject('fieldEditorStore')
@observer
class DefinitionFieldName extends Component<DefinitionFieldNameProps, DefinitionFieldNameState> {
    initialFieldName: string | null = null;
    get decoratedProps() { return this.props as DecoratedProps; }

    public state = getInitialState();

    onEditNameClick = () => {
        if (this.props.hasPermissions) {
            this.initialFieldName = this.decoratedProps.fieldEditorStore.serializedField!.name;
            this.setState({ isEditingName: true });
        }
    }

    onSaveNameClick = () => {
        if (!this.state.isInvalidName) {
            this.setState({ isEditingName: false });
        }
    }

    onChangeName: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const { formatMessage } = this.props.intl;
        const value: string = event.target.value;
        const nameRegExp = new RegExp(/^[a-zA-Z0-9_]+$/);
        this.decoratedProps.fieldEditorStore.updateName(value);
        if (value.trim().length === 0) {
            return this.setState({
                isInvalidName: true, invalidNameMessage: formatMessage(labels.emptyNameError)
            });
        }
        if (!nameRegExp.test(value)) {
            return this.setState({
                isInvalidName: true, invalidNameMessage: formatMessage(labels.invalidNameError)
            });
        }
        if (this.initialFieldName !== value && this.props.onCheckNameAlreadyExist(value)) {
            return this.setState({
                isInvalidName: true, invalidNameMessage: formatMessage(labels.nameAlreadyExistError)
            });
        }
        return this.setState({ isInvalidName: false, invalidNameMessage: '' });
    }

    retrieveNameErrors = () => {
        const { intl: { formatMessage } } = this.props;
        if (this.props.hasErrors) {
            return this.props.errors.map(e => formatMessage(e)).join(', ');
        }
        if (this.state.isInvalidName) {
            return this.state.invalidNameMessage;
        }
        return undefined;
    }

    render() {
        const { intl, hasErrors, hasPermissions } = this.props;
        const { fieldEditorStore } = this.decoratedProps;
        return (
            <Form.Item
                className={styles.FieldNameEditing}
                validateStatus={hasErrors || this.state.isInvalidName ? 'error' : undefined}
                help={this.retrieveNameErrors()}
            >
                {this.state.isEditingName
                    ? <>
                        <Input
                            disabled={!hasPermissions}
                            style={{ fontSize: '1rem' }}
                            size="small"
                            value={fieldEditorStore.serializedField!.name}
                            onChange={this.onChangeName}
                        />
                        <Button
                            type="primary"
                            disabled={this.state.isInvalidName || !hasPermissions}
                            onClick={this.onSaveNameClick}
                        >
                            {intl.formatMessage(labels.saveNameButton)}
                        </Button>
                    </>
                    : <>
                        <Typography className={styles.FieldName}>
                            {fieldEditorStore.serializedField!.name}
                        </Typography>
                        {hasPermissions && <Button
                            type="primary"
                            onClick={this.onEditNameClick}
                        >
                            {intl.formatMessage(labels.editNameButton)}
                        </Button>}
                    </>
                }
            </Form.Item>
        );
    }
}

export default withPermissionsToInteract(withDefinitionErrors(injectIntl(DefinitionFieldName)));
