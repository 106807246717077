import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import { ContentId, ContentResponse, ContentTranslation, FieldTranslation } from '@contentchef/contentchef-types';
import { ContentErrorFactory, ContentErrorTypes, ContentValidationError } from '@services/Error/ContentErrorManager';
import { action, computed, IObservableArray, observable, ObservableMap, runInAction } from 'mobx';
import { FieldTranslationManager } from './FieldTranslationManager';

export class ContentTranslationManager {
    content: ContentResponse | null = null;
    contentLocale: string | null = null;
    contentTranslationLocale: string | null = null;
    @observable fieldTranslations: ObservableMap<string, FieldTranslationManager> = observable.map();
    @observable contentTranslations: IObservableArray<ContentTranslation> = observable.array();

    constructor(private api: ContentChefClient) { }

    @action.bound
    async init(contentLocale: string, locale: string, content: ContentResponse) {
        this.contentLocale = contentLocale;
        this.contentTranslationLocale = locale;
        this.content = JSON.parse(JSON.stringify(content));
        await this.createFieldTranslationsForLocale(locale, this.content!.translations);
        await this.setContentTranslations(content.id);
    }

    async createTranslation(contentId: ContentId, locale: string) {
        try {
            const response = await this.api.contentTranslations.save({ contentId, locale });
            return response;
        } catch (error) {
            console.log(error);
            return Promise.reject(error);
        }
    }

    @action.bound
    async createFieldTranslationsForLocale(locale: string, translations: ContentTranslation[]) {
        this.fieldTranslations.clear();
        let contentTranslation = translations.find(translation => translation.locale === locale);
        if (!contentTranslation) {
            contentTranslation = await this.createTranslation(this.content!.id, locale);
            runInAction(() => {
                this.content!.translations.push(contentTranslation!);
            });
        }
        if (!contentTranslation) {
            console.log(`Content has no translation for the given locale ${locale}`);
            return;
        }
        runInAction(() => {
            if (contentTranslation) {
                contentTranslation!.translations.forEach(fieldTranslation => {
                    this.fieldTranslations.set(
                        fieldTranslation.key,
                        new FieldTranslationManager(fieldTranslation, this.content!.checksum)
                    );
                });
            }
        });
    }

    @action.bound
    async setContentTranslations(contentId: ContentId) {
        try {
            const response = await this.api.contentTranslations.list({ contentId });
            runInAction(() => {
                this.contentTranslations.replace(response);
            });
        } catch (error) {
            console.log(error);
            console.log(`Failed to retrieve content translations for content ${contentId}`);
        }
    }

    getFieldTranslation(key: string) {
        return this.fieldTranslations.get(key);
    }

    getFieldTranslations() {
        return Array.from(this.fieldTranslations.values()).map(item => item.getField());
    }

    @action.bound
    replace(content: ContentResponse) {
        this.content = JSON.parse(JSON.stringify(content));
        const localeTranslation = content.translations.find(trans => trans.locale === this.contentTranslationLocale);
        if (localeTranslation) {
            localeTranslation.translations.forEach(fieldTranslation => {
                const existingFieldTranslation = this.fieldTranslations.get(fieldTranslation.key);
                if (existingFieldTranslation) {
                    existingFieldTranslation.setFieldTranslation(fieldTranslation);
                } else {
                    this.fieldTranslations.set(
                        fieldTranslation.key, new FieldTranslationManager(fieldTranslation, this.content!.checksum));
                }
            });
        }
    }

    @action.bound
    async updateTranslations(values?: FieldTranslation[]) {
        try {
            const response = await this.api.contentTranslations.update({
                contentId: this.content!.id,
                checksum: this.content!.checksum,
                locale: this.contentTranslationLocale!,
                translations: values ? values : Array.from(this.fieldTranslations.values()).map(item => item.getField())
            });
            runInAction(() => {
                response.translations.forEach(fieldTranslation => {
                    const existingFieldTranslation = this.fieldTranslations.get(fieldTranslation.key);
                    if (existingFieldTranslation) {
                        existingFieldTranslation.setFieldTranslation(fieldTranslation);
                    } else {
                        this.fieldTranslations.set(
                            fieldTranslation.key, new FieldTranslationManager(fieldTranslation, this.content!.checksum));
                    }
                });
            });
            await this.setContentTranslations(this.content!.id);
            return;
        } catch (error) {
            console.log(error);
            return Promise.reject(ContentErrorFactory.createContentError(
                { type: ContentErrorTypes.UnexpectedError } as ContentValidationError
            ));
        }
    }

    isFieldUpToDate(key: string) {
        return !!this.getFieldTranslation(key)?.isFieldUpToDate;
    }

    @computed
    get translationNeedsToBeSaved() {
        return Array.from(this.fieldTranslations.values()).some(item => item.isFieldChanged);
    }

}
