import React from 'react';
import AsideItem from '@components/AsideItem/AsideItem';
import FilterBySearch from '@components/FilterBySearch/FilterBySearch';
import { Switch } from 'antd';
import { ArchiveIcon } from '@components/CustomIcons/CustomIcons';
import styles from './index.module.scss';
import Aside from '@components/Aside/Aside';
import { DataAttributes, DataAttributesBuilder } from '@services/DataAttributesBuilder';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';

const labels = defineMessages({
    filterByNamePlaceholder: {
        id: 'MediaGalleryListFilters.filterByNamePlaceholder',
        defaultMessage: 'Filter by name'
    },
    filterByOnlyArchived: {
        id: 'MediaGalleryListFilters.filterByOnlyArchived',
        defaultMessage: 'Archived only'
    },
    filterByOnlyUnarchived: {
        id: 'MediaGalleryListFilters.filterByOnlyUnarchived',
        defaultMessage: 'Unarchived only'
    },
    removeAllFilters: {
        id: 'MediaGalleryListFilters.removeAllFilters',
        defaultMessage: 'Remove all filters'
    }
});

interface MediaGalleryListFiltersProps extends InjectedIntlProps {
    filterByNameValue: string | undefined;
    filterByArchivedValue: boolean | undefined;
    onSearchByName(value: string | undefined): void;
    onSearchByArchived(value: boolean | undefined): void;
    onResetAllFilters?(): void;
}

const MediaGalleryListFilters: React.FunctionComponent<MediaGalleryListFiltersProps> = (props) => {
    const createSearchDataAttributes = (): DataAttributes => {
        return new DataAttributesBuilder('mediagalleries')
            .setSearchAttribute('search-mediaGallery')
            .build();
    };

    return (
        <Aside>
            <AsideItem key={'FilterByName'}>
                <FilterBySearch
                    dataAttributes={createSearchDataAttributes}
                    placeholder={props.intl.formatMessage(labels.filterByNamePlaceholder)}
                    defaultValue={props.filterByNameValue}
                    onSearch={props.onSearchByName}
                />
            </AsideItem>
            <AsideItem key={'FilterByArchiveStatus'}>
                <Switch
                    style={{ marginRight: 10 }}
                    onChange={props.onSearchByArchived}
                    checked={props.filterByArchivedValue}
                />
                {props.filterByArchivedValue
                    ? <React.Fragment>
                        <span>{props.intl.formatMessage(labels.filterByOnlyArchived)}</span>
                        <ArchiveIcon className={styles.ArchiveIconStyle} />
                    </React.Fragment>
                    : <span>{props.intl.formatMessage(labels.filterByOnlyUnarchived)}</span>
                }
            </AsideItem>
            {props.onResetAllFilters && <AsideItem key="RemoveAllAppliedFilters">
                <a onClick={props.onResetAllFilters}>
                    {props.intl.formatMessage(labels.removeAllFilters)}
                </a>
            </AsideItem>}
        </Aside>
    );
};

export default injectIntl(MediaGalleryListFilters);
