import React from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { FormContext } from '@contexts/FormContext';

type WithoutFormProps<P> =
    Pick<P, Exclude<keyof P, keyof FormComponentProps>>;

const withForm =
    <P extends FormComponentProps>
        (WrappedComponent: React.ComponentType<P>): React.ComponentType<WithoutFormProps<P>> => {
        function WithForm(props: WithoutFormProps<P>) {
            return (
                <FormContext.Consumer>
                    {form => <WrappedComponent {...{ ...props, form } as P} />}
                </FormContext.Consumer>
            );
        }

        return WithForm;
    };

export default withForm;
