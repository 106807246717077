import React from 'react';
import { Provider, observer, inject } from 'mobx-react';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';
import { WithStoresComponentProps } from '../../constants/common-types';
import { SpaceDetailStore } from '@stores/index';

type InjectedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
};

export default function withSpaceDetailStore<T>(Component: React.ComponentType<T>): React.ComponentType<T> {
    @inject('apiStore')
    @observer
    class WithSpaceDetailHOC extends React.Component<T> {
        spaceDetailStore: SpaceDetailStore;

        public constructor(props: T, context: any) {
            super(props, context);

            const {
                apiStore,
            } = props as InjectedProps<T>;

            this.spaceDetailStore = new SpaceDetailStore(apiStore.api);
        }

        public render() {
            return (
                <Provider
                    spaceDetailStore={this.spaceDetailStore}
                >
                    <Component
                        {
                        ... this.props
                        }
                    />
                </Provider>
            );
        }
    }

    return WithSpaceDetailHOC;
}
