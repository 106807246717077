import Button from '@components/Button';
import {
    OneFragmentOfSchemaType
} from '@contentchef/contentchef-types';
import { Icon, Input, List, Pagination } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import CheckedIcon from '../../../CheckedIcon/CheckedIcon';
import Section from '../../../Section/Section';
import FieldEditorStore from '../../FieldEditorStore';
import { OneFragmentOfConstraints } from '../../FieldSerializer/fields/oneFragmentOf';
import { ChangeStepContext, FieldSelectionStep } from '../index';
import styles from './CustomFieldSelection.module.scss';

const ListItem = List.Item;
const ListItemMeta = List.Item.Meta;

type CustomFieldSelectionProps = {
    customFields: string[];
};

type CustomFieldSelectionState = {
    skip: number;
    take: number;
    items: string[];
    selectedItems: string[];
    searchText: string;
};

interface InjectedProps extends CustomFieldSelectionProps {
    fieldEditorStore: FieldEditorStore<OneFragmentOfConstraints>;
}

function getInitialState(): CustomFieldSelectionState {
    return {
        skip: 0,
        take: 20,
        items: [],
        selectedItems: [],
        searchText: '',
    };
}

@inject('fieldEditorStore')
@observer
class CustomFieldSelection extends Component<CustomFieldSelectionProps, CustomFieldSelectionState> {
    state = getInitialState();
    get injected() { return this.props as InjectedProps; }

    componentDidMount(): void {
        this.setState({
            items: this.props.customFields,
            selectedItems: this.getInitialSelectedCustomFields()
        });
    }

    getInitialSelectedCustomFields = () => {
        const { fieldEditorStore } = this.injected;
        if (fieldEditorStore.isArrayField<OneFragmentOfSchemaType>(fieldEditorStore.serializedField!)) {
            return fieldEditorStore.serializedField.constraints.items.constraints.names;
        } else {
            return fieldEditorStore.serializedField!.constraints.names;
        }
    }

    onCustomFieldClick = (itemId: string) => {
        const { selectedItems } = this.state;
        if (selectedItems.includes(itemId)) {
            this.setState((prevState) => ({ selectedItems: prevState.selectedItems.filter(name => name !== itemId) }));
        } else {
            this.setState((prevState) => ({ selectedItems: prevState.selectedItems.concat(itemId) }));
        }
    }

    onConfirmClick = () => {
        const { fieldEditorStore } = this.injected;
        if (fieldEditorStore.isArrayField<OneFragmentOfSchemaType>(fieldEditorStore.serializedField!)) {
            fieldEditorStore.updateArrayItemsConstraint<OneFragmentOfSchemaType>('names', this.state.selectedItems);
        } else {
            fieldEditorStore.updateConstraint('names', this.state.selectedItems);
        }
    }

    filterItems = () => {
        let items = this.props.customFields;
        if (this.state.searchText.length > 0) {
            const regExp = new RegExp(this.state.searchText, 'i');
            items = items.filter(item => regExp.test(item));
        }
        this.setState({ items });
    }

    onSearchCustomField: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        this.setState({ searchText: event.target.value }, this.filterItems);
    }

    onChangePage = (page: number) => {
        this.setState((prevState) => {
            return { skip: page === 1 ? 0 : prevState.take * (page - 1) };
        });
    }

    render() {
        const { skip, take, searchText, items } = this.state;
        return (
            <Section
                title={<FormattedMessage
                    id="CustomFieldSelection.Section.Title"
                    defaultMessage="Select one or more custom fields"
                />}
                header={<Input
                    addonBefore={<Icon type="search" />}
                    allowClear={true}
                    onChange={this.onSearchCustomField}
                    value={searchText}
                />}
                footer={<div className={styles.FooterWrapper}>
                    <Pagination
                        current={(skip / take) + 1}
                        onChange={this.onChangePage}
                        pageSize={take}
                        total={items.length}
                    />
                    <ChangeStepContext.Consumer>
                        {
                            context => (
                                <div>
                                    <Button
                                        type="default"
                                        onClick={() => context.changeActiveStep(FieldSelectionStep.EditField)}
                                    >
                                        <FormattedMessage
                                            id="CustomFieldSelection.Button.Back"
                                            defaultMessage="Back"
                                        />
                                    </Button>
                                    <Button
                                        type="primary"
                                        disabled={this.state.selectedItems.length === 0}
                                        onClick={() => {
                                            this.onConfirmClick();
                                            context.changeActiveStep(FieldSelectionStep.EditField);
                                        }}
                                    >
                                        <FormattedMessage
                                            id="CustomFieldSelection.Button.Confirm"
                                            defaultMessage="Confirm"
                                        />
                                    </Button>
                                </div>
                            )
                        }
                    </ChangeStepContext.Consumer>
                </div>}
            >
                <List
                    itemLayout="horizontal"
                    dataSource={this.state.items.slice(skip, (skip + take))}
                    renderItem={(customField: string, index) => {
                        return (
                            <ListItem
                                style={{ cursor: 'pointer' }}
                                key={index}
                                onClick={() => this.onCustomFieldClick(customField)}
                            >
                                <ListItemMeta
                                    avatar={<CheckedIcon selected={this.state.selectedItems.includes(customField)} />}
                                    title={customField}
                                />
                            </ListItem>
                        );
                    }}
                />
            </Section>
        );
    }
}

export default CustomFieldSelection;
