import { defineMessages } from 'react-intl';

export const NOTIFICATION_KEY_CONSTANTS = {
    CONTENTS_API_KEY_SUCCESS: 'API_CALL_SUCCESS',
    CONTENTS_API_KEY_ERROR: 'API_CALL_ERROR',
    CONTENT_API_KEY_REQUEST: 'API_CALL_REQUEST',
    MEDIAGALLERY_CREATE_API_KEY_REQUEST: 'MEDIAGALLERY_CREATE_REQUEST_KEY',
    MEDIAGALLERY_UPDATE_API_KEY_REQUEST: 'MEDIAGALLERY_UPDATE_REQUEST_KEY',
    MEDIAGALLERY_DELETE_API_KEY_REQUEST: 'MEDIAGALLERY_DELETE_REQUEST_KEY',
    MEDIA_CREATE_FOLDER_KEY: 'folderCreationNotification',
    MEDIA_UPDATE_API_KEY_REQUEST: 'MEDIA_UPDATE_API_REQUEST',
    CONTENTDEFINITION_CREATE_API_KEY_REQUEST: 'CONTENTDEFINITION_CREATE_REQUEST_KEY',
    CONTENTDEFINITION_UPDATE_API_KEY_REQUEST: 'CONTENTDEFINITION_UPDATE_REQUEST_KEY',
    CONTENTDEFINITION_FRAGMENT_VALIDATION: 'CONTENTDEFINITION_FRAGMENT_VALIDATION',
    REPOSITORY_CREATE: 'REPOSITORY_CREATE',
    REPOSITORY_UPDATE: 'REPOSITORY_UPDATE',
    REPOSITORY_TOGGLEARCHIVE: 'REPOSITORY_TOGGLEARCHIVE',
    PUBLISHING_CHANNEL_CREATE_REQUEST: 'PUBLISHING_CHANNEL_CREATE_REQUEST',
    PUBLISHING_CHANNEL_EDIT_REQUEST: 'PUBLISHING_CHANNEL_EDIT_REQUEST',
    PUBLISHING_CHANNEL_TOGGLE_ARCHIVE: 'PUBLISHING_CHANNEL_TOGGLE_ARCHIVE',
    MEDIA_UPLOAD_REQUEST: 'MEDIA_UPLOAD_REQUEST',
    MEDIA_UPLOAD_SUCCESS: 'MEDIA_UPLOAD_SUCCESS',
    MEDIA_UPLOAD_FAILED: 'MEDIA_UPLOAD_FAILED',
    PUBLISH_TO_LIVE_REQUEST: 'PUBLISH_TO_LIVE_REQUEST',
    ACCESS_TOKEN_CREATE_KEY: 'accessTokenCreationNotification',
    WEBHOOK_API_REQUEST: 'WEBHOOK_API_REQUEST',
    WEBHOOK_API_SUCCESS: 'WEBHOOK_API_SUCCESS',
    WEBHOOK_API_ERROR: 'WEBHOOK_API_ERROR',
    EXTENSION_API_REQUEST: 'EXTENSION_API_REQUEST',
    EXTENSION_API_RESPONSE: 'EXTENSION_API_RESPONSE',
    EXTENSION_API_ERROR: 'EXTENSION_API_ERROR',
    CREATE_TRANSLATION: 'CREATE_TRANSLATION'
};

export const NOTIFICATION_DEFAULT_MESSAGES = defineMessages({
    successNotificationMessage: {
        id: 'notificationsConstants.successMessage',
        defaultMessage: '{action} completed'
    },
    requestNotificationMessage: {
        id: 'notificationsConstants.requestMessage',
        defaultMessage: '{action} in progress...'
    },
    errorNotificationMessage: {
        id: 'notificationsConstants.errorMessage',
        defaultMessage: 'An error occurred during {action} operation'
    },
    createAction: {
        id: 'notificationsConstants.createAction',
        defaultMessage: 'save'
    },
    updateAction: {
        id: 'notificationsConstants.updateAction',
        defaultMessage: 'update'
    },
    deleteAction: {
        id: 'notificationsConstants.deleteAction',
        defaultMessage: 'delete'
    },
    archiveAction: {
        id: 'notificationsConstants.archiveAction',
        defaultMessage: 'archive'
    },
    unarchiveAction: {
        id: 'notificationsConstants.unarchiveActions',
        defaultMessage: 'unarchive'
    },
    validationAction: {
        id: 'notificationsConstants.validationActions',
        defaultMessage: 'validation'
    },
    publishToLiveAction: {
        id: 'notificationsConstants.publishAction',
        defaultMessage: 'publish to live'
    },
    publicIdNotExclusive: {
        id: 'notificationsConstants.publicIdNotExclusive',
        defaultMessage: 'Provided publicId should be exclusive for content definition'
    },
    contentPayloadValidationFailed: {
        id: 'notificationsConstants.contentPayloadValidationFailed',
        defaultMessage: `Errors occurred during fields' validation`
    },
    invalidExtensionURL: {
        id: 'notificationsConstants.invalidExtensionURL',
        defaultMessage: `Provided URL was not reachable`
    },
    createTranslationError: {
        id: 'notificationsConstants.createTranslationError',
        defaultMessage: `Failed to create translation`
    },
    deleteTranslationError: {
        id: 'notificationsConstants.deleteTranslationError',
        defaultMessage: `Failed to delete translation`
    },
    cleanTranslationError: {
        id: 'notificationsConstants.cleanTranslationError',
        defaultMessage: `Failed to reset translation`
    },
    translationModeError: {
        id: 'notificationsConstants.translationModeError',
        defaultMessage: `Failed to switch translation's mode`
    }
});
