import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classes from './index.module.scss';
import { Empty } from 'antd';
import { inject, observer } from 'mobx-react';
import { NotificationHubStore } from '../../stores/notificationHubStore';
import NotificationHubItem from '../NotificationHubItem';
import { FormattedMessage } from 'react-intl';

interface NotificationHubProps {
    triggerRef: React.RefObject<HTMLDivElement>;
}

interface InjectedProps extends NotificationHubProps {
    notificationHubStore: NotificationHubStore;
}

interface NotificationHubState {
    style: React.CSSProperties;
}

const DEFAULT_HUB_WIDTH = 300;

@inject('notificationHubStore')
@observer
class NotificationHub extends Component<NotificationHubProps, NotificationHubState> {

    get injected() {
        return this.props as InjectedProps;
    }

    constructor(props: NotificationHubProps) {
        super(props);
        this.state = {
            style: this.getPosition(props.triggerRef)
        };
    }

    calculatePosition = (triggerNode: HTMLDivElement): React.CSSProperties => {

        const {
            offsetHeight,
            offsetTop,
        } = triggerNode as HTMLDivElement;

        const style = {
            top: (offsetTop) + offsetHeight + 20,
            right: 0
        };

        return {
            width: DEFAULT_HUB_WIDTH,
            ...style
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.listenerCallback);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.listenerCallback);
    }

    listenerCallback = () => {
        this.setState(() => {
            return {
                style: this.getPosition(this.props.triggerRef)
            };
        });
    }

    getPosition = (triggerRef: React.RefObject<HTMLDivElement>): React.CSSProperties => {
        try {
            const triggerNode = triggerRef.current as HTMLDivElement;

            return this.calculatePosition(triggerNode);
        } catch (error) {
            this.injected.notificationHubStore.closeHub();
            return {};
        }
    }

    render() {
        return (
            ReactDOM.createPortal(
                <div
                    style={this.state.style}
                    className={classes.NotificationHub}
                >
                    {this.injected.notificationHubStore.notifications.length === 0 ? (
                        <div className={classes.EmptyNotification}>
                            <Empty
                                description={
                                    <FormattedMessage
                                        id="NotificationHub.Empty"
                                        defaultMessage="No notifications"
                                    />
                                }
                            />
                        </div>
                    ) : (
                            this.injected.notificationHubStore.firstFiveNotifications.map(notification => (
                                <NotificationHubItem
                                    notification={notification}
                                    key={notification.identifier}
                                />
                            ))
                        )}
                </div>,
                document.body
            )
        );
    }
}

export default NotificationHub;
