import { MediaTagsStoreModel, LoaderModel } from './mediaTagsStoreModel';
import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import { observable, IObservableArray, action, runInAction } from 'mobx';

export default class MediaTagsStore implements MediaTagsStoreModel {
    @observable api: ContentChefClient;
    @observable suggestedTags: IObservableArray<string> = observable.array([]);
    @observable loader: LoaderModel = {
        suggestedTags: false
    };
    constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action
    async setSuggestedTags(partialTag: string) {
        try {
            this.loader.suggestedTags = true;
            const response = await this.api.media.listTag({
                tag: partialTag
            });
            runInAction(() => {
                this.suggestedTags.replace(response.tags);
                this.loader.suggestedTags = false;
            });
        } catch (e) {
            console.log('Error occurred trying to retrieve media tag list', e);
            runInAction(() => {
                this.loader.suggestedTags = false;
            });
        }
    }
}
