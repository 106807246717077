import { SchemaTypeIds } from '@contentchef/contentchef-types';
import React from 'react';
import { LinkedContent } from '../../../components';
import { DynamicFieldTypes, FieldLinkedContent } from '../FormFields';
import { defaultFieldWrapper } from './DefaultFieldWrapper';
import { fieldMatchersUtils } from './FieldMatchersUtils';
import { FieldMatcher } from './FormFieldMatchersFactory';

export class LinkedContentTypeMatcher implements FieldMatcher {
    maybeCreateComponent(
        id: string, userLocale: string, onChange: (e: any) => void, field: DynamicFieldTypes
    ): JSX.Element | null {
        if (!fieldMatchersUtils.typeMatcher(field, SchemaTypeIds.LINKED_CONTENT)) { return null; }
        const fieldLinkedContent = field as FieldLinkedContent;

        function elementToRender(disabled: boolean) {
            return (
                <LinkedContent
                    id={id}
                    disableActions={disabled || fieldLinkedContent.disabled}
                    linkedField={fieldLinkedContent}
                    onChange={onChange}
                />
            );
        }

        return defaultFieldWrapper(elementToRender);

    }
}
