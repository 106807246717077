import { withDisabledPropertyInContentForm } from '@hoc/withDisabledPropertyInContentForm';
import { Col, DatePicker, Form, Icon, Row } from 'antd';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { DATE_TIME_FORMAT, TIME_FORMAT } from '../../../constants/date-constants';
import { withPermissionsToInteract } from '../../../providers/UserPermissionsProvider';
import { formNormalizers } from '../../../services/FormUtils/FormFieldNormalize';
import { createRange } from '../../../services/utils';
import styles from './PublicationRangePicker.module.scss';
import { InjectedProps, PublicationRangePickerProps, PublicationRangePickerState } from './PublicationRangePickerModel';

const FormItem = Form.Item;

const publicationRangePickerLabels = defineMessages({
    onlineDate: {
        id: 'PublicationRangePicker.OnlineDate',
        defaultMessage: 'Online Date'
    },
    offlineDate: {
        id: 'PublicationRangePicker.OfflineDate',
        defaultMessage: 'Offline Date'
    }
});

class PublicationRangePicker extends Component<PublicationRangePickerProps, PublicationRangePickerState> {
    isFirstOnlineDateChoose: boolean = true;
    isFirstOfflineDateChoose: boolean = true;

    get injected() {
        return this.props as InjectedProps;
    }

    constructor(props: PublicationRangePickerProps) {
        super(props);
        this.onChangeOnlineDate = this.onChangeOnlineDate.bind(this);
        this.onChangeOfflineDate = this.onChangeOfflineDate.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.disabledOnlineDate = this.disabledOnlineDate.bind(this);
        this.disabledOfflineDate = this.disabledOfflineDate.bind(this);
        this.disabledOnlineTime = this.disabledOnlineTime.bind(this);
        this.disabledOfflineTime = this.disabledOfflineTime.bind(this);
    }

    onChangeOnlineDate(date: Moment | null) {
        if (date && this.isFirstOnlineDateChoose) {
            date.hours(0).minutes(0).seconds(0);
            this.isFirstOnlineDateChoose = false;
        }
        if (date === null) {
            this.isFirstOnlineDateChoose = true;
        }
        const { offlineDate } = this.props.onlineRangeField.value;
        this.onChangeValue(date ? date : undefined, offlineDate.value ? moment(offlineDate.value) : undefined);
    }

    onChangeOfflineDate(date: Moment | null) {
        if (date && this.isFirstOfflineDateChoose) {
            date.hours(23).minutes(59).seconds();
            this.isFirstOfflineDateChoose = false;
        }
        if (date === null) {
            this.isFirstOfflineDateChoose = true;
        }
        const { onlineDate } = this.props.onlineRangeField.value;
        this.onChangeValue(onlineDate.value ? moment(onlineDate.value) : undefined, date ? date : undefined);
    }

    onChangeValue(onlineDate: Moment | undefined, offlineDate: Moment | undefined) {
        const onlineValue = formNormalizers['date'](onlineDate);
        const offlineValue = formNormalizers['date'](offlineDate);
        if (onlineValue && offlineValue && moment(onlineValue).isAfter(offlineValue)) {
            return;
        }
        this.props.onlineRangeField.setValues({ onlineDate: onlineValue, offlineDate: offlineValue });
        this.injected.onChange({ onlineDate, offlineDate });
    }

    disabledOnlineDate(date: Moment | null) {
        const { offlineDate } = this.props.onlineRangeField.value;
        if (!date || !offlineDate.value) {
            return false;
        }
        const offlineDateToCheck = new Date(offlineDate.value);
        return date.month() === offlineDateToCheck.getMonth()
            && date.year() === offlineDateToCheck.getFullYear()
            && date.date() > offlineDateToCheck.getDate();
    }

    disabledOfflineDate(date: Moment | null) {
        const { onlineDate } = this.props.onlineRangeField.value;
        if (!date || !onlineDate.value) {
            return false;
        }
        const onlineDateToCheck = new Date(onlineDate.value);
        return date.month() === onlineDateToCheck.getMonth()
            && date.year() === onlineDateToCheck.getFullYear()
            && date.date() < onlineDateToCheck.getDate();
    }

    disabledOnlineTime(current?: Moment | null) {
        const { offlineDate } = this.props.onlineRangeField.value;
        const momentOfflineDate: Moment = moment(offlineDate.value);

        if (!offlineDate.value) {
            return {};
        } else {
            if (
                current &&
                current.dayOfYear() === momentOfflineDate.dayOfYear() &&
                current.hours() !== momentOfflineDate.hours()
            ) {
                return {
                    disabledHours: () => {
                        return createRange(momentOfflineDate.hours(), 24);
                    }
                };
            }
            if (
                current &&
                current.dayOfYear() === momentOfflineDate.dayOfYear() &&
                current.hours() === momentOfflineDate.hours()
            ) {
                return {
                    disabledHours: () => {
                        return createRange(momentOfflineDate.hours(), 24);
                    },
                    disabledMinutes: () => {
                        return createRange(momentOfflineDate.minutes(), 60);
                    }
                };
            }
        }
        return {};
    }

    disabledOfflineTime(current?: Moment | null) {
        const { onlineDate } = this.props.onlineRangeField.value;
        const momentOnlineDate: Moment = moment(onlineDate.value);

        if (!onlineDate.value) {
            return {};
        } else {
            if (current &&
                current.dayOfYear() === momentOnlineDate.dayOfYear() &&
                current.hours() !== momentOnlineDate.hours()
            ) {
                return {
                    disabledHours: () => {
                        return createRange(0, momentOnlineDate.hours());
                    }
                };
            }
            if (current &&
                current.dayOfYear() === momentOnlineDate.dayOfYear() &&
                current.hours() === momentOnlineDate.hours()
            ) {
                return {
                    disabledHours: () => {
                        return createRange(0, momentOnlineDate.hours());
                    },
                    disabledMinutes: () => {
                        return createRange(0, momentOnlineDate.minutes());
                    }
                };
            }
        }
        return {};
    }

    render() {
        const { onlineRangeField, hasPermissions, archived, ...others } = this.injected;
        const { onlineDate, offlineDate } = onlineRangeField.getValues();
        const { formatMessage } = this.props.intl;
        const disabled = archived || !hasPermissions;
        return (
            <Row gutter={24}>
                <Col xs={24} md={12}>
                    <FormItem
                        label={<span className={styles.PublicationRangeFormItemLabel}>
                            <span>{formatMessage(publicationRangePickerLabels.onlineDate)}</span>
                            <Icon type="calendar" className={styles.PublicationRangeOnlineDateIcon} />
                        </span>}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <DatePicker
                            {...others}
                            showTime={{ format: TIME_FORMAT }}
                            showToday={false}
                            className={styles.PublicationRangePickerStyle}
                            disabled={disabled}
                            placeholder={formatMessage(publicationRangePickerLabels.onlineDate)}
                            value={onlineDate ? moment(onlineDate) : undefined}
                            format={DATE_TIME_FORMAT}
                            disabledDate={this.disabledOnlineDate}
                            disabledTime={this.disabledOnlineTime}
                            onChange={this.onChangeOnlineDate}
                            getCalendarContainer={() => document.getElementById('scrollable-content') as HTMLDivElement}
                        />
                    </FormItem>
                </Col>
                <Col xs={24} md={12}>
                    <FormItem
                        label={<span className={styles.PublicationRangeFormItemLabel}>
                            <span>{formatMessage(publicationRangePickerLabels.offlineDate)}</span>
                            <Icon type="calendar" className={styles.PublicationRangeOfflineDateIcon} />
                        </span>}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <DatePicker
                            {...others}
                            showTime={{ format: TIME_FORMAT }}
                            showToday={false}
                            className={styles.PublicationRangePickerStyle}
                            disabled={disabled}
                            placeholder={formatMessage(publicationRangePickerLabels.offlineDate)}
                            value={offlineDate ? moment(offlineDate) : undefined}
                            format={DATE_TIME_FORMAT}
                            disabledDate={this.disabledOfflineDate}
                            disabledTime={this.disabledOfflineTime}
                            onChange={this.onChangeOfflineDate}
                            getCalendarContainer={() => document.getElementById('scrollable-content') as HTMLDivElement}
                        />
                    </FormItem>
                </Col>
            </Row>
        );
    }
}

export default withDisabledPropertyInContentForm(withPermissionsToInteract(injectIntl(PublicationRangePicker)));
