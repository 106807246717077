import React, { Component } from 'react';
import { Icon } from 'antd';
import { NavLink } from 'react-router-dom';

import styles from './Crumb.module.scss';

interface CrumbProps {
    displayValue: string;
    path?: string;
    icon?: string;
}

class Crumb extends Component<CrumbProps> {
    render() {
        const { icon, displayValue, path } = this.props;

        if (!path) {
            return (
                <div
                    className={styles.CrumbContainer}
                >
                    {icon && (
                        <div>
                            <Icon type={icon} />
                        </div>
                    )}
                    <p
                        className={styles.CrumbTextContainer}
                    >
                        {displayValue && displayValue}
                    </p>
                </div>
            );
        }

        return (
            <NavLink
                to={path}
                className={styles.CrumbContainer}
            >
                {icon && (
                    <div>
                        <Icon type={icon} />
                    </div>
                )}
                <p
                    className={styles.CrumbTextContainer}
                >
                    {displayValue && displayValue}
                </p>
            </NavLink>
        );
    }
}

export default Crumb;
