import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import {
    ContentId, CreateContentRequest, DeleteContentRequest,
    DeleteContentResponse, GetContentRequest,
    GetContentResponse, LinkingContents, ToggleArchivedContentRequest, ToggleArchivedContentResponse, UpdateContentRequest,
    UpdateContentResponse
} from '@contentchef/contentchef-types';
import { action, IObservableArray, observable, runInAction } from 'mobx';
import { ContentErrorFactory } from '../../services/Error/ContentErrorManager';
import { ContentStoreModel, ErrorObjectModel } from './contentStoreModel';

class ContentStore implements ContentStoreModel {
    api: ContentChefClient;
    @observable selectedContent: GetContentResponse['content'];
    @observable linkingContents: IObservableArray<LinkingContents> = observable.array([]);

    @observable error = {
        content: {} as ErrorObjectModel,
        updateContent: {} as ErrorObjectModel,
        saveContent: {} as ErrorObjectModel,
        duplicateContent: {} as ErrorObjectModel
    };

    static handleApiErrors(error: any, apiName: string) {
        console.log(`Impossible to call ${apiName}`, JSON.parse(JSON.stringify(error)));
        return error.details ?
            ContentErrorFactory.createContentError(error.details)
            : ContentErrorFactory.createContentError(error);
    }

    constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action.bound
    async setContentById(contentId: string) {
        const reqParam: GetContentRequest = { id: +contentId, retrieveLinkingContents: true };
        try {
            const response = await this.api.contents.get(reqParam);
            runInAction(() => {
                this.selectedContent = response.content;
                this.linkingContents.replace(response.linkingContents || []);
            });
            return Promise.resolve();
        } catch (e: any) {
            runInAction(() => {
                this.error.content = e;
                console.log(e, 'Impossible to call setContentById');
            });
            return Promise.reject();
        }
    }

    async duplicateContent(id: ContentId) {
        const reqParam = {
            id
        };
        try {
            return await this.api.contents.duplicate(reqParam);
        } catch (e: any) {
            this.error.duplicateContent = e;
            console.log('Impossible to call duplicateContent');
            return Promise.reject(e);
        }
    }

    async saveContent(reqParams: CreateContentRequest): Promise<number | undefined> {
        try {
            const response = await this.api.contents.save(reqParams);
            return response.id;
        } catch (e) {
            return Promise.reject(ContentStore.handleApiErrors(e, 'Create Content'));
        }
    }

    @action
    async updateContent(reqParams: UpdateContentRequest): Promise<UpdateContentResponse> {
        try {
            const response = await this.api.contents.update(reqParams);
            return Promise.resolve(response);
        } catch (e) {
            return Promise.reject(ContentStore.handleApiErrors(e, 'Update Content'));
        }
    }

    @action
    async deleteContent(reqParams: DeleteContentRequest): Promise<DeleteContentResponse | undefined> {
        try {
            return await this.api.contents.remove(reqParams);
        } catch (e) {
            return Promise.reject(ContentStore.handleApiErrors(e, 'Delete Content'));
        }
    }

    @action
    async toggleArchivedContent(reqParams: ToggleArchivedContentRequest):
        Promise<ToggleArchivedContentResponse | undefined> {
        try {
            return await this.api.contents.toggleArchived(reqParams);
        } catch (e) {
            console.log(e);
            return Promise.reject(ContentStore.handleApiErrors(e, `Toggle Archived ${reqParams.archived}`));
        }
    }

    @action.bound
    async changeLocale(id: ContentId, locale: string) {
        try {
            const response = await this.api.contents.changeLocale({ contentId: id, locale });
            runInAction(() => {
                this.selectedContent = response;
            });
            return response;
        } catch (error) {
            console.log(error);
            return Promise.reject(ContentStore
                .handleApiErrors(error, `Change Locale ${this.selectedContent?.id} ${locale}`));
        }
    }
}

export default ContentStore;
