import React from 'react';
import styles from './SpaceStats.module.scss';
import { Row, Col, Popover, List } from 'antd';
import { Section } from '../../../../components';
import Statistic from '../Statistic';
import { SpaceStats, TopContentDefinition } from '@contentchef/contentchef-types';
import { FormattedMessage } from 'react-intl';

const TopDefinitions: React.SFC<{ spaceStats: SpaceStats }> = ({ spaceStats }) => {

    if (!spaceStats.topContentDefinitions) {
        return null;
    }

    const items = (
        <List
            size="small"
            header={
                <FormattedMessage
                    id="SpaceStats.ContentDefinitions.Top"
                    defaultMessage="Currently most used"
                />
            }
            dataSource={spaceStats.topContentDefinitions}
            bordered={true}
            renderItem={(item: TopContentDefinition) => (
                <List.Item>
                    <div className={styles.topDefContainer}>
                        <div className={styles.definitionData}>
                            <div>{item.mnemonic}</div>
                        </div>
                        <div className={styles.activeContentCount}>
                            {item.unarchivedContents}
                        </div>
                    </div>
                </List.Item>
            )
            }
        />
    );

    return (
        <Popover content={<>{items}</>} trigger="click">
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                {spaceStats.definitionsCount}
            </a>
        </Popover>
    );
};

const component: React.SFC<{
    spaceStats: SpaceStats
}> = ({ spaceStats }) => {
    return (
        <Row>
            <Col xs={24} >
                <Section >
                    <Row
                        className={styles.statsContainer}
                        type="flex"
                        justify="space-around"
                        align="middle"
                    >
                        <Statistic
                            title={
                                <FormattedMessage id="SpaceStats.Contents" defaultMessage="Contents" />
                            }
                            value={spaceStats.unarchivedContentCount}
                            suffix={spaceStats.archivedContentCount > 0 ?
                                `/ ${spaceStats.contentsCount}` : ''}
                        />
                        <Statistic
                            title={
                                <FormattedMessage
                                    id="SpaceStats.ContentDefinitions"
                                    defaultMessage="Content Definitions"
                                />
                            }
                            value={<TopDefinitions spaceStats={spaceStats} />}
                        />
                        <Statistic
                            title={
                                <FormattedMessage
                                    id="SpaceStats.mediaGalleries"
                                    defaultMessage="Media Galleries"
                                />
                            }
                            value={spaceStats.mediaGalleriesCount}
                        />
                        <Statistic
                            title={
                                <FormattedMessage
                                    id="SpaceStats.Repositories"
                                    defaultMessage="Repositories"
                                />
                            }
                            value={spaceStats.repositoriesCount}
                        />
                    </Row>
                </Section>
            </Col>
        </Row>
    );
};

export default component;
