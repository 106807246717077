import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { SceneMinHeaderProps } from './SceneMinHeaderModel';

import styles from './SceneMinHeader.module.scss';

class SceneMinHeader extends Component<SceneMinHeaderProps> {
    render() {
        const {
            name,
            color,
            links,
            dataAttributes
        } = this.props;
        const style = {
            background: color,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top center'
        };
        return (
            <div
                {...dataAttributes().Attributes}
                style={style}
                className={styles.sceneDashboardHeader}
            >
                <h2 className={styles.headerTitle}>{name}</h2>
                <ul className={styles.headerLinks}>
                    {links.map((link, index) => {
                        const activeClass = this.props.location.pathname === link.to ? styles.activeLink : '';
                        return (
                            <li key={index} className={styles.headerLinksElement}>
                                <Link
                                    data-link={link.label}
                                    className={activeClass}
                                    to={link.to}
                                >
                                    {link.label}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

export default SceneMinHeader;
