import { PaymentStore } from '../../stores';
import { ReactStripeElements } from 'react-stripe-elements';
import { PaymentIntentStatus } from '@contentchef/contentchef-types';
import { RequiresPaymentMethodFlow } from './RequiresPaymentMethodFlow';
import { DefaultPaymentFlow } from './DefaultPaymentFlow';
import { PaymentFlow } from './PaymentFlow';

export default function paymentFlowFactory(
    stripe: ReactStripeElements.StripeProps,
    paymentStore: PaymentStore,
    status: PaymentIntentStatus | null
): PaymentFlow {
    switch (status) {
        case PaymentIntentStatus.REQUIRES_PAYMENT_METHOD:
            return new RequiresPaymentMethodFlow(stripe, paymentStore);
        default:
            return new DefaultPaymentFlow(stripe, paymentStore);
    }
}
