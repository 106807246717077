import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import ContentDefinitions from '../ContentDefinitions/ContentDefinitions';
import { ContentDefinitionsRouterProps, InjectedProps } from './ContentDefinitionsRouterModel';
import {
    CONTENT_DEFINITIONS_ROUTE,
    NEW_CONTENT_DEFINITION,
    EDIT_CONTENT_DEFINITION,
    contentDefinitionBasePath,
} from '../../../constants/routing-constants';
import { inject } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { NavigationItem } from '../../../stores/navStore/navStore';
import { NAVIGATION_LABELS } from '../../../constants/navigation-internationalized-labels';
import CreateContentDefinitionRouter from '../CreateContentDefinitionRouter/CreateContentDefinitionRouter';
import UpdateContentDefinitionRouter from '../UpdateContentDefinitionRouter/UpdateContentDefinitionRouter';
import { NAVIGATION_ITEM_IDENTIFIERS } from '../../../constants/navigation-item-identifier';
import { NAVIGATION_PRIORITY } from '../../../stores/navStore/navStoreModel';
import { userPermissionsTable } from '../../../constants/user-permissions-table';
import { ManageFieldProvider } from '../../../providers/UserPermissionsProvider';

class ContentDefinitionsRouter extends Component<ContentDefinitionsRouterProps, {}> {
    get injected() { return this.props as InjectedProps; }

    UNSAFE_componentWillMount(): void {
        const {
            intl: { formatMessage },
            match: { params: { spaceId } }
        } = this.props;
        this.injected.navStore.setNavigationPath(
            new NavigationItem(
                NAVIGATION_ITEM_IDENTIFIERS.DEFINITIONS,
                contentDefinitionBasePath(spaceId),
                formatMessage(NAVIGATION_LABELS.contentDefinitions),
                NAVIGATION_PRIORITY.FIRST
            )
        );
    }

    componentWillUnmount() {
        this.injected.navStore.removeItem(NAVIGATION_ITEM_IDENTIFIERS.DEFINITIONS);
    }

    render() {
        return (
            <Switch>
                <Route path={NEW_CONTENT_DEFINITION}>
                    <ManageFieldProvider permissions={userPermissionsTable.Forms.CreateContentDefinitionForm}>
                        <CreateContentDefinitionRouter />
                    </ManageFieldProvider>
                </Route>
                <Route
                    path={EDIT_CONTENT_DEFINITION}
                >
                    <ManageFieldProvider permissions={userPermissionsTable.Forms.UpdateContentDefinitionForm}>
                        <UpdateContentDefinitionRouter />
                    </ManageFieldProvider>
                </Route>
                <Route
                    exact={true}
                    path={CONTENT_DEFINITIONS_ROUTE}
                    component={ContentDefinitions}
                />
            </Switch>
        );
    }
}

export default inject('navStore')(injectIntl(ContentDefinitionsRouter));
