import withExtensionStore from '@hoc/withExtensionStore';
import { useExtensionStore } from '@hooks/mobx';
import { DynamicFieldTypes } from '@services/FormUtils/FormFields';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';

export interface ExtensionFieldProps extends InjectedIntlProps {
    fieldData: DynamicFieldTypes;
    locale?: string;
    verifyFieldHasClientError: () => boolean;
}

function ExtensionField(props: ExtensionFieldProps) {

    const ref = useRef<HTMLIFrameElement>(null);
    const extensionStore = useExtensionStore();
    const height = extensionStore.height;

    return (
        <iframe
            title={props.fieldData.uniqueId}
            ref={ref}
            onLoad={() => {
                extensionStore.init(ref.current!);
            }}
            src={extensionStore.extensionURL!}
            style={{
                width: '100%',
                border: 0,
                ...height > 0 ? { height } : {}
            }}
        />
    );
}

export default injectIntl(withExtensionStore(observer(ExtensionField)));
