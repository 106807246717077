import React from 'react';
import HelpSection from '../HelpSection';
import { Icon } from 'antd';
import styles from './NavActions.module.scss';
import { inject } from 'mobx-react';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';
import {
    ONBOARDING_WIZARD_ROUTE,
    ROOT_ROUTE,
    PROFILE_ROUTE
} from '../../constants/routing-constants';
import { Link, Route, Switch } from 'react-router-dom';
import Authorized from '../Authorized';
import LogoutButton from '../LogoutButton';
import NotificationHubTrigger from '../NotificationHubTrigger';
import { InjectedIntlProps, injectIntl, defineMessages } from 'react-intl';

interface NavActionsProps extends InjectedIntlProps {
    hideActions?: boolean;
}

interface NavActionsInjectedProps extends NavActionsProps {
    apiStore: ApiStoreModel;
}

const labels = defineMessages({
    settingsTitle: { id: 'navactions.settings.icon.title', defaultMessage: 'Settings' },
    logoutTitle: { id: 'navactions.signout.icon.title', defaultMessage: 'Sign Out' }
});

@inject('apiStore')
class NavActions extends React.Component<NavActionsProps> {

    clearSpaceId(props: NavActionsProps) {
        return () => {
            const injected = props as NavActionsInjectedProps;
            injected.apiStore.clearCurrentSpace();
        };
    }

    render() {
        const injected = this.props as NavActionsInjectedProps;

        const { hideActions, intl: { formatMessage } } = injected;

        return (
            <div className={styles.NavActionsContainer}>
                {!hideActions && <Switch>
                    <Route
                        path={ONBOARDING_WIZARD_ROUTE}
                        render={() => null}
                    />
                    <Route
                        path={ROOT_ROUTE}
                        render={() => (
                            <React.Fragment>
                                <NotificationHubTrigger />
                                <Link
                                    to={PROFILE_ROUTE}
                                    onClick={this.clearSpaceId(this.props)}
                                    className={styles.NavActionsSettingsContainer}
                                >
                                    <Icon
                                        type="setting"
                                        title={formatMessage(labels.settingsTitle)}
                                    />
                                </Link>
                                <HelpSection />
                            </React.Fragment>
                        )}
                    />
                </Switch>}
                <Authorized>
                    <LogoutButton onClick={() => window.fbq('trackCustom', 'app.logout', {})}>
                        <div className={styles.NavActionsLogoutContainer}>
                            <Icon
                                type="logout"
                                title={formatMessage(labels.logoutTitle)}
                            />
                        </div>
                    </LogoutButton>
                </Authorized>
            </div>
        );
    }
}

export default injectIntl(NavActions);
