import {
    CloudinaryImageSchemaType,
    Field,
    SchemaTypeIds
} from '@contentchef/contentchef-types';

function transformableImageReference(defaultLanguage: string, name: string): Field<CloudinaryImageSchemaType> {
    return {
        name,
        type: SchemaTypeIds.CLOUDINARY_IMAGE,
        labels: {
            [defaultLanguage]: name,
        },
        placeholder: undefined,
        hint: undefined,
        constraints: {
            required: false
        },
        compact: undefined
    };
}

function transformableImageReferenceInArray(): CloudinaryImageSchemaType {
    return {
        type: SchemaTypeIds.CLOUDINARY_IMAGE,
        translatable: undefined,
        constraints: {}
    };
}

export const cloudinaryImageSnippets = {
    asField: {
        transformableImageReference
    },
    asArray: {
        transformableImageReferenceInArray
    }
};
