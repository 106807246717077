import { Input, Form } from 'antd';
import React from 'react';
import Segment from '../../../Segment/Segment';
import
withDefinitionErrors,
{ WithDefinitionErrorsReturnProps }
    from '../../../../hoc/withDefinitionErrors/withDefinitionErrors';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import {
    withPermissionsToInteract,
    WithPermissionsToInteractReturnType
} from '../../../../providers/UserPermissionsProvider';
const FormItem = Form.Item;

export interface StringProps extends WithDefinitionErrorsReturnProps, InjectedIntlProps,
    WithPermissionsToInteractReturnType {
    children?: React.ReactNode;
    value?: string;
    description?: React.ReactNode;
    onChange?(value: string): any;
}

function createHandler<T extends (arg: any) => any>(maybeFn?: T) {
    if (!maybeFn) {
        return () => void 0;
    }

    return (event: React.ChangeEvent<HTMLInputElement>) => maybeFn(event.target.value);
}

function String(props: StringProps) {
    return (
        <Segment
            divider={true}
            titleVariant="label"
            title={props.children}
            subTitle={props.description}
        >
            <FormItem
                help={props.hasErrors ? props.errors.map(e => props.intl.formatMessage(e)).join(', ') : undefined}
                validateStatus={props.hasErrors ? 'error' : undefined}
                style={{ width: '100%' }}
            >
                <Input
                    disabled={!props.hasPermissions}
                    onChange={createHandler(props.onChange)}
                    type="text"
                    value={props.value}
                />
            </FormItem>
        </Segment>
    );
}

export default withPermissionsToInteract(withDefinitionErrors(injectIntl(String)));
