import React from 'react';
import { Icon } from 'antd';
import { ArchiveSvg } from './SvgIcons';

interface IconProps {
    className?: string;
    style?: React.CSSProperties;
}

export const ArchiveIcon = (props: IconProps) => (
    <Icon component={ArchiveSvg} {...props} />
);
