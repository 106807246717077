import React, { Component } from 'react';
import { inject } from 'mobx-react';
import {
    ContentDefinitionSchemaStoreModel
} from '../../../stores/ContentDefinitionEditorStore/ContentDefinitionSchemaStore';
import { locales } from '../../ContentDefinitionSchemaEditor/ContentDefinitionFieldEditing/TranslateElement/locales';
import { Select } from 'antd';

interface ContentDefinitionDefaultLangProps {
    hasPermissions: boolean;
}

interface DecoratedProps extends ContentDefinitionDefaultLangProps {
    contentDefinitionSchemaStore: ContentDefinitionSchemaStoreModel;
    id: string;
    value?: string;
    onChange(value: string): void;
}

@inject('contentDefinitionSchemaStore')
class ContentDefinitionDefaultLang extends Component<ContentDefinitionDefaultLangProps, {}> {
    get decoratedProps() { return this.props as DecoratedProps; }

    onChangeValue = (newLang: string) => {
        const oldLang = this.decoratedProps.contentDefinitionSchemaStore.schema.lang;
        this.decoratedProps.contentDefinitionSchemaStore.changeSchemaI18Language(newLang, oldLang);
        this.decoratedProps.contentDefinitionSchemaStore.saveDefaultLanguage(newLang);
        return this.decoratedProps.onChange(newLang);
    }

    render() {
        const { id, value, hasPermissions } = this.decoratedProps;
        return (
            <Select disabled={!hasPermissions} id={id} showSearch={true} onChange={this.onChangeValue} value={value}>
                {locales.map(locale => (
                    <Select.Option key={locale} value={locale}>{locale}</Select.Option>
                ))}
            </Select>
        );
    }
}

export default ContentDefinitionDefaultLang;
