import React, { Component } from 'react';
import { inject, Provider } from 'mobx-react';
import ExtensionAPIStore from '@stores/extensionAPIStore';
import { ApiStoreModel } from '@stores/apiStore/apiStore';

export default function withExtensionAPI<T>(WrappedComponent: React.ComponentType<T>) {

    @inject('apiStore')
    class WithExtensionAPI extends Component<T> {
        get injected() {
            return this.props as T & { apiStore: ApiStoreModel };
        }
        extensionAPI: ExtensionAPIStore;
        constructor(props: T) {
            super(props);
            const apiStore = this.injected.apiStore;
            this.extensionAPI = new ExtensionAPIStore(apiStore.api);
        }
        render() {
            return (
                <Provider
                    extensionAPI={this.extensionAPI}
                >
                    <WrappedComponent {...this.props} />
                </Provider>
            );
        }
    }

    return WithExtensionAPI;
}
