import React from 'react';
import { Provider } from 'mobx-react';
import FieldEditorStore from './FieldEditorStore';

export default function withFieldEditorStore<T>(Component: React.ComponentType<T>) {
    class WithFieldEditorStore extends React.Component<T> {
        public store = new FieldEditorStore();

        public render() {
            return (
                <Provider fieldEditorStore={this.store}>
                    <Component
                        {
                        ... this.props
                        }
                    />
                </Provider>
            );
        }
    }

    return WithFieldEditorStore;
}
