import React, { Component } from 'react';
import { ExpandedListItemProps } from '../../components/List/ExpandedListItem/ExpandedListItemModel';

export type WithExpandedListItemProps =
    Pick<
        ExpandedListItemProps,
        Exclude<keyof ExpandedListItemProps, keyof { style: any, parent: any }>> & { parent: React.RefObject<any> };

export type AdditionalWithExpandedListItemProps = {
    parent: React.RefObject<any>;
};
export type WithExpandedListItemState = {
    style: React.CSSProperties;
};

const DEFAULT_DISTANCE_FROM_ITEM = 10;
const DEFAULT_HEIGHT_EXPANDED = 250;
const DEFAULT_WIDTH_EXPANDED = 250;

const baseStyle: React.CSSProperties = {
    position: 'absolute',
    height: DEFAULT_HEIGHT_EXPANDED,
    width: DEFAULT_WIDTH_EXPANDED,
    zIndex: 999
};

export type OrientationPreview =
    'top' | 'bottom';

enum Orientation {
    TOP = 'top',
    BOTTOM = 'bottom',
}

const withExpandedListItem = (WrappedComponent: React.ComponentClass<ExpandedListItemProps>) => {
    return class WithExpandedListItem extends Component<WithExpandedListItemProps, WithExpandedListItemState> {
        constructor(props: WithExpandedListItemProps) {
            super(props);
            this.state = {
                style: this.getStyleBasedOnOrientation(this.getOrientation(this.props.parent))
            };
        }

        getStyleBasedOnOrientation = (orientation: OrientationPreview) => {
            let styleBasedOnOrientation = {};
            const parentNode = this.props.parent.current as HTMLDivElement;
            const {
                offsetHeight,
                offsetLeft,
                offsetTop,
                offsetWidth,
                parentNode: itemNode } = parentNode.parentNode as HTMLDivElement;
            const { scrollTop } = itemNode as HTMLDivElement;

            if (orientation === Orientation.BOTTOM) {
                styleBasedOnOrientation = {
                    top: (offsetTop - scrollTop) + offsetHeight,
                    left: offsetLeft - ((DEFAULT_WIDTH_EXPANDED - offsetWidth) / 2)
                };
            }

            if (orientation === Orientation.TOP) {
                styleBasedOnOrientation = {
                    top: offsetTop - offsetHeight - (DEFAULT_HEIGHT_EXPANDED - offsetHeight) - scrollTop,
                    left: offsetLeft - ((DEFAULT_WIDTH_EXPANDED - offsetWidth) / 2)
                };
            }

            return Object.assign({}, baseStyle, styleBasedOnOrientation);
        }

        getVerticalOrientation = (distanceToBottomOfElement: number): OrientationPreview => {
            const expandedItemFitBottom =
                window.innerHeight - distanceToBottomOfElement > DEFAULT_HEIGHT_EXPANDED + DEFAULT_DISTANCE_FROM_ITEM;
            return expandedItemFitBottom ?
                Orientation.BOTTOM : Orientation.TOP;
        }

        getOrientation = (parentRef: React.RefObject<HTMLDivElement>) => {
            const parentNode = parentRef.current as HTMLDivElement;

            const { bottom } = parentNode.getBoundingClientRect();

            return this.getVerticalOrientation(bottom);
        }

        render() {
            const { style } = this.state;
            const { parent, ...others } = this.props;
            const listItemElement = parent.current!.parentNode;
            const listContainerNode = listItemElement.parentNode as HTMLDivElement;
            return (
                <WrappedComponent style={style} parent={listContainerNode} {...others} />
            );
        }
    };
};

export default withExpandedListItem;
