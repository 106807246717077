import { SchemaTypeIds } from '@contentchef/contentchef-types';
import { ValidationRule } from 'antd/lib/form';
import { defineMessages } from 'react-intl';
import {
    CommonFieldTypes,
    FieldLongText,
    FieldName,
    FieldNumber,
    FieldPublicId,
    FieldText,
    SimpleFieldTypes
} from './FormFields';

const DynamicFormMessages = defineMessages({
    requiredDefaultMsg: {
        id: 'DynamicForm.default.requiredMsg',
        defaultMessage: 'Field {fieldName} is required'
    },
    minStringMsg: {
        id: 'DynamicForm.string.minMsg',
        defaultMessage: 'Field {fieldName} must be more than {fieldMinLength} characters'
    },
    maxStringMsg: {
        id: 'DynamicForm.string.maxMsg',
        defaultMessage: 'Field {fieldName} must be less than {fieldMaxLength} characters'
    },
    lenStringMsg: {
        id: 'DynamicForm.string.lenMsg',
        defaultMessage: 'Field {fieldName} must be exactly {fieldExactLen} characters'
    },
    patternStringMsg: {
        id: 'DynamicForm.string.patternMsg',
        defaultMessage: 'Field {fieldName} contains not valid characters'
    },
    patternNumberMsg: {
        id: 'DynamicForm.number.patternMsg',
        defaultMessage: 'Field {fieldName} must be a number'
    },
    minNumberMsg: {
        id: 'DynamicForm.number.minMsg',
        defaultMessage: 'Field {fieldName} must be a number greater than {fieldMinLength}'
    },
    maxNumberMsg: {
        id: 'DynamicForm.number.maxMsg',
        defaultMessage: 'Field {fieldName} must be a number lower than {fieldMaxLength}'
    },
    slugSuffixMsg: {
        id: 'DynamicForm.slug.suffixMasg',
        defaultMessage: 'Field {fieldName} must end with {suffix}'
    },
    prefixSuffixMsg: {
        id: 'DynamicForm.slug.prefixMasg',
        defaultMessage: 'Field {fieldName} must start with {prefix}'
    },
});

type ShortStringRuleTypes = FieldText | FieldName | FieldPublicId;
type LongStringRuleTypes = FieldLongText;
type AllStringRuleTypes = ShortStringRuleTypes | LongStringRuleTypes;
type CustomValidator = (rule: any, value: string, callback: any, source: any, options: any) => void;

function invalidOnlyIfUndefinedValidator(rule: any, value: string, callback: any, source: any, options: any): void {
    const errors: string[] = [];
    if (value === undefined) {
        errors.push(rule.message);
    }
    callback(errors);
}

function createCustomRequiredRule(
    field: SimpleFieldTypes, intlFn: any, label: string, customValidator: CustomValidator
): ValidationRule {
    return {
        required: field.constraints.required,
        validator: customValidator,
        message: intlFn(DynamicFormMessages.requiredDefaultMsg, { fieldName: label })
    };
}

function createRequiredRule(field: SimpleFieldTypes | CommonFieldTypes, intlFn: any, label: string): ValidationRule {
    return {
        required: field.constraints.required,
        message: intlFn(DynamicFormMessages.requiredDefaultMsg, { fieldName: label })
    };
}

function createStringMinLengthRule(field: AllStringRuleTypes, intlFn: any, label: string): ValidationRule {
    return {
        min: field.constraints.minLength,
        message: intlFn(DynamicFormMessages.minStringMsg, {
            fieldName: label,
            fieldMinLength: field.constraints.minLength
        })
    };
}

function createStringMaxLengthRule(field: AllStringRuleTypes, intlFn: any, label: string): ValidationRule {
    return {
        max: field.constraints.maxLength,
        message: intlFn(DynamicFormMessages.maxStringMsg, {
            fieldName: label,
            fieldMaxLength: field.constraints.maxLength
        })
    };
}

function createSuffixPatternRule(suffix: string, intlFn: any, label: string) {
    const suffixPattern = `.*${suffix}$`;

    return {
        pattern: new RegExp(suffixPattern),
        message: intlFn(DynamicFormMessages.slugSuffixMsg, {
            fieldName: label,
            suffix
        })
    };
}

function createPrefixPatternRule(prefix: string, intlFn: any, label: string) {
    const prefixPattern = `${prefix}.*`;
    return {
        pattern: new RegExp(prefixPattern as string),
        message: intlFn(DynamicFormMessages.prefixSuffixMsg, {
            fieldName: label,
            prefix
        })
    };
}

function createStringPatternRule(pattern: string | RegExp, intlFn: any, label: string): ValidationRule {
    return {
        pattern: typeof pattern === 'string' ? new RegExp(pattern as string) : pattern,
        message: intlFn(DynamicFormMessages.patternStringMsg, {
            fieldName: label
        })
    };
}

function createNumberRule(field: FieldNumber, intlFn: any, label: string): ValidationRule {
    return {
        type: 'number',
        message: intlFn(DynamicFormMessages.patternNumberMsg, {
            fieldName: label
        }),
    };
}

function createNumberMinRule(field: FieldNumber, intlFn: any, label: string): ValidationRule {
    return {
        type: 'number',
        min: field.constraints.min,
        message: intlFn(DynamicFormMessages.minNumberMsg, {
            fieldName: label,
            fieldMinLength: field.constraints.min
        })
    };
}

function createNumberMaxRule(field: FieldNumber, intlFn: any, label: string): ValidationRule {
    return {
        type: 'number',
        max: field.constraints.max,
        message: intlFn(DynamicFormMessages.maxNumberMsg, {
            fieldName: label,
            fieldMaxLength: field.constraints.max
        })
    };
}

function createBooleanRule(): ValidationRule {
    return {
        type: 'boolean'
    };
}

export function createDynamicFieldRules(field: SimpleFieldTypes, intlFn: any, locale: string): ValidationRule[] {

    const formItemRules: ValidationRule[] = [];
    const fieldLabel = field.labels[locale] || field.labels.en || '';
    if (field.constraints.required) {
        if (
            (field.type === SchemaTypeIds.TEXT || field.type === SchemaTypeIds.LONG_TEXT)
            && field.constraints.allowEmpty) {
            formItemRules.push(createCustomRequiredRule(field, intlFn, fieldLabel, invalidOnlyIfUndefinedValidator));
        } else if (field.type === SchemaTypeIds.CLOUDINARY_PUBLICID) {
            formItemRules.push(createCustomRequiredRule(field, intlFn, fieldLabel, invalidOnlyIfUndefinedValidator));
        } else {
            formItemRules.push(createRequiredRule(field, intlFn, fieldLabel));
        }
    }
    if (field.type === SchemaTypeIds.TEXT) {
        if (field.constraints.pattern) {
            formItemRules.push(createStringPatternRule(field.constraints.pattern, intlFn, fieldLabel));
        }
    }

    if (field.type === SchemaTypeIds.SLUG) {
        formItemRules.push(createStringPatternRule(field.pattern, intlFn, fieldLabel));
        if (field.constraints.suffix) {
            formItemRules.push(createSuffixPatternRule(field.constraints.suffix, intlFn, fieldLabel));
        }
        if (field.constraints.prefix) {
            formItemRules.push(createPrefixPatternRule(field.constraints.prefix, intlFn, fieldLabel));
        }
    }

    if (field.type === SchemaTypeIds.TEXT || field.type === SchemaTypeIds.LONG_TEXT) {
        if (!!field.constraints.minLength && Number.isFinite(field.constraints.minLength)) {
            formItemRules.push(createStringMinLengthRule(field, intlFn, fieldLabel));
        }
        if (!!field.constraints.maxLength && Number.isFinite(field.constraints.maxLength)) {
            formItemRules.push(createStringMaxLengthRule(field, intlFn, fieldLabel));
        }
    }
    if (field.type === SchemaTypeIds.NUMBER) {
        formItemRules.push(createNumberRule(field, intlFn, fieldLabel));
        if (!!field.constraints.min && Number.isFinite(field.constraints.min)) {
            formItemRules.push(createNumberMinRule(field, intlFn, fieldLabel));
        }
        if (!!field.constraints.max && Number.isFinite(field.constraints.max)) {
            formItemRules.push(createNumberMaxRule(field, intlFn, fieldLabel));
        }
    }
    if (field.type === SchemaTypeIds.BOOLEAN) {
        formItemRules.push(createBooleanRule());
    }
    return formItemRules;
}

export function createCommonFieldRules(field: CommonFieldTypes, intlFn: any): ValidationRule[] {
    const formItemRules: ValidationRule[] = [];
    const fieldLabel = intlFn(field.getLabelDescriptor());
    if (field.constraints.required) {
        formItemRules.push(createRequiredRule(field, intlFn, fieldLabel));
    }
    if (field instanceof (FieldName || FieldPublicId)) {
        if (!!field.constraints.minLength && Number.isFinite(field.constraints.minLength)) {
            formItemRules.push(createStringMinLengthRule(field, intlFn, fieldLabel));
        }
        if (!!field.constraints.maxLength && Number.isFinite(field.constraints.maxLength)) {
            formItemRules.push(createStringMaxLengthRule(field, intlFn, fieldLabel));
        }
        if (field.constraints.pattern) {
            formItemRules.push(createStringPatternRule(field.constraints.pattern, intlFn, fieldLabel));
        }
    }
    return formItemRules;
}
