import React, { Component } from 'react';
import { inject } from 'mobx-react';
import UserPermissionsStore from '../../stores/userPermissionsStore';
import { Redirect, RouteComponentProps } from 'react-router';
import { dashboardPath } from '../../constants/routing-constants';
import { NoSpacesFound } from '../';
import SpaceSelector from '../SpaceSelector/SpaceSelector';

export interface ChooseSpaceProps extends RouteComponentProps<{}> { }

export interface ChooseSpaceInjectedProps extends ChooseSpaceProps {
    userPermissionsStore: UserPermissionsStore;
}

@inject('userPermissionsStore')
class ChooseSpace extends Component<ChooseSpaceProps, {}> {

    get injected() {
        return this.props as ChooseSpaceInjectedProps;
    }

    render() {
        const { permissionsList } = this.injected.userPermissionsStore;

        if (permissionsList.length === 1) {
            return <Redirect to={dashboardPath(permissionsList[0].spaceId)} />;
        }

        if (permissionsList.length === 0) {
            return <NoSpacesFound />;
        }

        return (
            <SpaceSelector
                spaceList={permissionsList}
            />
        );
    }
}

export default ChooseSpace;
