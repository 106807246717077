import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import {
    ContentListByDefinitionsProps,
    ContentListByDefinitionsState,
    InjectedProps
} from './ContentListByDefinitionsModule';
import { ContentListUrlParams } from '../../stores/contentListStore/contentListStoreModel';
import { FilterByContentDefinitions, FilterModal } from '../';
import { complexStringToArrayOfIds } from '../../services/urlSearchParamsHelper';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

@inject('contentListStore', 'contentListFiltersStore')
class ContentListByDefinitions extends Component<ContentListByDefinitionsProps, ContentListByDefinitionsState> {

    state: ContentListByDefinitionsState = {
        isLoading: true,
        filtersToApply: []
    };

    get injected() {
        return this.props as InjectedProps;
    }

    componentDidMount = async () => {
        await this.injected.contentListFiltersStore.setContentDefinitionOptions();
        this.setState({ isLoading: false });
    }

    onChangeSelectedContentDefinition = (event: CheckboxChangeEvent) => {
        const { checked, id } = event.target;
        if (id) {
            if (checked) {
                this.setState({ filtersToApply: this.state.filtersToApply.concat(+id) });
            } else {
                this.setState({
                    filtersToApply: this.state.filtersToApply.filter(
                        definitionId => +id !== definitionId
                    )
                });
            }
        } else {
            console.error('Missing required attribute id in checkbox');
        }
    }

    onSearchDefinition = async (value: string) => {
        this.setState({ isLoading: true });
        await this.injected.contentListFiltersStore.setContentDefinitionOptions(value);
        this.setState({ isLoading: false });
    }

    onLoadMoreDefinitions = async () => {
        this.setState({ isLoading: true });
        await this.injected.contentListFiltersStore.setMoreContentDefinitionOptions();
        this.setState({ isLoading: false });
    }

    onConfirmAppliedFilters = () => {
        this.props.onApplyFilters(this.state.filtersToApply);
        this.setState({ filtersToApply: [] });
    }

    render() {
        const { contentListStore: { searchParams: { usableSearchParams } } } = this.injected;
        const { contentDefinitionListOptions: { total, listOptions } } = this.injected.contentListFiltersStore;
        return (
            <FilterModal
                linkLabel={<FormattedMessage
                    id={'ContentListByDefinitions.CTALabel'}
                    defaultMessage={'Filter by definitions'}
                />}
                onModalOkCallback={this.onConfirmAppliedFilters}
            >
                <FilterByContentDefinitions
                    isLoading={this.state.isLoading}
                    listDefinitions={listOptions}
                    showMore={total > listOptions.length}
                    defaultValue={(
                        usableSearchParams[ContentListUrlParams.definitionId]
                        && complexStringToArrayOfIds(
                            usableSearchParams[ContentListUrlParams.definitionId]
                        )
                    ) || undefined}
                    onSearch={this.onSearchDefinition}
                    onChange={this.onChangeSelectedContentDefinition}
                    onLoadMore={this.onLoadMoreDefinitions}
                />
            </FilterModal>
        );
    }
}

export default ContentListByDefinitions;
