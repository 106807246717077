import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { PublishingChannelForm, Aside, AsideItem, FormActions, ContainerWithAside } from '../../../components';
import { WithLoadingData, withChannelUpdateStores, withFormInitialization } from '../../../hoc';
import { NOTIFICATION_KEY_CONSTANTS, NOTIFICATION_DEFAULT_MESSAGES } from '../../../constants/notifications-constants';
import {
    createInfoNotification,
    createErrorNotification,
    createSuccessNotification
} from '../../../services/Notification';
import { DataAttributesBuilder } from '../../../services/DataAttributesBuilder';
import { inject, observer } from 'mobx-react';
import { UpdatePublishingChannelProps, InjectedProps } from './UpdatePublishingChannelModel';
import { UpdatePublishingChannelRequest } from '@contentchef/contentchef-types';
import { userPermissionsTable } from '../../../constants/user-permissions-table';
import { ManageFieldProvider } from '../../../providers/UserPermissionsProvider';
import { publishingChannelNotFound } from '../../../constants/routing-constants';
import { Redirect } from 'react-router';
import { Modal } from 'antd';
import styles from './index.module.scss';

const labels = defineMessages({
    warningModalTitle: {
        id: 'UpdatePublishingChannel.warningModal.title',
        defaultMessage: '{action} channel?'
    },
    archiveAction: {
        id: 'UpdatePublishingChannel.action.archive',
        defaultMessage: 'archive'
    },
    unarchiveAction: {
        id: 'UpdatePublishingChannel.action.unarchive',
        defaultMessage: 'unarchive'
    },
    warningModalContent: {
        id: 'UpdatePublishingChannel.warningModal.content',
        defaultMessage: 'Are you sure to {action} this channel?'
    },
    warningModalCancel: {
        id: 'UpdatePublishingChannel.warningModal.cancel',
        defaultMessage: 'cancel'
    },
    warningModalOk: {
        id: 'UpdatePublishingChannel.warningModal.ok',
        defaultMessage: 'confirm'
    }
});

@inject('channelsStore', 'notificationStore')
@observer
class UpdatePublishingChannel extends Component<UpdatePublishingChannelProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    openWarningModal = (shouldArchive: boolean, method: () => Promise<void>) => {
        const { formatMessage } = this.props.intl;
        const action = shouldArchive ? formatMessage(labels.archiveAction) : formatMessage(labels.unarchiveAction);
        Modal.confirm({
            okText: formatMessage(labels.warningModalOk),
            cancelText: formatMessage(labels.warningModalCancel),
            okButtonProps: {
                className: styles.UpdatePublishingChannelCapitalize
            },
            cancelButtonProps: {
                className: styles.UpdatePublishingChannelCapitalize
            },
            title: (
                <p className={styles.UpdatePublishingChannelFirstLetterUpperCase}>
                    {formatMessage(labels.warningModalTitle, { action })}
                </p>
            ),
            content: formatMessage(labels.warningModalContent, { action }),
            onOk: method
        });
    }

    setPublishingChannel = () => {
        const { publishingChannelId } = this.props.match.params;
        return this.injected.channelsStore.setChannel(+publishingChannelId);
    }

    componentWillUnmount() {
        const { notificationStore } = this.injected;
        const { notifications } = notificationStore;
        if (notifications.includes(NOTIFICATION_KEY_CONSTANTS.PUBLISHING_CHANNEL_EDIT_REQUEST)) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.PUBLISHING_CHANNEL_EDIT_REQUEST);
        }
    }

    createDataAttributes = () => {
        return new DataAttributesBuilder('publishing-channels')
            .setIdAttribute('updatePublishingChannel')
            .setAddFormAttribute('publishingChannelUpdate')
            .build();
    }

    onSubmitForm = async (channel: UpdatePublishingChannelRequest) => {
        const { formatMessage } = this.props.intl;
        const { notificationStore } = this.injected;
        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.PUBLISHING_CHANNEL_EDIT_REQUEST,
            formatMessage,
            NOTIFICATION_DEFAULT_MESSAGES.updateAction
        );
        try {
            await this.injected.channelsStore.updateChannel(channel);
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.PUBLISHING_CHANNEL_EDIT_REQUEST);
            createSuccessNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.updateAction
            );
        } catch (e) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.PUBLISHING_CHANNEL_EDIT_REQUEST);
            createErrorNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.updateAction
            );
        }
    }

    toggleArchive = (shouldArchive: boolean) => async () => {
        const { formatMessage } = this.props.intl;
        const { notificationStore } = this.injected;
        const action = shouldArchive ?
            NOTIFICATION_DEFAULT_MESSAGES.archiveAction : NOTIFICATION_DEFAULT_MESSAGES.unarchiveAction;
        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.PUBLISHING_CHANNEL_TOGGLE_ARCHIVE,
            this.props.intl.formatMessage,
            action
        );
        try {
            await this.injected.channelsStore.toggleArchived(shouldArchive);
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.PUBLISHING_CHANNEL_TOGGLE_ARCHIVE);
            createSuccessNotification(
                formatMessage,
                action
            );
        } catch (error) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.PUBLISHING_CHANNEL_TOGGLE_ARCHIVE);
            createErrorNotification(
                formatMessage,
                action
            );
        }
    }

    onArchive = () => {
        this.openWarningModal(true, this.toggleArchive(true));
    }

    onUnarchive = () => {
        this.openWarningModal(false, this.toggleArchive(false));
    }

    renderWithLoadingChildren = () => {
        const { channel } = this.injected.channelsStore;
        const { notifications } = this.injected.notificationStore;
        return (
            !!channel
                ? (
                    <ContainerWithAside
                        dataAttributes={this.createDataAttributes().dataAttributes}
                        disableContent={notifications.includes(
                            NOTIFICATION_KEY_CONSTANTS.PUBLISHING_CHANNEL_EDIT_REQUEST
                        )}
                        renderAside={() => (
                            <Aside>
                                <AsideItem>
                                    <FormActions
                                        onSubmit={this.onSubmitForm}
                                        permissions={userPermissionsTable.FormActions.channel}
                                        onArchive={channel.archived
                                            ? undefined
                                            : this.onArchive
                                        }
                                        onUnarchive={channel.archived
                                            ? this.onUnarchive
                                            : undefined
                                        }
                                    />
                                </AsideItem>
                            </Aside>
                        )}
                    >
                        <ManageFieldProvider permissions={userPermissionsTable.Forms.UpdatePublishingChannelForm}>
                            <PublishingChannelForm
                                mnemonicIdDisabled={true}
                                publishingChannel={channel}
                            />
                        </ManageFieldProvider>
                    </ContainerWithAside>
                ) : (
                    <Redirect to={publishingChannelNotFound(this.props.match.params.spaceId)} />
                )
        );
    }

    render() {
        return (
            <WithLoadingData
                promise={this.setPublishingChannel}
                children={this.renderWithLoadingChildren()}
            />
        );
    }
}

export default withChannelUpdateStores(withFormInitialization(injectIntl(UpdatePublishingChannel)));
