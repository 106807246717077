import Button from '@components/Button';
import Entry from '@components/Entry';
import Paper from '@components/Paper';
import SettingsContentHeader from '@components/SettingsContentHeader';
import withEmailVerificationStores from '@hoc/withEmailVerificationStores/withEmailVerificationStores';
import { withUserInfo } from '@hoc/withUserInfo/withUserInfo';
import developerMode, { setDeveloperMode } from '@services/devMode';
import { UserInfo } from '@services/Users';
import { Icon, Skeleton, Switch, Tooltip } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { notificationStore, UserManagementStore } from '../../../stores';
import classes from './index.module.scss';

interface ProfilePageProps {
    userInfo?: UserInfo;
    refreshUserInfo?(): void;
}

interface ProfilePageState {
    temporaryDisabledVerification: boolean;
    developerModeActive: boolean;
}

interface InjectedProps {
    userManagementStore: UserManagementStore;
}

@inject('userManagementStore')
@observer
class ProfilePage extends Component<ProfilePageProps, ProfilePageState> {
    get injected() {
        return this.props as InjectedProps;
    }

    verificationDisabledTimeout: number | null = null;

    state: ProfilePageState = {
        temporaryDisabledVerification: false,
        developerModeActive: developerMode()
    };

    componentWillUnmount() {
        if(this.verificationDisabledTimeout) {
            window.clearTimeout(this.verificationDisabledTimeout);
        }
    }

    sendEmailVerification = async () => {
        await this.injected.userManagementStore.requestEmailVerification();
        if (this.injected.userManagementStore.requestVerificationSuccess) {
            await notificationStore.openNotification({
                message: (
                    <FormattedMessage
                        id="ProfilePage.emailVerificationNotification.success"
                        defaultMessage="A verification link will be sent to the provided email."
                    />
                ),
                type: 'success'
            });
            this.setState({
                temporaryDisabledVerification: true
            });
            this.verificationDisabledTimeout = window.setTimeout(
                () => {
                    this.setState({
                        temporaryDisabledVerification: false
                    });
                },
                5000
            );
        } else {
            await notificationStore.openNotification({
                message: (
                    <FormattedMessage
                        id="ProfilePage.emailVerificationNotification.error"
                        defaultMessage="We couldn't request a verification email. Try in a few minutes."
                    />
                ),
                type: 'error'
            });
        }
    }

    renderVerificationButton = (userInfo: UserInfo) => {
        return !userInfo.email_verified ? (
            <Tooltip
                key="email_not_verified"
                title={
                    <FormattedMessage
                        id="ProfilePage.emailVerificationButton.verifyEmailTooltip"
                        defaultMessage="Verify your email"
                    />
                }
            >
                <Button
                    icon="warning"
                    loading={this.injected.userManagementStore.loaders.requestingVerification}
                    disabled={this.state.temporaryDisabledVerification}
                    onClick={this.sendEmailVerification}
                    className={classes.EmailNotVerifiedButton}
                />
            </Tooltip>
        ) : null;
    }

    renderActions = (userInfo: UserInfo) => {
        return [
            this.renderVerificationButton(userInfo)
        ].filter(action => action !== null);
    }

    onChangeDeveloperMode = (checked: boolean) => {
        this.setState({
            developerModeActive: checked
        });
        setDeveloperMode(checked);
    }

    render() {

        const { userInfo } = this.props;

        return (
            <Fragment>
                <SettingsContentHeader
                    title={
                        <FormattedMessage
                            defaultMessage="Profile"
                            id="scenes.Profile.ProfilePage.title"
                        />
                    }
                />
                <Paper gutter={true}>
                    <Skeleton loading={!userInfo} active={true}>
                        {
                            userInfo && (
                                <React.Fragment>
                                    <Entry
                                        label={
                                            <FormattedMessage
                                                id="ProfilePage.labels.name"
                                                defaultMessage="name"
                                            />
                                        }
                                        value={userInfo.name}
                                    />
                                    <Entry
                                        label={
                                            <FormattedMessage
                                                id="ProfilePage.labels.email"
                                                defaultMessage="email"
                                            />
                                        }
                                        value={userInfo.email}
                                        actions={this.renderActions(userInfo)}
                                    />
                                </React.Fragment>
                            )
                        }
                    </Skeleton>
                </Paper>
                <SettingsContentHeader
                    gutter={true}
                    title={
                        <FormattedMessage
                            defaultMessage="Danger zone"
                            id="scenes.Profile.ProfilePage.dangerZone"
                        />
                    }
                />

                <Paper gutter={true}>
                    <Entry
                        label={
                            <FormattedMessage
                                id="ProfilePage.labels.devMode"
                                defaultMessage="Expert Mode"
                            />
                        }
                        labelIcon={
                            <Tooltip
                                title={(
                                    <FormattedMessage 
                                        id='ProfilePage.devModeTooltip'
                                        defaultMessage='This mode allows you to delete any media and modify definitions using JSON.'
                                    />
                                )}
                            >
                                <Icon type='info-circle' />
                            </Tooltip>
                        }
                        value={
                            <Switch
                                onChange={this.onChangeDeveloperMode}
                                defaultChecked={this.state.developerModeActive}
                            />}
                    />
                </Paper>
            </Fragment>
        );
    }
}

export default withUserInfo(withEmailVerificationStores(ProfilePage));
