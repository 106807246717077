import React from 'react';
import Required from '../Constraints/RequiredField';
import { observer } from 'mobx-react';
import { FieldEditTabProps } from '../../types';
import { TextConstraints } from '../../FieldSerializer/fields/text';
import { requiredMappedErrors } from '../../../../services/FormUtils/DefinitionFieldMappedErrors';

function ListOfTextValuesConstraints(props: FieldEditTabProps<TextConstraints>) {
    if (props.isArrayChildren) {
        return null;
    }
    return (
        <Required
            relatedTabKey={'validationTab'}
            mappedErrors={requiredMappedErrors}
            value={props.constraints.required}
            onChange={required => props.onChangeConstraint('required', required)}
        />
    );
}
export default observer(ListOfTextValuesConstraints);
