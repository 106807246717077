import {
    BooleanSchemaType,
    Field,
    SchemaTypeIds
} from '@contentchef/contentchef-types';

function simpleBoolean(defaultLanguage: string, name: string): Field<BooleanSchemaType> {
    return {
        name,
        type: SchemaTypeIds.BOOLEAN,
        enableFacet: undefined,
        labels: {
            [defaultLanguage]: name
        },
        placeholder: undefined,
        hint: undefined,
        constraints: {
            required: false
        },
        compact: undefined
    };
}

function simpleBooleanInArray(): BooleanSchemaType {
    return {
        type: SchemaTypeIds.BOOLEAN,
        enableFacet: undefined,
        constraints: {}
    };
}

export const booleanSnippets = {
    asField: {
        simpleBoolean
    },
    asArray: {
        simpleBooleanInArray
    }
};
