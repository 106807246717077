import classNames from 'classnames';
import React from 'react';
import classes from './index.module.scss';

export type Align = 'left' | 'center' | 'right';

export type Variant = 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'label'
    | 'paragraph'
    | 'small'
    | 'span'
    ;

export interface TypographyProps extends Partial<React.ComponentPropsWithRef<'div'>> {
    align?: Align;
    component?: string;
    gutter?: boolean;
    underline?: boolean;
    variant?: Variant;
}

function getComponent(variant: Variant): string {
    switch (variant) {
        case 'caption':
            return 'div';
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
        case 'h6':
        case 'label':
        case 'span':
            return variant;
        case 'paragraph':
            return 'p';
        case 'small':
            return 'small';
        default:
            return 'div';
    }
}

export default function Typography(props: TypographyProps) {
    const {
        align,
        component,
        className,
        gutter,
        variant,
        underline,
        ...componentProps
    } = props;
    const componentType = component || getComponent(variant!);

    return React.createElement(componentType, {
        className: classNames(
            {
                [classes.H1]: variant === 'h1',
                [classes.H2]: variant === 'h2',
                [classes.H3]: variant === 'h3',
                [classes.H4]: variant === 'h4',
                [classes.H5]: variant === 'h5',
                [classes.H6]: variant === 'h6',
                [classes.Paragraph]: variant === 'paragraph' || variant === 'span',
                [classes.Quote]: variant === 'caption',
                [classes.Label]: variant === 'label',
                [classes.Small]: variant === 'small',
                [classes.NoMargin]: !gutter,
                [classes.Center]: align === 'center',
                [classes.Left]: align === 'left',
                [classes.Right]: align === 'right',
                [classes.Underline]: underline
            },
            className
        ),
        ...componentProps,
    });
}

Typography.defaultProps = {
    align: 'left',
    gutter: true,
    underline: false,
    variant: 'paragraph',
} as Partial<TypographyProps>;

export function TypographyWithIcon(props: TypographyProps & { icon: React.ReactNode; }) {
    const { icon, children, ...rest } = props;
    return (
        <Typography {...rest}>
            {children}
            <span style={{ marginLeft: 5 }}>
                {icon}
            </span>
        </Typography>
    );
}
