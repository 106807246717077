import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import { CreateExtensionRequest, Extension } from '@contentchef/contentchef-types';
import { action, IObservableArray, observable, runInAction } from 'mobx';

export type UpdateExtensionParams = {
    name: string;
    extensionURL: string;
    supportedTypes: string[];
};

export default class ExtensionAPIStore {
    @observable extensions: IObservableArray<Extension> = observable.array([]);
    @observable extension: Extension | null = null;

    @observable loading: { list: boolean; get: boolean; save: boolean; } = {
        get: false,
        list: false,
        save: false
    };

    constructor(readonly api: ContentChefClient) { }

    @action.bound
    async setExtensions() {
        this.loading.list = true;
        try {
            const response = await this.api.extension.list();
            runInAction(() => {
                this.extensions.replace(response);
                this.loading.list = false;
            });
            return response;
        } catch (error) {
            console.error(error);
            runInAction(() => {
                this.loading.list = false;
            });
            return Promise.reject(error);
        }
    }

    @action.bound
    async setExtension(id: string) {
        this.loading.get = true;
        try {
            const response = await this.api.extension.get({ id });
            runInAction(() => {
                this.extension = response;
                this.loading.list = false;
            });
            return response;
        } catch (error) {
            console.error(error);
            runInAction(() => {
                this.loading.list = false;
            });
            return Promise.reject(error);
        }
    }

    @action.bound
    async updateExtension(request: UpdateExtensionParams) {
        this.loading.save = true;
        try {
            const response = await this.api.extension.update({ id: this.extension!.id, ...request });
            runInAction(() => {
                this.extension = {
                    ...this.extension,
                    ...response
                };
                this.loading.save = false;
            });
            return response;
        } catch (error) {
            console.error(error);
            runInAction(() => {
                this.loading.save = false;
            });
            return Promise.reject(error);
        }
    }

    async saveExtension(request: CreateExtensionRequest) {
        this.loading.save = true;
        try {
            const response = await this.api.extension.save(request);
            runInAction(() => {
                this.loading.save = false;
            });
            return response;
        } catch (error) {
            console.error(error);
            runInAction(() => {
                this.loading.save = false;
            });
            return Promise.reject(error);
        }
    }

    validateMnemonicId = async (mnemonicId: string) => {
        try {
            const response = await this.api.extension.list();
            return !response.find(extension => {
                return extension.mnemonicId === `local:${mnemonicId}`;
            });
        } catch (error) {
            return false;
        }

    }

}
