import React from 'react';
import RequiredField from '../Constraints/RequiredField';
import { FieldEditTabProps } from '../../types';
import { observer } from 'mobx-react';
import { CloudinaryImageConstraints } from '../../FieldSerializer/fields/cloudinary-image';
import { requiredMappedErrors } from '../../../../services/FormUtils/DefinitionFieldMappedErrors';

function TransformableImageConstraints(props: FieldEditTabProps<CloudinaryImageConstraints>) {
    if (props.isArrayChildren) {
        return null;
    }
    return (
        <RequiredField
            relatedTabKey={'validationTab'}
            mappedErrors={requiredMappedErrors}
            onChange={required => props.onChangeConstraint('required', required)}
            value={props.constraints.required}
        />
    );
}

export default observer(TransformableImageConstraints);
