import { defineMessages } from 'react-intl';

export const labels = defineMessages({
    spacesPerPlan: {
        defaultMessage: 'Project spaces',
        id: 'subscription.detail.plan.projectSpaces',
    },
    planCostPerMonth: {
        defaultMessage: '{currency}/month',
        id: 'subscription.detail.plan.perMonth',
    },
    planCostPerYear: {
        defaultMessage: '{currency}/year',
        id: 'subscription.detail.plan.perYear',
    },
    StarterTier: {
        defaultMessage: 'Starter',
        id: 'subscription.plan.type.starter',
    },
    AgencyTier: {
        defaultMessage: 'Agency',
        id: 'subscription.plan.type.agency',
    },
    ProfessionalTier: {
        defaultMessage: 'Professional',
        id: 'subscription.plan.type.professional',
    },
    EnterpriseTier: {
        defaultMessage: 'Enterprise',
        id: 'subscription.plan.type.enterprise',
    },
    TrialTier: {
        defaultMessage: 'Trial',
        id: 'subscription.plan.type.trial',
    },
    upgrade: {
        defaultMessage: 'Upgrade plan',
        id: 'subscription.detail.plan.upgradePlan',
    },
    upgradeModalText: {
        defaultMessage: 'You are going to upgrade this subscription, this is a permanent change',
        id: 'subscription.plan.modal.text',
    },
    upgradeModalTitle: {
        defaultMessage: 'Upgrading subscription',
        id: 'subscription.plan.modal.title',
    },
    upgradeConfirmViewActionConfirm: {
        defaultMessage: 'Upgrade now',
        id: 'subscription.plan.modal.confirm.actions.confirm',
    },
    upgradeConfirmViewActionEnterpriseConfirm: {
        defaultMessage: 'Contact us',
        id: 'subscription.plan.modal.confirm.actions.contactUs',
    },
    upgradeConfirmViewActionUndo: {
        defaultMessage: 'Go back',
        id: 'subscription.plan.modal.confirm.actions.undo',
    },
    upgradeConfirmViewText1: {
        defaultMessage: 'You are going to change your actual plan',
        id: 'subscription.plan.modal.confirm.text1',
    },
    upgradeConfirmViewText2: {
        defaultMessage: 'This action is permanent, but a lot of benefits will come with it',
        id: 'subscription.plan.modal.confirm.text2',
    },
    upgradeConfirmViewEnterpriseText1: {
        defaultMessage: 'If you want to upgrade to this subscription you should give us a call',
        id: 'subscription.plan.modal.confirm.enterprise.text1',
    },
    upgradeConfirmViewEnterpriseText2: {
        defaultMessage: 'Click the button below, we will contact you as soon as possible',
        id: 'subscription.plan.modal.confirm.enterprise.text2',
    },
    enterpriseContactUsLabel: {
        defaultMessage: 'Contact us',
        id: 'subscription.plan.pricing.enterprise'
    },
    enterpriseUnlimitedLabel: {
        defaultMessage: 'Unlimited',
        id: 'subscription.plan.enterprise.spaces.unlimited'
    },
    monthlyLabel: {
        defaultMessage: 'Monthly',
        id: 'subscription.plan.interval.monthly'
    },
    yearlyLabel: {
        defaultMessage: 'Yearly',
        id: 'subscription.plan.interval.yearly'
    },
    usersBenefit: {
        id: 'subscription.plan.benefits.users',
        defaultMessage: '{value} users'
    },
    spacesBenefit: {
        id: 'subscription.plan.benefits.spaces',
        defaultMessage: '{value} spaces'
    },
    mediaBenefit: {
        id: 'subscription.plan.benefits.media',
        defaultMessage: '{value} GB media'
    }
});

export const freelanceTierLabels = defineMessages({
    name: {
        id: 'subscription.plan.freelance.labels.name',
        defaultMessage: 'Freelance'
    },
    description: {
        id: 'subscription.plan.freelance.labels.description',
        defaultMessage: 'The freelance plan is perfect for small projects developed by a solo developer.'
    },
    feat1: {
        id: 'subscription.plan.freelance.labels.feat1',
        defaultMessage: 'Frontend Stack Freedom'
    },
    feat2: {
        id: 'subscription.plan.freelance.labels.feat2',
        defaultMessage: 'Drop-in SDK for instant productivity'
    },
    feat3: {
        id: 'subscription.plan.freelance.labels.feat3',
        defaultMessage: 'Fast API prototyping'
    },
});

export const teamTierLabels = defineMessages({
    name: {
        id: 'subscription.plan.team.labels.name',
        defaultMessage: 'Team'
    },
    description: {
        id: 'subscription.plan.team.labels.description',
        defaultMessage: 'The Team is a perfect plan for small teams collaborating on a few projects.'
    },
    feat1: {
        id: 'subscription.plan.team.labels.feat1',
        defaultMessage: 'Create, manage and publish all of your content from a central hub.'
    },
    feat2: {
        id: 'subscription.plan.team.labels.feat2',
        defaultMessage: 'Flexible Content Schemas'
    },
    feat3: {
        id: 'subscription.plan.team.labels.feat3',
        defaultMessage: 'WebHooks for easy integrations'
    },
});

export const agencyTierLabels = defineMessages({
    name: {
        id: 'subscription.plan.agency.labels.name',
        defaultMessage: 'Agency'
    },
    description: {
        id: 'subscription.plan.agency.labels.description',
        defaultMessage: 'The Agency plan is designed for many teams collaborating on complex projects.'
    },
    feat1: {
        id: 'subscription.plan.agency.labels.feat1',
        defaultMessage: 'Unlimited collaboration'
    },
    feat2: {
        id: 'subscription.plan.agency.labels.feat2',
        defaultMessage: 'Content staging for preview & publishing schedule'
    },
    feat3: {
        id: 'subscription.plan.agency.labels.feat3',
        defaultMessage: 'Media optimization'
    },
});
