import React, { Component } from 'react';
import { ContentListByTagsProps, ContentListByTagsState, InjectedProps } from './ContentListByTagsModel';
import { inject } from 'mobx-react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FilterContentsByTags, FilterModal } from '../index';
import { FormattedMessage } from 'react-intl';
import { ContentListUrlParams } from '../../stores/contentListStore/contentListStoreModel';
import { stringToArrayOfString } from '../../services/urlSearchParamsHelper';

@inject('contentListStore', 'contentListFiltersStore')
class ContentListByTags extends Component<ContentListByTagsProps, ContentListByTagsState> {

    state: ContentListByTagsState = {
        isLoading: true,
        filtersToApply: []
    };

    get injected() {
        return this.props as InjectedProps;
    }

    componentDidMount = async () => {
        await this.injected.contentListFiltersStore.setSuggestedTags();
        this.setState({ isLoading: false });
    }

    onChangeSelectedTag = (event: CheckboxChangeEvent) => {
        const { checked, id } = event.target;
        if (id) {
            if (checked) {
                this.setState({ filtersToApply: this.state.filtersToApply.concat(id) });
            } else {
                this.setState({
                    filtersToApply: this.state.filtersToApply.filter(
                        appliedTag => id !== appliedTag
                    )
                });
            }
        } else {
            console.error('Missing required attribute id in checkbox');
        }
    }

    onConfirmAppliedFilters = () => {
        this.props.onApplyFilters(this.state.filtersToApply);
        this.setState({ filtersToApply: [] });
    }

    onSearchPreferredTags = async (value: string) => {
        this.setState({ isLoading: true });
        await this.injected.contentListFiltersStore.setSuggestedTags(value);
        this.setState({ isLoading: false });
    }

    render() {
        const { contentListStore: { searchParams: { usableSearchParams } } } = this.injected;
        const { suggestedTags } = this.injected.contentListFiltersStore;
        return (
            <FilterModal
                linkLabel={<FormattedMessage
                    id={'ContentListByTags.CTALabel'}
                    defaultMessage={'Filter by tags'}
                />}
                onModalOkCallback={this.onConfirmAppliedFilters}
            >
                <FilterContentsByTags
                    isLoading={this.state.isLoading}
                    listTags={suggestedTags}
                    defaultValue={(
                        usableSearchParams[ContentListUrlParams.tags]
                        && stringToArrayOfString(usableSearchParams[ContentListUrlParams.tags])
                    ) || undefined}
                    onChange={this.onChangeSelectedTag}
                    onSearch={this.onSearchPreferredTags}
                />
            </FilterModal>
        );
    }
}

export default ContentListByTags;
