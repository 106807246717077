import { Icon, List } from 'antd';
import React, { Component } from 'react';
import styles from './AppliedFilterListItem.module.scss';
import { AppliedFilterListItemProps, AppliedFIlterListItemState } from './AppliedFilterListItemModule';

const ListItem = List.Item;
const ListItemMeta = ListItem.Meta;

class AppliedFilterListItem extends Component<AppliedFilterListItemProps, AppliedFIlterListItemState> {

    onRemoveItem = () => {
        const { itemName, itemId, filterType, onRemoveFilterCallback } = this.props;
        onRemoveFilterCallback(filterType, itemName ? itemId.concat('|', itemName) : itemId);
    }

    render() {
        const { itemName, itemId, filterType } = this.props;
        return (
            <ListItem
                actions={
                    [<Icon
                        className={styles.AppliedFilterListItemRemoveIcon}
                        key={`remove_${filterType}_filter-${itemId}`}
                        type="delete"
                        onClick={this.onRemoveItem}
                    />]
                }
            >
                <ListItemMeta
                    avatar={<Icon
                        className={styles.AppliedFilterListItemFilterTypeIcon}
                        type="book"
                    />}
                    title={itemName ? itemName : itemId}
                />
            </ListItem>
        );
    }
}

export default AppliedFilterListItem;
