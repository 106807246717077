import React, { Component, createRef } from 'react';
import { Icon, Badge } from 'antd';
import classes from './index.module.scss';
import { inject, observer } from 'mobx-react';
import { NotificationHubStore } from '../../stores/notificationHubStore';
import NotificationHub from '../NotificationHub';
import { InjectedIntlProps, injectIntl, defineMessages } from 'react-intl';

interface NotificationHubProps extends InjectedIntlProps { }

interface InjectedProps extends NotificationHubProps {
    notificationHubStore: NotificationHubStore;
}

const labels = defineMessages({
    buttonTitle: { id: 'notificationhub.button.title', defaultMessage: 'Notifications' }
});

@inject('notificationHubStore')
@observer
class NotificationHubTrigger extends Component<NotificationHubProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    private notificationHubIconRef: React.RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

    onClick = () => {
        this.injected.notificationHubStore.toggleHub();
    }

    render() {
        const { intl: { formatMessage } } = this.props;
        return (
            <React.Fragment>
                <div ref={this.notificationHubIconRef} className={classes.NotificationHubTrigger}>
                    <Badge count={this.injected.notificationHubStore.notifications.length}>
                        <Icon
                            type="bell"
                            className={classes.NotificationIcon}
                            onClick={this.onClick}
                            title={formatMessage(labels.buttonTitle)}
                        />
                    </Badge>
                </div>
                {this.injected.notificationHubStore.open && (
                    <NotificationHub triggerRef={this.notificationHubIconRef} />
                )}
            </React.Fragment>
        );
    }
}

export default injectIntl(NotificationHubTrigger);
