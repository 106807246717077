// tslint:disable:max-line-length
import React from 'react';
import FieldTypeIcon from '../../../components/atoms/FieldTypeIcon';

export const Fragment = () => (
    <svg data-icon="font-size" width="1em" height="1em" stroke="currentColor" fill="currentColor" aria-hidden="true" version="1.1" x="0px" y="0px" viewBox="0 0 373 341" >
        <g id="Group_961" data-name="Group 961" transform="translate(-3715 -16342)">
            <g id="Group_952" data-name="Group 952" transform="translate(-92 192)">
                <g id="Rectangle_2167" data-name="Rectangle 2167" transform="translate(3807 16150)" fill="none" stroke-width="15">
                    <rect width="167" height="105" rx="3" stroke="none" />
                    <rect x="2" y="2" width="163" height="101" rx="1" fill="none" />
                </g>
            </g>
            <g id="Rectangle_2174" data-name="Rectangle 2174" transform="translate(3715 16578)" fill="none" stroke-width="15">
                <rect width="193" height="105" rx="3" stroke="none" />
                <rect x="2" y="2" width="189" height="101" rx="1" fill="none" />
            </g>
            <g id="Rectangle_2175" data-name="Rectangle 2175" transform="translate(3921 16578)" fill="none" stroke-width="15">
                <rect width="167" height="105" rx="3" stroke="none" />
                <rect x="2" y="2" width="163" height="101" rx="1" fill="none" />
            </g>
            <g id="Group_951" data-name="Group 951" transform="translate(-48 185)">
                <g id="Rectangle_2169" data-name="Rectangle 2169" transform="translate(3763 16275)" fill="none" stroke-width="15">
                    <rect width="286" height="105" rx="3" stroke="none" />
                    <rect x="2" y="2" width="282" height="101" rx="1" fill="none" />
                </g>
            </g>
        </g>
    </svg>
);

export const FragmentFieldTypeIcon: React.FunctionComponent<{ title: string }> = (props) => (<FieldTypeIcon title={props.title} icon="build" />);
