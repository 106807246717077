import { MediaType } from '@contentchef/contentchef-types';
import { FieldCloudinaryImage, FieldCloudinaryVideo } from '../../services/FormUtils/FormFields';
import { MediaGalleryFlowStoreModel } from '../../stores/mediaGalleryFlowStore/mediaGalleryFlowStoreModel';

export enum MediaStep {
    MediaGallery = 0,
    Folder,
    Upload,
    MediaPreview
}

export interface MediaStepperProps {
    field?: FieldCloudinaryImage | FieldCloudinaryVideo;
    type: MediaType;
    onConfirmSelection(publicId: string, transformations: string, mediaUrl?: string): void;
}

export interface InjectedProps extends MediaStepperProps {
    mediaGalleryFlowStore: MediaGalleryFlowStoreModel;
}

export interface MediaStepperState {
    activeStep: MediaStep;
    temporaryPublicId: string;
    temporaryTransformations: string;
    hasTransformationError: boolean;
    mediaUrl?: string;
}
