import { Interval, PaymentIntentStatus } from '@contentchef/contentchef-types';
import { ReactStripeElements } from 'react-stripe-elements';
import { PaymentStore } from '../../stores';

export abstract class PaymentFlow {
    protected token: stripe.Token | null = null;
    constructor(
        protected stripe: ReactStripeElements.StripeProps,
        protected paymentStore: PaymentStore
    ) { }

    async tokenize(
        fields: ReactStripeElements.TokenOptions | undefined
    ) {
        try {
            const tokenResponse = await this.stripe.createToken({
                type: 'card',
                ...fields
            });
            if (tokenResponse.error) {
                return Promise.reject(tokenResponse.error);
            }
            this.token = tokenResponse.token!;
            return tokenResponse;
        } catch (error) {
            console.log(error);
            return Promise.reject(error);
        }
    }

    async handlePaymentError(
        error: any,
        onActionRequiredHooks: {
            onStart?: Function,
            onEnd?: Function,
            onActionError?: Function
        },
        onRequiresPaymentMethodHooks: {
            onStart?: Function,
            onEnd?: Function
        },
        onDefaultError: Function
    ) {
        if (!error.details) {
            onDefaultError();
            return;
        }
        const { details } = error;
        switch (details.paymentIntentStatus) {
            case PaymentIntentStatus.REQUIRES_ACTION:
                const { paymentIntentSecret } = details;
                const {
                    onStart: onStartActionRequired,
                    onEnd: onEndActionRequired,
                    onActionError
                } = onActionRequiredHooks;
                if (onStartActionRequired) {
                    await onStartActionRequired();
                }
                const { error: intentError } =
                    await this.stripe.handleCardPayment(paymentIntentSecret);
                if (intentError && onActionError) {
                    await onActionError();
                } else {
                    if (onEndActionRequired) {
                        await onEndActionRequired();
                    }
                }
                break;
            case PaymentIntentStatus.REQUIRES_PAYMENT_METHOD:
                const { onStart: onStartRequirePaymentMethod, onEnd: onEndRequirePaymentMethod } =
                    onRequiresPaymentMethodHooks;
                if (onStartRequirePaymentMethod) {
                    await onStartRequirePaymentMethod();
                }

                if (onEndRequirePaymentMethod) {
                    await onEndRequirePaymentMethod();
                }
                break;
            default:
                onDefaultError();
                break;
        }
    }

    abstract pay(
        subscriptionId: string,
        planId: string,
        interval: Interval,
        token?: stripe.Token
    ): Promise<boolean>;

}
