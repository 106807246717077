import React from 'react';
import { Col, Divider, Row } from 'antd';
import Typography, { Variant } from '../Typography';

type SegmentProps = {
    divider: boolean;
    title?: React.ReactNode;
    subTitle?: React.ReactNode;
    titleVariant: Variant;
    children: React.ReactNode;
    offset?: boolean;
};

const renderTitle = (title: React.ReactNode, titleVariant: Variant) => (
    <Typography variant={titleVariant}>
        {title}
    </Typography>
);

export default function Segment(props: SegmentProps) {
    const childrenColSize = props.offset ? { xs: 22, offset: 1 } : { xs: 24 };
    return (
        <Row type="flex">
            {props.title && <Col xs={24}>
                {props.divider
                    ? <Divider orientation="left">
                        {renderTitle(props.title, props.titleVariant)}
                    </Divider>
                    : renderTitle(props.title, props.titleVariant)
                }
            </Col>}
            {!!props.subTitle && <Col {...childrenColSize}>
                <Typography variant="caption" style={{ fontSize: '1em' }}>
                    {props.subTitle}
                </Typography>
            </Col>}
            <Col {...childrenColSize} >
                <Row type="flex" align="middle">
                    {props.children}
                </Row>
            </Col>
        </Row>
    );
}

Segment.defaultProps = {
    offset: true
} as Partial<SegmentProps>;
