import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import { action } from 'mobx';
import {
    SubscriptionType,
    ChangePlanRequest,
    AttemptPaymentRequest,
    UpdateCustomerSourceRequest,
    CancelSubscriptionRequest,
    Interval
} from '@contentchef/contentchef-types';

export default class PaymentStore {
    constructor(protected api: ContentChefClient) { }

    @action
    async changePlan(
        subscriptionId: string,
        plan: SubscriptionType,
        tokenizedPaymentMethod: string,
        interval: Interval
    ) {
        try {
            const request: ChangePlanRequest = {
                subscriptionId,
                plan,
                tokenizedPaymentMethod,
                interval
            };
            await this.api.subscription.changePlan(request);
            return true;
        } catch (error) {
            console.log(error);
            return Promise.reject(JSON.parse(JSON.stringify(error)));
        }
    }

    @action
    async attemptPayment(
        subscriptionId: string,
        plan: SubscriptionType,
        tokenizedPaymentMethod: string
    ) {
        try {
            const request: AttemptPaymentRequest = {
                subscriptionId,
                plan,
                tokenizedPaymentMethod
            };
            await this.api.subscription.attemptPayment(request);
            return true;
        } catch (error) {
            console.log(error);
            return Promise.reject(JSON.parse(JSON.stringify(error)));
        }
    }

    @action
    async updateCustomerSource(subscriptionId: string, tokenizedPaymentMethod: string) {
        try {
            const request: UpdateCustomerSourceRequest = {
                subscriptionId,
                tokenizedPaymentMethod
            };
            await this.api.subscription.updateCustomerSource(request);
            return true;
        } catch (error) {
            return Promise.reject(JSON.parse(JSON.stringify(error)));
        }
    }

    @action
    async cancelSubscription(subscriptionId: string) {
        try {
            const request: CancelSubscriptionRequest = {
                subscriptionId
            };
            await this.api.subscription.cancel(request);
            return true;
        } catch (error) {
            console.log(error);
            return Promise.reject(error);
        }
    }
}
