import React, { Component } from 'react';
import { Select } from 'antd';
import ReactCountryFlag from 'react-country-flag';
import countries from 'i18n-iso-countries';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import classes from './index.module.scss';

type Country = {
    code: string;
    name: string
};

interface CountryInputState {
    countryList: Country[];
}

interface CountryInputProps { }

interface InjectedProps extends CountryInputProps {
    intl: InjectedIntl;
    value: string;
    onChange(value: any): void;
}

// register supported languages
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/it.json'));

const { Option } = Select;

class CountryInput extends Component<any, CountryInputState> {

    get injected() {
        return this.props as InjectedProps;
    }

    state = {
        countryList: [] as Country[]
    };

    componentDidMount() {
        const locale = this.injected.intl.locale;
        const localizedCountries = countries.getNames(locale);

        const countryList: Country[] = [];
        for (const countryCode in localizedCountries) {
            if (localizedCountries.hasOwnProperty(countryCode)) {
                countryList.push({
                    code: countryCode,
                    name: localizedCountries[countryCode]
                });
            }
        }

        this.setState({
            countryList: countryList.sort((countryA, countryB) => {
                const nameA = countryA.name.toLowerCase();
                const nameB = countryB.name.toLowerCase();
                return nameA < nameB ? -1 : (nameA > nameB) ? 1 : 0;
            })
        });
    }

    onChangeSelect = (countryCode: string) => {
        this.injected.onChange(countryCode);
    }

    render() {
        const { countryList } = this.state;
        const { value } = this.injected;
        return (
            <Select
                placeholder={
                    <FormattedMessage
                        id="CountryInput.labels.selectCountry"
                        defaultMessage="Select Country"
                    />
                }
                onChange={this.onChangeSelect}
                showSearch={true}
                allowClear={true}
                value={value}
                loading={countryList.length === 0}
                optionFilterProp="data-name"
            >
                {countryList.map(country => {
                    return (
                        <Option
                            key={`${country.code}_${country.name}`}
                            value={country.code}
                            data-name={country.name}
                            className={classes.CountryOption}
                        >
                            <ReactCountryFlag
                                className={classes.FlagIcon}
                                code={country.code}
                            />
                            {country.name}
                        </Option>
                    );
                })}
            </Select>
        );
    }
}

export default injectIntl(CountryInput);
