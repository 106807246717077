import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import { ListSpaceApiCredentials, SpaceLocale, SpaceStats } from '@contentchef/contentchef-types';
import { action, observable, runInAction } from 'mobx';
import { SpaceDetailStoreModel } from './spaceDetailStoreModel';

class SpaceDetailStore implements SpaceDetailStoreModel {
    api: ContentChefClient;
    @observable spaceStats = {} as SpaceStats;
    @observable spaceApiCredentials = {} as ListSpaceApiCredentials;
    @observable spaceLocale = {} as SpaceLocale;
    @observable loading = true;
    @observable updatingDefaultLocale = false;
    @observable updatingFallbackLocale = false;
    @observable addingNewLocale = false;
    @observable removingLocale = false;

    constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action
    async refresh() {
        this.loading = true;
        try {
            const responses = await Promise.all(
                [
                    this.api.space.getSpaceStats(),
                    this.api.space.listSpaceCredentials(),
                    this.api.space.getSpaceLocale()
                ]
            );
            runInAction(() => {
                const [spaceStats, spaceApiCredentials, spaceLocale] = responses;
                this.spaceStats = spaceStats;
                this.spaceApiCredentials = spaceApiCredentials;
                this.spaceLocale = spaceLocale;
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    @action.bound
    async setSpaceLocale() {
        this.loading = true;
        try {
            const response = await this.api.space.getSpaceLocale();
            runInAction(() => {
                this.spaceLocale = response;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    @action.bound
    async addLocale(locale: string) {
        try {
            this.addingNewLocale = true;
            const response = await this.api.space.addSpaceLocale({ locale });
            runInAction(() => {
                this.spaceLocale = response;
                this.addingNewLocale = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.addingNewLocale = false;
            });
        }
    }

    @action.bound
    async updateSpaceDefaultLocale(locale: string) {
        try {
            this.updatingDefaultLocale = true;
            const response = await this.api.space.updateSpaceDefaultLocale({ locale });
            runInAction(() => {
                this.spaceLocale = response;
                this.updatingDefaultLocale = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.updatingDefaultLocale = false;
            });
        }
    }

    @action.bound
    async updateSpaceFallbackLocale(locale: string) {
        try {
            this.updatingFallbackLocale = true;
            const response = await this.api.space.updateSpaceFallbackLocale({ locale });
            runInAction(() => {
                this.spaceLocale = response;
                this.updatingFallbackLocale = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.updatingFallbackLocale = false;
            });
        }
    }

    @action.bound
    async removeLocale(locale: string) {
        try {
            this.removingLocale = true;
            const response = await this.api.space.removeSpaceLocale({ locale });
            runInAction(() => {
                this.spaceLocale = response;
                this.removingLocale = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.removingLocale = false;
            });
        }
    }

    retrieveSupportedLocales(localesToFilter?: string[]) {
        if (!!this.spaceLocale.defaultLocale) {
            return this.spaceLocale.locales
                .filter(locale => localesToFilter ? !localesToFilter.includes(locale) : true);
        }

        return [];
    }

    isEmpty() {
        return this.retrieveSupportedLocales().length < 2;
    }

}

export default SpaceDetailStore;
