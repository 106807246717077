import { Col, InputNumber, Row } from 'antd';
import React from 'react';
import Typography from '../../../Typography';
import styles from './index.module.scss';
import {
    withPermissionsToInteract,
    WithPermissionsToInteractReturnType
} from '../../../../providers/UserPermissionsProvider';

export interface NumberProps extends WithPermissionsToInteractReturnType {
    title: React.ReactNode;
    helper: React.ReactNode;
    minValue?: number;
    maxValue?: number;
    value?: number;
    onChange(value: number | undefined): void;
}

const getValidNumber = (callback: any) => (value: any) => {
    if (typeof value === 'number') {
        return callback(value);
    }
    if (typeof value === 'string' && value === '') {
        return callback(undefined);
    }
};

function Number(props: NumberProps) {
    return (
        <Row type="flex" align="middle" gutter={12} style={{ width: '100%' }}>
            <Col xs={3}>
                <InputNumber
                    disabled={!props.hasPermissions}
                    min={props.minValue !== undefined ? props.minValue : 0}
                    size="small"
                    onChange={getValidNumber(props.onChange)}
                    value={props.value}
                />
            </Col>
            <Col xs={21} className={styles.NumberWrapper}>
                <Typography variant="label" className={styles.TitleWrapper}>
                    {props.title}
                </Typography>
                <Typography className={styles.HelperWrapper}>
                    {props.helper}
                </Typography>
            </Col>
        </Row>
    );
}

export default withPermissionsToInteract(Number);
