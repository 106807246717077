import { Aside, AsideItem, ContainerWithAside, FormActions } from '@components';
import ExtensionForm from '@components/Extension/ExtensionForm';
import { NOTIFICATION_DEFAULT_MESSAGES, NOTIFICATION_KEY_CONSTANTS } from '@constants/notifications-constants';
import { editExtensionPath } from '@constants/routing-constants';
import { userPermissionsTable } from '@constants/user-permissions-table';
import { CreateExtensionRequest } from '@contentchef/contentchef-types';
import withExtensionAPI from '@hoc/withExtensionAPI';
import withFormInitialization from '@hoc/withFormInitialization/withFormInitialization';
import { useStoreForExtensionForm } from '@hooks/mobx';
import { ManageFieldProvider } from '@providers/UserPermissionsProvider';
import {
    createCustomNotification,
    createErrorNotification,
    createInfoNotification,
    createSuccessNotification
} from '@services/Notification';
import React, { useEffect } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';

interface CreateExtensionProps extends RouteComponentProps, InjectedIntlProps { }

function CreateExtension(props: CreateExtensionProps) {

    const { extensionAPI, notificationStore } = useStoreForExtensionForm();

    useEffect(
        () => {
            return () => {
                if (notificationStore.notifications.includes(
                    NOTIFICATION_KEY_CONSTANTS.EXTENSION_API_REQUEST
                )) {
                    notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.EXTENSION_API_REQUEST);
                }
            };
        },
        [notificationStore]
    );

    async function onSubmitForm(extension: CreateExtensionRequest) {
        const { formatMessage } = props.intl;
        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.EXTENSION_API_REQUEST,
            formatMessage,
            NOTIFICATION_DEFAULT_MESSAGES.createAction
        );
        try {
            const result = await extensionAPI.saveExtension(extension);
            createSuccessNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.createAction
            );
            props.history.push(editExtensionPath(result.spaceId, result.id));
        } catch (e: any) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.EXTENSION_API_REQUEST);

            if (e.type === 'InvalidURLExtensionError') {
                createCustomNotification(
                    formatMessage,
                    NOTIFICATION_DEFAULT_MESSAGES.invalidExtensionURL,
                    'error'
                );
            } else {
                createErrorNotification(
                    formatMessage,
                    NOTIFICATION_DEFAULT_MESSAGES.createAction
                );
            }

        }
    }

    async function validateMnemonicId(mnemonicId: string) {
        const valid = await extensionAPI.validateMnemonicId(mnemonicId);
        return valid;
    }
    return (
        <ContainerWithAside
            disableContent={notificationStore.notifications.includes(NOTIFICATION_KEY_CONSTANTS.EXTENSION_API_REQUEST)}
            renderAside={() => (
                <Aside>
                    <AsideItem>
                        <FormActions
                            onSubmit={onSubmitForm}
                            permissions={userPermissionsTable.FormActions.definition}
                        />
                    </AsideItem>
                </Aside>
            )}
        >
            <ManageFieldProvider permissions={userPermissionsTable.Forms.CreateContentDefinitionForm}>
                <ExtensionForm
                    validateMnemonicId={validateMnemonicId}
                />
            </ManageFieldProvider>
        </ContainerWithAside>
    );
}

export default withExtensionAPI(withFormInitialization(injectIntl(withExtensionAPI(CreateExtension))));
