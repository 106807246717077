import React from 'react';
import classes from './index.module.scss';
import { FormattedMessage } from 'react-intl';
import Button from '@components/Button';

export interface InviteAlreadyAcceptedProps {
    onClick?(): any;
}

export default function InviteAlreadyAccepted(props: InviteAlreadyAcceptedProps) {
    return (
        <div className={classes.Invite}>
            <h1 className={classes.InviteTitle}>
                <FormattedMessage
                    defaultMessage="Darn!"
                    id="scenes.AcceptInvite.InviteAlreadyAccepted.title"
                />
            </h1>
            <h2 className={classes.InviteSubTitle}>
                <FormattedMessage
                    defaultMessage="This invite has already been accepted"
                    id="scenes.AcceptInvite.InviteAlreadyAccepted.subTitle"
                />
            </h2>
            <p className={classes.InviteText}>
                <FormattedMessage
                    defaultMessage="Unfortunately, you cannot do anything but gettin back to the home"
                    id="scenes.AcceptInvite.InviteAlreadyAccepted.text"
                />
            </p>
            <Button onClick={props.onClick} type="primary">
                <FormattedMessage
                    defaultMessage="Bring me back home"
                    id="scenes.AcceptInvite.InviteAlreadyAccepted.backToHome"
                />
            </Button>
        </div>
    );
}
