import { LinkType as LinkTypeEnum } from '@contentchef/contentchef-types';
import React from 'react';
import { defineMessages, FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import withDefinitionErrors, {
    WithDefinitionErrorsReturnProps
} from '../../../../hoc/withDefinitionErrors/withDefinitionErrors';
import RadioSelect from '../Fields/RadioSelect';

export interface LinkTypeProps extends WithDefinitionErrorsReturnProps, InjectedIntlProps {
    value: LinkTypeEnum;
    onChange(value: LinkTypeEnum): any;
}

const labels = defineMessages({
    lazyTitle: {
        id: 'LinkType.lazyTitle',
        defaultMessage: 'Content Reference'
    },
    fullTitle: {
        id: 'LinkType.fullTitle',
        defaultMessage: 'Inline content'
    },
    compactTitle: {
        id: 'LinkType.compactTitle',
        defaultMessage: 'Compact view'
    },
    fullHelper: {
        id: 'LinkType.fullHelper',
        defaultMessage:
            `Select this option if you want to automatically load the referenced content when retrieving content.`
    },
    lazyHelper: {
        id: 'LinkType.lazyHelper',
        defaultMessage:
            `Select this option if you don't want to automatically load the referenced content when retrieving content.`
    },
    compactHelper: {
        id: 'LinkType.compactHelper',
        defaultMessage:
            `Select this option if you want to load the referenced content with only the fields marked as compact.`
    },
});

function LinkType(props: LinkTypeProps) {
    return (
        <RadioSelect
            hasErrors={props.hasErrors}
            errors={props.errors.map(e => props.intl.formatMessage(e))}
            value={props.value}
            onChange={props.onChange}
            attributeName={<FormattedMessage
                id="components.ContentDefinitionSchemaEditor.DetailsTab.LinkedContent.linkType.label"
                defaultMessage="Reference type"
            />}
            items={
                [
                    {
                        value: LinkTypeEnum.FULL,
                        title: props.intl.formatMessage(labels.fullTitle),
                        helper: props.intl.formatMessage(labels.fullHelper)
                    },
                    {
                        value: LinkTypeEnum.LAZY,
                        title: props.intl.formatMessage(labels.lazyTitle),
                        helper: props.intl.formatMessage(labels.lazyHelper)
                    },
                    {
                        value: LinkTypeEnum.COMPACT,
                        title: props.intl.formatMessage(labels.compactTitle),
                        helper: props.intl.formatMessage(labels.compactHelper)
                    },
                ]
            }
        />
    );
}

export default withDefinitionErrors(injectIntl(LinkType));
