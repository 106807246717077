import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Button } from 'antd';
import { CLOUDINARY_RAW, CLOUDINARY_IMAGE, CLOUDINARY_VIDEO } from '../../services/cloudinaryUtils';
import { CloudinaryPublicIdPreviewProps, CloudinaryPublicIdPreviewState } from './CloudinaryPublicIdPreviewModel';

import styles from './CloudinaryPublicIdPreview.module.scss';
import { previewComponent } from '../../services/Media/CloudinaryTypePreview';

class CloudinaryPublicIdPreview extends Component<CloudinaryPublicIdPreviewProps, CloudinaryPublicIdPreviewState> {
    render() {
        const { type, value, disableActions } = this.props;
        return (
            <Row type="flex" className={styles.CloudinaryPublicIdPreviewWrapper}>
                <Col xs={24} className={styles.CloudinaryPublicIdPreviewContainer}>
                    {!!this.props.value ?
                        (
                            <React.Fragment>
                                <div className={styles.CloudinaryPublicIdPreviewImage}>
                                    {previewComponent(type, value)}
                                </div>
                                <div className={styles.CloudinaryPublicIdPreviewEdit}>
                                    <Button
                                        icon="edit"
                                        disabled={disableActions}
                                        onClick={this.props.toggleImageEditor}
                                        type="primary"
                                    />
                                    <Button
                                        icon="delete"
                                        disabled={disableActions}
                                        onClick={this.props.onReset}
                                        type="danger"
                                    />
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className={styles.CloudinaryPublicIdPreviewAdd}>
                                <Button
                                    icon="edit"
                                    disabled={disableActions}
                                    onClick={this.props.toggleImageEditor}
                                    type="primary"
                                >
                                    {type === CLOUDINARY_RAW && (
                                        <FormattedMessage
                                            id="CloudinaryPublicIdPreview.SearchOrUploadRawFile"
                                            defaultMessage="Search or upload a file"
                                        />
                                    )}
                                    {type === CLOUDINARY_IMAGE && (
                                        <FormattedMessage
                                            id="CloudinaryPublicIdPreview.SearchOrUploadImage"
                                            defaultMessage="Search or upload an image"
                                        />
                                    )}
                                    {type === CLOUDINARY_VIDEO && (
                                        <FormattedMessage
                                            id="CloudinaryPublicIdPreview.SearchOrUploadVideo"
                                            defaultMessage="Search or upload a video"
                                        />
                                    )}
                                </Button>
                            </div>
                        )}
                </Col>
            </Row>
        );
    }
}

export default CloudinaryPublicIdPreview;
