import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl, defineMessages } from 'react-intl';
import { contentsBasePath, editRepositoryPath } from '../../constants/routing-constants';
import { RepositoryListItemActionsProps, RepositoryListItemActionsState } from './RepositoryListItemActionsModel';

import { ContentListUrlParams } from '../../stores/contentListStore/contentListStoreModel';
import { withPermissionsToInteract } from '../../providers/UserPermissionsProvider';
import ActionList, { ActionDef } from '../atoms/ActionList';

const labels = defineMessages({
    view: {
        id: 'RepositoryListItemActions.View',
        defaultMessage: 'View'
    },
    edit: {
        id: 'RepositoryListItemActions.Edit',
        defaultMessage: 'Edit'
    },
    viewContents: {
        id: 'RepositoryListItemActions.ViewContents',
        defaultMessage: 'View contents'
    }
});

class RepositoryListItemActions extends Component<RepositoryListItemActionsProps, RepositoryListItemActionsState> {

    onViewContents = () => {
        const { repositoryId, repositoryName } = this.props;
        const { spaceId } = this.props.match.params;
        return {
            pathname: contentsBasePath(spaceId),
            search: `?${ContentListUrlParams.repositoryId}=${repositoryId}|${repositoryName}`
        };
    }

    onEditRepository = () => {
        const { repositoryId } = this.props;
        const { spaceId } = this.props.match.params;
        return {
            pathname: editRepositoryPath(spaceId, repositoryId)
        };
    }

    render() {
        const { hasPermissions } = this.props;

        const viewAction = {
            linkHandler: this.onEditRepository,
            title: labels.view,
            icon: 'edit',
            type: 'default',
        };

        const editAction = {
            linkHandler: this.onEditRepository,
            title: labels.edit,
            icon: 'edit',
            type: 'default',
        };

        const actions = [
            {
                linkHandler: this.onViewContents,
                title: labels.viewContents,
                icon: 'search',
                type: 'primary',
            },
            hasPermissions ? editAction : viewAction,
        ] as ActionDef[];

        return (
            <ActionList actions={actions} />
        );
    }
}

export default withPermissionsToInteract(withRouter(injectIntl(RepositoryListItemActions)));
