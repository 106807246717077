import React from 'react';
import classes from './index.module.scss';
import classNames from 'classnames';

export interface ContainerInfoSectionProps extends Partial<React.ComponentPropsWithRef<'div'>> {
    children?: React.ReactNode;
    gutterBottom?: boolean;
    gutterLeft?: boolean;
    gutterRight?: boolean;
    gutterTop?: boolean;
}

export default function ContainerInfoSection(
    {
        className,
        gutterBottom,
        gutterLeft,
        gutterRight,
        gutterTop,
        ...props
    }: ContainerInfoSectionProps
) {
    return (
        <div
            className={
                classNames(
                    classes.ContainerInfoSection,
                    {
                        [classes.ContainerInfoSectionGutterBottom]: gutterBottom,
                        [classes.ContainerInfoSectionGutterLeft]: gutterLeft,
                        [classes.ContainerInfoSectionGutterRight]: gutterRight,
                        [classes.ContainerInfoSectionGutterTop]: gutterTop,
                    },
                    className,
                )
            }
            {
            ...props
            }
        />
    );
}
