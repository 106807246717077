import React, { createContext } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { browseMediaGalleryPathWithFolderPath, uploadMediaPathWithFolderPath } from '../constants/routing-constants';

export interface MediaNavigationContext {
    reloadOnBackButton?: boolean;
    onClickToFolder?(folderPath?: string): void;
    onClickToUpload?(folderPath?: string): void;
    onClickBackToMediaList?(folderPath?: string): void;
}

export const MediaNavigation = createContext<MediaNavigationContext>(
    {} as MediaNavigationContext);

type MediaNavigationProviderProps = { children: React.ReactNode } & RouteComponentProps<
    { mediaGalleryId: string, spaceId: string, folderPath?: string }>;

export const MediaNavigationProvider = withRouter((props: MediaNavigationProviderProps) => {
    return (
        <MediaNavigation.Provider
            value={{
                onClickToFolder: (folderPath) => {
                    const { match: { params: { mediaGalleryId, spaceId } } } = props;
                    props.history.push(
                        browseMediaGalleryPathWithFolderPath(spaceId, parseInt(mediaGalleryId, 10), folderPath)
                    );
                },
                onClickToUpload: (folderPath) => {
                    const { match: { params: { mediaGalleryId, spaceId } } } = props;
                    props.history.push(
                        uploadMediaPathWithFolderPath(spaceId, parseInt(mediaGalleryId, 10), folderPath)
                    );
                },
                onClickBackToMediaList: () => {
                    const { match: { params: { mediaGalleryId, spaceId, folderPath } } } = props;
                    props.history.push(
                        browseMediaGalleryPathWithFolderPath(spaceId, parseInt(mediaGalleryId, 10), folderPath)
                    );
                },
                reloadOnBackButton: true
            }}
        >
            {props.children}
        </MediaNavigation.Provider>
    );
});

export const withMediaNavigation = (WrappedComponent: any) => (props: any) => {
    return (
        <MediaNavigation.Consumer>
            {ctx => (
                <WrappedComponent {...props} {...ctx} />
            )}
        </MediaNavigation.Consumer>
    );
};
