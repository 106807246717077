import React from 'react';
import LanguageField from './LanguageField';
import { isFunction } from '../../../../services/utils';
import { I18NDictionary } from '@contentchef/contentchef-types';
import Segment from '../../../Segment/Segment';
import { Form } from 'antd';
import
withDefinitionErrors,
{ WithDefinitionErrorsReturnProps }
    from '../../../../hoc/withDefinitionErrors/withDefinitionErrors';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { observer } from 'mobx-react';
const FormItem = Form.Item;

export interface TranslateElementProps extends InjectedIntlProps {
    hasValidation?: boolean;
    availableLocales?: string[];
    defaultCulture: string;
    items: I18NDictionary;
    segment?: boolean;
    label?: React.ReactNode;
    onAdd(culture: string): void;
    onChange(culture: string, value: string): void;
}

export interface ValidatedTranslateElementProps extends TranslateElementProps, WithDefinitionErrorsReturnProps { }

function validatedTranslateElement({ segment = false, ...otherProps }: ValidatedTranslateElementProps) {
    const supportedLanguages = [otherProps.defaultCulture].concat(otherProps.availableLocales || []);
    return (
        <Segment
            offset={segment}
            title={otherProps.label}
            divider={!!otherProps.label}
            titleVariant="label"
        >
            <FormItem
                help={otherProps.hasErrors
                    ? otherProps.errors.map(e => otherProps.intl.formatMessage(e)).join(', ')
                    : undefined
                }
                validateStatus={otherProps.hasErrors ? 'error' : undefined}
                style={{ width: '100%' }}
            >
                {supportedLanguages.map((locale, index) => {
                    const labelLocalized = otherProps.items[locale];
                    return (
                        <LanguageField
                            key={index}
                            locale={locale}
                            onChangeValue={value =>
                                isFunction(otherProps.onChange) && otherProps.onChange(locale, value)
                            }
                            value={labelLocalized}
                        />
                    );
                })}
            </FormItem>
        </Segment>
    );
}

function TranslateElement({ segment = false, ...otherProps }: TranslateElementProps) {
    const supportedLanguages = [otherProps.defaultCulture].concat(otherProps.availableLocales || []);
    return (
        <Segment
            offset={segment}
            title={otherProps.label}
            divider={!!otherProps.label}
            titleVariant="label"
        >
            {supportedLanguages.map((locale, index) => {
                const labelLocalized = otherProps.items[locale];
                return (
                    <LanguageField
                        key={index}
                        locale={locale}
                        onChangeValue={value =>
                            isFunction(otherProps.onChange) && otherProps.onChange(locale, value)
                        }
                        value={labelLocalized}
                    />
                );
            })}
        </Segment>
    );
}

export const ValidatedTranslateElement = withDefinitionErrors(injectIntl(observer(validatedTranslateElement)));
export default injectIntl(observer(TranslateElement));
