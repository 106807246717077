import React from 'react';
import { UserProfileIds } from '@contentchef/contentchef-types';
import { FormattedMessage } from 'react-intl';

export interface UserRoleProps {
    role: UserProfileIds;
}

export default function UserRole(props: UserRoleProps) {
    switch (props.role) {
        case UserProfileIds.CONTENT_DESIGNER:
            return (
                <FormattedMessage
                    defaultMessage="Content Designer"
                    id="scenes.Users.UserList.roles.CONTENT_DESIGNER"
                />
            );
        case UserProfileIds.CONTENT_EDITOR:
            return (
                <FormattedMessage
                    defaultMessage="Content Editor"
                    id="scenes.Users.UserList.roles.CONTENT_EDITOR"
                />
            );
        case UserProfileIds.LIVE_PUBLISHING_MANAGER:
            return (
                <FormattedMessage
                    defaultMessage="Live Publishing Manager"
                    id="scenes.Users.UserList.roles.LIVE_PUBLISHING_MANAGER"
                />
            );
        case UserProfileIds.REPOSITORY_MANAGER:
            return (
                <FormattedMessage
                    defaultMessage="Repository Manager"
                    id="scenes.Users.UserList.roles.REPOSITORY_MANAGER"
                />
            );
        case UserProfileIds.SPACE_BILLING_MANAGER:
            return (
                <FormattedMessage
                    defaultMessage="Space Billing Manager"
                    id="scenes.Users.UserList.roles.SPACE_BILLING_MANAGER"
                />
            );
        case UserProfileIds.SPACE_MANAGER:
            return (
                <FormattedMessage
                    defaultMessage="Space Manager"
                    id="scenes.Users.UserList.roles.SPACE_MANAGER"
                />
            );
        case UserProfileIds.SPACE_PROFILES_MANAGER:
            return (
                <FormattedMessage
                    defaultMessage="Space Profiles Manager"
                    id="scenes.Users.UserList.roles.SPACE_PROFILES_MANAGER"
                />
            );
        default:
            throw new Error(`Invalid role (${props.role})`);
    }
}
