import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import {
    PUBLISHING_CHANNELS_ROUTE,
    NEW_PUBLISHING_CHANNEL,
    EDIT_PUBLISHING_CHANNEL,
    publishingChannelBasePath
} from '../../../constants/routing-constants';
import PublishingChannels from '../PublishingChannels/PublishingChannels';
import CreatePublishingChannel from '../CreatePublishingChannel/CreatePublishingChannel';
import UpdatePublishingChannel from '../UpdatePublishingChannels/UpdatePublishingChannel';
import { inject } from 'mobx-react';
import { PublishingChannelsRouterProps, InjectedProps } from './PublishingChannelModel';
import { injectIntl } from 'react-intl';
import { NavigationItem } from '../../../stores/navStore/navStore';
import { NAVIGATION_LABELS } from '../../../constants/navigation-internationalized-labels';
import { NAVIGATION_ITEM_IDENTIFIERS } from '../../../constants/navigation-item-identifier';
import { NAVIGATION_PRIORITY } from '../../../stores/navStore/navStoreModel';

@inject('navStore')
class PublishingChannelsRouter extends Component<PublishingChannelsRouterProps> {
    get injected() {
        return this.props as InjectedProps;
    }
    componentDidMount() {
        const { formatMessage } = this.props.intl;
        const { match: { params: { spaceId } } } = this.props;
        this.injected.navStore.setNavigationPath(
            new NavigationItem(
                NAVIGATION_ITEM_IDENTIFIERS.PUBLISHING_CHANNEL,
                publishingChannelBasePath(spaceId),
                formatMessage(NAVIGATION_LABELS.publishingChannels),
                NAVIGATION_PRIORITY.FIRST
            )
        );
    }
    componentWillUnmount() {
        this.injected.navStore.removeItem(NAVIGATION_ITEM_IDENTIFIERS.PUBLISHING_CHANNEL);
    }
    render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route
                        exact={true}
                        path={PUBLISHING_CHANNELS_ROUTE}
                        component={PublishingChannels}
                    />
                    <Route
                        exact={true}
                        path={NEW_PUBLISHING_CHANNEL}
                        component={CreatePublishingChannel}
                    />
                    <Route
                        exact={true}
                        path={EDIT_PUBLISHING_CHANNEL}
                        component={UpdatePublishingChannel}
                    />
                </Switch>
            </React.Fragment>
        );
    }
}

export default injectIntl(PublishingChannelsRouter);
