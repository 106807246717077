import React from 'react';
import styles from './Header.module.scss';
import { Row, Popover, Icon } from 'antd';
import {
    ExportedApiKey,
    ListSpaceApiCredentials
} from '@contentchef/contentchef-types';
import Typography from '../../../../components/Typography';
import { FormattedMessage } from 'react-intl';
import { CopyToClipBoard } from '../../../../components/CopyToClipboard';

type SpaceCredentials = {
    spaceId: string;
    spaceName: string;
    previewApiKey: string | null;
    onlineApiKey: string | null;
};

const SdkIntegration: React.SFC<{ spaceApiCredentials: SpaceCredentials }> = ({ spaceApiCredentials }) => {

    const sdkParams = (
        <>
            <Typography variant="label">
                <FormattedMessage
                    id="Home.Welcome.CodeIntegration.SpaceId"
                    defaultMessage="SpaceId"
                />
            </Typography>
            <Typography variant="caption"><CopyToClipBoard>{spaceApiCredentials.spaceId}</CopyToClipBoard></Typography>
            <Typography variant="label">
                <FormattedMessage
                    id="Home.Welcome.CodeIntegration.PreviewApiKey"
                    defaultMessage="Preview api key"
                />
            </Typography>
            <Typography variant="caption">
                {spaceApiCredentials.previewApiKey && (
                    <CopyToClipBoard>
                        {spaceApiCredentials.previewApiKey}
                    </CopyToClipBoard>
                )}
            </Typography>
            <Typography variant="label">
                <FormattedMessage
                    id="Home.Welcome.CodeIntegration.OnlineApiKey"
                    defaultMessage="Online api key"
                />
            </Typography>
            <Typography variant="caption">
                {spaceApiCredentials.onlineApiKey && (
                    <CopyToClipBoard>
                        {spaceApiCredentials.onlineApiKey}
                    </CopyToClipBoard>
                )}
            </Typography>
        </>
    );

    return (
        <Popover content={sdkParams} trigger="click">
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <FormattedMessage
                    id="Home.Welcome.CodeIntegration"
                    defaultMessage="Click here for code {codeIcon} integration"
                    values={{
                        codeIcon: <Icon type="code" />
                    }}
                />
            </a>
        </Popover>
    );

};

function sortByDateAscending(keyA: ExportedApiKey, keyB: ExportedApiKey) {
    var dateA = new Date(keyA.createdDate).valueOf();
    var dateB = new Date(keyB.createdDate).valueOf();

    if (dateA < dateB) {
        return -1;
    } else if (dateA === dateB) {
        return 0;
    } else {
        return 1;
    }
}

const component: React.SFC<{
    spaceApiCredentials: ListSpaceApiCredentials
}> = ({ spaceApiCredentials }) => {

    function getOldestActivePreviewAndOnlineKeys(keys: ExportedApiKey[]) {
        const previewKeys = keys.filter(key => !key.disabled && key.previewAccess).sort(sortByDateAscending);
        const onlineKeys =
            keys.filter(key => !key.disabled && key.onlineAccess && !key.previewAccess).sort(sortByDateAscending);

        return {
            onlineApiKey: onlineKeys.length > 0 ? onlineKeys[0].keyValue : '',
            previewApiKey: previewKeys.length > 0 ? previewKeys[0].keyValue : ''
        };
    }

    if (!spaceApiCredentials) {
        return null;
    }

    const { onlineApiKey, previewApiKey } = getOldestActivePreviewAndOnlineKeys(spaceApiCredentials.keys || []);

    const spaceCredentials = {
        spaceId: spaceApiCredentials.spaceId,
        spaceName: spaceApiCredentials.spaceName,
        previewApiKey,
        onlineApiKey
    };

    return (
        <Row type="flex" justify="end" align="middle">
            <span
                className={styles.welcome}
            >
                <FormattedMessage
                    id="dashboard.welcomeToSpace"
                    defaultMessage="Welcome to {spaceName}"
                    values={{ spaceName: spaceCredentials.spaceName }}
                />
            </span>
            <div className={styles.sdkContainer} >
                <SdkIntegration spaceApiCredentials={spaceCredentials} />
            </div>
        </Row>
    );
};

export default component;
