import React from 'react';
import RequiredField from '../Constraints/RequiredField';
import MinMax from '../Constraints/MinMax';
import { FieldEditTabProps } from '../../types';
import { observer } from 'mobx-react';
import { NumberConstraints } from '../../FieldSerializer/fields/number';
import { FormattedMessage } from 'react-intl';
import {
    getMinMaxErrorList,
    requiredMappedErrors
} from '../../../../services/FormUtils/DefinitionFieldMappedErrors';

function SimpleNumberConstraints(props: FieldEditTabProps<NumberConstraints>) {
    return (
        <>
            {!props.isArrayChildren && <RequiredField
                relatedTabKey={'validationTab'}
                mappedErrors={requiredMappedErrors}
                onChange={required => props.onChangeConstraint('required', required)}
                value={props.constraints.required}
            />}
            <MinMax
                relatedTabKey={'validationTab'}
                mappedErrors={getMinMaxErrorList(props.isArrayChildren)}
                onChange={(key, value) => props.onChangeConstraint(key, value)}
                valueMax={props.constraints.max}
                valueMin={props.constraints.min}
                attributeLabel={<FormattedMessage
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.SimpleNumber.MinMax.Label"
                    defaultMessage="Permissible values"
                />}
                minTitle={<FormattedMessage
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.SimpleNumber.MinMax.min.title"
                    defaultMessage="Minimum value"
                />}
                minHelper={<FormattedMessage
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.SimpleNumber.MinMax.min.helper"
                    defaultMessage="Define the minimum allowed value"
                />}
                maxTitle={<FormattedMessage
                    defaultMessage="Maximum value"
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.Constraint.MinMax.max.title"
                />}
                maxHelper={<FormattedMessage
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.Constraint.MinMax.max.helper"
                    defaultMessage="Define the maximum allowed value"
                />}
            />
        </>
    );
}

export default observer(SimpleNumberConstraints);
