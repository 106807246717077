import { Field, SchemaTypeIds } from '@contentchef/contentchef-types';
import * as arr from './fields/array';
import * as bool from './fields/boolean';
import * as cloudinaryImage from './fields/cloudinary-image';
import * as cloudinaryPublicId from './fields/cloudinary-publicId';
import * as cloudinaryVideo from './fields/cloudinary-video';
import * as date from './fields/date';
import * as fragment from './fields/fragment';
import * as linkedContent from './fields/linked-content';
import * as longText from './fields/long-text';
import * as media from './fields/media';
import * as num from './fields/number';
import * as oneFragmentOf from './fields/oneFragmentOf';
import * as oneLinkedContentOf from './fields/oneLinkedContentOf';
import * as slug from './fields/slug';
import * as text from './fields/text';
import { SerializedField } from './types';

export default function deserialize(field?: SerializedField): Field {
    if (!field) {
        throw new TypeError('');
    }

    switch (field.type) {
        case SchemaTypeIds.ARRAY:
            return arr.deserialize(field);
        case SchemaTypeIds.BOOLEAN:
            return bool.deserialize(field);
        case SchemaTypeIds.CLOUDINARY_IMAGE:
            return cloudinaryImage.deserialize(field);
        case SchemaTypeIds.CLOUDINARY_PUBLICID:
            return cloudinaryPublicId.deserialize(field);
        case SchemaTypeIds.MEDIA:
            return media.deserialize(field);
        case SchemaTypeIds.CLOUDINARY_VIDEO:
            return cloudinaryVideo.deserialize(field);
        case SchemaTypeIds.DATE:
            return date.deserialize(field);
        case SchemaTypeIds.FRAGMENT:
            return fragment.deserialize(field);
        case SchemaTypeIds.LINKED_CONTENT:
            return linkedContent.deserialize(field);
        case SchemaTypeIds.LONG_TEXT:
            return longText.deserialize(field);
        case SchemaTypeIds.NUMBER:
            return num.deserialize(field);
        case SchemaTypeIds.ONE_FRAGMENT_OF:
            return oneFragmentOf.deserialize(field);
        case SchemaTypeIds.ONE_LINKED_CONTENT_OF:
            return oneLinkedContentOf.deserialize(field);
        case SchemaTypeIds.TEXT:
            return text.deserialize(field);
        case SchemaTypeIds.SLUG:
            return slug.deserialize(field);
        default:
            throw new Error('unknown field type to deserialize');
    }
}
