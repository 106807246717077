import React from 'react';
import { Icon, Row, Col, Input, InputNumber } from 'antd';
import Button from '@components/Button';
import Typography from '../../../Typography';
import { FormattedMessage } from 'react-intl';
import TranslateElement from '../TranslateElement';
import { observer } from 'mobx-react';
import { ListItem } from '@contentchef/contentchef-types';
import Segment from '../../../Segment/Segment';
import
withDefinitionErrors,
{ WithDefinitionErrorsReturnProps }
    from '../../../../hoc/withDefinitionErrors/withDefinitionErrors';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import {
    withPermissionsToInteract,
    WithPermissionsToInteractReturnType
} from '../../../../providers/UserPermissionsProvider';

export interface ListOfValuesProps extends WithDefinitionErrorsReturnProps, InjectedIntlProps,
    WithPermissionsToInteractReturnType {
    type: 'string' | 'number';
    defaultLang: string;
    value: ListItem<string | number>[];
    onAdd(): void;
    onAddItem(listItemIndex: number, culture: string): any;
    onChangeItemLabel(culture: string, listItemIndex: number, value: string): void;
    onChangeItemValue(listItemIndex: number, listItemValue?: string | number): void;
    onRemove(listItemIndex: number): any;
}

function ListOfValues(props: ListOfValuesProps) {
    return (
        <Segment
            divider={true}
            title={<FormattedMessage
                defaultMessage="List of values"
                /* tslint:disable-next-line:max-line-length */
                id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.Constraints.ListOfValues.title"
            />}
            titleVariant="label"
        >
            <Col xs={24}>
                <span style={{ width: '100%', color: 'red' }}>
                    {props.errors.map(e => props.intl.formatMessage(e)).join(', ')}
                </span>
            </Col>
            <Col xs={24}>
                {props.value.map((item, listItemIndex) => {
                    return (
                        <Row key={listItemIndex} type="flex" align="middle" style={{ paddingBottom: '1em' }}>
                            <Col xs={20}>
                                <Col xs={5} sm={4} xl={3} style={{ paddingRight: '1rem', textAlign: 'right' }}>
                                    <Typography gutter={false} variant="label">
                                        <FormattedMessage
                                            defaultMessage="Value"
                                            // tslint:disable-next-line: max-line-length
                                            id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.Constraints.ListOfValues.value"
                                        />
                                    </Typography>
                                </Col>
                                <Col xs={19} sm={20} xl={21}>
                                    {props.type === 'string' && <Input
                                        onChange={event =>
                                            props.onChangeItemValue(listItemIndex, event.target.value)
                                        }
                                        value={item.code as string}
                                    />}
                                    {props.type === 'number' && <InputNumber
                                        onChange={value => props.onChangeItemValue(listItemIndex, value)}
                                        value={item.code as number}
                                    />}
                                </Col>
                                <Col xs={5} sm={4} xl={3} style={{ paddingRight: '1rem', textAlign: 'right' }}>
                                    <Typography gutter={false} variant="label">
                                        <FormattedMessage
                                            defaultMessage="Label"
                                            // tslint:disable-next-line: max-line-length
                                            id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.Constraints.ListOfValues.label"
                                        />
                                    </Typography>
                                </Col>
                                <Col xs={19} sm={20} xl={21}>
                                    <TranslateElement
                                        segment={false}
                                        defaultCulture={props.defaultLang}
                                        items={item.labels}
                                        onAdd={(culture) => props.onAddItem(listItemIndex, culture)}
                                        onChange={(culture, value) =>
                                            props.onChangeItemLabel(culture, listItemIndex, value)
                                        }
                                    />
                                </Col>
                            </Col>
                            <Col offset={1} xs={3}>
                                <Button
                                    block={true}
                                    onClick={() => props.onRemove(listItemIndex)}
                                    type="danger"
                                >
                                    <Icon type="delete" />
                                </Button>
                            </Col>
                        </Row>
                    );
                })}
            </Col>
            <Col offset={1} xs={7} style={{ paddingTop: '1em' }}>
                <Button onClick={props.onAdd} type="dashed" disabled={!props.hasPermissions}>
                    <Icon type="plus" />
                    <FormattedMessage
                        defaultMessage="Add value"
                        // tslint:disable-next-line: max-line-length
                        id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.Constraints.ListOfValues.addvalue"
                    />
                </Button>
            </Col>
        </Segment>
    );
}

export default withPermissionsToInteract(withDefinitionErrors(injectIntl(observer(ListOfValues))));
