import React, { Component } from 'react';
import { Provider, inject, observer } from 'mobx-react';
import {
    MediaGalleryFlowStore,
    CreateMediaGalleryStore,
    notificationStore,
    UpdateMediaGalleryStore,
    DeleteMediaGalleryStore,
    MediaTagsStore
} from '../../stores';
import { WithStoresComponentProps, WithStoresWrappedComponent } from '@constants/common-types';
import { CreateMediaGalleryStoreModel } from '@stores/createMediaGalleryStore/createMediaGalleryStoreModel';
import { NotificationStoreModel } from '@stores/notificationStore/notificationStoreModel';
import { MediaGalleryFlowStoreModel } from '@stores/mediaGalleryFlowStore/mediaGalleryFlowStoreModel';
import { DeleteMediaGalleryStoreModel } from '@stores/deleteMediaGalleryStore/deleteMediaGalleryStoreModel';
import { ApiStoreModel } from '@stores/apiStore/apiStore';
import { MediaTagsStoreModel } from '@stores/mediaTagsStore/mediaTagsStoreModel';
import MediaGalleryListStore, { MediaGalleryListStoreModel } from '@stores/mediaGalleryListStore';

type InjectedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
};

const withMediaGalleryStores = <T extends {}>(WrappedComponent: WithStoresWrappedComponent<T>) => {
    @inject('apiStore')
    @observer
    class WithMediaGalleryStores extends Component<WithStoresComponentProps<T>> {
        get injected() {
            return this.props as InjectedProps<T>;
        }
        mediaGalleryListStore: MediaGalleryListStoreModel;
        mediaGalleryFlowStore: MediaGalleryFlowStoreModel;
        mediaTagsStore: MediaTagsStoreModel;
        notificationStore: NotificationStoreModel;
        createMediaGalleryStore: CreateMediaGalleryStoreModel;
        deleteMediaGalleryStore: DeleteMediaGalleryStoreModel;
        updateMediaGalleryStore: UpdateMediaGalleryStore;
        constructor(props: WithStoresComponentProps<T>) {
            super(props);

            const { api } = this.injected.apiStore;

            this.mediaGalleryListStore = new MediaGalleryListStore(api);
            this.mediaGalleryFlowStore = new MediaGalleryFlowStore(
                api, this.injected.apiStore.currentSpaceId as string);
            this.mediaTagsStore = new MediaTagsStore(api);
            this.createMediaGalleryStore = new CreateMediaGalleryStore(api);
            this.updateMediaGalleryStore = new UpdateMediaGalleryStore(api);
            this.deleteMediaGalleryStore = new DeleteMediaGalleryStore(api);
            this.notificationStore = notificationStore;
        }
        render() {
            return (
                <Provider
                    mediaGalleryListStore={this.mediaGalleryListStore}
                    mediaGalleryFlowStore={this.mediaGalleryFlowStore}
                    createMediaGalleryStore={this.createMediaGalleryStore}
                    updateMediaGalleryStore={this.updateMediaGalleryStore}
                    deleteMediaGalleryStore={this.deleteMediaGalleryStore}
                    notificationStore={this.notificationStore}
                    mediaTagsStore={this.mediaTagsStore}
                >
                    <WrappedComponent {...this.props} />
                </Provider>
            );
        }
    }

    return WithMediaGalleryStores;
};

export default withMediaGalleryStores;
