import { IObservableArray } from 'mobx';

export enum NAVIGATION_PRIORITY {
    FIRST = 0,
    SECOND = 1,
    THIRD = 2,
    FOURTH = 3,
    FIFTH = 5,
    SIXTH = 6,
}

export interface NavStoreModel {
    navigationItems: IObservableArray<NavigationItemModel>;
    navigationItemsByPriority: IObservableArray<NavigationItemModel>;
    setNavigationPath(navigationItem: NavigationItemModel): void;
    replaceNavigationPaths(navigationItems: NavigationItemModel[]): void;
    pushToPath(navigationItem: NavigationItemModel): void;
    removeItem(id: string): void;
    pushMultipleToPath(navigationItems: NavigationItemModel[]): void;
}

export interface NavigationItemModel {
    displayValue: string;
    path: string | undefined;
}
