import {
    TextSchemaType,
    Field,
    SchemaTypeIds, ListItem,
    TextFieldConstraints
} from '@contentchef/contentchef-types';
import { SerializedField } from '../types';
import { ListItemIds } from '../../types';

export interface TextConstraints {
    allowEmpty?: boolean;
    listOfValues?: ListItem<string>[];
    maxLength?: number;
    minLength?: number;
    pattern?: string;
    required: boolean;
}

export type SerializedTextSchema = SerializedField<TextConstraints>;

export function retrieveTextEditingType(constraints: TextFieldConstraints): ListItemIds {
    if (constraints.hasOwnProperty('listOfValues')) {
        return ListItemIds.ListOfTextValues;
    }
    return ListItemIds.SimpleText;
}

export function deserialize(serialized: SerializedTextSchema): Field<TextSchemaType> {
    return {
        constraints: {
            allowEmpty: serialized.constraints.allowEmpty,
            maxLength: !!serialized.constraints.maxLength ? serialized.constraints.maxLength : undefined,
            minLength: !!serialized.constraints.minLength ? serialized.constraints.minLength : undefined,
            pattern: !!serialized.constraints.pattern ? serialized.constraints.pattern : undefined,
            required: serialized.constraints.required!,
            ...{
                ...serialized.constraints.hasOwnProperty('listOfValues')
                    ? { listOfValues: serialized.constraints.listOfValues }
                    : {}
            }
        },
        labels: serialized.labels,
        placeholder: Object.keys(serialized.placeholder).length > 0 ? serialized.placeholder : undefined,
        hint: Object.keys(serialized.hint).length > 0 ? serialized.hint : undefined,
        enableFacet: serialized.enableFacet,
        name: serialized.name,
        type: SchemaTypeIds.TEXT,
        extension: serialized.extension,
        translatable: serialized.translatable,
        compact: serialized.compact
    };
}

export function serialize(deserialized: Field<TextSchemaType>, initialIndex: number): SerializedTextSchema {
    return {
        constraints: { ...deserialized.constraints },
        labels: deserialized.labels,
        hint: deserialized.hint || {},
        placeholder: deserialized.placeholder || {},
        name: deserialized.name,
        enableFacet: deserialized.enableFacet,
        settings: {},
        type: deserialized.type,
        editingType: retrieveTextEditingType(deserialized.constraints),
        initialIndex,
        extension: deserialized.extension,
        translatable: deserialized.translatable,
        compact: deserialized.compact
    };
}
