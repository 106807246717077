import {
    Field,
    OneLinkedContentOfSchemaType,
    SchemaTypeIds
} from '@contentchef/contentchef-types';

function multiContentReference(defaultLanguage: string, name: string): Field<OneLinkedContentOfSchemaType> {
    return {
        name,
        type: SchemaTypeIds.ONE_LINKED_CONTENT_OF,
        enableFacet: undefined,
        labels: {
            [defaultLanguage]: name
        },
        placeholder: undefined,
        hint: undefined,
        constraints: {
            required: false,
            lazy: false,
            definitionMnemonicIds: [],
            linkType: undefined
        },
        compact: undefined
    };
}

function multiContentReferenceInArray(): OneLinkedContentOfSchemaType {
    return {
        type: SchemaTypeIds.ONE_LINKED_CONTENT_OF,
        enableFacet: undefined,
        constraints: {
            lazy: true,
            definitionMnemonicIds: [],
            linkType: undefined
        }
    };
}

export const oneLinkedContentOfSnippets = {
    asField: {
        multiContentReference,
    },
    asArray: {
        multiContentReferenceInArray,
    }
};
