import React, { Component } from 'react';
import { Col, Form, Divider, Icon, Row } from 'antd';
import { observer } from 'mobx-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { StaticContentFieldProps } from './StaticContentFieldModule';
import {
    ContentNameField,
    ContentPublicId,
    PublicationRangePicker,
    FieldTagsSelector,
    FieldChannelsSelector, FieldRepository
} from '../..';
import { createCommonFieldRules } from '../../../services/FormUtils/FormFieldRules';
import { withForm } from '../../../hoc';

import styles from './StaticContentField.module.scss';
const FormItem = Form.Item;

@observer
class StaticContentField extends Component<StaticContentFieldProps> {

    render() {
        const {
            fieldName, fieldRepository, fieldPublicId, fieldOnlineRange, fieldTags, fieldChannels,
            intl, contentCreationFlow, definition
        } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { formatMessage } = intl;
        return (
            <React.Fragment>
                <Col key={fieldName.name} xs={24}>
                    <FormItem
                        label={formatMessage(fieldName.getLabelDescriptor())}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {getFieldDecorator(
                            fieldName.name,
                            {
                                rules: createCommonFieldRules(fieldName, formatMessage),
                                initialValue: fieldName.value
                            }
                        )(<ContentNameField nameField={fieldName} />)}
                    </FormItem>
                </Col>
                <Col key={`repository_${fieldRepository.name}_definition_${definition.id}`} xs={24}>
                    <Row gutter={24}>
                        <Col xs={24} md={12}>
                            <FormItem
                                label={<span className={styles.StaticContentFieldFormItemLabel}>
                                    <span>{formatMessage(fieldRepository.getLabelDescriptor())}</span>
                                    <Icon type="appstore" />
                                </span>}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                {getFieldDecorator(
                                    fieldRepository.name,
                                    {
                                        rules: createCommonFieldRules(fieldRepository, formatMessage),
                                        initialValue: fieldRepository.value
                                    }
                                )(<FieldRepository repositoryField={fieldRepository} />)}
                            </FormItem>
                        </Col>
                        <Col xs={24} md={12}>
                            <FormItem
                                label={
                                    <span className={styles.StaticContentFieldFormItemLabel}>
                                        <FormattedMessage
                                            id="StaticContentField.definitionId"
                                            defaultMessage="Definition"
                                        />
                                        <Icon type="book" />
                                    </span>}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Row>
                                    <Col xs={24}>
                                        {definition.mnemonicId}
                                    </Col>
                                </Row>
                            </FormItem>
                        </Col>
                    </Row>
                </Col>
                <Col key={fieldPublicId.name} xs={24}>
                    <FormItem
                        label={<span className={styles.StaticContentFieldFormItemLabel}>
                            <span>{formatMessage(fieldPublicId.getLabelDescriptor())}</span>
                            <Icon type="link" />
                        </span>}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {getFieldDecorator(
                            fieldPublicId.name,
                            {
                                rules: createCommonFieldRules(fieldPublicId, formatMessage),
                                initialValue: fieldPublicId.value
                            }
                        )(<ContentPublicId publicIdField={fieldPublicId} />)}
                    </FormItem>
                </Col>
                <Col key={fieldOnlineRange.name} xs={24}>
                    {getFieldDecorator(
                        fieldOnlineRange.name,
                        {
                            rules: createCommonFieldRules(fieldOnlineRange, formatMessage),
                            initialValue: fieldOnlineRange.getValues(),
                        }
                    )(<PublicationRangePicker onlineRangeField={fieldOnlineRange} />)}
                </Col>
                <Col key={fieldTags.name} xs={24}>
                    <FormItem
                        label={<span className={styles.StaticContentFieldFormItemLabel}>
                            <span>{formatMessage(fieldTags.getLabelDescriptor())}</span>
                            <Icon type="tag" />
                        </span>}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {getFieldDecorator(
                            fieldTags.name,
                            {
                                rules: createCommonFieldRules(fieldTags, formatMessage)
                            }
                        )(<FieldTagsSelector fieldTags={fieldTags} />)}
                    </FormItem>
                </Col>
                <Col key={fieldChannels.name} xs={24}>
                    <FormItem
                        label={<span className={styles.StaticContentFieldFormItemLabel}>
                            <span>{formatMessage(fieldChannels.getLabelDescriptor())}</span>
                            <Icon type="cluster" />
                        </span>}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {getFieldDecorator(
                            fieldChannels.name,
                            {
                                rules: createCommonFieldRules(fieldChannels, formatMessage)
                            }
                        )(<FieldChannelsSelector
                            contentCreationFlow={contentCreationFlow}
                            fieldChannels={fieldChannels}
                        />)}
                    </FormItem>
                </Col>
                <Divider className={styles.StaticContentFieldDivider} />
            </React.Fragment>
        );
    }
}

export default withForm(injectIntl(StaticContentField));
