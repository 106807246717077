import { Aside, AsideItem, ContainerWithAside, FormActions } from '@components';
import ExtensionForm from '@components/Extension/ExtensionForm';
import { NOTIFICATION_DEFAULT_MESSAGES, NOTIFICATION_KEY_CONSTANTS } from '@constants/notifications-constants';
import { userPermissionsTable } from '@constants/user-permissions-table';
import withExtensionAPI from '@hoc/withExtensionAPI';
import withFormInitialization from '@hoc/withFormInitialization/withFormInitialization';
import WithLoadingData from '@hoc/WithLoadingData/WithLoadingData';
import { useStoreForExtensionForm } from '@hooks/mobx';
import { ManageFieldProvider } from '@providers/UserPermissionsProvider';
import {
    createCustomNotification,
    createErrorNotification,
    createInfoNotification,
    createSuccessNotification
} from '@services/Notification';
import { UpdateExtensionParams } from '@stores/extensionAPIStore';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Redirect, RouteComponentProps } from 'react-router';
import { extensionNotFound } from '../../../constants/routing-constants';

interface UpdateExtensionProps
    extends RouteComponentProps<{ spaceId: string, extensionId: string }>, InjectedIntlProps { }

function UpdateExtension(props: UpdateExtensionProps) {

    const { extensionAPI, notificationStore } = useStoreForExtensionForm();

    async function getExtension() {
        const { extensionId } = props.match.params;
        await extensionAPI.setExtension(extensionId);
    }

    useEffect(
        () => {
            return () => {
                if (notificationStore.notifications.includes(
                    NOTIFICATION_KEY_CONSTANTS.EXTENSION_API_REQUEST
                )) {
                    notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.EXTENSION_API_REQUEST);
                }
            };
        },
        [notificationStore]
    );

    async function onSubmitForm(params: UpdateExtensionParams) {
        const { formatMessage } = props.intl;
        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.EXTENSION_API_REQUEST,
            formatMessage,
            NOTIFICATION_DEFAULT_MESSAGES.updateAction
        );
        try {
            await extensionAPI.updateExtension(params);
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.EXTENSION_API_REQUEST);
            createSuccessNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.updateAction
            );
        } catch (e: any) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.EXTENSION_API_REQUEST);

            if (e.type === 'InvalidURLExtensionError') {
                createCustomNotification(
                    formatMessage,
                    NOTIFICATION_DEFAULT_MESSAGES.invalidExtensionURL,
                    'error'
                );
            } else {
                createErrorNotification(
                    formatMessage,
                    NOTIFICATION_DEFAULT_MESSAGES.updateAction
                );
            }

        }
    }

    const extension = extensionAPI.extension;

    return (
        <WithLoadingData
            promise={getExtension}
        >
            {extension ? <ContainerWithAside
                disableContent={
                    notificationStore.notifications.includes(NOTIFICATION_KEY_CONSTANTS.EXTENSION_API_REQUEST)
                }
                renderAside={() => (
                    <Aside>
                        <AsideItem>
                            <FormActions
                                onSubmit={onSubmitForm}
                                permissions={userPermissionsTable.FormActions.definition}
                            />
                        </AsideItem>
                    </Aside>
                )}
            >
                <ManageFieldProvider permissions={userPermissionsTable.Forms.CreateContentDefinitionForm}>
                    <ExtensionForm
                        extension={extension}
                        mnemonicIdDisabled={true}
                    />
                </ManageFieldProvider>
            </ContainerWithAside> : (
                    <Redirect to={extensionNotFound(props.match.params.spaceId)} />
                )
            }
        </WithLoadingData>
    );
}

export default withExtensionAPI(withFormInitialization(injectIntl(withExtensionAPI(observer(UpdateExtension)))));
