import React, { Component, ReactChild } from 'react';
import { Icon } from 'antd';

import styles from './Breadcrumb.module.scss';

interface BreadcrumbProps {
    children: JSX.Element[];
}

class Breadcrumb extends Component<BreadcrumbProps> {

    renderCrumb = (crumb: ReactChild, index: number) => {
        return (
            <React.Fragment>
                {(index > 0) && (
                    <Icon
                        className={styles.BreadcrumbSeparator}
                        type="right"
                    />
                )}
                <div className={styles.BreadcrumbItemContainer}>
                    {crumb}
                </div>
            </React.Fragment>
        );
    }

    render() {
        const { children } = this.props;
        return (
            <div
                className={styles.BreadcrumbContainer}
            >
                {React.Children.map(children, (child: ReactChild, index: number) => {
                    return this.renderCrumb(child, index);
                })}
            </div>
        );
    }
}

export default Breadcrumb;
