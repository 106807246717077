import React from 'react';
import { Icon } from 'antd';
import { RawFileProps } from './RawFileModel';
import styles from './RawFile.module.scss';

function RawFile(props: RawFileProps) {
    const { media } = props;
    return (
        <div
            className={styles.RawFile}
            data-media-name={media.retrieveImageName()}
        >
            <Icon
                className={styles.RawFileIcon}
                type="file"
                twoToneColor="#00AEEF"
                theme="twoTone"
            />
            <p className={styles.RawFileName}>{media.retrieveImageName()}</p>
        </div>
    );
}

export default RawFile;
