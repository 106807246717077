import Button from '@components/Button';
import { HasPermissions } from '@providers/UserPermissionsProvider';
import { history } from '@stores/index';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withForm } from '../../../hoc';
import styles from './FormActions.module.scss';
import { FormActionsProps } from './FormActionsModel';

class FormActions extends Component<FormActionsProps> {

    handleSubmit = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.onSubmit(values);
                return;
            }
            if (this.props.onSubmitFormErrors && err) {
                this.props.onSubmitFormErrors(err);
                return;
            }
        });
    }

    handleArchive = () => {
        const { onArchive } = this.props;
        if (onArchive) {
            onArchive();
        }
    }

    handleUnarchive = () => {
        if (this.props.onUnarchive) {
            this.props.onUnarchive();
        }
    }

    handleGoBack = () => {
        if (this.props.goBackTo) {
            history.replace(this.props.goBackTo);
            return;
        }
        history.goBack();
    }

    render() {
        const { onUnarchive, onArchive, disableSave, children, permissions, underPermissionButtons } = this.props;
        return (
            <div className={styles.FormActionsContainer}>
                {children}
                {!onUnarchive && <HasPermissions permissions={permissions!}>
                    <Button
                        id="save"
                        type="primary"
                        disabled={disableSave}
                        block={true}
                        className={`${children ? styles.FormActionsWithChildren : ''}`}
                        style={{ marginTop: children ? 10 : 0 }}
                        onClick={this.handleSubmit}
                    >
                        <FormattedMessage
                            id="FormActions.save"
                            defaultMessage="Save"
                        />
                    </Button>
                    {onArchive && (
                        <Button
                            id="archive"
                            type="danger"
                            block={true}
                            onClick={this.handleArchive}
                        >
                            <FormattedMessage
                                id="FormActions.archive"
                                defaultMessage="Archive"
                            />
                        </Button>
                    )}
                    {underPermissionButtons}
                </HasPermissions>}
                {!!onUnarchive && <Button
                    id="unarchive"
                    block={true}
                    type="primary"
                    onClick={this.handleUnarchive}
                >
                    <FormattedMessage
                        id="FormActions.unarchive"
                        defaultMessage="Unarchive"
                    />
                </Button>}
                <Button
                    id="back"
                    block={true}
                    onClick={this.handleGoBack}
                >
                    <FormattedMessage
                        id="FormActions.goBack"
                        defaultMessage="Go back"
                    />
                </Button>
            </div>
        );
    }
}

export default withForm(FormActions);
