import React from 'react';
import { Icon } from 'antd';
import { FolderProps } from './FolderModel';
import styles from './Folder.module.scss';

function Folder(props: FolderProps) {
    const { folder } = props;
    return (
        <div
            className={styles.Folder}
        >
            <Icon
                className={styles.FolderIcon}
                type="folder-open"
                twoToneColor="#00AEEF"
                theme="twoTone"
            />
            <h3>{folder.name}</h3>
        </div>
    );
}

export default Folder;
