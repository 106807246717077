import React from 'react';
import { Auth } from 'aws-amplify';

export interface AuthorizedProps {
    children?: React.ReactNode;
}

interface AuthorizedState {
    authorized: boolean;
}

function getInitialState(): AuthorizedState {
    return {
        authorized: false,
    };
}

export default class Authorized extends React.Component<AuthorizedProps, AuthorizedState> {
    public mounted: boolean = false;
    public state = getInitialState();

    public async componentDidMount() {
        this.mounted = true;

        const session = await Auth.currentSession();

        if (!this.mounted) {
            return;
        }

        this.setState({
            authorized: session.isValid()
        });
    }

    public componentWillUnmount(): void {
        this.mounted = false;
    }

    public render() {
        if (this.state.authorized) {
            return <>{this.props.children}</>;
        }

        return null;
    }
}
