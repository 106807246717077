import { Col, Row } from 'antd';
import classNames from 'classnames';
import React from 'react';
import classes from './index.module.scss';

interface EntryProps {
    label: React.ReactNode;
    labelIcon?: React.ReactNode;
    value: React.ReactNode;
    actions?: React.ReactNode[];
    alignValue?: 'center' | 'left' | 'right';

}

const Entry = (props: EntryProps) => {

    const entryValueClassName = classNames(classes.EntryValue, {
        [classes.AlignRight]: props.alignValue === 'right',
        [classes.AlignCenter]: props.alignValue === 'center',
        [classes.AlignLeft]: props.alignValue === 'left',
    });

    return (
        <Row className={classes.Entry}>
            <Col xs={12} md={6}>
                <p className={classes.EntryTitle}>
                    {props.label} {props.labelIcon && <span className={classes.EntryLabelIcon}>{props.labelIcon}</span>}
                </p>
            </Col>
            {
                props.actions && props.actions.length > 0 && (
                    <Col xs={12} md={0}>
                        {props.actions.map(action => action)}
                    </Col>
                )
            }
            <Col xs={24} md={props.actions && props.actions.length > 0 ? 9 : 18} className={entryValueClassName}>
                {typeof props.value === 'string' ? (
                    <p>
                    {props.value}
                </p>
                ) : <div>{props.value}</div>}
                
            </Col>
            {
                props.actions && props.actions.length > 0 && (
                    <Col xs={0} md={9}>
                        {props.actions.map(action => action)}
                    </Col>
                )
            }
        </Row>
    );
};

export default Entry;
