import React, { Component } from 'react';
import { Provider, inject, observer } from 'mobx-react';
import {
    ContentDefinitionListStore,
} from '../../stores';
import { WithStoresWrappedComponent, WithStoresComponentProps } from '../../constants/common-types';
import {
    ContentDefinitionListStoreModel
} from '../../stores/contentDefinitionListStore/contentDefinitionListStoreModel';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';

type InjectedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
};

const withContentDefinitionsStores =
    <T extends {}>(WrappedComponent: WithStoresWrappedComponent<T>) => {
        @inject('apiStore')
        @observer
        class WithContentDefinitionsStores extends Component<WithStoresComponentProps<T>> {
            get injected() {
                return this.props as InjectedProps<T>;
            }
            contentDefinitionListStore: ContentDefinitionListStoreModel;
            constructor(props: WithStoresComponentProps<T>) {
                super(props);

                const { api } = this.injected.apiStore;

                this.contentDefinitionListStore = new ContentDefinitionListStore(api);
            }
            render() {
                return (
                    <Provider
                        contentDefinitionListStore={this.contentDefinitionListStore}
                    >
                        <WrappedComponent {...this.props} />
                    </Provider>
                );
            }
        }

        return WithContentDefinitionsStores;
    };

export default withContentDefinitionsStores;
