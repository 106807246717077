import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { ACCESS_TOKEN_ROUTE } from '../../constants/routing-constants';
import AccessTokenList from './AccessTokenList';
import withAccessTokenStores from '../../hoc/withAccessTokenStores';

interface AccessTokensRouterProps { }

interface AccessTokensRouterState { }

class AccessTokensRouter extends Component<AccessTokensRouterProps, AccessTokensRouterState> {
    render() {
        return (
            <Switch>
                <Route
                    exact={true}
                    path={ACCESS_TOKEN_ROUTE}
                    component={AccessTokenList}
                />
            </Switch>
        );
    }
}

export default withAccessTokenStores(AccessTokensRouter);
