import React, { Component } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { DATE_TIME_FORMAT } from '../../constants/date-constants';
import { ContentOverviewProps, ContentOverviewState } from './ContentOverviewModel';
import { DrawerItem } from '..';

import styles from './ContentOverview.module.scss';
import { Icon } from 'antd';

class ContentOverview extends Component<ContentOverviewProps, ContentOverviewState> {
    render() {
        const { selectedContent } = this.props;
        return selectedContent && (
            <div className={styles.ContentOverviewContainer}>
                <DrawerItem
                    title={
                        <FormattedMessage
                            id="ContentOverview.name"
                            defaultMessage="Name"
                        />
                    }
                >
                    <p>
                        {selectedContent.name}
                    </p>
                </DrawerItem>
                <DrawerItem
                    title={
                        <FormattedMessage
                            id="ContentOverview.publicId"
                            defaultMessage="Public Id"
                        />
                    }
                    titleIcon={<Icon type="link" />}
                >
                    <p>
                        {selectedContent.publicId}
                    </p>
                </DrawerItem>
                <DrawerItem
                    title={
                        <FormattedMessage
                            id="ContentOverview.createdBy"
                            defaultMessage="Created by"
                        />
                    }
                >
                    <p>
                        {selectedContent.author.fullName}
                    </p>
                </DrawerItem>
                <DrawerItem
                    title={
                        <FormattedMessage
                            id="ContentOverview.updatedBy"
                            defaultMessage="Updated By"
                        />
                    }
                >
                    <p>
                        {selectedContent.latestContributor.fullName}
                    </p>
                </DrawerItem>
                <DrawerItem
                    title={
                        <FormattedMessage
                            id="ContentOverview.created"
                            defaultMessage="Created"
                        />
                    }
                >
                    <p>
                        {moment(selectedContent.createdDate).format(DATE_TIME_FORMAT)}
                    </p>
                </DrawerItem>
                <DrawerItem
                    title={
                        <FormattedMessage
                            id="ContentOverview.updated"
                            defaultMessage="Updated"
                        />
                    }
                >
                    <p>
                        {moment(selectedContent.lastModifiedDate).format(DATE_TIME_FORMAT)}
                    </p>
                </DrawerItem>
                <DrawerItem
                    title={
                        <FormattedMessage
                            id="ContentOverview.definition"
                            defaultMessage="Definition"
                        />
                    }
                >
                    <p>
                        {`${selectedContent.definition.name}`}
                    </p>
                    <p>
                        {`(${selectedContent.definition.mnemonicId})`}
                    </p>
                </DrawerItem>
                <DrawerItem
                    title={
                        <FormattedMessage
                            id="ContentOverview.id"
                            defaultMessage="Id"
                        />
                    }
                >
                    <p>
                        {selectedContent.id}
                    </p>
                </DrawerItem>
                <DrawerItem
                    title={
                        <FormattedMessage
                            id="ContentOverview.version"
                            defaultMessage="Version"
                        />
                    }
                >
                    <p>
                        {selectedContent.version}
                    </p>
                </DrawerItem>
            </div>
        );
    }
}

export default ContentOverview;
