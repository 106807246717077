import { DynamicFieldTypes } from '@services/FormUtils/FormFields';
import { ExtensionManager } from '@stores/extensionManager';
import ExtensionStore from '@stores/extensionStore';
import { FormMetaStoreModel } from '@stores/formMetaStore/formMetaStoreModel';
import { inject, Provider } from 'mobx-react';
import React, { Component } from 'react';

export default function withExtensionStore<T extends { fieldData: DynamicFieldTypes }>
    (WrappedComponent: React.ComponentType<T>) {

    @inject('extensionManager', 'formMetaStore')
    class WithExtensionStore extends Component<T> {
        extensionStore: ExtensionStore;

        get injected() {
            return this.props as T & { extensionManager: ExtensionManager; formMetaStore: FormMetaStoreModel };
        }

        constructor(props: T) {
            super(props);

            this.injected.extensionManager.add(
                this.props.fieldData, this.injected.formMetaStore.contentInfo!, this.injected.formMetaStore
            );
            this.extensionStore = this.injected.extensionManager.get(this.props.fieldData.uniqueId)!;
        }

        render() {
            return (
                <Provider extensionStore={this.extensionStore}>
                    <WrappedComponent {...this.props} />
                </Provider>
            );

        }
    }

    return WithExtensionStore;
}
