import { Button, Loader, SceneContainer } from '@components';
import { ButtonProps } from '@components/Button';
import Entry from '@components/Entry';
import LocalePicker, { LocalePickerWithoutFooter } from '@components/LocalePicker';
import Paper from '@components/Paper';
import SettingsContentHeader from '@components/SettingsContentHeader';
import Typography from '@components/Typography';
import { useSpaceDetailStore } from '@hooks/mobx';
import { languageByLocale } from '@services/languages';
import { List, Popconfirm } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import styles from './index.module.scss';

interface SpaceSettingsProps extends InjectedIntlProps, RouteComponentProps<{ spaceId: string }> { }

function ButtonWithConfirm(props: { button: ButtonProps, popConfirm: PopconfirmProps, children?: React.ReactNode }) {
    return (
        <Popconfirm
            {...props.popConfirm}
        >
            <Button
                {...props.button}
            >
                {props.children}
            </Button>
        </Popconfirm>
    );
}

interface SpaceLocalesProps {
    locales: string[];
    markAsDefault?: string;
    addLocaleLoading: boolean;
    removeLocaleLoading: boolean;
    onNewLocale(newLocale: string | null): void;
    onRemoveLocale(localeToRemove: string | null): void;
}

function SpaceLocales(props: SpaceLocalesProps) {
    const [localeToAdd, setLocaleToAdd] = useState<string | null>(null);

    async function onChangeNewLocale(locale: string) {
        setLocaleToAdd(locale);
    }

    async function onConfirmNewLocale() {
        const locale = localeToAdd;
        await props.onNewLocale(locale);
        setLocaleToAdd('');
    }

    function onRemoveLocale(locale: string) {
        return async () => {
            await props.onRemoveLocale(locale);
        };
    }

    return (
        <React.Fragment>
            <List
                itemLayout="horizontal"
                dataSource={props.locales}
                bordered={true}
                size="small"
                className={styles.ListContainer}
                footer={
                    <React.Fragment>
                        <LocalePicker
                            locale={'en'}
                            onChange={onChangeNewLocale}
                            value={localeToAdd}
                            size="small"
                            minWidth={200}
                        />
                        <Button
                            style={{ marginLeft: 10 }}
                            size="small"
                            icon="plus"
                            type="primary"
                            loading={props.addLocaleLoading}
                            onClick={onConfirmNewLocale}
                        >
                            Add
                        </Button>
                    </React.Fragment>
                }
                renderItem={
                    (locale) => {
                        const isDefault = locale === props.markAsDefault;
                        const additionalProperties = isDefault ? { underline: true } : {};
                        return (
                            <List.Item
                                actions={isDefault ? [
                                    <Typography
                                        key={`default-locale-${locale}`}
                                        variant="paragraph"
                                        gutter={false}
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        <FormattedMessage
                                            id="Locales.defaultLabel"
                                            defaultMessage="Default"
                                        />
                                    </Typography>
                                ] : [
                                        <ButtonWithConfirm
                                            button={{
                                                icon: 'minus',
                                                type: 'danger',
                                                size: 'small',
                                                loading: props.removeLocaleLoading
                                            }}
                                            popConfirm={{
                                                title: (
                                                    <FormattedMessage
                                                        id="Locales.removeSpaceLocale"
                                                        defaultMessage={
                                                            `You will not be able to publish content for this locale. 
                                                        Do you wish to continue?`
                                                        }
                                                    />
                                                ),
                                                onConfirm: onRemoveLocale(locale)
                                            }}
                                            key={`remove-${locale}`}
                                        />
                                    ]}
                            >
                                <Typography
                                    variant="paragraph"
                                    style={{ fontSize: '14px' }}
                                    gutter={false}
                                    {...additionalProperties}
                                >
                                    {languageByLocale.labelByLocale(locale)}
                                </Typography>
                            </List.Item>
                        );
                    }
                }
            />
        </React.Fragment>
    );
}

function SpaceSettings(props: SpaceSettingsProps) {
    const spaceDetailStore = useSpaceDetailStore();
    const [tempDefaultLocale, setTempDefaultLocale] = useState('');
    const [tempFallbackLocale, setTempFallbackLocale] = useState('');

    useEffect(
        () => {
            async function get() {
                await spaceDetailStore.refresh();
                setTempDefaultLocale(spaceDetailStore.spaceLocale.defaultLocale);
                setTempFallbackLocale(spaceDetailStore.spaceLocale.fallbackLocale || 'en');
            }

            get();
        },
        [spaceDetailStore]
    );

    const onChangeTempDefault = async (value: string) => {
        setTempDefaultLocale(value);
    };

    const onChangeTempFallback = async (value: string) => {
        setTempFallbackLocale(value);
    };

    const onClickDefaultLocale = async () => {
        await spaceDetailStore.updateSpaceDefaultLocale(tempDefaultLocale);
    };

    const onClickFallbackLocale = async () => {
        await spaceDetailStore.updateSpaceFallbackLocale(tempFallbackLocale);
    };

    const onNewLocale = async (value: string) => {
        await spaceDetailStore.addLocale(value);
    };

    const onRemoveLocale = async (localeToRemove: string) => {
        await spaceDetailStore.removeLocale(localeToRemove);
    };

    function renderLocaleSettings() {
        const isTempDefaultSameAsActualLocale = tempDefaultLocale === spaceDetailStore.spaceLocale.defaultLocale;
        const isTempFallbackSameAsActualLocale = tempFallbackLocale === spaceDetailStore.spaceLocale.fallbackLocale;
        const sortedLocales = spaceDetailStore.spaceLocale.locales.slice().sort(locale => {
            return locale === spaceDetailStore.spaceLocale.defaultLocale ? -1 : 0;
        });
        return (
            <Paper
                gutter={true}
                style={{
                    width: '60%',
                }}
            >
                <SettingsContentHeader
                    title={
                        <FormattedMessage
                            id="SpaceSettings.localeSettings"
                            defaultMessage="Locale Settings"
                        />
                    }
                />
                <Entry
                    label={
                        <FormattedMessage
                            id="SpaceSettings.defLocale"
                            defaultMessage="Default Locale"
                        />
                    }
                    value={
                        <div style={{ display: 'flex' }}>
                            <LocalePickerWithoutFooter
                                locale={props.intl.locale}
                                value={tempDefaultLocale}
                                localeOptions={spaceDetailStore.spaceLocale.locales}
                                onChange={onChangeTempDefault}
                                size="small"
                                minWidth={200}
                            />
                            {!isTempDefaultSameAsActualLocale && (
                                <ButtonWithConfirm
                                    button={{
                                        loading: spaceDetailStore.updatingDefaultLocale,
                                        icon: 'check',
                                        type: 'primary',
                                        size: 'small',
                                        style: { marginLeft: 10 }
                                    }}
                                    popConfirm={{
                                        title: (
                                            <FormattedMessage
                                                id="SpaceSettings.confirmChangeDefault"
                                                defaultMessage={
                                                    `This action will impact newly created contents. 
                                                    Do you wish to proceed?`
                                                }
                                            />
                                        ),
                                        onConfirm: onClickDefaultLocale,
                                    }}
                                />
                            )}
                        </div>}
                />
                <Entry
                    label={
                        <FormattedMessage
                            id="SpaceSettings.fallbackLocale"
                            defaultMessage="Fallback Locale"
                        />
                    }
                    value={
                        <div style={{ display: 'flex' }}>
                            <LocalePickerWithoutFooter
                                locale={props.intl.locale}
                                value={tempFallbackLocale}
                                localeOptions={spaceDetailStore.spaceLocale.locales
                                    .filter(locale => locale !== tempFallbackLocale)}
                                onChange={onChangeTempFallback}
                                size="small"
                                minWidth={200}
                            />
                            {!isTempFallbackSameAsActualLocale && (
                                <ButtonWithConfirm
                                    button={{
                                        loading: spaceDetailStore.updatingFallbackLocale,
                                        icon: 'check',
                                        type: 'primary',
                                        size: 'small',
                                        style: { marginLeft: 10 }
                                    }}
                                    popConfirm={{
                                        title: (
                                            <FormattedMessage
                                                id="SpaceSettings.confirmChangeFallback"
                                                defaultMessage={
                                                    `This action will have an impact on translations 
                                                    and their default values. Do you wish to proceed?`
                                                }
                                            />
                                        ),
                                        onConfirm: onClickFallbackLocale,
                                    }}
                                />
                            )}
                        </div>}
                />
                <Entry
                    label={
                        <FormattedMessage
                            id="SpaceSettings.supLocales"
                            defaultMessage="Supported Locales"
                        />
                    }
                    value={
                        <SpaceLocales
                            markAsDefault={spaceDetailStore.spaceLocale.defaultLocale}
                            locales={sortedLocales}
                            onNewLocale={onNewLocale}
                            onRemoveLocale={onRemoveLocale}
                            addLocaleLoading={spaceDetailStore.addingNewLocale}
                            removeLocaleLoading={spaceDetailStore.removingLocale}
                        />
                    }
                />
            </Paper>
        );
    }

    function renderGeneralSettings() {
        const spaceNameValue =
            `${spaceDetailStore.spaceApiCredentials.spaceName} (${spaceDetailStore.spaceApiCredentials.spaceId})`;
        return (
            <Paper
                gutter={true}
                style={{
                    width: '60%',
                }}
            >
                <SettingsContentHeader
                    title={
                        <FormattedMessage
                            id="SpaceSettings.generalSettings"
                            defaultMessage="General Settings"
                        />
                    }
                />
                <Entry
                    label={
                        <FormattedMessage
                            id="SpaceSettings.spaceNameLabel"
                            defaultMessage="Space name"
                        />
                    }
                    value={spaceNameValue}
                />
            </Paper>
        );
    }

    function renderSettings() {
        return (
            <React.Fragment>
                {renderGeneralSettings()}
                {renderLocaleSettings()}
            </React.Fragment>
        );
    }

    return (
        <SceneContainer>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                {spaceDetailStore.loading ? <Loader /> : renderSettings()}
            </div>
        </SceneContainer>
    );
}

export default injectIntl(observer(SpaceSettings));
