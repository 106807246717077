import { defineMessages } from 'react-intl';

export const validationErrorMessage = defineMessages({
    'booleanField.valueIsNotABoolean': {
        id: 'ValidationBooleanField.valueIsNotABoolean',
        defaultMessage: 'Field {fieldName} is not a valid true/false value'
    },
    'dateField.valueIsInvalidDate': {
        id: 'ValidationDateField.valueIsInvalidDate',
        defaultMessage: 'Field {fieldName} is not a valid date type'
    },
    'dateField.valueHasUnspecifiedTimeZone': {
        id: 'ValidationDateField.valueHasUnspecifiedTimeZone',
        defaultMessage: 'Field {fieldName} miss timezone data'
    },
    'dateField.valueIsAfterLimit': {
        id: 'ValidationDateField.valueIsAfterLimit',
        defaultMessage: 'Field {fieldName} is before than {value}'
    },
    'dateField.valueIsBeforeLimit': {
        id: 'ValidationDateField.valueIsBeforeLimit',
        defaultMessage: 'Field {fieldName} is after than {value}'
    },
    'numberField.valueIsNotANumber': {
        id: 'ValidationNumberField.valueIsNotANumber',
        defaultMessage: 'Field {fieldName} is not a valid integer number'
    },
    'numberField.valueTreatedAsAMaxInfinite': {
        id: 'ValidationNumberField.valueTreatedAsAMaxInfinite',
        defaultMessage: 'Field {fieldName} must be a number lower than the supported max value {value}'
    },
    'numberField.valueTreatedAsAMinInfinite': {
        id: 'ValidationNumberField.valueTreatedAsAMinInfinite',
        defaultMessage: 'Field {fieldName} must be a number greater than the supported min value {value}'
    },
    'numberField.valueIsOverMax': {
        id: 'ValidationNumberField.valueIsOverMax',
        defaultMessage: 'Field {fieldName} must be a number lower than {value}'
    },
    'numberField.valueIsBelowMin': {
        id: 'ValidationNumberField.valueIsBelowMin',
        defaultMessage: 'Field {fieldName} must be a number greater than {value}'
    },
    'numberField.valueMissingInListOfValue': {
        id: 'ValidationNumberField.valueMissingInListOfValue',
        defaultMessage: 'Field {fieldName} value is not in the acceptable list of values'
    },
    'textField.valueIsNotAString': {
        id: 'ValidationTextField.valueIsNotAString',
        defaultMessage: 'Field {fieldName} is not a valid string'
    },
    'textField.maxLengthExceeded': {
        id: 'ValidationTextField.maxLengthExceeded',
        defaultMessage: 'Field {fieldName} must be less than {value} characters'
    },
    'textField.minLengthNotReached': {
        id: 'ValidationTextField.minLengthNotReached',
        defaultMessage: 'Field {fieldName} must be more than {value} characters'
    },
    'textField.patternNotMatched': {
        id: 'ValidationTextField.patternNotMatched',
        defaultMessage: 'Field {fieldName} does not match the pattern {value}'
    },
    'textField.valueMissingInListOfValue': {
        id: 'ValidationTextField.valueMissingInListOfValue',
        defaultMessage: 'Field {fieldName} value is not in the acceptable list of values'
    },
    'cloudinaryPublicIdField.valueHaveAnInvalidPublicId': {
        id: 'ValidationCloudinaryPublicIdField.valueIsAnInvalidPublicId',
        defaultMessage: 'Field {fieldName} is not a valid publicId'
    },
    'cloudinaryImageField.valueHaveInvalidTransformationJson': {
        id: 'ValidationCloudinaryImageField.valueHaveInvalidTransformationJson',
        defaultMessage: 'Field {fieldName} is not a valid JSON format'
    },
    'cloudinaryImageField.valueHaveAnInvalidPublicId': {
        id: 'ValidationCloudinaryImageField.valueHaveAnInvalidPublicId',
        defaultMessage: 'Field {fieldName} is not a valid publicId'
    },
    'linkedContentField.invalid': {
        id: 'ValidationLinkedContentField.invalid',
        defaultMessage: 'Field {fieldName} is not a valid linked-content'
    },
    'linkedContentField.notFound': {
        id: 'ValidationLinkedContentField.notFound',
        defaultMessage: 'Field {fieldName} was not found on the DB'
    },
    'linkedContentField.invalidDefinitionType': {
        id: 'ValidationLinkedContentField.invalidDefinitionType',
        defaultMessage: 'Field {fieldName} has not a valid contentDefinition type'
    },
    'oneLinkedContentOfField.valueIsNotAOneLinkedContentOf': {
        id: 'ValidationOneLinkedContent.valueIsNotAOneLinkedContentOf',
        defaultMessage: 'Field {fieldName} is not a valid reference'
    },
    'oneLinkedContentOfField.contentNotFound': {
        id: 'ValidationOneLinkedContent.contentNotFound',
        defaultMessage: 'Field {fieldName} cannot be found'
    },
    'oneLinkedContentOfField.contentDefinitionNotAllowed': {
        id: 'ValidationOneLinkedContent.contentDefinitionNotAllowed',
        defaultMessage: 'Field {fieldName} has not a valid contentDefinition type'
    },
    'arrayField.valueIsNotAnArray': {
        id: 'ValidationArrayField.valueIsNotAnArray',
        defaultMessage: 'Field {fieldName} value is not a valid array'
    },
    'arrayField.minLengthNotReached': {
        id: 'ValidationArrayField.minLengthNotReached',
        defaultMessage: 'Field {fieldName} doesn\'t have the min length of {value} values'
    },
    'arrayField.maxLengthExceeded': {
        id: 'ValidationArrayField.maxLengthExceeded',
        defaultMessage: 'Field {fieldName} exceeded the max Length of {value} values'
    },
    'fragmentField.valueIsNotAFragment': {
        id: 'ValidationFragmentField.valueIsNotAFragment',
        defaultMessage: 'Field {fieldName} has not a valid value'
    },
    'oneFragmentOfField.valueIsNotAOneFragmentOf': {
        id: 'ValidationOneFragmentOfField.valueIsNotAOneFragmentOf',
        defaultMessage: 'Field {fieldName} is not a valid'
    },
    'oneFragmentOfField.fragmentNotFound': {
        id: 'ValidationOneFragmentOfField.fragmentNotFound',
        defaultMessage: 'The referenced field {fieldName} was not found inside the definition'
    },
    'oneFragmentOfField.fragmentNotAllowed': {
        id: 'ValidationOneFragmentOfField.fragmentNotAllowed',
        defaultMessage: 'The referenced field {fieldName} has a not supported value'
    },
    'oneFragmentOfField.fieldValueIsNotAFragment': {
        id: 'ValidationOneFragmentOfField.fieldValueIsNotAFragment',
        defaultMessage: 'The value of field {fieldName} is not a valid'
    },
    'oneFragmentOfField.fieldNameIsNotAString': {
        id: 'ValidationOneFragmentOfField.fieldNameIsNotAString',
        defaultMessage: 'Referenced field in {fieldName} is not a valid name'
    },
    'payload.unknownField': {
        id: 'ValidationPayload.unknownField',
        defaultMessage: 'Field {fieldName} was not find inside the current content definition, please refresh' +
            ' the page to update the current content definition and save the content'
    },
    'payload.MissingRequiredField': {
        id: 'ValidationPayload.missingRequiredField',
        defaultMessage: 'Field {fieldName} is a required field'
    },
    'generic.contentDefinitionNotFound': {
        id: 'ValidationGeneric.contentDefinitionNotFound',
        defaultMessage: 'The content definition has been deleted, select another content definition type'
    },
    'fieldValidationError.errorCodeNotFound': {
        id: 'FieldValidationError.ErrorCodeNotFound',
        defaultMessage: 'Your field had an unexpected validation error ({code}).Please contact our support team.'
    },
    'slugField.invalidSuffix': {
        id: 'FieldValidationError.slug.invalidSuffix',
        defaultMessage: 'Field {fieldName} must end with {suffix}'
    },
    'slugField.invalidPrefix': {
        id: 'FieldValidationError.slug.invalidPrefix',
        defaultMessage: 'Field {fieldName} must start with {prefix}'
    },
    'slugField.valueIsNotAString': {
        id: 'FieldValidationError.slug.valueIsNotAString',
        defaultMessage: 'Field {fieldName} is not a valid string'
    },
    'slugField.patternNotMatched': {
        id: 'FieldValidationError.slug.patternNotMatched',
        defaultMessage: 'Field {fieldName} has to match this pattern {pattern}'
    },
    'slugField.maxLengthExceeded': {
        id: 'FieldValidationError.slug.maxLengthExceeded',
        defaultMessage: 'Field {fieldName} must be less than {maxLength} characters'
    },
});
