import { Field } from '@contentchef/contentchef-types';
import { Drawer } from 'antd';
import classNames from 'classnames';
import { IObservableArray, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { SortEndHandler } from 'react-sortable-hoc';
import {
    ContentDefinitionSchemaStoreModel
} from '../../stores/ContentDefinitionEditorStore/ContentDefinitionSchemaStore';
import ContentDefinitionAddElement from './ContentDefinitionAddElement';
import ContentDefinitionEditorDrawer from './ContentDefinitionEditorDrawer';
import { createCustomItem, createFieldItems } from './ContentDefinitionEditorDrawer/snippets';
import { SortableDefinitionElementList } from './ContentDefinitionElementList';
import CustomFieldEditorStore from './CustomFieldEditorStore';
import FieldEditorStore from './FieldEditorStore';
import FieldSerializer from './FieldSerializer';
import { SerializedField } from './FieldSerializer/types';
import classes from './index.module.scss';
import withFieldEditorStore from './withFieldEditorStore';

export interface DefinitionSchemaEditorProps extends
    InjectedIntlProps, RouteComponentProps<{ customFieldName?: string }> {
    fields: IObservableArray<SerializedField>;
    gutter?: boolean;
    customFieldEditorStore?: CustomFieldEditorStore;
}

interface DecoratedDefinitionSchemaEditorProps extends DefinitionSchemaEditorProps {
    fieldEditorStore: FieldEditorStore;
    contentDefinitionSchemaStore: ContentDefinitionSchemaStoreModel;
}

interface DefinitionSchemaEditorState {
    editingFieldIndex?: number;
    isAddingField: boolean;
    isEditingField: boolean;
}

function getInitialState(): DefinitionSchemaEditorState {
    return {
        isAddingField: false,
        isEditingField: false,
        editingFieldIndex: undefined
    };
}

@inject('fieldEditorStore', 'contentDefinitionSchemaStore')
@observer
class DefinitionSchemaEditor extends React.Component<DefinitionSchemaEditorProps, DefinitionSchemaEditorState> {
    public state = getInitialState();

    public get decoratedProps(): DecoratedDefinitionSchemaEditorProps {
        return this.props as DecoratedDefinitionSchemaEditorProps;
    }

    public onCreateNewFieldClick = () => {
        this.setState({
            isEditingField: true,
            isAddingField: true
        });
    }

    public onDrawerClose = () => {
        this.setState({ ...getInitialState() });
    }

    public handleClone = (field: SerializedField, hasErrors: boolean) => {
        if (this.decoratedProps.customFieldEditorStore) {
            this.decoratedProps.customFieldEditorStore.cloneField(field, hasErrors);
        } else {
            this.decoratedProps.contentDefinitionSchemaStore.cloneSchemaField(field, hasErrors);
        }
    }

    public handleDelete = (key: number, hasErrors: boolean) => {
        if (this.decoratedProps.customFieldEditorStore) {
            this.decoratedProps.customFieldEditorStore.deleteField(key, hasErrors);
        } else {
            this.decoratedProps.contentDefinitionSchemaStore.deleteSchemaField(key, hasErrors);
        }
    }

    setFieldErrorsInFieldStore = (fieldIndex: number) => {
        if (this.props.customFieldEditorStore !== undefined) {
            const fieldErrors = this.props.customFieldEditorStore.retrieveFieldErrors(fieldIndex);
            this.decoratedProps.fieldEditorStore.setFieldValidationErrors(fieldErrors);
        } else {
            const fieldErrors = this.decoratedProps.contentDefinitionSchemaStore.retrieveFieldErrors(fieldIndex);
            this.decoratedProps.fieldEditorStore.setFieldValidationErrors(fieldErrors);
        }
    }

    public handleEdit = (editingField: SerializedField, editingFieldIndex: number, hasErrors: boolean) => {
        this.setState(state => {
            const assignedSchemaField = toJS(editingField, { recurseEverything: true });
            this.decoratedProps.fieldEditorStore.setField(assignedSchemaField);
            if (hasErrors) {
                this.setFieldErrorsInFieldStore(editingFieldIndex);
            }
            return {
                ...state,
                isAddingField: false,
                isEditingField: true,
                editingFieldIndex,
            };
        });
    }

    public handleEditingSave = () => {
        if (this.decoratedProps.customFieldEditorStore) {
            this.state.editingFieldIndex !== undefined
                ? this.decoratedProps.customFieldEditorStore.editField(
                    this.state.editingFieldIndex, this.decoratedProps.fieldEditorStore.serializedField!
                )
                : this.decoratedProps.customFieldEditorStore.saveNewField(
                    this.decoratedProps.fieldEditorStore.serializedField!
                );
        } else {
            this.state.editingFieldIndex !== undefined
                ? this.decoratedProps.contentDefinitionSchemaStore.editSchemaField(
                    this.decoratedProps.fieldEditorStore.serializedField!, this.state.editingFieldIndex!
                )
                : this.decoratedProps.contentDefinitionSchemaStore.saveNewSchemaField(
                    this.decoratedProps.fieldEditorStore.serializedField!
                );
        }
        this.setState({ ...getInitialState() });
    }

    public handleOnSelect = (editingField: Field) => {
        const initialIndex = this.decoratedProps.customFieldEditorStore
            ? this.decoratedProps.customFieldEditorStore.fragment.fields.length
            : this.decoratedProps.contentDefinitionSchemaStore.schema.fields.length;
        this.decoratedProps.fieldEditorStore.setField(FieldSerializer.serialize(editingField, initialIndex));
        this.setState({ isEditingField: true, isAddingField: false });
    }

    onSortElementEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
        if (this.decoratedProps.customFieldEditorStore) {
            this.decoratedProps.customFieldEditorStore.changeFieldsPosition(oldIndex, newIndex);
        } else {
            this.decoratedProps.contentDefinitionSchemaStore.changeSchemaFieldsPositions(oldIndex, newIndex);
        }
    }

    createCustomFieldSnippets = () => {
        const { customFieldName: MaybeEditingCustomFieldName } = this.props.match.params;
        let customFieldNames = Object.keys(this.decoratedProps.contentDefinitionSchemaStore.schema.fragments);
        if (!!MaybeEditingCustomFieldName) {
            customFieldNames = customFieldNames.filter(customFieldName =>
                customFieldName !== MaybeEditingCustomFieldName
            );
        }
        return customFieldNames.map(fragmentName => createCustomItem(fragmentName));
    }

    checkIfNameAlreadyExist = (fieldName: string) => {
        return !!this.props.fields.find(field => field.name === fieldName);
    }

    elementHasErrors = (elementIndex: number) => {
        if (this.props.customFieldEditorStore !== undefined) {
            return this.props.customFieldEditorStore.fieldHasErrors(elementIndex);
        } else {
            return this.decoratedProps.contentDefinitionSchemaStore.fieldHasErrors(elementIndex);
        }
    }

    public render() {
        const {
            gutter,
            fields,
            intl: { formatMessage }
        } = this.props;
        return (
            <div
                className={
                    classNames(
                        classes.DefinitionSchemaEditor,
                        {
                            [classes.DefinitionSchemaEditorGutter]: gutter,
                        }
                    )
                }
            >
                <SortableDefinitionElementList
                    fields={fields}
                    onCheckElementHasErrors={this.elementHasErrors}
                    onCloneClick={this.handleClone}
                    onEditClick={this.handleEdit}
                    onRemoveClick={this.handleDelete}
                    onSortEnd={this.onSortElementEnd}
                    lockToContainerEdges={true}
                    useDragHandle={true}
                    lockAxis={'y'}
                    onCheckNameAlreadyExist={this.checkIfNameAlreadyExist}
                />
                <ContentDefinitionAddElement onClick={this.onCreateNewFieldClick} />
                <Drawer
                    destroyOnClose={true}
                    placement="right"
                    width="50vw"
                    visible={this.state.isEditingField}
                    onClose={this.onDrawerClose}
                    bodyStyle={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                    <ContentDefinitionEditorDrawer
                        customFieldSnippets={this.createCustomFieldSnippets()}
                        defaultFieldSnippets={createFieldItems(formatMessage)}
                        onCheckNameAlreadyExist={this.checkIfNameAlreadyExist}
                        onSelect={this.handleOnSelect}
                        onSaveField={this.handleEditingSave}
                        isAddingField={this.state.isAddingField}
                        closeDrawer={this.onDrawerClose}
                    />
                </Drawer>
            </div>
        );
    }
}

export default withRouter(withFieldEditorStore(injectIntl(DefinitionSchemaEditor)));
