import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import {
    ListContentDefinitionsItem
} from '@contentchef/contentchef-types';
import { IObservableArray } from 'mobx';
import { SearchParamsStoreModel } from '../searchParamsStore/searchParamsStoreModel';

export interface ContentDefinitionListStoreModel {
    api: ContentChefClient;
    error: ErrorModel;
    dataLoaders: DataLoaders;
    searchParams: SearchParamsStoreModel;
    contentDefinitionListData: ContentDefinitionListDataModel;
    initContentDefinitionList(): void;
    setContentDefinitions(skip?: number, take?: number, name?: string): void;
}

export interface ContentDefinitionListDataModel {
    contentDefinitionList: IObservableArray<ListContentDefinitionsItem>;
    total: number;
}

interface ErrorModel {
    contentDefinitions: string;
    count: string;
}

export enum ContentDefinitionListUrlParams {
    byName = 'search',
    byPage = 'page',
    bySize = 'size'
}

export type DataLoaders = {
    contentDefinitionList: boolean
};
