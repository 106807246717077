import { Button, SceneContainer } from '@components/index';
import Typography from '@components/Typography';
import { DATE_TIME_FORMAT } from '@constants/date-constants';
import { NAVIGATION_LABELS } from '@constants/navigation-internationalized-labels';
import { NAVIGATION_ITEM_IDENTIFIERS } from '@constants/navigation-item-identifier';
import { apiKeysPath } from '@constants/routing-constants';
import { ExportedApiKey } from '@contentchef/contentchef-types';
import withSpaceDetailStore from '@hoc/withSpaceDetailStore';
import { useSpaceDetailWithNavStore } from '@hooks/mobx';
import { copyToClipboard } from '@services/utils';
import { NavigationItem } from '@stores/navStore/navStore';
import { NAVIGATION_PRIORITY } from '@stores/navStore/navStoreModel';
import { Icon, List, Tag } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useParams } from 'react-router';
import styles from './index.module.scss';

function Key(props: { keyValue: string }) {
    const [secure, setSecure] = useState(true);

    function secureString(keyValue: string) {
        const lastDigits = keyValue.slice(keyValue.length - 4, keyValue.length);
        return Array(keyValue.length - 4).fill('*').concat(lastDigits).join('');
    }

    function toggleSecured() {
        setSecure(!secure);
    }

    return (
        <div
            className={styles.KeyContainer}
        >
            <div>
                <Typography className={styles.Value}>
                    {secure ? secureString(props.keyValue) : props.keyValue}
                </Typography>
            </div>
            <div className={styles.KeyActions}>
                <Button size="small" icon="swap" type="primary" onClick={toggleSecured} />
            </div>
        </div>
    );
}

function Access(props: { preview: boolean, online: boolean }) {
    return (
        <div>
            <Tag color={props.preview ? 'green' : undefined}>Preview</Tag>
            <Tag color={props.online ? 'green' : undefined}>Online</Tag>
        </div>
    );
}

function ApiKeysList(props: { intl: InjectedIntl }) {

    const { navStore, spaceDetailStore } = useSpaceDetailWithNavStore();
    const params = useParams<{ spaceId: string }>();
    const [loading, setLoading] = useState(true);

    useEffect(
        () => {
            async function get() {
                setLoading(true);
                await spaceDetailStore.refresh();
                setLoading(false);
            }
            navStore.setNavigationPath(new NavigationItem(
                NAVIGATION_ITEM_IDENTIFIERS.APIKEYS,
                apiKeysPath(params.spaceId),
                props.intl.formatMessage(NAVIGATION_LABELS.apiKeys),
                NAVIGATION_PRIORITY.FIRST
            ));

            get();
        }
        ,
        [navStore, params.spaceId, props.intl, spaceDetailStore]
    );

    const onClickCopy = (item: ExportedApiKey) => () => {
        copyToClipboard('copyToClipboard', item.keyValue);
    };

    const keys = spaceDetailStore.spaceApiCredentials && spaceDetailStore.spaceApiCredentials.keys
        ? spaceDetailStore.spaceApiCredentials.keys : [];

    return (
        <SceneContainer>
            <List
                itemLayout="horizontal"
                loading={loading}
                dataSource={keys}
                renderItem={item => (
                    <List.Item className={styles.ItemContainer}>
                        <div className={styles.Info}>
                            <Typography style={{ marginBottom: 10 }}>
                                {item.referenceName}
                            </Typography>
                            <Key keyValue={item.keyValue} />
                            <div className={styles.Date}>
                                <Icon type="edit" style={{ paddingRight: 5 }} />
                                {moment(item.createdDate).local().format(DATE_TIME_FORMAT)}
                            </div>
                        </div>
                        <div className={styles.Access}>
                            <Access preview={item.previewAccess} online={item.onlineAccess} />
                        </div>
                        <div className={styles.Actions}>
                            <Button size="default" icon="copy" type="default" onClick={onClickCopy(item)} />
                        </div>
                    </List.Item>
                )}
            />
        </SceneContainer>
    );
}

export default injectIntl(withSpaceDetailStore(observer(ApiKeysList)));
