import { Col, Row } from 'antd';
import { ColProps } from 'antd/lib/grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FilterBySearch, FilterByTags } from '../index';
import styles from './SearchFilterGroup.module.scss';
import { SearchFilterGroupProps } from './SearchFilterGroupModel';

@observer
class SearchFilterGroup extends Component<SearchFilterGroupProps> {
    colSize: ColProps | null = null;

    constructor(props: SearchFilterGroupProps) {
        super(props);
        this.calculateFilterSizes();
    }

    calculateFilterSizes = () => {
        if (this.props.activeFilters === 2) {
            this.colSize = { xs: 24, md: 12 };
        } else if (this.props.activeFilters === 1) {
            this.colSize = { xs: 24 };
        }
    }

    render() {
        const {
            id,
            dataAttributes,
            filterByNameArgs,
            filterByTagsArgs,
            onResetAllFilters,
        } = this.props;
        return (
            <Row type="flex" justify="center" gutter={4} id={id} className={styles.searchFilterContainer}>
                {
                    filterByNameArgs &&
                    <Col {...this.colSize}>
                        <FilterBySearch
                            defaultValue={filterByNameArgs.defaultValue}
                            onSearch={filterByNameArgs.onSearchByName}
                            dataAttributes={dataAttributes}
                        />
                    </Col>
                }
                {
                    filterByTagsArgs &&
                    <Col {...this.colSize}>
                        <FilterByTags
                            suggestedTags={filterByTagsArgs.listSuggestedTags}
                            defaultValue={filterByTagsArgs.defaultValue}
                            onChange={filterByTagsArgs.onChange}
                            onSearch={filterByTagsArgs.onSearch}
                        />
                    </Col>
                }
                <button className={styles.searchButton} onClick={onResetAllFilters}>Reset</button>
            </Row>
        );
    }
}

export default SearchFilterGroup;
