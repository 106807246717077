import React, { Component } from 'react';
import { Col, Icon } from 'antd';
import { observer, inject } from 'mobx-react';
import { ListItem, Folder } from '..';
import { InjectedProps, FolderListItemProps } from './FolderListItemModel';

import styles from './FolderListItem.module.scss';
import { withMediaNavigation, MediaNavigationContext } from '../../providers/MediaNavigationProvider';

@inject('mediaGalleryFlowStore')
@observer
class FolderListItem extends Component<FolderListItemProps & MediaNavigationContext> {
    get injected() {
        return this.props as InjectedProps;
    }

    renderFolderImage = () => {
        return (
            <Icon
                className={styles.FolderListItemIcon}
                type="folder-open"
                twoToneColor="#00AEEF"
                theme="twoTone"
            />
        );
    }

    renderFolderDescription = () => {
        const { folder } = this.props;
        return (
            <p>
                {folder.name}
            </p>
        );
    }

    onClickFolder = async () => {
        const { folder, onClickToFolder } = this.props;
        if (typeof onClickToFolder !== 'undefined') {
            onClickToFolder(folder.path);
        }
        await this.injected.mediaGalleryFlowStore.reinitializeMediaAndFolders(folder.path);
    }

    openFolderPreview = () => {
        const { folder } = this.props;
        return (
            <Folder
                folder={folder}
            />
        );
    }

    render() {
        const { columnClassName } = this.props;
        return (
            <Col
                xs={24}
                sm={12}
                lg={6}
                xl={4}
                className={columnClassName}
            >
                <ListItem
                    renderPreview={this.renderFolderImage}
                    renderDescription={this.renderFolderDescription}
                    active={false}
                    renderOnHover={this.openFolderPreview}
                    onClick={this.onClickFolder}
                />
            </Col>
        );
    }
}

export default withMediaNavigation(FolderListItem);
