import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { NavStoreModel, NAVIGATION_PRIORITY } from '../../../stores/navStore/navStoreModel';
import { defineMessages, injectIntl, InjectedIntlProps } from 'react-intl';
import { Redirect, Route, RouteComponentProps, Switch, withRouter, } from 'react-router';
import { NavigationItem } from '../../../stores/navStore/navStore';
import {
    editContentDefinitionPath,
    EDIT_CONTENT_DEFINITION,
    EDIT_CONTENT_DEFINITION_CUSTOM_FIELD_EDIT,
    EDIT_CONTENT_DEFINITION_CUSTOM_FIELD_LIST,
    EDIT_CONTENT_DEFINITION_CUSTOM_FIELD_NEW, definitionNotFoundPath
} from '../../../constants/routing-constants';
import { NAVIGATION_LABELS } from '../../../constants/navigation-internationalized-labels';
import ContentDefinitionCustomFieldList from '../ContentDefinitionCustomField/List';
import ContentDefinitionCustomFieldEdit from '../ContentDefinitionCustomField/Edit';
import UpdateContentDefinition from '../UpdateContentDefinition/UpdateContentDefinition';
import {
    ContentDefinitionFormStoreModel
} from '../../../stores/contentDefinitionFormStore/contentDefinitionFormStoreModel';
import { withContentDefinitionFormStores } from '../../../hoc';
import { Loader } from '../../../components';
import {
    ContentDefinitionSchemaStoreModel
} from '../../../stores/ContentDefinitionEditorStore/ContentDefinitionSchemaStore';
import { NAVIGATION_ITEM_IDENTIFIERS } from '../../../constants/navigation-item-identifier';

interface UpdateContentDefinitionRouteProps extends
    RouteComponentProps<{ spaceId: string, contentDefId: string }>,
    InjectedIntlProps { }

interface InjectedProps extends UpdateContentDefinitionRouteProps {
    navStore: NavStoreModel;
    contentDefinitionFormStore: ContentDefinitionFormStoreModel;
    contentDefinitionSchemaStore: ContentDefinitionSchemaStoreModel;
}

const UpdateDefinitionLabels = defineMessages({
    changeRouteText: {
        id: 'UpdateContentDefinitionRouter.onChangeRoute.Text',
        defaultMessage: 'Changing page you will lose your unsaved changes. Continue?'
    }
});

@inject('navStore', 'contentDefinitionFormStore', 'contentDefinitionSchemaStore')
@observer
class UpdateContentDefinitionRouter extends Component<UpdateContentDefinitionRouteProps, {}> {
    unregisterBlockRouteChange = this.props.history.block((location, action) => {
        if (this.injected.contentDefinitionFormStore.contentDefinition) {
            if (this.shouldAskBeforeToChangeRoute(location.pathname)) {
                return this.props.intl.formatMessage(UpdateDefinitionLabels.changeRouteText);
            }
        }
        return;
    });

    get injected() { return this.props as InjectedProps; }

    shouldAskBeforeToChangeRoute = (newPathName: string) => {
        const { spaceId, contentDefId } = this.props.match.params;
        const isUpdateDefinitionPaths = newPathName.includes(editContentDefinitionPath(spaceId, +contentDefId));
        return !isUpdateDefinitionPaths && this.injected.contentDefinitionSchemaStore.isDefinitionSchemaModified();
    }

    componentDidMount = async (): Promise<void> => {
        const { contentDefId } = this.props.match.params;
        await Promise.all([
            this.injected.contentDefinitionFormStore.setContentDefinition(contentDefId!),
            this.injected.contentDefinitionFormStore.setDefinitionSchema()
        ]);
        if (this.injected.contentDefinitionFormStore.contentDefinition) {
            this.injected.contentDefinitionSchemaStore.initStoreSchemas(
                this.injected.contentDefinitionFormStore.contentDefinition.schema
            );
        }
        const {
            intl: { formatMessage },
            match: { params: { spaceId } }
        } = this.props;
        this.injected.contentDefinitionFormStore.updateLoaderStatus('setContentDefinition', false);
        this.injected.navStore.pushToPath(new NavigationItem(
            NAVIGATION_ITEM_IDENTIFIERS.EDIT_DEFINITION,
            editContentDefinitionPath(spaceId, +contentDefId),
            formatMessage(NAVIGATION_LABELS.editContentDefinition),
            NAVIGATION_PRIORITY.SECOND
        ));
    }

    componentWillUnmount() {
        this.unregisterBlockRouteChange();
        this.injected.navStore.removeItem(NAVIGATION_ITEM_IDENTIFIERS.EDIT_DEFINITION);
    }

    render() {
        const {
            contentDefinitionFormStore: { loaders: { setContentDefinition, setDefinitionSchema }, contentDefinition },
        } = this.injected;
        if (setContentDefinition || setDefinitionSchema) {
            return (
                <Loader />
            );
        }
        return !contentDefinition
            ? <Redirect to={definitionNotFoundPath(this.props.match.params.spaceId)} />
            : (
                <Switch>
                    <Route
                        exact={true}
                        path={EDIT_CONTENT_DEFINITION_CUSTOM_FIELD_LIST}
                        component={ContentDefinitionCustomFieldList}
                    />
                    <Route
                        exact={true}
                        path={EDIT_CONTENT_DEFINITION_CUSTOM_FIELD_NEW}
                        component={ContentDefinitionCustomFieldEdit}
                    />
                    <Route
                        exact={true}
                        path={EDIT_CONTENT_DEFINITION_CUSTOM_FIELD_EDIT}
                        component={ContentDefinitionCustomFieldEdit}
                    />
                    <Route
                        exact={true}
                        path={EDIT_CONTENT_DEFINITION}
                        component={UpdateContentDefinition}
                    />
                </Switch>
            );
    }
}

export default withContentDefinitionFormStores(withRouter(injectIntl(UpdateContentDefinitionRouter)));
