import React, { Component } from 'react';
import { inject } from 'mobx-react';
import FieldEditorStore, { TabErrorsCount } from '../../components/ContentDefinitionSchemaEditor/FieldEditorStore';
import { reaction } from 'mobx';
import { FormattedMessage } from 'react-intl';

type MappedErrors = {
    key: string;
    errorCode: string;
};

export type WithDefinitionErrorsReturnProps = {
    hasErrors: boolean;
    errors: FormattedMessage.MessageDescriptor[];
};

export type WithDefinitionErrorsState = WithDefinitionErrorsReturnProps;

export type WithDefinitionErrorsProps<T> = Pick<T, Exclude<keyof T, keyof WithDefinitionErrorsReturnProps>> & {
    mappedErrors: MappedErrors[];
    relatedTabKey?: keyof TabErrorsCount;
};

type InjectedProps<T> = WithDefinitionErrorsProps<T> & {
    fieldEditorStore: FieldEditorStore;
};

const withDefinitionErrors =
    <T extends WithDefinitionErrorsReturnProps>(WrappedComponent: React.ComponentType<T>)
        : React.ComponentType<WithDefinitionErrorsProps<T>> => {

        @inject('fieldEditorStore')
        class WithDefinitionErrors extends Component<WithDefinitionErrorsProps<T>, WithDefinitionErrorsState> {
            state: WithDefinitionErrorsState = {
                hasErrors: false,
                errors: []
            };

            get injected() { return this.props as InjectedProps<T>; }

            fieldErrorReaction = reaction(
                () => this.injected.fieldEditorStore.validationErrors.length,
                () => {
                    const errors = this.getErrors();
                    this.augmentErrorsCounter(errors.length);
                    this.setState({
                        errors: this.injected.fieldEditorStore.retrieveErrorsByErrorCodes(errors),
                        hasErrors: errors.length > 0
                    });
                }
            );

            augmentErrorsCounter = (count: number) => {
                const { relatedTabKey } = this.props;
                if (!!relatedTabKey && count > 0) {
                    this.injected.fieldEditorStore.updateErrorsCount(relatedTabKey as keyof TabErrorsCount, count);
                }
            }

            getErrors = () => {
                const {
                    isAttributeViolation,
                    isArrayAttributeViolation,
                    validationErrors
                } = this.injected.fieldEditorStore;
                let errors: string[] = [];
                this.props.mappedErrors.forEach(mappedError => {
                    validationErrors.forEach(validationError => {
                        if (isAttributeViolation(validationError) && validationError.key === mappedError.key) {
                            validationError.details.forEach(detail => {
                                if (detail.errorCode === mappedError.errorCode) {
                                    errors.push(detail.errorCode);
                                }
                            });
                        }
                        if (isArrayAttributeViolation(validationError) && validationError.key === mappedError.key) {
                            errors.push('checkValuesBeforeToProceed');
                        }
                    });
                });
                return errors;
            }

            componentDidMount(): void {
                const errors = this.getErrors();
                this.augmentErrorsCounter(errors.length);
                this.setState({
                    errors: this.injected.fieldEditorStore.retrieveErrorsByErrorCodes(errors),
                    hasErrors: errors.length > 0
                });
            }

            componentWillUnmount(): void {
                this.fieldErrorReaction();
            }

            render() {
                const { mappedErrors, ...others } = this.props;
                return (
                    <WrappedComponent
                        {...{
                            ...others, errors: this.state.errors, hasErrors: this.state.hasErrors
                        } as unknown as T}
                    />
                );
            }
        }

        return WithDefinitionErrors;
    };

export default withDefinitionErrors;
