import React, { Component } from 'react';
import { FilterModalProps, FilterModalState } from './FilterModalModel';
import { Icon, Modal } from 'antd';

import styles from './FilterModal.module.scss';

class FilterModal extends Component<FilterModalProps, FilterModalState> {
    state: FilterModalState = {
        showModal: false
    };

    onLinkClick = () => {
        this.setState({ showModal: true });
    }

    onCloseModal = () => {
        this.setState({ showModal: false });
    }

    onOkModal = () => {
        this.props.onModalOkCallback();
        this.setState({ showModal: false });
    }

    render() {
        const { linkLabel, children } = this.props;
        return (
            <React.Fragment>
                <a onClick={this.onLinkClick}>
                    <Icon type={'plus'} className={styles.FilterModalIcon} />
                    {linkLabel}
                </a>
                <Modal
                    className={styles.FilterModalModalContainer}
                    centered={true}
                    destroyOnClose={true}
                    visible={this.state.showModal}
                    onCancel={this.onCloseModal}
                    onOk={this.onOkModal}
                >
                    {children}
                </Modal>
            </React.Fragment>
        );
    }
}

export default FilterModal;
