import React from 'react';
import classNames from './index.module.scss';
import {
    GetSubscriptionSummaryResponse,
    SubscriptionType
} from '@contentchef/contentchef-types';
import { InjectedIntlProps, injectIntl, defineMessages } from 'react-intl';
import SubscriptionTypeName from '../SubscriptionTypeName';

export interface SubscriptionPlanSummaryProps extends InjectedIntlProps {
    summary: GetSubscriptionSummaryResponse;
}

const labels = defineMessages({
    fee: {
        defaultMessage: '€/Month',
        id: 'components.SubscriptionPlanSummary.fee',
    },
    plan: {
        defaultMessage: 'plan',
        id: 'components.SubscriptionPlanSummary.plan',
    },
    expiryOn: {
        defaultMessage: 'expires on {renewOn}',
        id: 'components.SubscriptionPlanSummary.expiryOn',
    },
    renewOn: {
        defaultMessage: 'renews on {renewOn}',
        id: 'components.SubscriptionPlanSummary.renewOn',
    },
});

function getFee(summary: GetSubscriptionSummaryResponse): number | null {
    if (isEnterprise(summary) || isTrial(summary)) {
        return null;
    }

    return summary.plan.cost / 100;
}

function isEnterprise(subscription: GetSubscriptionSummaryResponse): boolean {
    return subscription.subscription.type === SubscriptionType.Enterprise;
}

function isTrial(subscription: GetSubscriptionSummaryResponse): boolean {
    return subscription.subscription.type === SubscriptionType.Trial;
}

function EnterpriseSubscriptionSummary(props: SubscriptionPlanSummaryProps) {
    return (
        <div className={classNames.SubscriptionPlanSummary}>
            <div className={classNames.SubscriptionPlanSummaryPlan}>
                <SubscriptionTypeName type={SubscriptionType.Enterprise} />
                {' '}
                {props.intl.formatMessage(labels.plan)}
            </div>
        </div>
    );
}

function TrialSubscriptionSummary(props: SubscriptionPlanSummaryProps) {
    const renewOn = props.intl.formatDate(props.summary.subscription.subscriptionEndDate);

    return (
        <div className={classNames.SubscriptionPlanSummary}>
            <div className={classNames.SubscriptionPlanSummaryPlan}>
                <SubscriptionTypeName type={SubscriptionType.Trial} />
                {' '}
                {props.intl.formatMessage(labels.plan)}
            </div>
            <div className={classNames.SubscriptionPlanSummaryRenewalDate}>
                {props.intl.formatMessage(labels.expiryOn, { renewOn })}
            </div>
        </div>
    );
}

function SubscriptionPlanSummary(props: SubscriptionPlanSummaryProps) {
    if (isTrial(props.summary)) {
        return TrialSubscriptionSummary(props);
    }

    if (isEnterprise(props.summary)) {
        return EnterpriseSubscriptionSummary(props);
    }

    const {
        formatDate,
        formatMessage,
    } = props.intl;
    const {
        subscription,
    } = props.summary;

    const fee = getFee(props.summary);
    const renewOn = formatDate(subscription.subscriptionEndDate);

    return (
        <div className={classNames.SubscriptionPlanSummary}>
            <div className={classNames.SubscriptionPlanSummaryPlan}>
                <SubscriptionTypeName type={subscription.type} />
                {' '}
                {formatMessage(labels.plan)}
            </div>
            <div className={classNames.SubscriptionPlanSummaryFee}>
                {fee}
                {formatMessage(labels.fee)}
            </div>
            <div className={classNames.SubscriptionPlanSummaryRenewalDate}>
                {formatMessage(labels.renewOn, { renewOn })}
            </div>
        </div>
    );
}

export default injectIntl(SubscriptionPlanSummary);
