import React from 'react';
import { Empty } from 'antd';

type CustomEmptyProps = {
    image?: React.ReactNode;
    description?: React.ReactNode;
    addButton?: React.ReactNode;
};

const CustomEmpty = (props: CustomEmptyProps) => {
    return (
        <Empty
            image={!!props.image ? props.image : null}
            description={!!props.description ? props.description : null}
        >
            {!!props.addButton ? props.addButton : null}
        </Empty>
    );
};

export default CustomEmpty;
