import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import { debounce } from '../../services/utils';
import { FilterByOptionsProps, FilterByOptionsState } from './FilterByOptionsModel';
import { FormattedMessage } from 'react-intl';

import styles from './FilterByOptions.module.scss';

const { Option } = Select;

@observer
class FilterByOptions extends Component<FilterByOptionsProps, FilterByOptionsState> {
    debounceOnSearch: (value: string) => void;

    constructor(props: FilterByOptionsProps) {
        super(props);
        this.debounceOnSearch = debounce(this.props.onSearch);
    }

    render() {
        const { onSearch, onChange, defaultValue, filterOptions } = this.props;
        return (
            <Select
                className={styles.FilterByOptions}
                allowClear={true}
                showSearch={true}
                filterOption={false}
                defaultActiveFirstOption={false}
                placeholder={
                    <FormattedMessage
                        id="SearchFilterGroup.placeholder"
                        defaultMessage="Filter by..."
                    />
                }
                value={filterOptions.length > 0 ? defaultValue : undefined}
                onChange={onChange}
                onSearch={this.debounceOnSearch}
                onFocus={onSearch}
            >
                {filterOptions && filterOptions.map(({ label, value }, index) => (
                    <Option key={index.toString()} value={value}>
                        {label}
                    </Option>
                ))}
            </Select>
        );
    }
}

export default FilterByOptions;
