import { PublishingChannelResponse } from '@contentchef/contentchef-types';
import React from 'react';
import { PublishingChannelListProps } from './PublishingChannelListModel';
import { List } from 'antd';
import { PublishingChannelListItem } from '..';

import styles from './PublishingChannelList.module.scss';

function PublishingChannelList(props: PublishingChannelListProps) {
    return (
        <List
            className={styles.PublishingChannelListContainer}
            loading={props.isLoading}
            dataSource={props.channels}
            renderItem={(channel: PublishingChannelResponse) => (
                <PublishingChannelListItem
                    channel={channel}
                />
            )}
        />
    );
}

export default PublishingChannelList;
