import React, { ComponentType, Component } from 'react';
import { inject, observer, Provider } from 'mobx-react';
import WebhooksStore, { WebhooksStoreModel } from '../../stores/webhooksStore/WebhooksStore';
import { WithStoresComponentProps } from '../../constants/common-types';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';

type DecoratedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
};

export default function withWebhooksStores<T>(WrappedComponent: ComponentType<T>): ComponentType<T> {
    @inject('apiStore')
    @observer
    class WithWebhooksStoresHOC extends Component<T> {
        webhooksStore: WebhooksStoreModel;

        constructor(props: T) {
            super(props);

            const { apiStore } = props as DecoratedProps<T>;
            this.webhooksStore = new WebhooksStore(apiStore.api);
        }

        render() {
            return (
                <Provider
                    webhooksStore={this.webhooksStore}
                >
                    <WrappedComponent {...this.props} />
                </Provider>
            );
        }
    }

    return WithWebhooksStoresHOC;
}
