import React, { Component } from 'react';
import { FieldTagsSelectorProps, FieldTagsSelectorState, InjectedProps } from './FieldTagsSelectorModel';
import TagsSelector from '../../TagsSelector/TagsSelector';
import { inject, observer } from 'mobx-react';
import { Select } from 'antd';

import styles from './FieldTagsSelector.module.scss';
import { withDisabledPropertyInContentForm } from '@hoc/withDisabledPropertyInContentForm';

@inject('contentTagStore')
@observer
class FieldTagsSelector extends Component<FieldTagsSelectorProps, FieldTagsSelectorState> {

    get injected() {
        return this.props as InjectedProps;
    }

    onChangeTags = (values: string[]) => {
        this.props.fieldTags.setFieldValue(values);
        this.injected.onChange(values);
        this.injected.contentTagStore.setSuggestedTags();
    }

    onSearchTags = (value?: string) => {
        this.injected.contentTagStore.setSuggestedTags(value);
    }

    render() {
        const { fieldTags, archived } = this.props;
        const { suggestedTags, loader: { suggestedTags: loaderSuggestedTags } } = this.injected.contentTagStore;
        const disabled = archived;
        return (
            disabled
                ? (
                    <Select
                        className={styles.SelectWithArchivedStatus}
                        disabled={disabled}
                        defaultValue={fieldTags.value}
                        mode="tags"
                    />
                ) : (
                    <TagsSelector
                        value={fieldTags.value}
                        suggestedTags={suggestedTags}
                        loading={loaderSuggestedTags}
                        onSearch={this.onSearchTags}
                        onChange={this.onChangeTags}
                    />
                )
        );
    }
}

export default withDisabledPropertyInContentForm(FieldTagsSelector);
