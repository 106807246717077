import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { API_KEYS_ROUTE } from '../../../constants/routing-constants';
import ApiKeysList from './ApiKeysList';

interface ApiKeysRouterProps extends RouteComponentProps { }

const ApiKeysRouter = (props: ApiKeysRouterProps) => {
    return (
        <Switch>
            <Route
                exact={true}
                path={API_KEYS_ROUTE}
                component={ApiKeysList}
            />
        </Switch>
    );
};

export default ApiKeysRouter;
