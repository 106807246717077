import { FieldEditTabProps } from '../../types';
import { observer } from 'mobx-react';
import React from 'react';
import { OneFragmentOfConstraints } from '../../FieldSerializer/fields/oneFragmentOf';
import FragmentNames from '../Constraints/FragmentNames';
import { fragmentOfMappedErrors } from '../../../../services/FormUtils/DefinitionFieldMappedErrors';

function MultiCustomFieldDetails(props: FieldEditTabProps<OneFragmentOfConstraints>) {
    return (
        <FragmentNames
            relatedTabKey={'detailsTab'}
            mappedErrors={fragmentOfMappedErrors}
            value={props.constraints.names}
        />
    );
}

export default observer(MultiCustomFieldDetails);
