import { FieldTranslation } from '@contentchef/contentchef-types';
import { action, computed, observable } from 'mobx';

export class FieldTranslationManager {
    @observable fieldTranslation: FieldTranslation;
    originalValue: string;
    contentChecksum: string;
    constructor(fieldTranslation: FieldTranslation, contentChecksum: string) {
        this.fieldTranslation = fieldTranslation;
        this.contentChecksum = contentChecksum;
        this.originalValue = fieldTranslation.value;
    }

    @action.bound
    setValue(value: any) {
        this.fieldTranslation.value = value;
    }

    @action.bound
    setFieldTranslation(newFieldTranslation: FieldTranslation) {
        this.fieldTranslation = newFieldTranslation;
        this.originalValue = newFieldTranslation.value;
    }

    getValue() {
        return this.fieldTranslation.value;
    }

    getField() {
        return this.fieldTranslation;
    }

    @computed
    get isFieldUpToDate() {
        return this.fieldTranslation.checksum !== null;
    }

    @computed
    get isFieldChanged() {
        return this.fieldTranslation.value !== this.originalValue;
    }
}
