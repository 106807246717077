import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { withMediaGalleryStores, withFormInitialization } from '../../../hoc';
import { MediaGalleryForm, Aside, AsideItem, FormActions, ContainerWithAside } from '../../../components';
import { DataAttributesBuilder } from '../../../services/DataAttributesBuilder';
import { CreateMediaGalleryProps, InjectedProps } from './CreateMediaGalleryModel';
import {
    createInfoNotification,
    createErrorNotification,
    createSuccessNotification
} from '../../../services/Notification';

import { NOTIFICATION_KEY_CONSTANTS, NOTIFICATION_DEFAULT_MESSAGES } from '../../../constants/notifications-constants';
import { MediaGalleryFormDataModel } from '../../../components/Forms/MediaGalleryForm/MediaGalleryFormModel';
import { userPermissionsTable } from '../../../constants/user-permissions-table';
import { ManageFieldProvider } from '../../../providers/UserPermissionsProvider';

@inject('createMediaGalleryStore', 'notificationStore')
@observer
class CreateMediaGallery extends Component<CreateMediaGalleryProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    componentWillUnmount() {
        const { notificationStore } = this.injected;
        const { notifications } = notificationStore;
        if (notifications.includes(NOTIFICATION_KEY_CONSTANTS.MEDIAGALLERY_CREATE_API_KEY_REQUEST)) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.MEDIAGALLERY_CREATE_API_KEY_REQUEST);
        }
    }

    createDataAttributes = () => {
        return new DataAttributesBuilder('mediagallery')
            .setIdAttribute('addMediagallery')
            .setAddFormAttribute('mediaGalleryCreate')
            .build();
    }

    onSubmitForm = async (values: MediaGalleryFormDataModel) => {
        const { formatMessage } = this.props.intl;
        const { notificationStore } = this.injected;
        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.MEDIAGALLERY_CREATE_API_KEY_REQUEST,
            formatMessage,
            NOTIFICATION_DEFAULT_MESSAGES.createAction
        );
        try {
            await this.injected.createMediaGalleryStore.save(values);
            createSuccessNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.createAction
            );
        } catch (e) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.MEDIAGALLERY_CREATE_API_KEY_REQUEST);
            createErrorNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.createAction
            );
        }
    }

    render() {
        const { notifications } = this.injected.notificationStore;
        return (
            <ContainerWithAside
                dataAttributes={this.createDataAttributes().Attributes}
                disableContent={notifications.includes(NOTIFICATION_KEY_CONSTANTS.MEDIAGALLERY_CREATE_API_KEY_REQUEST)}
                renderAside={() => (
                    <Aside>
                        <AsideItem>
                            <FormActions
                                onSubmit={this.onSubmitForm}
                                permissions={userPermissionsTable.FormActions.mediaGallery}
                            />
                        </AsideItem>
                    </Aside>
                )}
            >
                <ManageFieldProvider permissions={userPermissionsTable.Forms.CreateMediaGalleryForm}>
                    <MediaGalleryForm />
                </ManageFieldProvider>
            </ContainerWithAside>
        );
    }
}

export default withMediaGalleryStores(withFormInitialization(injectIntl(CreateMediaGallery)));
