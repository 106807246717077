import developerMode from '@services/devMode';
import { Col, Icon } from 'antd';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Button, ListItem, Media, RawFile } from '..';
import { CLOUDINARY_IMAGE, CLOUDINARY_VIDEO } from '../../services/cloudinaryUtils';
import LazyImage from '../LazyImage';
import styles from './MediaListItem.module.scss';
import { InjectedProps, MediaListItemProps } from './MediaListItemModel';

@inject('mediaGalleryFlowStore')
@observer
class MediaListItem extends Component<MediaListItemProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    renderMediaImage = () => {
        const { mediaItem } = this.props;
        if (mediaItem.data.resourceType === CLOUDINARY_IMAGE || mediaItem.data.resourceType === CLOUDINARY_VIDEO) {
            return (
                <LazyImage
                    src={mediaItem.data.thumbnailUrl}
                    className={styles.MediaListItemImage}
                />
            );
        } else {
            return (
                <Icon
                    className={styles.MediaListItemRawFileIcon}
                    type="file"
                    twoToneColor="#00AEEF"
                    theme="twoTone"
                />
            );
        }
    }

    renderMediaDescription = () => {
        const { mediaItem } = this.props;
        return (
            <p className={styles.MediaListImageDescription}>
                {mediaItem.retrieveImageName()}
            </p>
        );
    }

    renderMediaThumbnail = () => {
        const { onClick, mediaItem } = this.props;
        if (mediaItem.data.resourceType === CLOUDINARY_IMAGE || mediaItem.data.resourceType === CLOUDINARY_VIDEO) {
            return (
                <Media
                    media={mediaItem}
                    onClick={onClick}
                />
            );
        } else {
            return (
                <RawFile
                    media={mediaItem}
                />
            );
        }
    }

    onClickMedia = () => {
        const { onClick, mediaItem } = this.props;
        if (onClick) {
            onClick(mediaItem);
        }
    }

    onDeleteSelectionToggle = () => {
        const { mediaItem } = this.props;
        this.injected.mediaGalleryFlowStore.toggleDeleteMedia(mediaItem);
    }

    renderDeleteSelection = () => {
        if (developerMode()) {
            return (
                <Button
                    shape="circle"
                    icon="delete"
                    className={styles.MediaListItemDeleteButton}
                    onClick={this.onDeleteSelectionToggle}
                    type="danger"
                    size="small"
                />
            );
        }
        return null;
    }

    itemSelectedToBeDeleted = () => {
        const { mediaItem } = this.props;
        return !!this.injected.mediaGalleryFlowStore.selectedMediaToDelete
            .find(item => (
                item.data.publicId === mediaItem.data.publicId &&
                item.data.resourceType === mediaItem.data.resourceType)
            );
    }

    render() {
        const { mediaItem, columnClassName } = this.props;
        const { media: activeMedia } = this.injected.mediaGalleryFlowStore;
        const className = classNames(columnClassName, {
            [styles.MediaListItemToBeDeleted]: this.itemSelectedToBeDeleted(),
        });
        return (
            <Col
                xs={24}
                sm={12}
                lg={6}
                xl={4}
                key={mediaItem.data.publicId}
                className={className}
                data-media-name={mediaItem.retrieveImageName()}
            >
                {this.renderDeleteSelection()}
                <ListItem
                    renderPreview={this.renderMediaImage}
                    renderDescription={this.renderMediaDescription}
                    active={activeMedia ? activeMedia.data.publicId === mediaItem.data.publicId : false}
                    renderOnHover={this.renderMediaThumbnail}
                    onClick={this.onClickMedia}
                />
            </Col>
        );
    }
}

export default MediaListItem;
