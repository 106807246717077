import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Button from '@components/Button';
import { PublishToStageProps, InjectedProps } from './PublishToStageModel';
import { FormattedMessage } from 'react-intl';

import styles from './PublishToStage.module.scss';

@inject('editContentStore', 'contentStatusStore')
@observer
class PublishToStage extends Component<PublishToStageProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    onPublishToStage = () => {
        const { editContentData: { initialContentData } } = this.injected.editContentStore;
        if (initialContentData) {
            this.injected.editContentStore.createPublishingRequest(initialContentData.id, initialContentData.version);
            this.injected.contentStatusStore.pollUntilStatusChanges(initialContentData.id);
        }
    }

    render() {
        const { isContentPublishable } = this.injected.editContentStore;
        return (
            <Button
                id="publish_content"
                disabled={isContentPublishable}
                onClick={this.onPublishToStage}
                type="primary"
                block={true}
                className={styles.PublishToStageButton}
            >
                <FormattedMessage
                    id="PublishToStage.publish"
                    defaultMessage="Publish to Stage"
                />
            </Button>
        );
    }
}

export default PublishToStage;
