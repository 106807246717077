import React from 'react';
import classes from './index.module.scss';

export interface SideContentProps {
    children?: React.ReactNode;
}

export default function SideContent(props: SideContentProps) {
    return (
        <div className={classes.OnboardingSideContent}>
            {props.children}
        </div>
    );
}
