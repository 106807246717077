import React from 'react';
import RequiredField from '../Constraints/RequiredField';
import { FieldEditTabProps } from '../../types';
import { observer } from 'mobx-react';
import { SlugConstraints } from '../../FieldSerializer/fields/slug';
import {
    requiredMappedErrors,
    suffixMappedError,
    prefixMappedError,
    allowSlashMappedErrors
} from '../../../../services/FormUtils/DefinitionFieldMappedErrors';
import { FormattedMessage } from 'react-intl';
import StringField from '../Fields/String';
import AllowSlash from '../Constraints/AllowSlash';

function Slug(props: FieldEditTabProps<SlugConstraints>) {
    if (props.isArrayChildren) {
        return null;
    }
    return (
        <>
            <RequiredField
                relatedTabKey={'validationTab'}
                mappedErrors={requiredMappedErrors}
                onChange={required => props.onChangeConstraint('required', required)}
                value={props.constraints.required}
            />
            <StringField
                relatedTabKey={'validationTab'}
                mappedErrors={suffixMappedError}
                value={props.constraints.suffix}
                description={
                    <FormattedMessage
                        id="components.ContentDefinitionSchemaEditor.ContentDefinitionModal.ConstraintTab.Slug.suffDesc"
                        // tslint:disable-next-line: max-line-length
                        defaultMessage="starts with an hyphen, lowercase letters (or numbers) separated by hyphens after that (eg. -ending)"
                    />
                }
                onChange={value => props.onChangeConstraint('suffix', value)}
            >
                <FormattedMessage
                    defaultMessage="Suffix"
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionModal.ConstraintTab.Slug.suffix"
                />
            </StringField>

            <StringField
                relatedTabKey={'validationTab'}
                mappedErrors={prefixMappedError}
                value={props.constraints.prefix}
                description={
                    <FormattedMessage
                        id="components.ContentDefinitionSchemaEditor.ContentDefinitionModal.ConstraintTab.Slug.preDesc"
                        // tslint:disable-next-line: max-line-length
                        defaultMessage="starts with at least one letter (or number), dashes and lowercase alphanumeric characters after that (eg. start-)"
                    />
                }
                onChange={value => props.onChangeConstraint('prefix', value)}
            >
                <FormattedMessage
                    defaultMessage="Prefix"
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionModal.ConstraintTab.Slug.prefix"
                />
            </StringField>

            <AllowSlash
                relatedTabKey="validationTab"
                mappedErrors={allowSlashMappedErrors}
                onChange={required => props.onChangeConstraint('allowSlash', required)}
                value={props.constraints.allowSlash}
            />
        </>

    );
}

export default observer(Slug);
