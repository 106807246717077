import { notification } from 'antd';
import { ArgsProps, NotificationApi } from 'antd/lib/notification';
import { action, IObservableArray, observable } from 'mobx';
import { NotificationStoreModel } from './notificationStoreModel';

class NotificationStore implements NotificationStoreModel {
    @observable notifications: IObservableArray<string> = observable.array([]);

    @action
    openNotification(args: ArgsProps) {
        notification.open(args);
        const key = args.key;
        if (key) {
            this.notifications.push(key);
        }
    }

    @action
    openNotificationWithIcon(type: keyof Pick<NotificationApi, 'open' | 'success' | 'error' | 'info' | 'warn' | 'warning'>, args: ArgsProps) {
        notification[type](args);
        const key = args.key;
        if (key) {
            this.notifications.push(key);
        }
    }

    @action
    closeNotification(key: string) {
        notification.close(key);
        this.notifications.remove(key);
    }

    @action
    closeAllNotifications() {
        this.notifications.forEach(notificationKey => {
            notification.close(notificationKey);
        });
        this.notifications.clear();
    }
}

const notificationStore = new NotificationStore();
export default notificationStore;
