import React, { Component } from 'react';
import { Provider, inject, observer } from 'mobx-react';
import { WithStoresWrappedComponent, WithStoresComponentProps } from '../../constants/common-types';
import { ChannelsStore } from '../../stores';
import { ChannelsStoreModel } from '../../stores/channelsStore/channelsStoreModel';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';

type InjectedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
};

const withChannelsStore = <T extends {}>(WrappedComponent: WithStoresWrappedComponent<T>) => {
    @inject('apiStore')
    @observer
    class WithChannelsStore extends Component<WithStoresComponentProps<T>> {
        get injected() {
            return this.props as InjectedProps<T>;
        }
        channelsStore: ChannelsStoreModel;
        constructor(props: WithStoresComponentProps<T>) {
            super(props);
            const { api } = this.injected.apiStore;
            this.channelsStore = new ChannelsStore(api);
        }
        render() {
            return (
                <Provider
                    channelsStore={this.channelsStore}
                >
                    <WrappedComponent {...this.props} />
                </Provider>
            );
        }
    }

    return WithChannelsStore;
};

export default withChannelsStore;
