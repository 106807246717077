import React, { Component, ChangeEvent } from 'react';
import {
    MediaStepperProps,
    MediaStepperState,
    MediaStep,
    InjectedProps
} from './MediaStepperModel';
import {
    Step,
    CloudinaryImageUpload,
    MediaGalleryListUpload,
    MediaFinder,
    CloudinaryModalPreview
} from '..';
import { MediaAdapter } from '../../services/Media/MediaConverterClass';
import { inject, observer } from 'mobx-react';
import {
    getFileType
} from '../../services/cloudinaryUtils';

@inject('mediaGalleryFlowStore')
@observer
class MediaStepper extends Component<MediaStepperProps, MediaStepperState> {

    get injected() {
        return this.props as InjectedProps;
    }
    constructor(props: MediaStepperProps) {
        super(props);

        const publicId = this.props.field && this.props.field.value ? this.props.field.value.publicId : '';
        const transformations =
            this.props.field && this.props.field.value ? this.props.field.value.transformations : '';

        const preferences = this.injected.mediaGalleryFlowStore.getMediaGalleryPreferences();

        this.state = {
            activeStep:
                publicId ? MediaStep.MediaPreview :
                    preferences?.folderPath && preferences.mediaGalleryId ? MediaStep.Folder : MediaStep.MediaGallery,
            temporaryPublicId: publicId ? publicId : '',
            temporaryTransformations: transformations ? transformations : '',
            hasTransformationError: false
        };
    }

    previousStep = () => {
        const { activeStep } = this.state;
        if (activeStep > 0) {
            this.setState({
                activeStep: activeStep - 1
            });
        }
        this.injected.mediaGalleryFlowStore.clearLocalStorage();
    }

    nextStep = () => {
        const { activeStep } = this.state;
        if (activeStep < MediaStep.Upload) {
            this.setState({
                activeStep: activeStep + 1
            });
        }
    }

    jumpToPreview = (publicId: string) => {
        this.setState({
            activeStep: MediaStep.MediaPreview
        });
        this.overridePublicId(publicId);
    }

    onUpload = (results: PromiseFulfilledResult<{
        url: string;
        mediaPublicId: string;
        fileName: string;
    }>[]) => {

        const media = results[0].value;
        this.setState({
            activeStep: MediaStep.MediaPreview,
            mediaUrl: media.url
        });
        this.overridePublicId(media.mediaPublicId);
    }

    overridePublicId = (publicId: string) => {
        this.setState({
            temporaryPublicId: publicId
        });
    }

    onChangeTransformations = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        const { target: { value } } = e;
        const parsedTransformation = this.getValidTransformations(value);

        if (value.length > 0 && Object.keys(parsedTransformation).length === 0) {
            this.setState({
                temporaryTransformations: value,
                hasTransformationError: true
            });
        } else {
            this.setState({
                temporaryTransformations: value,
                hasTransformationError: false
            });
        }
    }

    getValidTransformations = (transformations: string) => {
        let parsedTransformation;

        try {
            parsedTransformation = JSON.parse(transformations);
        } catch (e) {
            parsedTransformation = {};
        }

        return parsedTransformation;
    }

    onConfirmSelection = () => {
        const { temporaryPublicId, temporaryTransformations, mediaUrl } = this.state;
        this.props.onConfirmSelection(temporaryPublicId, temporaryTransformations, mediaUrl);
    }

    onMediaClick = (media: MediaAdapter) => {
        this.injected.mediaGalleryFlowStore.toggleMedia(media);
        this.setState({
            mediaUrl: media.data.publicUrl
        });
    }

    onlyAllowedType = (media: MediaAdapter) => {
        const { type } = this.props;
        return media.data.resourceType === type;
    }

    isValidFileType = (file: File) => {
        const { type } = this.props;
        return getFileType(file.type) === type;
    }

    render() {
        const { activeStep, temporaryPublicId, temporaryTransformations, hasTransformationError } = this.state;
        const localFolderPath = this.injected.mediaGalleryFlowStore.getMediaGalleryPreferences().folderPath;
        const { type } = this.props;
        return (
            <React.Fragment>
                <Step activeStep={activeStep} step={MediaStep.MediaGallery}>
                    <MediaGalleryListUpload
                        nextStep={this.nextStep}
                    />
                </Step>
                <Step activeStep={activeStep} step={MediaStep.Folder}>
                    <MediaFinder
                        onMediaClick={this.onMediaClick}
                        resourceType={type}
                        isForm={true}
                        nextStep={this.nextStep}
                        prevStep={this.previousStep}
                        validType={this.onlyAllowedType}
                        jumpToPreview={this.jumpToPreview}
                        localFolderPath={localFolderPath}
                    />
                </Step>
                <Step activeStep={activeStep} step={MediaStep.Upload}>
                    <CloudinaryImageUpload
                        limitFiles={1}
                        onUpload={this.onUpload}
                        previousStep={this.previousStep}
                        validateFileType={this.isValidFileType}
                    />
                </Step>
                <Step activeStep={activeStep} step={MediaStep.MediaPreview}>
                    <CloudinaryModalPreview
                        type={type}
                        publicId={temporaryPublicId}
                        transformations={temporaryTransformations}
                        hasTransformationError={hasTransformationError}
                        onChangeTransformations={this.onChangeTransformations}
                        onConfirmSelection={this.onConfirmSelection}
                    />
                </Step>
            </React.Fragment>
        );
    }
}

export default MediaStepper;
