import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { LinkableContentProps, LinkableContentState } from './LinkableContentModel';

import styles from './LinkableContent.module.scss';

class LinkableContent extends Component<LinkableContentProps, LinkableContentState> {
    render() {
        return (
            <Link
                className={styles.LinkableContentLinkContent}
                to={this.props.to}
            >
                {this.props.children}
            </Link>
        );
    }
}

export default LinkableContent;
