import React, { Component } from 'react';
import styles from './PublishActions.module.scss';
import Button from '@components/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PublishActionProps, InjectedProps } from './PublishActionsModel';
import { inject, observer } from 'mobx-react';
import {
    createInfoNotification,
    createSuccessNotification,
    createErrorNotification
} from '@services/Notification';
import { NOTIFICATION_KEY_CONSTANTS, NOTIFICATION_DEFAULT_MESSAGES } from '@constants/notifications-constants';
import { HasPermissions } from '@providers/UserPermissionsProvider';
import { userPermissionsTable } from '@constants/user-permissions-table';

@inject('publishToLiveStore', 'notificationStore')
@observer
class PublishActions extends Component<PublishActionProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    onPublish = async () => {
        const { formatMessage } = this.props.intl;
        const { notificationStore } = this.injected;
        try {
            createInfoNotification(
                NOTIFICATION_KEY_CONSTANTS.PUBLISH_TO_LIVE_REQUEST,
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.publishToLiveAction
            );
            const success = await this.injected.publishToLiveStore.publishToLive();
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.PUBLISH_TO_LIVE_REQUEST);
            if (success) {
                createSuccessNotification(
                    formatMessage,
                    NOTIFICATION_DEFAULT_MESSAGES.publishToLiveAction
                );
            } else {
                createErrorNotification(
                    formatMessage,
                    NOTIFICATION_DEFAULT_MESSAGES.publishToLiveAction
                );
            }
        } catch (e) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.PUBLISH_TO_LIVE_REQUEST);
            createErrorNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.publishToLiveAction
            );
        }
    }

    onRefresh = async () => {
        await this.injected.publishToLiveStore.init();
    }

    onSelectAll = () => {
        this.injected.publishToLiveStore.selectAllItems();
    }

    render() {
        const { releaseItemsToPromote, releaseItems, loaders: { init, publish } } = this.injected.publishToLiveStore;
        return (
            <div className={styles.PublishActionsContainer}>
                <HasPermissions permissions={userPermissionsTable.PublishActions.actions}>
                    <Button
                        type="primary"
                        block={true}
                        onClick={this.onSelectAll}
                        disabled={releaseItems.length === 0 || releaseItems.length === releaseItemsToPromote.length}
                    >
                        <FormattedMessage
                            id="PublishActions.selectAllLabel"
                            defaultMessage="Select all"
                        />
                    </Button>
                </HasPermissions>
                <HasPermissions permissions={userPermissionsTable.PublishActions.actions}>
                    <Button
                        type="primary"
                        block={true}
                        disabled={releaseItemsToPromote.length === 0}
                        onClick={this.onPublish}
                        loading={publish}
                    >
                        <FormattedMessage
                            id="PublishActions.publishLabel"
                            defaultMessage="publish"
                        />
                    </Button>
                </HasPermissions>
                <Button
                    block={true}
                    onClick={this.onRefresh}
                    loading={init}
                >
                    <FormattedMessage
                        id="PublishActions.refreshLabel"
                        defaultMessage="refresh"
                    />
                </Button>
            </div>
        );
    }
}

export default injectIntl(PublishActions);
