import { Icon } from 'antd';
import QuillEditor from 'quill';
import { useEffect, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

export const Editor = ({id, value, onChange, onClickImage, placeholder, onInit}: {id: string, value?: string; placeholder?: string; onChange: (value: string) => void; onClickImage: () => void; onInit: (editor: QuillEditor) => void;}) => {
  const editor = useRef<any>(null);
  useEffect(() => {
    if(editor.current && editor.current.editor) {
        onInit(editor.current.editor);
    }
  }, [onInit]);
  const modules = editorConfig(id);
  return (
    <div className="text-editor">
      <QuillToolbar id={id} editorRef={editor.current} onClickImage={onClickImage} />
      <ReactQuill
        ref={editor}
        style={{maxHeight: 800, overflow: 'auto'}}
        theme="snow"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default Editor;

const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

const ChefImageButton = ({onClick}: {onClick: () => void;}) => {
    
    return (
        <button onClick={onClick} className='ql-chefimage'>
            <Icon type="folder-open" theme="filled" />
        </button>
    );
};

function undoChange(this: any) {
    this.quill.history.undo();
  }
function redoChange(this: any) {
    this.quill.history.redo();
}

export const Parchment = Quill.import("parchment")
const pixelLevels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
const TAB_MULTIPLIER = 30

class IndentAttributor extends (Parchment.Attributor.Style as { 
  new(formatName: any, styleProperty: any, attributorOptions: any): any;}) {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(formatName: any, styleProperty: any, attributorOptions: any) {
    super(formatName, styleProperty, attributorOptions)
  }

  public add(node: HTMLElement, value: string): boolean {
    return super.add(node, `${+value * TAB_MULTIPLIER}px`)
  }

  public value(node: HTMLElement): number | undefined {
    return parseFloat(super.value(node)) / TAB_MULTIPLIER || undefined // Don't return NaN
  }
}

const IndentStyle = new IndentAttributor("indent", "margin-left", {
  scope: Parchment.Scope.BLOCK,
  whitelist: pixelLevels.map(value => `${value * TAB_MULTIPLIER}px`),
});

var DirectionAttribute = Quill.import('attributors/attribute/direction');
Quill.register(DirectionAttribute, true);
var AlignClass = Quill.import('attributors/class/align');
Quill.register(AlignClass, true);
var BackgroundClass = Quill.import('attributors/class/background');
Quill.register(BackgroundClass, true);
var ColorClass = Quill.import('attributors/class/color');
Quill.register(ColorClass, true);
var DirectionClass = Quill.import('attributors/class/direction');
Quill.register(DirectionClass, true);
var FontClass = Quill.import('attributors/class/font');
Quill.register(FontClass, true);
var SizeClass = Quill.import('attributors/class/size');
Quill.register(SizeClass, true);
var AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);
var BackgroundStyle = Quill.import('attributors/style/background');
Quill.register(BackgroundStyle, true);
var ColorStyle = Quill.import('attributors/style/color');
Quill.register(ColorStyle, true);
var DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);
var FontStyle = Quill.import('attributors/style/font');
Quill.register(FontStyle, true);
var SizeStyle = Quill.import('attributors/style/size');
Quill.register(SizeStyle, true);
Quill.register({ "formats/indent": IndentStyle }, true)
// Modules object for setting up the Quill editor
function editorConfig(id: string) {
  return {
    toolbar: {
      container: "#toolbar-" + id.replace(/\[|\]|\$|\./g, '-'),
      handlers: {
        undo: undoChange,
        redo: redoChange
      }
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true
    }
  } 
}

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "color",
  "code-block",
  "image"
];

// Quill Toolbar component
export const QuillToolbar = ({id, editorRef, onClickImage}: {id: string, editorRef: typeof QuillEditor; onClickImage: () => void;}) => (
  <div id={`toolbar-${id.replace(/\[|\]|\$|\./g, '-')}`}>
    <span className="ql-formats">
      <select className="ql-font" defaultValue="arial">
      </select>
      <select className="ql-header" defaultValue="3">
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
    </span>
    <span className="ql-formats">
      <button className="ql-script" value="super" />
      <button className="ql-script" value="sub" />
      <button className="ql-blockquote" />
      <button className="ql-direction" />
    </span>
    <span className="ql-formats">
      <select className="ql-align" />
      <select className="ql-color" />
      <select className="ql-background" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
      <ChefImageButton onClick={onClickImage} />
    </span>
    <span className="ql-formats">
      <button className="ql-code-block" />
      <button className="ql-clean" />
    </span>
    <span className="ql-formats">
      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-redo">
        <CustomRedo />
      </button>
    </span>
  </div>
);
