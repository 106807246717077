import { Form, Radio } from 'antd';
import React from 'react';
import Typography from '../../../Typography';
import styles from './index.module.scss';
import Segment from '../../../Segment/Segment';
import {
    withPermissionsToInteract,
    WithPermissionsToInteractReturnType
} from '../../../../providers/UserPermissionsProvider';

const RadioGroup = Radio.Group;

export interface RadioSelectProps extends WithPermissionsToInteractReturnType {
    attributeName: React.ReactNode;
    label?: React.ReactNode;
    items: {
        title?: React.ReactNode;
        helper?: React.ReactNode;
        value: string | number;
    }[];
    value: string | number;
    hasErrors: boolean;
    errors: string[];
    onChange(value: string | number): any;
}

const getRadioOption = (key: string, value: string | number, title: React.ReactNode, helper: React.ReactNode) => (
    <Radio key={key} value={value} className={styles.RadioWrapper}>
        <Typography variant="label" underline={true}>
            {title}
        </Typography>
        <Typography variant="caption" className={styles.HelperWrapper}>
            {helper}
        </Typography>
    </Radio>
);

function RadioSelect(props: RadioSelectProps) {
    return (
        <Segment
            divider={true}
            titleVariant="label"
            title={props.attributeName}
        >
            <Form.Item
                help={props.hasErrors ? props.errors.join(', ') : undefined}
                validateStatus={props.hasErrors ? 'error' : undefined}
                style={{ width: '100%' }}
                {...props.label ? { colon: false, label: props.label } : {}}
            >
                <RadioGroup
                    disabled={!props.hasPermissions}
                    onChange={(e) => props.onChange(e.target.value as string | number)}
                    value={props.value === undefined ? false : props.value}
                >
                    {props.items.map((item, index) => {
                        return getRadioOption(
                            `${index}_${item.value}`,
                            item.value,
                            item.title,
                            item.helper
                        );
                    })}
                </RadioGroup>
            </Form.Item>
        </Segment>
    );
}

export default withPermissionsToInteract(RadioSelect);
