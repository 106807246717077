import React from 'react';
import Number from '../Fields/Number';
import Segment from '../../../Segment/Segment';
import { Form } from 'antd';
import
withDefinitionErrors,
{
    WithDefinitionErrorsReturnProps
} from '../../../../hoc/withDefinitionErrors/withDefinitionErrors';
import { InjectedIntlProps, injectIntl } from 'react-intl';
const FormItem = Form.Item;

export type MinMaxKey = 'min' | 'max';

export interface MinMaxProps extends WithDefinitionErrorsReturnProps, InjectedIntlProps {
    attributeLabel: React.ReactNode;
    minTitle: React.ReactNode;
    minHelper: React.ReactNode;
    maxTitle: React.ReactNode;
    maxHelper: React.ReactNode;
    valueMin?: number;
    valueMax?: number;
    onChange(key: MinMaxKey, value: number): any;
}

function createHandler<T extends (arg: MinMaxKey, value: number) => any>(key: MinMaxKey, onChangeFn: T) {
    return (value: number) => onChangeFn(key as MinMaxKey, value);
}

function MinMax(props: MinMaxProps) {
    return (
        <Segment
            divider={true}
            titleVariant="label"
            title={props.attributeLabel}
        >
            <FormItem
                help={props.hasErrors ? props.errors.map(e => props.intl.formatMessage(e)).join(', ') : undefined}
                validateStatus={props.hasErrors ? 'error' : undefined}
                style={{ width: '100%' }}
            >
                <Number
                    onChange={createHandler('min', props.onChange)}
                    maxValue={props.valueMax}
                    value={props.valueMin}
                    title={props.minTitle}
                    helper={props.minHelper}
                />
                <Number
                    onChange={createHandler('max', props.onChange)}
                    minValue={props.valueMin}
                    value={props.valueMax}
                    title={props.maxTitle}
                    helper={props.maxHelper}
                />
            </FormItem>
        </Segment>
    );
}

export default withDefinitionErrors(injectIntl(MinMax));
