import { configure } from 'mobx';
import React, { Component, ComponentType } from 'react';
import { Router } from 'react-router-dom';
import { history } from '../../stores';
import { UserInfoProvider } from '../withUserInfo/withUserInfo';

configure({ enforceActions: 'observed' });

interface WithInitSettingsProps { }

const withInitSettings = (WrappedComponent: ComponentType) => {

    class WithInitSettings extends Component<WithInitSettingsProps> {
        render() {
            return (
                <UserInfoProvider>
                    <Router history={history}>
                        <WrappedComponent />
                    </Router>
                </UserInfoProvider>
            );
        }
    }

    return WithInitSettings;
};

export default withInitSettings;
