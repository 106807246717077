import {
    Field,
    SchemaTypeIds,
    SlugSchemaType,
} from '@contentchef/contentchef-types';

function slug(defaultLanguage: string, name: string): Field<SlugSchemaType> {
    return {
        name,
        type: SchemaTypeIds.SLUG,
        labels: {
            [defaultLanguage]: name
        },
        hint: undefined,
        placeholder: undefined,
        constraints: {
            required: false,
            prefix: undefined,
            suffix: undefined,
            allowSlash: false
        },
        compact: undefined
    };
}

export const slugSnippets = {
    asField: {
        slug,
    }
};
