import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FolderNavigationProps, InjectedProps } from './FolderNavigationModel';
import { Breadcrumb } from 'antd';
import Button from '@components/Button';
import { FormattedMessage } from 'react-intl';
import { FolderCrumb } from '..';
import styles from './FolderNavigation.module.scss';
import { withMediaNavigation, MediaNavigationContext } from '../../providers/MediaNavigationProvider';

@inject('mediaGalleryFlowStore')
@observer
class FolderNavigation extends Component<FolderNavigationProps & MediaNavigationContext> {
    get injected() {
        return this.props as InjectedProps;
    }

    onClickMediaGalleryRoot = async () => {
        if (this.props.onClickToFolder) {
            this.props.onClickToFolder();
        }
        await this.injected.mediaGalleryFlowStore.reinitializeMediaAndFolders();
    }

    render() {
        const mediaGalleryName = this.injected.mediaGalleryFlowStore.mediaGallery.name;
        const { folderPath } = this.injected.mediaGalleryFlowStore;
        const { prevStep } = this.props;
        return (
            <Breadcrumb className={styles.FolderNavigationContainer}>
                <Breadcrumb.Item>
                    <Button type="primary" onClick={prevStep}>
                        <FormattedMessage
                            id="FolderNavigation.BackToGalleryList"
                            defaultMessage="Media Galleries"
                        />
                    </Button>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Button type="primary" onClick={this.onClickMediaGalleryRoot}>
                        {mediaGalleryName}
                    </Button>
                </Breadcrumb.Item>
                {folderPath.split('/').map((folder, index) => {
                    return (
                        <Breadcrumb.Item key={index}>
                            <FolderCrumb
                                folderDepth={index}
                                folderName={folder}
                            />
                        </Breadcrumb.Item>
                    );
                })}
            </Breadcrumb>
        );
    }
}

export default withMediaNavigation(FolderNavigation);
