import React, { Component } from 'react';
import {
    ContentListByRepositoriesProps,
    ContentListByRepositoriesState,
    InjectedProps
} from './ContentListByRepositoriesModel';
import { ContentListUrlParams } from '../../stores/contentListStore/contentListStoreModel';
import { complexStringToArrayOfIds } from '../../services/urlSearchParamsHelper';
import { FilterByRepositories, FilterModal } from '../index';
import { inject } from 'mobx-react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FormattedMessage } from 'react-intl';

@inject('contentListStore', 'contentListFiltersStore')
class ContentListByRepositories extends Component<ContentListByRepositoriesProps, ContentListByRepositoriesState> {

    state: ContentListByRepositoriesState = {
        isLoading: true,
        filtersToApply: []
    };

    get injected() {
        return this.props as InjectedProps;
    }

    componentDidMount = async () => {
        await this.injected.contentListFiltersStore.setRepositoriesOption();
        this.setState({ isLoading: false });
    }

    onConfirmAppliedFilters = () => {
        this.props.onApplyFilters(this.state.filtersToApply);
        this.setState({ filtersToApply: [] });
    }

    onLoadMoreRepositories = async () => {
        this.setState({ isLoading: true });
        await this.injected.contentListFiltersStore.setMoreRepositoriesOptions();
        this.setState({ isLoading: false });
    }

    onChangeSelectedRepository = (event: CheckboxChangeEvent) => {
        const { checked, id } = event.target;
        if (id) {
            if (checked) {
                this.setState({ filtersToApply: this.state.filtersToApply.concat(+id) });
            } else {
                this.setState({
                    filtersToApply: this.state.filtersToApply.filter(
                        appliedFilerId => +id !== appliedFilerId
                    )
                });
            }
        } else {
            console.error('Missing required attribute id in checkbox');
        }
    }

    render() {
        const { contentListStore: { searchParams: { usableSearchParams } } } = this.injected;
        const { pagedRepositoryListOptions: { total, listOptions } } = this.injected.contentListFiltersStore;
        return (
            <FilterModal
                linkLabel={<FormattedMessage
                    id={'ContentListByRepositories.CTALabel'}
                    defaultMessage={'Filter by repositories'}
                />}
                onModalOkCallback={this.onConfirmAppliedFilters}
            >
                <FilterByRepositories
                    isLoading={this.state.isLoading}
                    onLoadMore={this.onLoadMoreRepositories}
                    showMore={total > listOptions.length}
                    listRepository={listOptions}
                    defaultValue={(
                        usableSearchParams[ContentListUrlParams.repositoryId]
                        && complexStringToArrayOfIds(usableSearchParams[ContentListUrlParams.repositoryId])
                    ) || undefined}
                    onChange={this.onChangeSelectedRepository}
                />
            </FilterModal>
        );
    }
}

export default ContentListByRepositories;
