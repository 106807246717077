import moment from 'moment';
import { defaultFormatDate } from './FormHelpers';

export const formNormalizers = {
    'date': (newValue: moment.Moment | undefined) => {
        return defaultFormatDate(newValue);
    },
    'onlineRange': (onlineRange: {onlineDate: moment.Moment | undefined, offlineDate: moment.Moment | undefined}) => {
        const formatOnlineRange: any = {};
        if (onlineRange) {

            if (onlineRange.onlineDate) {
                const formattedDate = defaultFormatDate(onlineRange.onlineDate);
                formatOnlineRange.onlineDate = formattedDate;
            }

            if (onlineRange.offlineDate) {
                const formattedDate = defaultFormatDate(onlineRange.offlineDate);
                formatOnlineRange.offlineDate = formattedDate;
            }
        }
        return onlineRange ? formatOnlineRange : onlineRange;
    },
    'json': (value: string | object) => {
        if (typeof value === 'object') {
            return value;
        }
        try {
            return JSON.parse(value);
        } catch (e) {
            return {};
        }
    },
    emptyStringToUndefined: (value: string) => {
        if (!value) {
            return undefined;
        }
        return value;
    }
};

export const formFieldNormalize = (fieldType: keyof typeof formNormalizers) => (formNormalizers[fieldType]);
