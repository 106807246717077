import React, { ChangeEvent, Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { observer } from 'mobx-react';
import { List, Checkbox, Input } from 'antd';
import { FilterByContentDefinitionsProps, FilterByContentDefinitionsState } from './FilterByContentDefinitionsModel';
import { DefinitionOption } from '../../models/UI';
import { debounce } from '../../services/utils';

import styles from './FilterByContentDefinitions.module.scss';

const Search = Input.Search;
const ListItem = List.Item;

const FilterByContentDefinitionsLabels = defineMessages({
    SearchPlaceholder: {
        id: 'FilterByContentDefinitions.SearchPlaceholder',
        defaultMessage: 'Find a definition'
    },
    ListLoadMoreLink: {
        id: 'FilterByContentDefinitions.LoadMoreLink',
        defaultMessage: 'Load more'
    }
});

@observer
class FilterByContentDefinitions extends Component<FilterByContentDefinitionsProps, FilterByContentDefinitionsState> {
    debounceOnSearch: (value?: string) => void;

    constructor(props: FilterByContentDefinitionsProps) {
        super(props);
        this.debounceOnSearch = debounce(this.props.onSearch);
    }

    onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        this.debounceOnSearch(event.target.value);
    }

    render() {
        const {
            isLoading,
            showMore,
            listDefinitions,
            defaultValue,
            onChange,
            onLoadMore,
            intl: { formatMessage } } = this.props;
        const filteredDefinitions = defaultValue
            ? listDefinitions.filter(definition => !defaultValue.find(value => value === definition.id))
            : listDefinitions;
        return (
            <List
                className={styles.FilterByContentDefinitionListWrapper}
                header={<Search
                    placeholder={formatMessage(FilterByContentDefinitionsLabels.SearchPlaceholder)}
                    onChange={this.onSearch}
                />}
                loading={isLoading}
                loadMore={showMore
                    ? <a onClick={onLoadMore}>{formatMessage(FilterByContentDefinitionsLabels.ListLoadMoreLink)}</a>
                    : null
                }
                dataSource={filteredDefinitions}
                renderItem={(item: DefinitionOption) => (
                    <ListItem key={item.id}>
                        <Checkbox
                            id={item.id.toString()}
                            className={styles.FilterByContentDefinitionCheckboxWrapper}
                            onChange={onChange}
                        >
                            <span>{item.name} ({item.mnemonicId})</span>
                        </Checkbox>
                    </ListItem>
                )}
            />
        );
    }
}

export default injectIntl(FilterByContentDefinitions);
