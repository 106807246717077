import React from 'react';
import { SchemaTypeIds } from '@contentchef/contentchef-types';
import {
    DynamicFieldTypes,
    FieldCloudinaryVideo
} from '../FormFields';
import { fieldMatchersUtils } from './FieldMatchersUtils';
import { FieldMatcher } from './FormFieldMatchersFactory';
import { CloudinaryVideoField } from '../../../components';
import { defaultFieldWrapper } from './DefaultFieldWrapper';

export class CloudinaryVideoTypeMatcher implements FieldMatcher {
    maybeCreateComponent(
        id: string, userLocale: string, onChange: (e: any) => void, field: DynamicFieldTypes
    ): JSX.Element | null {
        if (!fieldMatchersUtils.typeMatcher(field, SchemaTypeIds.CLOUDINARY_VIDEO)) { return null; }
        const fieldCloudinaryVideo = field as FieldCloudinaryVideo;

        function elementToRender(disabled: boolean) {
            return (
                <CloudinaryVideoField
                    id={id}
                    disableActions={disabled || field.disabled}
                    fieldData={fieldCloudinaryVideo as FieldCloudinaryVideo}
                    onChange={onChange}
                />
            );
        }

        return defaultFieldWrapper(elementToRender);
    }
}
