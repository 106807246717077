import { Checkbox, Input, List } from 'antd';
import { observer } from 'mobx-react';
import React, { ChangeEvent, Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { debounce } from '../../services/utils';
import styles from './FilterContentsByTags.module.scss';
import { FilterContentsByTagsProps, FilterContentsByTagsState } from './FilterContentsByTagsModel';

const ListItem = List.Item;
const Search = Input.Search;


const FilterContentsByTagsLabels = defineMessages({
    SearchPlaceholder: {
        id: 'FilterContentsByTags.SearchPlaceholder',
        defaultMessage: 'Find a tag'
    }
});

@observer
class FilterContentsByTags extends Component<FilterContentsByTagsProps, FilterContentsByTagsState> {
    debounceOnSearch: (value?: string) => void;

    constructor(props: FilterContentsByTagsProps) {
        super(props);
        this.debounceOnSearch = debounce(this.props.onSearch);
    }

    onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        this.debounceOnSearch(event.target.value);
    }

    render() {
        const { isLoading, listTags, defaultValue, onChange, intl: { formatMessage } } = this.props;
        const filteredTags = defaultValue
            ? listTags.filter(tag => !defaultValue.find(value => value === tag))
            : listTags;
        return (
            <List
                className={styles.FilterContentsByTagsListWrapper}
                header={<Search
                    placeholder={formatMessage(FilterContentsByTagsLabels.SearchPlaceholder)}
                    onChange={this.onSearch}
                />}
                loading={isLoading}
                dataSource={filteredTags}
                renderItem={(tag: string) => (
                    <ListItem key={tag}>
                        <Checkbox
                            id={tag}
                            className={styles.FilterContentsByTagsCheckboxWrapper}
                            onChange={onChange}
                        >
                            {tag}
                        </Checkbox>
                    </ListItem>
                )}
            />
        );
    }
}

export default injectIntl(FilterContentsByTags);
