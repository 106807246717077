import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { PublishingChannelListItemActionsProps } from './PublishingChannelListItemActionsModel';
import { defineMessages } from 'react-intl';
import { editPublishingChannelPath } from '../../constants/routing-constants';
import { withPermissionsToInteract } from '../../providers/UserPermissionsProvider';
import ActionList, { ActionDef } from '../atoms/ActionList';

const labels = defineMessages({
    edit: {
        id: 'PublishingChannelListItemActions.Edit',
        defaultMessage: 'Edit'
    },
    view: {
        id: 'PublishingChannelListItemActions.View',
        defaultMessage: 'View'
    }
});

class PublishingChannelListItemActions extends Component<PublishingChannelListItemActionsProps, {}> {

    onEditChannel = () => {
        const { match: { params: { spaceId } }, channelId } = this.props;
        return editPublishingChannelPath(spaceId, channelId);
    }

    render() {
        const { hasPermissions } = this.props;

        const viewEditAction = {
            linkHandler: this.onEditChannel,
            title: hasPermissions ? labels.edit : labels.view,
            icon: 'edit',
            type: 'primary',
        };

        const actions = [
            viewEditAction,
        ] as ActionDef[];

        return (
            <ActionList actions={actions} />
        );

    }
}

export default withPermissionsToInteract(withRouter(PublishingChannelListItemActions));
