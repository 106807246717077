import {
    CloudinaryVideoSchemaType,
    Field,
    SchemaTypeIds
} from '@contentchef/contentchef-types';

function transformableVideoReference(
    defaultLanguage: string, name: string
): Field<CloudinaryVideoSchemaType> {
    return {
        name,
        type: SchemaTypeIds.CLOUDINARY_VIDEO,
        labels: {
            [defaultLanguage]: name
        },
        placeholder: undefined,
        hint: {},
        constraints: {
            required: false
        },
        compact: undefined
    };
}

function transformableVideoReferenceInArray(): CloudinaryVideoSchemaType {
    return {
        type: SchemaTypeIds.CLOUDINARY_VIDEO,
        translatable: undefined,
        constraints: {}
    };
}

export const clodinaryVideoSnippets = {
    asField: {
        transformableVideoReference
    },
    asArray: {
        transformableVideoReferenceInArray
    }
};
