import { action, computed, observable } from 'mobx';
import { LoaderManagerStoreModel } from './loaderManagerStoreModel';

class LoaderManagerStore implements LoaderManagerStoreModel {
    @observable showLoader?: boolean = undefined;

    @action
    setLoader() {
        this.showLoader = true;
    }

    @action
    unsetLoader() {
        this.showLoader = false;
    }

    @computed
    get mustShowLoader(): boolean {
        return this.showLoader === undefined || this.showLoader;
    }
}

export default LoaderManagerStore;
