import React, { Component } from 'react';
import { Provider, inject, observer } from 'mobx-react';
import {
    AddContentStore,
    ChannelsStore,
    ListPublicIdStore,
    ContentStore,
    ContentListStore,
    formMetaStore,
    MediaGalleryFlowStore,
    notificationStore,
    ContentDefinitionStore,
    ContentTagStore,
    RepositoryListStore,
    MediaTagsStore
} from '../../stores';
import { WithStoresComponentProps, WithStoresWrappedComponent } from '../../constants/common-types';
import { AddContentStoreModel } from '../../stores/addContentStore/addContentStoreModel';
import { ChannelsStoreModel } from '../../stores/channelsStore/channelsStoreModel';
import { ContentStoreModel } from '../../stores/contentStore/contentStoreModel';
import { ListPublicIdStoreModel } from '../../stores/listPublicIdStore/listPublicIdStoreModel';
import { ContentDefinitionStoreModel } from '../../stores/contentDefinitionStore/contentDefinitionStoreModel';
import { ContentListStoreModel } from '../../stores/contentListStore/contentListStoreModel';
import { MediaGalleryFlowStoreModel } from '../../stores/mediaGalleryFlowStore/mediaGalleryFlowStoreModel';
import { FormMetaStoreModel } from '../../stores/formMetaStore/formMetaStoreModel';
import { NotificationStoreModel } from '../../stores/notificationStore/notificationStoreModel';
import { ContentTagStoreModel } from '../../stores/contentTagStore/contentTagStoreModel';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';
import { RepositoryListStoreModel } from '../../stores/repositoryListStore/repositoryListStoreModel';
import { MediaTagsStoreModel } from '../../stores/mediaTagsStore/mediaTagsStoreModel';
import { ExtensionManager } from '@stores/extensionManager';

type InjectedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
};

const withContentCreationStores =
    <T extends {}>(WrappedComponent: WithStoresWrappedComponent<T>) => {
        @inject('apiStore')
        @observer
        class WithContentCreationStores extends Component<WithStoresComponentProps<T>> {
            get injected() {
                return this.props as InjectedProps<T>;
            }
            addContentStore: AddContentStoreModel;
            repositoryListStore: RepositoryListStoreModel;
            channelsStore: ChannelsStoreModel;
            contentStore: ContentStoreModel;
            listPublicIdStore: ListPublicIdStoreModel;
            contentDefinitionStore: ContentDefinitionStoreModel;
            contentListStore: ContentListStoreModel;
            mediaGalleryFlowStore: MediaGalleryFlowStoreModel;
            formMetaStore: FormMetaStoreModel;
            notificationStore: NotificationStoreModel;
            contentTagStore: ContentTagStoreModel;
            mediaTagsStore: MediaTagsStoreModel;
            extensionManager: ExtensionManager;

            constructor(props: WithStoresComponentProps<T>) {
                super(props);

                const { api } = this.injected.apiStore;

                this.repositoryListStore = new RepositoryListStore(api);
                this.channelsStore = new ChannelsStore(api);
                this.listPublicIdStore = new ListPublicIdStore(api);
                this.contentDefinitionStore = new ContentDefinitionStore(api);
                this.contentStore = new ContentStore(api);
                this.contentListStore = new ContentListStore(api);
                this.mediaGalleryFlowStore = new MediaGalleryFlowStore(
                    api, this.injected.apiStore.currentSpaceId as string, true);
                this.mediaTagsStore = new MediaTagsStore(api);

                this.formMetaStore = formMetaStore;
                this.notificationStore = notificationStore;
                this.extensionManager = new ExtensionManager(api);
                this.addContentStore = new AddContentStore(api, {
                    repositoryListStore: this.repositoryListStore,
                    channelsStore: this.channelsStore,
                    listPublicIdStore: this.listPublicIdStore,
                    contentDefinitionStore: this.contentDefinitionStore,
                    contentStore: this.contentStore,
                    formMetaStore: this.formMetaStore,
                    extensionManager: this.extensionManager
                });
                this.contentTagStore = new ContentTagStore(api);

            }

            render() {
                return (
                    <Provider
                        addContentStore={this.addContentStore}
                        repositoryListStore={this.repositoryListStore}
                        channelsStore={this.channelsStore}
                        listPublicIdStore={this.listPublicIdStore}
                        contentStore={this.contentStore}
                        contentListStore={this.contentListStore}
                        formMetaStore={this.formMetaStore}
                        mediaGalleryFlowStore={this.mediaGalleryFlowStore}
                        notificationStore={this.notificationStore}
                        contentTagStore={this.contentTagStore}
                        mediaTagsStore={this.mediaTagsStore}
                        extensionManager={this.extensionManager}
                    >
                        <WrappedComponent {...this.props} />
                    </Provider>
                );
            }
        }

        return WithContentCreationStores;
    };

export default withContentCreationStores;
