import React, { Component } from 'react';
import { Modal } from 'antd';
import { ModalTitle, CloudinaryPreview, MediaStepper } from '..';
import {
    CloudinaryVideoFieldProps,
    CloudinaryVideoFieldState,
} from './CloudinaryVideoFieldModel';

import styles from './CloudinaryVideoField.module.scss';
import { CLOUDINARY_VIDEO } from '../../services/cloudinaryUtils';

class CloudinaryVideoField extends Component<CloudinaryVideoFieldProps, CloudinaryVideoFieldState> {

    constructor(props: CloudinaryVideoFieldProps) {
        super(props);

        this.state = {
            visible: false,
            publicId: this.props.fieldData.value ? this.props.fieldData.value.publicId : '',
            transformations: this.props.fieldData.value ? this.props.fieldData.value.transformations : '',
        };

        this.onConfirmSelection = this.onConfirmSelection.bind(this);
        this.toggleImageEditor = this.toggleImageEditor.bind(this);
        this.onReset = this.onReset.bind(this);

    }

    onConfirmSelection(publicId: string, transformations: string) {
        this.setState({
            publicId,
            transformations
        });
        const cloudinaryImageValue = {
            publicId,
            transformations
        };
        this.props.onChange(cloudinaryImageValue);
        this.toggleImageEditor();
    }

    toggleImageEditor() {
        const { visible } = this.state;
        if (!visible) {
            this.setState({
                publicId: this.props.fieldData.value ? this.props.fieldData.value.publicId : '',
                transformations: this.props.fieldData.value ? this.props.fieldData.value.transformations : '',
                visible: !visible
            });
        } else {
            this.setState({
                visible: !visible
            });
        }
    }

    onReset() {
        const resetImage = {
            publicId: '',
            transformations: ''
        };
        this.setState(resetImage);
        this.props.onChange(undefined);
    }

    render() {
        const { id, disableActions } = this.props;
        const { publicId, transformations } = this.state;
        const cloudinaryVideoValue = {
            publicId,
            transformations
        };

        return (
            <div id={id}>
                <CloudinaryPreview
                    type={CLOUDINARY_VIDEO}
                    disableActions={disableActions}
                    value={cloudinaryVideoValue}
                    toggleImageEditor={this.toggleImageEditor}
                    onReset={this.onReset}
                />
                <Modal
                    title={
                        <ModalTitle onCancel={this.toggleImageEditor}>
                            {this.props.fieldData.id}
                        </ModalTitle>}
                    closable={false}
                    visible={this.state.visible}
                    wrapClassName={styles.CloudinaryVideoModalPreview}
                    destroyOnClose={true}
                    footer={false}
                >
                    <MediaStepper
                        field={this.props.fieldData}
                        type={CLOUDINARY_VIDEO}
                        onConfirmSelection={this.onConfirmSelection}
                    />
                </Modal>
            </div>
        );
    }
}

export default CloudinaryVideoField;
