import {
    ListContentRepositoriesItem
} from '@contentchef/contentchef-types';
import React, { Component } from 'react';
import { ConfigProvider, Icon, List } from 'antd';
import { RepositoryListProps, RepositoryListState } from './RepositoryListModel';
import { RepositoryListItem } from '../index';
import CustomEmpty from '@components/CustomEmpty/CustomEmpty';
import Typography from '@components/Typography';
import { FormattedMessage } from 'react-intl';

import styles from './RepositoryList.module.scss';

class RepositoryList extends Component<RepositoryListProps, RepositoryListState> {
    render() {
        const { repositoryList, isLoading } = this.props;
        return (
            <div className={styles.RepositoryListContainer}>
                <ConfigProvider
                    renderEmpty={() => (<CustomEmpty
                        image={<Icon type="appstore" className={styles.RepositoryListEmptyIcon} />}
                        description={<Typography
                            variant="label"
                            className={styles.RepositoryListEmptyDescription}
                        >
                            <FormattedMessage
                                id="RepositoryList.EmptyList.Description"
                                defaultMessage="Repositories not found"
                            />
                        </Typography>}
                    />)}
                >
                    <List
                        dataSource={repositoryList}
                        loading={isLoading}
                        renderItem={(repository: ListContentRepositoriesItem) => (
                            <RepositoryListItem repository={repository} />
                        )}
                    />
                </ConfigProvider>
            </div>
        );
    }
}

export default RepositoryList;
