import React from 'react';
import ContainerInfoSection from '../ContainerInfoSection';
import { Badge, Button, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { contentDefinitionCustomFieldsListPath } from '../../constants/routing-constants';
import {
    ContentDefinitionSchemaStoreModel
} from '../../stores/ContentDefinitionEditorStore/ContentDefinitionSchemaStore';
import { inject, observer } from 'mobx-react';
import classes from './index.module.scss';

// tslint:disable-next-line:max-line-length
export interface ContentDefinitionEditCustomFieldsProps extends RouteComponentProps<{ spaceId: string, contentDefId?: string }> { }

interface DecoratedProps extends ContentDefinitionEditCustomFieldsProps {
    contentDefinitionSchemaStore: ContentDefinitionSchemaStoreModel;
}

function ContentDefinitionEditCustomFields(props: ContentDefinitionEditCustomFieldsProps) {
    const decoratedProps = props as DecoratedProps;
    const handleClick = () => props.history.push(
        contentDefinitionCustomFieldsListPath(props.match.params.spaceId, props.match.params.contentDefId)
    );

    return (
        <ContainerInfoSection>
            <Badge
                className={classes.BadgeDotStyle}
                dot={decoratedProps.contentDefinitionSchemaStore.customFieldsHasErrors}
            >
                <Button onClick={handleClick} type="dashed">
                    <Icon type="snippets" />
                    <FormattedMessage
                        defaultMessage="Custom fields"
                        id="components.Forms.CreateContentDefinitionForm.customFields"
                    />
                </Button>
            </Badge>
        </ContainerInfoSection>
    );
}

export default inject('contentDefinitionSchemaStore')(withRouter(observer(
    ContentDefinitionEditCustomFields,
)));
