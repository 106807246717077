import React, { Component } from 'react';
import { Button as AntdButton } from 'antd';
import {
    AnchorButtonProps as AntAnchorButtonProps,
    NativeButtonProps as AntNativeButtonProps
} from 'antd/lib/button/button';

export type ButtonProps = Pick<AntNativeButtonProps, Exclude<keyof AntNativeButtonProps, 'onClick'>> & {
    onClick?(): void;
} | Pick<AntAnchorButtonProps, Exclude<keyof AntAnchorButtonProps, 'onClick'>> & {
    onClick?(): void;
};

class Button extends Component<ButtonProps> {
    handleOnClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
        if (this.props.onClick) {
            this.props.onClick();
        }
    }
    render() {
        const { children, ...others } = this.props;
        return (
            <AntdButton
                {...others}
                {...this.props.onClick && { onClick: this.handleOnClick }}
            >
                {children}
            </AntdButton>
        );
    }
}

export default Button;
