import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import { ContentId, ContentStatus } from '@contentchef/contentchef-types';
import { action, observable, runInAction } from 'mobx';

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

class ContentStatusStore {
    @observable status: ContentStatus[] | null = null;
    @observable defaultStatus: ContentStatus | null = null;
    constructor(readonly api: ContentChefClient) { }

    @action.bound
    async getContentStatus(id: ContentId) {
        try {
            const result = await this.api.contents.getContentStatus({ id });
            return result;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    @action.bound
    async setContentStatus(id: ContentId) {
        try {
            const status = await this.getContentStatus(id);
            runInAction(() => {
                this.defaultStatus = this.getOriginalStatus(status);
                this.status = status;
            });
        } catch (error) {
            console.error(error);
        }
    }

    @action.bound
    async pollUntilStatusChanges(id: ContentId) {
        const defaultStatus = Object.assign({}, this.defaultStatus);
        let originalStatus = this.getOriginalStatus(await this.getContentStatus(id)) || {};
        let status = this.status;

        while (!originalStatus || defaultStatus.stageVersion === originalStatus.stageVersion) {
            await delay(1500);
            status = await this.getContentStatus(id);
            originalStatus = this.getOriginalStatus(status);
        }
        runInAction(() => {
            this.defaultStatus = originalStatus;
            this.status = status;
        });
    }

    private getOriginalStatus(status: ContentStatus[]) {
        return status.find(item => item.isTranslation === false || item.isTranslation === undefined)!;
    }
}

export default ContentStatusStore;
