import { ContentDefinitionId, ContentId, ContentRepositoryId, MediaGalleryId, PublishingChannelId } from '@contentchef/contentchef-types';

export const EDIT_URL_SUFFIX: string = 'edit';
export const NEW_URL_SUFFIX: string = 'new';

// ROOT
export const ROOT_ROUTE: string = '/';

export function basePath(spaceId: string) {
    return `/space/${spaceId}`;
}

// LOGIN ROUTE
export const LOGIN_ROUTE: string = '/login';

// REGISTRATION ROUTE
export const REGISTRATION_ROUTE: string = '/registration';

// COGNITO CALLBACK URL
export const COGNITO_CALLBACK_URL_ROUTE = '/cognito/verify';

export const ACCEPT_INVITE_ROUTE = '/api/invites/accept';

// CHOOSE_SPACE
export const CHOOSE_SPACE_ROUTE = '/choose-space';

// VERIFY EMAIL
export const VERIFY_EMAIL_ROUTE = '/verify-email/:emailToken';

// Settings
export const SETTINGS_BASED_ROUTE = '/settings';

// Settings/ACCESS TOKEN
export const ACCESS_TOKEN_ROUTE = '/settings/access-tokens';

// Settings/SUBSCRIPTION
export const SUBSCRIPTION_DETAIL_ROUTE = '/settings/subscriptions/:subscriptionId';

// Settings/INVITES
export const INVITES_ROUTE = '/settings/invites';

export function getSubscriptionDetailRoute(subscriptionId: string): string {
    return SUBSCRIPTION_DETAIL_ROUTE.replace(':subscriptionId', subscriptionId);
}

export const SUBSCRIPTION_MANAGEMENT_ROUTE = '/settings/subscriptions';

// Settings/PROFILE
export const PROFILE_ROUTE = '/settings/profile';

// Settings/PROFILE
export const PROFILE_SECURITY_ROUTE = '/settings/profile/security';

export const WEBHOOKS_ROUTE: string = '/space/:spaceId/webhooks';
export const WEBHOOKS_CREATE_ROUTE = `${WEBHOOKS_ROUTE}/new`;
export const WEBHOOKS_UPDATE_ROUTE = `${WEBHOOKS_ROUTE}/update/:webhookId`;

export function listWebhookPath(spaceId: string) {
    return WEBHOOKS_ROUTE.replace(':spaceId', spaceId);
}

export function createWebhookPath(spaceId: string) {
    return WEBHOOKS_CREATE_ROUTE.replace(':spaceId', spaceId);
}
export function viewWebhookPath(spaceId: string, webhookId: string) {
    return WEBHOOKS_UPDATE_ROUTE
        .replace(':spaceId', spaceId)
        .replace(':webhookId', webhookId);
}

export function createWebhooksListPath(spaceId: string) {
    return `${basePath(spaceId)}/webhooks`;
}

// Onboarding Wizard
export const ONBOARDING_UPDATE_CONSENTS_ROUTE = '/onboarding/update-consents';

// Onboarding Wizard
export const ONBOARDING_ACCEPT_INVITE = '/onboarding/accept-terms-and-conditions';

// Onboarding Wizard
export const ONBOARDING_WIZARD_ROUTE = '/onboarding/subscription-wizard';

export const SPACE_SELECTOR_ROUTE = '/choose-space';
// DASHBOARD
export const SPACE_ROUTE: string = '/space/:spaceId';
export function dashboardPath(spaceId: string) {
    return `${basePath(spaceId)}/`;
}

// NOT FOUND
export const NOT_FOUND_ROUTE: string = '/space/:spaceId/notFound/:type';
export function notFoundPath(spaceId: string, type: string) {
    return `${basePath(spaceId)}/notFound/${type}`;
}
export function contentNotFoundPath(spaceId: string) {
    return notFoundPath(spaceId, 'content');
}
export function definitionNotFoundPath(spaceId: string) {
    return notFoundPath(spaceId, 'content-definition');
}
export function publishingChannelNotFound(spaceId: string) {
    return notFoundPath(spaceId, 'publishing-channel');
}
export function repositoryNotFound(spaceId: string) {
    return notFoundPath(spaceId, 'repository');
}
export function mediaGalleryNotFound(spaceId: string) {
    return notFoundPath(spaceId, 'media-gallery');
}
export function webhookNotFound(spaceId: string) {
    return notFoundPath(spaceId, 'webhook');
}
export function extensionNotFound(spaceId: string) {
    return notFoundPath(spaceId, 'extension');
}

/* REPOSITORY PATHS */
export const REPOSITORY_ROUTE: string = '/space/:spaceId/repositories';
export const CREATE_REPOSITORY_ROUTE: string = '/space/:spaceId/repositories/new';
export const REPOSITORY_DASHBOARD_ROUTE: string = '/space/:spaceId/repositories/:repositoryId';
export const EDIT_REPOSITORY_ROUTE: string = '/space/:spaceId/repositories/:repositoryId/edit';

export function baseRepositoriesPath(spaceId: string) {
    return `${basePath(spaceId)}/repositories`;
}

export function createRepositoryPath(spaceId: string) {
    return `${baseRepositoriesPath(spaceId)}/new`;
}

export function repositoryDetailPath(spaceId: string, repositoryId: ContentRepositoryId) {
    return `${baseRepositoriesPath(spaceId)}/${repositoryId}`;
}

export function editRepositoryPath(spaceId: string, repositoryId: ContentRepositoryId) {
    return `${repositoryDetailPath(spaceId, repositoryId)}/edit`;
}

// ##################### CONTENT PATHS START
export const CONTENTS_ROUTE: string = '/space/:spaceId/contents';
export const CREATE_CONTENT_ROUTE: string = '/space/:spaceId/contents/new/:contentDefId';
export const CREATE_CONTENT_SELECT_DEFINITION_ROUTE: string = '/space/:spaceId/contents/new';
export const EDIT_CONTENT_ROUTE: string = '/space/:spaceId/contents/:contentId/edit';
export const CONTENT_TRANSLATION_EDIT: string = '/space/:spaceId/contents/:contentId/translation/:locale';

export function contentsBasePath(spaceId: string) {
    return `${basePath(spaceId)}/contents`;
}

export function createUsersPath(spaceId: string) {
    return `${basePath(spaceId)}/users-and-permissions`;
}

export function createContentPath(spaceId: string, contentDefId: ContentDefinitionId) {
    return `${contentSelectDefinitionPath(spaceId)}/${contentDefId}`;
}

export function contentSelectDefinitionPath(spaceId: string) {
    return `${contentsBasePath(spaceId)}/new`;
}

export function contentDetailPath(spaceId: string, contentId: ContentId) {
    return `${contentsBasePath(spaceId)}/${contentId}`;
}

export function editContentPath(spaceId: string, contentId: ContentId) {
    return `${contentDetailPath(spaceId, contentId)}/edit`;
}

export function contentTranslationPath(spaceId: string, contentId: ContentId, locale: string) {
    return `${contentDetailPath(spaceId, contentId)}/translation/${locale}`;
}

// ####################################### CONTENT PATHS END

// CONTENT DEFINITION PATHS START
export const CONTENT_DEFINITIONS_ROUTE: string = '/space/:spaceId/content-definitions';
export const NEW_CONTENT_DEFINITION: string = '/space/:spaceId/content-definitions/new';
export const NEW_CONTENT_DEFINITION_CUSTOM_FIELD_LIST: string = '/space/:spaceId/content-definitions/new/custom-fields';
// tslint:disable-next-line:max-line-length
export const NEW_CONTENT_DEFINITION_CUSTOM_FIELD_NEW: string = '/space/:spaceId/content-definitions/new/custom-fields/new';
// tslint:disable-next-line:max-line-length
export const NEW_CONTENT_DEFINITION_CUSTOM_FIELD_EDIT: string = '/space/:spaceId/content-definitions/new/custom-fields/:customFieldName';
export const EDIT_CONTENT_DEFINITION: string = '/space/:spaceId/content-definitions/:contentDefId/edit';
// tslint:disable-next-line:max-line-length
export const EDIT_CONTENT_DEFINITION_CUSTOM_FIELD_LIST: string = '/space/:spaceId/content-definitions/:contentDefId/edit/custom-fields';
// tslint:disable-next-line:max-line-length
export const EDIT_CONTENT_DEFINITION_CUSTOM_FIELD_NEW: string = '/space/:spaceId/content-definitions/:contentDefId/edit/custom-fields/new';
// tslint:disable-next-line:max-line-length
export const EDIT_CONTENT_DEFINITION_CUSTOM_FIELD_EDIT: string = '/space/:spaceId/content-definitions/:contentDefId/edit/custom-fields/:customFieldName';

export function contentDefinitionBasePath(spaceId: string) {
    return `${basePath(spaceId)}/content-definitions`;
}

export function createContentDefinitionPath(spaceId: string) {
    return `${contentDefinitionBasePath(spaceId)}/new`;
}

export function contentDefinitionCustomFieldsListPath(
    spaceId: string,
    contentDefId?: string,
) {
    return !!contentDefId
        ? EDIT_CONTENT_DEFINITION_CUSTOM_FIELD_LIST
            .replace(':spaceId', spaceId)
            .replace(':contentDefId', contentDefId)
        : NEW_CONTENT_DEFINITION_CUSTOM_FIELD_LIST
            .replace(':spaceId', spaceId);
}

export function contentDefinitionCustomFieldsListBackPath(spaceId: string, contentDefId?: string) {
    return !!contentDefId
        ? EDIT_CONTENT_DEFINITION
            .replace(':spaceId', spaceId)
            .replace(':contentDefId', contentDefId)
        : NEW_CONTENT_DEFINITION
            .replace(':spaceId', spaceId);
}

export function contentDefinitionCustomFieldNewPath(
    spaceId: string,
    contentDefId?: string
) {
    return !!contentDefId
        ? EDIT_CONTENT_DEFINITION_CUSTOM_FIELD_NEW
            .replace(':spaceId', spaceId)
            .replace(':contentDefId', contentDefId)
        : NEW_CONTENT_DEFINITION_CUSTOM_FIELD_NEW
            .replace(':spaceId', spaceId);
}

export function contentDefinitionCustomFieldsEditPath(
    spaceId: string,
    customFieldName: string,
    contentDefId?: string,
) {
    return !!contentDefId
        ? EDIT_CONTENT_DEFINITION_CUSTOM_FIELD_EDIT
            .replace(':spaceId', spaceId)
            .replace(':contentDefId', contentDefId)
            .replace(':customFieldName', customFieldName)
        : NEW_CONTENT_DEFINITION_CUSTOM_FIELD_EDIT
            .replace(':spaceId', spaceId)
            .replace(':customFieldName', customFieldName);
}

export function contentDefinitionDetailPath(spaceId: string, contentDefId: ContentDefinitionId) {
    return `${contentDefinitionBasePath(spaceId)}/${contentDefId}`;
}

export function editContentDefinitionPath(spaceId: string, contentDefId: ContentDefinitionId) {
    return `${contentDefinitionDetailPath(spaceId, contentDefId)}/edit`;
}

// CONTENT DEFINITION PATHS END

// MEDIA-GALLERIES PATHS START
export const MEDIA_GALLERIES_ROUTE: string = '/space/:spaceId/media-galleries';
export const MEDIA_GALLERY_DETAIL: string = '/space/:spaceId/media-galleries/:mediaGalleryId';
export const NEW_MEDIA_GALLERY: string = '/space/:spaceId/media-galleries/new';
export const EDIT_MEDIA_GALLERY: string = '/space/:spaceId/media-galleries/:mediaGalleryId/edit';
export const MEDIA_GALLERY_BROWSE: string = '/space/:spaceId/media-galleries/:mediaGalleryId/browse';
export const MEDIA_GALLERY_BROWSE_WITH_FOLDER_PATH: string =
    '/space/:spaceId/media-galleries/:mediaGalleryId/browse/:folderPath+';
export const MEDIA_GALLERY_NEW_MEDIA: string = '/space/:spaceId/media-galleries/:mediaGalleryId/browse/new';
export const MEDIA_GALLERY_NEW_MEDIA_WITH_FOLDER_PATH: string =
    '/space/:spaceId/media-galleries/:mediaGalleryId/browse/:folderPath+/new';

export function mediaGalleryBasePath(spaceId: string) {
    return `${basePath(spaceId)}/media-galleries`;
}

export function mediaGalleryDetailPath(spaceId: string, mediaGalleryId: MediaGalleryId) {
    return `${mediaGalleryBasePath(spaceId)}/${mediaGalleryId}`;
}

export function createMediaGalleryPath(spaceId: string) {
    return `${mediaGalleryBasePath(spaceId)}/new`;
}

export function editMediaGalleryPath(spaceId: string, mediaGalleryId: MediaGalleryId) {
    return `${mediaGalleryDetailPath(spaceId, mediaGalleryId)}/edit`;
}

export function browseMediaGalleryPath(spaceId: string, mediaGalleryId: MediaGalleryId) {
    return `${mediaGalleryDetailPath(spaceId, mediaGalleryId)}/browse`;
}

export function browseMediaGalleryPathWithFolderPath(
    spaceId: string, mediaGalleryId: MediaGalleryId, folderPath?: string
) {
    if (!folderPath) {
        return browseMediaGalleryPath(spaceId, mediaGalleryId);
    }

    return `${mediaGalleryDetailPath(spaceId, mediaGalleryId)}/browse/${folderPath}`;
}

export function uploadMediaPath(spaceId: string, mediaGalleryId: MediaGalleryId) {
    return `${browseMediaGalleryPath(spaceId, mediaGalleryId)}/new`;
}

export function uploadMediaPathWithFolderPath(spaceId: string, mediaGalleryId: MediaGalleryId, folderPath?: string) {
    if (!folderPath) {
        return uploadMediaPath(spaceId, mediaGalleryId);
    }
    return `${browseMediaGalleryPathWithFolderPath(spaceId, mediaGalleryId, folderPath)}/new`;
}

// MEDIA-GALLERIES PATHS END

// PUBLISHING_CHANNELS
export const PUBLISHING_CHANNELS_ROUTE: string = '/space/:spaceId/publishing-channels';
export const NEW_PUBLISHING_CHANNEL = '/space/:spaceId/publishing-channels/new';
export const EDIT_PUBLISHING_CHANNEL = '/space/:spaceId/publishing-channels/:publishingChannelId/edit';

export function publishingChannelBasePath(spaceId: string) {
    return `${basePath(spaceId)}/publishing-channels`;
}

export function publishingChannelDetailPath(spaceId: string, channelId: PublishingChannelId) {
    return `${publishingChannelBasePath(spaceId)}/${channelId}`;
}

export function createPublishingChannelPath(spaceId: string) {
    return `${publishingChannelBasePath(spaceId)}/new`;
}

export function editPublishingChannelPath(spaceId: string, channelId: PublishingChannelId) {
    return `${publishingChannelDetailPath(spaceId, channelId)}/edit`;
}

// PUBLISH
export const PUBLISH_ROUTE = '/space/:spaceId/publishtolive';

export function publishToLiveBasePath(spaceId: string) {
    return `${basePath(spaceId)}/publishtolive`;
}

// USERS
export const USERS_ROUTE = '/space/:spaceId/users-and-permissions';

// SUBSCRIPTION
export const ROOT_SUBSCRIPTION_PATH = '/subscriptions';
export const SUBSCRIPTION_DETAIL_PATH = '/subscriptions/:subscriptionId';
export const SUBSCRIPTION_UPGRADE_PLAN_PATH = '/subscriptions/:subscriptionId/:planId/upgrade';

export const subscriptionDetailPath = (subscriptionId: string) => {
    return `${ROOT_SUBSCRIPTION_PATH}/${subscriptionId}`;
};

export const subscriptionPlanPath = (subscriptionId: string, planId: number | string) => {
    return `${subscriptionDetailPath(subscriptionId)}/${planId}`;
};

export const subscriptionUpgradeToPlanPath = (subscriptionId: string, planId: number | string) => {
    return `${subscriptionPlanPath(subscriptionId, planId)}/upgrade`;
};

// Space Api Keys

export const API_KEYS_ROUTE = '/space/:spaceId/api-keys';

export const apiKeysPath = (spaceId: string) => {
    return `${basePath(spaceId)}/api-keys`;
};

// Extensions

export const EXTENSIONS_ROUTE = '/space/:spaceId/extensions';
export const CREATE_EXTENSION_ROUTE = `${EXTENSIONS_ROUTE}/new`;
export const EDIT_EXTENSION_ROUTE = `${EXTENSIONS_ROUTE}/:extensionId/edit`;

export const extensionsPath = (spaceId: string) => {
    return `${basePath(spaceId)}/extensions`;
};

export const extensionPath = (spaceId: string, id: string) => {
    return `${extensionsPath(spaceId)}/${id}`;
};

export const createExtensionPath = (spaceId: string) => {
    return `${extensionsPath(spaceId)}/new`;
};

export const editExtensionPath = (spaceId: string, id: string) => {
    return `${extensionPath(spaceId, id)}/edit`;
};

// Space Settings
export const SPACE_SETTINGS_ROUTE = '/space/:spaceId/space-settings';

export const spaceSettingsPath = (spaceId: string) => {
    return `${basePath(spaceId)}/space-settings`;
};
