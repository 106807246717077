import { ContentStatus as ContentStatusType } from '@contentchef/contentchef-types';
import { Badge, Icon, Popover, Table, Tag, Tooltip } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import { envRelatedLabels } from '../../constants/envinroment-related-labels';
import styles from './index.module.scss';

interface ContentStatusProps extends InjectedIntlProps {
    contentLocale: string;
    currentVersion: number;
    currentChannels: string[];
    status?: ContentStatusType[];
    className?: string;
}

const labels = defineMessages({
    contentLocaleTooltip: {
        id: 'ContentStatus.contentLocaleTooltip',
        defaultMessage: `Content's locale`
    },
    translationLocaleTooltip: {
        id: 'ContentStatus.translationLocaleTooltip',
        defaultMessage: `Translation's locale`
    },
    contentVersionTooltip: {
        id: 'ContentStatus.contentVersionTooltip',
        defaultMessage: `Content's version`
    },
    translationVersionTooltip: {
        id: 'ContentStatus.translationVersionTooltip',
        defaultMessage: `Translation's version`
    },
});

type StatusTableRow = {
    locale: React.ReactNode;
    contentVersion: React.ReactNode;
    translationVersion: React.ReactNode,
    channels: React.ReactNode;
    environment: React.ReactNode;
    metadata: {channels: string[], contentVersion: number, live: boolean, locale: string, stage: boolean, translationVersion?: number}
};

function ContentStatus(props: ContentStatusProps) {

    const { formatMessage } = props.intl;
    const cLocaleTooltipLabel = formatMessage(labels.contentLocaleTooltip);
    const tLocaleTooltipLabel = formatMessage(labels.translationLocaleTooltip);
    const cVersionTooltipLabel = formatMessage(labels.contentVersionTooltip);
    const tVersionTooltipLabel = formatMessage(labels.translationVersionTooltip);

    function renderLocale(locale: string, isTranslation: boolean = false) {
        return (
            <Tooltip placement="top" title={!isTranslation ? cLocaleTooltipLabel : tLocaleTooltipLabel}>
                <span style={!isTranslation ? { fontWeight: 'bold' } : {}}>{locale}</span>
            </Tooltip>
        );
    }

    function renderVersion(version?: number, isTranslation: boolean = false) {
        return (
            <Tooltip placement="top" title={!isTranslation ? cVersionTooltipLabel : tVersionTooltipLabel}>
                <span>{version ? `v${version}` : '-'}</span>
            </Tooltip>
        );
    }

    function renderChannels(channels: string[], moreThanOne: boolean) {

        const channelsToRender = channels.map(channel =>
            <Tag title={channel} color={'#00AEEF'} key={channel}>{channel}</Tag>);

        function renderWithBadge(channelToRender: JSX.Element, numberOfChannels: number) {
            if (numberOfChannels <= 1) {
                return channelToRender;
            }
            return (
                <Badge count={`+${numberOfChannels - 1}`}>
                    {channelToRender}
                </Badge>
            );
        }

        return (
            <div
                className={styles.StatusChannelsContainer}
            >
                <Popover
                    trigger="hover"
                    content={channelsToRender}
                    title={formatMessage(envRelatedLabels.channels)}
                    {...moreThanOne ? {} : { visible: false }}
                >
                    {renderWithBadge(channelsToRender[0], channelsToRender.length)}
                </Popover>
            </div>
        );
    }

    function renderStageLive(stage: boolean, live: boolean) {

        function renderCheckOrClose() {
            return stage || live ?
                <Icon type="check-circle" className={styles.SameVersion} /> :
                <Icon type="close-circle" className={styles.DifferentVersion} />;
        }

        function renderLabel() {
            const label: string[] = [];
            if (!stage && !live) {
                return (
                    <Popover
                        content={
                            formatMessage(envRelatedLabels.neverPublishedVersion)
                        }
                    >
                        {formatMessage(envRelatedLabels.shortNeverPublishedVersion)}
                    </Popover>
                );
            }
            if (stage) {
                label.push('Stage');
            }

            if (live) {
                label.push('Live');
            }

            return label.join(', ');
        }

        return (
            <div
                className={styles.EnvironmentContainer}
            >
                <p>{renderCheckOrClose()} {renderLabel()}</p>
            </div>
        );
    }

    function buildTableRows() {
        const { status, contentLocale, currentChannels, currentVersion } = props;

        if (!status || status.length === 0) {
            return [{
                locale: contentLocale,
                contentVersion: `v${currentVersion}`,
                translationVersion: '-',
                channels:
                    renderChannels(currentChannels, currentChannels.length > 1),
                environment: (
                    renderStageLive(false, false)
                ),
                metadata: {
                    channels: currentChannels,
                    contentVersion: currentVersion,
                    live: false,
                    locale: contentLocale,
                    stage: false,
                }
            }];
        }

        function versionAreTheSame(version1: number = 0, version2: number = 0) {
            return version1 && version2 && version1 === version2;
        }

        function versionAreNotTheSame(version1: number = 0, version2: number = 0) {
            return version1 && version2 && version1 !== version2;
        }

        function firstVersionExistsOtherDoesnt(version1: number = 0, version2: number = 0) {
            return version1 && version2 === 0;
        }

        function determineProperties(
            stageVersion: number,
            liveVersion: number,
            stageChannels: string[],
            liveChannels: string[],
            locale: string,
            isTranslation: boolean = false,
            stageTranslationVersion?: number,
            liveTranslationVersion?: number,
        ) {
            const stageVersionToUse = isTranslation ? stageTranslationVersion : stageVersion;
            const liveVersionToUse = isTranslation ? liveTranslationVersion : liveVersion;

            if (versionAreTheSame(stageVersionToUse, liveVersionToUse)) {
                return [{
                    locale,
                    contentVersion: stageVersion,
                    channels: stageChannels,
                    stage: true,
                    live: true,
                    ...{ ...isTranslation ? { translationVersion: stageVersionToUse } : {} }
                }];
            }
            if (versionAreNotTheSame(stageVersionToUse, liveVersionToUse)) {
                return [{
                    locale,
                    contentVersion: stageVersion,
                    channels: stageChannels,
                    stage: true,
                    live: false,
                    ...{ ...isTranslation ? { translationVersion: stageVersionToUse } : {} }
                }, {
                    locale,
                    contentVersion: liveVersion,
                    channels: liveChannels,
                    stage: false,
                    live: true,
                    ...{ ...isTranslation ? { translationVersion: liveVersionToUse } : {} }
                }];
            }
            if (firstVersionExistsOtherDoesnt(stageVersionToUse, liveVersionToUse)) {
                return [{
                    locale,
                    contentVersion: stageVersion,
                    channels: stageChannels,
                    stage: true,
                    live: false,
                    ...{ ...isTranslation ? { translationVersion: stageVersionToUse } : {} }
                }];
            }

            return [];
        }

        const rows = status.reduce(
            (acc, item) => {
                const stageContentVersion = item.isTranslation ? item.stageContentVersion! : item!.stageVersion!;
                const liveContentVersion = item.isTranslation ? item.liveContentVersion! : item!.liveVersion!;
                const liveChannels = item!.liveChannels!;
                const stageChannels = item!.stageChannels!;
                const stageTranslationVersion = item.isTranslation ? item.stageVersion! : undefined;
                const liveTranslationVersion = item.isTranslation ? item.liveVersion! : undefined;

                const propertiesToUse =
                    determineProperties(
                        stageContentVersion,
                        liveContentVersion,
                        stageChannels,
                        liveChannels,
                        item.contentLocale!,
                        item.isTranslation,
                        stageTranslationVersion,
                        liveTranslationVersion
                    );

                propertiesToUse.forEach(({ channels, contentVersion, live, locale, stage, translationVersion }) => {
                    acc.push({
                        locale: renderLocale(locale, item.isTranslation),
                        contentVersion: renderVersion(contentVersion, false),
                        translationVersion: renderVersion(translationVersion, item.isTranslation),
                        channels:
                            renderChannels(channels, channels.length > 1),
                        environment: (
                            renderStageLive(stage, live)
                        ),
                        metadata: {channels, contentVersion, live, locale, stage, translationVersion}
                    });
                });

                return acc;
            },
            [] as StatusTableRow[]
        );

        const originalStatus = status.find(item => item.isTranslation === false);
        if (
            originalStatus && (currentVersion !== originalStatus.stageVersion)
        ) {
            rows.unshift({
                locale: renderLocale(originalStatus.contentLocale!, false),
                contentVersion: renderVersion(currentVersion),
                translationVersion: renderVersion(),
                channels:
                    renderChannels(currentChannels, currentChannels.length > 1),
                environment: (
                    renderStageLive(false, false)
                ),
                metadata: {channels: currentChannels, contentVersion: currentVersion, live: false, locale: originalStatus.contentLocale!, stage: false}
            });
        }

        return rows;
    }

    const tableDataSource = buildTableRows();

    const tableClassName = classNames(styles.StatusTable, props.className);
    return (
        <Table
            scroll={{ y: true }}
            showHeader={true}
            className={tableClassName}
            pagination={false}
            size="small"
            rowKey={record => record.metadata ? `locale-${record.metadata.locale}_content-version-${record.metadata.contentVersion}${record.metadata.translationVersion ? `_translation-version-${record.metadata.translationVersion}`: ''}`: ''}
            columns={[
                {
                    title: formatMessage(envRelatedLabels.locale),
                    dataIndex: 'locale',
                    key: 'locale'
                },
                {
                    title: formatMessage(envRelatedLabels.contentVersion),
                    dataIndex: 'contentVersion',
                    key: 'contentVersion'
                },
                {
                    title: formatMessage(envRelatedLabels.translationVersion),
                    dataIndex: 'translationVersion',
                    key: 'translationVersion'
                },
                {
                    title: formatMessage(envRelatedLabels.channels),
                    dataIndex: 'channels',
                    key: 'channels'
                },
                {
                    title: formatMessage(envRelatedLabels.environment),
                    dataIndex: 'environment',
                    key: 'environment'
                }]
            }
            dataSource={tableDataSource}
        />
    );
}

export default injectIntl(ContentStatus);
