import React, { Component } from 'react';
import { UpdateMediaGalleryState, UpdateMediaGalleryProps, InjectedProps } from './UpdateMediaGalleryModel';
import { MediaGalleryForm, Aside, AsideItem, FormActions, ContainerWithAside } from '../../../components';
import { DataAttributesBuilder } from '../../../services/DataAttributesBuilder';
import { injectIntl } from 'react-intl';
import { withMediaGalleryStores, withFormInitialization } from '../../../hoc';
import {
    createErrorNotification,
    createInfoNotification,
    createSuccessNotification
} from '../../../services/Notification';
import { NOTIFICATION_KEY_CONSTANTS, NOTIFICATION_DEFAULT_MESSAGES } from '../../../constants/notifications-constants';
import { inject, observer } from 'mobx-react';
import { MediaGalleryFormDataModel } from '../../../components/Forms/MediaGalleryForm/MediaGalleryFormModel';
import { userPermissionsTable } from '../../../constants/user-permissions-table';
import { ManageFieldProvider } from '../../../providers/UserPermissionsProvider';

@inject('updateMediaGalleryStore', 'deleteMediaGalleryStore', 'notificationStore')
@observer
class UpdateMediaGallery extends Component<UpdateMediaGalleryProps, UpdateMediaGalleryState> {

    get injected() {
        return this.props as InjectedProps;
    }

    componentWillUnmount() {
        const { notificationStore } = this.injected;
        const { notifications } = notificationStore;
        if (notifications.includes(NOTIFICATION_KEY_CONSTANTS.MEDIAGALLERY_DELETE_API_KEY_REQUEST)) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.MEDIAGALLERY_DELETE_API_KEY_REQUEST);
        }
    }

    createDataAttributes = () => {
        const { mediaGalleryData } = this.props;
        return new DataAttributesBuilder('mediagallery')
            .setNameAttribute(mediaGalleryData.name)
            .setEditFormAttribute('mediaGalleryUpdate')
            .build();
    }

    onArchiveMediaGallery = async () => {
        const { notificationStore } = this.injected;
        const { mediaGalleryData: { id } } = this.props;
        const { formatMessage } = this.props.intl;
        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.MEDIAGALLERY_DELETE_API_KEY_REQUEST,
            formatMessage,
            NOTIFICATION_DEFAULT_MESSAGES.archiveAction
        );
        try {
            await this.injected.deleteMediaGalleryStore.delete(id);
            createSuccessNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.archiveAction
            );
        } catch (e) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.MEDIAGALLERY_DELETE_API_KEY_REQUEST);
            createErrorNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.archiveAction
            );
        }
    }

    onSubmitForm = async (values: MediaGalleryFormDataModel) => {
        const { mediaGalleryData, intl: { formatMessage } } = this.props;
        const { notificationStore } = this.injected;
        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.MEDIAGALLERY_UPDATE_API_KEY_REQUEST,
            formatMessage,
            NOTIFICATION_DEFAULT_MESSAGES.updateAction
        );
        try {
            await this.injected.updateMediaGalleryStore.update(mediaGalleryData, values);
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.MEDIAGALLERY_UPDATE_API_KEY_REQUEST);
            createSuccessNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.updateAction,
            );
        } catch (e) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.MEDIAGALLERY_UPDATE_API_KEY_REQUEST);
            createErrorNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.updateAction
            );
        }
    }

    areNotificationsActive = (): boolean => {
        const { notifications } = this.injected.notificationStore;
        return notifications.includes(NOTIFICATION_KEY_CONSTANTS.MEDIAGALLERY_UPDATE_API_KEY_REQUEST) ||
            notifications.includes(NOTIFICATION_KEY_CONSTANTS.MEDIAGALLERY_DELETE_API_KEY_REQUEST);
    }

    render() {
        const { mediaGalleryData } = this.props;
        return (
            <ContainerWithAside
                dataAttributes={this.createDataAttributes().Attributes}
                disableContent={this.areNotificationsActive()}
                renderAside={() => (
                    <Aside>
                        <AsideItem>
                            <FormActions
                                onSubmit={this.onSubmitForm}
                                onArchive={this.onArchiveMediaGallery}
                                permissions={userPermissionsTable.FormActions.mediaGallery}
                            />
                        </AsideItem>
                    </Aside>
                )}
            >
                <ManageFieldProvider permissions={userPermissionsTable.Forms.UpdateMediaGalleryForm}>
                    <MediaGalleryForm
                        mediaGalleryData={mediaGalleryData}
                    />
                </ManageFieldProvider>
            </ContainerWithAside>
        );
    }
}

export default withFormInitialization(withMediaGalleryStores(injectIntl(UpdateMediaGallery)));
