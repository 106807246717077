import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from './index.module.scss';
import {
    GetSubscriptionResponse,
    SubscriptionType
} from '@contentchef/contentchef-types';
import { Alert } from 'antd';

export interface YouTrialHasExpiredMessageProps {
    subscription?: GetSubscriptionResponse;
    children?: React.ReactNode;
}

function shouldNotRender(props: YouTrialHasExpiredMessageProps): boolean {
    if (props.subscription) {
        return (
            props.subscription.type !== SubscriptionType.Trial &&
            new Date().getTime() <= new Date(props.subscription.updateDate!).getTime()
        );
    }

    return false;
}

function YouTrialHasExpiredMessage(props: YouTrialHasExpiredMessageProps) {
    if (shouldNotRender(props)) {
        return null;
    }

    return (
        <Alert
            message={
                <div>
                    <h2 className={classNames.YouTrialHasExpiredMessageTitle}>
                        <FormattedMessage
                            defaultMessage="Your trial has expired"
                            id="components.YouTrialHasExpiredMessage.title"
                        />
                    </h2>
                    <p className={classNames.YouTrialHasExpiredMessageText}>
                        <FormattedMessage
                            defaultMessage="We strongly recommend you to choose a plan"
                            id="components.YouTrialHasExpiredMessage.firstText"
                        />
                    </p>
                    <p className={classNames.YouTrialHasExpiredMessageText}>
                        <FormattedMessage
                            // tslint:disable-next-line: max-line-length
                            defaultMessage="Until your will not upgrade, all operations about this subscription will be disabled"
                            id="components.YouTrialHasExpiredMessage.secondText"
                        />
                    </p>
                    <div className={classNames.YouTrialHasExpiredMessageChildren}>
                        {
                            props.children
                        }
                    </div>
                </div>
            }
            type="warning"
        />
    );
}

export default YouTrialHasExpiredMessage;
