import React, { Component } from 'react';
import {
    Icon,
} from 'antd';
import styles from './HelpSection.module.scss';
import { InjectedIntlProps, injectIntl, defineMessages } from 'react-intl';

const labels = defineMessages({
    button: { id: 'helpsection.button.title', defaultMessage: 'Help' }
});

interface HelpSectionProps extends InjectedIntlProps { }

class HelpSection extends Component<HelpSectionProps> {

    state = {
        visible: false
    };

    openHelpModal = () => {
        const state = {
            visible: true,
        };

        window.fbq('trackCustom', 'app.help.click', state);
        if (window.supportHeroWidget) {
            window.supportHeroWidget.show();
        }

        this.setState(state);
    }

    onOk = () => {
        const state = {
            visible: false,
        };

        window.fbq('trackCustom', 'app.help.click', state);

        this.setState(state);
    }

    componentDidMount() {
        window.addEventListener('click', (e: MouseEvent) => {
            if (
                e.target &&
                (e.target as EventTarget & { id: string }).id !== 'supporthero-container'
                && window.supportHeroWidget.isOpened()
            ) {
                window.supportHeroWidget.hide();
            }
        });
    }

    render() {
        const { intl: { formatMessage } } = this.props;
        return (
            <React.Fragment>
                <Icon
                    type="question-circle"
                    className={styles.HelpIcon}
                    onClick={this.openHelpModal}
                    title={formatMessage(labels.button)}
                />
            </React.Fragment>
        );
    }
}

export default injectIntl(HelpSection);
