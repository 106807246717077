import React, { Component } from 'react';
import { StepProps } from './StepModel';

class Step extends Component<StepProps> {
    render() {
        return (
            this.props.activeStep === this.props.step ? (
                <React.Fragment>
                    {this.props.children}
                </React.Fragment>
            ) : null
        );
    }
}

export default Step;
