import TranslateIcon from '@assets/custom_icons/components/Translate';
import Typography from '@components/Typography';
import { editContentPath } from '@constants/routing-constants';
import { languageByLocale } from '@services/languages';
import { Icon } from 'antd';
import React from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

interface Props extends InjectedIntlProps, RouteComponentProps<{ spaceId: string, contentId: string; }> {
    contentLocale: string;
    translationLocale?: string;
}

const labels = defineMessages({
    contentOriginalLocale: {
        id: 'LocaleSection.originalLocaleTitle',
        defaultMessage: `Content's original locale`
    },
    contentTranslationLocale: {
        id: 'LocaleSection.translationLocaleTitle',
        defaultMessage: `Translation's locale`
    }
});

function LocaleSection({
    contentLocale,
    translationLocale,
    intl: { formatMessage },
    match: { params: { contentId, spaceId } }
}: Props) {
    function renderItem(locale: string, withLink: boolean, title: string) {
        const item = (
            <Typography
                gutter={false}
                variant="span"
                title={formatMessage(labels.contentOriginalLocale)}
                style={{ cursor: 'pointer' }}
            >
                <TranslateIcon style={{ paddingRight: 10 }} />
                {languageByLocale.getLanguageNameByLocale(locale)} ({locale})
            </Typography>
        );
        if (withLink) {
            const link = editContentPath(spaceId, parseInt(contentId, 10));
            return (
                <Link to={link} className={styles.LinkItem}>
                    {item}
                </Link>
            );
        }
        return (
            <Typography
                gutter={false}
                variant="span"
                title={formatMessage(labels.contentOriginalLocale)}
            >
                <TranslateIcon style={{ paddingRight: 10 }} />
                {languageByLocale.getLanguageNameByLocale(locale)} ({locale})
            </Typography>
        );
    }
    return (
        <>
            {renderItem(contentLocale, !!translationLocale, formatMessage(labels.contentOriginalLocale))}
            {translationLocale && (
                <>
                    <Icon type="double-right" className={styles.ItemSpacing} />
                    {renderItem(translationLocale, false, formatMessage(labels.contentTranslationLocale))}
                </>
            )}
        </>
    );
}

export default withRouter(injectIntl(LocaleSection));
