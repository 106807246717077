import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { MediaFinder, MediaPreview } from '../../../components';
import { InjectedProps, MediaListSceneProps, MediaListSceneState } from './MediaListSceneModel';
import { Drawer } from 'antd';

import styles from './MediaListScene.module.scss';
import { MediaAdapterDataModel, MediaAdapter } from '../../../services/Media/MediaConverterClass';
import {
    createErrorNotification,
    createInfoNotification,
    createSuccessNotification
} from '../../../services/Notification';
import { NOTIFICATION_KEY_CONSTANTS, NOTIFICATION_DEFAULT_MESSAGES } from '../../../constants/notifications-constants';
import {
    mediaGalleryBasePath
} from '../../../constants/routing-constants';
import { withMediaNavigation } from '../../../providers/MediaNavigationProvider';

@inject('mediaGalleryFlowStore', 'notificationStore')
@observer
class MediaListScene extends Component<MediaListSceneProps, MediaListSceneState> {

    state: MediaListSceneState = {
        previewOpen: false,
        selectedMedia: undefined
    };

    get injected() {
        return this.props as InjectedProps;
    }

    onMediaClick = (media: MediaAdapter) => {
        this.injected.mediaGalleryFlowStore.selectMedia(media);
        this.setState({ previewOpen: true, selectedMedia: media });
    }

    onCloseDrawer = () => {
        this.setState({ previewOpen: false });
        this.injected.mediaGalleryFlowStore.clearSelectedMedia();
    }

    updateMedia = async (mediaData: MediaAdapterDataModel) => {
        const { intl: { formatMessage }, match: { params: { mediaGalleryId } } } = this.props;
        const { mediaGalleryFlowStore, notificationStore } = this.injected;
        try {
            createInfoNotification(
                NOTIFICATION_KEY_CONSTANTS.MEDIA_UPDATE_API_KEY_REQUEST,
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.updateAction
            );
            await mediaGalleryFlowStore.updateMedia(mediaData, mediaGalleryId);
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.MEDIA_UPDATE_API_KEY_REQUEST);
            createSuccessNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.updateAction
            );
        } catch (e) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.MEDIA_UPDATE_API_KEY_REQUEST);
            createErrorNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.updateAction
            );
        }
    }

    render() {
        const { selectedMedia } = this.state;
        const { match: { params: { spaceId } } } = this.props;
        return (
            <React.Fragment>
                {selectedMedia &&
                    <Drawer
                        className={styles.MediaLisSceneDrawer}
                        placement="right"
                        closable={false}
                        visible={this.state.previewOpen}
                        onClose={this.onCloseDrawer}
                        destroyOnClose={true}
                    >
                        <MediaPreview mediaData={selectedMedia} updateMediaCallback={this.updateMedia} />
                    </Drawer>
                }
                <div className={styles.MediaListSceneBody}>
                    <MediaFinder
                        onMediaClick={this.onMediaClick}
                        prevStep={() => { this.props.history.push(mediaGalleryBasePath(spaceId)); }}
                        nextStep={() => {
                            const folderPath = this.injected.mediaGalleryFlowStore.buildParentFolder();
                            if (this.injected.onClickToUpload) {
                                this.injected.onClickToUpload(folderPath);
                            }
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default withMediaNavigation(injectIntl(MediaListScene));
