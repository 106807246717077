import { action, observable } from 'mobx';
import { routerStoreHelper } from '..';
import { SearchParamsStoreModel, UsableSearchParams } from './searchParamsStoreModel';

class SearchParamsStore implements SearchParamsStoreModel {
    @observable urlSearchParams: URLSearchParams;
    @observable usableSearchParams: UsableSearchParams = {};

    constructor() {
        this.urlSearchParams = new URLSearchParams(routerStoreHelper.routerStore.location.search);
        this.usableSearchParams = this.readableSearchParams();
    }

    readableSearchParams() {
        const urlParams: UsableSearchParams = {};
        this.urlSearchParams.forEach((value, key) => {
            urlParams[key] = value;
        });
        return urlParams;
    }

    @action
    setUrlParam(param: string, value?: string) {
        this.deleteUrlParam('page');
        if (!value || value.length === 0) {
            this.deleteUrlParam(param);
        } else {
            this.urlSearchParams.set(param, value);
            this.usableSearchParams[param] = value;
        }
        if (window.history) {
            this.pushSearchParamsToUrl();
        }
    }

    @action
    appendUrlParam(param: string, value: string) {
        this.deleteUrlParam('page');
        const oldValues = this.urlSearchParams.get(param);
        if (oldValues) {
            const newValues = oldValues.concat(',', value);
            this.urlSearchParams.set(param, newValues);
            this.usableSearchParams[param] = newValues;
            if (window.history) {
                this.pushSearchParamsToUrl();
            }
        } else {
            this.setUrlParam(param, value);
        }
    }

    @action
    deleteUrlParam(param: string) {
        this.urlSearchParams.delete(param);
        delete this.usableSearchParams[param];
        if (window.history) {
            this.pushSearchParamsToUrl();
        }
    }

    @action
    deleteSingleUrlParamValue(param: string, value: string) {
        if (this.usableSearchParams[param]) {
            const specificUrlParams = this.usableSearchParams[param].split(',');
            const newUrlParams = specificUrlParams.filter(urlParam => urlParam !== value);
            this.setUrlParam(param, newUrlParams.join(','));
        }
    }

    @action
    deleteAllUrlParams() {
        const queryParamsKeys: string[] = [];
        this.urlSearchParams.forEach((value, key) => queryParamsKeys.push(key));
        queryParamsKeys.map(key => {
            this.urlSearchParams.delete(key);
            delete this.usableSearchParams[key];
            return key;
        });
        this.pushSearchParamsToUrl();
    }

    @action.bound
    reset() {
        this.urlSearchParams = new URLSearchParams(routerStoreHelper.routerStore.location.search);
        this.usableSearchParams = this.readableSearchParams();
    }

    private pushSearchParamsToUrl() {
        if (this.urlSearchParams.toString().length > 0) {
            routerStoreHelper.routerStore.history.push({
                search: `?${this.urlSearchParams.toString()}`
            });
        } else {
            routerStoreHelper.routerStore.history.push({
                search: ''
            });
        }
    }
}

export default SearchParamsStore;
