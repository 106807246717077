import React from 'react';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { Icon, Popover } from 'antd';
import styles from './NotificationPopover.module.scss';
import Typography from '../Typography';

type NotificationPopoverProps = {
    visible: boolean;
    title: string;
    content: string;
    children: React.ReactNode;
    placement?: TooltipPlacement;
    iconType?: string;
    onVisibleChangeCallback?(visible: boolean): void;
};

function NotificationPopover(props: NotificationPopoverProps) {
    const { visible, onVisibleChangeCallback, title, iconType, content, placement = 'bottomLeft', children } = props;
    return (
        <Popover
            {...!!onVisibleChangeCallback ? { onVisibleChange: onVisibleChangeCallback } : null}
            trigger="click"
            visible={visible}
            title={<div className={styles.PopoverTitleWrapper}>
                <Icon type={iconType} className={styles.PopoverTitleIcon} />
                <Typography variant="h6" gutter={false}>{title}</Typography>
            </div>}
            content={<Typography variant="paragraph" gutter={false} className={styles.PopoverContent}>
                {content}
            </Typography>}
            placement={placement}
        >
            {children}
        </Popover>
    );
}

export default NotificationPopover;
