import { DatePicker } from 'antd';
import React from 'react';
import Typography from '../../../Typography';
import moment from 'moment';
import {
    withPermissionsToInteract,
    WithPermissionsToInteractReturnType
} from '../../../../providers/UserPermissionsProvider';

export interface DateProps extends WithPermissionsToInteractReturnType {
    children?: React.ReactNode;
    value?: Date;
    onChange?(value: Date): any;
}

function createHandler<T extends (arg: any) => any>(maybeFn?: T) {
    if (!maybeFn) {
        return () => void 0;
    }

    return (date: moment.Moment | null) => {
        if (date === null) {
            maybeFn(undefined);
        } else {
            maybeFn(date.toDate());
        }
    };
}

function Date(props: DateProps) {
    return (
        <>
            <Typography variant="label">
                {props.children}
            </Typography>
            <DatePicker
                disabled={!props.hasPermissions}
                onChange={createHandler(props.onChange)}
                value={!!props.value ? moment(props.value) : undefined}
            />
        </>
    );
}

export default withPermissionsToInteract(Date);
