import React from 'react';
import AsideItem from '@components/AsideItem/AsideItem';
import FilterBySearch from '@components/FilterBySearch/FilterBySearch';
import { Switch } from 'antd';
import { ArchiveIcon } from '@components/CustomIcons/CustomIcons';
import Aside from '@components/Aside/Aside';
import { DataAttributes, DataAttributesBuilder } from '@services/DataAttributesBuilder';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import styles from './index.module.scss';
import { inject, observer } from 'mobx-react';
import { MediaGalleryListUrlParams } from '@stores/mediaGalleryListStore';
import { RepositoryListStoreModel } from '@stores/repositoryListStore/repositoryListStoreModel';

const labels = defineMessages({
    filterByNamePlaceholder: {
        id: 'RepositoryListFilters.filterByNamePlaceholder',
        defaultMessage: 'Filter by name'
    },
    filterByOnlyArchived: {
        id: 'RepositoryListFilters.filterByOnlyArchived',
        defaultMessage: 'Archived only'
    },
    filterByOnlyUnarchived: {
        id: 'RepositoryListFilters.filterByOnlyUnarchived',
        defaultMessage: 'Unarchived only'
    },
    removeAllFilters: {
        id: 'RepositoryListFilters.removeAllFilters',
        defaultMessage: 'Remove all filters'
    }
});

interface RepositoryListFiltersProps extends InjectedIntlProps { }

interface InjectedProps extends RepositoryListFiltersProps {
    repositoryListStore: RepositoryListStoreModel;
}

@inject('repositoryListStore')
@observer
class RepositoryListFilters extends React.Component<RepositoryListFiltersProps> {

    get injected() {
        return this.props as InjectedProps;
    }

    createSearchDataAttributes = (): DataAttributes => {
        return new DataAttributesBuilder('repositories')
            .setSearchAttribute('search-repository')
            .build();
    }

    onSearchByName = (value?: string) => {
        if (!!value && value.trim().length > 0) {
            this.injected.repositoryListStore.searchParams.setUrlParam(
                MediaGalleryListUrlParams.byName, value
            );
            this.injected.repositoryListStore.setListFilterByName(value);
        } else {
            this.injected.repositoryListStore.searchParams.deleteUrlParam(
                MediaGalleryListUrlParams.byName
            );
            this.injected.repositoryListStore.setListFilterByName(undefined);
        }
        this.injected.repositoryListStore.setRepositories();
    }

    onSearchByArchived = (value: boolean) => {
        if (value) {
            this.injected.repositoryListStore.searchParams.setUrlParam(
                MediaGalleryListUrlParams.byArchived, 'true'
            );
            this.injected.repositoryListStore.setListFilterByArchived(value);
        } else {
            this.injected.repositoryListStore.searchParams.deleteUrlParam(
                MediaGalleryListUrlParams.byArchived
            );
            this.injected.repositoryListStore.setListFilterByArchived(undefined);
        }
        this.injected.repositoryListStore.setRepositories();
    }

    onResetFilters = () => {
        this.injected.repositoryListStore.searchParams.deleteAllUrlParams();
        this.injected.repositoryListStore.resetAllFilters();
        this.injected.repositoryListStore.setRepositories();
    }

    render() {
        const {
            listFilters,
            canResetAllFilters
        } = this.injected.repositoryListStore;
        return (
            <Aside>
                <AsideItem key={'FilterByName'}>
                    <FilterBySearch
                        dataAttributes={this.createSearchDataAttributes}
                        placeholder={this.props.intl.formatMessage(labels.filterByNamePlaceholder)}
                        defaultValue={listFilters.name}
                        onSearch={this.onSearchByName}
                    />
                </AsideItem>
                <AsideItem key={'FilterByArchiveStatus'}>
                    <Switch
                        style={{ marginRight: 10 }}
                        onChange={this.onSearchByArchived}
                        checked={listFilters.archived}
                    />
                    {listFilters.archived
                        ? <React.Fragment>
                            <span>{this.props.intl.formatMessage(labels.filterByOnlyArchived)}</span>
                            <ArchiveIcon className={styles.ArchiveIconStyle} />
                        </React.Fragment>
                        : <span>{this.props.intl.formatMessage(labels.filterByOnlyUnarchived)}</span>
                    }
                </AsideItem>
                {canResetAllFilters && <AsideItem key="RemoveAllAppliedFilters">
                    <a onClick={this.onResetFilters}>
                        {this.props.intl.formatMessage(labels.removeAllFilters)}
                    </a>
                </AsideItem>}
            </Aside>
        );
    }
}

export default injectIntl(RepositoryListFilters);
