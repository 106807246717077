import React from 'react';

export enum EditingMode {
    normal = 'normal',
    custom = 'custom',
}

export const Context = React.createContext<{ mode: EditingMode }>({ mode: EditingMode.normal });

export const withEditingMode = (Component: any) => (props: any) => (
    <Context.Consumer>
        {
            ctx => (
                <Component {...props} mode={ctx.mode} />
            )
        }
    </Context.Consumer>
);

export const EditingModeProvider = (props: { mode: EditingMode } & { children: any }) => (
    <Context.Provider value={{ mode: props.mode }}>
        {props.children}
    </Context.Provider>
);

const module = {
    EditingModeProvider,
    withEditingMode
}

export default module;
