import {
    AllSchemaTypes,
    Field
} from '@contentchef/contentchef-types';
import { defineMessages, InjectedIntl } from 'react-intl';
import { FieldDescriptor, ListItemFamily, ListItemIds } from '../../types';
import { booleanSnippets } from './boolean';
import { cloudinaryImageSnippets } from './cloudinary-image';
import { cloudinaryPublicIdSnippets } from './cloudinary-publicId';
import { clodinaryVideoSnippets } from './cloudinary-video';
import { dateSnippets } from './date';
import { fragmentSnippets } from './fragment';
import { linkedContentSnippets } from './linked-content';
import { longTextSnippets } from './long-text';
import { mediaSnippets } from './media';
import { numberSnippets } from './number';
import { oneFragmentOfSnippets } from './oneFragmentOf';
import { oneLinkedContentOfSnippets } from './oneLinkedContentOf';
import { slugSnippets } from './slug';
import { textSnippets } from './text';

export type createSnippetParams = {
    definitionMnemonicId?: string,
    definitionMnemonicIds?: string[],
    nameConstraint?: string,
    namesConstraint?: string[],
};

type CreateItemArgument = {
    name: string;
    id: ListItemIds;
    description: string;
    families: ListItemFamily[];
    usable: boolean;
    snippet: (defaultLanguage: string, name: string, params?: createSnippetParams) => Field;
    icon?: string;
    iconComponent?: JSX.Element;
    rotateIcon?: number;
};

const snippetsLabels = defineMessages({
    simpleTextName: {
        id: 'ContentDefinition.FieldSnippetSelection.SimpleTextName',
        defaultMessage: 'Simple Text'
    },
    simpleTextDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.SimpleTextDescription',
        defaultMessage: 'Used for short text entries'
    },
    listOfTextValuesName: {
        id: 'ContentDefinition.FieldSnippetSelection.ListOfTextValuesName',
        defaultMessage: 'List of Text Values'
    },
    listOfTextValuesDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.ListOfTextValuesDescription',
        defaultMessage: 'Used to create text entries from predefined values'
    },
    simpleNumberName: {
        id: 'ContentDefinition.FieldSnippetSelection.SimpleNumberName',
        defaultMessage: 'Simple Number'
    },
    simpleNumberDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.SimpleNumberDescription',
        defaultMessage: 'Used for numbers'
    },
    listOfNumberValuesName: {
        id: 'ContentDefinition.FieldSnippetSelection.ListOfNumberValuesName',
        defaultMessage: 'List of Number Values'
    },
    listOfNumberValuesDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.ListOfNumberValuesDescription',
        defaultMessage: 'Used to create number entries from predefined values'
    },
    simplePlainTextName: {
        id: 'ContentDefinition.FieldSnippetSelection.SimplePlainTextName',
        defaultMessage: 'Simple Plain Text'
    },
    simplePlainTextDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.SimplePlainTextDescription',
        defaultMessage: 'Used for long text entries'
    },
    markdownPlainTextName: {
        id: 'ContentDefinition.FieldSnippetSelection.MarkdownPlainTextName',
        defaultMessage: 'Markdown Plain Text'
    },
    markdownPlainTextDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.MarkdownPlainTextDescription',
        defaultMessage: 'Used for long text in markdown format'
    },
    jsonPlainTextName: {
        id: 'ContentDefinition.FieldSnippetSelection.JsonPlainTextName',
        defaultMessage: 'JSON Plain Text'
    },
    jsonPlainTextDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.JsonPlainTextDescription',
        defaultMessage: 'Used for long text in JSON format'
    },
    htmlPlainTextName: {
        id: 'ContentDefinition.FieldSnippetSelection.HtmlPlainTextName',
        defaultMessage: 'HTML Plain Text'
    },
    htmlPlainTextDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.HtmlPlainTextDescription',
        defaultMessage: 'Used for long text in HTML format'
    },
    simpleDateName: {
        id: 'ContentDefinition.FieldSnippetSelection.SimpleDateName',
        defaultMessage: 'Date'
    },
    simpleDateDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.SimpleDateDescription',
        defaultMessage: 'Used for dates'
    },
    simpleBooleanName: {
        id: 'ContentDefinition.FieldSnippetSelection.SimpleBooleanName',
        defaultMessage: 'Boolean'
    },
    simpleBooleanDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.SimpleBooleanDescription',
        defaultMessage: 'Used to create boolean flags with true and false'
    },
    contentReferenceName: {
        id: 'ContentDefinition.FieldSnippetSelection.ContentReferenceName',
        defaultMessage: 'LEGACY Content Reference'
    },
    contentReferenceDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.ContentReferenceDescription',
        defaultMessage: 'A reference to the chosen content, filtered by a definition',
    },
    multiCustomFieldName: {
        id: 'ContentDefinition.FieldSnippetSelection.MultiCustomFieldName',
        defaultMessage: 'Selectable Custom Field'
    },
    multiCustomFieldDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.MultiCustomFieldDescription',
        defaultMessage: 'Used to display multiple custom fields to choose from'
    },
    multiContentReferenceName: {
        id: 'ContentDefinition.FieldSnippetSelection.MultiContentReferenceName',
        defaultMessage: 'Content Reference'
    },
    multiContentReferenceDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.MultiContentReferenceDescription',
        defaultMessage: 'Used to refer to other content that belongs to one or more specific definition'
    },
    imageReferenceName: {
        id: 'ContentDefinition.FieldSnippetSelection.ImageReferenceName',
        defaultMessage: 'Image Reference'
    },
    imageReferenceDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.ImageReferenceDescription',
        defaultMessage: 'Used to refer to an image'
    },
    imageWithMetadataName: {
        id: 'ContentDefinition.FieldSnippetSelection.ImageWithMetadataName',
        defaultMessage: 'Image'
    },
    imageWithMetadataDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.ImageWithMetadataDescription',
        defaultMessage: 'Used to refer to an image with metadata (aspect ratio, size, ecc.)'
    },
    videoReferenceName: {
        id: 'ContentDefinition.FieldSnippetSelection.VideoReferenceName',
        defaultMessage: 'Video Reference'
    },
    videoReferenceDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.VideoReferenceDescription',
        defaultMessage: 'Used to refer to a video'
    },
    videoWithMetadataName: {
        id: 'ContentDefinition.FieldSnippetSelection.VideoWithMetadataName',
        defaultMessage: 'Video'
    },
    videoWithMetadataDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.VideoWithMetadataDescription',
        defaultMessage: 'Used to refer to a video with metadata (aspect ratio, size, ecc.)'
    },
    rawReferenceName: {
        id: 'ContentDefinition.FieldSnippetSelection.RawReferenceName',
        defaultMessage: 'Raw Reference'
    },
    rawReferenceDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.RawReferenceDescription',
        defaultMessage: 'Used to refer to a raw file'
    },
    rawWithMetadataName: {
        id: 'ContentDefinition.FieldSnippetSelection.RawWithMetadataName',
        defaultMessage: 'Raw'
    },
    rawWithMetadataDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.RawWithMetadataDescription',
        defaultMessage: 'Used to refer to a raw file'
    },
    transformableImageReferenceName: {
        id: 'ContentDefinition.FieldSnippetSelection.TransformableImageReferenceName',
        defaultMessage: 'Transformable Image Reference'
    },
    transformableImageReferenceDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.TransformableImageReferenceDescription',
        defaultMessage: 'Used to refer to an image with possible transformations'
    },
    transformableVideoReferenceName: {
        id: 'ContentDefinition.FieldSnippetSelection.TransformableVideoReferenceName',
        defaultMessage: 'Transformable Video Reference'
    },
    transformableVideoReferenceDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.TransformableVideoReferenceDescription',
        defaultMessage: 'Used to refer to a video with possible transformations'
    },
    slugName: {
        id: 'ContentDefinition.FieldSnippetSelection.slugName',
        defaultMessage: 'Slug'
    },
    slugDescription: {
        id: 'ContentDefinition.FieldSnippetSelection.slugDescription',
        defaultMessage: 'Url friendly text needed to identify a web page'
    }
});

export function createCustomItem(nameConstraint: string): FieldDescriptor {
    return {
        usable: true,
        name: nameConstraint,
        id: ListItemIds.CustomField,
        families: [ListItemFamily.Custom],
        icon: 'build',
        snippet: (defaultLanguage, fieldName) =>
            fragmentSnippets.asField.customField(defaultLanguage, fieldName, nameConstraint)
    };
}

export function createArrayItem(selectedField: FieldDescriptor): AllSchemaTypes {
    const itemType = selectedField.id;

    switch (itemType) {
        case ListItemIds.SimpleText:
            return textSnippets.asArray.simpleTextInArray();
        case ListItemIds.ListOfTextValues:
            return textSnippets.asArray.listOfTextValueInArray();
        case ListItemIds.SimpleNumber:
            return numberSnippets.asArray.simpleNumberInArray();
        case ListItemIds.ListOfNumberValues:
            return numberSnippets.asArray.listOfNumberValuesInArray();
        case ListItemIds.SimplePlainText:
            return longTextSnippets.asArray.simplePlainTextInArray();
        case ListItemIds.MarkdownPlainText:
            return longTextSnippets.asArray.markdownPlainTextInArray();
        case ListItemIds.JsonPlainText:
            return longTextSnippets.asArray.jsonPlainTextInArray();
        case ListItemIds.HtmlPlainText:
            return longTextSnippets.asArray.htmlPlainTextInArray();
        case ListItemIds.SimpleDate:
            return dateSnippets.asArray.simpleDateInArray();
        case ListItemIds.SimpleBoolean:
            return booleanSnippets.asArray.simpleBooleanInArray();
        case ListItemIds.LegacyContentReference:
            return linkedContentSnippets.asArray.contentReferenceInArray();
        case ListItemIds.ContentReference:
            return oneLinkedContentOfSnippets.asArray.multiContentReferenceInArray();
        case ListItemIds.CustomField:
            return fragmentSnippets.asArray.customFieldInArray(selectedField.name);
        case ListItemIds.MultiCustomField:
            return oneFragmentOfSnippets.asArray.multiCustomFieldInArray();
        case ListItemIds.ImageReference:
            return cloudinaryPublicIdSnippets.asArray.imageReferenceInArray();
        case ListItemIds.ImageWithMetadata:
            return mediaSnippets.asArray.imageWithMetadataInArray();
        case ListItemIds.VideoReference:
            return cloudinaryPublicIdSnippets.asArray.videoReferenceInArray();
        case ListItemIds.VideoWithMetadata:
            return mediaSnippets.asArray.videoWithMetadataInArray();
        case ListItemIds.RawReference:
            return cloudinaryPublicIdSnippets.asArray.rawReferenceInArray();
        case ListItemIds.RawWithMetadata:
            return mediaSnippets.asArray.rawWithMetadataInArray();
        case ListItemIds.TransformableImage:
            return cloudinaryImageSnippets.asArray.transformableImageReferenceInArray();
        case ListItemIds.TransformableVideo:
            return clodinaryVideoSnippets.asArray.transformableVideoReferenceInArray();
        default:
            throw new Error();
    }
}

function createItem({
    name,
    id,
    description,
    snippet,
    families = [],
    usable,
    icon,
    iconComponent,
    rotateIcon
}: CreateItemArgument): FieldDescriptor {
    return {
        name,
        id,
        description,
        families,
        snippet,
        usable,
        icon,
        iconComponent,
        rotateIcon
    };
}

export function createFieldItems(formatMessage: InjectedIntl['formatMessage']) {
    return [
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.simpleTextName),
            description: formatMessage(snippetsLabels.simpleTextDescription),
            id: ListItemIds.SimpleText,
            snippet: (defaultLanguage, name) => textSnippets.asField.simpleText(defaultLanguage, name),
            families: [ListItemFamily.Text],
            icon: 'font-size',
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.listOfTextValuesName),
            description: formatMessage(snippetsLabels.listOfTextValuesDescription),
            id: ListItemIds.ListOfTextValues,
            snippet: (defaultLanguage, name) => textSnippets.asField.listOfTextValues(defaultLanguage, name),
            families: [ListItemFamily.Text],
            icon: 'unordered-list',
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.simpleNumberName),
            description: formatMessage(snippetsLabels.simpleNumberDescription),
            id: ListItemIds.SimpleNumber,
            snippet: (defaultLanguage, name) => numberSnippets.asField.simpleNumber(defaultLanguage, name),
            families: [ListItemFamily.Text],
            icon: 'number',
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.listOfNumberValuesName),
            description: formatMessage(snippetsLabels.listOfNumberValuesDescription),
            id: ListItemIds.ListOfNumberValues,
            snippet: (defaultLanguage, name) => numberSnippets.asField.listOfNumberValues(defaultLanguage, name),
            families: [ListItemFamily.Text],
            icon: 'ordered-list',
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.simplePlainTextName),
            description: formatMessage(snippetsLabels.simplePlainTextDescription),
            id: ListItemIds.SimplePlainText,
            snippet: (defaultLanguage, name) => longTextSnippets.asField.simplePlainText(defaultLanguage, name),
            families: [ListItemFamily.Text],
            icon: 'file-text',
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.markdownPlainTextName),
            description: formatMessage(snippetsLabels.markdownPlainTextDescription),
            id: ListItemIds.MarkdownPlainText,
            snippet: (defaultLanguage, name) => longTextSnippets.asField.markdownPlainText(defaultLanguage, name),
            families: [ListItemFamily.Text],
            icon: 'file-markdown',
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.jsonPlainTextName),
            description: formatMessage(snippetsLabels.jsonPlainTextDescription),
            id: ListItemIds.JsonPlainText,
            snippet: (defaultLanguage, name) => longTextSnippets.asField.jsonPlainText(defaultLanguage, name),
            families: [ListItemFamily.Text],
            icon: 'code'
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.htmlPlainTextName),
            description: formatMessage(snippetsLabels.htmlPlainTextDescription),
            id: ListItemIds.HtmlPlainText,
            snippet: (defaultLanguage, name) => longTextSnippets.asField.htmlPlainText(defaultLanguage, name),
            families: [ListItemFamily.Text],
            icon: 'table',
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.simpleDateName),
            description: formatMessage(snippetsLabels.simpleDateDescription),
            id: ListItemIds.SimpleDate,
            snippet: (defaultLanguage, name) => dateSnippets.asField.simpleDate(defaultLanguage, name),
            families: [ListItemFamily.Text],
            icon: 'calendar',
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.simpleBooleanName),
            description: formatMessage(snippetsLabels.simpleBooleanDescription),
            id: ListItemIds.SimpleBoolean,
            snippet: (defaultLanguage, name) => booleanSnippets.asField.simpleBoolean(defaultLanguage, name),
            families: [ListItemFamily.Text],
            icon: 'bulb',
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.contentReferenceName),
            description: formatMessage(snippetsLabels.contentReferenceDescription),
            id: ListItemIds.LegacyContentReference,
            snippet: (defaultLanguage, name) => linkedContentSnippets.asField.contentReference(defaultLanguage, name),
            families: [ListItemFamily.Special],
            icon: 'link',
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.transformableImageReferenceName),
            description: formatMessage(snippetsLabels.transformableImageReferenceDescription),
            id: ListItemIds.TransformableImage,
            snippet: (defaultLanguage, name) =>
                cloudinaryImageSnippets.asField.transformableImageReference(defaultLanguage, name),
            families: [ListItemFamily.Media],
            icon: 'block',
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.transformableVideoReferenceName),
            description: formatMessage(snippetsLabels.transformableVideoReferenceDescription),
            id: ListItemIds.TransformableVideo,
            snippet: (defaultLanguage, name) =>
                clodinaryVideoSnippets.asField.transformableVideoReference(defaultLanguage, name),
            families: [ListItemFamily.Media],
            icon: 'video-camera',
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.imageReferenceName),
            description: formatMessage(snippetsLabels.imageReferenceDescription),
            id: ListItemIds.ImageReference,
            snippet: (defaultLanguage, name) =>
                cloudinaryPublicIdSnippets.asField.imageReference(defaultLanguage, name),
            families: [ListItemFamily.Media],
            icon: 'picture',
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.imageWithMetadataName),
            description: formatMessage(snippetsLabels.imageWithMetadataDescription),
            id: ListItemIds.ImageWithMetadata,
            snippet: (defaultLanguage, name) =>
                mediaSnippets.asField.imageWithMetadata(defaultLanguage, name),
            families: [ListItemFamily.Media],
            icon: 'picture',
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.videoReferenceName),
            description: formatMessage(snippetsLabels.videoReferenceDescription),
            id: ListItemIds.VideoReference,
            snippet: (defaultLanguage, name) =>
                cloudinaryPublicIdSnippets.asField.videoReference(defaultLanguage, name),
            families: [ListItemFamily.Media],
            icon: 'play-square',
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.videoWithMetadataName),
            description: formatMessage(snippetsLabels.videoWithMetadataDescription),
            id: ListItemIds.VideoWithMetadata,
            snippet: (defaultLanguage, name) =>
                mediaSnippets.asField.videoWithMetadata(defaultLanguage, name),
            families: [ListItemFamily.Media],
            icon: 'picture',
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.rawReferenceName),
            description: formatMessage(snippetsLabels.rawReferenceDescription),
            id: ListItemIds.RawReference,
            snippet: (defaultLanguage, name) =>
                cloudinaryPublicIdSnippets.asField.rawReference(defaultLanguage, name),
            families: [ListItemFamily.Media],
            icon: 'file',
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.rawWithMetadataName),
            description: formatMessage(snippetsLabels.rawWithMetadataDescription),
            id: ListItemIds.RawWithMetadata,
            snippet: (defaultLanguage, name) =>
                mediaSnippets.asField.rawWithMetadata(defaultLanguage, name),
            families: [ListItemFamily.Media],
            icon: 'file',
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.multiCustomFieldName),
            description: formatMessage(snippetsLabels.multiCustomFieldDescription),
            id: ListItemIds.MultiCustomField,
            snippet: (defaultLanguage, name) => oneFragmentOfSnippets.asField.multiCustomField(defaultLanguage, name),
            families: [ListItemFamily.Special],
            icon: 'apartment',
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.multiContentReferenceName),
            description: formatMessage(snippetsLabels.multiContentReferenceDescription),
            id: ListItemIds.ContentReference,
            snippet: (defaultLanguage, name) =>
                oneLinkedContentOfSnippets.asField.multiContentReference(defaultLanguage, name),
            families: [ListItemFamily.Special],
            icon: 'link',
        }),
        createItem({
            usable: true,
            name: formatMessage(snippetsLabels.slugName),
            description: formatMessage(snippetsLabels.slugDescription),
            id: ListItemIds.Slug,
            snippet: (defaultLanguage, name) =>
                slugSnippets.asField.slug(defaultLanguage, name),
            families: [ListItemFamily.Special],
            icon: 'link',
            rotateIcon: 45,
        })
    ];
}

export function createFieldItemsMap(formatMessage: InjectedIntl['formatMessage']) {

    const fieldDescriptors = createFieldItems(formatMessage);
    return fieldDescriptors.reduce(
        (accumulator, item) => {
            accumulator.set(item.id, item);
            return accumulator;
        },
        new Map<ListItemIds, FieldDescriptor>());

}
