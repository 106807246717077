import Button from '@components/Button';
import LabelWithIcon from '@components/Forms/LabelWithIcon';
import { COMMON_LABELS } from '@constants/common-internationalized-labels';
import { NOTIFICATION_DEFAULT_MESSAGES } from '@constants/notifications-constants';
import { withForm } from '@hoc';
import { createSuccessNotification } from '@services/Notification';
import { ContentTranslationManager } from '@stores/contentTranslation/ContentTranslationManager';
import { FormComponentProps } from 'antd/es/form';
import { MobXProviderContext, observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import styles from './index.module.scss';

interface Props extends InjectedIntlProps, FormComponentProps {
    label: string;
    icon?: React.ReactNode;
    pathString: string;
    formItemId: string;
}

const TranslationFieldAddon = ({ pathString, label, icon, intl, formItemId, form }: Props) => {
    const [submitting, setSubmitting] = useState(false);

    const { contentTranslationManager } =
        useContext(MobXProviderContext) as { contentTranslationManager: ContentTranslationManager };

    const [submitDisabled, setSubmitDisabled] = useState(false);

    const value = form.getFieldValue(formItemId);

    useEffect(
        () => {
            if (!contentTranslationManager.getFieldTranslation(pathString)) {
                setSubmitDisabled(true);
                return;
            }
            const errors = form.getFieldError(formItemId);
            if (errors && errors.length > 0) {
                setSubmitDisabled(true);
            } else {
                setSubmitDisabled(false);
            }
        },
        [contentTranslationManager, form, formItemId, pathString, value]
    );

    const iconClassName =
        contentTranslationManager.isFieldUpToDate(pathString) ? styles.Synchronized : styles.NotSynchronized;

    async function onSubmit() {
        try {
            setSubmitting(true);
            const fieldTranslationManager = contentTranslationManager.getFieldTranslation(pathString);
            if (!fieldTranslationManager) {
                throw new Error('Field not found');
            }
            await contentTranslationManager.updateTranslations([fieldTranslationManager.getField()]);
            createSuccessNotification(
                intl.formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.updateAction
            );
        } catch (error: any) {
            error.handleError(intl.formatMessage, NOTIFICATION_DEFAULT_MESSAGES.updateAction);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <div className={styles.Container}>
            <LabelWithIcon
                label={label}
                icon={icon}
                iconClassName={iconClassName}
            />
            <Button
                className={styles.Button}
                type="primary"
                onClick={onSubmit}
                size="small"
                loading={submitting}
                disabled={submitDisabled}
            >
                {intl.formatMessage(COMMON_LABELS.saveFormButtonLabel)}
            </Button>
        </div>
    );
};

export default withForm(injectIntl(observer(TranslationFieldAddon)));
