import React from 'react';
import { FormattedMessage, InjectedIntl } from 'react-intl';
import { NOTIFICATION_DEFAULT_MESSAGES } from '../constants/notifications-constants';
import { notificationStore } from '../stores';

export function createInfoNotification(
    key: string,
    formatMessage: InjectedIntl['formatMessage'],
    actionDescriptor: FormattedMessage.MessageDescriptor) {
    notificationStore.openNotificationWithIcon('info', {
        message: (
            <p>
                {
                    formatMessage(NOTIFICATION_DEFAULT_MESSAGES.requestNotificationMessage, {
                        action: formatMessage(actionDescriptor)
                    })
                }
            </p>
        ),
        key: key,
        duration: 0,
        placement: 'topRight'
    });
}

export function createSuccessNotification(
    formatMessage: InjectedIntl['formatMessage'],
    actionDescriptor: FormattedMessage.MessageDescriptor
) {
    notificationStore.openNotificationWithIcon('success', {
        message: (
            <p>
                {
                    formatMessage(NOTIFICATION_DEFAULT_MESSAGES.successNotificationMessage, {
                        action: formatMessage(actionDescriptor)
                    })
                }
            </p>
        ),
        duration: 2,
        placement: 'topRight'
    });
}

export function createErrorNotification(
    formatMessage: InjectedIntl['formatMessage'],
    actionDescriptor: FormattedMessage.MessageDescriptor
) {
    notificationStore.openNotificationWithIcon('error', {
        message: (
            <p>
                {
                    formatMessage(NOTIFICATION_DEFAULT_MESSAGES.errorNotificationMessage, {
                        action: formatMessage(actionDescriptor),
                    })
                }
            </p>
        ),
        duration: 5,
        placement: 'topRight'
    });
}

export function createCustomNotification(
    formatMessage: InjectedIntl['formatMessage'],
    message: FormattedMessage.MessageDescriptor,
    type: 'success' | 'info' | 'error',
    duration?: number,
    key?: string,
) {
    notificationStore.openNotificationWithIcon(type, {
        message: (<p>{formatMessage(message)}</p>),
        duration,
        key,
        placement: 'topRight'
    });
}
