import React from 'react';
import styles from './AsideItem.module.scss';
import { AsideItemProps } from './AsideItemModel';

const AsideItem = (props: AsideItemProps) => {
    const { children, header } = props;
    return (
        <div
            className={styles.AsideItemDefault}
        >
            {header && (typeof header === 'string' ? <p className={styles.AsideItemHeader}>{header}</p> : <div className={styles.AsideItemHeader}>{header}</div>)}
            {children}
        </div>
    );
};

export default AsideItem;
