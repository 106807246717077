import {
    OneFragmentOfSchemaType,
    Field,
    SchemaTypeIds
} from '@contentchef/contentchef-types';
import { SerializedField } from '../types';
import { ListItemIds } from '../../types';

export interface OneFragmentOfConstraints {
    required: boolean;
    names: string[];
}

export type SerializedOneFragmentOfSchema = SerializedField<OneFragmentOfConstraints, {}>;

export function retrieveOneFragmentOfEditingType(): ListItemIds {
    return ListItemIds.MultiCustomField;
}

export function deserialize(serialized: SerializedOneFragmentOfSchema): Field<OneFragmentOfSchemaType> {
    return {
        constraints: {
            required: serialized.constraints.required,
            names: serialized.constraints.names || [],
        },
        labels: serialized.labels,
        placeholder: Object.keys(serialized.placeholder).length > 0 ? serialized.placeholder : undefined,
        hint: Object.keys(serialized.hint).length > 0 ? serialized.hint : undefined,
        name: serialized.name,
        type: SchemaTypeIds.ONE_FRAGMENT_OF,
        extension: serialized.extension,
        compact: serialized.compact
    };
}

// tslint:disable-next-line:max-line-length
export function serialize(deserialized: Field<OneFragmentOfSchemaType>, initialIndex: number): SerializedOneFragmentOfSchema {
    return {
        constraints: {
            required: deserialized.constraints.required,
            names: deserialized.constraints.names,
        },
        labels: deserialized.labels,
        hint: deserialized.hint || {},
        placeholder: deserialized.placeholder || {},
        name: deserialized.name,
        settings: {},
        type: deserialized.type,
        editingType: retrieveOneFragmentOfEditingType(),
        initialIndex,
        extension: deserialized.extension,
        compact: deserialized.compact
    };
}
