import React, { Component } from 'react';
import { Provider, inject, observer } from 'mobx-react';
import {
    RepositoryDashboardStore, RepositorySummaryStore, ActivityStore
} from '../../stores';
import { WithStoresComponentProps, WithStoresWrappedComponent } from '../../constants/common-types';
import { RepositoryDashboardStoreModel } from '../../stores/repositoryDashboardStore/repositoryDashboardStoreModel';
import { ActivityStoreModel } from '../../stores/activityStore/activityStoreModel';
import { RepositorySummaryStoreModel } from '../../stores/repositorySummaryStore/repositorySummaryStoreModel';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';

type InjectedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
};

const withRepoDashboardStore = <T extends {}>(WrappedComponent: WithStoresWrappedComponent<T>) => {
    @inject('apiStore')
    @observer
    class WithRepoDashboardStore extends Component<WithStoresComponentProps<T>> {
        get injected() {
            return this.props as InjectedProps<T>;
        }
        repositoryDashboardStore: RepositoryDashboardStoreModel;
        repositorySummaryStore: RepositorySummaryStoreModel;
        activityStore: ActivityStoreModel;
        constructor(props: WithStoresComponentProps<T>) {
            super(props);
            const { api } = this.injected.apiStore;
            this.repositoryDashboardStore = new RepositoryDashboardStore(api);
            this.repositorySummaryStore = new RepositorySummaryStore(api);
            this.activityStore = new ActivityStore(api);
        }
        render() {
            return (
                <Provider
                    repositoryDashboardStore={this.repositoryDashboardStore}
                    repositorySummaryStore={this.repositorySummaryStore}
                    activityStore={this.activityStore}
                >
                    <WrappedComponent {...this.props} />
                </Provider>
            );
        }
    }

    return WithRepoDashboardStore;
};

export default withRepoDashboardStore;
