import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { SpaceSwitcherProps, InjectedProps, SpaceSwitcherPropsState } from './SpaceSwitcherModel';
import { withRouter } from 'react-router';
import { Icon, Modal } from 'antd';
import styles from './SpaceSwitcher.module.scss';
import SpaceList from '../SpaceList/SpaceList';
import { FormattedMessage } from 'react-intl';
import { CHOOSE_SPACE_ROUTE } from '../../constants/routing-constants';
import { SpaceSvg } from '../../assets/custom_icons/components/Space';

@inject('userPermissionsStore')
@observer
class SpaceSwitcher extends Component<SpaceSwitcherProps, SpaceSwitcherPropsState> {
    state: SpaceSwitcherPropsState = {
        visible: false,
    };

    get injected() {
        return this.props as InjectedProps;
    }

    openModal = () => {
        this.setState({ visible: true });
    }

    closeModal = () => {
        this.setState({ visible: false });
    }

    render() {
        const maybeSpaceName = this.injected.userPermissionsStore.activeUserSpace();

        if (this.props.match.path === CHOOSE_SPACE_ROUTE) {
            return null;
        }

        return (
            <React.Fragment>
                <div className={styles.SpaceSwitcherWrapper}>
                    <div className={styles.SpaceSwitcherContainer} onClick={this.openModal}>
                        <Icon className={styles.SpaceSwitcherSpaceIcon} component={SpaceSvg} />
                        <div className={styles.SpaceSwitcherTextContainer}>
                            <p
                                {...!!maybeSpaceName ? { title: maybeSpaceName } : undefined}
                                className={styles.SpaceSwitcherSpaceName}
                            >
                                {maybeSpaceName}
                            </p>
                            <p className={styles.SpaceSwitcherCTA}>
                                <FormattedMessage
                                    id="SpaceSwitcher.ChangeSpace"
                                    defaultMessage="change space"
                                />
                            </p>
                        </div>
                    </div>
                </div>
                <Modal
                    wrapClassName={styles.SpaceSwitcherModalWrapper}
                    centered={true}
                    visible={this.state.visible}
                    onCancel={this.closeModal}
                    title={<FormattedMessage id="SpaceSwitcher.ChooseSpace" defaultMessage="Choose a space" />}
                    footer={false}
                >
                    <SpaceList
                        spaceList={this.injected.userPermissionsStore.permissionsList}
                        onClickItem={this.closeModal}
                    />
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(SpaceSwitcher);
