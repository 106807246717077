import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import {
    CreateContentDefinitionRequest,
    GetContentDefinitionRequest, GetContentDefinitionResponse, UpdateContentDefinitionRequest
} from '@contentchef/contentchef-types';
import { action, observable, runInAction } from 'mobx';
import { routerStoreHelper } from '..';
import { ContentDefinitionStoreModel } from './contentDefinitionStoreModel';

class ContentDefinitionStore implements ContentDefinitionStoreModel {
    api: ContentChefClient;
    @observable contentDefinition: GetContentDefinitionResponse;
    @observable error = {
        setContendDefinition: ''
    };

    constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action
    async setContentDefinition(id: string) {
        this.contentDefinition = undefined;
        const reqParams: GetContentDefinitionRequest = {
            id: +id
        };
        try {
            const response = await this.api.contentDefinitions.get(reqParams);
            runInAction(() => {
                this.contentDefinition = response;
            });
        } catch (e: any) {
            runInAction(() => {
                this.error.setContendDefinition = e;
                console.log(e, 'Impossible to call singleContentDefinition');
            });
        }
    }

    @action
    async save(values: CreateContentDefinitionRequest) {
        try {
            const response = await this.api.contentDefinitions.save(values);
            routerStoreHelper.onSuccessfulContentDefinitionCreate(await this.api.spaceId, response.id);
        } catch (e) {
            console.log('Impossible to save the content definition', e);
        }
    }

    @action
    async update(values: UpdateContentDefinitionRequest) {
        try {
            await this.api.contentDefinitions.update(values);
        } catch (e) {
            console.log('Impossible to update the content definition', e);
        }
    }
}

export default ContentDefinitionStore;
