import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { defineMessages } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { withMediaGalleryStores } from '../../../hoc';
import { MediaGalleriesProps, MediaGalleriesState, InjectedProps } from './MediaGalleriesModel';
import { FloatingActions, MediaGalleryList, } from '../../../components';
import { DataAttributes, DataAttributesBuilder } from '@services/DataAttributesBuilder';
import { createMediaGalleryPath } from '@constants/routing-constants';
import { HasPermissions } from '@providers/UserPermissionsProvider';
import { userPermissionsTable } from '@constants/user-permissions-table';
import AddButton from '@components/atoms/AddButton';
import ContainerWithAside from '@components/ContainerWithAside/ContainerWithAside';
import { MEDIAGALLERY_CONSTANTS } from '@constants/media-gallery';
import { MediaGalleryListUrlParams } from '@stores/mediaGalleryListStore';
import { Pagination } from 'antd';
import MediaGalleryListFilters from '@components/MediaGalleryListFilters';

const mediaGalleriesLabels = defineMessages({
    addButtonTitle: {
        id: 'MediaGallery.Add',
        defaultMessage: 'Add new media gallery'
    }
});

@inject('mediaGalleryListStore')
@observer
class MediaGalleries extends Component<MediaGalleriesProps, MediaGalleriesState> {

    get injected() {
        return this.props as InjectedProps;
    }

    componentDidMount(): void {
        this.injected.mediaGalleryListStore.setMediaGalleries();
    }

    componentDidUpdate(prevProps: MediaGalleriesProps) {
        if (prevProps && prevProps.location.search.length > 0 && this.props.location.search.length === 0) {
            this.injected.mediaGalleryListStore.searchParams.reset();
            this.injected.mediaGalleryListStore.resetAllFilters();
            this.injected.mediaGalleryListStore.setMediaGalleries();
        }
    }

    onChangePageSize = (currentPage: number, newPageSize: number) => {
        if (newPageSize !== MEDIAGALLERY_CONSTANTS.DEFAULT_TAKE) {
            this.injected.mediaGalleryListStore.searchParams.setUrlParam(
                MediaGalleryListUrlParams.bySize, newPageSize.toString()
            );
        } else {
            this.injected.mediaGalleryListStore.searchParams.deleteUrlParam(
                MediaGalleryListUrlParams.bySize
            );
        }
        this.injected.mediaGalleryListStore.setMediaGalleries();
    }

    onChangePage = (pageNumber: number) => {
        if (pageNumber !== MEDIAGALLERY_CONSTANTS.DEFAULT_PAGE) {
            this.injected.mediaGalleryListStore.searchParams.setUrlParam(
                MediaGalleryListUrlParams.byPage, pageNumber.toString()
            );
        } else {
            this.injected.mediaGalleryListStore.searchParams.deleteUrlParam(
                MediaGalleryListUrlParams.byPage
            );
        }
        this.injected.mediaGalleryListStore.setMediaGalleries();
    }

    onSearchByName = (value?: string) => {
        if (!!value && value.trim().length > 0) {
            this.injected.mediaGalleryListStore.searchParams.setUrlParam(
                MediaGalleryListUrlParams.byName, value
            );
            this.injected.mediaGalleryListStore.setListFilterByName(value);
        } else {
            this.injected.mediaGalleryListStore.searchParams.deleteUrlParam(
                MediaGalleryListUrlParams.byName
            );
            this.injected.mediaGalleryListStore.setListFilterByName(undefined);
        }
        this.injected.mediaGalleryListStore.setMediaGalleries();
    }

    onSearchByArchived = (value: boolean) => {
        if (value) {
            this.injected.mediaGalleryListStore.searchParams.setUrlParam(
                MediaGalleryListUrlParams.byArchived, 'true'
            );
            this.injected.mediaGalleryListStore.setListFilterByArchived(value);
        } else {
            this.injected.mediaGalleryListStore.searchParams.deleteUrlParam(
                MediaGalleryListUrlParams.byArchived
            );
            this.injected.mediaGalleryListStore.setListFilterByArchived(undefined);
        }
        this.injected.mediaGalleryListStore.setMediaGalleries();
    }

    onResetFilters = () => {
        this.injected.mediaGalleryListStore.searchParams.deleteAllUrlParams();
        this.injected.mediaGalleryListStore.resetAllFilters();
        this.injected.mediaGalleryListStore.setMediaGalleries();
    }

    createSceneContainerAttributes = (): DataAttributes => {
        return new DataAttributesBuilder('mediagallery')
            .setIdAttribute('mediaGalleries_list')
            .build();
    }

    printCreateButton = () => {
        const { spaceId } = this.props.match.params;
        return (
            <Link to={createMediaGalleryPath(spaceId)}>
                <AddButton
                    dataId="mediagallery-new"
                    title={mediaGalleriesLabels.addButtonTitle}
                />
            </Link>
        );
    }

    render() {
        const {
            pagedMediaGalleryList: { items, skip, take, total },
            loaders: { list },
            listFilters: { name, archived },
            canResetAllFilters,
        } = this.injected.mediaGalleryListStore;
        return (
            <>
                <ContainerWithAside
                    drawerIcon={'filter'}
                    dataAttributes={this.createSceneContainerAttributes().Attributes}
                    footer={<Pagination
                        showSizeChanger={true}
                        pageSize={take}
                        current={(skip / take) + 1}
                        total={total}
                        onChange={this.onChangePage}
                        onShowSizeChange={this.onChangePageSize}
                    />}
                    renderAside={() => (<MediaGalleryListFilters
                        filterByNameValue={name}
                        filterByArchivedValue={archived}
                        onSearchByName={this.onSearchByName}
                        onSearchByArchived={this.onSearchByArchived}
                        onResetAllFilters={canResetAllFilters ? this.onResetFilters : undefined}
                    />)}
                >
                    <MediaGalleryList
                        isLoading={list}
                        listMediaGalleries={items}
                    />
                </ContainerWithAside>
                <HasPermissions permissions={userPermissionsTable.FloatingActions.MediaGallery}>
                    <FloatingActions>
                        {this.printCreateButton()}
                    </FloatingActions>
                </HasPermissions>
            </>
        );
    }
}

export default withMediaGalleryStores(withRouter(MediaGalleries));
