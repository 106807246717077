import { defineMessages } from 'react-intl';

export const activityLabels = defineMessages({
    create: {
        id: 'Activity.create',
        defaultMessage: 'New {subjectType} added'
    },
    update: {
        id: 'Activity.update',
        defaultMessage: '{subjectType} updated'
    },
    delete: {
        id: 'Activity.archived',
        defaultMessage: '{subjectType} archived'
    },
    archive: {
        id: 'Activity.archived',
        defaultMessage: '{subjectType} archived'
    },
    unarchive: {
        id: 'Activity.unarchived',
        defaultMessage: '{subjectType} unarchived'
    },
});

export const activityTypeLabels = defineMessages({
    repository: {
        id: 'Activity.Repository',
        defaultMessage: 'Repository'
    },
    content: {
        id: 'Activity.Content',
        defaultMessage: 'Content'
    },
    media_gallery: {
        id: 'Activity.MediaGallery',
        defaultMessage: 'Media Gallery'
    },
    content_definition: {
        id: 'Activity.ContentDefinition',
        defaultMessage: 'Content Definition'
    },
    publishing_channel: {
        id: 'Activity.PublishingChannel',
        defaultMessage: 'Publishing Channel'
    }
});
