import { Auth } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import initAuthHub from './services/AuthHub';

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID as string);

// refer to https://aws-amplify.github.io/docs/js/authentication#manual-setup
Auth.configure({
    authenticationFlowType: 'USER_SRP_AUTH',
    identityPoolId: process.env.IdentityPoolId,
    oauth: {
        domain: process.env.AppWebDomain,
        redirectSignIn: process.env.RedirectUriSignIn,
        redirectSignOut: process.env.RedirectUriSignOut,
        scope: [
            'openid',
            'profile'
        ],
        responseType: 'code',
    },
    region: process.env.Region,
    userPoolId: process.env.UserPoolId,
    userPoolWebClientId: process.env.UserPoolClientId,
});

initAuthHub();

ReactDOM.render(
    <App />,
    document.getElementById('root') as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
