import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { UserInfo, toUserInfo, DecodedPayload } from '../../services/Users';
import { Auth } from 'aws-amplify';
import { NotificationHubStore } from '../../stores/notificationHubStore';
import { FormattedMessage } from 'react-intl';
import { PROFILE_ROUTE } from '../../constants/routing-constants';
import classes from './index.module.scss';
import { CognitoUser } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

interface InjectedProps {
    notificationHubStore: NotificationHubStore;
}

interface WithUserInfoState {
    userInfo: UserInfo | undefined;
}

interface UserInfoContextValue {
    userInfo?: UserInfo;
    refreshUserInfo?(): void;
}

export const UserInfoContext = React.createContext<UserInfoContextValue>({});

@inject('notificationHubStore')
export class UserInfoProvider extends Component<{}, WithUserInfoState> {

    state: WithUserInfoState = {
        userInfo: undefined
    };

    get injected() {
        return this.props as InjectedProps;
    }

    async componentDidMount() {
        await this.refreshUserInfo();
        if (this.state.userInfo) {
            await this.checkForEmailNotVerified(this.state.userInfo);
        }
    }

    checkForEmailNotVerified = (userInfo: UserInfo) => {
        const emailVerified = userInfo.email_verified;
        if (!emailVerified) {
            const notification = this.injected.notificationHubStore.createNotification(
                'email_not_verified',
                (
                    <FormattedMessage
                        id="Navbar.emailNotVerifiedLabel"
                        defaultMessage="Your email has not been verified. Click here to proceed."
                    />
                ),
                {
                    type: 'warning',
                    className: classes.WarningIconEmailVerified
                },
                PROFILE_ROUTE
            );
            this.injected.notificationHubStore.addNotification(notification);
        }
    }

    refreshUserInfo = async () => {
        const maybeUserInfo = await Auth.currentAuthenticatedUser({ bypassCache: true });
        if (maybeUserInfo) {
            const session = (maybeUserInfo as CognitoUser).getSignInUserSession() as CognitoUserSession;
            const userInfo = toUserInfo(session.getIdToken().decodePayload() as DecodedPayload);
            this.setState({
                userInfo
            });
        }
    }

    render() {
        return (
            <UserInfoContext.Provider
                value={{
                    userInfo: this.state.userInfo,
                    refreshUserInfo: this.refreshUserInfo
                }}
            >
                {this.props.children}
            </UserInfoContext.Provider>
        );
    }
}

export const withUserInfo = <T extends {}>(WrappedComponent: React.ComponentType<T>) => {
    class WithUserInfo extends Component<T> {
        render() {
            return (
                <UserInfoContext.Consumer>
                    {
                        userInfoValue => (
                            <WrappedComponent
                                refreshUserInfo={
                                    userInfoValue.refreshUserInfo
                                }
                                userInfo={
                                    userInfoValue.userInfo
                                }
                                {...this.props}
                            />
                        )
                    }
                </UserInfoContext.Consumer>
            );
        }
    }

    return WithUserInfo;
};
