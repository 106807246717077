import { UserInvite } from '@contentchef/contentchef-types';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { notification } from 'antd';
import { Auth } from 'aws-amplify';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Loader } from '../../components';
import { ROOT_ROUTE } from '../../constants/routing-constants';
import withInvites from '../../hoc/withInvites';
import { UserPermissionStore } from '../../stores';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';
import { InviteStoreModel } from '../../stores/inviteStore';
import { OnboardingStoreModel } from '../../stores/onboardingStore';
import Invite from './Invite';
import InviteAlreadyAccepted from './InviteAlreadyAccepted';
import InviteNotFound from './InviteNotFound';
import UserAlreadyInSpace from './UserAlreadyInSpace';

export interface AcceptInviteSceneProps extends RouteComponentProps {

}

interface DecoratedAcceptInviteSceneProps extends AcceptInviteSceneProps {
    apiStore: ApiStoreModel;
    inviteStore: InviteStoreModel;
    onboardingStore: OnboardingStoreModel;
    userPermissionsStore: UserPermissionStore;
}

interface AcceptInviteSceneState {
    didLoad: boolean;
    isAccepting: boolean;
    isUserAlreadyInSpace: boolean;
}

function getInitialState(): AcceptInviteSceneState {
    return {
        didLoad: false,
        isAccepting: false,
        isUserAlreadyInSpace: false,
    };
}

function getQuerystring(location: Location) {
    const urlparams = new URLSearchParams(location.search);
    const guid = urlparams.get('invite');
    const spaceId = urlparams.get('spaceId');
    const email = urlparams.get('email');

    return {
        guid,
        spaceId,
        email
    };
}

function isInvite(arg: unknown): arg is UserInvite {
    return (
        typeof arg === 'object' &&
        arg !== null &&
        'guid' in arg &&
        'spaceId' in arg
    );
}

function isString(arg: unknown): arg is string {
    return typeof arg === 'string';
}

@inject('apiStore', 'inviteStore', 'onboardingStore', 'userPermissionsStore')
@observer
class AcceptInviteScene extends React.Component<AcceptInviteSceneProps, AcceptInviteSceneState> {
    public get decoratedProps(): DecoratedAcceptInviteSceneProps {
        return this.props as DecoratedAcceptInviteSceneProps;
    }

    public state = getInitialState();

    public async componentDidMount(): Promise<void> {
        const { guid, spaceId } = getQuerystring(this.props.location as any);

        if (!isString(guid) || !isString(spaceId)) {
            return;
        }

        this.decoratedProps.apiStore.changeCurrentSpace(spaceId);

        const inviteStore = this.decoratedProps.inviteStore;

        await inviteStore.get(guid);

        this.setState({ didLoad: true });
    }

    public handleAccept = async () => {
        const {
            apiStore,
            inviteStore,
            onboardingStore,
            userPermissionsStore,
        } = this.decoratedProps;
        const {
            guid,
            spaceId,
            email
        } = getQuerystring(this.props.location as any);

        this.setState({ isAccepting: true });

        try {
            await inviteStore.acceptInvite({ guid: guid!, email: email! });
            const initialPermissionsCount = userPermissionsStore.permissionsList.length;
            const timeout = 2500;
            const poll = async (pollCount: number) => {
                await userPermissionsStore.list();
                const permissionsCount = userPermissionsStore.permissionsList.length;

                if (pollCount > 10) {
                    throw new Error('');
                }

                if (permissionsCount <= initialPermissionsCount) {
                    setTimeout(poll, timeout, pollCount + 1);
                    return;
                }

                notification.success({
                    message: (
                        <FormattedMessage
                            defaultMessage="Access granted to space {spaceId}"
                            id="scenes.AcceptInvite.acceptSuccess"
                            values={{ spaceId }}
                        />
                    )
                });

                const user = await Auth.currentAuthenticatedUser();
                const session = await Auth.currentSession();

                user.refreshSession(session.getRefreshToken(), async (error: Error, response: CognitoUserSession) => {
                    if (error || response === null) {
                        return;
                    }

                    await onboardingStore.postAcceptInvite();
                    await userPermissionsStore.list();
                    apiStore.switchToSpace(spaceId!);
                });
            };

            setTimeout(poll, timeout, 0);
        } catch (error: any) {
            notification.error({
                message: (
                    <FormattedMessage
                        defaultMessage="{error}"
                        id="scenes.AcceptInvite.acceptError"
                        values={{ error: error.message }}
                    />
                )
            });
            this.setState({
                isAccepting: false,
                isUserAlreadyInSpace: error.type === 'UserAlreadyInSpaceError',
            });
        }
    }

    public handleHomeRedirect = () => this.props.history.push(ROOT_ROUTE);

    public render() {
        const { isUserAlreadyInSpace } = this.state;
        const invite = this.decoratedProps.inviteStore.currentInvite;

        if (!this.state.didLoad) {
            return (
                <Loader />
            );
        }

        return (
            <div>
                {
                    !isInvite(invite) &&
                    <InviteNotFound onClick={this.handleHomeRedirect} />
                }
                {
                    isInvite(invite) &&
                    invite.accepted &&
                    !isUserAlreadyInSpace &&
                    <InviteAlreadyAccepted onClick={this.handleHomeRedirect} />
                }
                {
                    isInvite(invite) &&
                    !invite.accepted &&
                    !isUserAlreadyInSpace &&
                    <Invite
                        invite={invite}
                        isAccepting={this.state.isAccepting}
                        onClick={this.handleAccept}
                    />
                }
                {
                    isUserAlreadyInSpace &&
                    <UserAlreadyInSpace onClick={this.handleHomeRedirect} />
                }
            </div>
        );
    }
}

export default withInvites(
    AcceptInviteScene
);
