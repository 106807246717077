import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Col, Row, ConfigProvider } from 'antd';
import { FormattedMessage } from 'react-intl';
import { WithLoader } from '../../hoc';
import Button from '@components/Button';
import { MediaList, MediaActions, Loader, FolderNavigation, FloatingActions } from '..';
import LoaderManagerStore from '@stores/loaderManagerStore/loaderManagerStore';
import { LoaderManagerStoreModel } from '@stores/loaderManagerStore/loaderManagerStoreModel';
import { MediaFinderProps, InjectedProps, MediaFinderState } from './MediaFinderModel';
import { TagsSelectorWithoutPermission } from '../TagsSelector/TagsSelector';
import { withRouter } from 'react-router';
import { withMediaNavigation, MediaNavigationContext } from '@providers/MediaNavigationProvider';

import styles from './MediaFinder.module.scss';

@inject('mediaGalleryFlowStore', 'mediaTagsStore')
@observer
class MediaFinder extends Component<MediaFinderProps & MediaNavigationContext, MediaFinderState> {
    loaderManager: LoaderManagerStoreModel;

    state: MediaFinderState = {
        selectedTags: []
    };

    get injected() {
        return this.props as InjectedProps;
    }

    constructor(props: MediaFinderProps) {
        super(props);
        this.loaderManager = new LoaderManagerStore();
    }

    async componentDidMount() {
        const { match: { params: { folderPath } }, localFolderPath, isForm } = this.props;
        if (folderPath || localFolderPath) {
            this.injected.mediaGalleryFlowStore.setFolderPath(
                localFolderPath ? localFolderPath : folderPath as string, isForm);
        } else {
            this.injected.mediaGalleryFlowStore.clearFolderPath();
        }
        this.loaderManager.setLoader();
        await this.injected.mediaGalleryFlowStore.initializeMediaData(this.props.resourceType);
        this.loaderManager.unsetLoader();
    }

    async componentDidUpdate(prevProps: MediaFinderProps) {
        if (this.props.reloadOnBackButton) {
            const { match: { params: { folderPath: prevFolderPath } } } = prevProps;
            const { match: { params: { folderPath: currentFolderPath } }, history: { action } } = this.props;
            if ((prevFolderPath !== currentFolderPath) && action === 'POP') {
                await this.injected.mediaGalleryFlowStore.reinitializeMediaAndFolders(currentFolderPath);
            }
        }
    }

    onConfirm = () => {
        const { jumpToPreview, validType } = this.props;
        const { media } = this.injected.mediaGalleryFlowStore;
        if (jumpToPreview && validType && media && validType(media)) {
            jumpToPreview(media.data.publicId);
        }
    }

    onChangeSelectedTags = async (value: string[]) => {
        this.setState({
            selectedTags: value
        });

        await this.injected.mediaGalleryFlowStore.setMediaListGivenTags(value.join(', '));
    }

    onSearchSuggestedTags = async (value: string) => {
        await this.injected.mediaTagsStore.setSuggestedTags(value);
    }

    onChangeFolder = () => {
        this.setState({
            selectedTags: []
        });
    }

    render() {
        const { isForm = false, nextStep, prevStep, onMediaClick, validType } = this.props;
        const { media, loader: { finder } } = this.injected.mediaGalleryFlowStore;
        const { loader: { suggestedTags: loaderSuggestedTags }, suggestedTags } = this.injected.mediaTagsStore;
        return (
            <WithLoader showLoader={this.loaderManager.mustShowLoader}>
                <div className={styles.MediaFinderContainer} data-id="media-list">
                    <Row className={styles.MediaFinderSelectContainer} type="flex" >
                        <Col xs={24} lg={16} className={styles.MediaFinderFolderSelector}>
                            <FolderNavigation prevStep={prevStep} />
                        </Col>
                        <Col xs={24} lg={8} className={styles.MediaFinderTagsSelect}>
                            <ConfigProvider
                                getPopupContainer={() => document.body}
                            >
                                <TagsSelectorWithoutPermission
                                    loading={loaderSuggestedTags}
                                    suggestedTags={suggestedTags}
                                    value={this.state.selectedTags}
                                    onChange={this.onChangeSelectedTags}
                                    onSearch={this.onSearchSuggestedTags}
                                    hasPermissions={true}
                                />
                            </ConfigProvider>
                        </Col>
                    </Row>
                    <div className={isForm ? styles.MediaFinderBodyWithForm : styles.MediaFinderBodyWithoutForm}>
                        {finder ?
                            <Loader />
                            :
                            <MediaList onClick={onMediaClick} />
                        }
                    </div>
                    <FloatingActions>
                        <MediaActions
                            nextStep={nextStep}
                        />
                    </FloatingActions>
                    {isForm && (
                        <div className={styles.MediaFinderFooter}>
                            <Button
                                className={styles.MediaFinderConfirm}
                                size="large"
                                disabled={!media || !validType!(media)}
                                type="primary"
                                onClick={this.onConfirm}
                            >
                                <FormattedMessage
                                    id="MediaFinder.confirm"
                                    defaultMessage="Ok"
                                />
                            </Button>
                        </div>
                    )}
                </div>
            </WithLoader>
        );
    }
}

export default withMediaNavigation(withRouter(MediaFinder));
