import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import ContentDefinitionAddElement from '@components/ContentDefinitionSchemaEditor/ContentDefinitionAddElement';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import {
    contentDefinitionCustomFieldNewPath,
    contentDefinitionCustomFieldsEditPath,
    contentDefinitionCustomFieldsListBackPath
} from '@constants/routing-constants';
import { inject, observer } from 'mobx-react';
import {
    ContentDefinitionSchemaStoreModel
} from '@stores/ContentDefinitionEditorStore/ContentDefinitionSchemaStore';
import { FragmentDefinition } from '@contentchef/contentchef-types';
import { ContainerWithAside } from '../../../components';
import Aside from '@components/Aside/Aside';
import AsideItem from '@components/AsideItem/AsideItem';
import Button from '@components/Button';
import { NavStoreModel, NAVIGATION_PRIORITY } from '@stores/navStore/navStoreModel';
import { NavigationItem } from '@stores/navStore/navStore';
import { NAVIGATION_LABELS } from '@constants/navigation-internationalized-labels';
import { NAVIGATION_ITEM_IDENTIFIERS } from '@constants/navigation-item-identifier';
import CustomFIeldElement from '@components/ContentDefinitionSchemaEditor/CustomFIeldElement';

export interface ContentDefinitionCustomFieldListMatchParams {
    spaceId: string;
    contentDefId?: string;
}

export interface ContentDefinitionCustomFieldListProps extends
    RouteComponentProps<ContentDefinitionCustomFieldListMatchParams>, InjectedIntlProps { }

export interface DecoratedProps extends ContentDefinitionCustomFieldListProps {
    contentDefinitionSchemaStore: ContentDefinitionSchemaStoreModel;
    navStore: NavStoreModel;
}

@inject('contentDefinitionSchemaStore', 'navStore')
@observer
class ContentDefinitionCustomFieldList extends React.Component<ContentDefinitionCustomFieldListProps> {
    get decoratedProps() { return this.props as DecoratedProps; }

    handleClone = (key: string, fragment: FragmentDefinition, newFragmentKey: string) => {
        this.decoratedProps.contentDefinitionSchemaStore.cloneFragment(key, fragment, newFragmentKey);
    }

    handleEdit = (key: string) => {
        const { history, match: { params: { spaceId, contentDefId } } } = this.props;
        history.push(contentDefinitionCustomFieldsEditPath(spaceId, key, contentDefId));
    }

    handleRemove = (key: string) => {
        this.decoratedProps.contentDefinitionSchemaStore.deleteFragment(key);
    }

    handleSidebarGoBack = () => {
        const {
            match: { params: { spaceId, contentDefId } },
            history,
        } = this.props;
        history.push(contentDefinitionCustomFieldsListBackPath(spaceId, contentDefId));
    }

    handleAddCustomField = () => {
        const {
            match: { params: { spaceId, contentDefId } },
            history,
        } = this.props;
        history.push(contentDefinitionCustomFieldNewPath(spaceId, contentDefId));
    }

    retrieveFragmentFriendlyName = (fragment: FragmentDefinition) => {
        const { lang } = this.decoratedProps.contentDefinitionSchemaStore.schema;
        if (fragment.labels !== undefined) {
            return fragment.labels[lang];
        }
        return undefined;
    }

    isFragmentNameInUse = (newFragmentName: string) => {
        const { contentDefinitionSchemaStore: { schema: { fragments } } } = this.decoratedProps;
        return !!Object.keys(fragments).find(fragmentName => fragmentName === newFragmentName);
    }

    renderCustomFieldList = () => {
        const { contentDefinitionSchemaStore: { schema: { fragments }, customFieldHasErrors } } = this.decoratedProps;

        return Object.keys(fragments).sort().map(fragmentId => {
            const fragment = fragments[fragmentId];
            const fragmentFieldsName = fragment.fields.map(field => field.name);
            const fragmentFriendlyName = this.retrieveFragmentFriendlyName(fragment) || fragmentId;
            return (
                <CustomFIeldElement
                    key={fragmentId}
                    hasErrors={customFieldHasErrors(fragmentId)}
                    draggable={false}
                    fieldName={fragmentFriendlyName}
                    relatedFields={fragmentFieldsName}
                    fieldId={fragmentId}
                    onClone={(newFragmentKey: string) => this.handleClone(fragmentId, fragment, newFragmentKey)}
                    onEdit={() => this.handleEdit(fragmentId)}
                    onRemove={() => this.handleRemove(fragmentId)}
                    onCheckNameAlreadyExist={this.isFragmentNameInUse}
                />
            );
        });
    }

    componentDidMount() {
        this.decoratedProps.navStore.pushToPath(
            new NavigationItem(
                NAVIGATION_ITEM_IDENTIFIERS.CUSTOM_FIELD_LIST,
                this.props.location.pathname,
                this.props.intl.formatMessage(NAVIGATION_LABELS.customFields),
                NAVIGATION_PRIORITY.THIRD
            )
        );
    }

    componentWillUnmount() {
        this.decoratedProps.navStore.removeItem(NAVIGATION_ITEM_IDENTIFIERS.CUSTOM_FIELD_LIST);
    }

    public render() {
        return (
            <ContainerWithAside
                renderAside={() => (
                    <Aside>
                        <AsideItem>
                            <Button
                                style={{ width: '100%' }}
                                key="go-back-to-definition"
                                type="default"
                                onClick={this.handleSidebarGoBack}
                            >
                                <FormattedMessage
                                    defaultMessage="BACK TO DEFINITION"
                                    id="scenes.ContentDefinitions.ContentDefinitionCustomFieldList.back"
                                />
                            </Button>
                        </AsideItem>
                    </Aside>
                )}
            >
                {this.renderCustomFieldList()}
                <ContentDefinitionAddElement onClick={this.handleAddCustomField} />
            </ContainerWithAside>
        );
    }
}

export default withRouter(injectIntl(ContentDefinitionCustomFieldList));
