import { NAVIGATION_LABELS } from '@constants/navigation-internationalized-labels';
import { NAVIGATION_ITEM_IDENTIFIERS } from '@constants/navigation-item-identifier';
import {
    spaceSettingsPath,
    SPACE_SETTINGS_ROUTE
} from '@constants/routing-constants';
import withSpaceDetailStore from '@hoc/withSpaceDetailStore';
import { useNavStore } from '@hooks/mobx';
import { NavigationItem } from '@stores/navStore/navStore';
import { NAVIGATION_PRIORITY } from '@stores/navStore/navStoreModel';
import React, { useEffect } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Route, RouteComponentProps, Switch } from 'react-router';
import SpaceSettings from './SpaceSettings';

interface SettingsRouterProps extends RouteComponentProps<{ spaceId: string }>, InjectedIntlProps { }

function SettingsRouter(props: SettingsRouterProps) {

    const navStore = useNavStore();

    useEffect(
        () => {
            navStore.setNavigationPath(new NavigationItem(
                NAVIGATION_ITEM_IDENTIFIERS.SPACE_SETTINGS,
                spaceSettingsPath(props.match.params.spaceId),
                props.intl.formatMessage(NAVIGATION_LABELS.spaceSettings),
                NAVIGATION_PRIORITY.FIRST
            ));
        },
        [navStore, props.intl, props.match.params.spaceId]
    );

    return (
        <Switch>
            <Route
                exact={true}
                component={SpaceSettings}
                path={SPACE_SETTINGS_ROUTE}
            />
        </Switch>
    );
}

export default withSpaceDetailStore(injectIntl(SettingsRouter));
