import React, { Component } from 'react';
import { SideBar } from '../../components';
import { Layout } from 'antd';
import styles from './index.module.scss';
import NavbarLayout from '../../hoc/NavbarLayout/NavbarLayout';
import { Route } from 'react-router';
import {
    SPACE_ROUTE, PROFILE_ROUTE, SUBSCRIPTION_MANAGEMENT_ROUTE,
    PROFILE_SECURITY_ROUTE, ACCESS_TOKEN_ROUTE, INVITES_ROUTE
} from '../../constants/routing-constants';
import SideBarUserSettings from '../SideBarUserSettings';
import { Helmet } from 'react-helmet';

interface BaseLayoutProps { }

class BaseLayout extends Component<BaseLayoutProps> {

    render() {
        return (
            <Layout className={styles.BaseLayoutContainer}>
                <Helmet>
                    <title>
                        ContentChef
                    </title>
                    <link rel="canonical" href="https://app.contentchef.io" />
                    <meta name="description" content="Accelerate content management for any digital experience." />
                </Helmet>
                <NavbarLayout
                    layoutClassName={styles.BaseLayoutContent}
                >
                    <Route
                        path={SPACE_ROUTE}
                        component={SideBar}
                    />
                    <Route
                        path={[PROFILE_ROUTE, SUBSCRIPTION_MANAGEMENT_ROUTE, PROFILE_SECURITY_ROUTE,
                            ACCESS_TOKEN_ROUTE, INVITES_ROUTE]}
                        component={SideBarUserSettings}
                    />
                    {this.props.children}
                </NavbarLayout>
            </Layout>
        );
    }
}

export default BaseLayout;
