import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import { action, observable, runInAction } from 'mobx';
import { UserProfileIds } from '@contentchef/contentchef-types';
import { UserInvite } from '@contentchef/contentchef-types';

export type Guid = string;
export type Email = string;
export interface AcceptInviteParams {
    guid: Guid;
    email: Email;
}

export interface InviteStoreModel {
    /**
     *
     *
     * @type {(UserInvite|null)}
     * @memberof InviteStoreModel
     */
    currentInvite: UserInvite | null;
    /**
     *
     *
     * @type {UserInvite[]}
     * @memberof InviteStoreModel
     */
    invites: UserInvite[];
    /**
     *
     *
     * @type {boolean}
     * @memberof InviteStoreModel
     */
    isLoadingList: boolean;
    /**
     *
     *
     * @param {AcceptInviteParams} params
     * @returns {Promise<boolean>}
     * @memberof InviteStoreModel
     */
    acceptInvite(params: AcceptInviteParams, spaceId?: string): Promise<boolean>;
    /**
     *
     *
     * @param {Guid} guid
     * @returns {Promise<boolean>}
     * @memberof InviteStoreModel
     */
    get(guid: Guid): Promise<boolean>;
    /**
     *
     *
     * @returns {Promise<boolean>}
     * @memberof InviteStoreModel
     */
    listForSpace(spaceId?: string): Promise<boolean>;
    /**
     *
     * @param {(string|undefined)} spaceId
     * @returns {Promise<boolean>}
     * @memberof InviteStoreModel
     */
    pollListForSpace(spaceId?: string): Promise<boolean>;
    /**
     *
     *
     * @returns {Promise<boolean>}
     * @memberof InviteStoreModel
     */
    listForUser(): Promise<boolean>;
    /**
     *
     *
     * @returns {Promise<boolean>}
     * @memberof InviteStoreModel
     */
    pollListForUser(): Promise<boolean>;
    /**
     *
     *
     * @param {Email} email
     * @param {UserProfileIds[]} profileIds
     * @param {string} spaceId
     * @returns {Promise<boolean>}
     * @memberof InviteStoreModel
     */
    inviteUser(email: Email, profileIds: UserProfileIds[], spaceId: string): Promise<boolean>;
    /**
     *
     *
     * @param {UserInvite} invite
     * @returns {Promise<boolean>}
     * @memberof InviteStoreModel
     */
    revoke(invite: UserInvite): Promise<boolean>;
}

export class InviteStore implements InviteStoreModel {
    @observable public currentInvite: UserInvite | null = null;
    @observable public invites: UserInvite[] = [];
    @observable public isLoadingList: boolean = false;

    public constructor(protected api: ContentChefClient) { }

    protected async createPollFor(key: keyof InviteStore, pollCountLimit: number = 20, ...params: any[]) {
        const length = this.invites.length;
        const poll = async (currentPollCount: number, resolve: <T>(arg?: T) => any) => {
            await (this[key] as any).apply(this, params);

            if (length !== this.invites.length) {
                return resolve();
            }

            if (currentPollCount >= pollCountLimit) {
                return resolve();
            }

            setTimeout(poll, 2500, currentPollCount + 1, resolve);
        };

        return new Promise(resolve => poll(pollCountLimit, resolve));
    }

    @action
    public async acceptInvite({ guid, email }: AcceptInviteParams, spaceId?: string): Promise<boolean> {
        try {
            return this.api.invites.acceptInvite({ guid, email }, spaceId);
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    @action
    public async get(guid: Guid): Promise<boolean> {
        try {
            const invite = await this.api.invites.get({ guid });

            runInAction(() =>
                this.currentInvite = invite
            );
            return !!this.currentInvite;
        } catch (error) {
            console.log(error);
            runInAction(() => this.currentInvite = null);
            return false;
        }
    }

    @action
    public async listForSpace(spaceId?: string): Promise<boolean> {
        let result = false;

        runInAction(() => this.isLoadingList = true);

        try {
            const invites = await this.api.invites.listForSpace(spaceId);
            runInAction(() => this.invites = invites);
            result = this.invites.length > 0;
        } catch (error) {
            console.log(error);
            runInAction(() => this.invites = []);
        }

        runInAction(() => this.isLoadingList = false);
        return result;
    }

    @action
    public async listForUser(): Promise<boolean> {
        try {
            const invites = await this.api.invites.listForUser();
            runInAction(() => this.invites = invites);
            return this.invites.length > 0;
        } catch (error) {
            console.log(error);
            runInAction(() => this.invites = []);
            return false;
        }
    }

    @action
    public async pollListForSpace(spaceId?: string) {
        await this.createPollFor('listForSpace', 20, spaceId);
        return true;
    }

    @action
    public async pollListForUser() {
        await this.createPollFor('listForUser');
        return true;
    }

    @action
    public async inviteUser(email: Email, profileIds: UserProfileIds[], spaceId: string): Promise<boolean> {
        try {
            return this.api.invites.createInvite({ email, profileIds }, spaceId);
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    @action
    public async revoke({ guid, spaceId }: UserInvite): Promise<boolean> {
        try {
            await this.api.invites.revokeInvite({ guid }, spaceId);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    }
}
