import React, { FormEvent } from 'react';
import { withFormInitialization, withForm } from '../../hoc';
import Form, { FormComponentProps, ValidationRule } from 'antd/lib/form';
import FormItem from 'antd/lib/form/FormItem';
import Button from '@components/Button';
import { FormattedMessage } from 'react-intl';

interface SimpleFormField {
    id: string;
    component: React.ReactNode;
    initialValue?: string | number;
    rules?: ValidationRule[];
}

interface SimpleFormProps extends FormComponentProps {
    fields: SimpleFormField[];
    layout: 'horizontal' | 'vertical' | 'inline';
    onSubmit(fields: object): void;
}

class SimpleForm extends React.Component<SimpleFormProps> {

    onSubmit = (e: FormEvent) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }
            this.props.onSubmit(values);
        });
    }

    render() {
        const { form, fields, layout } = this.props;

        return (
            <Form
                layout={layout}
                onSubmit={this.onSubmit}
            >
                {fields.map((field) => {
                    return (
                        <FormItem
                            key={field.id}
                        >
                            {form.getFieldDecorator(
                                field.id as string,
                                {
                                    rules: field.rules,
                                    initialValue: field.initialValue
                                })(field.component)}
                        </FormItem>
                    );
                })}
                <div style={{ textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit">
                        <FormattedMessage
                            id="SimpleForm.labels.ok"
                            defaultMessage="OK"
                        />
                    </Button>
                </div>
            </Form>
        );
    }
}

export default withFormInitialization(withForm(SimpleForm));
