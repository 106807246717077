import Button from '@components/Button';
import { NotificationStoreModel } from '@stores/notificationStore/notificationStoreModel';
import { OnboardingStoreModel } from '@stores/onboardingStore';
import { Form, notification } from 'antd';
import Checkbox from 'antd/lib/checkbox';
import { FormComponentProps } from 'antd/lib/form';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { defineMessages, FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { withForm, withFormInitialization } from '../../hoc';
import UsersStore from '../../stores/usersStore';
import classes from './index.module.scss';
import OnboardingLogo from './OnboardingLogo';
import OnboardingPrivacy from './OnboardingPrivacy';

const updateConsentsLabels = defineMessages({
    updateError: {
        id: 'UpdateContents.notification.error',
        // tslint:disable-next-line:max-line-length
        defaultMessage: 'An error occurred upgrading your {updateCount, plural, one {consent} other {consents}}. Please try again'
    },
    termsAndConditions: {
        defaultMessage: `terms and conditions policy is mandatory to proceed`,
        id: 'UpdateConsents.policy.termsAndConditions'
    },
    privacy: {
        defaultMessage: `privacy policy is mandatory to proceed`,
        id: 'UpdateConsents.policy.privacy'
    }
});

export interface UpdateConsentsProps extends FormComponentProps, InjectedIntlProps {

}

interface DecoratedUpdateConsentsProps extends UpdateConsentsProps {
    onboardingStore: OnboardingStoreModel;
    usersStore: UsersStore;
    notificationStore: NotificationStoreModel;
}

@inject('onboardingStore', 'usersStore', 'notificationStore')
@observer
class UpdateConsents
    extends React.Component<UpdateConsentsProps, {}> {
    formFieldIds = {
        termsAndConditions: 'terms and conditions',
        privacy: 'privacy'
    };

    public get decoratedProps(): DecoratedUpdateConsentsProps {
        return this.props as DecoratedUpdateConsentsProps;
    }

    public handleUpdateConsents = async () => {
        this.props.form.validateFields(async (error, values) => {
            if (error) {
                return;
            }

            const { formatMessage } = this.props.intl;
            const {
                [this.formFieldIds.privacy]: privacyVal,
                [this.formFieldIds.termsAndConditions]: termsAndConditionsVal
            } = values;
            const { onboardingStore } = this.decoratedProps;

            const areUpdateValid = await onboardingStore.updateConsents(termsAndConditionsVal, privacyVal);

            if (areUpdateValid) {
                await onboardingStore.postAcceptEula();
            } else {
                notification.error({
                    type: 'error',
                    placement: 'topRight',
                    duration: 5,
                    message: formatMessage(
                        updateConsentsLabels.updateError,
                        { updateCount: Object.keys(values).length }
                    )
                });
            }
        });
    }

    public render() {
        const { form: { getFieldDecorator }, intl: { formatMessage } } = this.props;
        const {
            usersStore: { currentUser },
            onboardingStore: { loading }
        } = this.decoratedProps;


        const consents = currentUser ? currentUser.consents : null;

        return (
            <Form className={classes.OnboardingForm} style={{ margin: '0 auto' }}>
                <Form.Item>
                    <OnboardingLogo />
                </Form.Item>
                <Form.Item>
                    <FormattedMessage
                        defaultMessage="Before proceeding"
                        id="UpdateConsents.title"
                    />
                </Form.Item>
                {consents && consents.termsAndConditions.signedVersion !== consents.termsAndConditions.latestVersion && <Form.Item>
                    {
                        getFieldDecorator(this.formFieldIds.termsAndConditions, {
                            initialValue: false,
                            valuePropName: 'checked',
                            rules: [{
                                message:
                                    formatMessage(updateConsentsLabels.termsAndConditions),
                                validator: (rule, value, callback) => {
                                    if (value === false) { callback(rule.message); }
                                    callback();
                                },
                                type: 'boolean',
                                required: true,
                            }]
                        })(
                            <Checkbox disabled={loading.updateConsents}>
                                <FormattedMessage
                                    defaultMessage="I accept {termsAndConditions} policy"
                                    id="UpdateConsents.policy.termsAndConditions.agree"
                                    values={{
                                        termsAndConditions: <a
                                            href="https://www.contentchef.io/terms-and-conditions"
                                            target="_blank" rel="noreferrer"
                                        >
                                            terms and conditions
                                        </a>
                                    }}
                                />
                            </Checkbox>
                        )
                    }
                </Form.Item>}
                {consents && consents.privacy.signedVersion !== consents.privacy.latestVersion && <Form.Item>
                    {
                        getFieldDecorator(this.formFieldIds.privacy, {
                            initialValue: false,
                            valuePropName: 'checked',
                            rules: [{
                                message:
                                    formatMessage(updateConsentsLabels.privacy),
                                validator: (rule, value, callback) => {
                                    if (value === false) { callback(rule.message); }
                                    callback();
                                },
                                type: 'boolean',
                                required: true,
                            }]
                        })(
                            <Checkbox disabled={loading.updateConsents}>
                                <FormattedMessage
                                    defaultMessage="I accept {privacy} policy"
                                    id="UpdateConsents.policy.privacy.agree"
                                    values={{
                                        privacy: <a
                                            href="https://www.iubenda.com/privacy-policy/41328736"
                                            target="_blank" rel="noreferrer"
                                        >
                                            privacy
                                        </a>
                                    }}
                                />
                            </Checkbox>
                        )
                    }
                </Form.Item>}
                <Form.Item>
                    <Button
                        disabled={loading.updateConsents}
                        loading={loading.updateConsents}
                        onClick={this.handleUpdateConsents}
                        type="primary"
                    >
                        <FormattedMessage
                            defaultMessage="Continue to dashboard"
                            id="UpdateConsents.button"
                        />
                    </Button>
                </Form.Item>
                <Form.Item>
                    <OnboardingPrivacy />
                </Form.Item>
            </Form>
        );
    }
}

export default withFormInitialization(withForm(injectIntl(UpdateConsents)));
