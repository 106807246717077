import { MEDIAGALLERY_CONSTANTS, MEDIA_CONSTANTS } from '@constants/media-gallery';
import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import {
    Folder, ListMediaGalleryFoldersRequest, ListMediaGalleryRequest, ListMediaRequest, MediaGallery, MediaType,
    UploadMediaResponse
} from '@contentchef/contentchef-types';
import {
    fromMultiMediaToAdaptedMediaArray, fromSingleMediaToAdaptedMedia,
    MediaAdapter, MediaAdapterDataModel
} from '@services/Media/MediaConverterClass';
import { action, computed, IObservableArray, observable, runInAction } from 'mobx';
import { Media } from '../Media';
import { LoadingModel, MediaGalleryFlowStoreModel, PaginationMediaGallery } from './mediaGalleryFlowStoreModel';

class MediaGalleryFlowStore implements MediaGalleryFlowStoreModel {
    api: ContentChefClient;
    @observable folderPath: string = '';
    @observable newFolderName: string = '';
    @observable resourceType: MediaType | undefined = undefined;
    @observable mediaGalleries: IObservableArray<MediaGallery> = observable.array([]);
    @observable isLoadingMediaGalleries: boolean = false;
    @observable paginationMediaGallery: PaginationMediaGallery = {
        take: MEDIAGALLERY_CONSTANTS.DEFAULT_TAKE,
        total: 0,
        current: 1
    };

    @observable mediaGallery: MediaGallery = {} as MediaGallery;
    @observable mediaList: IObservableArray<MediaAdapter> = observable.array([]);
    @observable nextCursor: string | null = null;
    @observable media: MediaAdapter | undefined;

    @observable folders: IObservableArray<Folder> = observable.array([]);

    @observable loader: LoadingModel = {
        finder: false
    };

    @observable includeFolders: boolean = true;

    @observable listMediaGalleryParams: ListMediaGalleryRequest = {
        take: MEDIAGALLERY_CONSTANTS.DEFAULT_TAKE,
        skip: MEDIAGALLERY_CONSTANTS.DEFAULT_SKIP,
        filters: {
            name: undefined,
            archived: undefined
        }
    };

    @observable mediaToUpload: IObservableArray<Media> = observable.array([]);

    @observable selectedMediaToDelete: IObservableArray<MediaAdapter> = observable.array([]);

    constructor(api: ContentChefClient, readonly spaceId: string, readonly useLocalStorage: boolean = false) {
        this.api = api;
    }

    @action
    initMediaToUpload(files: FileList, limitFiles?: number) {
        const mediaToUpload: Media[] = [];
        const numberOfFilesToInit = limitFiles || files.length;
        for (let index = 0; index < numberOfFilesToInit; index++) {
            const file = files.item(index) as File;
            mediaToUpload.push(
                new Media(
                    this.api,
                    this.mediaGallery.id,
                    this.buildParentFolder(),
                    file
                )
            );
        }
        this.mediaToUpload.replace(mediaToUpload);
    }

    @action
    removeMediaToUpload(mediaToUploadToRemove: Media) {
        this.mediaToUpload.remove(mediaToUploadToRemove);
    }

    @action
    removeSuccessfullyUploadedMedia() {
        this.mediaToUpload.replace(this.mediaToUpload.filter(media => !media.action.uploaded));
    }

    @action
    async uploadAllFiles() {
        const uploads = this.mediaToUpload.map(media => {
            return media.upload();
        });
        return await Promise.allSettled(uploads);
    }

    @action
    removeAllMediaToUpload() {
        this.mediaToUpload.clear();
    }

    @computed
    get areMediaReadyToBeUploaded() {
        return this.mediaToUpload.filter(media => media.readyToUpload === false).length === 0;
    }

    @computed
    get mediaAreUploading() {
        return this.mediaToUpload.filter(media => media.loader.uploading === false).length === 0;
    }

    @action
    async initializeMediaData(resourceType?: MediaType) {
        const preferences = this.getMediaGalleryPreferences();
        this.setFolderName('');
        this.setResourceType(resourceType);
        this.clearSelectedMedia();
        this.nextCursor = '';
        const promises: Promise<unknown>[] = [this.setFolders(), this.setMediaList()];
        if (this.useLocalStorage && preferences.mediaGalleryId) {
            promises.push(this.setMediaGalleryFromApi(preferences.mediaGalleryId));
        }
        return await Promise.all(promises);
    }

    @action
    async initializeMediaGalleries() {
        this.paginationMediaGallery.total = 0;
        this.paginationMediaGallery.current = 1;
        this.paginationMediaGallery.take = MEDIAGALLERY_CONSTANTS.DEFAULT_TAKE;
        this.listMediaGalleryParams = {
            take: MEDIAGALLERY_CONSTANTS.DEFAULT_TAKE,
            skip: MEDIAGALLERY_CONSTANTS.DEFAULT_SKIP,
            filters: {
                name: undefined,
                archived: undefined
            }
        };
        await this.setMediaGalleries();
    }

    @action.bound
    async initializeMediaGalleryFromApi(mediaGalleryId: string) {
        this.folders.clear();
        this.mediaGallery = {} as MediaGallery;
        this.setFolderPath('');
        this.setFolderName('');
        this.nextCursor = '';
        await this.setMediaGalleryFromApi(mediaGalleryId);
    }

    @action.bound
    setListMediaGalleryNameFilter(name?: string) {
        if (!!name && name.trim().length > 0) {
            this.listMediaGalleryParams.filters.name = name;
        } else {
            this.listMediaGalleryParams.filters.name = undefined;
        }
        this.setMediaGalleries();
    }

    @action.bound
    setListMediaGalleryArchivedFilter(archived?: boolean) {
        this.listMediaGalleryParams.filters.archived = archived;
        this.setMediaGalleries();
    }

    @action.bound
    resetAllListMediaGalleryFilters() {
        this.initializeMediaGalleries();
    }

    @computed
    get canResetAllFilters() {
        return this.listMediaGalleryParams.skip !== MEDIAGALLERY_CONSTANTS.DEFAULT_SKIP ||
            this.listMediaGalleryParams.take !== MEDIAGALLERY_CONSTANTS.DEFAULT_TAKE ||
            this.listMediaGalleryParams.filters.name !== undefined ||
            !!this.listMediaGalleryParams.filters.archived;
    }

    @action.bound
    async setMediaGalleries() {
        this.isLoadingMediaGalleries = true;
        this.mediaGalleries.clear();
        this.folders.clear();
        this.mediaGallery = {} as MediaGallery;
        this.folderPath = '';
        this.nextCursor = '';
        this.newFolderName = '';
        try {
            const response = await this.api.mediaGallery.list({
                skip: this.listMediaGalleryParams.skip,
                take: this.listMediaGalleryParams.take,
                filters: {
                    name: this.listMediaGalleryParams.filters.name,
                    archived: this.listMediaGalleryParams.filters.archived
                }
            });
            runInAction(() => {
                this.mediaGalleries.replace(response.items);
                this.paginationMediaGallery.total = response.total;
                this.paginationMediaGallery.current = (response.skip / response.take) + 1;
                this.isLoadingMediaGalleries = false;
            });
        } catch (e) {
            console.log('Error during retrieving MediaGalleries');
            runInAction(() => {
                this.isLoadingMediaGalleries = false;
            });
        }
    }

    @action
    async reinitializeMediaAndFolders(folderPath?: string) {
        if (this.folderPath === folderPath) {
            return;
        }

        this.loader.finder = true;

        if (folderPath) {
            this.setFolderPath(folderPath);
        } else {
            this.clearFolderPath();
        }
        this.clearSelectedMedia();
        await Promise.all([
            this.setFolders(),
            this.setMediaList()
        ]);

        runInAction(
            () => {
                this.loader.finder = false;
            }
        );
    }

    @action
    async setMediaListGivenTags(tags: string) {
        this.loader.finder = true;

        await this.setMediaList(undefined, tags);

        runInAction(() => {
            this.loader.finder = false;
        });
    }

    @action.bound
    setPaginationParams(currentPage: number, take?: number) {
        this.paginationMediaGallery.current = currentPage;
        if (!!take) {
            this.listMediaGalleryParams.take = take;
            this.listMediaGalleryParams.skip = (currentPage - 1) * take;
        } else {
            this.listMediaGalleryParams.take = MEDIAGALLERY_CONSTANTS.DEFAULT_TAKE;
            this.listMediaGalleryParams.skip = (currentPage - 1) * MEDIAGALLERY_CONSTANTS.DEFAULT_TAKE;
        }
        this.setMediaGalleries();
    }

    @action
    async setMediaGalleryFromApi(id: string) {
        if (id) {
            try {
                const response = await this.api.mediaGallery.get({ id: +id });
                runInAction(() => {
                    this.mediaGallery = response;
                });
            } catch (e) {
                console.log('Error occurred trying to retrieve MediaGallery Data');
            }
        } else {
            console.log('invalid ID param');
        }
    }

    @action
    setMediaGallery(mediaGallery: MediaGallery) {
        this.mediaGallery = mediaGallery;

        if (this.useLocalStorage) {
            const preferences = this.getMediaGalleryPreferences();
            const updatedPreferences = {
                ...preferences,
                mediaGalleryId: this.mediaGallery.id
            };
            this.updateMediaGalleryPreferences(updatedPreferences);
        }
    }

    @action
    async setFolderPath(folderPath: string) {
        if (this.mediaGallery.rootFolderName) {
            this.folderPath = folderPath.replace(`${this.mediaGallery.rootFolderName}/`, '');
        } else {
            this.folderPath = folderPath;
        }

        if (this.useLocalStorage) {
            const preferences = this.getMediaGalleryPreferences();
            const updatedPreferences = {
                ...preferences,
                folderPath: this.folderPath
            };
            this.updateMediaGalleryPreferences(updatedPreferences);
        }
    }

    @action
    clearFolderPath() {
        this.folderPath = '';
        if (this.useLocalStorage) {
            const preferences = this.getMediaGalleryPreferences();
            const updatedPreferences = {
                mediaGalleryId: preferences.mediaGalleryId
            };
            this.updateMediaGalleryPreferences(updatedPreferences);
        }
    }

    clearLocalStorage() {
        const preferences = {};
        this.updateMediaGalleryPreferences(preferences);
    }

    @action
    setResourceType(resourceType?: MediaType): void {
        this.resourceType = resourceType;
    }

    @action
    async setFolders() {

        const preferences = this.getMediaGalleryPreferences();

        const requestParams: ListMediaGalleryFoldersRequest = {
            mediaGalleryId:
                this.useLocalStorage && preferences?.mediaGalleryId ?
                    preferences.mediaGalleryId : this.mediaGallery.id,
        };

        if (this.folderPath) {
            requestParams.folderPath = this.folderPath;
        }

        try {
            const response = await this.api.mediaGallery.listFolders(requestParams);
            runInAction(() => {
                this.folders.replace(response.folders);
            });
            return response.folders;
        } catch (e) {
            console.log('Error occurred trying to retrieve MediaGallery\'s folders');
            return undefined;
        }
    }

    @action
    async setMediaList(nextCursor?: string, tags?: string) {

        const preferences = this.getMediaGalleryPreferences();

        const requestParams: ListMediaRequest = {
            mediaGalleryId:
                this.useLocalStorage && preferences?.mediaGalleryId ?
                    preferences.mediaGalleryId : this.mediaGallery.id,
            take: MEDIA_CONSTANTS.DEFAULT_TAKE,
            folderPath: this.folderPath,
            resourceType: this.resourceType ? this.resourceType : undefined
        };

        if (tags) {
            requestParams.tags = tags.split(', ');
        }

        if (nextCursor) {
            requestParams.nextCursor = nextCursor;
        }

        try {
            const response = await this.api.media.list(requestParams);
            runInAction(() => {
                if (nextCursor && response.items.length > 0) {
                    this.mediaList.replace(this.mediaList.concat(fromMultiMediaToAdaptedMediaArray(response.items)));
                } else {
                    this.mediaList.replace(fromMultiMediaToAdaptedMediaArray(response.items));
                }
                if (response.nextCursor) {
                    this.nextCursor = response.nextCursor;
                } else {
                    this.nextCursor = '';
                }
            });
        } catch (e) {
            console.log('Error retrieving media for given mediaGallery and folderPath', e);
        }
    }

    @action
    async toggleMedia(media: MediaAdapter) {
        if (this.media && this.media.data.publicId === media.data.publicId) {
            this.media = undefined;
        } else {
            this.media = media;
        }
    }

    @action
    async clearSelectedMedia() {
        this.media = undefined;
    }

    @action
    setFolderName(folderName: string) {
        this.newFolderName = folderName;
    }

    buildParentFolder() {
        if (this.newFolderName.length > 0 && this.folderPath.length > 0) {
            return `${this.folderPath}/${this.newFolderName}`;
        }

        if (!this.newFolderName) {
            return this.folderPath;
        } else {
            return this.newFolderName;
        }
    }

    @action
    async setMedia(mediaPublicId: string, mediaType: MediaType) {
        try {
            const response = await this.api.media.get({
                mediaGalleryId: this.mediaGallery.id,
                mediaPublicId,
                mediaType,
                parentFolder: this.folderPath
            });
            runInAction(() => {
                this.media = fromSingleMediaToAdaptedMedia(response);
            });

        } catch (e) {
            console.log('Error occurred retrieving media details');
        }
    }

    async getUploadUrl(
        fileType: MediaType,
        fileName: string,
        size: number,
        tags?: string[]
    ): Promise<UploadMediaResponse | undefined> {

        const requestParams = {
            mediaType: fileType,
            mediaPublicId: fileName,
            mediaGalleryId: this.mediaGallery.id,
            parentFolder: this.buildParentFolder(),
            size,
            tags
        };

        try {
            const response = await this.api.media.upload(requestParams);
            return response;
        } catch (e) {
            console.log('Error occurred trying to get uploadUrl');
            return undefined;
        }
    }

    @action
    async isValidMediaPublicId(fileName: string, mediaType: MediaType) {
        try {
            const isValid = await this.api.media.verify({
                mediaGalleryId: this.mediaGallery.id,
                mediaType,
                mediaPublicId: fileName,
                parentFolder: this.buildParentFolder()
            });
            return isValid.valid;
        } catch (e) {
            console.log('Error occurred trying to valid mediaPublicId');
            return false;
        }
    }

    @computed
    get getMediaGallery() {
        return this.mediaGallery;
    }

    @action
    async updateMedia(mediaData: MediaAdapterDataModel, mediaGalleryId: string): Promise<void> {
        try {
            const response = await this.api.media.update({
                mediaGalleryId: +mediaGalleryId,
                mediaPublicId: mediaData.publicId,
                tags: mediaData.tags,
                resourceType: mediaData.resourceType
            });
            runInAction(() => {
                this.media = fromSingleMediaToAdaptedMedia(response);
            });
        } catch (e) {
            console.log('Error occurred trying to update media', e);
            return Promise.reject();
        }
    }

    @action
    selectMedia(media: MediaAdapter) {
        this.media = media;
    }

    @action
    toggleIncludeFolders() {
        this.includeFolders = !this.includeFolders;
    }

    @action.bound
    async deleteSelectedMedia(): Promise<void> {
        try {
            const result = this.selectedMediaToDelete.map((media) => {
                return this.api.media.delete({
                    mediaGalleryId: this.mediaGallery.id,
                    mediaPublicId: media.data.publicId,
                    resourceType: media.data.resourceType
                });
            });
            await Promise.all(result);
            runInAction(async () => {
                this.selectedMediaToDelete.clear();
                this.clearSelectedMedia();
                Promise.all([
                    this.setFolders(),
                    this.setMediaList()
                ]);
            });
        } catch (error) {
            console.log('Error trying to delete media', error);
            return Promise.reject();
        }
    }

    @action.bound
    toggleDeleteMedia(media: MediaAdapter): void {
        if (this.itemInSelectedMediaToDelete(media)) {
            this.selectedMediaToDelete.replace(this.selectedMediaToDelete.filter(item =>
                item.data.publicId !== media.data.publicId));
        } else {
            this.selectedMediaToDelete.push(media);
        }
    }

    @action.bound
    removeMediaFromDeletion(media: MediaAdapter): void {
        if (this.itemInSelectedMediaToDelete(media)) {
            this.selectedMediaToDelete.replace(this.selectedMediaToDelete.filter(item =>
                item.data.publicId !== media.data.publicId));
        }
    }

    itemInSelectedMediaToDelete(media: MediaAdapter): boolean {
        return !!this.selectedMediaToDelete
            .find(item => (
                item.data.publicId === media.data.publicId &&
                item.data.resourceType === media.data.resourceType)
            );
    }

    get localFolderPath() {
        const preferences = this.getMediaGalleryPreferences();
        const folderPath = preferences?.folderPath;
        return folderPath;
    }

    getPreferences() {
        return JSON.parse(localStorage.getItem(this.spaceId) || '{}');
    }

    getMediaGalleryPreferences() {
        const preferences = JSON.parse(localStorage.getItem(this.spaceId) || '{}');
        return preferences?.mediaGallery || {};
    }

    updateMediaGalleryPreferences(mediaGalleryPreferences: object) {
        const preferences = this.getPreferences();
        const updatedPreferences = {
            ...preferences,
            mediaGallery: mediaGalleryPreferences
        };
        localStorage.setItem(this.spaceId, JSON.stringify(updatedPreferences));
    }
}

export default MediaGalleryFlowStore;
