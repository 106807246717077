import React from 'react';
import { InjectedIntl, defineMessages, injectIntl } from 'react-intl';
import { Progress, Icon } from 'antd';
import Button from '@components/Button';
import classNames from './index.module.scss';

export type Variant = 'flat' | 'raised';

export interface SubscriptionSpaceCountIndicatorProps {
    intl: InjectedIntl;
    spaceCount: number;
    spaceCap: number;
    variant?: Variant;
    onClickUpgrade?(): any;
}

const labels = defineMessages({
    button: {
        defaultMessage: 'Upgrade your subscription',
        id: 'components.getFormattedSpaceCount.button'
    },
    progress: {
        defaultMessage: 'Space {spaceCount} of {spaceCap}',
        id: 'components.getFormattedSpaceCount.spaceProgress'
    },
});

function getFormattedSpaceCount(props: SubscriptionSpaceCountIndicatorProps): React.ReactNode {
    const {
        intl,
        spaceCap,
        spaceCount,
    } = props;

    const label = intl.formatMessage(labels.progress, {
        spaceCount,
        spaceCap,
    });
    const color = getSpaceProgressColour(getSpaceProgression(spaceCount, spaceCap), 50);

    return (
        <span
            className={classNames.SubscriptionSpaceCountIndicatorCounter}
            style={{ color }}
        >
            {label}
        </span>
    );
}

function getSpaceProgression(spaceCount: number, spaceCap: number): number {
    return (spaceCount / spaceCap) * 100;
}

function getSpaceProgressColour(progression: number, saturationModifier: number = 0): string {
    const hue = Math.floor(100 - progression);
    const saturation = 100 - Math.floor(progression / 4) - saturationModifier;

    return `hsl(${hue}, ${saturation}%, 50%)`;
}

function SubscriptionSpaceCountIndicator(props: SubscriptionSpaceCountIndicatorProps) {
    const {
        intl,
        onClickUpgrade,
        spaceCap,
        spaceCount,
    } = props;

    const progression = getSpaceProgression(spaceCount, spaceCap);
    const progressioncolour = getSpaceProgressColour(progression);
    const progressionLabel = () => getFormattedSpaceCount(props);

    return (
        <div
            className={
                props.variant === 'raised'
                    ? classNames.SubscriptionSpaceCountIndicatorShadow
                    : classNames.SubscriptionSpaceCountIndicator
            }
        >
            <Progress
                percent={progression}
                format={progressionLabel}
                strokeColor={progressioncolour}
                type="circle"
            />
            {
                onClickUpgrade &&
                <div className={classNames.SubscriptionSpaceCountIndicatorButtonContainer}>
                    <Button
                        className={classNames.SubscriptionSpaceCountIndicatorButton}
                        onClick={onClickUpgrade}
                        type="primary"
                    >
                        <Icon type="arrow-up" />
                        {intl.formatMessage(labels.button)}
                    </Button>
                </div>
            }
        </div>
    );
}

export default injectIntl(SubscriptionSpaceCountIndicator);
