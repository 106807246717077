import React, { Component } from 'react';
import { Icon, Menu, Layout } from 'antd';
import { RobotSvg } from '../../assets/custom_icons/components/robot';
import { RouteComponentProps, withRouter } from 'react-router';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';

import styles from './Menubar.module.scss';
import { Link } from 'react-router-dom';
import {
    SUBSCRIPTION_MANAGEMENT_ROUTE,
    ACCESS_TOKEN_ROUTE,
    PROFILE_ROUTE,
    INVITES_ROUTE,
    PROFILE_SECURITY_ROUTE
} from '../../constants/routing-constants';
import Typography from '../Typography';

enum MenubarItems {
    TITLE = 'settings',
    PROFILE = 'profile',
    ACCESS_TOKENS = 'access-tokens',
    WEBHOOKS = 'webhooks',
    SUBSCRIPTIONS = 'subscriptions',
    INVITES = 'invites',
    USERS = 'users',
    SECURITY = 'security',
}

interface MenubarProps extends RouteComponentProps<{}>, InjectedIntlProps { }
interface MenubarState {
    collapsed: boolean;
}

const { Sider } = Layout;

const MenubarLayoutLabels = defineMessages({
    Settings: {
        id: 'MenubarLayout.Settings',
        defaultMessage: 'Settings'
    },
    Profile: {
        id: 'MenubarLayout.Profile',
        defaultMessage: 'Profile'
    },
    AccessTokens: {
        id: 'MenubarLayout.AccessTokens',
        defaultMessage: 'Programmatic Access'
    },
    Webhooks: {
        id: 'MenubarLayout.Webhooks',
        defaultMessage: 'Webhooks'
    },
    Security: {
        id: 'MenubarLayout.Security',
        defaultMessage: 'Profile Security',
    },
    Subscriptions: {
        id: 'MenubarLayout.Subscriptions',
        defaultMessage: 'Subscriptions'
    },
    Invites: {
        id: 'MenubarLayout.Invites',
        defaultMessage: 'Invites'
    },
});

class Menubar extends Component<MenubarProps, MenubarState> {

    state: MenubarState = {
        collapsed: false
    };

    findActiveMenuLink = () => {
        switch (this.props.location.pathname) {
            case ACCESS_TOKEN_ROUTE:
                return [MenubarItems.ACCESS_TOKENS];
            case PROFILE_ROUTE:
                return [MenubarItems.PROFILE];
            case SUBSCRIPTION_MANAGEMENT_ROUTE:
                return [MenubarItems.SUBSCRIPTIONS];
            case INVITES_ROUTE:
                return [MenubarItems.INVITES];
            case PROFILE_SECURITY_ROUTE:
                return [MenubarItems.SECURITY];
            default:
                return undefined;
        }
    }

    onCollapse = (collapsed: boolean) => {
        this.setState({
            collapsed
        });
    }

    render() {
        const { intl: { formatMessage } } = this.props;
        const selectedKeys = this.findActiveMenuLink();
        return (
            <Sider
                theme="light"
                width={250}
                breakpoint="md"
                collapsedWidth={64}
                onCollapse={this.onCollapse}
                className={styles.MenubarContainer}
            >
                <Menu mode="inline" selectedKeys={selectedKeys}>
                    {
                        !this.state.collapsed &&
                        <Menu.Item disabled={true} key={MenubarItems.TITLE}>
                            <Typography gutter={false} variant="h5">
                                {
                                    formatMessage(MenubarLayoutLabels.Settings)
                                }
                            </Typography>
                        </Menu.Item>
                    }
                    <Menu.Item key={MenubarItems.PROFILE}>
                        <Link to={PROFILE_ROUTE}>
                            <Icon type="user" />
                            <span>{formatMessage(MenubarLayoutLabels.Profile)}</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={MenubarItems.SECURITY}>
                        <Link to={PROFILE_SECURITY_ROUTE}>
                            <Icon type="key" />
                            <span>{formatMessage(MenubarLayoutLabels.Security)}</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={MenubarItems.SUBSCRIPTIONS}>
                        <Link to={SUBSCRIPTION_MANAGEMENT_ROUTE}>
                            <Icon type="credit-card" />
                            <span>{formatMessage(MenubarLayoutLabels.Subscriptions)}</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={MenubarItems.ACCESS_TOKENS}>
                        <Link to={ACCESS_TOKEN_ROUTE}>
                            <Icon component={RobotSvg} />
                            <span>{formatMessage(MenubarLayoutLabels.AccessTokens)}</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={MenubarItems.INVITES}>
                        <Link to={INVITES_ROUTE}>
                            <Icon type="user-add" />
                            <span>{formatMessage(MenubarLayoutLabels.Invites)}</span>
                        </Link>
                    </Menu.Item>
                </Menu>
            </Sider>
        );
    }
}

export default withRouter(injectIntl(Menubar));
