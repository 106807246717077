import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import {
    ContentRepositorySummaryRequest, ContentRepositorySummaryResponse
} from '@contentchef/contentchef-types';
import { action, observable, runInAction } from 'mobx';
import { RepositoryDashboardDataModel, RepositoryDashboardStoreModel } from './repositoryDashboardStoreModel';

class RepositoryDashboardStore implements RepositoryDashboardStoreModel {
    api: ContentChefClient;
    @observable error = {
        repositoryDashboard: '',
    };

    @observable repositoryDashboardData: RepositoryDashboardDataModel = {
        repositoryDashboard: {} as ContentRepositorySummaryResponse,
        counters: []
    };

    constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action
    async setRepositoryDashboardData(id: string) {
        const reqParam: ContentRepositorySummaryRequest = {
            id: +id
        };
        try {
            const payload = await this.api.contentRepositories.viewSummary(reqParam);
            runInAction(() => {
                this.repositoryDashboardData.repositoryDashboard = payload;
                this.repositoryDashboardData.counters = [
                    { name: 'Contents', count: payload.contents },
                    { name: 'Releases', count: 123 } // map with payload response for releases counter
                ];
            });
        } catch (e: any) {
            runInAction(() => {
                this.error.repositoryDashboard = e;
                console.log(e, 'Impossible to call contentRepositories.viewSummary');
            });
        }
    }
}

export default RepositoryDashboardStore;
