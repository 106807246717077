import React, { Component } from 'react';

export const ArchivedContentContext = React.createContext({ archived: false });

export type ArchivedContentProviderData = {
    archived?: boolean;
};

export class ArchivedContentProvider extends Component<{ archived: boolean }, {}> {
    render() {
        const { children, archived } = this.props;
        return (
            <ArchivedContentContext.Provider
                children={children}
                value={{ archived: archived }}
            />
        );
    }
}

export type WithArhivedStatusReturnType = {
    archived?: boolean;
};

export type WithoutArchivedStatusProps<T> = Pick<T, Exclude<keyof T, keyof ArchivedContentProviderData>>;

export const withArchivedStatus =
    <T extends WithArhivedStatusReturnType>
        (WrappedComponent: React.ComponentType<T>): React.ComponentType<WithoutArchivedStatusProps<T>> => {
        return class WithArchivedStatus extends Component<WithoutArchivedStatusProps<T>> {
            static contextType = ArchivedContentContext;

            render() {
                return (
                    <WrappedComponent
                        {...{
                            ...this.props,
                            archived: this.context.archived
                        } as T}
                    />
                );
            }
        };
    };
