import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import {
    Aside,
    AsideItem, ContainerWithAside, FormActions, UpdateContentDefinitionForm
} from '../../../components';
import { NOTIFICATION_DEFAULT_MESSAGES, NOTIFICATION_KEY_CONSTANTS } from '../../../constants/notifications-constants';
import { userPermissionsTable } from '../../../constants/user-permissions-table';
import { withFormInitialization } from '../../../hoc';
import { DataAttributesBuilder } from '../../../services/DataAttributesBuilder';
import { ContentDefinitionErrorTypes } from '../../../services/Error/ContentDefinitionErrorManager';
import {
    createCustomNotification,
    createErrorNotification,
    createInfoNotification,
    createSuccessNotification
} from '../../../services/Notification';
import {
    InjectedProps,
    UpdateContentDefinitionProps,
    UpdateContentDefinitionState
} from './UpdateContentDefinitionModel';


const labels = defineMessages({
    validateDefinitionError: {
        id: 'UpdateContentDefinition.validateDefinitionError',
        defaultMessage: 'Your definition contain invalid fields, please check them before saving'
    }
});

@inject('notificationStore', 'contentDefinitionFormStore', 'contentDefinitionSchemaStore')
@observer
class UpdateContentDefinition extends Component
<UpdateContentDefinitionProps, UpdateContentDefinitionState> {
    get injected() {
        return this.props as InjectedProps;
    }

    createDataAttributes = () => {
        return new DataAttributesBuilder('contentdefinition')
            .setIdAttribute('editContentDefinition')
            .setAddFormAttribute('contentDefinitionUpdate')
            .build();
    }

    onSubmitForm = async (values: { name: string, mnemonicId: string, id: number }) => {
        const { formatMessage } = this.props.intl;
        const {
            notificationStore,
            contentDefinitionSchemaStore: { retrieveDeserializedDefinitionSchema, jsonEditorSchema }
        } = this.injected;
        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.CONTENTDEFINITION_UPDATE_API_KEY_REQUEST,
            formatMessage,
            NOTIFICATION_DEFAULT_MESSAGES.updateAction
        );
        try {
            this.injected.contentDefinitionSchemaStore.resetSchemaErrors();
            this.injected.contentDefinitionSchemaStore.resetFieldsInitialIndex();
            const updateResult = await this.injected.contentDefinitionFormStore.update({
                id: values.id, name: values.name, mnemonicId: values.mnemonicId,
                schema: !!jsonEditorSchema ? jsonEditorSchema : retrieveDeserializedDefinitionSchema()
            });
            this.injected.contentDefinitionSchemaStore.initStoreSchemas(updateResult.schema);
            notificationStore.closeNotification(
                NOTIFICATION_KEY_CONSTANTS.CONTENTDEFINITION_UPDATE_API_KEY_REQUEST
            );
            createSuccessNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.updateAction
            );
        } catch (e: any) {
            this.injected.contentDefinitionSchemaStore.setSchemaErrors(e.retrieveValidationErrors());
            await notificationStore.closeNotification(
                NOTIFICATION_KEY_CONSTANTS.CONTENTDEFINITION_UPDATE_API_KEY_REQUEST
            );
            if (e.type === ContentDefinitionErrorTypes.FailedSchemaValidation) {
                createCustomNotification(formatMessage, labels.validateDefinitionError, 'error', 5);
            } else {
                createErrorNotification(formatMessage, NOTIFICATION_DEFAULT_MESSAGES.updateAction);
            }
        }
    }

    render() {
        const { contentDefinition, jsonSchema } = this.injected.contentDefinitionFormStore;
        const { notifications } = this.injected.notificationStore;
        return (
            <ContainerWithAside
                dataAttributes={this.createDataAttributes().Attributes}
                disableContent={
                    notifications.includes(NOTIFICATION_KEY_CONSTANTS.CONTENTDEFINITION_UPDATE_API_KEY_REQUEST)
                }
                renderAside={() => (
                    <Aside>
                        <AsideItem>
                            <FormActions
                                onSubmit={this.onSubmitForm}
                                permissions={userPermissionsTable.FormActions.definition}
                            />
                        </AsideItem>
                    </Aside>
                )}
            >
                <UpdateContentDefinitionForm
                    jsonSchema={jsonSchema}
                    formData={contentDefinition!}
                />
            </ContainerWithAside>
        );
    }
}

export default withFormInitialization(injectIntl(UpdateContentDefinition));
