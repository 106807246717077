import React from 'react';
import { Switch } from 'antd';
import { SchemaTypeIds } from '@contentchef/contentchef-types';
import { DynamicFieldTypes, FieldBoolean } from '../FormFields';
import { fieldMatchersUtils } from './FieldMatchersUtils';
import { FieldMatcher } from './FormFieldMatchersFactory';
import { defaultFieldWrapper } from './DefaultFieldWrapper';

export class BooleanTypeMatcher implements FieldMatcher {
    maybeCreateComponent(
        id: string, userLocale: string, onChange: (e: any) => void, field: DynamicFieldTypes
    ): JSX.Element | null {
        if (!fieldMatchersUtils.typeMatcher(field, SchemaTypeIds.BOOLEAN)) { return null; }
        const fieldBoolean = field as FieldBoolean;

        function elementToRender(disabled: boolean) {
            return (
                <Switch
                    {...{ id: id }}
                    disabled={disabled || field.disabled}
                    defaultChecked={false}
                    checked={fieldBoolean.value}
                    onChange={onChange}
                />
            );
        }

        return defaultFieldWrapper(elementToRender);
    }
}
