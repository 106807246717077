import { defineMessages, InjectedIntl } from 'react-intl';

const USER_PROFILES_LABELS = defineMessages({
    space_manager: {
        id: 'UserProfile.SpaceManager',
        defaultMessage: 'space manager'
    },
    space_profiles_manager: {
        id: 'UserProfiles.SpaceProfilesManager',
        defaultMessage: 'space profiles manager'
    },
    space_billing_manager: {
        id: 'UserProfiles.SpaceBillingManager',
        defaultMessage: 'space billing manager'
    },
    content_designer: {
        id: 'UserProfiles.ContentDesigner',
        defaultMessage: 'content designer',
    },
    power_user: {
        id: 'UserProfiles.PowerUser',
        defaultMessage: 'power user',
    },
    content_editor: {
        id: 'UserProfiles.ContentEditor',
        defaultMessage: 'content editor',
    },
    repository_manager: {
        id: 'UserProfiles.RepositoryManager',
        defaultMessage: 'repository manager',
    },
    live_publishing_manager: {
        id: 'UserProfiles.LivePublishingManager',
        defaultMessage: 'live publishing manager',
    }
});

export default function getAvailableProfileLabel(profileId: string, formatMessage: InjectedIntl['formatMessage']) {
    switch (profileId) {
        case 'space_manager':
            return formatMessage(USER_PROFILES_LABELS.space_manager);
        case 'space_profiles_manager':
            return formatMessage(USER_PROFILES_LABELS.space_profiles_manager);
        case 'space_billing_manager':
            return formatMessage(USER_PROFILES_LABELS.space_billing_manager);
        case 'content_designer':
            return formatMessage(USER_PROFILES_LABELS.content_designer);
        case 'power_user':
            return formatMessage(USER_PROFILES_LABELS.power_user);
        case 'content_editor':
            return formatMessage(USER_PROFILES_LABELS.content_editor);
        case 'repository_manager':
            return formatMessage(USER_PROFILES_LABELS.repository_manager);
        case 'live_publishing_manager':
            return formatMessage(USER_PROFILES_LABELS.live_publishing_manager);
        default:
            return;
    }
}
