import React, { Component } from 'react';
import { CreatePublishingChannelRequest } from '@contentchef/contentchef-types';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { PublishingChannelForm, Aside, AsideItem, FormActions, ContainerWithAside } from '../../../components';
import { CreatePublishingChannelProps, InjectedProps } from './CreatePublishingChannelModel';
import { DataAttributesBuilder } from '../../../services/DataAttributesBuilder';
import { withChannelCreateStores, withFormInitialization } from '../../../hoc';
import {
    createInfoNotification,
    createErrorNotification,
    createSuccessNotification
} from '../../../services/Notification';
import { NOTIFICATION_KEY_CONSTANTS, NOTIFICATION_DEFAULT_MESSAGES } from '../../../constants/notifications-constants';
import { userPermissionsTable } from '../../../constants/user-permissions-table';
import { ManageFieldProvider } from '../../../providers/UserPermissionsProvider';

@inject('channelsStore', 'notificationStore')
@observer
class CreatePublishingChannel extends Component<CreatePublishingChannelProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    componentWillUnmount() {
        const { notificationStore } = this.injected;
        const { notifications } = notificationStore;
        if (notifications.includes(NOTIFICATION_KEY_CONSTANTS.PUBLISHING_CHANNEL_CREATE_REQUEST)) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.PUBLISHING_CHANNEL_CREATE_REQUEST);
        }
    }

    createDataAttributes = () => {
        return new DataAttributesBuilder('publishing-channels')
            .setIdAttribute('addPublishingChannel')
            .setAddFormAttribute('publishingChannelCreate')
            .build();
    }

    onSubmitForm = async (channel: CreatePublishingChannelRequest) => {
        const { formatMessage } = this.props.intl;
        const { notificationStore } = this.injected;
        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.PUBLISHING_CHANNEL_CREATE_REQUEST,
            formatMessage,
            NOTIFICATION_DEFAULT_MESSAGES.createAction
        );
        try {
            await this.injected.channelsStore.createChannel(channel);
            createSuccessNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.createAction
            );
        } catch (e) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.PUBLISHING_CHANNEL_CREATE_REQUEST);

            createErrorNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.createAction
            );
        }
    }

    validateMnemonicId = async (mnemonicIdToValidate: string) => {
        await this.injected.channelsStore.setChannels();
        const foundChannels =
            this.injected.channelsStore.channels.filter((channel) => channel.mnemonicId === mnemonicIdToValidate);

        return !(foundChannels && foundChannels.length > 0);

    }

    render() {
        const { notifications } = this.injected.notificationStore;
        return (
            <ContainerWithAside
                dataAttributes={this.createDataAttributes().dataAttributes}
                disableContent={notifications.includes(NOTIFICATION_KEY_CONSTANTS.PUBLISHING_CHANNEL_CREATE_REQUEST)}
                renderAside={() => (
                    <Aside>
                        <AsideItem>
                            <FormActions
                                onSubmit={this.onSubmitForm}
                                permissions={userPermissionsTable.FormActions.channel}
                            />
                        </AsideItem>
                    </Aside>
                )}
            >
                <ManageFieldProvider permissions={userPermissionsTable.Forms.CreatePublishingChannelForm}>
                    <PublishingChannelForm
                        validateMnemonicId={this.validateMnemonicId}
                    />
                </ManageFieldProvider>
            </ContainerWithAside>
        );
    }
}

export default withChannelCreateStores(withFormInitialization(injectIntl(CreatePublishingChannel)));
