import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { ListPublicIdStore, SpaceDetailStore } from '@stores/index';
import { NavStoreModel } from '@stores/navStore/navStoreModel';
import ExtensionStore from '@stores/extensionStore';
import { ApiStoreModel } from '@stores/apiStore/apiStore';
import ExtensionAPIStore from '@stores/extensionAPIStore';
import { NotificationStoreModel } from '@stores/notificationStore/notificationStoreModel';

export function useSpaceDetailStore() {
    const { spaceDetailStore } = useContext(MobXProviderContext);
    return spaceDetailStore as SpaceDetailStore;
}

export function useNavStore() {
    const { navStore } = useContext(MobXProviderContext);
    return navStore as NavStoreModel;
}

export function useSpaceDetailWithNavStore() {
    const spaceDetailStore = useSpaceDetailStore();
    const navStore = useNavStore();
    return {
        spaceDetailStore,
        navStore
    };
}

export function useExtensionStore() {
    const { extensionStore } = useContext(MobXProviderContext);
    return extensionStore as ExtensionStore;
}

export function useApiStore() {
    const { apiStore } = useContext(MobXProviderContext);
    return apiStore as ApiStoreModel;
}

export function useExtensionAPIStore() {
    const { extensionAPI } = useContext(MobXProviderContext);
    return extensionAPI as ExtensionAPIStore;
}

export function useNotificationStore() {
    const { notificationStore } = useContext(MobXProviderContext);
    return notificationStore as NotificationStoreModel;
}

export function useStoreForExtension() {
    const navStore = useNavStore();
    const extensionAPI = useExtensionAPIStore();
    return {
        navStore,
        extensionAPI
    };
}

export function useStoreForExtensionForm() {
    const { extensionAPI, navStore } = useStoreForExtension();
    const notificationStore = useNotificationStore();

    return {
        extensionAPI,
        navStore,
        notificationStore
    };
}

export function useListPublicIdStore() {
    const { listPublicIdStore } = useContext(MobXProviderContext);
    return listPublicIdStore as ListPublicIdStore;
}
