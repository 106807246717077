import React, { MouseEvent, Component } from 'react';
import { Notification, NotificationIcon, NotificationHubStore } from '../../stores/notificationHubStore';
import { Icon } from 'antd';
import { Link } from 'react-router-dom';
import classes from './index.module.scss';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

interface NotificationHubItemProps {
    notification: Notification;
}

interface InjectedProps extends NotificationHubItemProps {
    notificationHubStore: NotificationHubStore;
}

interface NotificationHubItemState {
    removed: boolean;
}

class NotificationHubItem extends Component<NotificationHubItemProps, NotificationHubItemState> {

    state = {
        removed: false
    };

    get injected() {
        return this.props as InjectedProps;
    }

    onRemoveNotification = (event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        this.setState({
            removed: true
        });
        setTimeout(
            () => this.injected.notificationHubStore.removeNotification(this.props.notification.identifier),
            500
        );
    }

    onClickLink = () => {
        this.injected.notificationHubStore.toggleHub();
        this.injected.notificationHubStore.removeNotification(this.props.notification.identifier);
    }

    renderIcon(icon: NotificationIcon) {
        const iconClassName = classNames(classes.IconContainer, icon.className);
        return (
            <div className={iconClassName}>
                <Icon
                    type={icon.type}
                />
            </div>
        );
    }

    renderLabel(label: React.ReactNode) {
        return (
            <div className={classes.LabelContainer}>
                {label}
            </div>
        );
    }

    renderCTAIcon() {
        return (
            <div className={classes.CTAIconContainer}>
                <Icon type="right" />
            </div>
        );
    }

    renderRemovableAction() {
        return (
            <div className={classes.RemovableAction} onClick={this.onRemoveNotification}>
                <p>
                    <FormattedMessage
                        id="NotificationHubitem.Dismiss"
                        defaultMessage="Dismiss"
                    />
                </p>
            </div>
        );
    }

    renderNotification(notification: Notification) {

        const notificationHubItemClassName = classNames(
            classes.NotificationHubItem,
            {
                [classes.ItemFadeOut]: this.state.removed
            }
        );

        return (
            <div className={notificationHubItemClassName}>
                {this.maybeWrapWithLink(
                    <div className={classes.NotificationHubItemInfo}>
                        {notification.icon && this.renderIcon(notification.icon)}
                        {this.renderLabel(notification.label)}
                        {notification.cta && this.renderCTAIcon()}
                    </div>
                )}
                {notification.removable && this.renderRemovableAction()}
            </div>
        );
    }

    maybeWrapWithLink(notificationElement: JSX.Element) {

        const notificationHubItemClassName = classNames(
            classes.NotificationHubItemLink
        );

        return this.props.notification.cta ? (
            <Link
                to={this.props.notification.cta}
                onClick={this.onClickLink}
                className={notificationHubItemClassName}
            >
                {notificationElement}
            </Link>
        ) : notificationElement;
    }

    render() {
        return (
            this.renderNotification(this.props.notification)
        );
    }

}

export default inject('notificationHubStore')(observer(NotificationHubItem));
