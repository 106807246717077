import Button from '@components/Button';
import { UserInvite } from '@contentchef/contentchef-types';
import { ApiStoreModel } from '@stores/apiStore/apiStore';
import { InviteStoreModel } from '@stores/inviteStore';
import UserPermissionStore from '@stores/userPermissionsStore';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { notification, Table } from 'antd';
import { Auth } from 'aws-amplify';
import { inject, observer } from 'mobx-react';
import React, { Fragment } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import SettingsContentHeader from '../../../components/SettingsContentHeader';

export interface MyInvitesProps extends RouteComponentProps { }

interface MyInvitesState {
    acceptingGuid: string | null;
    isAccepting: boolean;
    isLoading: boolean;
}

interface DecoratedMyInvitesProps extends MyInvitesProps {
    apiStore: ApiStoreModel;
    inviteStore: InviteStoreModel;
    userPermissionsStore: UserPermissionStore;
}

function getInitialState(): MyInvitesState {
    return {
        acceptingGuid: null,
        isAccepting: false,
        isLoading: true,
    };
}

@inject('apiStore', 'inviteStore', 'userPermissionsStore')
@observer
class MyInvites extends React.Component<MyInvitesProps, MyInvitesState> {
    protected get decoratedProps(): DecoratedMyInvitesProps {
        return this.props as DecoratedMyInvitesProps;
    }

    public state = getInitialState();

    public async componentDidMount() {
        await this.decoratedProps.inviteStore.listForUser();
        this.setState({ isLoading: false });
    }

    public createAcceptInviteHandler(invite: UserInvite) {
        return () => this.handleAcceptInvite(invite);
    }

    public filterInvites(invite: UserInvite): boolean {
        return !invite.accepted;
    }

    public dataSource(): Array<UserInvite & { key: string }> {
        const filter: (value: UserInvite) => unknown = a => !a.accepted;
        const map: (value: UserInvite) => UserInvite & { key: string } = a => ({
            ...a,
            key: a.guid,
        });

        return this.decoratedProps.inviteStore.invites.filter(filter).map(map);
    }

    public async handleAcceptInvite(invite: UserInvite) {
        try {
            const {
                apiStore,
                inviteStore,
                userPermissionsStore,
            } = this.decoratedProps;

            this.setState({
                acceptingGuid: invite.guid,
                isAccepting: true,
            });

            await inviteStore.acceptInvite({ guid: invite.guid, email: invite.userEmail }, invite.spaceId);
            await inviteStore.pollListForUser();
            await userPermissionsStore.pollUntilChange();

            const user = await Auth.currentAuthenticatedUser();
            const session = await Auth.currentSession();

            this.setState(getInitialState());

            notification.success({
                message: (
                    <FormattedMessage
                        defaultMessage="Invite accepted"
                        id="scenes.SettingsInvites.Invites.inviteAccepted"
                    />
                )
            });

            user.refreshSession(session.getRefreshToken(), async (error: Error, response: CognitoUserSession) => {
                if (error || response === null) {
                    throw error;
                }

                await userPermissionsStore.list();
                apiStore.switchToSpace(invite.spaceId);
            });
        } catch (error: any) {
            this.setState({
                acceptingGuid: null,
                isAccepting: false,
            });

            notification.error({
                message: (
                    <FormattedMessage
                        defaultMessage="{error}"
                        id="scenes.SettingsInvites.Invites.acceptError"
                        values={{
                            error: error.message,
                        }}
                    />
                )
            });
        }
    }

    public render() {
        return (
            <Fragment>
                <SettingsContentHeader
                    title={
                        <FormattedMessage
                            defaultMessage="Your invites"
                            id="scenes.SettingsInvite.Invites.title"
                        />
                    }
                />
                <Table
                    columns={[
                        {
                            align: 'left',
                            dataIndex: 'timeToLive',
                            key: 'timeToLive',
                            render: (_, item) => (
                                <FormattedDate value={item.timeToLive * 1000} />
                            ),
                            title: (
                                <FormattedMessage
                                    defaultMessage="expires on"
                                    id="scenes.SettingsInvite.Invites.timeToLive"
                                />
                            ),
                        },
                        {
                            align: 'left',
                            dataIndex: 'spaceId',
                            key: 'spaceId',
                            title: 'Space',
                        },
                        {
                            key: '__accept',
                            render: (_, invite) => (
                                <Button
                                    disabled={this.state.isAccepting}
                                    onClick={this.createAcceptInviteHandler(invite)}
                                    loading={this.state.acceptingGuid === invite.guid}
                                >
                                    <FormattedMessage
                                        defaultMessage="Accept invite"
                                        id="scenes.SettingsInvite.Invites.acceptInvite"
                                    />
                                </Button>
                            )
                        },
                    ]}
                    dataSource={this.dataSource()}
                    loading={this.state.isLoading}
                    size="middle"
                />
            </Fragment>
        );
    }
}

export default MyInvites;
