import React from 'react';

import { LoaderProps } from './LoaderModel';

import styles from './Loader.module.scss';
import { Spin } from 'antd';

const Loader = ({ style = {}, className }: LoaderProps) => (
    <div className={`${styles.LoaderWrapper}${className ? ` ${className}` : ''}`} style={style}>
        <Spin size="large" />
    </div>
);

export default Loader;
