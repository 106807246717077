import React, { Component } from 'react';
import { TeamMembersProps } from './TeamMembersModel';
import { RoundAvatar } from '..';

import styles from './TeamMembers.module.scss';

class TeamMembers extends Component<TeamMembersProps> {
    render() {
        const { teamMembers } = this.props;
        return (
            <div className={styles.TeamMembersContainer}>
                <div className={styles.TeamMembersHeader}>
                    Team Members:
                </div>
                <div className={styles.TeamMembersBody}>
                    {teamMembers.map((member, index) => (
                        <RoundAvatar
                            key={index}
                            name={member.name}
                            size={28}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export default TeamMembers;
