import React from 'react';
import { RouteComponentProps, Route, Switch } from 'react-router-dom';
import ProfilePage from '../ProfilePage';
import ProfileSecurity from '../ProfileSecurity';
import {
    PROFILE_SECURITY_ROUTE,
    PROFILE_ROUTE
} from '../../../constants/routing-constants';

interface ProfileRouterProps extends RouteComponentProps { }

const ProfileRouter = (props: ProfileRouterProps) => {
    return (
        <Switch>
            <Route
                component={ProfilePage}
                exact={true}
                path={PROFILE_ROUTE}
            />
            <Route
                component={ProfileSecurity}
                exact={true}
                path={PROFILE_SECURITY_ROUTE}
            />
        </Switch>
    );
};

export default ProfileRouter;
