import React from 'react';
import { TabTitleProps } from './TabTitleModel';
import { Icon } from 'antd';

import styles from './TabTitle.module.scss';

function TabTitle(props: TabTitleProps) {
    return (
        <div className={styles.TabTitleContainer}>
            {
                props.title &&
                <div className={styles.TabTitleLabel}>
                    {props.title}
                </div>
            }
            <div className={styles.TabTitleIcon}>
                <Icon type={props.icon} />
            </div>
        </div>
    );
}

export default TabTitle;
