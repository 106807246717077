import AddButton from '../../../components/atoms/AddButton';
import React from 'react';
import { defineMessages } from 'react-intl';

const labels = defineMessages({
    buttonTitle: {
        defaultMessage: 'Invite user',
        id: 'scenes.SettingsUsers.UsersList.inviteUser'
    }
});

const AddUserButton: React.FunctionComponent<{ handleInvite: () => void }> = (props) => {
    return (
        <AddButton
            dataId="btn-invite-user"
            onClick={props.handleInvite}
            title={labels.buttonTitle}
        />);
};

export default AddUserButton;
