import React, { Component } from 'react';
import { WithLoaderProps } from './WithLoaderModel';
import { Loader } from '../../components';

class WithLoader extends Component<WithLoaderProps> {
    render() {
        const { children, showLoader } = this.props;
        if (showLoader) {
            return <Loader />;
        }
        return (
            children
        );
    }
}

export default WithLoader;
