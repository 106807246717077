import React from 'react';
import Button from '@components/Button';
import { FormattedMessage } from 'react-intl';
import classes from './index.module.scss';

export interface InviteNotFoundProps {
    onClick?(): any;
}

export default function InviteNotFound(props: InviteNotFoundProps) {
    return (
        <div className={classes.Invite}>
            <h1 className={classes.InviteTitle}>
                <FormattedMessage
                    defaultMessage="Squirrel!"
                    id="scenes.AcceptInvite.InviteNotFound.title"
                />
            </h1>
            <h2 className={classes.InviteSubTitle}>
                <FormattedMessage
                    defaultMessage="This invite does not exist"
                    id="scenes.AcceptInvite.InviteNotFound.subTitle"
                />
            </h2>
            <p className={classes.InviteText}>
                <FormattedMessage
                    defaultMessage="Maybe it has been accepted or it expired long time ago"
                    id="scenes.AcceptInvite.InviteNotFound.text"
                />
            </p>
            <Button onClick={props.onClick} type="primary">
                <FormattedMessage
                    defaultMessage="Bring me back home"
                    id="scenes.AcceptInvite.InviteNotFound.backToHome"
                />
            </Button>
        </div>
    );
}
