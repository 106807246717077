import React, { Component } from 'react';
import { Provider, inject, observer } from 'mobx-react';
import {
    ContentListStore, ContentStore, ContentListFiltersStore
} from '../../stores';
import {
    WithStoresComponentProps,
    WithStoresWrappedComponent
} from '../../constants/common-types';
import { ContentListFiltersStoreModel } from '../../stores/contentListFiltersStore/contentListFiltersStoreModel';
import { ContentListStoreModel } from '../../stores/contentListStore/contentListStoreModel';
import { ContentStoreModel } from '../../stores/contentStore/contentStoreModel';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';

type InjectedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
};

const withContentsStores = <T extends {}>(WrappedComponent: WithStoresWrappedComponent<T>) => {
    @inject('apiStore')
    @observer
    class WithContentsStores extends Component<WithStoresComponentProps<T>> {
        get injected() {
            return this.props as InjectedProps<T>;
        }
        contentStore: ContentStoreModel;
        contentListStore: ContentListStoreModel;
        contentListFiltersStore: ContentListFiltersStoreModel;
        constructor(props: WithStoresComponentProps<T>) {
            super(props);

            const { api } = this.injected.apiStore;

            this.contentStore = new ContentStore(api);
            this.contentListStore = new ContentListStore(api);
            this.contentListFiltersStore = new ContentListFiltersStore(api);
        }
        render() {
            return (
                <Provider
                    contentListStore={this.contentListStore}
                    contentStore={this.contentStore}
                    contentListFiltersStore={this.contentListFiltersStore}
                >
                    <WrappedComponent {...this.props} />
                </Provider>
            );
        }
    }

    return WithContentsStores;
};

export default withContentsStores;
