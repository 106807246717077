import { Col, Row } from 'antd';
import classNames from 'classnames';
import React from 'react';
import styles from './Section.module.scss';
import { SectionProps } from './SectionModel';

const Section = ({ header, children, footer, title, contentWrapperClass, additionalContainerClass }: SectionProps) => {
    const containerClass = classNames(styles.SectionContainer, additionalContainerClass);
    const bodyClass = classNames(styles.SectionContent, contentWrapperClass);
    return (
        <section className={containerClass}>
            {!!title && <Row className={styles.SectionTitle}>
                <div className={styles.TitleLabel}>{title}</div>
            </Row>}
            <Row className={bodyClass}>
                {!!header && <Col xs={24} className={styles.ContentHeader}>{header}</Col>}
                <Col xs={24} className={styles.ContentBody}>
                    {children}
                </Col>
                {!!footer && <Col xs={24} className={styles.ContentFooter}>{footer}</Col>}
            </Row>
        </section>
    );
};

export default Section;
