import { LongTextSubTypes } from '@contentchef/contentchef-types';
import { Input, Modal } from 'antd';
import classNames from 'classnames';
import 'easymde/dist/easymde.min.css';
import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import MonacoEditor from 'react-monaco-editor';
import SimpleMDE from 'react-simplemde-editor';
import {
    CLOUDINARY_IMAGE
} from '../../services/cloudinaryUtils';
import { FieldLongText } from '../../services/FormUtils/FormFields';
import MediaStepper from '../MediaStepper/MediaStepper';
import ModalTitle from '../ModalTitle/ModalTitle';
import HTMLEditor from './HTMLEditor';
import styles from './LongTextEditor.module.scss';
import { LongTextEditorProps, LongTextEditorState } from './LongTextEditorModule';

const TextArea = Input.TextArea;

const labels = defineMessages({
    markdownImageModalTitle: {
        id: 'LongTextEditor.markdownImageModalTitle',
        defaultMessage: 'Pick an image'
    },
    browseGalleryButtonTitle: {
        id: 'LongTextEditor.browseGalleryButtonTitle',
        defaultMessage: 'Pick an image from gallery'
    }
});

class LongTextEditor extends Component<LongTextEditorProps, LongTextEditorState> {
    editor: EasyMDE | null = null;
    quillEditor: any = null;

    defaultJson = {
        example_1: 'example1',
        example_2: 'example2',
        example_3: [1, 2, 3, 4]
    };

    constructor(props: LongTextEditorProps) {
        super(props);

        this.state = {
            isMediaModalVisible: false
        };
    }

    receiveMarkdown = (value: string) => {
        this.props.onChange(value.trim());
    }

    isValidJson = (value: string) => {
        try {
            JSON.parse(value);
            return true;
        } catch (e) {
            return false;
        }
    }

    onChangeJson = (value: string) => {
        const { fieldLongText } = this.props;
        if (fieldLongText.value !== value) {
            this.props.onChange(value);
        }
    }

    onChangeHtml = (value: string, e?: any) => {
        this.props.onChange(value);
    }

    retrieveDefaultValue(fieldLongText: FieldLongText, locale: string) {
        if (fieldLongText.value) {
            return fieldLongText.value;
        } else if (fieldLongText.placeholder) {
            return fieldLongText.placeholder[locale] || fieldLongText.placeholder[fieldLongText.locale];
        }
        return undefined;
    }

    onConfirmMedia = (publicId: string, transformations?: string, url?: string) => {
        const cm = this.editor!.codemirror;
        let [start, end] = (this.editor as EasyMDE & { options: EasyMDE.Options }).options!.insertTexts!.image!;
        end = end.replace('#url#', url!);
        const startPoint = Object.assign({}, cm.getCursor('start'));
        const endPoint = Object.assign({}, cm.getCursor('end'));
        const text = cm.getSelection();
        cm.replaceSelection(start + text + end);
        startPoint.ch += start.length;
        if (startPoint !== endPoint) {
            endPoint.ch += start.length;
        }
        cm.setSelection(startPoint, endPoint);
        cm.focus();
        this.setState({
            isMediaModalVisible: false
        });
    }

    onInitQuill = (editor: any) => {
        this.quillEditor = editor;
    }

    onConfirmQuill = (publicId: string, transformations?: string, url?: string) => {
        const index = this.quillEditor.selection.savedRange.index;
        this.quillEditor.insertEmbed(index, 'image', url,  'user');
        this.setState({
            isMediaModalVisible: false
        });
    }

    render() {
        const { fieldLongText, id, disabled, onChange, intl: { locale, formatMessage } } = this.props;
        switch (fieldLongText.constraints.subType) {
            case LongTextSubTypes.MARKDOWN: {
                const defaultValue = fieldLongText.value === '' && fieldLongText.placeholder
                    ? fieldLongText.placeholder[locale] || fieldLongText.placeholder[fieldLongText.locale]
                    : fieldLongText.value;
                return (
                    <React.Fragment>
                        <SimpleMDE
                            id={id}
                            className={classNames(styles.LongTextEditorMDE, {
                                [styles.LongTextEditorMDEDisabled]: disabled
                            })}
                            value={defaultValue}
                            options={{
                                autoDownloadFontAwesome: true,
                                spellChecker: false,
                                status: false,
                                toolbar: [
                                    'bold', 'italic', 'heading', '|', 'quote', 'unordered-list', 'ordered-list', '|',
                                    'link', 'image',
                                    {
                                        name: 'media',
                                        action: (editor: EasyMDE) => {
                                            this.setState({
                                                isMediaModalVisible: true,
                                            });
                                            this.editor = editor;
                                        },
                                        className: 'fa fa-folder-open-o',
                                        title: formatMessage(labels.browseGalleryButtonTitle),
                                    },
                                    '|', 'preview', 'side-by-side', 'fullscreen', '|', 'guide',
                                ]
                            }}
                            onChange={onChange}
                        />
                        <Modal
                            title={
                                <ModalTitle onCancel={() => { this.setState({ isMediaModalVisible: false }); }}>
                                    {formatMessage(labels.markdownImageModalTitle)}
                                </ModalTitle>}
                            closable={false}
                            visible={this.state.isMediaModalVisible}
                            wrapClassName={styles.CloudinaryImageModalPreview}
                            destroyOnClose={true}
                            footer={false}
                        >
                            <MediaStepper
                                type={CLOUDINARY_IMAGE}
                                onConfirmSelection={this.onConfirmMedia}
                            />
                        </Modal>
                    </React.Fragment>
                );
            }
            case LongTextSubTypes.JSON: {
                let fieldValue = this.retrieveDefaultValue(fieldLongText, locale);
                const editorOptions = {
                    automaticLayout: true,
                    extraEditorClassName: styles.monacoEditorStyle,
                    readOnly: disabled
                };
                return (
                    <MonacoEditor
                        {...{ id }}
                        width={'auto'}
                        height={'300px'}
                        language={'json'}
                        options={editorOptions}
                        defaultValue={fieldValue}
                        onChange={this.onChangeJson}
                    />
                );
            }
            case LongTextSubTypes.HTML: {
                return (
                    <>
                        <HTMLEditor id={id} onInit={this.onInitQuill} placeholder={fieldLongText.placeholder ? fieldLongText.placeholder[locale] || fieldLongText.placeholder[fieldLongText.locale] : undefined} value={fieldLongText.value} onChange={this.onChangeHtml} onClickImage={() => this.setState({isMediaModalVisible: true})} />
                        <Modal
                            title={
                                <ModalTitle onCancel={() => { this.setState({ isMediaModalVisible: false }); }}>
                                    {formatMessage(labels.markdownImageModalTitle)}
                                </ModalTitle>}
                            closable={false}
                            visible={this.state.isMediaModalVisible}
                            wrapClassName={styles.CloudinaryImageModalPreview}
                            destroyOnClose={true}
                            footer={false}
                        >
                            <MediaStepper
                                type={CLOUDINARY_IMAGE}
                                onConfirmSelection={this.onConfirmQuill}
                            />
                        </Modal>
                    </>
                );
            }
            default:
                return (
                    <TextArea
                        id={id}
                        disabled={disabled}
                        placeholder={
                            !!fieldLongText.placeholder
                                ? fieldLongText.placeholder[locale] || fieldLongText.placeholder[fieldLongText.locale]
                                : undefined
                        }
                        value={fieldLongText.value}
                        onChange={onChange}
                    />
                );
        }
    }
}

export default injectIntl(LongTextEditor);
