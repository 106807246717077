import {
    Field,
    OneFragmentOfSchemaType,
    SchemaTypeIds
} from '@contentchef/contentchef-types';

function multiCustomField(defaultLanguage: string, name: string): Field<OneFragmentOfSchemaType> {
    return {
        name,
        type: SchemaTypeIds.ONE_FRAGMENT_OF,
        labels: {
            [defaultLanguage]: name
        },
        placeholder: undefined,
        hint: undefined,
        constraints: {
            required: false,
            names: []
        },
        compact: undefined
    };
}

function multiCustomFieldInArray(): OneFragmentOfSchemaType {
    return {
        type: SchemaTypeIds.ONE_FRAGMENT_OF,
        constraints: {
            names: []
        }
    };
}

export const oneFragmentOfSnippets = {
    asField: {
        multiCustomField
    },
    asArray: {
        multiCustomFieldInArray
    }
};
