import React from 'react';
import { defineMessages } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { editExtensionPath } from '../../../constants/routing-constants';
import { withPermissionsToInteract } from '../../../providers/UserPermissionsProvider';
import ActionList, { ActionDef } from '../../atoms/ActionList';

const labels = defineMessages({
    edit: {
        id: 'ExtensionListItemActions.edit',
        defaultMessage: 'Edit'
    },
    view: {
        id: 'ExtensionListItemActions.view',
        defaultMessage: 'View'
    }
});

interface ExtensionListItemActionsProps extends RouteComponentProps<{ spaceId: string }> {
    extensionId: string;
    hasPermissions: boolean;
}

function ExtensionListItemActions(props: ExtensionListItemActionsProps) {
    const { hasPermissions } = props;

    const onEditClick = () => {
        const { match: { params: { spaceId } }, extensionId } = props;
        return editExtensionPath(spaceId, extensionId);
    };

    const viewEditAction = {
        linkHandler: onEditClick,
        title: hasPermissions ? labels.edit : labels.view,
        icon: 'edit',
        type: 'primary',
    } as ActionDef;

    return (
        <ActionList actions={[viewEditAction]} />
    );
}

export default withPermissionsToInteract(withRouter(ExtensionListItemActions));
