import React, { Component } from 'react';
import { Provider, inject, observer } from 'mobx-react';
import {
    EditContentStore,
    ChannelsStore,
    ListPublicIdStore,
    ContentStore,
    ContentListStore,
    PublishingStore,
    formMetaStore,
    MediaGalleryFlowStore,
    notificationStore,
    ContentTagStore,
    RepositoryListStore,
    MediaTagsStore
} from '../../stores';
import { WithStoresComponentProps, WithStoresWrappedComponent } from '../../constants/common-types';
import { ChannelsStoreModel } from '../../stores/channelsStore/channelsStoreModel';
import { ContentStoreModel } from '../../stores/contentStore/contentStoreModel';
import { ListPublicIdStoreModel } from '../../stores/listPublicIdStore/listPublicIdStoreModel';
import { ContentListStoreModel } from '../../stores/contentListStore/contentListStoreModel';
import { MediaGalleryFlowStoreModel } from '../../stores/mediaGalleryFlowStore/mediaGalleryFlowStoreModel';
import { FormMetaStoreModel } from '../../stores/formMetaStore/formMetaStoreModel';
import { NotificationStoreModel } from '../../stores/notificationStore/notificationStoreModel';
import { PublishingStoreModel } from '../../stores/publishingStore/publishingStoreModel';
import { EditContentStoreModel } from '../../stores/editContentStore/editContentStoreModel';
import { ContentTagStoreModel } from '../../stores/contentTagStore/contentTagStoreModel';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';
import { RepositoryListStoreModel } from '../../stores/repositoryListStore/repositoryListStoreModel';
import { MediaTagsStoreModel } from '../../stores/mediaTagsStore/mediaTagsStoreModel';
import ContentStatusStore from '@stores/contentStatusStore';
import { ExtensionManager } from '@stores/extensionManager';

type InjectedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
};

const withContentEditStores = <T extends {}>(WrappedComponent: WithStoresWrappedComponent<T>) => {
    @inject('apiStore')
    @observer
    class WithContentEditStores extends Component<WithStoresComponentProps<T>> {
        get injected() {
            return this.props as InjectedProps<T>;
        }
        editContentStore: EditContentStoreModel;
        repositoryListStore: RepositoryListStoreModel;
        channelsStore: ChannelsStoreModel;
        contentStore: ContentStoreModel;
        listPublicIdStore: ListPublicIdStoreModel;
        contentListStore: ContentListStoreModel;
        publishingStore: PublishingStoreModel;
        mediaGalleryFlowStore: MediaGalleryFlowStoreModel;
        formMetaStore: FormMetaStoreModel;
        notificationStore: NotificationStoreModel;
        contentTagStore: ContentTagStoreModel;
        mediaTagsStore: MediaTagsStoreModel;
        contentStatusStore: ContentStatusStore;
        extensionManager: ExtensionManager;

        constructor(props: WithStoresComponentProps<T>) {
            super(props);

            const { api } = this.injected.apiStore;

            this.repositoryListStore = new RepositoryListStore(api);
            this.channelsStore = new ChannelsStore(api);
            this.listPublicIdStore = new ListPublicIdStore(api);
            this.contentStore = new ContentStore(api);
            this.contentListStore = new ContentListStore(api);
            this.mediaGalleryFlowStore = new MediaGalleryFlowStore(
                api, this.injected.apiStore.currentSpaceId as string, true);
            this.publishingStore = new PublishingStore(api);
            this.formMetaStore = formMetaStore;
            this.notificationStore = notificationStore;
            this.extensionManager = new ExtensionManager(api);
            this.editContentStore = new EditContentStore(api, {
                repositoryListStore: this.repositoryListStore,
                channelsStore: this.channelsStore,
                listPublicIdStore: this.listPublicIdStore,
                publishingStore: this.publishingStore,
                contentStore: this.contentStore,
                formMetaStore: this.formMetaStore,
                extensionManager: this.extensionManager
            });
            this.contentTagStore = new ContentTagStore(api);
            this.mediaTagsStore = new MediaTagsStore(api);
            this.contentStatusStore = new ContentStatusStore(api);
        }

        render() {
            return (
                <Provider
                    editContentStore={this.editContentStore}
                    repositoryListStore={this.repositoryListStore}
                    channelsStore={this.channelsStore}
                    listPublicIdStore={this.listPublicIdStore}
                    contentStore={this.contentStore}
                    contentListStore={this.contentListStore}
                    publishingStore={this.publishingStore}
                    formMetaStore={this.formMetaStore}
                    mediaGalleryFlowStore={this.mediaGalleryFlowStore}
                    notificationStore={this.notificationStore}
                    contentTagStore={this.contentTagStore}
                    mediaTagsStore={this.mediaTagsStore}
                    contentStatusStore={this.contentStatusStore}
                    extensionManager={this.extensionManager}
                >
                    <WrappedComponent {...this.props} />
                </Provider>
            );
        }
    }

    return WithContentEditStores;
};

export default withContentEditStores;
