import { observable } from 'mobx';
import { RouterStore } from 'mobx-react-router';
import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import { RootStoreModel } from './rootStoreModel';
import { navStore } from '..';
import { NavStoreModel } from '../navStore/navStoreModel';

class RootStore implements RootStoreModel {
    @observable apiInstance: ContentChefClient;
    routerStore: RouterStore;
    navStore: NavStoreModel;

    constructor(routerStore: RouterStore, apiInstance: ContentChefClient) {
        this.apiInstance = apiInstance;
        this.routerStore = routerStore;
        this.navStore = navStore;
    }
}

export default RootStore;
