import { FormContext } from '@contexts/FormContext';
import Form, { FormComponentProps } from 'antd/lib/form';
import { RcBaseFormProps, WrappedFormInternalProps } from 'antd/lib/form/Form';
import React from 'react';

type WithFormInitializationProps<T> = T & FormComponentProps;

type FormInitializationReturnComponent<T> =
    React.ComponentType<RcBaseFormProps & Omit<T, keyof WrappedFormInternalProps>>;

const withFormInitialization = <T extends any>(WrappedComponent: React.ComponentType<T>)
    : FormInitializationReturnComponent<T> => {
    class WithFormInitialization extends React.Component<WithFormInitializationProps<T>> {
        render() {
            const { form, ...others } = this.props;
            return (
                <FormContext.Provider value={form}>
                    <WrappedComponent {...others as any} />
                </FormContext.Provider>
            );
        }
    }

    return Form.create()(WithFormInitialization as any) as any;
};

export default withFormInitialization;
