import React, { Component } from 'react';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';
import { WithStoresComponentProps, WithStoresWrappedComponent } from '../../constants/common-types';
import { inject, Provider } from 'mobx-react';
import AccessTokenStore from '../../stores/accessTokenStore';
import { NotificationStoreModel } from '../../stores/notificationStore/notificationStoreModel';
import { notificationStore } from '../../stores';

type WithAccessTokenStoresInjectedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
};

const withAccessTokenStores = <T extends {}>(WrappedComponent: WithStoresWrappedComponent<T>) => {
    @inject('apiStore')
    class WithAccessTokenStores extends Component<WithStoresComponentProps<T>> {
        accessTokenStore: AccessTokenStore;
        notificationStore: NotificationStoreModel;

        get injected() {
            return this.props as WithAccessTokenStoresInjectedProps<T>;
        }

        constructor(props: WithStoresComponentProps<T>) {
            super(props);
            const { api } = this.injected.apiStore;
            this.accessTokenStore = new AccessTokenStore(api);
            this.notificationStore = notificationStore;
        }

        render() {
            return (
                <Provider
                    accessTokenStore={this.accessTokenStore}
                    notificationStore={this.notificationStore}
                >
                    <WrappedComponent {...this.props} />
                </Provider>
            );
        }
    }

    return WithAccessTokenStores;
};

export default withAccessTokenStores;
