import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { ContentCardActionsProps, ContentCardActionsState, InjectedProps } from './ContentCardActionsModel';
import { editContentPath } from '../../constants/routing-constants';
import { defineMessages } from 'react-intl';
import { withPermissionsToInteract } from '../../providers/UserPermissionsProvider';
import ActionList, { ActionDef } from '../atoms/ActionList';
import { BadgeProps } from 'antd/lib/badge';
import styles from './index.module.scss';
import { envRelatedLabels } from '@constants/envinroment-related-labels';

const labels = defineMessages({
    edit: {
        id: 'ContentCardActions.EditLabel',
        defaultMessage: 'Edit'
    },
    view: {
        id: 'ContentCardActions.ViewLabel',
        defaultMessage: 'View'
    },
    duplicate: {
        id: 'ContentCardActions.DuplicateLabel',
        defaultMessage: 'Duplicate'
    }
});

@inject('contentStore')
@observer
class ContentCardActions extends Component<ContentCardActionsProps, ContentCardActionsState> {
    state: ContentCardActionsState = {
        isDuplicateInProgress: false
    };

    get injected() {
        return this.props as InjectedProps;
    }

    onDuplicate = () => {
        const { isDuplicateInProgress } = this.state;
        const { contentStore } = this.injected;
        if (!isDuplicateInProgress) {
            this.setState({ isDuplicateInProgress: true });
            contentStore.duplicateContent(this.props.content.id)
                .then(response => {
                    this.setState({ isDuplicateInProgress: false });
                    if (response && response.duplicatedId) {
                        const { spaceId } = this.props.match.params;
                        this.props.history.push(editContentPath(spaceId, response.duplicatedId));
                    }
                });
        }
    }

    onViewEdit = () => {
        const { content } = this.props;
        const { spaceId } = this.props.match.params;
        return editContentPath(spaceId, content.id);
    }

    statusActionProps = () => {
        const { content } = this.props;
        let defaultProps: ActionDef = {
            clickHandler: this.props.onClickStatusAction,
            title: envRelatedLabels.versions,
            icon: 'cloud-upload',
            type: 'default'
        };
        const status = content.status!;
        const originalStatus = content.status!.find(item => item.isTranslation === false);
        if (status.length === 0 || !originalStatus) {
            defaultProps = {
                ...defaultProps,
                type: 'dashed',
                title: envRelatedLabels.neverPublished
            };

            return defaultProps;
        }

        if (content.version !== originalStatus.stageVersion ||
            content.version !== originalStatus.liveVersion ||
            originalStatus.stageVersion !== originalStatus.liveVersion) {
            defaultProps = {
                ...defaultProps,
                title: envRelatedLabels.notSynchronized,
                badgeProps: {
                    dot: true,
                    className: styles.NotSynchronizedBadge
                } as BadgeProps
            };

            return defaultProps;
        }

        return {
            ...defaultProps,
            title: envRelatedLabels.synchronized,
            badgeProps: {
                dot: true,
                className: styles.Synchronized,
            }
        };
    }

    render() {
        const { showDuplicateAction, showEditAction, hasPermissions, content, showStatusAction = false } = this.props;

        const actions = [] as ActionDef[];

        if (showEditAction) {
            actions.push({
                linkHandler: this.onViewEdit,
                title: hasPermissions && !content.archived ? labels.edit : labels.view,
                icon: 'edit',
                type: 'primary',
            });
        }

        if (showDuplicateAction && hasPermissions) {
            actions.push({
                clickHandler: this.onDuplicate,
                title: labels.duplicate,
                icon: 'copy',
                type: 'default',
            });
        }

        if (showStatusAction) {
            actions.push(this.statusActionProps());
        }

        return (
            <ActionList actions={actions} />
        );
    }
}

export default withPermissionsToInteract(withRouter(ContentCardActions));
