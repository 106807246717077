import Button from '@components/Button';
import { DEFAULT_PAGE_SIZE } from '@constants/tables';
import {
    LinkedContentSchemaType, ListContentDefinitionsItem, OneLinkedContentOfSchemaType,
    SchemaTypeIds
} from '@contentchef/contentchef-types';
import { debounce } from '@services/utils';
import { ContentDefinitionListStoreModel } from '@stores/contentDefinitionListStore/contentDefinitionListStoreModel';
import { Icon, Input, List, Pagination } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import withContentDefinitionsStores from '../../../../hoc/withContentDefinitionsStores/withContentDefinitionsStores';
import CheckedIcon from '../../../CheckedIcon/CheckedIcon';
import Section from '../../../Section/Section';
import FieldEditorStore from '../../FieldEditorStore';
import { ArrayConstraints } from '../../FieldSerializer/fields/array';
import { LinkedContentConstraints } from '../../FieldSerializer/fields/linked-content';
import { OneLinkedContentOfConstraints } from '../../FieldSerializer/fields/oneLinkedContentOf';
import { ChangeStepContext, FieldSelectionStep } from '../index';
import styles from './DefinitionSelection.module.scss';

const ListItem = List.Item;
const ListItemMeta = ListItem.Meta;

interface DefinitionSelectionProps {
    selectionType: 'single' | 'multiple';
}

interface InjectedProps<T = OneLinkedContentOfSchemaType | LinkedContentSchemaType> extends DefinitionSelectionProps {
    contentDefinitionListStore: ContentDefinitionListStoreModel;
    fieldEditorStore: FieldEditorStore<T>;
}

interface DefinitionSelectionState {
    skip: number;
    take: number;
    searchText?: string;
    selectedMnemonicIds: string[];
}

function getInitialState(): DefinitionSelectionState {
    return {
        skip: 0,
        take: DEFAULT_PAGE_SIZE,
        searchText: undefined,
        selectedMnemonicIds: []
    };
}

@inject('contentDefinitionListStore', 'fieldEditorStore')
@observer
class DefinitionSelection extends Component<DefinitionSelectionProps, DefinitionSelectionState> {
    state = getInitialState();
    debounceSearch: (searchParam?: string) => void;

    isLinkedContentType = (arg: FieldEditorStore): arg is FieldEditorStore<LinkedContentConstraints> => {
        return SchemaTypeIds.LINKED_CONTENT === arg.serializedField!.type;
    }

    // tslint:disable-next-line:max-line-length
    isArrayOfLinkedContentType = (arg: FieldEditorStore<ArrayConstraints>): arg is FieldEditorStore<ArrayConstraints<LinkedContentSchemaType>> => {
        return arg.serializedField!.constraints.items.type === SchemaTypeIds.LINKED_CONTENT;
    }

    isOneLinkedContentOfType = (arg: FieldEditorStore): arg is FieldEditorStore<OneLinkedContentOfConstraints> => {
        return SchemaTypeIds.ONE_LINKED_CONTENT_OF === arg.serializedField!.type;
    }

    // tslint:disable-next-line:max-line-length
    isArrayOfLinkedContentOfType = (arg: FieldEditorStore<ArrayConstraints>): arg is FieldEditorStore<ArrayConstraints<OneLinkedContentOfSchemaType>> => {
        return arg.serializedField!.constraints.items.type === SchemaTypeIds.ONE_LINKED_CONTENT_OF;
    }

    get injected() { return this.props as InjectedProps<any>; }

    async componentDidMount(): Promise<void> {
        this.getInitialSelectedDefinition();
        await this.retrieveContentDefinitions();
    }

    retrieveContentDefinitions = async (searchParam?: string) => {
        const { skip, take } = this.state;
        await this.injected.contentDefinitionListStore.setContentDefinitions(skip, take, searchParam);
    }

    getInitialSelectedDefinition = () => {
        const { fieldEditorStore } = this.injected;
        if (fieldEditorStore.isArrayField(fieldEditorStore.serializedField!)) {
            if (this.isArrayOfLinkedContentType(fieldEditorStore)) {
                const mnemonicId = fieldEditorStore.serializedField.constraints.items.constraints.definitionMnemonicId;
                this.setState({ selectedMnemonicIds: !!mnemonicId ? [mnemonicId] : [] });
            }
            if (this.isArrayOfLinkedContentOfType(fieldEditorStore)) {
                // tslint:disable-next-line:max-line-length
                const mnemonicIds = fieldEditorStore.serializedField.constraints.items.constraints.definitionMnemonicIds;
                this.setState({ selectedMnemonicIds: !!mnemonicIds ? mnemonicIds : [] });
            }
        }
        if (this.isLinkedContentType(fieldEditorStore)) {
            const mnemonicId = fieldEditorStore.serializedField!.constraints.definitionMnemonicId;
            this.setState({ selectedMnemonicIds: !!mnemonicId ? [mnemonicId] : [] });
        }
        if (this.isOneLinkedContentOfType(fieldEditorStore)) {
            const mnemonicIds = fieldEditorStore.serializedField!.constraints.definitionMnemonicIds;
            this.setState({ selectedMnemonicIds: !!mnemonicIds ? mnemonicIds : [] });
        }
    }

    constructor(props: DefinitionSelectionProps) {
        super(props);
        this.debounceSearch = debounce(this.retrieveContentDefinitions);
    }

    onSearchDefinition = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchText: event.target.value });
        this.debounceSearch(event.target.value);
    }

    onChangePage = async (page: number) => {
        this.setState(
            (prevState) => {
                return { skip: page === 1 ? 0 : prevState.take * (page - 1) };
            },
            async () => await this.retrieveContentDefinitions()
        );

    }

    onSelectDefinition = (item: ListContentDefinitionsItem, isSelectedItem: boolean) => {
        if (this.props.selectionType === 'single') {
            this.setState({ selectedMnemonicIds: [item.mnemonicId] });
        } else {
            this.setState({
                selectedMnemonicIds: isSelectedItem
                    ? this.state.selectedMnemonicIds.filter(mnemonicId => mnemonicId !== item.mnemonicId)
                    : this.state.selectedMnemonicIds.concat(item.mnemonicId)
            });
        }
    }

    onConfirmClick = () => {
        const fieldEditorStore = this.injected.fieldEditorStore;
        if (fieldEditorStore.isArrayField(fieldEditorStore.serializedField!)) {
            if (this.isArrayOfLinkedContentType(fieldEditorStore)) {
                fieldEditorStore.updateArrayItemsConstraint<LinkedContentSchemaType>(
                    'definitionMnemonicId',
                    this.state.selectedMnemonicIds
                );
            }
            if (this.isArrayOfLinkedContentOfType(fieldEditorStore)) {
                fieldEditorStore.updateArrayItemsConstraint<OneLinkedContentOfSchemaType>(
                    'definitionMnemonicIds',
                    this.state.selectedMnemonicIds
                );
            }
        }
        if (this.isLinkedContentType(fieldEditorStore)) {
            fieldEditorStore.updateConstraint('definitionMnemonicId', this.state.selectedMnemonicIds[0]);
        }
        if (this.isOneLinkedContentOfType(fieldEditorStore)) {
            fieldEditorStore.updateConstraint('definitionMnemonicIds', this.state.selectedMnemonicIds);
        }
    }

    render() {
        const { skip, take, searchText } = this.state;
        const { dataLoaders, contentDefinitionListData } = this.injected.contentDefinitionListStore;
        return (
            <Section
                title={this.props.selectionType === 'single'
                    ? <FormattedMessage
                        id="DefinitionSelection.Title.Selection.Single"
                        defaultMessage="Select a definition"
                    />
                    : <FormattedMessage
                        id="DefinitionSelection.Title.Selection.Multiple"
                        defaultMessage="Select one or more definitions"
                    />
                }
                header={<Input
                    addonBefore={<Icon type="search" />}
                    allowClear={true}
                    onChange={this.onSearchDefinition}
                    value={searchText}
                />}
                footer={<div className={styles.FooterWrapper}>
                    <Pagination
                        current={(skip / take) + 1}
                        onChange={this.onChangePage}
                        pageSize={take}
                        total={contentDefinitionListData.total}
                    />
                    <ChangeStepContext.Consumer>
                        {
                            context => (
                                <div>
                                    <Button
                                        type="default"
                                        onClick={() => context.changeActiveStep(FieldSelectionStep.EditField)}
                                    >
                                        <FormattedMessage
                                            id="DefinitionSelection.Button.Back"
                                            defaultMessage="Back"
                                        />
                                    </Button>
                                    <Button
                                        type="primary"
                                        disabled={this.state.selectedMnemonicIds.length === 0}
                                        onClick={() => {
                                            this.onConfirmClick();
                                            context.changeActiveStep(FieldSelectionStep.EditField);
                                        }}
                                    >
                                        <FormattedMessage
                                            id="DefinitionSelection.Button.Confirm"
                                            defaultMessage="Confirm"
                                        />
                                    </Button>
                                </div>
                            )
                        }
                    </ChangeStepContext.Consumer>
                </div>}
            >
                <List
                    className={styles.ListWrapper}
                    itemLayout="horizontal"
                    loading={dataLoaders.contentDefinitionList}
                    dataSource={contentDefinitionListData.contentDefinitionList}
                    renderItem={(item: ListContentDefinitionsItem) => {
                        const { selectedMnemonicIds } = this.state;
                        return (
                            <ListItem
                                style={{ cursor: 'pointer' }}
                                id={item.id.toString()}
                                onClick={() =>
                                    this.onSelectDefinition(
                                        item, selectedMnemonicIds.includes(item.mnemonicId)
                                    )
                                }
                            >
                                <ListItemMeta
                                    avatar={<CheckedIcon selected={selectedMnemonicIds.includes(item.mnemonicId)} />}
                                    title={<span className={styles.DefinitionName}>{item.name}</span>}
                                    description={<span className={styles.DefinitionMnemonicId}>{item.mnemonicId}</span>}
                                />
                            </ListItem>
                        );
                    }}
                />
            </Section>
        );
    }
}

export default withContentDefinitionsStores(DefinitionSelection);
