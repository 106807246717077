import React from 'react';
import { Card, Empty } from 'antd';
import { EmptyPanelProps } from './EmptyPanelModel';

function EmptyPanel({ label }: EmptyPanelProps) {
    return (
        <Card>
            <Empty
                description={label}
            />
        </Card>
    );
}

export default EmptyPanel;
