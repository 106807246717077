import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import {
    CloudinaryImageUpload,
    CloudinaryPublicIdModalPreview,
    MediaFinder,
    MediaGalleryListUpload,
    Step
} from '..';
import { MediaAdapter } from '../../services/Media/MediaConverterClass';
import { getFileType } from '../../services/cloudinaryUtils';
import {
    CloudinaryPublicIdMediaStepperProps,
    CloudinaryPublicIdMediaStepperState,
    InjectedProps,
    MediaStep
} from './CloudinaryPublicIdMediaStepperModel';

@inject('mediaGalleryFlowStore')
@observer
class CloudinaryPublicIdMediaStepper extends Component<
CloudinaryPublicIdMediaStepperProps, CloudinaryPublicIdMediaStepperState
> {

    get injected() {
        return this.props as InjectedProps;
    }

    constructor(props: CloudinaryPublicIdMediaStepperProps) {
        super(props);

        const publicId = !!this.props.field.value ? this.props.field.value : '';

        const preferences = this.injected.mediaGalleryFlowStore.getMediaGalleryPreferences();

        this.state = {
            activeStep:
                    preferences?.folderPath && preferences.mediaGalleryId ? MediaStep.Folder : MediaStep.MediaGallery,
            temporaryPublicId: publicId ? publicId : ''
        };
    }

    previousStep = () => {
        const { activeStep } = this.state;
        if (activeStep > 0) {
            this.setState({
                activeStep: activeStep - 1
            });
        }
        this.injected.mediaGalleryFlowStore.clearLocalStorage();
    }

    nextStep = () => {
        const { activeStep } = this.state;
        if (activeStep < MediaStep.Upload) {
            this.setState({
                activeStep: activeStep + 1
            });
        }
    }

    jumpToPreview = (publicId: string) => {
        this.setState({
            activeStep: MediaStep.MediaPreview
        });
        this.overridePublicId(publicId);
    }

    onUpload = (results: PromiseFulfilledResult<{
        url: string;
        mediaPublicId: string;
        fileName: string;
    }>[]) => {
        const media = results[0].value;
        this.setState({
            activeStep: MediaStep.MediaPreview
        });
        this.overridePublicId(media.mediaPublicId);
    }

    overridePublicId = (publicId: string) => {
        this.setState({
            temporaryPublicId: publicId
        });
    }

    onConfirmSelection = () => {
        const { temporaryPublicId } = this.state;
        this.props.onConfirmSelection(temporaryPublicId);
    }

    onMediaClick = (media: MediaAdapter) => {
        this.injected.mediaGalleryFlowStore.toggleMedia(media);
    }

    onlyAllowedType = (media: MediaAdapter) => {
        const { type } = this.props;
        return media.data.resourceType === type;
    }

    isValidFileType = (file: File) => {
        const { type } = this.props;
        return getFileType(file.type) === type;
    }

    render() {
        const { activeStep, temporaryPublicId } = this.state;
        const { type } = this.props;
        const localFolderPath = this.injected.mediaGalleryFlowStore.getMediaGalleryPreferences().folderPath;
        return (
            <React.Fragment>
                <Step activeStep={activeStep} step={MediaStep.MediaGallery}>
                    <MediaGalleryListUpload
                        nextStep={this.nextStep}
                    />
                </Step>
                <Step activeStep={activeStep} step={MediaStep.Folder}>
                    <MediaFinder
                        onMediaClick={this.onMediaClick}
                        isForm={true}
                        nextStep={this.nextStep}
                        prevStep={this.previousStep}
                        jumpToPreview={this.jumpToPreview}
                        resourceType={type}
                        validType={this.onlyAllowedType}
                        localFolderPath={localFolderPath}
                    />
                </Step>
                <Step activeStep={activeStep} step={MediaStep.Upload}>
                    <CloudinaryImageUpload
                        limitFiles={1}
                        onUpload={this.onUpload}
                        previousStep={this.previousStep}
                        validateFileType={this.isValidFileType}
                    />
                </Step>
                <Step activeStep={activeStep} step={MediaStep.MediaPreview}>
                    <CloudinaryPublicIdModalPreview
                        type={type}
                        publicId={temporaryPublicId}
                        onConfirmSelection={this.onConfirmSelection}
                    />
                </Step>
            </React.Fragment>
        );
    }
}

export default CloudinaryPublicIdMediaStepper;
