import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RepositoryStatsProps, InjectedProps } from './RepositoryStatsModel';
import { HorizontalLoader } from '..';

import styles from './RepositoryStats.module.scss';

@inject('repositorySummaryStore')
@observer
class RepositoryStats extends Component<RepositoryStatsProps> {

    get injected() {
        return this.props as InjectedProps;
    }

    componentDidMount() {
        const { repositoryId } = this.props;
        this.injected.repositorySummaryStore.setSummaryRepository(repositoryId);
    }

    render() {
        const { repositoryId, classNames } = this.props;
        const { repositoriesStats } = this.injected.repositorySummaryStore;

        return (
            repositoriesStats[repositoryId] ?
                (
                    <div className={styles.RepositoryStatsContainer}>
                        {repositoriesStats[repositoryId].map(({ name, count }, index) => (
                            <div className={styles.RepositoryStatsStat} key={index}>
                                <div>{name + ':'}</div>
                                <div
                                    className={`${styles.RepositoryStatsCount}${
                                        classNames && classNames.count ?
                                            ` ${classNames.count}` : ''}`}
                                >
                                    {count}
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <HorizontalLoader />
                )
        );
    }
}

export default RepositoryStats;
