import React from 'react';
import AddButton from '../../../../components/atoms/AddButton';
import { createWebhookPath } from '../../../../constants/routing-constants';
import { Link } from 'react-router-dom';
import { defineMessages } from 'react-intl';

const labels = defineMessages({
    buttonTitle: {
        id: 'SettingsWebhook.WebhookList.AddButton',
        defaultMessage: 'Add new webhook'
    }
});

const AddWebookButton: React.FunctionComponent<{ spaceId: string }> = (props) => {
    return (
        <Link to={createWebhookPath(props.spaceId)}>
            <AddButton
                dataId="btn-add-webhook"
                title={labels.buttonTitle}
            />
        </Link>
    );
};

export default AddWebookButton;
