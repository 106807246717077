import {
    GetSubscriptionSummaryResponse,
    SubscriptionType,
    Interval
} from '@contentchef/contentchef-types';
import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import classes from '../index.module.scss';
import { subscriptionUpgradeToPlanPath } from '@constants/routing-constants';
import { withRouter, RouteComponentProps } from 'react-router';
import PlanCard from '@components/Plan/PlanCard/Plan';
import { Toggle } from '@components/Toggle';
import { labels } from '@services/subscriptions/labels';
import { PlanInterval, toPlanInterval, getSubscriptionPlanProps } from '@services/plans';

export interface SubscriptionDetailPlanTabProps extends
    RouteComponentProps<{ subscriptionId: string }>, InjectedIntlProps {
    subscriptionSummary: GetSubscriptionSummaryResponse;
    onUpgradeSuccess?(): any;
}

interface SubscriptionDetailPlanTabState {
    isConfirmingUpgrade: boolean;
    isWaitingConfirmResult: boolean;
    upgradingToTier: SubscriptionType | null;
    interval: Interval;
}

function getInitialState(): SubscriptionDetailPlanTabState {
    return {
        isConfirmingUpgrade: false,
        isWaitingConfirmResult: false,
        upgradingToTier: null,
        interval: Interval.year
    };
}

class SubscriptionDetailPlanTab
    extends React.Component<SubscriptionDetailPlanTabProps, SubscriptionDetailPlanTabState> {
    public static defaultProps: Partial<SubscriptionDetailPlanTabProps> = {
        onUpgradeSuccess: () => void 0,
    };

    public state = getInitialState();

    public canUpgradeTo(upgrade: SubscriptionType, upgrades: SubscriptionType[]): boolean {
        return upgrades.indexOf(upgrade) >= 0;
    }

    public getAvailableUpgrades(): SubscriptionType[] {
        return this.props.subscriptionSummary.availableUpgrades.map(i => i.subscriptionType);
    }

    public changeInterval = (value: Interval) => {
        this.setState({
            interval: value
        });
    }

    public render() {
        const availableUpgrades = this.getAvailableUpgrades();
        const intl = this.props.intl;
        const { interval } = this.state;

        return (
            <div className={classes.SubscriptionDetailPlan}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Toggle
                        left={{ value: Interval.month, label: intl.formatMessage(labels.monthlyLabel) }}
                        right={{ value: Interval.year, label: intl.formatMessage(labels.yearlyLabel) }}
                        selected={interval}
                        onChange={this.changeInterval}
                    />
                </div>
                <section className={classes.SubscriptionDetailPlanContent}>
                    {
                        this.RenderCardTier(SubscriptionType.Starter, availableUpgrades)
                    }
                    {
                        this.RenderCardTier(SubscriptionType.Professional, availableUpgrades)
                    }
                    {
                        this.RenderCardTier(SubscriptionType.Agency, availableUpgrades)
                    }
                </section>
            </div>
        );
    }

    private createNavigateToUpgrade = (planId: number) => {
        const { subscriptionId } = this.props.match.params;

        return () => {
            this.props.history.push(subscriptionUpgradeToPlanPath(subscriptionId, planId));
        };

    }

    private RenderCardTier(subscriptionType: SubscriptionType, availableUpgrades: SubscriptionType[]) {
        if (!this.canUpgradeTo(subscriptionType, availableUpgrades)) {
            return null;
        }

        return (
            this.RenderTier(subscriptionType)
        );
    }

    private RenderTier(subscriptionType: SubscriptionType) {
        const { interval } = this.state;

        switch (subscriptionType) {
            case SubscriptionType.Agency:
                return this.AgencyTier(toPlanInterval(interval));
            case SubscriptionType.Professional:
                return this.TeamTier(toPlanInterval(interval));
            case SubscriptionType.Starter:
                return this.FreelanceTier(toPlanInterval(interval));
            default:
                throw new ReferenceError(`Tier ${subscriptionType} not found`);
        }
    }

    private AgencyTier(planInterval: PlanInterval) {
        return (
            <PlanCard
                paymentInterval={planInterval}
                plan={getSubscriptionPlanProps(SubscriptionType.Agency, this.props.intl)}
                onClick={this.createNavigateToUpgrade(SubscriptionType.Agency)}
            />
        );
    }

    private TeamTier(planInterval: PlanInterval) {
        return (
            <PlanCard
                paymentInterval={planInterval}
                plan={getSubscriptionPlanProps(SubscriptionType.Professional, this.props.intl)}
                onClick={this.createNavigateToUpgrade(SubscriptionType.Professional)}
            />
        );
    }

    private FreelanceTier(planInterval: PlanInterval) {
        return (
            <PlanCard
                paymentInterval={planInterval}
                plan={getSubscriptionPlanProps(SubscriptionType.Starter, this.props.intl)}
                onClick={this.createNavigateToUpgrade(SubscriptionType.Starter)}
            />
        );
    }
}

export default withRouter(SubscriptionDetailPlanTab);
