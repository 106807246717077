import React from 'react';
import { Input } from 'antd';
import classes from './index.module.scss';
import { isFunction } from '../../../../services/utils';
import classNames from 'classnames';
import {
    withPermissionsToInteract,
    WithPermissionsToInteractReturnType
} from '../../../../providers/UserPermissionsProvider';

export interface LanguageFieldProps extends WithPermissionsToInteractReturnType {
    locale: string;
    value: string;
    onChangeValue(value: string): void;
}

function LanguageField(props: LanguageFieldProps) {
    return (
        <div className={classes.LanguageField}>
            <Input
                disabled={!props.hasPermissions}
                addonBefore={props.locale}
                className={classNames(classes.LanguageFieldInput)}
                onChange={event => isFunction(props.onChangeValue) && props.onChangeValue(event.target.value)}
                value={props.value}
            />
        </div>
    );
}

export default withPermissionsToInteract(LanguageField);
