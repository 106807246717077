import React from 'react';
import { Switch, Route } from 'react-router';
import { ROOT_SUBSCRIPTION_PATH } from '../../constants/routing-constants';
import SubscriptionRouter from '../../scenes/Subscriptions/Subscription/SubscriptionRouter';

interface SubscriptionBasedRoutesProps { }

const SubscriptionBasedRoutes = (props: SubscriptionBasedRoutesProps) => {
    return (
        <Switch>
            <Route
                path={ROOT_SUBSCRIPTION_PATH}
                component={SubscriptionRouter}
            />
        </Switch>
    );
};

export default SubscriptionBasedRoutes;
