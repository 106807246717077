import { Webhook, WebhookLifecycleEvents } from '@contentchef/contentchef-types';
import { Form, Input, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Component } from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import withForm from '../../hoc/withForm/withForm';

export type WebhookManageableAttrs = {
    name: string,
    url: string,
    webhookType: WebhookLifecycleEvents | WebhookLifecycleEvents[];
};

export interface WebhookFormProps extends InjectedIntlProps, FormComponentProps {
    webhook?: Webhook;
}
export interface WebhookFormState { }

export enum WebhookFormIds {
    name = 'name',
    url = 'url',
    eventType = 'eventType'
}

const webhookFormLabels = defineMessages({
    formNameLabel: {
        id: 'SettingWebhook.WebhookForm.NameField.Label',
        defaultMessage: 'Name'
    },
    formUrlLabel: {
        id: 'SettingWebhook.WebhookForm.UrlField.Label',
        defaultMessage: 'Url'
    },
    formEventTypeLabel: {
        id: 'SettingWebhook.WebhookForm.EventTypeField.Label',
        defaultMessage: 'Event type'
    },
    formNamePlaceholder: {
        id: 'SettingWebhook.WebhookForm.NameField.Placeholder',
        defaultMessage: 'Insert webhook name'
    },
    formUrlPlaceholder: {
        id: 'SettingWebhook.WebhookForm.UrlField.Placeholder',
        defaultMessage: 'Insert webhook url like https:///www.youdomain.com/a-valid-path'
    },
    formEventTypePlaceholder: {
        id: 'SettingsWebhook.WebhookForm.EventTypeField.Placeholder',
        defaultMessage: 'Select an event type'
    },
    formEventTypeValue1: {
        id: 'SettingsWebhook.WebhookForm.EventTypeField.Value1',
        defaultMessage: 'Publish to live'
    },
    formEventTypeValue2: {
        id: 'SettingsWebhook.WebhookForm.EventTypeField.Value2',
        defaultMessage: 'Publish to stage'
    },

});

const availableWebhookEventTypes = [
    WebhookLifecycleEvents.PublishToLive,
    WebhookLifecycleEvents.PublishToStage
];

class WebhookForm extends Component<WebhookFormProps, WebhookFormState> {

    getEventReadableType = (eventType: WebhookLifecycleEvents) => {
        if (eventType === WebhookLifecycleEvents.PublishToLive) {
            return this.props.intl.formatMessage(webhookFormLabels.formEventTypeValue1);
        }
        if (eventType === WebhookLifecycleEvents.PublishToStage) {
            return this.props.intl.formatMessage(webhookFormLabels.formEventTypeValue2);
        }
        return '';
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { getFieldDecorator } = this.props.form;
        return (
            <Form>
                <Form.Item
                    colon={false}
                    required={true}
                    label={formatMessage(webhookFormLabels.formNameLabel)}
                >
                    {getFieldDecorator(WebhookFormIds.name, {
                        initialValue: this.props.webhook !== undefined ? this.props.webhook.name : undefined,
                        rules: [{ required: true, type: 'string', whitespace: true }]
                    })(<Input placeholder={formatMessage(webhookFormLabels.formNamePlaceholder)} />)}
                </Form.Item>
                <Form.Item
                    colon={false}
                    required={true}
                    label={formatMessage(webhookFormLabels.formUrlLabel)}
                >
                    {getFieldDecorator(WebhookFormIds.url, {
                        initialValue: this.props.webhook !== undefined ? this.props.webhook.url : undefined,
                        rules: [
                            {
                                required: true, type: 'string', whitespace: true,
                                pattern: /^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
                            }]
                    })(<Input placeholder={formatMessage(webhookFormLabels.formUrlPlaceholder)} />)}
                </Form.Item>
                <Form.Item
                    colon={false}
                    required={true}
                    label={formatMessage(webhookFormLabels.formEventTypeLabel)}
                >
                    {getFieldDecorator(WebhookFormIds.eventType, {
                        initialValue: availableWebhookEventTypes[0],
                        rules: [{ required: true }]
                    })(
                        <Select
                            {...this.props.webhook ? {disabled: true} : {mode: 'tags', tokenSeparators: [',']}}
                            disabled={this.props.webhook ? true : false}
                            placeholder={formatMessage(webhookFormLabels.formEventTypePlaceholder)}
                        >
                            {this.retrieveEventTypeOptions()}
                        </Select>
                    )}
                </Form.Item>
            </Form>
        );
    }

    retrieveEventTypeOptions = () => {
        return availableWebhookEventTypes.map(eventType => (
            <Select.Option key={eventType} value={eventType}>{this.getEventReadableType(eventType)}</Select.Option>
        ));
    }
}

export default withForm(injectIntl(WebhookForm));
