import { Interval, SubscriptionType } from '@contentchef/contentchef-types';
import { labels, agencyTierLabels, teamTierLabels, freelanceTierLabels } from '../subscriptions/labels';
import { InjectedIntl } from 'react-intl';

export type Plan = {
    name: string;
    description: string;
    suggested?: boolean;
    price: {
        currency: string;
        monthly: number;
        yearly: number;
    };
    mostValuatedFeatures: string[];
    includedBenefits: string[];
};

export enum PlanInterval {
    monthly = 'Monthly',
    yearly = 'Yearly'
}

function agencyTierData(intl: InjectedIntl) {
    return {
        name: intl.formatMessage(agencyTierLabels.name),
        description: intl.formatMessage(agencyTierLabels.description),
        price: {
            currency: '$',
            monthly: 1499,
            yearly: 1249 // monthly price for a yearly subscription
        },
        mostValuatedFeatures: [
            intl.formatMessage(agencyTierLabels.feat1),
            intl.formatMessage(agencyTierLabels.feat2),
            intl.formatMessage(agencyTierLabels.feat3)
        ],
        includedBenefits: [
            intl.formatMessage(labels.usersBenefit, { value: intl.formatMessage(labels.enterpriseUnlimitedLabel) }),
            intl.formatMessage(labels.spacesBenefit, { value: 20 }),
            intl.formatMessage(labels.mediaBenefit, { value: 100 })
        ]
    };
}

function teamTierData(intl: InjectedIntl) {
    return {
        name: intl.formatMessage(teamTierLabels.name),
        description: intl.formatMessage(teamTierLabels.description),
        price: {
            currency: '$',
            monthly: 149,
            yearly: 125 // monthly price for a yearly subscription
        },
        suggested: true,
        mostValuatedFeatures: [
            intl.formatMessage(teamTierLabels.feat1),
            intl.formatMessage(teamTierLabels.feat2),
            intl.formatMessage(teamTierLabels.feat3)
        ],
        includedBenefits: [
            intl.formatMessage(labels.usersBenefit, { value: 5 }),
            intl.formatMessage(labels.spacesBenefit, { value: 5 }),
            intl.formatMessage(labels.mediaBenefit, { value: 20 })
        ]
    };
}

function freelanceTierData(intl: InjectedIntl) {
    return {
        name: intl.formatMessage(freelanceTierLabels.name),
        description: intl.formatMessage(freelanceTierLabels.description),
        price: {
            currency: '$',
            monthly: 9,
            yearly: 8 // monthly price for a yearly subscription
        },
        mostValuatedFeatures: [
            intl.formatMessage(freelanceTierLabels.feat1),
            intl.formatMessage(freelanceTierLabels.feat2),
            intl.formatMessage(freelanceTierLabels.feat3)
        ],
        includedBenefits: [
            intl.formatMessage(labels.usersBenefit, { value: 2 }),
            intl.formatMessage(labels.spacesBenefit, { value: 2 }),
            intl.formatMessage(labels.mediaBenefit, { value: 5 })
        ]
    };
}

// function enterpriseTierData(intl: InjectedIntl) {
//     return {
//         imageSrc: EnterpriseTier,
//         title: intl.formatMessage(labels.EnterpriseTier),
//         cost: intl.formatMessage(labels.enterpriseContactUsLabel),
//         spaceCount: intl.formatMessage(labels.enterpriseUnlimitedLabel)
//     };
// }

// export function getPlanData(subscriptionType: SubscriptionType, intl: InjectedIntl) {
//     switch (subscriptionType) {
//         case SubscriptionType.Agency:
//             return agencyTierData(interval, intl);
//         case SubscriptionType.Enterprise:
//             return enterpriseTierData(intl);
//         case SubscriptionType.Professional:
//             return professionalTierData(interval, intl);
//         case SubscriptionType.Starter:
//             return starterTierData(interval, intl);
//         default:
//             throw new ReferenceError(`Tier ${subscriptionType} not found`);
//     }
// }

export function getSubscriptionPlanProps(subscriptionType: SubscriptionType, intl: InjectedIntl) {
    switch (subscriptionType) {
        case SubscriptionType.Agency:
            return agencyTierData(intl);
        // case SubscriptionType.Enterprise:
        //     return enterpriseTierData(intl);
        case SubscriptionType.Professional:
            return teamTierData(intl);
        case SubscriptionType.Starter:
            return freelanceTierData(intl);
        default:
            throw new ReferenceError(`Tier ${subscriptionType} not found`);
    }
}

export function toPlanInterval(interval: Interval): PlanInterval {
    switch (interval) {
        case Interval.month:
            return PlanInterval.monthly;
        case Interval.year:
            return PlanInterval.yearly;
        default:
            throw new ReferenceError(`Interval ${interval} not supported`);
    }
}
