import React, { Component } from 'react';
import { Table } from 'antd';

import { CCTableProps } from './CCTableModel';

import styles from './CCTable.module.scss';

class CCTable extends Component<CCTableProps> {
    defaultColumn = {
        width: 200,
    };
    getDefaultCols = () =>
        this.props.columns.map(col => ({
            ...this.defaultColumn,
            ...col,
        }))

    render() {
        const {
            isLoading,
            dataSource,
            onTableChange,
            onRowClick,
            rowClassName
        } = this.props;

        const columns = this.getDefaultCols();
        return (
            <div className={styles.CCTableDataContainer}>
                <Table
                    onRow={
                        (record: any) => {
                            return {
                                onClick: (e: React.MouseEvent) => {
                                    e.stopPropagation();
                                    return onRowClick && onRowClick(record);
                                }
                            };
                        }
                    }
                    rowClassName={rowClassName}
                    dataSource={dataSource.map((data: any) => { return ({ ...data, key: data.id }); })}
                    columns={columns}
                    loading={isLoading}
                    onChange={onTableChange}
                    scroll={{ y: true }}
                    useFixedHeader={true}
                    pagination={false}
                />
            </div>
        );
    }
}

export default CCTable;
