import React, { Component } from 'react';
import styles from './NavbarLayout.module.scss';
import { Navbar } from '../../components';
import { Layout } from 'antd';

interface NavbarLayoutProps {
    layoutClassName?: string;
    hideActions?: boolean;
}

class NavbarLayout extends Component<NavbarLayoutProps, {}> {
    render() {
        const { layoutClassName, hideActions } = this.props;
        return (
            <Layout
                className={!!layoutClassName ? layoutClassName : styles.NavbarLayoutContainer}
            >
                <Navbar hideActions={hideActions} />
                <Layout className={styles.NavbarLayoutContent}>
                    {this.props.children}
                </Layout>
            </Layout>
        );
    }
}

export default NavbarLayout;
