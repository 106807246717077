import React, { Component } from 'react';
import { Provider, observer, inject } from 'mobx-react';
import { notificationStore, RepositoryStore } from '../../stores';
import { WithStoresComponentProps, WithStoresWrappedComponent } from '../../constants/common-types';
import { RepositoryStoreModel } from '../../stores/repositoryStore/repositoryStoreModel';
import { NotificationStoreModel } from '../../stores/notificationStore/notificationStoreModel';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';

type InjectedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
};

const withRepositoryFormStores = <T extends {}>(WrappedComponent: WithStoresWrappedComponent<T>) => {
    @inject('apiStore')
    @observer
    class WithRepositoryFormStores extends Component<WithStoresComponentProps<T>> {
        get injected() {
            return this.props as InjectedProps<T>;
        }
        repositoryStore: RepositoryStoreModel;
        notificationStore: NotificationStoreModel;
        constructor(props: WithStoresComponentProps<T>) {
            super(props);

            const { api } = this.injected.apiStore;

            this.notificationStore = notificationStore;
            this.repositoryStore = new RepositoryStore(api);
        }
        render() {
            return (
                <Provider
                    notificationStore={this.notificationStore}
                    repositoryStore={this.repositoryStore}
                >
                    <WrappedComponent {...this.props} />
                </Provider>
            );
        }
    }

    return WithRepositoryFormStores;
};

export default withRepositoryFormStores;
