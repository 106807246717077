import { UserProfileIds } from '@contentchef/contentchef-types';
import { Form, Input, Modal, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { defineMessages, FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { withForm, withFormInitialization } from '../../hoc';
import { InviteStoreModel } from '../../stores/inviteStore';
import Typography from '../Typography';
import UserProfileTreeSelect from '../UserProfileTreeSelect';

const labels = defineMessages({
    modalOkText: {
        id: 'components.InviteUserModal.modal.okText', defaultMessage: 'Send invitation'
    }
});

export interface InviteUserModalProps extends InjectedIntlProps, FormComponentProps {
    open?: boolean;
    spaceId: string;
    onClose?(): any;
    onInvite?(): any;
}

interface DecoratedInviteUserModalProps extends InviteUserModalProps {
    inviteStore: InviteStoreModel;
}

interface InviteUserModalState {
    invitedUserEmail: string;
    isInviting: boolean;
    invalidUserRoles: boolean;
    invitedUserRoles: UserProfileIds[];
}

function getInitialState(): InviteUserModalState {
    return {
        invitedUserEmail: '',
        isInviting: false,
        invalidUserRoles: false,
        invitedUserRoles: [],
    };
}

@inject('inviteStore')
@observer
class InviteUserModal extends React.Component<InviteUserModalProps, InviteUserModalState> {
    public get decoratedProps(): DecoratedInviteUserModalProps {
        return this.props as DecoratedInviteUserModalProps;
    }

    public state = getInitialState();

    public handleInviteUser = () => {
        this.props.form.validateFields(async error => {
            const {
                invitedUserEmail,
                invitedUserRoles,
            } = this.state;

            if (invitedUserRoles.length === 0) {
                return this.setState({
                    invalidUserRoles: true,
                });
            }

            if (error) {
                return;
            }

            await this.inviteUser(invitedUserEmail, invitedUserRoles);
        });
    }

    public async inviteUser(invitedUserEmail: string, invitedUserRoles: UserProfileIds[]) {
        const store = this.decoratedProps.inviteStore;

        this.setState({ isInviting: true });

        try {
            await store.inviteUser(invitedUserEmail, invitedUserRoles, this.props.spaceId);
            notification.success({
                message: (
                    <FormattedMessage
                        defaultMessage="Invite sent to {invitedUserEmail}"
                        id="components.InviteUserModal.notification.inviteSent"
                        values={{ invitedUserEmail }}
                    />
                ),
            });
            this.setState(getInitialState());

            if (typeof this.props.onInvite === 'function') {
                this.props.onInvite();
            }

        } catch (error: any) {
            notification.error({
                message: (
                    <FormattedMessage
                        defaultMessage="{error}"
                        id="components.InviteUserModal.notification.inviteError"
                        values={{ error: error.message }}
                    />
                )
            });
            this.setState({ isInviting: false });
        }
    }

    public render() {
        const {
            form: {
                getFieldDecorator,
            },
            onClose,
            open,
            intl: {
                formatMessage
            }
        } = this.props;

        return (
            <Modal
                onCancel={onClose}
                onOk={this.handleInviteUser}
                okButtonProps={{
                    disabled: this.state.isInviting,
                    loading: this.state.isInviting,
                }}
                title={
                    <FormattedMessage
                        defaultMessage="Invite an user to this space"
                        id="components.InviteUserModal.modal.title"
                    />
                }
                okText={formatMessage(labels.modalOkText)}
                visible={open}
            >
                <Form>
                    <Form.Item>
                        <Typography variant="label">
                            <FormattedMessage
                                defaultMessage="User email"
                                id="components.InviteUserModal.modal.userEmail"
                            />
                        </Typography>
                        {
                            getFieldDecorator('Email', {
                                initialValue: this.state.invitedUserEmail,
                                rules: [
                                    {
                                        type: 'email'
                                    },
                                    {
                                        required: true,
                                    }
                                ]
                            })(
                                <Input
                                    onChange={
                                        event =>
                                            this.setState({
                                                invitedUserEmail: event.target.value
                                            })
                                    }
                                />
                            )
                        }
                    </Form.Item>
                    <Form.Item
                        validateStatus={
                            this.state.invalidUserRoles
                                ? 'error'
                                : 'validating'
                        }
                    >
                        <Typography variant="label">
                            <FormattedMessage
                                defaultMessage="Choose user roles (at least one)"
                                id="components.InviteUserModal.roles"
                            />
                        </Typography>
                        <UserProfileTreeSelect
                            onChange={
                                (invitedUserRoles) =>
                                    this.setState({
                                        invalidUserRoles: false,
                                        invitedUserRoles,
                                    })
                            }
                            value={this.state.invitedUserRoles}
                        />
                        {
                            this.state.invalidUserRoles &&
                            <span className="ant-form-explain">
                                <FormattedMessage
                                    defaultMessage="You must choose at least one role"
                                    id="components.InviteUserModal.roles.invalid"
                                />
                            </span>
                        }
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default withFormInitialization(
    withForm(
        injectIntl(
            InviteUserModal
        )
    )
);
