import { action, computed, IObservableArray, observable, toJS } from 'mobx';
import {
    Field,
    FragmentDefinition, I18NDictionary
} from '@contentchef/contentchef-types';
import {
    AttributeViolation,
    SchemaFieldViolationsErrors
} from '@contentchef/contentchef-types';
import arrayMove from 'array-move';
import { SerializedField } from './FieldSerializer/types';
import FieldSerializer from './FieldSerializer';

type SerializedFragmentDefinition = {
    fields: IObservableArray<SerializedField>;
    labels?: I18NDictionary;
};

const getDefaultSerializedFragment = (): SerializedFragmentDefinition => {
    return {
        fields: observable.array([]),
        labels: undefined
    };
};

export default class CustomFieldEditorStore {
    @observable fragment: SerializedFragmentDefinition = getDefaultSerializedFragment();
    @observable fragmentErrors:
        IObservableArray<SchemaFieldViolationsErrors | AttributeViolation> = observable.array([]);

    // tslint:disable-next-line:max-line-length
    isSchemaFieldViolationErrors(arg: SchemaFieldViolationsErrors | AttributeViolation): arg is SchemaFieldViolationsErrors {
        return 'fieldIndex' in arg;
    }

    isAttributeViolationErrors(arg: SchemaFieldViolationsErrors | AttributeViolation): arg is AttributeViolation {
        return 'key' in arg && 'details' in arg;
    }

    @action
    initFragment = (fragment?: FragmentDefinition) => {
        if (!!fragment) {
            this.fragment = this.serializeFragment(fragment);
        } else {
            this.fragment = getDefaultSerializedFragment();
        }
    }

    retrieveDeserializedFragment = (): FragmentDefinition => {
        return {
            labels: this.fragment.labels,
            fields: this.deserializeFragmentField(this.fragment.fields)
        };
    }

    @action
    setFragmentErrors = (errors: (SchemaFieldViolationsErrors | AttributeViolation)[]) => {
        this.fragmentErrors.replace(errors);
    }

    @action
    cleanFragmentErrors = () => {
        this.fragmentErrors.clear();
    }

    @action
    saveNewField = (field: SerializedField) => {
        this.fragment.fields.push(field);
    }

    @action
    editField = (fieldIndexToEdit: number, field: SerializedField) => {
        this.removeFieldErrors(fieldIndexToEdit);
        this.fragment.fields.splice(fieldIndexToEdit, 1, field);
    }

    @action
    cloneField = (field: SerializedField, hasErrors?: boolean) => {
        const fieldToCopy = { ...toJS(field, { recurseEverything: true }), initialIndex: this.fragment.fields.length };
        this.fragment.fields.push(fieldToCopy);
        if (hasErrors) {
            this.cloneFieldErrors(field.initialIndex);
        }
    }

    @action cloneFieldErrors = (fieldIndex: number) => {
        const cloneFieldViolations = this.retrieveFieldErrors(fieldIndex);
        const clonedErrors: SchemaFieldViolationsErrors = {
            fieldIndex: this.fragment.fields.length,
            violations: cloneFieldViolations
        };
        this.fragmentErrors.push(clonedErrors);
    }

    @action
    deleteField = (fieldIndexToDelete: number, hasErrors?: boolean) => {
        if (hasErrors) {
            this.removeFieldErrors(fieldIndexToDelete);
        }
        this.fragment.fields.splice(fieldIndexToDelete, 1);
    }

    @action
    editingLabelsCreate = (culture: string) => {
        this.fragment.labels = {
            ...this.fragment.labels,
            [culture]: ''
        };
    }

    @action
    editingLabelsUpdate = (culture: string, value: string) => {
        this.removeLabelsValidationErrors();
        this.fragment.labels = {
            ...this.fragment.labels,
            [culture]: value
        };
    }

    @action
    removeLabelsValidationErrors = () => {
        const labelsErrorsIndex = this.fragmentErrors.findIndex(error => {
            if (this.isAttributeViolationErrors(error)) {
                return error.key === 'labels';
            }
            return false;
        });
        if (labelsErrorsIndex > -1) {
            this.fragmentErrors.splice(labelsErrorsIndex, 1);
        }
    }

    @action
    editingLabelsRemove = (culture: string) => {
        if (this.fragment.labels) {
            delete this.fragment.labels[culture];
        }
    }

    fieldHasErrors = (fieldIndex: number) => {
        return !!this.fragmentErrors.find(error => {
            if (this.isSchemaFieldViolationErrors(error)) {
                return error.fieldIndex === fieldIndex;
            }
            return false;
        });
    }

    retrieveFieldErrors = (fieldIndex: number) => {
        const errors = this.fragmentErrors.find(fieldViolations => {
            if (!this.isSchemaFieldViolationErrors(fieldViolations)) {
                return false;
            }
            return fieldViolations.fieldIndex === fieldIndex;
        });
        if (!!errors && this.isSchemaFieldViolationErrors(errors) && typeof errors.violations !== 'string') {
            return errors.violations;
        }
        return [];
    }

    @action
    removeFieldErrors = (fieldIndex: number) => {
        const fieldIndexToRemove = this.fragmentErrors.findIndex(fieldErrors => {
            if (this.isSchemaFieldViolationErrors(fieldErrors)) {
                return fieldErrors.fieldIndex === fieldIndex;
            }
            return false;
        });
        this.fragmentErrors.splice(fieldIndexToRemove, 1);
    }

    @action
    changeFieldsPosition = (oldIndex: number, newIndex: number) => {
        if (oldIndex !== newIndex) {
            this.fragment.fields.replace(arrayMove(this.fragment.fields, oldIndex, newIndex));
        }
    }

    @action
    resetFieldsInitialIndex = () => {
        this.fragment.fields.replace(this.fragment.fields.map(
            (field, index) =>
                ({ ...field, initialIndex: index })
        ));
    }

    @computed
    get nameValidationErrors() {
        const nameValidationErrors = this.fragmentErrors.find(error => {
            if (this.isAttributeViolationErrors(error)) {
                return error.key === 'name';
            }
            return false;
        });
        if (nameValidationErrors && this.isAttributeViolationErrors(nameValidationErrors)) {
            return nameValidationErrors.details;
        }
        return [];
    }

    @computed
    get labelsValidationErrors() {
        const labelsValidationErrors = this.fragmentErrors.find(error => {
            if (this.isAttributeViolationErrors(error)) {
                return error.key === 'labels';
            }
            return false;
        });
        if (labelsValidationErrors && this.isAttributeViolationErrors(labelsValidationErrors)) {
            return labelsValidationErrors.details;
        }
        return [];
    }

    deserializeFragmentField = (fields: SerializedField[]): Field[] => {
        return fields.map(field => FieldSerializer.deserialize(field));
    }

    private serializeFragment = (fragment: FragmentDefinition): SerializedFragmentDefinition => {
        return {
            labels: fragment.labels,
            fields: observable.array(fragment.fields.map(
                (field, index) => FieldSerializer.serialize(field, index))
            )
        };
    }
}
