import React from 'react';
import { Icon } from 'antd';
import classNames from 'classnames';
import styles from './CheckedIcon.module.scss';

export type CheckedIconProps = {
    selected: boolean;
};

export default function CheckedIcon(props: CheckedIconProps) {
    return (
        <Icon
            type="check-circle"
            className={classNames(styles.IconDefault, {
                [styles.IconSelected]: props.selected
            })}
        />
    );
}
