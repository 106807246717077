import { defineMessages } from 'react-intl';

export const ADD_CONTENT_CONSTANTS = {
    FIELDS: {
        NAME: 'name',
        ALIAS: 'alias',
        EMPTY_ALIAS: '',
        ONLINE_RANGE: 'onlineRange'
    }
};

export const EDIT_CONTENT_CONSTANTS = {
    FIELDS: {
        NAME: 'name',
        ALIAS: 'alias',
        ONLINE_RANGE: 'onlineRange'
    }
};

export const CLOUDINARY_IMAGE_CONSTANTS = {
    TABS: {
        PREVIEW: 'preview',
        BROWSE: 'browse',
        UPLOAD: 'upload'
    }
};

const typeLabels = defineMessages({
    text: {
        id: 'typeLabel.text',
        defaultMessage: 'Text'
    },
    'long-text': {
        id: 'typeLabel.longText',
        defaultMessage: 'Long Text'
    },
    'linked-content': {
        id: 'typeLabel.linkedContent',
        defaultMessage: '(LEGACY) Content Reference'
    },
    'content-reference': {
        id: 'typeLabel.contentReference',
        defaultMessage: 'Content Reference'
    },
    'cloudinary-publicId': {
        id: 'typeLabel.cloudinaryPublicId',
        defaultMessage: 'Media Reference'
    },
    'cloudinary-image': {
        id: 'typeLabel.cloudinaryImage',
        defaultMessage: 'Transformable Image'
    },
    'cloudinary-video': {
        id: 'typeLabel.cloudinaryVideo',
        defaultMessage: 'Transformable Video'
    },
    'cloudinary-raw': {
        id: 'typeLabel.cloudinaryRaw',
        defaultMessage: 'File Reference'
    },
    number: {
        id: 'typeLabel.number',
        defaultMessage: 'Number'
    },
    boolean: {
        id: 'typeLabel.boolean',
        defaultMessage: 'Boolean'
    },
    date: {
        id: 'typeLabel.date',
        defaultMessage: 'Date'
    },
    fragment: {
        id: 'typeLabel.fragment',
        defaultMessage: 'Custom Field'
    },
    oneFragmentOf: {
        id: 'typeLabel.oneFragmentOf',
        defaultMessage: 'Selectable Custom Field'
    },
    oneLinkedContentOf: {
        id: 'typeLabel.oneLinkedContentOf',
        defaultMessage: 'Content Reference'
    },
    array: {
        id: 'typeLabel.array',
        defaultMessage: 'List of Fields'
    }
});

export function getTypeLabel(type: string) {
    return typeLabels[type as keyof typeof typeLabels];
}

export const SLUG_PATTERN = /^[a-z0-9_]+(?:-[a-z0-9_]+)*$/;
export const SLUG_PATTERN_SLASH = /^[a-z0-9/_]+(?:-[a-z0-9/_]+)*$/;
export const SLUG_SUFFIX_CONSTRAINT = /^([-_]{1}(?:[a-z0-9_]+)+)+$/;
export const SLUG_PREFIX_CONSTRAINT = /^([a-z0-9_]+(?:[-_]{1})+)+$/;
export const SLUG_SUFFIX_SLASH_CONSTRAINT = /^([/-_]{1}(?:[a-z0-9/_]+)+)+$/;
export const SLUG_PREFIX_SLASH_CONSTRAINT = /^([a-z0-9/_]+(?:[-/_]{1})+)+$/;
