import React, { Component } from 'react';
import { Provider, observer, inject } from 'mobx-react';
import {
    RepositoryListStore, RepositorySummaryStore
} from '../../stores';
import { WithStoresComponentProps, WithStoresWrappedComponent } from '../../constants/common-types';
import { RepositoryListStoreModel } from '../../stores/repositoryListStore/repositoryListStoreModel';
import { RepositorySummaryStoreModel } from '../../stores/repositorySummaryStore/repositorySummaryStoreModel';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';

type InjectedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
};

const withRepositoriesStores = <T extends {}>(WrappedComponent: WithStoresWrappedComponent<T>) => {
    @inject('apiStore')
    @observer
    class WithRepositoriesStore extends Component<WithStoresComponentProps<T>> {
        get injected() {
            return this.props as InjectedProps<T>;
        }
        repositoryListStore: RepositoryListStoreModel;
        repositorySummaryStore: RepositorySummaryStoreModel;
        constructor(props: WithStoresComponentProps<T>) {
            super(props);
            const { api } = this.injected.apiStore;
            this.repositoryListStore = new RepositoryListStore(api);
            this.repositorySummaryStore = new RepositorySummaryStore(api);
        }
        render() {
            return (
                <Provider
                    repositoryListStore={this.repositoryListStore}
                    repositorySummaryStore={this.repositorySummaryStore}
                >
                    <WrappedComponent {...this.props} />
                </Provider>
            );
        }
    }

    return WithRepositoriesStore;
};

export default withRepositoriesStores;
