import React, { Component } from 'react';
import { Provider, inject, observer } from 'mobx-react';
import { WithStoresComponentProps, WithStoresWrappedComponent } from '../../constants/common-types';
import { ContentListStoreModel } from '../../stores/contentListStore/contentListStoreModel';
import { ContentListFiltersStoreModel } from '../../stores/contentListFiltersStore/contentListFiltersStoreModel';
import { ContentListStore, ContentListFiltersStore } from '../../stores';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';

type InjectedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
};

const withLinkedFieldStores = <T extends {}>(WrappedComponent: WithStoresWrappedComponent<T>) => {
    @inject('apiStore')
    @observer
    class WithLinkedFieldStores extends Component<WithStoresComponentProps<T>> {
        get injected() {
            return this.props as InjectedProps<T>;
        }
        contentListStore: ContentListStoreModel;
        contentListFiltersStore: ContentListFiltersStoreModel;

        constructor(props: WithStoresComponentProps<T>) {
            super(props);

            const { api } = this.injected.apiStore;

            this.contentListStore = new ContentListStore(api);
            this.contentListFiltersStore = new ContentListFiltersStore(api);
        }

        render() {
            return (
                <Provider
                    contentListStore={this.contentListStore}
                    contentListFiltersStore={this.contentListFiltersStore}
                >
                    <WrappedComponent {...this.props} />
                </Provider>
            );
        }
    }

    return WithLinkedFieldStores;
};

export default withLinkedFieldStores;
