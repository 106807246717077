import React, { Component } from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, Pagination, Spin } from 'antd';
import { SchemaTypeIds } from '@contentchef/contentchef-types';
import { LoaderManagerStoreModel } from '../../stores/loaderManagerStore/loaderManagerStoreModel';
import LoaderManagerStore from '../../stores/loaderManagerStore/loaderManagerStore';
import { LinkedContentPreviewProps, LinkedContentPreviewState, InjectedProps } from './LinkedContentPreviewModel';
import { LinkedContentsFilters } from '../../stores/contentListStore/contentListStoreModel';
import { ContentCard } from '../index';

import styles from './LinkedContentPreview.module.scss';

const DEFAULT_TAKE: number = 50;
const DEFAULT_SKIP: number = 0;

@inject('contentListStore')
@observer
class LinkedContentPreview extends Component<LinkedContentPreviewProps, LinkedContentPreviewState> {
    loaderManagerStore: LoaderManagerStoreModel;

    state: LinkedContentPreviewState = {
        showLinkedContentModal: false,
        skipMultiplier: DEFAULT_SKIP
    };

    fieldValueReaction = reaction(
        () => this.props.linkedField.getSubmittableValue(),
        async fieldValue => {
            if (fieldValue) {
                this.loaderManagerStore.setLoader();
                await this.setLinkedContents();
                this.loaderManagerStore.unsetLoader();
            }
        }
    );

    constructor(props: LinkedContentPreviewProps) {
        super(props);

        this.loaderManagerStore = new LoaderManagerStore();
        this.loaderManagerStore.setLoader();
    }

    get injected() {
        return this.props as InjectedProps;
    }

    async componentDidMount(): Promise<void> {
        const fieldValue = this.props.linkedField.getSubmittableValue();
        if (fieldValue) {
            this.loaderManagerStore.setLoader();
            await this.setLinkedContents();
            this.loaderManagerStore.unsetLoader();
        }
    }

    showLinkedContents = () => {
        const { onLinkedContentClick } = this.props;
        const { linkedContents } = this.injected.contentListStore;
        switch (linkedContents.items.length) {
            case 0:
                return (<div>no content found</div>);
            case 1:
                return (<ContentCard content={linkedContents.items[0]} onCardClick={onLinkedContentClick} />);
            default:
                return (
                    <div>
                        <FormattedMessage
                            id="LinkedContentPreview.TotalItems"
                            defaultMessage={`{total} items total`}
                            values={{ total: linkedContents.total }}
                        />
                        <Button
                            className={styles.LinkedContentPreviewShowMoreButton}
                            type="primary"
                            icon="search"
                            onClick={this.onOpenLinkedContentModal}
                        />
                    </div>
                );
        }
    }

    async setLinkedContents() {
        let definitionMnemonicIdArray: string[] | undefined;
        let contentDefinitionIdArray: number[] | undefined;
        if (this.props.linkedField.type === SchemaTypeIds.LINKED_CONTENT) {
            const { definitionMnemonicId, contentDefinitionId } = this.props.linkedField.constraints;
            if (definitionMnemonicId) {
                definitionMnemonicIdArray = definitionMnemonicId ? [definitionMnemonicId] : undefined;

            } else {
                contentDefinitionIdArray = contentDefinitionId ? [contentDefinitionId] : undefined;
            }
        } else {
            const { definitionMnemonicIds, contentDefinitionIds } = this.props.linkedField.constraints;
            if (definitionMnemonicIds) {
                definitionMnemonicIdArray = definitionMnemonicIds;
            } else {
                contentDefinitionIdArray = contentDefinitionIds;
            }
        }
        const publicId = this.props.linkedField.getSubmittableValue();
        const skip = this.state.skipMultiplier * DEFAULT_TAKE;
        const take = DEFAULT_TAKE;
        const filters: LinkedContentsFilters = {
            contentDefinitionIds: contentDefinitionIdArray,
            definitionMnemonicIds: definitionMnemonicIdArray,
            publicIds: [publicId!]
        };
        await this.injected.contentListStore.setLinkedContents(filters, skip, take);
        this.loaderManagerStore.unsetLoader();
    }

    onOpenLinkedContentModal = async () => {
        await this.setLinkedContents();
        this.setState({ showLinkedContentModal: true, skipMultiplier: DEFAULT_SKIP });
    }

    onCloseLinkedContentModal = () => {
        this.setState({ showLinkedContentModal: false, skipMultiplier: DEFAULT_SKIP });
    }

    onRemoveLinkedContentValue = () => {
        this.props.linkedField.setFieldValue(undefined);
    }

    onChangePage = async (page: number) => {
        await this.setState({ skipMultiplier: page - 1 });
        this.setLinkedContents();
    }

    render() {
        const { linkedField, openModalCallback, onLinkedContentClick, disableActions } = this.props;
        const fieldValue = linkedField.getSubmittableValue();
        const { linkedContents } = this.injected.contentListStore;
        return (
            <React.Fragment>
                {!!fieldValue
                    ? (
                        <div className={styles.LinkedContentPreviewWrapper}>
                            <div className={styles.LinkedContentPreviewLinkToWrapper}>Link to: {fieldValue}</div>
                            {this.loaderManagerStore.mustShowLoader
                                ? <Spin />
                                : this.showLinkedContents()
                            }
                            <div className={styles.LinkedContentPreviewButtonsWrapper}>
                                <Button
                                    className={styles.LinkedContentPreviewButton}
                                    type="primary"
                                    icon="edit"
                                    disabled={disableActions}
                                    onClick={openModalCallback}
                                />
                                <Button
                                    className={styles.LinkedContentPreviewButton}
                                    type="danger"
                                    icon="delete"
                                    disabled={disableActions}
                                    onClick={this.onRemoveLinkedContentValue}
                                />
                            </div>
                        </div>
                    )
                    : (
                        <Button
                            disabled={disableActions}
                            icon="select"
                            type="primary"
                            size="default"
                            onClick={openModalCallback}
                        >
                            <FormattedMessage
                                id="LinkedContentPreview.Choose"
                                defaultMessage="Choose"
                            />
                        </Button>
                    )}
                <Modal
                    footer={null}
                    centered={true}
                    onCancel={this.onCloseLinkedContentModal}
                    visible={this.state.showLinkedContentModal}
                    wrapClassName={styles.LinkedContentPreviewModalWrapper}
                >
                    <div className={styles.LinkedContentPreviewModalBodyWrapper}>
                        <div
                            className={styles.LinkedContentPreviewModalContentsWrapper}
                        >
                            {linkedContents.items.map(content => <ContentCard
                                key={content.id}
                                content={content}
                                onCardClick={onLinkedContentClick}
                            />)}
                        </div>
                        <div className={styles.LinkedContentPreviewModalPaginationWrapper}>
                            <Pagination
                                current={this.state.skipMultiplier + 1}
                                onChange={this.onChangePage}
                                pageSize={DEFAULT_TAKE}
                                total={linkedContents.total}
                            />
                        </div>
                    </div>
                </Modal>
            </React.Fragment>

        );
    }
}

export default LinkedContentPreview;
