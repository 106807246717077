import React from 'react';
import classes from './index.module.scss';
import Logo from '../Logo/Logo';
import SideContent from './SideContent';
import OnboardingTitle from './OnboardingTitle';

export interface OnboardingContainerProps {
    children: React.ReactNode;
    topContent: React.ReactNode;
    leftContent: React.ReactNode;
}

export default function OnboardingContainer(props: OnboardingContainerProps) {
    const {
        children,
        topContent,
        leftContent,
    } = props;

    return (
        <div className={classes.Onboarding}>
            <div className={classes.OnboardingTopContainer}>
                <Logo textColor={'black'} />
                <OnboardingTitle>
                    {topContent}
                </OnboardingTitle>
            </div>
            <div className={classes.OnboardingBottomContainer}>
                <div className={classes.OnboardingContentLeft}>
                    <SideContent>
                        {leftContent}
                    </SideContent>
                </div>
                <div className={classes.OnboardingContentRight}>
                    {children}
                </div>
            </div>
        </div>
    );
}
