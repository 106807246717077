import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { SchemaTypeIds } from '@contentchef/contentchef-types';
import { DynamicFieldTypes, FieldDate } from '../FormFields';
import { DATE_TIME_FORMAT, TIME_FORMAT, MINUTE_STEP } from '../../../constants/date-constants';
import { fieldMatchersUtils } from './FieldMatchersUtils';
import { FieldMatcher } from './FormFieldMatchersFactory';
import { defaultFieldWrapper } from './DefaultFieldWrapper';

export class DateTypeMatcher implements FieldMatcher {
    maybeCreateComponent(
        id: string, userLocale: string, onChange: (e: any) => void, field: DynamicFieldTypes
    ): JSX.Element | null {
        if (!fieldMatchersUtils.typeMatcher(field, SchemaTypeIds.DATE)) { return null; }
        const fieldDate = field as FieldDate;
        const defaultValue = fieldDate.value ? moment(fieldDate.value) : undefined;
        const placeholder = fieldMatchersUtils.generateFieldPlaceholder(field, userLocale);

        function elementToRender(disabled: boolean) {
            return (
                <DatePicker
                    id={id}
                    disabled={disabled || field.disabled}
                    placeholder={placeholder}
                    showTime={{ format: TIME_FORMAT, minuteStep: MINUTE_STEP }}
                    value={defaultValue}
                    format={DATE_TIME_FORMAT}
                    onChange={onChange}
                    getCalendarContainer={() => document.getElementById('scrollable-content') as HTMLDivElement}
                />
            );
        }

        return defaultFieldWrapper(elementToRender);
    }
}
