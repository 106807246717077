import TranslateIcon from '@assets/custom_icons/components/Translate';
import LabelWithHint from '@components/LabelWithHint/LabelWithHint';
import { FormContext } from '@contexts/FormContext';
import withFieldTranslation from '@hoc/withFieldTranslation';
import { SimpleFieldTypes } from '@services/FormUtils/FormFields';
import { FieldTranslationManager } from '@stores/contentTranslation/FieldTranslationManager';
import { ValidationRule } from 'antd/lib/form';
import FormItem from 'antd/lib/form/FormItem';
import { observer } from 'mobx-react';
import { IReactComponent } from 'mobx-react/dist/types/IReactComponent';
import React, { useContext } from 'react';
import { InjectedIntl } from 'react-intl';
import DynamicField from '../DynamicField/DynamicField';
import ExtensionField from '../ExtensionField';
import LabelWithIcon from '../LabelWithIcon';
import TranslationFieldAddon from './TranslationFieldAddon';

interface Props {
    fieldData: SimpleFieldTypes;
    translationFieldData?: SimpleFieldTypes;
    contentLocale?: string;
    contentTranslationLocale?: string;
    formRules: ValidationRule[];
    formItemId: string;
    fieldTranslationManager?: FieldTranslationManager | null;
    intlLocale: string;
    formatMessage: InjectedIntl['formatMessage'];
}

const TAG_PROPORTION_WIDTH_MEMBER = 15;

function FieldManager(
    {
        fieldData,
        formRules,
        formItemId,
        fieldTranslationManager,
        translationFieldData,
        formatMessage,
        intlLocale,
        contentLocale,
        contentTranslationLocale
    }: Props
) {
    const form = useContext(FormContext);
    const inTranslation = fieldData.inTranslation;
    const inputGroupStyle = inTranslation && (fieldData as any)['translatable'] && contentLocale && contentTranslationLocale ?
        buildTagStyle(contentLocale!, contentTranslationLocale) : {};

    function buildTagStyle(cLocale: string, cTransLocale: string): React.CSSProperties {
        const maxLength = cLocale.length > cTransLocale.length ? cLocale.length : cTransLocale.length;
        const inputGroupWidth = maxLength * TAG_PROPORTION_WIDTH_MEMBER;

        return {
            width: inputGroupWidth,
        };
    }

    const verifyFieldHasClientError = () => {
        const errors = form.getFieldError(formItemId);
        return errors !== undefined && errors.length > 0;
    };

    function renderField(
        fieldDataToUse: SimpleFieldTypes,
        formItemIdToUse: string,
        rules: ValidationRule[],
        locale?: string,
    ) {
        return (
            fieldData.extension ?
                form.getFieldDecorator(formItemIdToUse, {
                    initialValue: fieldDataToUse.value,
                    rules
                })(
                    <ExtensionField
                        locale={locale}
                        fieldData={fieldDataToUse}
                        verifyFieldHasClientError={verifyFieldHasClientError}
                    />
                ) :
                form.getFieldDecorator(formItemIdToUse, {
                    initialValue: fieldDataToUse.value,
                    rules
                })(
                    <DynamicField
                        locale={locale}
                        fieldData={fieldDataToUse}
                        verifyFieldHasClientError={verifyFieldHasClientError}
                        inputGroupStyle={inputGroupStyle}
                    />
                )
        );
    }

    function renderFieldTranslation() {
        if (!fieldTranslationManager || !translationFieldData) {
            console.error(`Missing field translation manager for field ${fieldData.pathString}`);
            return renderWithFormItem(
                formItemId, fieldData,
                renderField(fieldData, formItemId, formRules), false
            );
        }
        return [
            renderWithFormItem(
                formItemId, fieldData,
                renderField(fieldData, formItemId, formRules, contentLocale), false
            ),
            renderWithFormItem(
                `translation_${formItemId}`, translationFieldData,
                renderField(
                    translationFieldData,
                    `translation_${formItemId}`,
                    formRules.filter(rule => !rule.required), contentTranslationLocale
                ),
                true
            ),
        ];
    }

    function renderWithFormItem(
        formItemIdToUse: string,
        fieldDataToUse: SimpleFieldTypes,
        component: React.ReactNode,
        isTranslation: boolean = false,
        formItemIdPrefix: string = 'translation'
    ) {

        const labelWithIcon = !inTranslation || !(fieldDataToUse as any)['translatable'] ? (
            <LabelWithIcon
                label={
                    fieldDataToUse.labels[intlLocale] || fieldDataToUse.labels[fieldData.locale]
                }
            />) : (
                <TranslationFieldAddon
                    pathString={fieldDataToUse.pathString}
                    label={
                        fieldDataToUse.labels[intlLocale] || fieldDataToUse.labels[fieldData.locale]
                    }
                    formItemId={`${formItemIdPrefix}_${formItemIdToUse}`}
                    icon={<TranslateIcon />}
                />
            );

        return (
            <FormItem
                key={formItemIdToUse}
                htmlFor={formItemIdToUse}
                label={
                    !isTranslation ?
                        !!fieldDataToUse.hint
                            ? <LabelWithHint
                                label={labelWithIcon}
                                hint={fieldDataToUse.hint[intlLocale] || fieldDataToUse.hint[fieldData.locale]}
                            />
                            :
                            labelWithIcon
                        : undefined
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                validateStatus={fieldDataToUse.errors.length > 0 ? 'error' : undefined}
                help={
                    fieldDataToUse.errors.length > 0 ?
                        fieldDataToUse.getFieldErrorMessages(
                            formatMessage,
                            intlLocale
                        ) :
                        undefined
                }
            >
                {component}
            </FormItem>
        );
    }

    return inTranslation && (fieldData as any)['translatable'] ?
        renderFieldTranslation() :
        renderWithFormItem(
            formItemId, fieldData,
            renderField(fieldData, formItemId, formRules, contentLocale), false
        );
}

export default withFieldTranslation(observer(FieldManager as IReactComponent));
