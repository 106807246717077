import React, { Component, createRef } from 'react';
import { Icon } from 'antd';
import { ListItemProps, ListItemState } from './ListItemModel';
import styles from './ListItem.module.scss';
import ExpandedListItem from '../ExpandedListItem/ExpandedListItem';

class ListItem extends Component<ListItemProps, ListItemState> {
    state = {
        hover: false
    };

    private listItemRef: React.RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

    onMouseEnter = () => {
        if (this.state.hover === false) {
            this.setState({
                hover: true
            });
        }

    }

    onMouseLeave = () => {
        this.setState({
            hover: false
        });
    }

    render() {
        const { renderPreview, renderDescription, renderOnHover, onClick, active } = this.props;
        const { hover } = this.state;
        return (
            <div
                ref={this.listItemRef}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                onClick={onClick}
                className={`
                    ${styles.ListItemContainer} 
                    ${active ? styles.ListItemActive : ''}
                    ${hover ? styles.ListItemHover : ''}
                `}
            >
                <div
                    className={styles.ListItemPreview}
                >
                    {renderPreview()}
                </div>
                <div
                    className={styles.ListItemDescription}
                >
                    {renderDescription()}
                    {active && (
                        <Icon
                            className={styles.ListItemActiveIcon}
                            type="check-circle"
                        />
                    )
                    }
                </div>
                {hover &&
                    (
                        <ExpandedListItem
                            parent={this.listItemRef}
                            onClick={onClick}
                            renderExpandedItem={renderOnHover}
                            hover={hover}
                        />
                    )
                }
            </div>
        );
    }
}

export default ListItem;
