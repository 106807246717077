import Button from '@components/Button';
import React from 'react';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';

import styles from './AddButton.module.scss';

export type Props = {
    dataId: string;
    icon?: string;
    onClick?: (() => void);
    title: FormattedMessage.MessageDescriptor
};

const AddButton: React.SFC<Props & InjectedIntlProps> = (props) => {
    return (
        <Button
            data-id={props.dataId}
            icon={props.icon ? props.icon : 'plus'}
            type="primary"
            shape="circle"
            className={styles.CreateButton}
            onClick={props.onClick}
            title={props.intl.formatMessage(props.title)}
        >
            {props.children}
        </Button>
    );
};

export default injectIntl(AddButton);
