import {
    CloudinaryPublicIdConstraints,
    CloudinaryPublicIdSchemaType,
    Field,
    MediaType,
    SchemaTypeIds
} from '@contentchef/contentchef-types';
import { ListItemIds } from '../../types';
import { SerializedField } from '../types';

export interface CloudinaryIdConstraints {
    required: boolean;
    type: MediaType;
}

export type SerializedCloudinaryPublicIdSchema = SerializedField<CloudinaryIdConstraints, {}>;

export function retrieveCloudinaryPublicIdEditingType(constraints: CloudinaryPublicIdConstraints): ListItemIds {
    if (constraints.type === 'video') {
        return ListItemIds.VideoReference;
    }
    if (constraints.type === 'raw') {
        return ListItemIds.RawReference;
    }
    if (constraints.type === 'image') {
        return ListItemIds.ImageReference;
    }

    throw new Error('Invalid cloudinary public id type');
}

export function deserialize(serialized: SerializedCloudinaryPublicIdSchema): Field<CloudinaryPublicIdSchemaType> {
    return {
        constraints: {
            required: serialized.constraints.required!,
            type: serialized.constraints.type!,
        },
        labels: serialized.labels,
        placeholder: Object.keys(serialized.placeholder).length > 0 ? serialized.placeholder : undefined,
        hint: Object.keys(serialized.hint).length > 0 ? serialized.hint : undefined,
        name: serialized.name,
        type: SchemaTypeIds.CLOUDINARY_PUBLICID,
        extension: serialized.extension,
        translatable: serialized.translatable,
        compact: serialized.compact
    };
}

// tslint:disable-next-line:max-line-length
export function serialize(deserialized: Field<CloudinaryPublicIdSchemaType>, initialIndex: number): SerializedCloudinaryPublicIdSchema {
    return {
        settings: {},
        constraints: {
            required: deserialized.constraints.required,
            type: deserialized.constraints.type
        },
        labels: deserialized.labels,
        hint: deserialized.hint || {},
        placeholder: deserialized.placeholder || {},
        name: deserialized.name,
        type: deserialized.type,
        editingType: retrieveCloudinaryPublicIdEditingType(deserialized.constraints),
        initialIndex,
        extension: deserialized.extension,
        translatable: deserialized.translatable,
        compact: deserialized.compact
    };
}
