import React, { Component } from 'react';
import { BillingInformation } from '@contentchef/contentchef-types';
import Paper from '../Paper';
import Entry from '../Entry';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { Drawer, notification } from 'antd';
import ReactCountryFlag from 'react-country-flag';
import countries from 'i18n-iso-countries';
import classes from './index.module.scss';
import BillingForm from '../BillingForm';
import { observer, inject } from 'mobx-react';
import { SubscriptionStore, isBillingInformation } from '../../stores/subscriptionStore';
import { RouteComponentProps, withRouter } from 'react-router';
import Loader from '../Loader/Loader';
import Typography from '../Typography';
import Button from '@components/Button';

interface BillingInfoProps extends InjectedIntlProps, RouteComponentProps<{ subscriptionId: string }> {
    allowEdit?: boolean;
}

interface InjectedProps extends BillingInfoProps {
    subscriptionStore: SubscriptionStore;
}

interface BillingInfoState {
    showEditDrawer: boolean;
    isLoadingBilling: boolean;
}

@inject('subscriptionStore')
@observer
class BillingInfo extends Component<BillingInfoProps, BillingInfoState> {
    get injected() {
        return this.props as InjectedProps;
    }
    state = {
        showEditDrawer: false,
        isLoadingBilling: true
    };

    showDrawer = () => {
        this.setState({
            showEditDrawer: true,
        });
    }

    onClose = () => {
        this.setState({
            showEditDrawer: false,
        });
    }

    async componentDidMount() {
        await this.withLoading(this.setBillingInfo);
    }

    setBillingInfo = async () => {
        const { subscriptionId } = this.props.match.params;
        await this.injected.subscriptionStore.setSubscriptionBillingAddress({ subscriptionId });
    }

    withLoading = async (functionToCall: () => Promise<any>) => {
        this.setState({
            isLoadingBilling: true
        });
        await functionToCall();
        this.setState({
            isLoadingBilling: false
        });
    }

    onSubmitBilling = async (values: BillingInformation) => {
        const req = {
            subscriptionId: this.props.match.params.subscriptionId,
            billingInformation: values
        };
        try {
            await this.injected.subscriptionStore.updateSubscriptionBillingAddress(req);
            this.onClose();
        } catch (error) {
            notification.error({
                message: (
                    <FormattedMessage
                        defaultMessage="Error occurred when saving billing information"
                        id="BillingInfo.labels.error"
                    />
                )
            });
        }
    }

    renderBilling = (
        { zip, tax_id, address_line1, state, name, country, city, address_line2 }: BillingInformation
    ) => {
        const locale = this.props.intl.locale;
        const { allowEdit = true } = this.props;
        const countryName = countries.getName(country, locale);
        return (
            <React.Fragment>
                <Entry
                    label={
                        <FormattedMessage
                            id="BillingInfo.labels.name"
                            defaultMessage="name"
                        />
                    }
                    value={name}
                />
                {tax_id && (
                    <Entry
                        label={
                            <FormattedMessage
                                id="BillingInfo.labels.taxId"
                                defaultMessage="tax id"
                            />
                        }
                        value={tax_id.substr(2)}
                    />
                )}
                <Entry
                    label={
                        <FormattedMessage
                            id="BillingInfo.labels.address1"
                            defaultMessage="address 1"
                        />
                    }
                    value={address_line1}
                />
                <Entry
                    label={
                        <FormattedMessage
                            id="BillingInfo.labels.address2"
                            defaultMessage="address 2"
                        />
                    }
                    value={address_line2}
                />
                <Entry
                    label={
                        <FormattedMessage
                            id="BillingInfo.labels.state"
                            defaultMessage="state"
                        />
                    }
                    value={state}
                />
                <Entry
                    label={
                        <FormattedMessage
                            id="BillingInfo.labels.city"
                            defaultMessage="city"
                        />
                    }
                    value={city}
                />
                <Entry
                    label={
                        <FormattedMessage
                            id="BillingInfo.labels.country"
                            defaultMessage="country"
                        />
                    }
                    value={
                        <span>
                            <ReactCountryFlag
                                className={classes.FlagIcon}
                                code={country}
                            />
                            {countryName}
                        </span>
                    }
                />
                <Entry
                    label={
                        <FormattedMessage
                            id="BillingInfo.labels.zip"
                            defaultMessage="zip"
                        />
                    }
                    value={zip}
                />
                {allowEdit && <div className={classes.ButtonContainer}>
                    <Button icon="edit" type="primary" onClick={this.showDrawer}>
                        <FormattedMessage
                            id="BillingInfo.labels.edit"
                            defaultMessage="edit"
                        />
                    </Button>
                </div>}
            </React.Fragment>
        );
    }

    renderEmptyBilling = () => {
        return (
            <div className={classes.EmptyBillingContainer}>
                <Typography>
                    <FormattedMessage
                        id="BillingInfo.labels.notFound"
                        defaultMessage="No billing information found. Click here to fill them."
                    />
                </Typography>
                <div className={classes.ButtonContainer}>
                    <Button
                        icon="plus"
                        type="primary"
                        onClick={this.showDrawer}
                    >
                        <FormattedMessage
                            id="BillingInfo.labels.add"
                            defaultMessage="Add"
                        />
                    </Button>
                </div>
            </div>
        );
    }

    renderLoader = () => {
        return (
            <div className={classes.LoaderContainer}>
                <Loader />
            </div>
        );
    }

    render() {
        const {
            subscriptionBillingInfo
        } = this.injected.subscriptionStore;

        return (
            <Paper
                gutter={true}
            >
                {this.state.isLoadingBilling ?
                    this.renderLoader() :
                    !isBillingInformation(subscriptionBillingInfo) ?
                        this.renderEmptyBilling() :
                        this.renderBilling(subscriptionBillingInfo)
                }
                <Drawer
                    visible={this.state.showEditDrawer}
                    onClose={this.onClose}
                    destroyOnClose={true}
                    className={classes.BillingDrawer}
                    title={
                        (
                            <FormattedMessage
                                id="BillingInfo.labels.drawer.title"
                                defaultMessage="Edit Billing Information"
                            />
                        )
                    }
                >
                    <BillingForm
                        existingBillingInformation={this.injected.subscriptionStore.subscriptionBillingInfo}
                        onSubmit={this.onSubmitBilling}
                    />
                </Drawer>
            </Paper>
        );
    }
}

export default injectIntl(withRouter(BillingInfo));
