import { Modal } from 'antd';
import { Component } from 'react';
import { CloudinaryPublicIdMediaStepper, CloudinaryPublicIdPreview, ModalTitle } from '..';
import { CLOUDINARY_IMAGE } from '../../services/cloudinaryUtils';
import { CloudinaryPublicIdFieldProps, CloudinaryPublicIdFieldState } from './CloudinaryPublicIdFieldModel';

import styles from './CloudinaryPublicIdField.module.scss';

class CloudinaryPublicIdField extends Component<CloudinaryPublicIdFieldProps, CloudinaryPublicIdFieldState> {

    constructor(props: CloudinaryPublicIdFieldProps) {
        super(props);

        this.state = {
            visible: false,
            publicId: this.props.fieldData.value ? this.props.fieldData.value : '',
        };

        this.onConfirmSelection = this.onConfirmSelection.bind(this);
        this.toggleImageEditor = this.toggleImageEditor.bind(this);
        this.onReset = this.onReset.bind(this);

    }

    onConfirmSelection(publicId: string) {
        this.setState({ publicId });
        this.props.onChange(publicId);
        this.toggleImageEditor();
    }

    toggleImageEditor() {
        const { visible } = this.state;
        if (!visible) {
            this.setState({
                publicId: this.props.fieldData.value ? this.props.fieldData.value : '',
                visible: !visible
            });
        } else {
            this.setState({
                visible: !visible
            });
        }
    }

    onReset() {
        this.setState({ publicId: '' });
        this.props.onChange(undefined);
    }

    render() {
        const { publicId } = this.state;
        const { id, disableActions, fieldData } = this.props;
        return (
            <div id={id}>
                <CloudinaryPublicIdPreview
                    type={fieldData.constraints.type}
                    disableActions={disableActions}
                    value={publicId}
                    toggleImageEditor={this.toggleImageEditor}
                    onReset={this.onReset}
                />
                <Modal
                    title={
                        <ModalTitle onCancel={this.toggleImageEditor}>
                            {fieldData.id}
                        </ModalTitle>}
                    closable={false}
                    visible={this.state.visible}
                    wrapClassName={styles.CloudinaryPublicIdImageModalPreview}
                    destroyOnClose={true}
                    footer={false}
                >
                    <CloudinaryPublicIdMediaStepper
                        type={fieldData.constraints.type || CLOUDINARY_IMAGE}
                        field={fieldData}
                        onConfirmSelection={this.onConfirmSelection}
                    />
                </Modal>
            </div>
        );
    }
}

export default CloudinaryPublicIdField;
