import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import {
    ContentDefinitionList,
    ContainerWithAside,
    DefinitionListFilters
} from '../../../components';
import { SelectDefinitionProps, SelectDefinitionState, InjectedProps } from './SelectDefinitionModel';
import WithLoadingData from '../../../hoc/WithLoadingData/WithLoadingData';
import { withContentDefinitionsStores } from '../../../hoc';
import { DataAttributes, DataAttributesBuilder } from '../../../services/DataAttributesBuilder';
import { ContentDefinitionsFooter } from '../../../components';
import {
    ContentDefinitionListUrlParams
} from '../../../stores/contentDefinitionListStore/contentDefinitionListStoreModel';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../../constants/tables';
import { injectIntl, FormattedMessage } from 'react-intl';
import Typography from '../../../components/Typography';

@inject('contentDefinitionListStore')
@observer
class SelectDefinition extends Component<SelectDefinitionProps, SelectDefinitionState> {
    get injected() {
        return this.props as InjectedProps;
    }

    getContentDefinitionsForRepo = () => {
        return this.injected.contentDefinitionListStore.initContentDefinitionList();
    }

    createSearchDataAttributes = (): DataAttributes => {
        return new DataAttributesBuilder('repository')
            .setSearchAttribute('search-contentDefinition')
            .build();
    }

    createDefinitionViewAttributes = (): DataAttributes => {
        return new DataAttributesBuilder('repository')
            .setListAttribute('content-definition-list-select')
            .build();
    }

    render() {
        const { dataAttributes } = this.props;
        const { usableSearchParams = {}, urlSearchParams } = this.injected.contentDefinitionListStore.searchParams;
        return (
            <ContainerWithAside
                drawerIcon={'filter'}
                dataAttributes={dataAttributes}
                footer={<ContentDefinitionsFooter
                    currentSize={urlSearchParams.has(ContentDefinitionListUrlParams.bySize)
                        ? parseInt(usableSearchParams[ContentDefinitionListUrlParams.bySize], undefined)
                        : DEFAULT_PAGE_SIZE
                    }
                    currentPage={urlSearchParams.has(ContentDefinitionListUrlParams.byPage)
                        ? parseInt(usableSearchParams[ContentDefinitionListUrlParams.byPage], undefined)
                        : DEFAULT_PAGE
                    }
                />}
                renderAside={() => <DefinitionListFilters />}
            >
                <Typography
                    align={'left'}
                    gutter={false}
                    variant={'h6'}
                >
                    <FormattedMessage
                        id="contentCreation.selectDefinition"
                        defaultMessage="Choose a content definition"
                    />
                </Typography>
                <WithLoadingData
                    promise={this.getContentDefinitionsForRepo}
                    broadcastLoading={true}
                    rerenderWhenRouteChange={true}
                >
                    <ContentDefinitionList
                        isContentCreationFlow={true}
                        dataAttribute={this.createDefinitionViewAttributes}
                    />
                </WithLoadingData>
            </ContainerWithAside>
        );
    }
}

export default withContentDefinitionsStores(injectIntl(SelectDefinition));
