import React, { Component } from 'react';
import { Row, Col, Input, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { observer, inject } from 'mobx-react';

import { CloudinaryModalPreviewProps, InjectedProps } from './CloudinaryModalPreviewModel';

import styles from './CloudinaryModalPreview.module.scss';
import { previewComponent } from '../../services/Media/CloudinaryTypePreview';

@inject('mediaGalleryFlowStore')
@observer
class CloudinaryModalPreview extends Component<CloudinaryModalPreviewProps> {
    get injected() {
        return this.props as InjectedProps;
    }
    render() {
        const {
            publicId,
            transformations,
            onChangeTransformations,
            onConfirmSelection,
            hasTransformationError,
            type
        } = this.props;
        const userFriendlyPublicId = publicId.split('/').pop();
        return (
            <Row
                type="flex"
                align="middle"
                className={styles.CloudinaryModalPreviewContainer}
            >
                <Col xs={24} lg={7} className={styles.CloudinaryModalPreviewColumns}>
                    <Col xs={24} className={styles.CloudinaryModalPreviewInputContainer}>
                        <p>
                            <FormattedMessage
                                id="CloudinaryModalPreview.publicIdLabel"
                                defaultMessage="Cloudinary publicId: "
                            />
                        </p>
                        <Input
                            placeholder="Cloudinary publicId"
                            value={userFriendlyPublicId}
                            disabled={true}
                        />
                    </Col>
                    <Col xs={24} className={styles.CloudinaryModalPreviewInputContainer}>
                        <p>
                            <FormattedMessage
                                id="CloudinaryModalPreview.transformationsLabel"
                                defaultMessage="Cloudinary transformations: "
                            />
                        </p>
                        <Input
                            placeholder="Cloudinary transformations"
                            value={transformations}
                            onChange={onChangeTransformations}
                            className={
                                hasTransformationError ?
                                    styles.CloudinaryModalPreviewInputError
                                    : ''}
                        />
                        {hasTransformationError &&
                            <p className={styles.CloudinaryModalPreviewErrorLabel}>
                                <FormattedMessage
                                    id="CloudinaryModalPreview.transformationError"
                                    defaultMessage="This is not a valid JSON string"
                                />
                            </p>
                        }
                    </Col>
                </Col>
                <Col xs={24} lg={17} className={styles.CloudinaryModalPreviewColumns}>
                    {previewComponent(type, publicId, transformations)}
                </Col>
                <Col xs={24} className={styles.CloudinaryModalPreviewCTAContainer}>
                    <Button
                        type="primary"
                        size="large"
                        disabled={hasTransformationError}
                        onClick={onConfirmSelection}
                        className={styles.CloudinaryModalPreviewCTAButton}
                    >
                        <FormattedMessage
                            id="CloudinaryModalPreview.Confirm"
                            defaultMessage="Confirm"
                        />
                    </Button>
                </Col>
            </Row>
        );
    }
}

export default CloudinaryModalPreview;
