import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { MediaGallery } from '@contentchef/contentchef-types';
import { MediaGalleryList } from '..';
import { MediaGalleryListUploadProps, InjectedProps } from './MediaGalleryListUploadModel';
import ContainerWithAside from '@components/ContainerWithAside/ContainerWithAside';
import { Pagination } from 'antd';
import MediaGalleryListFilters from '@components/MediaGalleryListFilters';

@inject('mediaGalleryFlowStore')
@observer
class MediaGalleryListUpload extends Component<MediaGalleryListUploadProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    async componentDidMount() {
        await this.injected.mediaGalleryFlowStore.initializeMediaGalleries();
    }

    overrideClick = (mediaGallery: MediaGallery) => {
        this.injected.mediaGalleryFlowStore.setMediaGallery(mediaGallery);
        this.props.nextStep();
    }

    render() {
        const { mediaGalleryFlowStore } = this.injected;
        return (
            <ContainerWithAside
                drawerIcon={'filter'}
                footer={<Pagination
                    showSizeChanger={false}
                    pageSize={mediaGalleryFlowStore.paginationMediaGallery.take}
                    current={mediaGalleryFlowStore.paginationMediaGallery.current}
                    total={mediaGalleryFlowStore.paginationMediaGallery.total}
                    onChange={mediaGalleryFlowStore.setPaginationParams}
                />}
                renderAside={() => <MediaGalleryListFilters
                    filterByNameValue={mediaGalleryFlowStore.listMediaGalleryParams.filters.name}
                    filterByArchivedValue={mediaGalleryFlowStore.listMediaGalleryParams.filters.archived}
                    onSearchByName={mediaGalleryFlowStore.setListMediaGalleryNameFilter}
                    onSearchByArchived={mediaGalleryFlowStore.setListMediaGalleryArchivedFilter}
                    onResetAllFilters={mediaGalleryFlowStore.canResetAllFilters
                        ? mediaGalleryFlowStore.resetAllListMediaGalleryFilters
                        : undefined
                    }
                />}
            >
                <MediaGalleryList
                    isLoading={mediaGalleryFlowStore.isLoadingMediaGalleries}
                    listMediaGalleries={mediaGalleryFlowStore.mediaGalleries}
                    overrideClick={this.overrideClick}
                />
            </ContainerWithAside>
        );
    }
}

export default MediaGalleryListUpload;
