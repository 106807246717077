import Button from '@components/Button';
import { Icon } from 'antd';
import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import classes from './index.module.scss';

export interface FullPageModalProps {
    animated?: boolean;
    children?: React.ReactNode;
    footerActions?: React.ReactNode;
    open?: boolean;
    title?: React.ReactNode;
    onAfterClose?(): any;
    onBeforeClose?(): any;
    onClose?(): any;
}

const noop = () => void 0;

function createCloseHandler(props: FullPageModalProps): () => any {
    const {
        onAfterClose,
        onBeforeClose,
        onClose,
    } = props;

    return () => {
        onBeforeClose!();
        onClose!();
        onAfterClose!();
    };
}

function FullPageModal(props: FullPageModalProps) {
    const {
        animated,
        children,
        footerActions,
        open,
        title,
    } = props;
    const buttonClassName = classNames(classes.FullPageModalContentButton);
    const contentClassName = classNames(classes.FullPageModalContent);
    const dialogClassName = classNames(classes.FullPageModal, {
        [classes.FullPageModalAnimated]: animated,
        [classes.FullPageModalVisible]: open,
    });
    const footerClassName = classNames(classes.FullPageModalFooter, {
        [classes.FullPageModalFooterEmpty]: !footerActions,
    });
    const titleClassName = classNames(classes.FullPageModalTitle);

    return (
        <div className={dialogClassName}>
            <div className={titleClassName}>
                <div>
                    {
                        title
                    }
                </div>
                <Button
                    className={buttonClassName}
                    onClick={createCloseHandler(props)}
                    type="danger"
                >
                    <Icon type="close-circle" />
                </Button>
            </div>
            <div className={contentClassName}>
                {
                    children
                }
            </div>
            <div className={footerClassName}>
                {
                    footerActions
                }
            </div>
        </div>
    );
}

FullPageModal.defaultProps = {
    animated: true,
    children: null,
    open: false,
    title: null,
    onAfterClose: noop,
    onBeforeClose: noop,
    onClose: noop,
} as Partial<FullPageModalProps>;

export default function FullPageModalWithPortal(props: FullPageModalProps) {
    return ReactDOM.createPortal(<FullPageModal {...props} />, document.body);
};
