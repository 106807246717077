import { SchemaTypeIds } from '@contentchef/contentchef-types';
import Typography from '../../../Typography';
import React from 'react';
import BooleanAttribute from '../Fields/Boolean';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import Segment from '../../../Segment/Segment';
import
withDefinitionErrors,
{ WithDefinitionErrorsReturnProps }
    from '../../../../hoc/withDefinitionErrors/withDefinitionErrors';
import { Form } from 'antd';

interface IndexingProps extends InjectedIntlProps, WithDefinitionErrorsReturnProps {
    type: SchemaTypeIds;
    value?: boolean;
    onChange(value: boolean): any;
}

const IndexingLabels = defineMessages({
    indexingAttrName: {
        id: 'components.ContentDefinitionSchemaEditor.Indexing.Label',
        defaultMessage: 'Indexing'
    },
    indexingSelectedHelper: {
        id: 'components.ContentDefinitionSchemaEditor.Indexing.Helper.True',
        defaultMessage: 'Make this field searchable'
    },
    indexingUnselectedHelper: {
        id: 'components.ContentDefinitionSchemaEditor.Indexing.Helper.False',
        defaultMessage: 'Don\'t make this field searchable'
    },
    indexingNotAvailable: {
        id: 'components.ContentDefinitionSchemaEditor.Indexing.Helper.NotAvailable',
        defaultMessage: 'This field type cannot be indexed.'
    },
});

export function hasFacetAttribute(type: SchemaTypeIds) {
    switch (type) {
        case SchemaTypeIds.TEXT:
        case SchemaTypeIds.LONG_TEXT:
        case SchemaTypeIds.LINKED_CONTENT:
        case SchemaTypeIds.NUMBER:
        case SchemaTypeIds.BOOLEAN:
        case SchemaTypeIds.ONE_LINKED_CONTENT_OF:
            return true;
        default:
            return false;
    }
}

function IndexingAttribute(props: IndexingProps) {
    return (hasFacetAttribute(props.type)
        ? (
            <BooleanAttribute
                attributeName={props.intl.formatMessage(IndexingLabels.indexingAttrName)}
                helperTrue={props.intl.formatMessage(IndexingLabels.indexingSelectedHelper)}
                helperFalse={props.intl.formatMessage(IndexingLabels.indexingUnselectedHelper)}
                onChange={props.onChange}
                value={props.value}
                hasErrors={props.hasErrors}
                errors={props.errors.map(error => props.intl.formatMessage(error))}
            />
        ) : (
            <Segment
                divider={true}
                titleVariant="label"
                title={props.intl.formatMessage(IndexingLabels.indexingAttrName)}
            >
                <Form.Item
                    help={props.hasErrors
                        ? props.errors.map(error => props.intl.formatMessage(error)).join(', ')
                        : undefined
                    }
                    validateStatus={props.hasErrors ? 'error' : undefined}
                    style={{ width: '100%' }}
                >
                    <Typography variant="small">
                        {props.intl.formatMessage(IndexingLabels.indexingNotAvailable)}
                    </Typography>
                </Form.Item>
            </Segment>
        )
    );
}

export default withDefinitionErrors(injectIntl(IndexingAttribute));
