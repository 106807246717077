import React from 'react';
import {
    Switch,
    withRouter,
    Route
} from 'react-router-dom';
import AccessTokensRouter from '../../scenes/AccessTokens';
import SubscriptionSettingsRouter from '../../scenes/Subscriptions/Settings';
import MenubarLayout from '../../hoc/MenubarLayout/MenubarLayout';
import SettingsInvitesRouter from '../../scenes/SettingsInvites';
import {
    ACCESS_TOKEN_ROUTE,
    SUBSCRIPTION_MANAGEMENT_ROUTE,
    PROFILE_ROUTE,
    INVITES_ROUTE,
} from '../../constants/routing-constants';
import ProfileRouter from '../../scenes/Profile/ProfileRouter';

function SettingsBasedRoutesComponent() {
    return (
        <MenubarLayout>
            <Switch>
                <Route path={ACCESS_TOKEN_ROUTE} component={AccessTokensRouter} />
                <Route path={SUBSCRIPTION_MANAGEMENT_ROUTE} component={SubscriptionSettingsRouter} />
                <Route path={PROFILE_ROUTE} component={ProfileRouter} />
                <Route path={INVITES_ROUTE} component={SettingsInvitesRouter} />
            </Switch>
        </MenubarLayout>
    );
}

export const SettingsBasedRoutes = withRouter(SettingsBasedRoutesComponent);

export default SettingsBasedRoutes;
