import { action, computed, IObservableArray, observable } from 'mobx';
import { NavigationItemModel, NAVIGATION_PRIORITY, NavStoreModel } from './navStoreModel';

export class NavigationItem implements NavigationItemModel {
    constructor(
        public id: string,
        public path: string | undefined,
        public displayValue: string,
        public priority: NAVIGATION_PRIORITY
    ) {
    }
}

class NavStore implements NavStoreModel {
    @observable navigationItems: IObservableArray<NavigationItem> = observable.array([]);

    @action
    setNavigationPath(navigationItem: NavigationItem) {
        this.navigationItems.replace([navigationItem]);
    }

    @action
    replaceNavigationPaths(navigationItems: NavigationItem[]) {
        this.navigationItems.replace(navigationItems);
        this.sortNavigationItemsByPriority();
    }

    @action
    pushToPath(navigationItem: NavigationItem) {
        this.navigationItems.push(navigationItem);
        this.sortNavigationItemsByPriority();
    }

    @action
    pushMultipleToPath(navigationItems: NavigationItem[]) {
        this.navigationItems.replace(this.navigationItems.concat(navigationItems));
        this.sortNavigationItemsByPriority();
    }

    @action
    removeItem(id: string) {
        this.navigationItems.replace(this.navigationItems.filter(item => item.id !== id));
    }

    @computed
    get navigationItemsByPriority() {
        return this.navigationItems;
    }

    @action
    sortNavigationItemsByPriority() {
        this.navigationItems.replace(this.navigationItems.slice().sort((firstNavigationItem, secondNavigationItem) => {
            if (firstNavigationItem.priority < secondNavigationItem.priority) {
                return -1;
            }

            if (firstNavigationItem.priority > secondNavigationItem.priority) {
                return 1;
            }

            return 0;
        }));
    }

}

const navStore = new NavStore();

export default navStore;
