export enum SubscriptionErrorTypeIds {
    InvalidPromoCode = 'InvalidPromoCode',
    UnknownError = 'UnknownError'
}

export interface SubscriptionErrorModel {
    message: string;
    type: SubscriptionErrorTypeIds;
}

export class SubscriptionError implements SubscriptionErrorModel {
    message: string;
    type: SubscriptionErrorTypeIds;

    constructor(message: string, type: string) {
        this.message = message;
        this.type = type as SubscriptionErrorTypeIds;
    }
}

export class SubscriptionErrorFactory {
    static crateSubscriptionError(error: SubscriptionErrorModel) {
        switch (error.type) {
            case SubscriptionErrorTypeIds.InvalidPromoCode:
                return new SubscriptionError(error.message, error.type);
            default:
                return new SubscriptionError(error.message, SubscriptionErrorTypeIds.UnknownError);
        }
    }
}
