import React from 'react';
import { Collapse } from 'antd';
import { AsidePanelItemProps } from './AsidePanelItemModel';

import styles from './AsidePanelItem.module.scss';

const { Panel } = Collapse;

const AsidePanelItem = (props: AsidePanelItemProps) => {
    const { header, children, key, showArrow = true, ...others } = props;
    return (
        <Panel
            className={styles.AsidePanelItemDefault}
            key={key}
            header={header}
            showArrow={showArrow}
            {...others}
        >
            {children}
        </Panel>
    );
};

export default AsidePanelItem;
