import React from 'react';
import { MediaType } from '@contentchef/contentchef-types';
import {
    CLOUDINARY,
    CLOUDINARY_VIDEO,
    CLOUDINARY_IMAGE,
    getValidCloudinaryTransformations,
    CLOUDINARY_RAW
} from '../cloudinaryUtils';
import { Image } from 'cloudinary-react';
import { Icon } from 'antd';

export const DEFAULT_VIDEO_TRANSFORMATIONS = {
    format: 'png',
    resource_type: CLOUDINARY_VIDEO
};

export function previewComponent(type: MediaType, publicId: string, transformations?: string) {
    switch (type) {
        case CLOUDINARY_RAW:
            return (
                <Icon
                    style={{ fontSize: 80 }}
                    type="file"
                    twoToneColor="#00AEEF"
                    theme="twoTone"
                />
            );
        case CLOUDINARY_VIDEO:

            const videoTransformations = {
                ...(transformations ? getValidCloudinaryTransformations(transformations) : {}),
                ...DEFAULT_VIDEO_TRANSFORMATIONS
            };
            return (
                <Image
                    cloudName={CLOUDINARY.cloudName}
                    publicId={publicId}
                    {...(
                        videoTransformations
                    )}
                />
            );
        case CLOUDINARY_IMAGE:
            return (
                <Image
                    cloudName={CLOUDINARY.cloudName}
                    publicId={publicId}
                    {...(
                        transformations
                        && getValidCloudinaryTransformations(transformations)
                    )}
                />
            );
        default:
            return (
                <Image
                    cloudName={CLOUDINARY.cloudName}
                    publicId={publicId}
                    {...(
                        transformations
                        && getValidCloudinaryTransformations(transformations)
                    )}
                />
            );
    }
}
