import React from 'react';

import LogoProps from './LogoModel';
import styles from './Logo.module.scss';
import classNames from 'classnames';

const Logo: React.SFC<LogoProps> = ({ isCollapsed, textColor = 'white' }: LogoProps) => {
    const imageClass = classNames('', {
        [styles.WhiteTextLogo]: textColor === 'white',
        [styles.BlackTextLogo]: textColor === 'black',
        [styles.ForceCollapsed]: isCollapsed
    });

    return (
        <div className={styles.logoContainer}>
            <img className={imageClass} alt={'ContentChef'} />
        </div>
    );
};

export default Logo;
