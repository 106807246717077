import React from 'react';
import { UserPermissionIds } from '@contentchef/contentchef-types';
import { MenuLink } from '@components/index';
import { ManageFieldProvider } from '@providers/UserPermissionsProvider';
import { CustomIconComponentProps } from 'antd/lib/icon';

type SidebarItemProps = {
    permissions: UserPermissionIds[];
    icon?: string;
    className: string;
    title: string;
    to: string;
    iconComponent?: React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>>;
};

function SidebarItem({ permissions, icon, iconComponent, className, title, to, ...others }: SidebarItemProps) {
    return (
        <ManageFieldProvider
            permissions={permissions}
            {...others}
        >
            <MenuLink
                className={className}
                icon={icon}
                iconComponent={iconComponent}
                title={title}
                to={to}
            />
        </ManageFieldProvider>
    );
}

SidebarItem.isMenuItem = true;

export default SidebarItem;
