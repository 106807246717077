import { SchemaFieldViolationCodes } from '@contentchef/contentchef-types';

export const nameMappedErrors = [
    { key: 'name', errorCode: 'requiredAttributeMissing' },
    { key: 'name', errorCode: 'invalidCharactersInString' }
];

export const labelsMappedErrors = [
    { key: 'labels', errorCode: 'requiredAttributeMissing' },
    { key: 'labels', errorCode: 'missingDefaultLabel' },
];

export const indexingMappedErrors = [
    { key: 'enableFacet', errorCode: 'enableFacetNotPermitted' },
];

export const translatableMappedErrors = [
    { key: 'translatable', errorCode: 'translatableNotAllowed' },
];

export const compactMappedErrors = [
    { key: 'compact', errorCode: SchemaFieldViolationCodes.compactCannotBeNested },
    { key: 'compact', errorCode: SchemaFieldViolationCodes.invalidBooleanValue },
];

export const placeholderMappedErrors = [
    { key: 'placeholder', errorCode: 'requiredAttributeMissing' },
    { key: 'placeholder', errorCode: 'missingDefaultLabel' },
];

export const hintMappedErrors = [
    { key: 'hint', errorCode: 'requiredAttributeMissing' },
    { key: 'hint', errorCode: 'missingDefaultLabel' },
];

export const extensionMappedErrors = [
    { key: 'extension', errorCode: SchemaFieldViolationCodes.extensionNotFound },
    { key: 'extension', errorCode: SchemaFieldViolationCodes.extensionNotSupportedForType },
    { key: 'extension', errorCode: SchemaFieldViolationCodes.invalidStringValue }
];

export const requiredMappedErrors = [
    { key: 'constraints.required', errorCode: 'requiredAttributeMissing' },
    { key: 'constraints.required', errorCode: 'invalidBooleanValue' },
];

export const arrayItemIndexingMappedErrors = [
    { key: 'constraints.items.enableFacet', errorCode: 'enableFacetNotPermitted' },
];

export const arrayItemTranslatableMappedErrors = [
    { key: 'constraints.items.translatable', errorCode: 'translatableNotAllowed' },
];

export const minMaxLengthMappedErrors = [
    { key: 'constraints', errorCode: 'badMinMaxLengths' },
    { key: 'constraints.minLength', errorCode: 'invalidNumberValue' },
    { key: 'constraints.maxLength', errorCode: 'invalidNumberValue' },
];

export const arrayItemMinMaxLengthMappedErrors = [
    { key: 'constraints.items.constraints', errorCode: 'badMinMaxLengths' },
    { key: 'constraints.items.constraints.minLength', errorCode: 'invalidNumberValue' },
    { key: 'constraints.items.constraints.maxLength', errorCode: 'invalidNumberValue' },
];

export const minMaxMappedErrors = [
    { key: 'constraints', errorCode: 'badMinMax' },
    { key: 'constraints.min', errorCode: 'invalidNumberValue' },
    { key: 'constraints.max', errorCode: 'invalidNumberValue' },
];

export const arrayItemMinMaxMappedErrors = [
    { key: 'constraints.items.constraints', errorCode: 'badMinMax' },
    { key: 'constraints.items.constraints.min', errorCode: 'invalidNumberValue' },
    { key: 'constraints.items.constraints.max', errorCode: 'invalidNumberValue' },
];

export const allowEmptyMappedErrors = [
    { key: 'constraints.allowEmpty', errorCode: 'invalidBooleanValue' },
    { key: 'constraints.items.constraints.allowEmpty', errorCode: 'invalidBooleanValue' }
];

export const dateRangeMappedErrors = [
    { key: 'constraints', errorCode: 'badBeforeAfter' },
    { key: 'constraints.before', errorCode: 'invalidDateValue' },
    { key: 'constraints.after', errorCode: 'invalidDateValue' },
    { key: 'constraints.items.constraints', errorCode: 'badBeforeAfter' },
    { key: 'constraints.items.constraints.before', errorCode: 'invalidDateValue' },
    { key: 'constraints.items.constraints.after', errorCode: 'invalidDateValue' },
];

export const patternMappedErrors = [
    { key: 'constraints.pattern', errorCode: 'invalidRegExpValue' },
    { key: 'constraints.pattern', errorCode: 'invalidStringValue' },
];

export const suffixMappedError = [
    { key: 'constraints.suffix', errorCode: 'patternNotMatched' },
];

export const prefixMappedError = [
    { key: 'constraints.prefix', errorCode: 'patternNotMatched' },
];

export const allowSlashMappedErrors = [
    { key: 'constraints.allowSlash', errorCode: 'invalidBooleanValue' },
];

export const definitionMnemonicIdMappedErrors = [
    { key: 'constraints.definitionMnemonicId', errorCode: 'invalidStringValue' },
    { key: 'constraints.items.constraints.definitionMnemonicId', errorCode: 'invalidStringValue' },
    { key: 'constraints.definitionMnemonicIds', errorCode: 'missingDefinitionByMnemonicId' },
    { key: 'constraints.items.constraints.definitionMnemonicIds', errorCode: 'missingDefinitionByMnemonicId' },
];

export const definitionMnemonicIdsMappedErrors = [
    { key: 'constraints.definitionMnemonicIds', errorCode: 'invalidEmptyArray' },
    { key: 'constraints.items.constraints.definitionMnemonicIds', errorCode: 'invalidEmptyArray' },
    { key: 'constraints.definitionMnemonicIds', errorCode: 'invalidStringValueInList' },
    { key: 'constraints.items.constraints.definitionMnemonicIds', errorCode: 'invalidStringValueInList' },
    { key: 'constraints.definitionMnemonicIds', errorCode: 'missingDefinitionByMnemonicIds' },
    { key: 'constraints.items.constraints.definitionMnemonicIds', errorCode: 'missingDefinitionByMnemonicIds' }
];

export const lazyMappedErrors = [
    { key: 'constraints.lazy', errorCode: 'requiredAttributeMissing' },
    { key: 'constraints.items.constraints.lazy', errorCode: 'requiredAttributeMissing' }
];

export const linkMappedErrors = [
    { key: 'constraints.linkType', errorCode: SchemaFieldViolationCodes.invalidEnumValue },
    { key: 'constraints.linkType', errorCode: SchemaFieldViolationCodes.invalidNumberValue },
];

export const listOfValuesMappedErrors = [
    { key: 'constraints.listOfValues', errorCode: 'invalidEmptyArray' },
    { key: 'constraints.items.constraints.listOfValues', errorCode: 'invalidEmptyArray' },
];

export const fragmentOfMappedErrors = [
    { key: 'constraints.names', errorCode: 'requiredAttributeMissing' },
    { key: 'constraints.items.constraints.names', errorCode: 'requiredAttributeMissing' },
    { key: 'constraints.names', errorCode: 'invalidEmptyArray' },
    { key: 'constraints.items.constraints.names', errorCode: 'invalidEmptyArray' },
    { key: 'constraints.names', errorCode: 'invalidStringValueInList' },
    { key: 'constraints.items.constraints.names', errorCode: 'invalidStringValueInList' },
    { key: 'constraints.names', errorCode: 'fragmentNameNotFound' },
    { key: 'constraints.items.constraints.names', errorCode: 'fragmentNameNotFound' }
];

export const getMinMaxLengthErrorList = (isArrayItem: boolean) => {
    if (isArrayItem) {
        return arrayItemMinMaxLengthMappedErrors;
    } else {
        return minMaxLengthMappedErrors.concat(arrayItemMinMaxLengthMappedErrors);
    }
};

export const getMinMaxErrorList = (isArrayItem: boolean) => {
    if (isArrayItem) {
        return arrayItemMinMaxMappedErrors;
    } else {
        return minMaxMappedErrors.concat(arrayItemMinMaxMappedErrors);
    }
};
