import { MediaType } from '@contentchef/contentchef-types';

export const CLOUDINARY = {
    cloudName: process.env.CloudName
};

export const CLOUDINARY_IMAGE = 'image';
export const CLOUDINARY_VIDEO = 'video';
export const CLOUDINARY_RAW = 'raw';

export function getValidCloudinaryTransformations(transformations: string) {
    let parsedTransformation;

    try {
        parsedTransformation = JSON.parse(transformations);
    } catch (e) {
        parsedTransformation = {};
    }

    if (Object.keys(parsedTransformation).length > 0) {
        for (const key in parsedTransformation) {
            if (parsedTransformation.hasOwnProperty(key)) {
                if (key.length === 0) {
                    return {};
                }
            }
        }
    }

    return parsedTransformation;
}

export function isImageType(file: File | null) {
    if (!file) {
        return false;
    }
    return !!file.type.match('image/*');
}

export function isValidType(file: File | null) {
    if (!file) {
        return false;
    }
    return !!file.type.match('^.*(image|video|audio|application/pdf).*$');
}

export function getFileNameAndExtension(file: File) {
    const fileName = file.name;
    const splittedFileName = fileName.split('.');
    const ext = splittedFileName.pop();
    const name = splittedFileName.join('.');
    return {
        name: name,
        ext: ext || ''
    };
}

export function getFileType(fileType: string): MediaType {
    if (fileType.match('image/*')) {
        return CLOUDINARY_IMAGE;
    }
    if (fileType.match('video/*')) {
        return CLOUDINARY_VIDEO;
    }

    if (fileType.match('application/pdf') || fileType.match('audio/*')) {
        return CLOUDINARY_RAW;
    }

    return CLOUDINARY_RAW;
}
