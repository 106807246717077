import { FieldEditTabProps } from '../../types';
import React from 'react';
import { observer } from 'mobx-react';
import { LinkedContentConstraints } from '../../FieldSerializer/fields/linked-content';
import DefinitionMnemonicIds from '../Constraints/DefinitionMnemonicIds';
import { FormattedMessage } from 'react-intl';
import {
    definitionMnemonicIdMappedErrors,
    linkMappedErrors
} from '../../../../services/FormUtils/DefinitionFieldMappedErrors';
import LinkType from '../Constraints/LinkType';

function LegacyLinkedContentsDetails(props: FieldEditTabProps<LinkedContentConstraints>) {
    return (
        <>
            <LinkType
                relatedTabKey={'detailsTab'}
                mappedErrors={linkMappedErrors}
                onChange={linkType => props.onChangeConstraint('linkType', linkType)}
                value={props.constraints.linkType!}
            />
            <DefinitionMnemonicIds
                relatedTabKey={'detailsTab'}
                mappedErrors={definitionMnemonicIdMappedErrors}
                selectionType="single"
                value={props.constraints.definitionMnemonicId === undefined
                    ? []
                    : [props.constraints.definitionMnemonicId]
                }
                label={<FormattedMessage
                    id="LinkedContentsDetails.DefinitionMnemonicId.Label"
                    defaultMessage="Referable content definitions"
                />}
            />
        </>
    );
}

export default observer(LegacyLinkedContentsDetails);
