import { FieldCloudinaryPublicId, FieldCloudinaryRaw } from '../../services/FormUtils/FormFields';
import { MediaGalleryFlowStoreModel } from '../../stores/mediaGalleryFlowStore/mediaGalleryFlowStoreModel';
import { MediaType } from '@contentchef/contentchef-types';

export enum MediaStep {
    MediaGallery = 0,
    Folder,
    Upload,
    MediaPreview
}

export interface CloudinaryPublicIdMediaStepperProps {
    type: MediaType;
    field: FieldCloudinaryPublicId | FieldCloudinaryRaw;
    onConfirmSelection(publicId: string): void;
}

export interface InjectedProps extends CloudinaryPublicIdMediaStepperProps {
    mediaGalleryFlowStore: MediaGalleryFlowStoreModel;
}

export interface CloudinaryPublicIdMediaStepperState {
    activeStep: MediaStep;
    temporaryPublicId: string;
}
