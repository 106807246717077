import {
    ArraySchemaType,
    Field,
    SchemaTypeIds
} from '@contentchef/contentchef-types';
import { Moment } from 'moment';
import {
    FieldArray,
    FieldFragment,
    FieldModelType,
    FieldOneFragmentOf,
    SimpleField,
    SimpleSchemaTypes
} from './FormFields';

export const defaultFormatDate = (date: Moment | undefined) => {
    if (date) {
        date.seconds(0);
        date.milliseconds(0);
        return date.toISOString();
    }
    return date;
};

export const isSimpleField = (arg: FieldModelType | null): arg is SimpleField<Field<SimpleSchemaTypes>, any> => {
    return arg != null && arg.type !== SchemaTypeIds.ARRAY && arg.type !== SchemaTypeIds.FRAGMENT &&
        arg.type !== SchemaTypeIds.ONE_FRAGMENT_OF;
};

export const isArrayField = (arg: FieldModelType | null): arg is FieldArray<Field<ArraySchemaType>> => {
    return arg != null && arg.type === SchemaTypeIds.ARRAY;
};

export const isFragmentField = (arg: FieldModelType | null): arg is FieldFragment => {
    return arg != null && arg.type === SchemaTypeIds.FRAGMENT;
};

export const isFragmentOfField = (arg: FieldModelType | null): arg is FieldOneFragmentOf => {
    return arg != null && arg.type === SchemaTypeIds.ONE_FRAGMENT_OF;
};
