import { Col, Icon, List } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { DATE_FORMAT } from '../../constants/date-constants';
import { createContentPath, editContentDefinitionPath } from '../../constants/routing-constants';
import { userPermissionsTable } from '../../constants/user-permissions-table';
import { LinkableContent } from '../../hoc';
import { ManageFieldProvider } from '../../providers/UserPermissionsProvider';
import { ContentDefinitionListItemActions } from '../index';
import styles from './ContentDefinitionListItem.module.scss';
import {
    ContentDefinitionListItemProps,
    ContentDefinitionListItemState
} from './ContentDefinitionListItemModel';

const ListItem = List.Item;


class ContentDefinitionListItem extends Component<ContentDefinitionListItemProps, ContentDefinitionListItemState> {

    showDate = (lastModifiedDate: Date) => {
        return moment(lastModifiedDate).isBefore(moment().subtract(1, 'days'))
            ? moment(lastModifiedDate).format(DATE_FORMAT)
            : moment(lastModifiedDate).fromNow();
    }

    onItemClick = () => {
        const { contentCreationFlow, contentDefinition, match: { params: { spaceId } } } = this.props;
        if (contentCreationFlow) {
            return createContentPath(spaceId, contentDefinition.id);
        } else {
            return editContentDefinitionPath(spaceId, contentDefinition.id);
        }
    }

    render() {
        const { contentDefinition, contentCreationFlow } = this.props;
        const { author: { fullName }, lastModifiedDate, name, mnemonicId } = contentDefinition;
        return (
            <ListItem id={contentDefinition.id.toString()}>
                <LinkableContent to={this.onItemClick()}>
                    <div className={styles.ContentDefinitionListItemDataContainer}>
                        <Col xs={19} sm={20} md={21}>
                            <Col
                                xs={contentCreationFlow ? 24 : 16}
                                className={styles.ContentDefinitionListItemDefinitionDataContainer}
                            >
                                <Icon type="book" className={styles.ContentDefinitionListItemIcons} />
                                <div className={styles.ContentDefinitionListItemInfoContainer}>
                                    <div className={styles.ContentDefinitionListItemNameWrapper}>{name}</div>
                                    <div className={styles.ContentDefinitionListItemMnemonicWrapper}>{mnemonicId}</div>
                                </div>
                            </Col>
                            {!contentCreationFlow
                                && <Col xs={8} className={styles.ContentDefinitionListItemAuthorContainer}>
                                    <Col xs={24} className={styles.ContentDefinitionListItemLastEditInfoWrapper}>
                                        <Icon type="user" className={styles.ContentDefinitionListItemIcons} />
                                        <div className={styles.ContentDefinitionListItemEditInfoTextContainer}>
                                            {fullName}
                                        </div>
                                    </Col>
                                    <Col xs={24} className={styles.ContentDefinitionListItemLastEditInfoWrapper}>
                                        <Icon type="edit" className={styles.ContentDefinitionListItemIcons} />
                                        <div className={styles.ContentDefinitionListItemEditInfoTextContainer}>
                                            {this.showDate(lastModifiedDate)}
                                        </div>
                                    </Col>
                                </Col>}
                        </Col>
                        <ManageFieldProvider
                            permissions={userPermissionsTable.ListItemActions.ContentDefinitionListItem}
                        >
                            <Col xs={5} sm={4} md={3} className={styles.ContentDefinitionListItemActionsContainer}>
                                <div>
                                    <ContentDefinitionListItemActions
                                        contentCreationFlow={contentCreationFlow}
                                        definitionId={contentDefinition.id}
                                    />
                                </div>
                            </Col>
                        </ManageFieldProvider>
                    </div>
                </LinkableContent>
            </ListItem>
        );
    }
}

export default withRouter(ContentDefinitionListItem);
