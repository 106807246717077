import React from 'react';
import { FormattedMessage } from 'react-intl';
import BooleanAttribute from '../Fields/Boolean';
import
withDefinitionErrors,
{ WithDefinitionErrorsReturnProps }
    from '../../../../hoc/withDefinitionErrors/withDefinitionErrors';
import { InjectedIntlProps, injectIntl } from 'react-intl';

export interface AllowEmptyProps extends WithDefinitionErrorsReturnProps, InjectedIntlProps {
    value?: boolean;
    onChange(allowed: boolean): any;
}

function AllowEmpty(props: AllowEmptyProps) {
    return (
        <BooleanAttribute
            hasErrors={props.hasErrors}
            errors={props.errors.map(e => props.intl.formatMessage(e))}
            attributeName={<FormattedMessage
                defaultMessage="Allow empty"
                id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.Constraint.AllowEmpty"
            />}
            helperTrue={<FormattedMessage
                id="AllowEmpty.attributeHelper.value.true"
                defaultMessage="Let this field take an empty value"
            />}
            helperFalse={<FormattedMessage
                id="AllowEmpty.attributeHelper.value.false"
                defaultMessage="Don't let this field take an empty value"
            />}
            onChange={props.onChange}
            value={props.value}
        />
    );
}

export default withDefinitionErrors(injectIntl(AllowEmpty));
