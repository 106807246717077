import { Field } from '@contentchef/contentchef-types';
import { ConfigProvider } from 'antd';
import React, { Component } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { FieldDescriptor } from '../types';
import { inject, observer } from 'mobx-react';
import { Step } from '../../index';
import {
    ContentDefinitionSchemaStoreModel
} from '../../../stores/ContentDefinitionEditorStore/ContentDefinitionSchemaStore';
import ContentDefinitionFieldEditing from '../ContentDefinitionFieldEditing';
import DefinitionSelection from './DefinitionSelection/DefinitionSelection';
import CustomFieldSelection from './CustomFieldSelection/CustomFieldSelection';
import DefinitionFieldSelection from './DefinitionFieldSelection/DefinitionFieldSelection';

export type ChangeStepContextData = {
    changeActiveStep(newStep: FieldSelectionStep): void
};

export const ChangeStepContext = React.createContext<ChangeStepContextData>({
    changeActiveStep(newStep: FieldSelectionStep): void { return; }
});

export enum FieldSelectionStep {
    SelectField = 0,
    EditField,
    SelectDefinition,
    SelectDefinitions,
    SelectCustomFields
}

export interface ContentDefinitionEditorDrawerProps extends InjectedIntlProps {
    defaultFieldSnippets: FieldDescriptor[];
    customFieldSnippets: FieldDescriptor[];
    isAddingField: boolean;
    onSelect(arg: Field): any;
    onSaveField(): void;
    onCheckNameAlreadyExist(value: string): boolean;
    closeDrawer(): void;
}

interface InjectedProps extends ContentDefinitionEditorDrawerProps {
    contentDefinitionSchemaStore: ContentDefinitionSchemaStoreModel;
}

interface ContentDefinitionEditorDrawerState {
    activeStep: FieldSelectionStep;
}

function getInitialState(): ContentDefinitionEditorDrawerState {
    return {
        activeStep: FieldSelectionStep.SelectField,
    };
}

@inject('contentDefinitionSchemaStore')
@observer
// tslint:disable-next-line: max-line-length
class ContentDefinitionEditorDrawer extends Component<ContentDefinitionEditorDrawerProps, ContentDefinitionEditorDrawerState> {
    public state = getInitialState();

    get injected() {
        return this.props as InjectedProps;
    }

    componentDidMount(): void {
        if (this.props.isAddingField) {
            this.setState({ activeStep: FieldSelectionStep.SelectField });
        } else {
            this.setState({ activeStep: FieldSelectionStep.EditField });
        }
    }

    onSaveField = () => {
        this.setState({ ...getInitialState() });
        return this.props.onSaveField();
    }

    public changeActiveStep = (newStep: FieldSelectionStep) => {
        this.setState({ activeStep: newStep });
    }

    public backToSelectField = () => {
        this.setState({ activeStep: FieldSelectionStep.SelectField });
    }

    public cancelSelection = () => {
        this.props.closeDrawer();
    }

    public render() {
        const {
            customFieldSnippets,
            defaultFieldSnippets,
        } = this.props;
        const { activeStep } = this.state;
        return (
            <ConfigProvider getPopupContainer={() => document.body}>
                <ChangeStepContext.Provider value={{ changeActiveStep: this.changeActiveStep }}>
                    <Step activeStep={activeStep} step={FieldSelectionStep.SelectField}>
                        <DefinitionFieldSelection

                            onCheckNameAlreadyExist={this.props.onCheckNameAlreadyExist}
                            fieldSnippets={defaultFieldSnippets.concat(customFieldSnippets)}
                            onSelect={this.props.onSelect}
                        />
                    </Step>
                    <Step activeStep={activeStep} step={FieldSelectionStep.EditField}>
                        <ContentDefinitionFieldEditing
                            onCheckNameAlreadyExist={this.props.onCheckNameAlreadyExist}
                            onSave={this.onSaveField}
                            onBack={this.cancelSelection}
                        />
                    </Step>
                    <Step activeStep={activeStep} step={FieldSelectionStep.SelectDefinition}>
                        <DefinitionSelection selectionType={'single'} />
                    </Step>
                    <Step activeStep={activeStep} step={FieldSelectionStep.SelectDefinitions}>
                        <DefinitionSelection selectionType={'multiple'} />
                    </Step>
                    <Step activeStep={activeStep} step={FieldSelectionStep.SelectCustomFields}>
                        <CustomFieldSelection
                            customFields={this.props.customFieldSnippets.map(customField => customField.name)}
                        />
                    </Step>
                </ChangeStepContext.Provider>
            </ConfigProvider>
        );
    }
}

export default injectIntl(ContentDefinitionEditorDrawer);
