import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import {

    ContentId,
    ContentResponse,
    ContentsStatus,
    ContentTranslation,
    FieldTranslation,
    LinkingContents,
    ListContentsItem,
    LocalizationSettings,
    ToggleArchivedContentRequest,
    UpdateContentRequest
} from '@contentchef/contentchef-types';
import { ExtensionManager } from '@stores/extensionManager';
import { action, computed, IObservableArray, observable, reaction, runInAction } from 'mobx';
import {
    ContentErrorFactory, ContentErrorTypes, ContentValidationError
} from '../../services/Error/ContentErrorManager';
import { ChannelsStoreModel } from '../channelsStore/channelsStoreModel';
import { ContentStoreModel } from '../contentStore/contentStoreModel';
import { FormMetaStoreModel } from '../formMetaStore/formMetaStoreModel';
import { ListPublicIdStoreModel } from '../listPublicIdStore/listPublicIdStoreModel';
import { PublishingStoreModel } from '../publishingStore/publishingStoreModel';
import { RepositoryListStoreModel } from '../repositoryListStore/repositoryListStoreModel';
import {
    EditContentDataModel,
    EditContentStoreDependencies, EditContentStoreModel
} from './editContentStoreModel';

class EditContentStore implements EditContentStoreModel {
    api: ContentChefClient;
    repositoryListStore: RepositoryListStoreModel;
    channelsStore: ChannelsStoreModel;
    formMetaStore: FormMetaStoreModel;
    contentStore: ContentStoreModel;
    listPublicIdStore: ListPublicIdStoreModel;
    publishingStore: PublishingStoreModel;
    extensionManager: ExtensionManager;
    inTranslation: boolean = false;

    @observable samePublicIdContentList: IObservableArray<ListContentsItem> = observable.array([]);
    @observable totalContentWithSamePublicId: number | null = null;
    @observable publicIdContentListLoader = false;
    @observable toggleContentTranslationsLoader = false;

    @observable editContentData: EditContentDataModel = {
        initialContentData: undefined,
        modifiedContentData: false,
        contentHasDeletedProperties: false,
    };

    @observable contentTranslations: IObservableArray<ContentTranslation> = observable.array([]);

    constructor(api: ContentChefClient, storeDependencies: EditContentStoreDependencies) {
        this.api = api;
        this.repositoryListStore = storeDependencies.repositoryListStore;
        this.channelsStore = storeDependencies.channelsStore;
        this.formMetaStore = storeDependencies.formMetaStore;
        this.contentStore = storeDependencies.contentStore;
        this.listPublicIdStore = storeDependencies.listPublicIdStore;
        this.publishingStore = storeDependencies.publishingStore;
        this.extensionManager = storeDependencies.extensionManager;

        reaction(
            () => this.formMetaStore.changedFields.length,
            length => this.editContentData.modifiedContentData = length > 0
        );
    }

    @action
    async initializeEditContent(contentId: string, inTranslation: boolean = false) {
        this.inTranslation = inTranslation;
        try {
            await Promise.all([
                this.contentStore.setContentById(contentId),
                this.channelsStore.setAllChannels(),
                this.extensionManager.setSpaceExtensions()
            ]);
            const { selectedContent, linkingContents } = this.contentStore;
            if (selectedContent) {
                await Promise.all([
                    this.repositoryListStore.setRepositories(30),
                    this.listPublicIdStore.initialize(selectedContent.definition.id, selectedContent.publicId),
                    this.publishingStore.startPollingPublishingRequests(selectedContent.id, selectedContent.version),
                    this.setSamePublicIdContentList(selectedContent.publicId)
                ]);
                this.initFormMetaStoreAndButtonsState(selectedContent, linkingContents, inTranslation);
            }
        } catch (e) {
            console.log('Impossible to initialize edit form', e);
        }
    }

    @action
    async setSamePublicIdContentList(publicId: string) {
        try {
            this.publicIdContentListLoader = true;
            const result = await this.api.contents.list({
                take: 100, skip: 0, filters: { publicIds: [publicId], contentsStatus: ContentsStatus.all }
            });
            runInAction(() => {
                this.samePublicIdContentList.replace(result.items);
                this.totalContentWithSamePublicId = result.total;
                this.publicIdContentListLoader = false;
            });
        } catch (error) {
            console.log(`Error when retrieving contents for publicId ${publicId}`, error);
            runInAction(() => {
                this.publicIdContentListLoader = false;
            });
        }
    }

    @action.bound
    async toggleContentTranslations(locSetting: LocalizationSettings) {
        try {
            this.toggleContentTranslationsLoader = true;
            const contentId = this.editContentData.initialContentData!.id;
            const result =
                await this.api.contents.toggleContentTranslations({
                    contentId, localizationSettings: locSetting
                });
            await this.initializeEditContent(contentId.toString(), this.inTranslation);
            runInAction(() => {
                this.toggleContentTranslationsLoader = false;
            });
            return result;
        } catch (error) {
            console.log('Something went wrong while trying to enable/disable translations');
            runInAction(() => {
                this.toggleContentTranslationsLoader = false;
            });
            return Promise.reject();
        }
    }

    @action
    async updateContent() {
        const updateContentData = this.createUpdateContentDate();
        if (updateContentData) {
            try {
                const response = await this.contentStore.updateContent(updateContentData);
                // TODO: REFACTOR AFTER THAT BACKEND CALL WILL RETURN THE UPDATED CONTENT FULLY RESOLVE;
                try {
                    await this.contentStore.setContentById(response.id.toString());
                    const { selectedContent, linkingContents } = this.contentStore;
                    if (selectedContent) {
                        this.publishingStore.clearPollingPublishingRequests();
                        await this.listPublicIdStore.initialize(
                            selectedContent.definition.id, selectedContent.publicId
                        );
                        await this.publishingStore.startPollingPublishingRequests(
                            selectedContent.id, selectedContent.version
                        );
                        await this.setSamePublicIdContentList(selectedContent.publicId);
                        this.initFormMetaStoreAndButtonsState(selectedContent, linkingContents);
                        return Promise.resolve();
                    }
                } catch (e: any) {
                    console.log('Something went wrong while trying retrieve the new content');

                    return Promise.reject(ContentErrorFactory.createContentError(e));
                }
            } catch (error: any) {
                error.maybeHandleViolations();
                return Promise.reject(error);
            }
        } else {
            console.log('Something went wrong while trying to update the content');
            return Promise.reject(ContentErrorFactory.createContentError(
                { type: ContentErrorTypes.UnexpectedError } as ContentValidationError
            ));
        }
    }

    async createPublishingRequest(contentId: ContentId, version: number) {
        await this.publishingStore.createPublishingRequest(contentId, version);
    }

    @computed
    get isContentModified() {
        if (this.editContentData.contentHasDeletedProperties) {
            return false;
        }
        return !this.editContentData.modifiedContentData;
    }

    @computed
    get isContentPublishable() {
        return this.formMetaStore.formMeta.channels.value.length > 0
            ? this.editContentData.modifiedContentData
            : true;
    }

    async toggleArchiveContent(archive: boolean) {
        if (this.contentStore.selectedContent) {
            const reqParams: ToggleArchivedContentRequest = {
                id: this.contentStore.selectedContent.id,
                archived: archive
            };

            try {
                await this.contentStore.toggleArchivedContent(reqParams);
                await this.initializeEditContent(this.contentStore.selectedContent.id.toString());
            } catch (e) {
                console.log(e);
            }
        }
    }

    @action.bound
    async updateTranslations(locale: string, translations: FieldTranslation[]) {
        try {
            await this.api.contentTranslations.update({
                contentId: this.editContentData.initialContentData!.id,
                checksum: this.editContentData.initialContentData!.checksum,
                locale: locale,
                translations
            });
            await this.initializeEditContent(
                this.editContentData.initialContentData!.id.toString(), true
            );
            return true;
        } catch (error) {
            console.log(error);
            return Promise.reject(ContentErrorFactory.createContentError(
                { type: ContentErrorTypes.UnexpectedError } as ContentValidationError
            ));
        }
    }

    @action.bound
    async createTranslations(locales: string[]) {
        try {
            await Promise.all(locales.map(locale => this.api.contentTranslations.save(
                { contentId: this.editContentData.initialContentData!.id, locale })));
            await this.replaceTranslationsWithRemote();
            return true;
        } catch (error) {
            console.log(error);
            return Promise.reject();
        }
    }

    @action.bound
    async deleteTranslation(locale: string) {
        try {
            await this.api.contentTranslations.delete(
                { contentId: this.editContentData.initialContentData!.id, locale });
            await this.replaceTranslationsWithRemote();
            return true;
        } catch (error) {
            console.log(error);
            return Promise.reject();
        }
    }

    @action.bound
    async updateContentLocale(id: ContentId, locale: string) {
        try {
            const response = await this.api.contents.changeLocale({ contentId: id, locale });
            runInAction(() => {
                this.editContentData.initialContentData = response;
            });
            return response;
        } catch (error) {
            console.log(error);
            return Promise.reject(ContentErrorFactory.createContentError(
                { type: ContentErrorTypes.UnexpectedError } as ContentValidationError
            ));
        }
    }

    @action.bound
    async cleanTranslation(locales: string[]) {
        try {
            await this.api.contentTranslations.cleanTranslations(
                { contentId: this.editContentData.initialContentData!.id, locales });
            return true;
        } catch (error) {
            console.log(error);
            return Promise.reject();
        }
    }

    @action.bound
    async cleanTranslations(locales: string[]) {
        try {
            await this.api.contentTranslations.cleanTranslations(
                { contentId: this.editContentData.initialContentData!.id, locales });
            await this.replaceTranslationsWithRemote();
            return true;
        } catch (error) {
            console.log(error);
            return Promise.reject();
        }
    }

    private createUpdateContentDate(): UpdateContentRequest | undefined {
        const { initialContentData } = this.editContentData;
        if (initialContentData) {
            const { onlineDate, offlineDate, ...others } = this.formMetaStore.getCommonFieldsValue();
            const payloadValues = this.formMetaStore.getDynamicFieldValues();
            return {
                id: initialContentData.id,
                publicId: others.publicId,
                name: others.name,
                tags: others.tags,
                repositoryId: others.repository,
                channelIds: others.channels,
                payload: {
                    ...payloadValues
                },
                definitionId: initialContentData.definition.id,
                onlineDate,
                offlineDate
            } as UpdateContentRequest;
        }
        return undefined;
    }

    @action
    private initFormMetaStoreAndButtonsState(
        selectedContent: ContentResponse,
        linkedContent?: IObservableArray<LinkingContents>,
        inTranslation: boolean = false
    ) {
        this.formMetaStore.initializeStore(selectedContent.definition, selectedContent, inTranslation);
        this.editContentData.initialContentData = selectedContent;
        this.contentTranslations.replace(selectedContent.translations);
        this.editContentData.linkedContent = linkedContent;
        this.editContentData.modifiedContentData = this.formMetaStore.changedFields.length > 0;
        const originalContentPayload = JSON.stringify(selectedContent.payload);
        const payloadFromMeta = JSON.stringify(this.formMetaStore.getDynamicFieldValues());
        this.editContentData.contentHasDeletedProperties = originalContentPayload !== payloadFromMeta;
    }

    @action.bound
    private async replaceTranslationsWithRemote() {
        const translations = await this.api.contentTranslations.list({
            contentId: this.editContentData.initialContentData!.id
        });
        runInAction(() => {
            if (this.editContentData.initialContentData) {
                this.contentTranslations.replace(translations);
            }
        });
    }
}

export default EditContentStore;
