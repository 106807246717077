import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { ListContentsItem } from '@contentchef/contentchef-types';
import { ContentDefinitionResponse } from '@contentchef/contentchef-types';
import { ContentCard } from '..';
import { ReleaseItemProps, InjectedProps } from './ReleaseItemModel';
import { ReleaseItemWithRepository } from '../../stores/publishToLiveStore/publishToLiveStoreModel';

import CheckedIcon from '../CheckedIcon/CheckedIcon';

function toContentItem(releaseItem: ReleaseItemWithRepository): Partial<ListContentsItem> {
    return {
        id: releaseItem.id,
        name: releaseItem.contentName,
        publicId: releaseItem.publicId,
        tags: releaseItem.contentTags,
        onlineDate: releaseItem.onlineDate,
        offlineDate: releaseItem.offlineDate,
        definition: {
            mnemonicId: releaseItem.contentDefinitionMnemonicId
        } as ContentDefinitionResponse,
        repository: releaseItem.repository,
        locale: releaseItem.contentLocale,
        translationStatus: releaseItem.translationStatus
    } as Partial<ListContentsItem>;
}

@inject('publishToLiveStore')
@observer
class ReleaseItem extends Component<ReleaseItemProps> {

    get injected() {
        return this.props as InjectedProps;
    }

    onToggle = () => {
        const { releaseItem } = this.props;
        const { publishToLiveStore } = this.injected;
        if (publishToLiveStore.isReleaseItemSelected(releaseItem)) {
            this.injected.publishToLiveStore.removeReleaseItem(releaseItem);
        } else {
            this.injected.publishToLiveStore.addReleaseItem(releaseItem);
        }
    }

    render() {
        const {
            releaseItem
        } = this.props;
        const { publishToLiveStore } = this.injected;
        return (
            <ContentCard
                selectable={<CheckedIcon selected={publishToLiveStore.isReleaseItemSelected(releaseItem)} />}
                onCardClick={this.onToggle}
                content={toContentItem(releaseItem) as ListContentsItem}
            />
        );
    }
}

export default ReleaseItem;
