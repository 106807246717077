import React from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ChangeStepContext, FieldSelectionStep } from '../../ContentDefinitionEditorDrawer';
import styles from './index.module.scss';
import Segment from '../../../Segment/Segment';
import
withDefinitionErrors,
{ WithDefinitionErrorsReturnProps }
    from '../../../../hoc/withDefinitionErrors/withDefinitionErrors';
import Button from '@components/Button';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import {
    withPermissionsToInteract,
    WithPermissionsToInteractReturnType
} from '@providers/UserPermissionsProvider';

export interface DefinitionMnemonicIdsProps extends WithDefinitionErrorsReturnProps, InjectedIntlProps,
    WithPermissionsToInteractReturnType {
    label: React.ReactNode;
    value?: string[];
    selectionType: 'single' | 'multiple';
}

function DefinitionMnemonicIds(props: DefinitionMnemonicIdsProps) {
    return (
        <Segment
            title={props.label}
            divider={true}
            titleVariant="label"
        >
            <Form.Item
                help={props.hasErrors ? props.errors.map(e => props.intl.formatMessage(e)).join(', ') : undefined}
                validateStatus={props.hasErrors ? 'error' : undefined}
                style={{ width: '100%' }}
            >
                <span className={styles.ValueWrapper}>
                    {!!props.value && props.value.length > 0
                        ? <FormattedMessage
                            id="DefinitionMnemonicIds.Selected.Message"
                            /* tslint:disable-next-line:max-line-length */
                            defaultMessage="This field allows you to refer to content derived from the following content {count, plural, one {definition} other {definitions}}: {definitions}"
                            values={{
                                count: props.value.length,
                                definitions: props.value.join(', ')
                            }}
                        />
                        : <FormattedMessage
                            id="DefinitionMnemonicIds.NotSelected.Message"
                            defaultMessage="Nothing selected"
                        />
                    }
                </span>
                <span>
                    <ChangeStepContext.Consumer>
                        {context => (
                            <Button
                                disabled={!props.hasPermissions}
                                type="dashed"
                                icon={!!props.value && props.value.length > 0 ? 'edit' : 'plus'}
                                onClick={() => props.selectionType === 'single'
                                    ? context.changeActiveStep(FieldSelectionStep.SelectDefinition)
                                    : context.changeActiveStep(FieldSelectionStep.SelectDefinitions)
                                }
                            >
                                {!!props.value && props.value.length > 0
                                    ? <FormattedMessage
                                        id="DefinitionMnemonicIds.Selected.Button"
                                        defaultMessage="Update"
                                    />
                                    : <FormattedMessage
                                        id="DefinitionMnemonicIds.NotSelected.Button"
                                        defaultMessage="Add"
                                    />
                                }
                            </Button>
                        )}
                    </ChangeStepContext.Consumer>
                </span>
            </Form.Item>
        </Segment>
    );
}

export default withPermissionsToInteract(withDefinitionErrors(injectIntl(DefinitionMnemonicIds)));
