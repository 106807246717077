import React from 'react';
import { ArchivedContentContext } from '../../../providers/ArchivedContentProvider';
import { DisableFieldContext } from '../../../providers/UserPermissionsProvider';

export type RenderElement = (disabled: boolean) => JSX.Element;

export function defaultFieldWrapper(renderElement: RenderElement) {
    return (
        <ArchivedContentContext.Consumer>
            {({ archived }) => (
                <DisableFieldContext.Consumer>
                    {hasPermission => (
                        renderElement(archived || !hasPermission)
                    )}
                </DisableFieldContext.Consumer>
            )}
        </ArchivedContentContext.Consumer>
    );
}
