import { SchemaTypeIds } from '@contentchef/contentchef-types';
import React from 'react';
import BooleanAttribute from '../Fields/Boolean';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import
withDefinitionErrors,
{ WithDefinitionErrorsReturnProps }
    from '../../../../hoc/withDefinitionErrors/withDefinitionErrors';

interface CompactProps extends InjectedIntlProps, WithDefinitionErrorsReturnProps {
    type: SchemaTypeIds;
    value?: boolean;
    onChange(value: boolean): any;
}

const compactLabels = defineMessages({
    CompactAttrName: {
        id: 'components.ContentDefinitionSchemaEditor.Compact.Label',
        defaultMessage: 'Compact'
    },
    CompactSelectedHelper: {
        id: 'components.ContentDefinitionSchemaEditor.Compact.Helper.True',
        defaultMessage: 'Show this field when content is linked with compact view'
    },
    CompactUnselectedHelper: {
        id: 'components.ContentDefinitionSchemaEditor.Compact.Helper.False',
        defaultMessage: 'Exclude this field from compact view'
    },
    CompactDescription: {
        id: 'components.ContentDefinitionSchemaEditor.Compact.Description',
        defaultMessage: 'Determines whether or not this field is included in the compact view'
    }
});

function CompactAttribute(props: CompactProps) {
    return (
        <BooleanAttribute
            attributeName={props.intl.formatMessage(compactLabels.CompactAttrName)}
            helperTrue={props.intl.formatMessage(compactLabels.CompactSelectedHelper)}
            helperFalse={props.intl.formatMessage(compactLabels.CompactUnselectedHelper)}
            label={props.intl.formatMessage(compactLabels.CompactDescription)}
            onChange={props.onChange}
            value={props.value}
            hasErrors={props.hasErrors}
            errors={props.errors.map(error => props.intl.formatMessage(error))}
        />
    );
}

export default withDefinitionErrors(injectIntl(CompactAttribute));
