import SignUpImage from '@assets/images/signUpFormImage.svg';
import Button from '@components/Button';
import { LOGIN_ROUTE, ROOT_ROUTE } from '@constants/routing-constants';
import withTracker from '@hoc/withTracker/withTracker';
import { Divider, Form, Icon, Input, Popover } from 'antd';
import { Auth } from 'aws-amplify';
import { ISignUpProps } from 'aws-amplify-react/lib/Auth/SignUp';
import React from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages, FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Route, RouteComponentProps } from 'react-router-dom';
import classes from './index.module.scss';
import OnboardingContainer from './OnboardingContainer';
import OnboardingPrivacy from './OnboardingPrivacy';
import SignInWithGoogle from './SignInWithGoogle';

const Item = Form.Item;

const signUpLabels = defineMessages({
    title: {
        defaultMessage: 'Start your 30-day free trial',
        id: 'components.onboarding.signUp.title'
    },
    subtitle: {
        defaultMessage: 'No credit card required',
        id: 'components.onboarding.signUp.subtitle'
    },
    name: {
        defaultMessage: 'Name',
        id: 'components.onboarding.SignUp.name'
    },
    email: {
        defaultMessage: 'Email',
        id: 'components.onboarding.SignUp.Email'
    },
    password: {
        defaultMessage: 'Password',
        id: 'components.onboarding.SignUp.Password'
    },
    confirmPassword: {
        defaultMessage: 'Confirm password',
        id: 'components.onboarding.SignUp.ConfirmPassword'
    },
    samePassword: {
        defaultMessage: 'Passwords does not match',
        id: 'components.onboarding.SignUp.SamePassword'
    },
    or: {
        defaultMessage: 'or',
        id: 'components.onboarding.signUp.or'
    },
    signInInstead: {
        defaultMessage: 'Do you already have an account?',
        id: 'components.onboarding.signUp.signInInstead'
    },
    primaryButton: {
        defaultMessage: 'Sign up',
        id: 'components.onboarding.signUp.signUp'
    },
    secondaryButton: {
        defaultMessage: 'Sign in',
        id: 'components.onboarding.signUp.signInInsteadAction'
    }
});

export interface SignUpProps extends InjectedIntlProps, ISignUpProps { }

interface SignUpState {
    loading: boolean;
    name: string;
    password: string;
    username: string;
    confirmPassword: string;
    isConfirmPasswordDirty: boolean;
    samePassword: boolean;
}

function getInitialState(): SignUpState {
    return {
        loading: false,
        name: '',
        password: '',
        username: '',
        confirmPassword: '',
        isConfirmPasswordDirty: false,
        samePassword: true
    };
}

class SignUp extends React.Component<SignUpProps & RouteComponentProps, SignUpState> {
    public state = getInitialState();

    public changeState = (authState: string, event?: any) => {
        return this.props.onStateChange!(authState, event);
    }

    public handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name === 'confirmPassword') {
            this.setState({
                isConfirmPasswordDirty: true,
                samePassword: value === this.state.password
            });
        }
        if (this.state.isConfirmPasswordDirty && name === 'password') {
            this.setState({ samePassword: value === this.state.confirmPassword });
        }

        this.setState({
            [name]: value,
        } as any);
    }

    public signUp = async () => {
        const {
            name,
            password,
            username,
            samePassword
        } = this.state;

        if (!samePassword) {
            return;
        }

        const trimmedName = name.trim();

        this.setState({ loading: true });

        try {
            await Auth.signUp({
                attributes: { name: trimmedName || undefined },
                username,
                password
            });

            await Auth.signIn({ password, username });

            const session = await Auth.currentSession();

            if (session.isValid()) {
                this.storePromoCodeIfAvailable();
                this.props.onStateChange!('signedIn', {});
            }
        } catch (error: any) {
            const parseError = (e: unknown) => {
                if (typeof e === 'string') {
                    return e;
                }

                return error.message ? error.message : JSON.stringify(error);
            };

            this.props.onAuthEvent!('signUp', {
                type: 'error',
                data: parseError(error),
            });
        }

        this.setState({ loading: false });
    }

    storePromoCodeIfAvailable = () => {
        const promoCode = new URLSearchParams(this.props.location.search).get('promocode');
        if (promoCode) {
            sessionStorage.setItem('promoCode', promoCode);
        }
    }

    componentDidUpdate(prevProps: SignUpProps & RouteComponentProps) {
        if ((prevProps.authState !== this.props.authState) && this.props.authState === 'signedIn') {
            this.props.history.push(ROOT_ROUTE);
        }
    }

    public render() {
        const {
            loading,
            name,
            password,
            username,
            confirmPassword,
            samePassword
        } = this.state;

        if (this.props.authState !== 'signUp') {
            return null;
        }

        return (
            <React.Fragment>
                <OnboardingContainer
                    topContent={<React.Fragment>
                        {this.props.intl.formatMessage(signUpLabels.title)}
                        <small className={classes.OnboardingSubTitle}>
                            {this.props.intl.formatMessage(signUpLabels.subtitle)}
                        </small>
                    </React.Fragment>}
                    leftContent={<img style={{ width: '100%' }} src={SignUpImage} alt={'Sign up'} />}
                >
                    <Form className={classes.OnboardingForm}>
                        <Item>
                            <Input
                                disabled={loading}
                                id="name"
                                name="name"
                                onChange={this.handleInputChange}
                                value={name}
                                placeholder={this.props.intl.formatMessage(signUpLabels.name)}
                            />
                        </Item>
                        <Item>
                            <Input
                                disabled={loading}
                                id="email"
                                name="username"
                                onChange={this.handleInputChange}
                                type="email"
                                value={username}
                                placeholder={this.props.intl.formatMessage(signUpLabels.email)}
                            />
                        </Item>
                        <Item>
                            <Popover
                                trigger="focus"
                                content={
                                    <React.Fragment>
                                        <div className={classes.SignUpPasswordHint}>
                                            <Icon type="warning" className={classes.WarningIcon} />
                                            <FormattedMessage
                                                id="SignUp.labels.passwordPattern"
                                                // tslint:disable-next-line: max-line-length
                                                defaultMessage="Password must be at least 8 characters and contain 1 number, 1 uppercase letter and 1 lowercase letter"
                                            />
                                        </div>
                                    </React.Fragment>
                                }
                                overlayClassName={classes.SignUpPasswordHintContainer}
                            >
                                <Input
                                    disabled={loading}
                                    id="password"
                                    name="password"
                                    onChange={this.handleInputChange}
                                    placeholder={this.props.intl.formatMessage(signUpLabels.password)}
                                    type="password"
                                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
                                    value={password}
                                />
                            </Popover>
                        </Item>
                        <Item
                            validateStatus={samePassword ? undefined : 'error'}
                            help={samePassword
                                ? undefined
                                : this.props.intl.formatMessage(signUpLabels.samePassword)
                            }
                        >
                            <Input
                                disabled={loading}
                                id="confirmPassword"
                                name="confirmPassword"
                                onChange={this.handleInputChange}
                                placeholder={this.props.intl.formatMessage(signUpLabels.confirmPassword)}
                                type="password"
                                value={confirmPassword}
                            />
                        </Item>
                        <Item>
                            <Button
                                className={classes.OnboardingButton}
                                disabled={loading || !samePassword}
                                loading={loading}
                                onClick={this.signUp}
                                type="primary"
                            >
                                {this.props.intl.formatMessage(signUpLabels.primaryButton)}
                            </Button>
                        </Item>
                        {process.env.REACT_APP_ENABLE_GOOGLE_AUTH === 'true' && (<>
                            <Divider className={classes.OnboardingOr}>
                                {this.props.intl.formatMessage(signUpLabels.or)}
                            </Divider>
                            <Item>
                                <SignInWithGoogle disabled={loading} inSignUpPage={true} />
                            </Item>
                        </>)}
                        <Item>
                            <OnboardingPrivacy />
                        </Item>
                        <Item>
                            {this.props.intl.formatMessage(signUpLabels.signInInstead)}
                            <a
                                style={{ marginLeft: '5px' }}
                                onClick={() => {
                                    this.changeState('signIn');
                                    this.props.history.push(LOGIN_ROUTE);
                                }}
                            >
                                {this.props.intl.formatMessage(signUpLabels.secondaryButton)}
                            </a>
                        </Item>
                    </Form>
                </OnboardingContainer>
            </React.Fragment>
        );
    }
}

export const SignUpComponent = injectIntl(SignUp);

export function SignUpWithRoute(props: any) {
    return (
        <Route
            path="/registration"
            component={withTracker((rProps: any) => (
                <React.Fragment>
                    <Helmet>
                        <title>
                            Registration - ContentChef
                        </title>
                        <link rel="canonical" href="https://app.contentchef.io/registration" />
                        <meta
                            name="description"
                            content="Register and start your 30 day free trial. No credit card required."
                        />
                    </Helmet>
                    <SignUpComponent {...{ ...props, ...rProps }} />
                </React.Fragment>
            ))}
        />
    );
}
