import Button from '@components/Button';
import { COMMON_LABELS } from '@constants/common-internationalized-labels';
import { labels } from '@constants/content-translation-labels';
import { contentTranslationPath } from '@constants/routing-constants';
import {
    ContentTranslation, ContentTranslationStatus as ContentTranslationStatusType, LocalizationSettings
} from '@contentchef/contentchef-types';
import { groupByLocales, sortByFallback } from '@services/languages';
import { Button as AntdButton, Collapse, Popconfirm } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styles from './index.module.scss';
import LocaleItem, { LocaleItemWithToggle } from './LocaleItem';

const Panel = Collapse.Panel;

interface Props extends RouteComponentProps<{ spaceId: string; contentId: string }> {
    locales: string[];
    contentLocale: string;
    translations: ContentTranslation[];
    intl: InjectedIntl;
    locSetting: LocalizationSettings;
    loading: boolean;
    hideSelection?: boolean;
    fallback?: string;
    cleanTransMode?: boolean;
    onAddTranslation(locales: string[]): any;
    changeLocSetting(locSetting: LocalizationSettings): any;
    onClean?(locales: string[]): any;
    onDelete?(locale: string): any;
}

function ContentTranslationStatus(
    { locales, contentLocale, translations, onAddTranslation, onDelete, locSetting, loading, intl, changeLocSetting,
        hideSelection, fallback = 'en', onClean, cleanTransMode,
        match: { params: { contentId, spaceId } } }: Props
) {
    const existingContentLocales = translations.map(trans => trans.locale);
    const mergedLocales = [...locales, ...existingContentLocales];
    const uniqueLocales = Array.from(new Set([...mergedLocales])).sort();
    const allBaseLocales = uniqueLocales.reduce(
        (acc, item) => {
            if (!item.includes('_')) {
                acc.push(item);
                return acc;
            }
            const language = item.split('_')[0];
            if (language && !uniqueLocales.includes(language)) {
                acc.push(item);
                return acc;
            }

            return acc;
        },
        [] as string[]
    );
    const regionLocales = uniqueLocales.filter(item => item.includes('_'));
    const groupedLocales = groupByLocales(allBaseLocales, regionLocales);

    const panelsToOpen = Array.from(groupedLocales.keys()).filter(loc => !loc.includes('_'));
    const [localesToSave, setLocaleToSave] = useState<string[]>([]);
    const [saveLoader, setSaveLoader] = useState(false);
    const [localesToClean, setLocalesToClean] = useState<string[]>([]);
    const [cleanLoader, setCleanLoader] = useState(false);

    function toggleLocaleToSave(locale: string) {
        if (localesToSave.includes(locale)) {
            setLocaleToSave(localesToSave.filter(item => item !== locale));
            return;
        }

        setLocaleToSave(localesToSave.concat([locale]));
        return;
    }

    function toggleLocaleToClean(locale: string) {
        if (localesToClean.includes(locale)) {
            setLocalesToClean(localesToClean.filter(item => item !== locale));
            return;
        }

        setLocalesToClean(localesToClean.concat([locale]));
        return;
    }

    useEffect(
        () => {
            if (!cleanTransMode) {
                setLocalesToClean([]);
            }

        },
        [cleanTransMode]
    );

    async function createAllTranslations() {
        try {
            setSaveLoader(true);
            await onAddTranslation(localesToSave);
            setLocaleToSave([]);
        } catch (error) {
            console.error(error);
        } finally {
            setSaveLoader(false);
        }
    }

    async function cleanAllTranslations() {
        try {
            setCleanLoader(true);
            await onClean!(localesToClean);
            setLocalesToClean([]);
        } catch (error) {
            console.error(error);
        } finally {
            setCleanLoader(false);
        }
    }

    async function onConfirm(newLocSetting: LocalizationSettings) {
        await changeLocSetting(newLocSetting);
        setLocaleToSave([]);
        setLocalesToClean([]);
    }

    function buildTranslationUrl(loc: string) {
        return contentTranslationPath(spaceId!, parseInt(contentId!, 10), loc);
    }

    function renderAuto(shouldWrap: boolean = false) {
        const button = (
            <Button
                size="small"
                type={locSetting === LocalizationSettings.DEFAULT ? 'primary' : 'default'}
                className={styles.Button}
                loading={loading && locSetting === LocalizationSettings.MAIN}
            >
                {intl.formatMessage(labels.automatic)}
            </Button>
        );
        return shouldWrap ? (
            <Popconfirm
                overlayClassName={styles.Overlay}
                placement="leftBottom"
                disabled={!!cleanTransMode}
                title={intl.formatMessage(labels.confirmAuto)}
                onConfirm={() => onConfirm(LocalizationSettings.DEFAULT)}
                getPopupContainer={() => document.body}
            >
                {button}
            </Popconfirm>
        ) : button;
    }

    function renderManual(shouldWrap: boolean = false) {
        const button = (
            <Button
                size="small"
                type={locSetting === LocalizationSettings.MAIN ? 'primary' : 'default'}
                className={styles.Button}
                loading={loading && locSetting === LocalizationSettings.DEFAULT}
            >
                {intl.formatMessage(labels.manual)}
            </Button>
        );

        return shouldWrap ? (
            <Popconfirm
                overlayClassName={styles.Overlay}
                placement="leftBottom"
                title={intl.formatMessage(labels.confirmManual)}
                disabled={!!cleanTransMode}
                onConfirm={() => onConfirm(LocalizationSettings.MAIN)}
                getPopupContainer={() => document.body}
            >
                {button}
            </Popconfirm>
        ) : button;
    }

    if (groupedLocales.size === 0) {
        return null;
    }

    function shouldShowSelection(loc: string) {
        if (locSetting === LocalizationSettings.DEFAULT) {
            return false;
        }
        if (typeof hideSelection !== 'undefined') {
            return !hideSelection;
        }

        return locSetting === LocalizationSettings.MAIN;
    }

    function renderLocaleItem(locale: string) {
        const selected = translations.find(item => item.locale === locale
            && item.status !== ContentTranslationStatusType.MISSING);
        const original = locale === contentLocale;
        if (cleanTransMode && (!selected || original)) {
            return null;
        }
        if (cleanTransMode && selected) {
            return (
                <LocaleItemWithToggle
                    key={locale}
                    locale={locale}
                    to={buildTranslationUrl(locale)}
                    contentTranslation={translations.find(item => item.locale === locale)}
                    onSelect={toggleLocaleToSave}
                    onDelete={onDelete!}
                    showSelection={shouldShowSelection(locale)}
                    original={original}
                    disabled={!!hideSelection}
                    toSave={localesToSave.includes(locale)}
                    onToggle={toggleLocaleToClean}
                    toggled={localesToClean.includes(locale)}
                    showEdit={false}
                />
            );
        }
        return (
            <LocaleItem
                key={locale}
                locale={locale}
                to={buildTranslationUrl(locale)}
                contentTranslation={translations.find(item => item.locale === locale)}
                onSelect={toggleLocaleToSave}
                onDelete={onDelete!}
                showSelection={shouldShowSelection(locale)}
                original={original}
                disabled={!!hideSelection}
                toSave={localesToSave.includes(locale)}
                showEdit={locSetting === LocalizationSettings.DEFAULT ? true : undefined}
            />
        );
    }

    return (
        <div className={styles.TranslationStatusContainer}>
            {!hideSelection && <AntdButton.Group className={styles.Toggle}>
                {renderAuto(locSetting !== LocalizationSettings.DEFAULT)}
                {renderManual(locSetting !== LocalizationSettings.MAIN)}
            </AntdButton.Group>}
            <Collapse
                expandIconPosition="right"
                expandIcon={() => null}
                bordered={false}
                className={styles.Container}
                activeKey={panelsToOpen}
                onChange={() => toggleLocaleToClean}
                prefixCls="translation-collapse"
            >
                {sortByFallback(Array.from(groupedLocales.keys()), fallback)
                    .map(locale => {
                        const innerLocales = groupedLocales.get(locale);
                        if (locale === contentLocale && (!innerLocales || innerLocales.length === 0)) {
                            return null;
                        }
                        if (!innerLocales || innerLocales.length === 0) {
                            return renderLocaleItem(locale);
                        }
                        return (
                            <Panel
                                header={renderLocaleItem(locale)}
                                key={locale}
                                style={{ border: 0, padding: 0 }}
                            >
                                {groupedLocales.get(locale)?.map(innerLocale => {
                                    return renderLocaleItem(innerLocale);
                                })}
                            </Panel>
                        );
                    })}
            </Collapse>

            {localesToSave.length > 0 && (
                <Button
                    type="primary"
                    onClick={createAllTranslations}
                    loading={saveLoader}
                    block={true}
                    className={styles.SaveButton}
                >
                    {intl.formatMessage(COMMON_LABELS.saveFormButtonLabel)}
                </Button>
            )}

            {localesToClean.length > 0 && (
                <Popconfirm
                    placement="leftBottom"
                    title={intl.formatMessage(labels.confirmCleanMultiple)}
                    onConfirm={cleanAllTranslations}
                    getPopupContainer={() => document.body}
                >
                    <Button
                        type="danger"
                        loading={cleanLoader}
                        block={true}
                        className={styles.CleanButton}
                    >
                        {intl.formatMessage(COMMON_LABELS.cleanTransLabel)}
                    </Button>
                </Popconfirm>
            )}
        </div>
    );
}

export default withRouter(injectIntl(observer(ContentTranslationStatus)));
