import React, { ChangeEvent, PureComponent } from 'react';
import { Input } from 'antd';
import { defineMessages, injectIntl } from 'react-intl';
import { FilterBySearchProps, FilterBySearchState } from './FilterBySearchModel';

import styles from './FilterBySearch.module.scss';
import { debounce } from '@services/utils';
const { Search } = Input;

const FilterBySearchLabels = defineMessages({
    byNamePlaceHolder: {
        id: 'FilterBySearchLabels.byNamePlaceholder',
        defaultMessage: 'Filter by name'
    },
});

class FilterBySearch extends PureComponent<FilterBySearchProps, FilterBySearchState> {
    debounceSearch: (value?: string) => void;

    constructor(props: FilterBySearchProps) {
        super(props);

        this.debounceSearch = debounce(this.props.onSearch);
    }

    onChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.debounceSearch(event.target.value);
    }

    render() {
        const { intl: { formatMessage }, defaultValue, placeholder, onSearch, dataAttributes } = this.props;
        return (
            <Search
                placeholder={placeholder
                    ? placeholder
                    : formatMessage(FilterBySearchLabels.byNamePlaceHolder)
                }
                className={styles.FilterByName}
                defaultValue={defaultValue}
                onSearch={onSearch}
                onChange={this.onChange}
                {...dataAttributes().Attributes}
            />
        );
    }
}

export default injectIntl(FilterBySearch);
