import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { WEBHOOKS_CREATE_ROUTE, WEBHOOKS_ROUTE, WEBHOOKS_UPDATE_ROUTE } from '../../../constants/routing-constants';
import withWebhooksStores from '../../../hoc/withWebhooksStores/withWebhooksStores';
import WebhookList from './WebhookList/WebhookList';
import CreateWebhook from './CreateWebhook/CreateWebhook';
import UpdateWebhook from './UpdateWebhook/UpdateWebhook';

interface WebhooksRouterProps extends RouteComponentProps { }

const WebhooksRouter = (props: WebhooksRouterProps) => {
    return (
        <Switch>
            <Route
                exact={true}
                path={WEBHOOKS_ROUTE}
                component={WebhookList}
            />
            <Route
                exact={true}
                path={WEBHOOKS_CREATE_ROUTE}
                component={CreateWebhook}
            />
            <Route
                exact={true}
                path={WEBHOOKS_UPDATE_ROUTE}
                component={UpdateWebhook}
            />
        </Switch>
    );
};

export default withWebhooksStores(WebhooksRouter);
