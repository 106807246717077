import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export class ScenarioSelect extends React.Component<SelectProps> {
    public render() {
        return (
            <Select {...this.props}>
                <Select.Option
                    key={'0'}
                    children={<FormattedMessage defaultMessage="Minimal scenario" id="subscription.scenario.minimal" />}
                    value={0}
                />
                <Select.Option
                    key={'1'}
                    children={<FormattedMessage defaultMessage="Empty scenario" id="subscription.scenario.empty" />}
                    value={1}
                />
            </Select>
        );
    }
}
