import React from 'react';
import { Col, Row } from 'antd';
import Button from '@components/Button';
import { ButtonGroupProps } from './ButtonGroupModel';
import styles from './ButtonGroup.module.scss';

function ButtonGroup(props: ButtonGroupProps) {
    return (
        <Row gutter={18} type="flex" justify="center">
            {props.buttonToCreate.map((button, index) => (
                <Col key={index} xs={12} sm={8} lg={6} className={styles.buttonGroupCol}>
                    <Button
                        id={button.id}
                        onClick={button.onClick}
                        className={button.style}
                        disabled={button.disabled && button.disabled()}
                    >
                        {button.label}
                    </Button>
                </Col>
            ))}
        </Row>
    );
}

export default ButtonGroup;
