import {
    LinkedContentSchemaType,
    Field,
    SchemaTypeIds, LinkedContentFieldConstraints, LinkType
} from '@contentchef/contentchef-types';
import { SerializedField } from '../types';
import { ListItemIds } from '../../types';

export interface LinkedContentConstraints {
    lazy?: boolean;
    definitionMnemonicId?: string;
    required: boolean;
    linkType?: LinkType;
}

export type SerializedLinkedContentSchema = SerializedField<LinkedContentConstraints>;

export function retrieveLinkedContentEditingType(constraints: LinkedContentFieldConstraints): ListItemIds {
    return ListItemIds.LegacyContentReference;
}

export function deserialize(serialized: SerializedLinkedContentSchema): Field<LinkedContentSchemaType> {
    return {
        constraints: {
            required: serialized.constraints.required!,
            definitionMnemonicId: serialized.constraints.definitionMnemonicId!,
            lazy: serialized.constraints.lazy!,
            linkType: serialized.constraints.linkType
                ? serialized.constraints.linkType :
                serialized.constraints.lazy! ? LinkType.LAZY : LinkType.FULL
        },
        labels: serialized.labels,
        placeholder: Object.keys(serialized.placeholder).length > 0 ? serialized.placeholder : undefined,
        hint: Object.keys(serialized.hint).length > 0 ? serialized.hint : undefined,
        name: serialized.name,
        enableFacet: serialized.enableFacet,
        type: SchemaTypeIds.LINKED_CONTENT,
        extension: serialized.extension,
        compact: serialized.compact
    };
}

// tslint:disable-next-line:max-line-length
export function serialize(deserialized: Field<LinkedContentSchemaType>, initialIndex: number): SerializedLinkedContentSchema {
    return {
        constraints: {
            lazy: deserialized.constraints.lazy,
            definitionMnemonicId: deserialized.constraints.definitionMnemonicId,
            required: deserialized.constraints.required,
            linkType: deserialized.constraints.linkType
        },
        settings: {},
        labels: deserialized.labels,
        hint: deserialized.hint || {},
        placeholder: deserialized.placeholder || {},
        name: deserialized.name,
        enableFacet: deserialized.enableFacet,
        type: deserialized.type,
        editingType: retrieveLinkedContentEditingType(deserialized.constraints),
        initialIndex,
        extension: deserialized.extension,
        compact: deserialized.compact
    };
}
