import React, { Component } from 'react';
import { UserPermission } from '@contentchef/contentchef-types';
import { LinkableContent } from '../../hoc';
import { Avatar, List } from 'antd';
import styles from './SpaceList.module.scss';
import { getAvatarBackgroundColor } from '../../services/utils';
import { inject } from 'mobx-react';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';
import { ROOT_ROUTE } from '../../constants/routing-constants';

interface SpaceListProps {
    className?: string;
    spaceList: UserPermission[];
    onClickItem?(): void;
}

interface InjectedProps extends SpaceListProps {
    apiStore: ApiStoreModel;
}

interface SpaceListState { }

@inject('apiStore')
class SpaceList extends Component<SpaceListProps, SpaceListState> {

    get injected() { return this.props as InjectedProps; }

    retrieveAvatarName = (space: UserPermission) => {
        return [
            space.spaceName[0].toUpperCase(),
            space.spaceName[space.spaceName.length - 1].toUpperCase()
        ].join('');
    }

    onClickListItem = (spaceId: string) => () => {
        this.injected.apiStore.switchToSpace(spaceId);
        if (!!this.props.onClickItem) {
            this.props.onClickItem();
        }
    }

    render() {
        const { spaceList, className } = this.props;
        return (
            <List
                className={!!className ? `${className} ${styles.SpaceListWrapper}` : styles.SpaceListWrapper}
                pagination={{
                    position: 'bottom',
                    total: spaceList.length,
                    pageSize: 4
                }}
                dataSource={spaceList}
                renderItem={(space: UserPermission) => (
                    <LinkableContent to={ROOT_ROUTE}>
                        <List.Item className={styles.SpaceListItem} onClick={this.onClickListItem(space.spaceId)}>
                            <List.Item.Meta
                                className={styles.SpaceListItemMeta}
                                avatar={<Avatar
                                    style={{
                                        backgroundColor: getAvatarBackgroundColor(
                                            space.spaceId, 75, 75
                                        )
                                    }}
                                >
                                    {this.retrieveAvatarName(space)}
                                </Avatar>}
                                title={<React.Fragment>
                                    {space.spaceName}
                                </React.Fragment>}
                                description={space.spaceId}
                            />
                        </List.Item>
                    </LinkableContent>
                )}
            />
        );
    }
}

export default SpaceList;
