import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { PublishSummaryProps, InjectedProps } from './PublishSummaryModel';

import styles from './PublishSummary.module.scss';
import { FormattedMessage } from 'react-intl';
import { Empty } from 'antd';

const totalReleaseItemsLabel = 'Total of {count, number} {count, plural, one {content} other {contents}}';
const totalSelectedReleaseItemsLabel = '{count, number} {count, plural, one {content} other {contents}} selected';
const noContentsSelected = `No contents selected`;

@inject('publishToLiveStore')
@observer
class PublishSummary extends Component<PublishSummaryProps> {
    get injected() {
        return this.props as InjectedProps;
    }
    render() {
        const { releaseItemsToPromote, releaseItems } = this.injected.publishToLiveStore;

        return (
            <div className={styles.PublishSummaryContainer}>
                {
                    releaseItems.length === 0 ? (
                        <Empty
                            description={
                                <FormattedMessage
                                    id="PublishSummary.emptyReleaseItems"
                                    defaultMessage="No contents to publish"
                                />
                            }
                        />
                    ) : (
                            <React.Fragment>
                                <div className={styles.PublishSummaryItem}>
                                    <FormattedMessage
                                        id="PublishSummary.totalReleaseItems"
                                        defaultMessage={totalReleaseItemsLabel}
                                        values={{ count: releaseItems.length }}
                                    />
                                </div>
                                {
                                    <div className={styles.PublishSummaryItem}>
                                        {releaseItemsToPromote.length > 0 ?
                                            (
                                                <FormattedMessage
                                                    id="PublishSummary.totalSelectedReleaseItems"
                                                    defaultMessage={totalSelectedReleaseItemsLabel}
                                                    values={{ count: releaseItemsToPromote.length }}
                                                />
                                            ) : (
                                                <FormattedMessage
                                                    id="PublishSummary.noContentsSelected"
                                                    defaultMessage={noContentsSelected}
                                                />
                                            )}
                                    </div>}
                            </React.Fragment>
                        )
                }
            </div>
        );
    }
}

export default PublishSummary;
