import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ContentDefinitionListItemActionsProps } from './ContentDefinitionListItemActionsModel';
import { createContentPath, editContentDefinitionPath } from '../../constants/routing-constants';
import { defineMessages } from 'react-intl';
import { withPermissionsToInteract } from '../../providers/UserPermissionsProvider';
import ActionList, { ActionDef } from '../atoms/ActionList';

const labels = defineMessages({
    select: {
        id: 'ContentDefinitionListItemActions.Select',
        defaultMessage: 'Select'
    },
    edit: {
        id: 'ContentDefinitionListItemActions.Edit',
        defaultMessage: 'Edit'
    },
    view: {
        id: 'ContentDefinitionListItemActions.View',
        defaultMessage: 'View'
    }
});

class ContentDefinitionListItemActions extends Component<ContentDefinitionListItemActionsProps, {}> {

    onEditClick = () => {
        const { match: { params: { spaceId } }, definitionId } = this.props;
        return editContentDefinitionPath(spaceId, definitionId);
    }

    onSelectClick = () => {
        const { match: { params: { spaceId } }, definitionId } = this.props;
        return createContentPath(spaceId, definitionId);
    }

    render() {
        const { contentCreationFlow, hasPermissions } = this.props;

        const selectAction = {
            linkHandler: this.onSelectClick,
            title: labels.select,
            icon: 'select',
            type: 'primary',
        } as ActionDef;

        const viewEditAction = {
            linkHandler: this.onEditClick,
            title: hasPermissions ? labels.edit : labels.view,
            icon: 'edit',
            type: 'primary',
        };

        const actions = [
            contentCreationFlow ? selectAction : viewEditAction,
        ] as ActionDef[];

        return (
            <ActionList actions={actions} />
        );
    }
}

export default withPermissionsToInteract(withRouter(ContentDefinitionListItemActions));
