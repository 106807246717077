import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    SubscriptionType
} from '@contentchef/contentchef-types';

export interface SubscriptionTypeNameProps {
    type: SubscriptionType;
}

export default function SubscriptionTypeName(props: SubscriptionTypeNameProps) {
    switch (props.type) {
        case SubscriptionType.Agency:
            return (
                <FormattedMessage
                    defaultMessage="Agency"
                    id="subscription.type.agency"
                />
            );
        case SubscriptionType.Enterprise:
            return (
                <FormattedMessage
                    defaultMessage="Enterprise"
                    id="subscription.type.enterprise"
                />
            );
        case SubscriptionType.Professional:
            return (
                <FormattedMessage
                    defaultMessage="Professional"
                    id="subscription.type.professional"
                />
            );
        case SubscriptionType.Starter:
            return (
                <FormattedMessage
                    defaultMessage="Starter"
                    id="subscription.type.starter"
                />
            );
        case SubscriptionType.Trial:
            return (
                <FormattedMessage
                    defaultMessage="Trial"
                    id="subscription.type.Trial"
                />
            );
        default:
            throw new Error('Invalid subscription type');
    }
}
