import * as React from 'react';
import { BaseRouting } from '../components';
import { withInitSettings } from '../hoc';
// DO NOT REMOVE, NEEDED TO OVERRIDE ANTD THEME
import '../theme/base.scss';
import rootStore, { history, apiStore, notificationHubStore, notificationStore } from '../stores';
import AppErrorBoundary from './AppErrorBoundary';
import { Locale } from 'antd/lib/locale-provider';
import { resolveI18nConf } from '@services/DynamicI18NImport';
import { addLocaleData, IntlProvider } from 'react-intl';
// import EyeletUser from './EyeletUser';
import withAuthentication from '@hoc/withAuthentication';
import { Router, Switch, Route } from 'react-router-dom';
import OnboardingHandler from './Onboarding/Handler';
import BaseLayout from './BaseLayout';
import { Provider } from 'mobx-react';
import withAuthenticationErrorHandling from '@hoc/withAuthenticationErrorHandling';
import { VERIFY_EMAIL_ROUTE } from '@constants/routing-constants';
import VerifyEmailLanding from '@scenes/Authorization/VerifyEmailLanding';
import { UserPermissionsProvider } from '@providers/UserPermissionsProvider';
import withUsersStores from '../hoc/withUsersStores';
import { Component } from 'react';
import { ConfigProvider } from 'antd';

interface SecuredAppProps { }
interface SecuredAppState { }

class App extends React.Component {
    render() {
        return (
            <AppErrorBoundary>
                <OnboardingHandler>
                    <BaseLayout>
                        <BaseRouting />
                    </BaseLayout>
                </OnboardingHandler>
            </AppErrorBoundary>
        );
    }
}

const InitializedApp = withInitSettings(withUsersStores(App));
const SecuredComponent = withAuthenticationErrorHandling(InitializedApp);

class Authenticated extends React.Component<SecuredAppProps, SecuredAppState> {
    public render() {
        return (
            <UserPermissionsProvider apiStore={apiStore}>
                <Provider
                    {...rootStore(apiStore)}
                    apiStore={apiStore}
                    notificationHubStore={notificationHubStore}
                    notificationStore={notificationStore}
                >
                    <Switch>
                        <Route
                            render={(rProps) => (
                                <BaseLayout>
                                    <VerifyEmailLanding {...rProps} />
                                </BaseLayout>
                            )}
                            exact={true}
                            path={VERIFY_EMAIL_ROUTE}
                        />
                        <Route>
                            <SecuredComponent />
                        </Route>
                    </Switch>
                </Provider>
            </UserPermissionsProvider>
        );
    }
}

const AuthenticatedApp = withAuthentication(() => <Authenticated />);

interface BaseAppState {
    renderComponent: boolean;
    antdLocale: Locale;
    intlMessages: object | undefined;
    language: string | undefined;
}

export default class BaseApp extends Component<{}, BaseAppState> {
    state: BaseAppState = {
        renderComponent: false,
        antdLocale: {} as Locale,
        intlMessages: undefined,
        language: undefined,
    };

    async componentDidMount() {
        // const localeString = (navigator.languages && navigator.languages[0]) || navigator.language;
        const i18NCacheItem = await resolveI18nConf('en');
        const { language, intlMessages, antdLocale = {} } = i18NCacheItem;
        addLocaleData(i18NCacheItem.intlLocaleData);
        this.setState({
            renderComponent: true,
            language, intlMessages: intlMessages,
            antdLocale: antdLocale as Locale,
        });
    }

    render() {
        const { renderComponent, language, antdLocale, intlMessages } = this.state;
        return (
            renderComponent && (
                <IntlProvider locale={language} messages={intlMessages}>
                    <ConfigProvider locale={antdLocale}>
                        <Router history={history}>
                            <AuthenticatedApp />
                        </Router>
                    </ConfigProvider>
                </IntlProvider>
            )
        );
    }
}
