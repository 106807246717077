import React, { Component } from 'react';
import { Layout } from 'antd';
import Menubar from '../../components/Menubar/Menubar';

import styles from './MenubarLayout.module.scss';

const { Content } = Layout;

interface MenubarLayoutProps { }
interface MenubarLayoutState { }

class MenubarLayout extends Component<MenubarLayoutProps, MenubarLayoutState> {
    render() {
        return (
            <Layout className={styles.MenubarLayoutContainer}>
                <Menubar />
                <Content className={styles.MenubarLayoutContent}>
                    {this.props.children}
                </Content>
            </Layout>
        );
    }
}

export default MenubarLayout;
