import { action, observable, runInAction, IObservableArray } from 'mobx';
import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import { ContentId } from '@contentchef/contentchef-types';
import {
    PublishingRequestResponse
} from '@contentchef/contentchef-types';

import { PublishingStoreModel } from './publishingStoreModel';
import { PUBLISHING_REQUESTS } from '../../constants/api-constants';

class PublishingStore implements PublishingStoreModel {
    pollingPublishingRequestsId: any = null;
    api: ContentChefClient;
    @observable isLoading = true;
    @observable publishingRequests: IObservableArray<PublishingRequestResponse> = observable.array([]);
    constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action
    async createPublishingRequest(contentId: ContentId, version: number) {
        const reqParams = {
            contentId,
            contentVersion: version
        };

        this.isLoading = true;

        try {
            const response = await this.api.publishingRequests.create(reqParams);
            runInAction(() => {
                this.publishingRequests.unshift(response);
                this.isLoading = false;
            });
        } catch (e) {
            console.log('Error in calling publishingRequests.create');
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    @action
    async setListPublishingRequest(contentId: ContentId, version: number) {
        const reqParams = {
            contentId,
            contentVersion: version
        };
        this.isLoading = true;

        try {
            const response = await this.api.publishingRequests.list(reqParams);
            runInAction(() => {
                this.publishingRequests.replace(response);
                this.isLoading = false;
            });
        } catch (e) {
            console.log('Error in calling publishingRequests.list');
            runInAction(() => {
                this.isLoading = false;
            });
        }

    }

    @action
    startPollingPublishingRequests(contentId: ContentId, version: number) {
        if (!this.pollingPublishingRequestsId) {
            this.publishingRequests.clear();
            this.setListPublishingRequest(contentId, version);
            this.pollingPublishingRequestsId = setInterval(
                () => this.setListPublishingRequest(contentId, version),
                PUBLISHING_REQUESTS.POLLING_INTERVAL
            );
        }
    }

    clearPollingPublishingRequests() {
        if (this.pollingPublishingRequestsId) {
            clearInterval(this.pollingPublishingRequestsId);
            this.pollingPublishingRequestsId = null;
        }
    }

}

export default PublishingStore;
