import { defineMessages } from 'react-intl';

export const labels = defineMessages({
    0: {
        id: 'contentTranslationLabels.Uptodate',
        defaultMessage: 'Up to date'
    },
    1: {
        id: 'contentTranslationLabels.toVerify',
        defaultMessage: 'To verify'
    },
    2: {
        id: 'contentTranslationLabels.incomplete',
        defaultMessage: 'Not complete'
    },
    3: {
        id: 'contentTranslationLabels.missing',
        defaultMessage: 'Missing'
    },
    formSidebarHeader: {
        id: 'contentTranslationLabels.formSidebarHeader',
        defaultMessage: 'translations'
    },
    locale: {
        id: 'contentTranslationLabels.locale',
        defaultMessage: 'Original'
    },
    blockRouteChange: {
        id: 'contentTranslationLabels.blockRouteChange',
        defaultMessage: 'All unsaved changes will be lost. Do you wish to proceed?',
    },
    enable: {
        id: 'contentTranslationLabels.enable',
        defaultMessage: 'Enable'
    },
    disable: {
        id: 'contentTranslationLabels.disable',
        defaultMessage: 'Remove'
    },
    confirmClean: {
        id: 'contentTranslationLabels.confirmClean',
        defaultMessage: `This translation will be cleaned but still be active. Proceed?`
    },
    confirmCleanMultiple: {
        id: 'contentTranslationLabels.confirmCleanMultiple',
        defaultMessage: `Selected translations will be cleaned but still be active. Proceed?`
    },
    confirmAuto: {
        id: 'contentTranslationLabels.confirmAuto',
        defaultMessage: 'Translations will be enabled in all configured locales. Proceed?'
    },
    confirmManual: {
        id: 'contentTranslationLabels.confirmManual',
        defaultMessage: `Translations will be enabled in selected locales. Proceed?`
    },
    automatic: {
        id: 'contentTranslationLabels.automatic',
        defaultMessage: 'all'
    },
    manual: {
        id: 'contentTranslationLabels.manual',
        defaultMessage: 'selected'
    },
    cleanLabel: {
        id: 'contentTranslationLabels.cleanLabel',
        defaultMessage: 'Clean'
    }
});
