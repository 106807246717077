import React from 'react';
import classes from './index.module.scss';
import classNames from 'classnames';
import Typography from '../Typography';

export interface SettingsContentHeaderProps {
    children?: React.ReactNode;
    gutter?: boolean;
    title?: React.ReactNode;
}

export default function SettingsContentHeader(props: SettingsContentHeaderProps) {
    const className = classNames(classes.SettingsContentHeader, {
        [classes.SettingsContentHeaderWithGutter]: props.gutter,
    });

    return (
        <div className={className}>
            <Typography gutter={false} variant="h6">
                {props.title}
            </Typography>
            <div>
                {
                    props.children
                }
            </div>
        </div>
    );
}

SettingsContentHeader.defaultProps = {
    gutter: false,
} as Partial<SettingsContentHeaderProps>;
