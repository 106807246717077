import React, { Component } from 'react';
import { Image } from 'cloudinary-react';
import { CloudinaryImageCardProps } from './CloudinaryImageCardModel';

import styles from './CloudinaryImageCard.module.scss';

class CloudinaryImageCard extends Component<CloudinaryImageCardProps> {

    constructor(props: CloudinaryImageCardProps) {
        super(props);

        this.onClickImage = this.onClickImage.bind(this);
    }

    onClickImage() {
        const { publicId } = this.props;
        this.props.onClickImageCard(publicId);
    }
    render() {
        return (
            <div className={styles.CloudinaryImageCard} onClick={this.onClickImage}>
                <Image
                    cloudName="leroymerlin"
                    publicId={this.props.publicId}
                />
            </div>
        );
    }
}

export default CloudinaryImageCard;
