import Button from '@components/Button';
import { languageByLocale } from '@services/languages';
import { Divider, Input, Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import React, { ChangeEvent, useState } from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';

const Option = Select.Option;

interface LocalePickerProps {
    multiselection?: boolean;
    // from intl
    locale: string;

    disabled?: boolean;
    value?: string | null;
    localeOptions?: string[];
    size?: SelectProps['size'];
    minWidth?: number | string;
    hideFooter?: boolean;
    onChange?(value: any): void;
}

interface InjectedProps extends LocalePickerProps, InjectedIntlProps {
    value: string;
    onChange(value: any): void;
}

const labels = defineMessages({
    placeholder: {
        id: 'localePicker.placeholder',
        defaultMessage: 'Add custom locale',
    },
});

const AddCustomLocale = injectIntl((props: InjectedIntlProps & { onClickAdd: (value: string) => any; }) => {
    const [custom, setCustom] = useState('');

    const placeholderLabel = props.intl.formatMessage(labels.placeholder);

    function customOnChange(event: ChangeEvent<HTMLInputElement>) {
        const value = event.target.value.trim();
        setCustom(value);
    }

    function onConfirm() {
        const trimmedCustomLocale = custom.trim();
        if (trimmedCustomLocale.length > 0) {
            props.onClickAdd(trimmedCustomLocale);
            setCustom('');
        }
    }

    return (
        <React.Fragment>
            <Input
                size="small"
                value={custom}
                onChange={customOnChange}
                placeholder={placeholderLabel}
            />
            <Button
                style={{
                    marginLeft: 5
                }}
                size="small"
                icon="plus"
                type="primary"
                onClick={onConfirm}
            />
        </React.Fragment>
    );
});

function getLangs(allLangs: { code: string, language: string, withoutRegion: boolean }[], locales: string[]) {
    return locales.map(locale => {
        const existingLang = allLangs.find(lang => lang.code === locale);
        return existingLang ? existingLang : { code: locale, language: locale, withoutRegion: false };
    });
}

function LocalePicker(props: LocalePickerProps) {
    const [langs] = useState(() => {
        const allLangs = languageByLocale.retrieveList();
        return props.localeOptions ? getLangs(allLangs, props.localeOptions) : allLangs;
    });
    const injected = props as InjectedProps;
    const [open, setOpen] = useState(false);
    const [lock, setLock] = useState(false);

    function onChange(valueToChange: string) {
        injected.onChange(valueToChange);
    }

    const onDropdownVisibleChange = (value: boolean) => {
        if (lock) {
            return;
        }
        setOpen(value);
    };

    function onClickCustomButton(value: string) {
        onChange(value);
        setLock(false);
        setOpen(false);
    }

    function onBlur() {
        if(!lock) {
            setLock(false);
            setOpen(false);
        }
    }

    return (
        <Select
            disabled={props.disabled}
            defaultValue={injected.value}
            value={injected.value}
            onChange={onChange}
            size={props.size}
            showSearch={true}
            onDropdownVisibleChange={onDropdownVisibleChange}
            open={open}
            filterOption={(input, option) => {
                return option.props.label!.toString().toLowerCase().includes(input.toLowerCase());
            }}
            style={{ minWidth: props.minWidth }}
            dropdownRender={!props.hideFooter ? menu => (
                <div onBlur={onBlur}>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div
                        style={{ padding: '4px 10px', display: 'flex' }}
                        onMouseDown={() => setLock(true)}
                        onMouseUp={() => setLock(true)}
                        onMouseLeave={() => setLock(false)}
                    >
                        <AddCustomLocale
                            onClickAdd={onClickCustomButton}
                        />
                    </div>
                </div>
            ) : undefined}
        >
            {langs.map(lang => {
                return (
                    <Option
                        key={lang.code}
                        value={lang.code}
                        label={languageByLocale.labelByLocale(lang.code)}
                    >
                        {languageByLocale.labelByLocale(lang.code)}
                    </Option>
                );
            })}
        </Select>
    );
}

export default injectIntl(LocalePicker);

export function LocalePickerWithoutFooter(props: LocalePickerProps) {
    return <LocalePicker {...props} hideFooter={true} />;
}
