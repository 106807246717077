import { FieldEditTabProps } from '../../types';
import ListOfValues from '../Constraints/ListOfValues';
import React from 'react';
import { TextConstraints } from '../../FieldSerializer/fields/text';
import { listOfValuesMappedErrors } from '../../../../services/FormUtils/DefinitionFieldMappedErrors';

function ListOfTextValuesDetails(props: FieldEditTabProps<TextConstraints>) {
    if (!props.constraints.listOfValues) {
        return null;
    }
    return (
        <ListOfValues
            relatedTabKey={'detailsTab'}
            mappedErrors={listOfValuesMappedErrors}
            type={'string'}
            defaultLang={props.defaultLang}
            onAdd={() => props.fieldEditorStore.createI18nListOfValueItem()}
            onAddItem={(valueIndex: number, culture: string) =>
                props.fieldEditorStore.createI18nItemForListOfValue(valueIndex, culture)
            }
            onChangeItemLabel={(culture: string, valueIndex: number, label: string) =>
                props.fieldEditorStore.updateI18nItemForListOfValue(valueIndex, culture, label)
            }
            onChangeItemValue={(valueIndex: number, itemValue: string) =>
                props.fieldEditorStore.updateI18nListOfValuesValue(valueIndex, itemValue)
            }
            onRemove={valueIndex => props.fieldEditorStore.removeI18nListOfValuesValue(valueIndex)}
            value={props.constraints.listOfValues}
        />
    );
}

export default ListOfTextValuesDetails;
