import React from 'react';
import { copyToClipboard } from '../../services/utils';
import { notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import classes from './index.module.scss';

interface CopyToClipBoardProps {
    children: string;
}

export function CopyToClipBoard(props: CopyToClipBoardProps) {
    function onClick() {
        copyToClipboard('copyToClipBoard', props.children);
        notification.info({
            message: (
                <FormattedMessage
                    id="CopyToClipBoard.success"
                    defaultMessage="Copied {value} to clipboard"
                    values={{ value: props.children }}
                />
            )
        });
    }
    return (
        <span onClick={onClick} className={classes.CopyToClipBoardLabelContainer} style={{ cursor: 'pointer' }}>
            {props.children}
        </span>
    );
}
