import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import { action, runInAction, observable } from 'mobx';

export default class UserManagementStore {
    api: ContentChefClient;
    @observable loaders: { [key: string]: boolean } = {
        validatingEmail: true,
        requestingVerification: false
    };
    @observable emailValid: boolean = false;
    @observable requestVerificationSuccess: boolean = false;
    constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action
    async verifyEmail(token: string): Promise<void> {
        this.emailValid = false;
        this.loaders.validatingEmail = true;
        try {
            const result = await this.api.users.verifyEmail({ token });
            runInAction(() => {
                this.emailValid = result;
                this.loaders.validatingEmail = false;
            });
        } catch (error) {
            runInAction(() => {
                this.emailValid = false;
                this.loaders.validatingEmail = false;
            });
            console.log(error);
        }
    }

    @action
    async removeCurrentUser(): Promise<boolean> {
        try {
            return await this.api.users.removeCurrentUser({});
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    @action
    async requestEmailVerification(): Promise<void> {
        this.loaders.requestingVerification = true;
        this.requestVerificationSuccess = false;
        try {
            const result = await this.api.users.requestEmailVerification({});
            runInAction(() => {
                this.requestVerificationSuccess = result;
                this.loaders.requestingVerification = false;
            });
        } catch (error) {
            runInAction(() => {
                this.requestVerificationSuccess = false;
                this.loaders.requestingVerification = false;
            });
            console.log(error);
        }
    }

}
