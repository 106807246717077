import Button from '@components/Button';
import MediaListModal from '@components/MediaListModal';
import { userPermissionsTable } from '@constants/user-permissions-table';
import { HasPermissions } from '@providers/UserPermissionsProvider';
import developerMode from '@services/devMode';
import { MediaAdapter } from '@services/Media/MediaConverterClass';
import { createCustomNotification } from '@services/Notification';
import { inject, observer } from 'mobx-react';
import React, { ChangeEvent, Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import FloatingInput from '../FloatingInput/FloatingInput';
import styles from './MediaActions.module.scss';
import { InjectedProps, MediaActionsProps, MediaActionsState } from './MediaActionsModel';

const mediaActionsLabels = defineMessages({
    notificationMessage: {
        id: 'MediaActions.notificationMessage',
        defaultMessage: 'Create new folder'
    },
    modalInputPlaceholder: {
        id: 'MediaActions.ModalInputPlaceholder',
        defaultMessage: 'folder_1/sub_folder-2/sub folder3'
    },
    maxLengthValidation: {
        id: 'MediaActions.MaxLengthValidation',
        defaultMessage: 'Value must be max {value} characters.'
    },
    minLengthValidation: {
        id: 'MediaActions.MinLengthValidation',
        defaultMessage: 'Value must be at least {value} characters.'
    },
    patternValidation: {
        id: 'MediaActions.PatternValidation',
        defaultMessage: 'Value accept letters, numbers, _, - and spaces, use / to include nested folders.'
    },
    createNewFolder: {
        id: 'mediagallery.addFolder',
        defaultMessage: 'Create new folder'
    },
    createNewMediaAsset: {
        id: 'mediagallery.upload',
        defaultMessage: 'Add new media asset'
    },
    folderAction: {
        id: 'mediagallery.toggleFolderVisibility',
        defaultMessage: 'Show/Hide folders'
    },
    deleteMedia: {
        id: 'mediagallery.delete-media',
        defaultMessage: 'Delete selected media'
    },
    infoDeleteMedia: {
        id: 'mediagallery.delete-media.info',
        defaultMessage: 'Attempting to delete selected media. May take a while.'
    },
    successDelete: {
        id: 'mediagallery.delete-media.success',
        defaultMessage: 'Successfully removed selected media. May need to refresh the page to see the changes.'
    },
    errorDelete: {
        id: 'mediagallery.delete-media.error',
        defaultMessage: `
            Error removing selected media. Please try again. If the problem persists, please contact support.
        `
    }
});

@inject('mediaGalleryFlowStore', 'notificationStore')
@observer
class MediaActions extends Component<MediaActionsProps, MediaActionsState> {
    MEDIA_DELETE_INFO_NOTIFICATION = 'media-delete-info-notification';
    state = {
        folderName: '',
        showModal: false,
        hideActions: false,
        showDeleteModal: false
    };

    get injected() {
        return this.props as InjectedProps;
    }

    onChangeFolderName = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        this.setState({
            folderName: value
        });
    }

    onConfirmFolderName = (folder: string) => {
        const { nextStep } = this.props;
        this.injected.mediaGalleryFlowStore.setFolderName(folder.trim());
        nextStep();
    }

    openFolderNotification = () => {
        this.setState({
            hideActions: true,
            showModal: true
        });
    }

    onCloseNotification = () => {
        this.setState({
            hideActions: false,
            showModal: false
        });
    }

    toggleFolders = () => {
        this.injected.mediaGalleryFlowStore.toggleIncludeFolders();
    }

    inDeveloperMode = () => {
        return developerMode();
    }

    deleteSelectedMedia = async () => {
        const { formatMessage } = this.injected.intl;
        createCustomNotification(
            formatMessage,
            mediaActionsLabels.infoDeleteMedia,
            'info',
            0,
            this.MEDIA_DELETE_INFO_NOTIFICATION
        );
        this.closeDeleteModal();
        try {
            await this.injected.mediaGalleryFlowStore.deleteSelectedMedia();
            createCustomNotification(
                formatMessage,
                mediaActionsLabels.successDelete,
                'success',
                5,
            );
            this.injected.notificationStore.closeNotification(this.MEDIA_DELETE_INFO_NOTIFICATION);
        } catch (error) {
            createCustomNotification(
                formatMessage,
                mediaActionsLabels.errorDelete,
                'error',
                5,
            );
        } finally {
            this.injected.notificationStore.closeNotification(this.MEDIA_DELETE_INFO_NOTIFICATION);
        }
    }

    closeDeleteModal = () => {
        this.setState({
            showDeleteModal: false
        });
    }

    showDeleteModal = () => {
        this.setState({
            showDeleteModal: true
        });
    }

    onRestoreItem = (media: MediaAdapter) => {
        this.injected.mediaGalleryFlowStore.removeMediaFromDeletion(media);
        if (this.injected.mediaGalleryFlowStore.selectedMediaToDelete.length === 0) {
            this.closeDeleteModal();
        }
    }

    render() {
        const { nextStep } = this.props;
        const { includeFolders, selectedMediaToDelete } = this.injected.mediaGalleryFlowStore;
        const { formatMessage } = this.props.intl;
        return (
            <React.Fragment>
                {this.state.hideActions ? null : (
                    <div className={styles.MediaActionsContainer}>
                        {this.inDeveloperMode() && selectedMediaToDelete.length > 0 && (
                            <React.Fragment>
                                <HasPermissions permissions={userPermissionsTable.MediaActions.deletePermissions}>
                                    <Button
                                        id="media-delete"
                                        className={styles.MediaActionsButton}
                                        type="danger"
                                        shape="circle"
                                        icon="close"
                                        size="large"
                                        title={formatMessage(mediaActionsLabels.deleteMedia)}
                                        onClick={this.showDeleteModal}
                                    />
                                </HasPermissions>
                                <MediaListModal
                                    list={selectedMediaToDelete.toJS()}
                                    modalTitle={formatMessage(mediaActionsLabels.deleteMedia)}
                                    visible={this.state.showDeleteModal}
                                    onCancel={this.closeDeleteModal}
                                    onConfirm={this.deleteSelectedMedia}
                                    onRestoreItem={this.onRestoreItem}
                                />
                            </React.Fragment>
                        )}
                        <Button
                            className={
                                `${styles.MediaActionsButton} 
                                ${includeFolders ? '' : styles.MediaActionsButtonToggled}`}
                            id="toggle-folders"
                            type="primary"
                            onClick={this.toggleFolders}
                            icon={includeFolders ? 'eye' : 'eye-invisible'}
                            title={formatMessage(mediaActionsLabels.folderAction)}
                        />
                        <HasPermissions permissions={userPermissionsTable.MediaActions.uploadPermissions}>
                            <Button
                                id="media-upload"
                                className={styles.MediaActionsButton}
                                type="primary"
                                shape="circle"
                                icon="upload"
                                size="large"
                                title={formatMessage(mediaActionsLabels.createNewMediaAsset)}
                                onClick={nextStep}
                            />
                        </HasPermissions>
                        <HasPermissions permissions={userPermissionsTable.MediaActions.addFolderPermissions}>
                            <Button
                                className={styles.MediaActionsButton}
                                type="primary"
                                shape="circle"
                                icon="folder-add"
                                size="large"
                                title={formatMessage(mediaActionsLabels.createNewFolder)}
                                onClick={this.openFolderNotification}
                            />
                        </HasPermissions>
                    </div>
                )}
                <FloatingInput
                    visible={this.state.showModal}
                    modalTitle={formatMessage(mediaActionsLabels.notificationMessage)}
                    placeholder={formatMessage(mediaActionsLabels.modalInputPlaceholder)}
                    patternValidation={{
                        pattern: /^[A-Za-z0-9_\- ]+(\/[A-Za-z0-9_\- ]+)*$/g,
                        message: formatMessage(mediaActionsLabels.patternValidation)
                    }}
                    maxLengthValidation={{
                        max: 100,
                        message: formatMessage(mediaActionsLabels.maxLengthValidation, { value: 100 })
                    }}
                    minLengthValidation={{
                        min: 1,
                        message: formatMessage(mediaActionsLabels.minLengthValidation, { value: 1 })
                    }}
                    onConfirm={this.onConfirmFolderName}
                    onCancel={this.onCloseNotification}
                />
            </React.Fragment>
        );
    }
}

export default injectIntl(MediaActions);
