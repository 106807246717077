import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { ConfigProvider, Empty, Modal, Pagination } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { InjectedProps, LinkedContentProps, LinkedContentState } from './LinkedContentModel';
import { ContentCard, LinkedContentPreview, Loader, SearchFilterGroup } from '../index';
import { LinkableContentsFilters } from '../../stores/contentListStore/contentListStoreModel';
import { LoaderManagerStoreModel } from '../../stores/loaderManagerStore/loaderManagerStoreModel';
import LoaderManagerStore from '../../stores/loaderManagerStore/loaderManagerStore';
import { ListContentsItem } from '@contentchef/contentchef-types';
import { DataAttributes, DataAttributesBuilder } from '../../services/DataAttributesBuilder';
import { withLinkedFieldStores } from '../../hoc';
import { SchemaTypeIds } from '@contentchef/contentchef-types';

import styles from './LinkedContent.module.scss';
import { withRouter } from 'react-router';
import { editContentPath } from '../../constants/routing-constants';

const DEFAULT_TAKE: number = 50;
const DEFAULT_SKIP: number = 0;
const DEFAULT_TAGS_FILTER: string[] = [];
const DEFAULT_NAME_FILTER: string = '';

@inject('contentListStore', 'contentListFiltersStore')
@observer
class LinkedContent extends Component<LinkedContentProps, LinkedContentState> {
    loaderManagerStore: LoaderManagerStoreModel;

    state: LinkedContentState = {
        showLinkableModal: false,
        skipMultiplier: DEFAULT_SKIP,
        filterByTags: DEFAULT_TAGS_FILTER,
        filterByName: DEFAULT_NAME_FILTER
    };

    constructor(props: LinkedContentProps) {
        super(props);

        this.loaderManagerStore = new LoaderManagerStore();
    }

    get injected() {
        return this.props as InjectedProps;
    }

    createSearchDataAttributes = (): DataAttributes => {
        return new DataAttributesBuilder('linked-content-field')
            .setSearchAttribute('search-linkable-contents')
            .build();
    }

    onSelectContent = (content: ListContentsItem) => {
        this.props.linkedField.setFieldValue(content.publicId);
        this.props.onChange(content.publicId);
        this.setState({ showLinkableModal: false, skipMultiplier: DEFAULT_SKIP });
    }

    async setLinkableContents() {
        let definitionMnemonicIdArray: string[] | undefined;
        let contentDefinitionIdArray: number[] | undefined;
        if (this.props.linkedField.type === SchemaTypeIds.LINKED_CONTENT) {
            const { definitionMnemonicId, contentDefinitionId } = this.props.linkedField.constraints;
            if (definitionMnemonicId) {
                definitionMnemonicIdArray = definitionMnemonicId ? [definitionMnemonicId] : undefined;
            } else {
                contentDefinitionIdArray = contentDefinitionId ? [contentDefinitionId] : undefined;
            }
        } else {
            const { definitionMnemonicIds, contentDefinitionIds } = this.props.linkedField.constraints;
            if (definitionMnemonicIds) {
                definitionMnemonicIdArray = definitionMnemonicIds;
            } else {
                contentDefinitionIdArray = contentDefinitionIds;
            }
        }
        const filters: LinkableContentsFilters = {
            name: this.state.filterByName.length > 0 ? this.state.filterByName : undefined,
            tags: this.state.filterByTags.length > 0 ? this.state.filterByTags : undefined,
            definitionMnemonicIds: definitionMnemonicIdArray,
            contentDefinitionIds: contentDefinitionIdArray
        };
        this.loaderManagerStore.setLoader();
        await this.injected.contentListStore.setLinkableContents(
            filters, this.state.skipMultiplier * DEFAULT_TAKE, DEFAULT_TAKE
        );
        this.loaderManagerStore.unsetLoader();
    }

    onOpenChooseLinkableContentModal = async () => {
        this.setState({ showLinkableModal: true });
        await this.setLinkableContents();
        await Promise.all([
            this.injected.contentListFiltersStore.setContentDefinitionOptions(),
            this.injected.contentListFiltersStore.setSuggestedTags()
        ]);
    }

    onCloseChooseLinkableContentModal = () => {
        this.setState({ showLinkableModal: false });
        this.onResetFilters();
    }

    onChangePage = async (page: number) => {
        await this.setState({ skipMultiplier: page - 1 });
        this.setLinkableContents();
    }

    onChangeSearch = async (value: string) => {
        await this.setState({ filterByName: value, skipMultiplier: DEFAULT_SKIP });
        this.setLinkableContents();
    }

    onChangeSelectedTags = async (value: string[]) => {
        await this.setState({ filterByTags: value, skipMultiplier: DEFAULT_SKIP });
        this.setLinkableContents();
        this.injected.contentListFiltersStore.setSuggestedTags();
    }

    onSearchPreferredTags = (value: string) => {
        this.injected.contentListFiltersStore.setSuggestedTags(value);
    }

    onResetFilters = async () => {
        await this.setState({
            skipMultiplier: DEFAULT_SKIP,
            filterByTags: DEFAULT_TAGS_FILTER,
            filterByName: DEFAULT_NAME_FILTER
        });
        this.setLinkableContents();
    }

    onLinkedContentClick = (content: ListContentsItem) => {
        const { match: { params: { spaceId } } } = this.props;
        const link = document.createElement('a');
        link.setAttribute('href', editContentPath(spaceId, content.id));
        link.setAttribute('target', '_blank');
        link.click();
    }

    render() {
        const { linkedField, disableActions, id } = this.props;
        const { linkableContents } = this.injected.contentListStore;
        const { suggestedTags } = this.injected.contentListFiltersStore;
        return (
            <div
                id={id}
                className={styles.LinkedContentWrapper}
            >
                <LinkedContentPreview
                    disableActions={disableActions}
                    linkedField={linkedField}
                    openModalCallback={this.onOpenChooseLinkableContentModal}
                    onLinkedContentClick={this.onLinkedContentClick}
                />
                <Modal
                    footer={null}
                    centered={true}
                    onCancel={this.onCloseChooseLinkableContentModal}
                    visible={this.state.showLinkableModal}
                    wrapClassName={styles.LinkedContentModalWrapper}

                >
                    <div className={styles.LinkedContentModalBodyWrapper}>
                        <ConfigProvider
                            getPopupContainer={() => document.getElementById('linkable-modal') as HTMLDivElement}
                        >
                            <div id="linkable-modal" className={styles.LinkedContentModalFiltersWrapper}>
                                <SearchFilterGroup
                                    id="linkable-contents-search"
                                    dataAttributes={this.createSearchDataAttributes}
                                    activeFilters={2}
                                    filterByNameArgs={{
                                        defaultValue: (this.state.filterByName),
                                        onSearchByName: this.onChangeSearch
                                    }}
                                    filterByTagsArgs={{
                                        listSuggestedTags: suggestedTags,
                                        defaultValue: (this.state.filterByTags),
                                        onChange: this.onChangeSelectedTags,
                                        onSearch: this.onSearchPreferredTags
                                    }}
                                    onResetAllFilters={this.onResetFilters}
                                />
                            </div>
                        </ConfigProvider>
                        <div className={styles.LinkedContentModalContentsWrapper}>
                            {this.loaderManagerStore.mustShowLoader
                                ? <Loader />
                                : linkableContents.total > 0
                                    ? linkableContents.items.map(content => <ContentCard
                                        key={content.id}
                                        content={content}
                                        onCardClick={this.onSelectContent}
                                    />)
                                    : <div className={styles.LinkedContentModalNotContentsFoundWrapper}>
                                        <Empty
                                            description={
                                                <FormattedMessage
                                                    id="LinkedContent.NoContentsFound"
                                                    defaultMessage="Contents not found"
                                                />
                                            }
                                        />
                                    </div>
                            }
                        </div>
                        <div className={styles.LinkedContentModalPaginationWrapper}>
                            <Pagination
                                current={(this.state.skipMultiplier + 1)}
                                onChange={this.onChangePage}
                                pageSize={DEFAULT_TAKE}
                                total={linkableContents.total}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default withLinkedFieldStores(withRouter(injectIntl(LinkedContent)));
