import React, { Component } from 'react';
import { WithStoresComponentProps, WithStoresWrappedComponent } from '../../constants/common-types';
import { inject, Provider } from 'mobx-react';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';
import { PaymentStore } from '../../stores';

type WithPaymentStoresInjectedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
};

const withPaymentStores = <T extends {}>(WrappedComponent: WithStoresWrappedComponent<T>) => {
    @inject('apiStore')
    class WithPaymentStores extends Component<WithStoresComponentProps<T>> {
        get injected() {
            return this.props as WithPaymentStoresInjectedProps<T>;
        }
        paymentStore: PaymentStore;

        constructor(props: WithStoresComponentProps<T>) {
            super(props);
            const { api } = this.injected.apiStore;
            this.paymentStore = new PaymentStore(api);
        }

        render() {
            return (
                <Provider
                    paymentStore={this.paymentStore}
                >
                    <WrappedComponent {...this.props} />
                </Provider>
            );
        }
    }

    return WithPaymentStores;
};

export default withPaymentStores;
