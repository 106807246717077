import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { INVITES_ROUTE } from '../../constants/routing-constants';
import withInvites from '../../hoc/withInvites';
import Invites from './Invites';

interface SettingsInvitesRouterProps { }

interface SettingsInvitesRouterState { }

class SettingsInvitesRouter extends Component<SettingsInvitesRouterProps, SettingsInvitesRouterState> {
    render() {
        return (
            <Switch>
                <Route
                    exact={true}
                    path={INVITES_ROUTE}
                    component={Invites}
                />
            </Switch>
        );
    }
}

export default withInvites(SettingsInvitesRouter);
