import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon } from 'antd';
import MenuLinkProps from './MenuLinkModel';
import { withPermissionsToInteract } from '../../providers/UserPermissionsProvider';

const MenuItem = Menu.Item;

class MenuLink extends Component<MenuLinkProps> {
    render() {
        const { id, to, title, icon, iconComponent, hasPermissions, ...others } = this.props;
        return (
            hasPermissions && (
                <MenuItem {...{ id }} {...others}>
                    {icon && <Icon type={icon} />}
                    {iconComponent && <Icon component={iconComponent} />}

                    {title && <span>{title}</span>}
                    <Link to={to} />
                </MenuItem>
            )
        );
    }
}

export default withPermissionsToInteract(MenuLink);

export const PermissionLessMenuLink = MenuLink;
