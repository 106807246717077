import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import { DeleteMediaGalleryStoreModel } from './deleteMediaGalleryStoreModel';
import { MediaGalleryId } from '@contentchef/contentchef-types';
import { routerStoreHelper } from '..';

class DeleteMediaGalleryStore implements DeleteMediaGalleryStoreModel {
    api: ContentChefClient;

    constructor(api: ContentChefClient) {
        this.api = api;
    }

    async delete(mediaGalleryId: MediaGalleryId) {
        try {
            const response = await this.api.mediaGallery.delete({ id: mediaGalleryId });
            if (response) {
                return routerStoreHelper.onSuccessfulDeleteMediaGallery(await this.api.spaceId);
            }
        } catch (e) {
            return Promise.reject();
        }
    }
}

export default DeleteMediaGalleryStore;
