import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Col, Icon, List } from 'antd';
import { RepositoryListItemProp, RepositoryListItemState } from './RepositoryListItemModel';
import { RepositoryListItemActions } from '..';
import { LinkableContent } from '../../hoc';
import { contentsBasePath } from '../../constants/routing-constants';
import { ContentListUrlParams } from '../../stores/contentListStore/contentListStoreModel';
import { ManageFieldProvider } from '../../providers/UserPermissionsProvider';
import { userPermissionsTable } from '../../constants/user-permissions-table';

import styles from './RepositoryListItem.module.scss';
import TextEllipsis from '../TextEllipsis';

const ListItem = List.Item;

class RepositoryListItem extends Component<RepositoryListItemProp, RepositoryListItemState> {
    onViewContents = () => {
        const { repository: { id: repositoryId, name: repositoryName } } = this.props;
        const { spaceId } = this.props.match.params;
        return {
            pathname: contentsBasePath(spaceId),
            search: `?${ContentListUrlParams.repositoryId}=${repositoryId}|${repositoryName}`
        };
    }

    render() {
        const { repository } = this.props;
        return (
            <ListItem
                id={repository.id.toString()}
            >
                <LinkableContent to={this.onViewContents()}>
                    <div
                        className={styles.RepositoryListItemContainer}
                        style={{ borderLeftColor: repository.color }}
                    >
                        <Col xs={19} sm={20} md={21} className={styles.RepositoryListItemDataContainer}>
                            <Icon type="appstore" className={styles.RepositoryListItemIcons} />
                            <Col xs={23} style={{ paddingBottom: 7 }}>
                                <Col xs={24}>
                                    <div>
                                        <Col xs={24}>
                                            <TextEllipsis
                                                title={repository.name}
                                                className={styles.RepositoryListItemNameContainer}
                                            >
                                                {repository.name}
                                            </TextEllipsis>
                                        </Col>
                                        <Col xs={24}>
                                            <TextEllipsis
                                                title={repository.mnemonicId}
                                                className={styles.RepositoryListItemMnemonicIdContainer}
                                            >
                                                {repository.mnemonicId}
                                            </TextEllipsis>
                                        </Col>
                                    </div>
                                </Col>
                                <Col xs={24}>
                                    <TextEllipsis
                                        title={repository.description}
                                        className={styles.RepositoryListItemDescriptionContainer}
                                    >
                                        {repository.description}
                                    </TextEllipsis>
                                </Col>
                            </Col>
                        </Col>
                        <Col xs={5} sm={4} md={3} className={styles.RepositoryListItemActionsContainer}>
                            <ManageFieldProvider
                                permissions={userPermissionsTable.ListItemActions.RepositoryListItemActions}
                            >
                                <RepositoryListItemActions
                                    repositoryId={repository.id}
                                    repositoryName={repository.name}
                                />
                            </ManageFieldProvider>
                        </Col>
                    </div>
                </LinkableContent>
            </ListItem>
        );
    }
}

export default withRouter(RepositoryListItem);
