import React from 'react';
import { syncHistoryWithStore, RouterStore } from 'mobx-react-router';
import { createBrowserHistory } from 'history';

import RootStore from './rootStore/rootStore';
import RouterStoreHelper from './routerStoreHelper/routerStoreHelper';
import { ApiStoreModel } from './apiStore/apiStore';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import Typography from '../components/Typography';

const routerStore = new RouterStore();
const browserHistory = createBrowserHistory({
    getUserConfirmation: (message, callback) => {
        Modal.confirm({
            centered: true,
            title: (
                <Typography variant={'h6'} gutter={false}>
                    <FormattedMessage id="onChangePage.modal.title" defaultMessage="Warning" />
                </Typography>
            ),
            content: (
                <Typography variant={'caption'}>
                    {message}
                </Typography>
            ),
            onOk: () => callback(true),
            onCancel: () => callback(false),
        });
    }
});
const history = syncHistoryWithStore(browserHistory, routerStore);
const routerStoreHelper = new RouterStoreHelper(routerStore);

const rootStore = (apiStore: ApiStoreModel) => {
    return new RootStore(routerStore, apiStore.api);
};

export default rootStore;
export { history };
export { routerStoreHelper };
export { default as apiStore } from './apiStore/apiStore';
export { default as notificationHubStore } from './notificationHubStore';
export { default as UserPermissionStore } from './userPermissionsStore';
export { default as navStore } from './navStore/navStore';
export { default as ChannelsStore } from './channelsStore/channelsStore';
export { default as formMetaStore } from './formMetaStore/formMetaStore';
export { default as ContentDefinitionListStore } from './contentDefinitionListStore/contentDefinitionListStore';
export { default as ContentDefinitionStore } from './contentDefinitionStore/contentDefinitionStore';
export { default as ContentListStore } from './contentListStore/contentListStore';
export { default as ContentStore } from './contentStore/contentStore';
export { default as ListPublicIdStore } from './listPublicIdStore/listPublicIdStore';
export { default as AddContentStore } from './addContentStore/addContentStore';
export { default as EditContentStore } from './editContentStore/editContentStore';
export { default as RepositoryListStore } from './repositoryListStore/repositoryListStore';
export { default as RepositoryDashboardStore } from './repositoryDashboardStore/repositoryDashboardStore';
export { default as PublishingStore } from './publishingStore/publishingStore';
export { default as LoaderManagerStore } from './loaderManagerStore/loaderManagerStore';
export { default as notificationStore } from './notificationStore/notificationStore';
export { default as MediaGalleryFlowStore } from './mediaGalleryFlowStore/mediaGalleryFlowStore';
export { default as RepositorySummaryStore } from './repositorySummaryStore/repositorySummaryStore';
export { default as ContentListFiltersStore } from './contentListFiltersStore/contentListFiltersStore';
export { default as CreateMediaGalleryStore } from './createMediaGalleryStore/createMediaGalleryStore';
export { default as UpdateMediaGalleryStore } from './updateMediaGalleryStore/updateMediaGalleryStore';
export { default as DeleteMediaGalleryStore } from './deleteMediaGalleryStore/deleteMediaGalleryStore';
export { default as SearchParamsStore } from './searchParamsStore/searchParamsStore';
export { default as RepositoryStore } from './repositoryStore/repositoryStore';
export { default as ActivityStore } from './activityStore/activityStore';
export { default as SpaceDetailStore } from './spaceDetailsStore/spaceDetailStore';
export { default as ContentDefinitionFormStore } from './contentDefinitionFormStore/contentDefinitionFormStore';
export { default as ContentTagStore } from './contentTagStore/contentTagStore';
export { default as PublishToLiveStore } from './publishToLiveStore/publishToLiveStore';
export { default as MediaTagsStore } from './mediaTagsStore/mediaTagsStore';
export { default as UsersStore } from './usersStore';
export { default as UserManagementStore } from './userManagementStore';
export { default as PaymentStore } from './paymentStore';
