import React, { Component } from 'react';
import { RepositoryOverviewProps, RepositoryOverviewState, InjectedProps } from './RepositoryOverviewModel';
import { inject, observer } from 'mobx-react';

import { LatestActivities } from '../../../components';

@inject('repositoryDashboardStore', 'activityStore')
@observer
class RepositoryOverview extends Component<RepositoryOverviewProps, RepositoryOverviewState> {
    get injected() {
        return this.props as InjectedProps;
    }

    render() {
        const { dataAttributes } = this.props;
        const { activityStore: { userLatestActivitiesByRepo, teamLatestActivitiesByRepo } } = this.injected;
        return (
            <div id="repository_detail" {...dataAttributes}>
                <LatestActivities
                    userActivity={userLatestActivitiesByRepo.items}
                    teamActivity={teamLatestActivitiesByRepo.items}
                />
            </div>
        );
    }
}

export default RepositoryOverview;
