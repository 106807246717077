import { action, observable, runInAction, IObservableArray } from 'mobx';
import { MediaType, MediaGalleryId } from '@contentchef/contentchef-types';
import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import { debounce } from '../../services/utils';
import { getFileNameAndExtension, CLOUDINARY_RAW, getFileType } from '../../services/cloudinaryUtils';
import axios from 'axios';

export class Media {
    file: File;
    @observable fileName: string = '';
    fileExt: string = '';
    fileType: MediaType;
    mediaPreview: string = '';
    @observable validMediaPublicId: boolean | null = null;
    @observable tags: IObservableArray<string> = observable.array([]);
    debouncedValidPublicId: Function;
    @observable suggestedTags: IObservableArray<string> = observable.array([]);
    @observable loader: {
        suggestedTags: boolean;
        uploading: boolean;
        validatingPublicId: boolean;
    } = {
            suggestedTags: false,
            uploading: false,
            validatingPublicId: false
        };

    @observable errors: { upload: boolean } = {
        upload: false
    };

    @observable action: { uploaded: boolean; } = {
        uploaded: false
    };

    @observable readyToUpload: boolean = false;

    constructor(
        private readonly api: ContentChefClient,
        private mediaGalleryId: MediaGalleryId,
        // all folders with rootFolder excluded
        private folderPath: string = '',
        file: File,
    ) {

        const { name, ext } = getFileNameAndExtension(file);
        this.file = file;
        this.fileType = getFileType(file.type);
        this.fileName = name;
        this.fileExt = ext;
        this.mediaPreview = window.URL.createObjectURL(file);
        this.debouncedValidPublicId = debounce(this.isValidMediaPublicId);

        this.isValidMediaPublicId(this.fileType === CLOUDINARY_RAW ? file.name : name, this.fileType);
    }

    get fileNameAndExtension() {
        return `${this.fileName}.${this.fileExt}`;
    }

    get fullPublicId() {
        return `${this.folderPath}/${this.fileName}`;
    }

    @action
    async validate() {
        this.readyToUpload = false;
        this.isValidMediaPublicId(this.fileNameAndExtension, this.fileType as MediaType);
    }

    @action
    setFileName(fileName: string) {
        this.fileName = fileName;
        this.validMediaPublicId = false;
        this.loader.validatingPublicId = true;
        if (fileName.length > 0) {
            if (getFileType(this.file.type) === CLOUDINARY_RAW) {
                this.debouncedValidPublicId(this.fileNameAndExtension, this.fileType);
            } else {
                this.debouncedValidPublicId(this.fileName, this.fileType);
            }
        }
    }

    @action
    async setSuggestedTags(partialTag: string) {
        try {
            this.loader.suggestedTags = true;
            const response = await this.api.media.listTag({
                tag: partialTag
            });
            runInAction(() => {
                this.suggestedTags.replace(response.tags);
                this.loader.suggestedTags = false;
            });
        } catch (e) {
            console.log('Error occurred trying to retrieve media tag list', e);
            runInAction(() => {
                this.loader.suggestedTags = false;
            });
        }
    }

    @action
    async setTags(tags: string[]) {
        this.tags.replace(tags);
    }

    @action
    async upload() {
        this.loader.uploading = true;
        this.errors.upload = false;

        try {
            const uploadUrlResponse = await this.getUploadUrl();
            const { uploadUrl, readUrl, writeUrl, mediaPublicId } = uploadUrlResponse;
            const result = uploadUrlResponse.largeUpload ?
                await this.largeUpload(uploadUrl, readUrl!, writeUrl!, mediaPublicId) :
                await this.uploadToCloudinary(uploadUrl, mediaPublicId);

            runInAction(() => {
                this.loader.uploading = false;
                this.errors.upload = false;
                this.action.uploaded = true;
            });

            return result;

        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loader.uploading = false;
                this.errors.upload = true;
            });
            return Promise.reject({ error, fileName: this.fileName });
        }
    }

    private async largeUpload(uploadUrl: string, readUrl: string, writeUrl: any, fullMediaPublicId: string) {
        try {
            await axios.put(writeUrl, this.file);
            return await this.uploadToCloudinary(uploadUrl, fullMediaPublicId, readUrl);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    private async uploadToCloudinary(uploadUrl: string, fullMediaPublicId: string, readUrl?: string) {
        const formData = new FormData();
        if (readUrl) {
            formData.append('file', readUrl);
        } else {
            formData.append('file', this.file);
        }

        try {
            const result = await axios.post(uploadUrl, formData);
            return { url: result.data.secure_url, mediaPublicId: fullMediaPublicId, fileName: this.fileName };
        } catch (error) {
            return Promise.reject(error);
        }
    }

    private async getUploadUrl() {
        const fileType = getFileType(this.file.type);
        const requestParams = {
            mediaType: fileType,
            mediaPublicId: this.fileName,
            mediaGalleryId: this.mediaGalleryId,
            parentFolder: this.folderPath,
            size: this.file.size,
            tags: this.tags
        };

        try {
            return await this.api.media.upload(requestParams);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    private async isValidMediaPublicId(fileName: string, mediaType: MediaType) {
        try {
            const response = await this.api.media.verify({
                mediaGalleryId: this.mediaGalleryId,
                mediaType,
                mediaPublicId: fileName,
                parentFolder: `${this.folderPath}`
            });
            runInAction(() => {
                this.validMediaPublicId = response.valid;
                this.loader.validatingPublicId = false;
                this.readyToUpload = response.valid;
            });
        } catch (e) {
            console.log('Error occurred trying to valid mediaPublicId');
            runInAction(() => {
                this.validMediaPublicId = false;
                this.loader.validatingPublicId = false;
            });
        }
    }

}
