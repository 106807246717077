import {
    BooleanSchemaType,
    Field,
    SchemaTypeIds
} from '@contentchef/contentchef-types';
import { SerializedField } from '../types';
import { ListItemIds } from '../../types';

export interface BooleanConstraints {
    required: boolean;
}

export type SerializedBooleanSchema = SerializedField<BooleanConstraints>;

export function retrieveBooleanEditingType() {
    return ListItemIds.SimpleBoolean;
}

export function deserialize(serialized: SerializedBooleanSchema): Field<BooleanSchemaType> {
    return {
        constraints: {
            required: serialized.constraints.required!,
        },
        labels: serialized.labels,
        placeholder: Object.keys(serialized.placeholder).length > 0 ? serialized.placeholder : undefined,
        hint: Object.keys(serialized.hint).length > 0 ? serialized.hint : undefined,
        name: serialized.name,
        enableFacet: serialized.enableFacet,
        type: SchemaTypeIds.BOOLEAN,
        extension: serialized.extension,
        compact: serialized.compact
    };
}

export function serialize(deserialized: Field<BooleanSchemaType>, initialIndex: number): SerializedBooleanSchema {
    return {
        constraints: {
            required: deserialized.constraints.required,
        },
        labels: deserialized.labels,
        hint: deserialized.hint || {},
        placeholder: deserialized.placeholder || {},
        name: deserialized.name,
        enableFacet: deserialized.enableFacet,
        settings: {},
        type: deserialized.type,
        editingType: retrieveBooleanEditingType(),
        initialIndex,
        extension: deserialized.extension,
        compact: deserialized.compact
    };
}
