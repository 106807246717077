import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import {
    BillingInformation,
    CancelSubscriptionRequest,
    CancelSubscriptionResponse,
    CreateSubscriptionRequest,
    CreateSubscriptionSpaceRequest,
    CreateSubscriptionSpaceResponse,
    GetBillingRequest,
    GetBillingResponse,
    GetSubscriptionSummaryRequest,
    GetSubscriptionSummaryResponse,
    GetTaxRateRequest,
    GetTaxRateResponse,
    ListSubscriptionResponse,
    SubscriptionResponse,
    UpdateBillingRequest,
    UpdateBillingResponse
} from '@contentchef/contentchef-types';
import {
    SubscriptionErrorFactory,
    SubscriptionErrorModel,
    SubscriptionErrorTypeIds
} from '@services/Error/SubscriptionErrorManager';
import { action, computed, observable, runInAction } from 'mobx';

export function isBillingInformation(value: any): value is BillingInformation {
    return (
        typeof value === 'object' &&
        'name' in value && typeof value.name === 'string' &&
        'address_line1' in value && typeof value.address_line1 === 'string' &&
        'city' in value && typeof value.city === 'string' &&
        'country' in value && typeof value.country === 'string' &&
        'state' in value && typeof value.state === 'string' &&
        'zip' in value && typeof value.zip === 'string'
    );
}

export type SubscriptionStoreErrorsModel = {
    createSubscription: SubscriptionErrorModel | undefined
};

export interface SubscriptionStoreModel {
    /**
     * @type {SubscriptionStoreErrorsModel}
     * @memberof SubscriptionStoreModel
     */
    errors: SubscriptionStoreErrorsModel;
    /**
     * @type {RegExp}
     * @memberof SubscriptionStoreModel
     */
    spaceNameRegExp: RegExp;
    /**
     * @type {(GetSubscriptionSummaryResponse | null)}
     * @memberof SubscriptionStoreModel
     */
    subscriptionSummary: GetSubscriptionSummaryResponse | null;
    subscriptionBillingInfo: Partial<BillingInformation>;
    /**
     * @type {SubscriptionResponse[]}
     * @memberof SubscriptionStoreModel
     */
    subscriptions: SubscriptionResponse[];
    customerTaxRate: GetTaxRateResponse | null;
    /**
     * @param {CreateSubscriptionRequest} subscription
     * @returns {Promise<CreateSubscriptionResponse>}
     * @memberof SubscriptionStoreModel
     */
    create(subscription: CreateSubscriptionRequest): Promise<boolean>;
    /**
     * @param {CreateSubscriptionSpaceRequest} request
     * @returns {(Promise<CreateSubscriptionSpaceResponse | null>)}
     * @memberof SubscriptionStoreModel
     */
    createSpace(request: CreateSubscriptionSpaceRequest): Promise<CreateSubscriptionSpaceResponse | null>;
    /**
     * @param {GetSubscriptionSummaryRequest} request
     * @returns {(Promise<GetSubscriptionSummaryResponse | null>)}
     * @memberof SubscriptionStoreModel
     */
    getSummary(request: GetSubscriptionSummaryRequest): Promise<GetSubscriptionSummaryResponse | null>;
    /**
     * @returns {Promise<ListSubscriptionResponse>}
     * @memberof SubscriptionStoreModel
     */
    list(): Promise<ListSubscriptionResponse>;
    /**
     * Checks if a given space name is valid
     *
     * @param {string} spaceId
     * @returns {boolean}
     * @memberof SubscriptionStoreModel
     */
    isValidSpaceName(spaceId: string): boolean;

    setSubscriptionBillingAddress(request: GetBillingRequest): Promise<GetBillingResponse | null>;

    updateSubscriptionBillingAddress(request: UpdateBillingRequest): Promise<UpdateBillingResponse>;

    setCustomerTaxRate(request: GetTaxRateRequest): Promise<GetTaxRateResponse>;

    cancelSubscription(request: CancelSubscriptionRequest): Promise<CancelSubscriptionResponse>;

    onChangePromoCode(value: string): void;
}

export interface SubscriptionStoreProps {
    api: ContentChefClient;
}

export class SubscriptionStore implements SubscriptionStoreModel {
    public api: ContentChefClient;

    public spaceNameRegExp = /^[a-z]{1,}[_a-z0-9\\s]{1,}[a-z0-9]{1,}$/gi;

    @observable
    public errors: SubscriptionStoreErrorsModel = {
        createSubscription: undefined
    };

    @observable
    public subscriptionSummary: GetSubscriptionSummaryResponse | null = null;

    @observable
    public subscriptions: SubscriptionResponse[] = [];

    @observable
    public subscriptionBillingInfo: Partial<BillingInformation> = {};

    @observable
    public customerTaxRate: GetTaxRateResponse | null = null;

    static handleApiErrors(error: any, apiName: string) {
        console.log(`Impossible to call ${apiName}`, JSON.parse(JSON.stringify(error)));
        return SubscriptionErrorFactory.crateSubscriptionError(error);
    }

    public constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action
    onChangePromoCode(value: string) {
        if (!!this.errors.createSubscription &&
            this.errors.createSubscription.type === SubscriptionErrorTypeIds.InvalidPromoCode
        ) {
            this.errors.createSubscription = undefined;
        }
    }

    /**
     * @param {CreateSubscriptionRequest} subscription
     * @returns {(Promise<boolean>)}
     * @memberof SubscriptionStore
     */
    @action
    public async create(subscription: CreateSubscriptionRequest): Promise<boolean> {
        try {
            await this.api.subscription.create(subscription);
            await this.list();
            return true;
        } catch (error) {
            this.errors.createSubscription = SubscriptionStore.handleApiErrors(error, 'create subscription');
            return false;
        }
    }

    /**
     * @param {CreateSubscriptionSpaceRequest} request
     * @returns {(Promise<CreateSubscriptionSpaceResponse | null>)}
     * @memberof SubscriptionStore
     */
    @action
    public async createSpace(request: CreateSubscriptionSpaceRequest): Promise<CreateSubscriptionSpaceResponse | null> {
        try {
            return await this.api.subscription.createSpace(request);
        } catch (error: any) {
            console.log(error.message);
            return null;
        }
    }

    /**
     * @param {GetSubscriptionSummaryRequest} request
     * @returns {(Promise<GetSubscriptionSummaryResponse | null>)}
     * @memberof SubscriptionStore
     */
    @action
    public async getSummary(request: GetSubscriptionSummaryRequest): Promise<GetSubscriptionSummaryResponse | null> {
        try {
            const response = await this.api.subscription.getSummary(request);

            runInAction(
                () => this.subscriptionSummary = response
            );

            return response;
        } catch (error: any) {
            console.log(error.message);
            return null;
        }
    }

    @action
    public async setSubscriptionBillingAddress(request: GetBillingRequest): Promise<GetBillingResponse | null> {
        try {
            const response = await this.api.subscription.getBilling(request);

            runInAction(
                () => this.subscriptionBillingInfo = response
            );

            return response;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    @action
    public async updateSubscriptionBillingAddress(request: UpdateBillingRequest): Promise<UpdateBillingResponse> {
        try {
            const response = await this.api.subscription.updateBilling(request);

            runInAction(() => {
                this.subscriptionBillingInfo = response;
            });

            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    @action
    public async setCustomerTaxRate(request: GetTaxRateRequest): Promise<GetTaxRateResponse> {
        try {
            this.customerTaxRate = null;
            const response = await this.api.subscription.getTaxRate(request);

            runInAction(() => {
                this.customerTaxRate = response;
            });

            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     * @returns
     * @memberof SubscriptionStore
     */
    @action
    public async list(): Promise<ListSubscriptionResponse> {
        try {
            const response = await this.api.subscription.list({});

            this.subscriptions = response;

            return response;
        } catch (error: any) {
            console.log(error.message);
            return [];
        }
    }

    public isValidSpaceName(spaceName: string) {
        return this.spaceNameRegExp.test(spaceName);
    }

    @action
    async cancelSubscription(request: CancelSubscriptionRequest) {
        try {
            const response = await this.api.subscription.cancel(request);
            return response;
        } catch (error: any) {
            console.log(error);
            return false;
        }
    }

    @computed
    get isSubscriptionBillingInfoEmpty() {
        return !isBillingInformation(this.subscriptionBillingInfo);
    }
}
