import { Interval } from '@contentchef/contentchef-types';
import { PaymentFlow } from './PaymentFlow';

export class RequiresPaymentMethodFlow extends PaymentFlow {
    async pay(subscriptionId: string, planId: string, interval: Interval, token?: stripe.Token) {
        const tokenToUse = token ? token.id : this.token!.id;
        try {
            return await this.paymentStore.attemptPayment(
                subscriptionId, parseInt(planId, 10), tokenToUse
            );
        } catch (error) {
            return Promise.reject(error);
        }
    }
}
