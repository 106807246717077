import { Select as AntdSelect } from 'antd';
import React from 'react';
import Typography from '../../../Typography';
import styles from './index.module.scss';

export interface SelectProps {
    className?: string;
    children?: React.ReactNode;
    options?: Array<{ label: string, value: any }>;
    value?: string;
    onChange?(value: any): any;
}

export default function Select(props: SelectProps) {
    return (
        <div className={props.className}>
            <Typography variant="label">
                {props.children}
            </Typography>
            <AntdSelect
                className={styles.SelectWrapper}
                allowClear={true}
                filterOption={true}
                onChange={props.onChange}
                value={props.value}
            >
                {
                    props.options!.map(option =>
                        <AntdSelect.Option key={option.label} value={option.value}>
                            {option.label}
                        </AntdSelect.Option>
                    )
                }
            </AntdSelect>
        </div>
    );
}

Select.defaultProps = {
    options: [],
};
