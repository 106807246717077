import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentDefinitionListProps, ContentDefinitionListState, InjectedProps } from './ContentDefinitionListModel';
import { List } from 'antd';
import { ContentDefinitionListItem } from '..';

import styles from './ContentDefinitionList.module.scss';
import { ListContentDefinitionsItem } from '@contentchef/contentchef-types';

@inject('contentDefinitionListStore')
@observer
class ContentDefinitionList extends Component<ContentDefinitionListProps, ContentDefinitionListState> {
    get injected() {
        return this.props as InjectedProps;
    }

    render() {
        const { dataAttribute, isContentCreationFlow } = this.props;
        const { contentDefinitionList } = this.injected.contentDefinitionListStore.contentDefinitionListData;
        return (
            <div {...dataAttribute().Attributes} className={styles.ContentDefinitionListContainer}>
                <List
                    loading={this.injected.isLoading}
                    itemLayout="horizontal"
                    dataSource={contentDefinitionList}
                    renderItem={(item: ListContentDefinitionsItem) => (
                        isContentCreationFlow
                            ? <ContentDefinitionListItem
                                contentDefinition={item}
                                contentCreationFlow={isContentCreationFlow}
                            />
                            : <ContentDefinitionListItem
                                contentDefinition={item}
                                contentCreationFlow={isContentCreationFlow}
                            />

                    )}
                />
            </div>
        );
    }
}

export default ContentDefinitionList;
