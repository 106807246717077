import React, { Component } from 'react';
import { Row } from 'antd';
import { inject, observer } from 'mobx-react';
import VerifyEmailCard from '../../../components/VerifyEmailCard';
import withEmailVerificationStores from '../../../hoc/withEmailVerificationStores/withEmailVerificationStores';
import { RouteComponentProps } from 'react-router';
import { UserManagementStore } from '../../../stores';

interface VerifyEmailLandingProps extends RouteComponentProps<{ emailToken: string }> { }

interface InjectedProps extends VerifyEmailLandingProps {
    userManagementStore: UserManagementStore;
}

@inject('userManagementStore')
@observer
class VerifyEmailLanding extends Component<VerifyEmailLandingProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    async componentDidMount() {
        const { match: { params: { emailToken } } } = this.props;
        await this.injected.userManagementStore.verifyEmail(emailToken);
    }
    render() {
        return (
            <Row type="flex" justify="center" align="middle" style={{ height: '100%' }}>
                <VerifyEmailCard
                    isVerifying={this.injected.userManagementStore.loaders.validatingEmail}
                    isEmailVerified={this.injected.userManagementStore.emailValid}
                />
            </Row>
        );
    }
}

export default withEmailVerificationStores(VerifyEmailLanding);
