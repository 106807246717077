import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

interface ToggleItem {
    label: string;
    value: string;
}

interface ToggleProps {
    left: ToggleItem;
    right: ToggleItem;
    selected: string;
    onChange(value: string): any;
}

export const Toggle = (props: ToggleProps) => {
    const { left, right, selected, onChange } = props;

    const onClick = (side: ToggleItem) => () => {
        onChange(side.value);
    };

    return (
        <div className={styles.ToggleContainer}>
            <div
                onClick={onClick(left)}
                className={classNames(styles.ToggleItem, styles.ToggleItemLeft, {
                    [styles.SelectedItem]: selected === left.value
                })}
            >
                <p>{left.label}</p>
            </div>
            <div
                onClick={onClick(right)}
                className={classNames(styles.ToggleItem, styles.ToggleItemRight, {
                    [styles.SelectedItem]: selected === right.value
                })}
            >
                <p>{right.label}</p>
            </div>
        </div>
    );
};
