import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import {
    ContainerWithAside,
    PublishActions,
    Aside,
    AsideItem,
    ReleaseItemList,
    PublishSummary,
} from '../../../components';
import withPublishToLiveStores from '../../../hoc/withPublishToLiveStores/withPublishToLiveStores';
import { PublishProps, InjectedProps } from './PublishModel';
import { WithLoadingData } from '../../../hoc';

@inject('publishToLiveStore', 'notificationStore')
@observer
class Publish extends Component<PublishProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    initializePublishToLive = () => {
        return this.injected.publishToLiveStore.init();
    }

    render() {
        return (
            <WithLoadingData promise={this.initializePublishToLive}>
                <ContainerWithAside
                    renderAside={() => (
                        <Aside>
                            <AsideItem header="Summary">
                                <PublishSummary />
                            </AsideItem>
                            <AsideItem>
                                <PublishActions />
                            </AsideItem>
                        </Aside>
                    )
                    }
                >
                    <ReleaseItemList />
                </ContainerWithAside>
            </WithLoadingData>
        );
    }
}

export default withPublishToLiveStores(Publish);
