import { observable, action, runInAction } from 'mobx';
import {
    ContentRepositoryId,
    GetContentRepositoryResponse,
    CreateContentRepositoryRequest,
    UpdateContentRepositoryRequest
} from '@contentchef/contentchef-types';
import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import { RepositoryStoreLoadersModel, RepositoryStoreModel } from './repositoryStoreModel';
import { routerStoreHelper } from '..';

class RepositoryStore implements RepositoryStoreModel {
    api: ContentChefClient;
    @observable repository: GetContentRepositoryResponse | undefined = undefined;
    @observable loaders: RepositoryStoreLoadersModel = {
        toggleArchive: false
    };

    constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action
    async setRepository(id: ContentRepositoryId) {
        this.repository = undefined;
        try {
            const response = await this.api.contentRepositories.get({ id });
            runInAction(() => {
                this.repository = response;
            });
        } catch (e) {
            console.log('Error while retrieving repository', e);
        }
    }

    @action
    async save(params: CreateContentRepositoryRequest) {
        const response = await this.api.contentRepositories.save(params);
        if (response) {
            routerStoreHelper.onSuccessfulRepositoryCreate(await this.api.spaceId, response.id);
        }
    }

    @action
    async update(params: UpdateContentRepositoryRequest) {
        if (this.repository) {
            await this.api.contentRepositories.update({ ...params, id: this.repository.id });
        }
    }

    @action
    async toggleArchive(archive: boolean) {
        if (this.repository) {
            this.loaders.toggleArchive = true;
            try {
                const response = await this.api.contentRepositories.toggleArchived({
                    id: this.repository.id,
                    archived: archive
                });
                runInAction(() => {
                    this.repository = response;
                    this.loaders.toggleArchive = false;
                });
                return Promise.resolve();
            } catch (e) {
                console.log(`error during { archive: ${archive} } action`, e);
                runInAction(() => {
                    this.loaders.toggleArchive = false;
                });
                return Promise.reject({});
            }
        }
    }
}

export default RepositoryStore;
