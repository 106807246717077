import React from 'react';
import AllowEmpty from '../Constraints/AllowEmpty';
import Required from '../Constraints/RequiredField';
import { FieldEditTabProps } from '../../types';
import { observer } from 'mobx-react';
import { LongTextConstraints } from '../../FieldSerializer/fields/long-text';
import MinMax from '../Constraints/MinMax';
import { FormattedMessage } from 'react-intl';
import {
    allowEmptyMappedErrors,
    getMinMaxLengthErrorList,
    requiredMappedErrors
} from '../../../../services/FormUtils/DefinitionFieldMappedErrors';

function HtmlPlainTextConstraints(props: FieldEditTabProps<LongTextConstraints>) {
    return (
        <>
            {!props.isArrayChildren && <Required
                relatedTabKey={'validationTab'}
                mappedErrors={requiredMappedErrors}
                value={props.constraints.required}
                onChange={required => props.onChangeConstraint('required', required)}
            />}
            <AllowEmpty
                relatedTabKey={'validationTab'}
                mappedErrors={allowEmptyMappedErrors}
                value={props.constraints.allowEmpty}
                onChange={allowEmpty => props.onChangeConstraint('allowEmpty', allowEmpty)}
            />
            <MinMax
                relatedTabKey={'validationTab'}
                mappedErrors={getMinMaxLengthErrorList(props.isArrayChildren)}
                valueMax={props.constraints.maxLength}
                valueMin={props.constraints.minLength}
                onChange={
                    (key, value) => props.onChangeConstraint(key === 'max' ? 'maxLength' : 'minLength', value)
                }
                attributeLabel={<FormattedMessage
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.LongText.MinMaxLength.label"
                    defaultMessage="Text length"
                />}
                minTitle={<FormattedMessage
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.LongText.MinMaxLength.min.title"
                    defaultMessage="Minimum length"
                />}
                maxTitle={<FormattedMessage
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.LongText.MinMaxLength.max.title"
                    defaultMessage="Maximum length"
                />}
                minHelper={<FormattedMessage
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.LongText.MinMaxLength.min.helper"
                    defaultMessage="Define the minimum text length"
                />}
                maxHelper={<FormattedMessage
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.LongText.MinMaxLength.max.max.helper"
                    defaultMessage="Define the maximum text length"
                />}
            />
        </>
    );
}

export default observer(HtmlPlainTextConstraints);
