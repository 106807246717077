import { EditingMode, withEditingMode } from '@providers/ContentDefinitionEditingModeContext';
import { observer } from 'mobx-react';
import React from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import {
    compactMappedErrors, indexingMappedErrors, labelsMappedErrors, translatableMappedErrors
} from '../../../../services/FormUtils/DefinitionFieldMappedErrors';
import FieldEditorStore from '../../FieldEditorStore';
import { SerializedField } from '../../FieldSerializer/types';
import { ListItemIds } from '../../types';
import CompactAttribute from '../Constraints/CompactAttribute';
import IndexingAttribute from '../Constraints/Indexing';
import TranslatableAttribute from '../Constraints/TranslatableAttribute';
import { ValidatedTranslateElement } from '../TranslateElement';
import LegacyLinkedContentsDetails from './LegacyLinkedContents';
import LinkedContentsDetails from './LinkedContents';
import ListOfItemsDetails from './ListOfItems';
import ListOfNumberValuesDetails from './ListOfNumberValues';
import ListOfTextValuesDetails from './ListOfTextValues';
import MultiCustomFieldDetails from './MultiCustomField';

export interface DefaultTabProps extends InjectedIntlProps {
    id: string;
    fieldToRenderType: ListItemIds;
    fieldEditorStore: FieldEditorStore;
    defaultLang: string;
    constraints: SerializedField['constraints'];
    isArrayChildren: boolean;
    onChangeConstraint(key: string, value: unknown): void;
}

const detailsTabLabels = defineMessages({
    labelAttrName: {
        id: 'components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.Details.TranslatedLabels',
        defaultMessage: 'Label'
    }
});

function getDetailComponent(props: DefaultTabProps) {
    switch (props.fieldToRenderType) {
        case ListItemIds.ListOfTextValues: return <ListOfTextValuesDetails {...props} />;
        case ListItemIds.ListOfNumberValues: return <ListOfNumberValuesDetails {...props} />;
        case ListItemIds.LegacyContentReference: return <LegacyLinkedContentsDetails {...props} />;
        case ListItemIds.ContentReference: return <LinkedContentsDetails {...props} />;
        case ListItemIds.MultiCustomField: return <MultiCustomFieldDetails {...props} />;
        case ListItemIds.ListOfItems: return <ListOfItemsDetails {...props} />;
        default: return null;
    }
}

function DetailsTab(props: DefaultTabProps & { mode?: EditingMode }) {
    return (
        <div id={props.id}>
            {!props.isArrayChildren && <ValidatedTranslateElement
                relatedTabKey={'detailsTab'}
                mappedErrors={labelsMappedErrors}
                segment={true}
                defaultCulture={props.defaultLang}
                items={props.fieldEditorStore.serializedField!.labels}
                label={props.intl.formatMessage(detailsTabLabels.labelAttrName)}
                onAdd={(culture) => props.fieldEditorStore.createI18n('labels', culture)}
                onChange={
                    (locale, value) => props.fieldEditorStore.updateI18n('labels', locale, value)
                }
            />}
            {(!props.fieldEditorStore.isArrayField(props.fieldEditorStore.serializedField!) &&
                !props.fieldEditorStore.isSlugField(props.fieldEditorStore.serializedField!)
            )
                && <IndexingAttribute
                    relatedTabKey={'detailsTab'}
                    mappedErrors={indexingMappedErrors}
                    value={props.fieldEditorStore.serializedField!.enableFacet}
                    type={props.fieldEditorStore.serializedField!.type}
                    onChange={props.fieldEditorStore.updateEnableFacet}
                />}
            {props.fieldEditorStore.isTranslatableField(props.fieldEditorStore.serializedField!) &&
                <TranslatableAttribute
                    relatedTabKey={'detailsTab'}
                    mappedErrors={translatableMappedErrors}
                    value={props.fieldEditorStore.serializedField.translatable}
                    type={props.fieldEditorStore.serializedField.type}
                    onChange={props.fieldEditorStore.updateTranslatableAttribute}
                />
            }
            {(props.mode !== EditingMode.custom && !props.isArrayChildren) && <CompactAttribute
                relatedTabKey={'detailsTab'}
                mappedErrors={compactMappedErrors}
                value={props.fieldEditorStore.serializedField!.compact}
                type={props.fieldEditorStore.serializedField!.type}
                onChange={props.fieldEditorStore.updateCompactAttribute}
            />}
            {getDetailComponent(props)}
        </div>
    );
}

export default withEditingMode(injectIntl(observer(DetailsTab)));
