import React from 'react';
import { FormattedMessage } from 'react-intl';
import BooleanAttribute from '../Fields/Boolean';
import
withDefinitionErrors,
{ WithDefinitionErrorsReturnProps }
    from '../../../../hoc/withDefinitionErrors/withDefinitionErrors';
import { InjectedIntlProps, injectIntl } from 'react-intl';

export interface RequiredFieldProps extends WithDefinitionErrorsReturnProps, InjectedIntlProps {
    value?: boolean;
    onChange(required: boolean): any;
}

function RequiredField(props: RequiredFieldProps) {
    return (
        <BooleanAttribute
            hasErrors={props.hasErrors}
            errors={props.errors.map(e => props.intl.formatMessage(e))}
            attributeName={<FormattedMessage
                defaultMessage="Required"
                id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.Constraint.RequiredField"
            />}
            titleTrue={<FormattedMessage id="RequiredField.attributeTitle.value.true" defaultMessage="Yes" />}
            titleFalse={<FormattedMessage id="RequiredField.attributeTitle.value.false" defaultMessage="No" />}
            helperTrue={<FormattedMessage
                id="RequiredField.attributeHelper.value.true"
                defaultMessage="Make this field mandatory"
            />}
            helperFalse={<FormattedMessage
                id="RequiredField.attributeHelper.value.false"
                defaultMessage="Make this field optional"
            />}
            onChange={value => props.onChange!(value)}
            value={props.value}
        />
    );
}

export default withDefinitionErrors(injectIntl(RequiredField));
