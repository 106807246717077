import { LocaleData } from 'react-intl';

const rootToLang = {
    'ar': 'ar_EG',
    'bg': 'bg_BG',
    'ca': 'ca_ES',
    'cs': 'cs_CZ',
    'de': 'de_DE',
    'el': 'el_GR',
    'en': 'en_US',
    'es': 'es_ES',
    'et': 'et_EE',
    'fa': 'fa_IR',
    'fi': 'fi_FI',
    'fr': 'fr_FR',
    'is': 'is_IS',
    'it': 'it_IT',
    'ja': 'ja_JP',
    'ko': 'ko_KR',
    'nb': 'nb_NO',
    'nl': 'nl_NL',
    'pl': 'pl_PL',
    'pt': 'pt_PT',
    'ru': 'ru_RU',
    'sk': 'sk_SK',
    'sr': 'sr_RS',
    'sv': 'sv_SE',
    'th': 'th_TH',
    'tr': 'tr_TR',
    'uk': 'uk_UA',
    'vi': 'vi_VN',
    'zh': 'zh_CN'
};

type I18NCacheItem = {
    locale: string;
    language: string,
    antdLocale: object;
    intlMessages: object;
    intlLocaleData: LocaleData;
};

type DynamicImport = {
    default: any;
    [key: string]: any;
};

const i18nCache: Map<string, I18NCacheItem> = new Map<string, I18NCacheItem>();

export async function resolveI18nConf(locale: string): Promise<I18NCacheItem> {
    const lang = locale.toLowerCase().split(/[_-]+/)[0] as keyof typeof rootToLang;

    async function loadLanguageItem(): Promise<I18NCacheItem> {
        try {
            const results: DynamicImport[] = await Promise.all([
                import(`../translations/${lang}.json`),
                import(`antd/lib/locale-provider/${rootToLang[lang]}.js`),
                import(`react-intl/locale-data/${lang}`)
            ]);

            return {
                locale: locale, language: lang, intlMessages: results[0].default,
                antdLocale: results[1].default, intlLocaleData: results[2].default
            } as I18NCacheItem;
        } catch (err) {
            const results = await Promise.all([
                import('../translations/en.json'),
                import('antd/lib/locale-provider/en_GB.js'),
                import('react-intl/locale-data/en')
            ] as unknown[]);

            return {
                locale: locale,
                language: lang,
                intlMessages: results[0],
                antdLocale: results[1],
                intlLocaleData: results[2]
            } as I18NCacheItem;
        }

    }

    const foundItem = i18nCache.get(lang);

    if (foundItem) {
        return foundItem;
    }

    const loadedItem = await loadLanguageItem();

    i18nCache.set(lang, loadedItem);

    return loadedItem;
}
