import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import {
    action, computed, IObservableArray, observable,
    runInAction
} from 'mobx';
import {
    UserPermission
} from '@contentchef/contentchef-types';
import { UserBySpace } from '@contentchef/contentchef-types';

export interface Space {
    spaceId: string;
    spaceName: string;
}

export default class UserPermissionsStore {
    getCurrentSpaceId: Function;
    @observable protected client: ContentChefClient;
    @observable permissionsList: IObservableArray<UserPermission> = observable.array([]);

    public constructor(clientInstance: ContentChefClient, getCurrentSpaceId: { getSpaceId: () => string | undefined }) {
        this.getCurrentSpaceId = getCurrentSpaceId.getSpaceId;
        this.client = clientInstance;
    }

    @action
    public async list(): Promise<void> {
        this.permissionsList.clear();
        try {
            const response = await this.client.userPermissions.list();
            runInAction(() => {
                this.permissionsList.replace(response);
            });
        } catch (error) {
            console.log(error);
        }
    }

    @action
    async pollUntilChange(maximumPollIterations: number = 20) {
        const length = this.permissionsList.length;
        const poll = async (currentIteration: number, resolve: <T>(value?: T) => any) => {
            await this.list();

            if (length !== this.permissionsList.length) {
                return resolve();
            }

            if (currentIteration >= maximumPollIterations) {
                return resolve();
            }

            setTimeout(poll, 2500, currentIteration + 1, resolve);
        };

        return new Promise(resolve => poll(0, resolve));
    }

    async updateUserProfile(user: UserBySpace, optionalSpaceId?: string): Promise<boolean> {
        return await this.client.userPermissions.changeSpaceProfileForUser(
            {
                profiles: user.profileIds,
                sub: user.userId,
            },
            optionalSpaceId,
        );
    }

    @computed
    get activeUserSpaces(): Space[] {
        return this.permissionsList.map(spacePermissions => ({
            spaceId: spacePermissions.spaceId,
            spaceName: spacePermissions.spaceName
        }));
    }

    activeUserSpace(): string | undefined {
        if (!this.getCurrentSpaceId()) {
            return undefined;
        }
        const space = this.permissionsList.find(spacePermissions =>
            spacePermissions.spaceId === this.getCurrentSpaceId()
        );
        return space ? space.spaceName : this.getCurrentSpaceId();
    }

    @computed
    get currentPermissions(): UserPermission['permissions'] {
        const maybePermissions = this.permissionsList.find(spacePermissions =>
            spacePermissions.spaceId === this.getCurrentSpaceId()
        );
        return maybePermissions ? maybePermissions.permissions : [];

    }
}
