import React from 'react';
import classes from './index.module.scss';
import { FormattedMessage } from 'react-intl';
import Button from '@components/Button';
import { UserInvite } from '@contentchef/contentchef-types';

export interface InviteProps {
    isAccepting: boolean;
    invite: UserInvite;
    onClick?(): void;
}

export default function Invite(props: InviteProps) {
    return (
        <div className={classes.Invite}>
            <h1 className={classes.InviteTitle}>
                <FormattedMessage
                    defaultMessage="Hi"
                    id="scenes.AcceptInvite.Invite.title"
                />
            </h1>
            <h2 className={classes.InviteSubTitle}>
                <FormattedMessage
                    defaultMessage="This is an invitation"
                    id="scenes.AcceptInvite.Invite.subTitle"
                />
            </h2>
            <p className={classes.InviteText}>
                <FormattedMessage
                    defaultMessage="Accept it and you will gain the access to the space {spaceId}"
                    id="scenes.AcceptInvite.Invite.text"
                    values={{
                        spaceId: props.invite.spaceId
                    }}
                />
            </p>
            <Button
                disabled={props.isAccepting}
                onClick={props.onClick}
                loading={props.isAccepting}
                type="primary"
            >
                <FormattedMessage
                    defaultMessage="Accept invite"
                    id="scenes.AcceptInvite.Invite.accept"
                />
            </Button>
        </div>
    );
}
