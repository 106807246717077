import MissingPermissions from '@components/MissingPermissions/MissingPermissions';
import { getTypeLabel } from '@constants/fields-constants';
import { schemaTypeArray } from '@constants/fields-schemas';
import { Extension } from '@contentchef/contentchef-types';
import withForm from '@hoc/withForm/withForm';
import { withPermissionsToInteract } from '@providers/UserPermissionsProvider';
import { debounce } from '@services/utils';
import { Form, Input, Row, Select, Switch } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import styles from './index.module.scss';


const FormItem = Form.Item;
const Option = Select.Option;

const labels = defineMessages({
    nameLabel: {
        id: 'ExtensionForm.nameLabel',
        defaultMessage: 'Name'
    },
    mnemonicIdLabel: {
        id: 'ExtensionForm.mnemonicIdLabel',
        defaultMessage: 'Mnemonic Id'
    },
    urlLabel: {
        id: 'ExtensionForm.urlLabel',
        defaultMessage: 'URL'
    },
    skipValidation: {
        id: 'ExtensionForm.validationLabel',
        defaultMessage: `Skip URL's validation`
    },
    supportedTypesLabel: {
        id: 'ExtensionForm.supportedTypesLabel',
        defaultMessage: 'Supported types'
    },
    supportedTypesPlaceholder: {
        id: 'ExtensionForm.supportedTypesPlaceholder',
        defaultMessage: 'Please select at least one field type'
    },
    errorPatternURL: {
        id: 'ExtensionForm.errorPatternURL',
        defaultMessage: 'Not a valid URL.'
    },
    errorValidationMnemonicId: {
        id: 'ExtensionForm.errorValidationMnemonicId',
        defaultMessage: 'Mnemonic Id is in use already.'
    },
    errorPatternMnemonicId: {
        id: 'ExtensionForm.errorPatternMnemonicId',
        defaultMessage: 'Mnemonic Id has invalid characters.'
    }
});

interface ExtensionFormProps extends FormComponentProps, InjectedIntlProps {
    extension?: Extension;
    mnemonicIdDisabled?: boolean;
    hasPermissions: boolean;
    validateMnemonicId?(mnemonicId: string): Promise<boolean>;
}

function ExtensionForm(props: ExtensionFormProps) {

    const isValidMnemonicId = async (rule: any, value: any, callback: any, source: any, options: any) => {
        const errors: string[] = [];
        const validMnemonicId = props.validateMnemonicId ? await props.validateMnemonicId(value) : true;

        if (!validMnemonicId) {
            errors.push(rule.message);
        }

        callback(errors);
    };

    const debouncedCallback = debounce(isValidMnemonicId);

    const trimValue: React.FocusEventHandler<HTMLInputElement> = ({ target }) => {
        const { id, value } = target;
        props.form.setFieldsValue({ [id]: value.trim() });
    };

    function removeLocalFromMnemonicId(mnemonicId: string) {
        return mnemonicId.replace('local:', '');
    }

    const { formatMessage } = props.intl;
    const { getFieldDecorator } = props.form;
    const { extension, mnemonicIdDisabled = false, hasPermissions } = props;
    if (!extension && !hasPermissions) {
        return <MissingPermissions />;
    }
    return (
        <Form>
            <Row type="flex">
                <FormItem
                    label={formatMessage(labels.nameLabel)}
                    className={styles.FormItemContainer}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    {getFieldDecorator('name', {
                        initialValue: extension ? extension.name : undefined,
                        rules: [{ required: true }]
                    })(<Input disabled={!hasPermissions} onBlur={trimValue} />)}
                </FormItem>
                <FormItem
                    label={formatMessage(labels.mnemonicIdLabel)}
                    className={styles.FormItemContainer}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    {getFieldDecorator('mnemonicId', {
                        initialValue: extension ? removeLocalFromMnemonicId(extension.mnemonicId) : undefined,
                        rules: [
                            {
                                required: true,
                                type: 'string'
                            },
                            {
                                pattern: new RegExp('^[a-z]{1,}[_a-z0-9\\s]{1,}[a-z0-9]{1,}$', 'i'),
                                message: formatMessage(labels.errorPatternMnemonicId)
                            },
                            {
                                validator: debouncedCallback,
                                message: formatMessage(labels.errorValidationMnemonicId)
                            }
                        ]
                    })(<Input
                        disabled={!hasPermissions ? !hasPermissions : mnemonicIdDisabled}
                        onBlur={trimValue}
                    />)}
                </FormItem>
                <FormItem
                    label={formatMessage(labels.urlLabel)}
                    className={styles.FormItemContainer}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    {getFieldDecorator('extensionURL', {
                        initialValue: extension ? extension.extensionURL : undefined,
                        rules: [{
                            required: true,
                        }, {
                            message: formatMessage(labels.errorPatternURL),
                            // tslint:disable-next-line: max-line-length
                            pattern: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/
                        }]
                    })(<Input disabled={!hasPermissions} type="url" onBlur={trimValue} />)}
                </FormItem>
                <FormItem
                    label={formatMessage(labels.skipValidation)}
                    className={styles.FormItemContainer}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    colon={true}
                >
                    {getFieldDecorator('skipValidation', {
                        initialValue: false,
                    })(<Switch />)}
                </FormItem>
                <Form.Item
                    label={formatMessage(labels.supportedTypesLabel)}
                    className={styles.FormItemContainer}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    {getFieldDecorator('supportedTypes', {
                        initialValue: extension ? extension.supportedTypes : undefined,
                        rules: [
                            { required: true, type: 'array' },
                        ],
                    })(
                        <Select mode="multiple" placeholder={formatMessage(labels.supportedTypesPlaceholder)}>
                            {schemaTypeArray.map(type => {
                                return <Option key={type} value={type}>{formatMessage(getTypeLabel(type as Parameters<typeof getTypeLabel>['0']))}</Option>;
                            })}
                        </Select>,
                    )}
                </Form.Item>
            </Row>
        </Form>
    );
}

export default withPermissionsToInteract(withForm(injectIntl(ExtensionForm)));
