import React from 'react';
import { Input } from 'antd';
import { SchemaTypeIds } from '@contentchef/contentchef-types';
import { DynamicFieldTypes, FieldText } from '../FormFields';
import { fieldMatchersUtils } from './FieldMatchersUtils';
import { FieldMatcher } from './FormFieldMatchersFactory';
import { defaultFieldWrapper } from './DefaultFieldWrapper';

export class SlugMatcher implements FieldMatcher {

    maybeCreateComponent(
        id: string, userLocale: string, onChange: (e: any) => void, field: DynamicFieldTypes
    ): JSX.Element | null {
        if (!fieldMatchersUtils.typeMatcher(field, SchemaTypeIds.SLUG)) { return null; }
        const fieldText = field as FieldText;
        const placeholder = fieldMatchersUtils.generateFieldPlaceholder(field, userLocale);

        function elementToRender(disabled: boolean) {
            return (
                <Input
                    id={id}
                    disabled={disabled || fieldText.disabled}
                    placeholder={placeholder}
                    value={fieldText.value}
                    onChange={onChange}
                />
            );
        }

        return defaultFieldWrapper(elementToRender);

    }
}
