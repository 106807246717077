import React, { Component } from 'react';
import { NavBreadcrumbsProps, InjectedProps } from './NavBreadcrumbsModel';
import { observer, inject } from 'mobx-react';
import { Breadcrumb, Crumb } from '..';
import { withRouter } from 'react-router';

@inject('navStore')
@observer
class NavBreadcrumbs extends Component<NavBreadcrumbsProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    render() {
        if (!this.props.match.params.spaceId) {
            return null;
        }

        const { navStore: { navigationItemsByPriority } } = this.injected;

        return (
            <Breadcrumb>
                {navigationItemsByPriority.map((item, index) => {
                    return (
                        <Crumb
                            key={`crumb-${index}`}
                            path={item.path}
                            displayValue={item.displayValue}
                        />
                    );
                })}
            </Breadcrumb>
        );
    }
}

export default withRouter(NavBreadcrumbs);
