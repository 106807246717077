import {
    GetWebhookRequest,
    ListWebhookRequest, ListWebhookResponse,
    SaveWebhookRequest, UpdateWebhookRequest, Webhook, DeleteWebhookRequest
} from '@contentchef/contentchef-types';
import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import { action, observable, runInAction } from 'mobx';
import { Space } from '../userPermissionsStore';
import { downloadTxtFile } from '@services/utils';

type WebhookStoreLoadingModel = {
    get: boolean;
    list: boolean;
    create: boolean;
    update: boolean;
    delete: boolean;
    getPublicKey: boolean;
};

type WebhooksStoreErrorsModel = {
    get?: string;
    list?: string;
    create?: string;
    update?: string;
    delete?: string;
};

export interface WebhooksStoreModel {
    webhook?: Webhook;
    webhookList: ListWebhookResponse;
    loading: WebhookStoreLoadingModel;
    error: WebhooksStoreErrorsModel;
    setWebhook(spaceId: Space['spaceId'], params: GetWebhookRequest): Promise<void>;
    setList(spaceId: Space['spaceId'], params: ListWebhookRequest): Promise<void>;
    create(spaceId: Space['spaceId'], params: SaveWebhookRequest): Promise<Webhook>;
    update(spaceId: Space['spaceId'], params: UpdateWebhookRequest): Promise<void>;
    delete(spaceId: Space['spaceId'], params: DeleteWebhookRequest): Promise<void>;
    getPublicKey(): Promise<string>;
    downloadSigningKey(): Promise<boolean>;
}

export enum WebhooksApiErrorTypes {
    validationError = 'ValidationError',
}

export default class WebhooksStore implements WebhooksStoreModel {
    protected api: ContentChefClient;
    @observable webhook?: Webhook = undefined;
    @observable webhookList: ListWebhookResponse = observable.array([]);
    @observable loading: WebhookStoreLoadingModel = {
        get: true,
        list: false,
        create: false,
        update: false,
        delete: false,
        getPublicKey: false
    };
    @observable error: WebhooksStoreErrorsModel = {
        get: undefined,
        list: undefined,
        create: undefined,
        update: undefined,
        delete: undefined,
    };

    static retrieveErrorType(error: any, apiName: string) {
        console.log(`Impossibile to call ${apiName}`, JSON.parse(JSON.stringify(error)));
        return error.type;
    }

    constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action.bound
    async setWebhook(spaceId: Space['spaceId'], params: GetWebhookRequest): Promise<void> {
        try {
            this.webhook = undefined;
            this.loading.get = true;
            const response = await this.api.webhooks.get(params, spaceId);
            runInAction(() => {
                this.webhook = response;
                this.loading.get = false;
            });
            return Promise.resolve();
        } catch (e) {
            runInAction(() => {
                this.loading.get = false;
                this.error.get = WebhooksStore.retrieveErrorType(e, 'webhooksGet');
            });
            return Promise.reject();
        }
    }

    @action.bound
    async setList(spaceId: Space['spaceId'], params: ListWebhookRequest): Promise<void> {
        try {
            this.loading.list = true;
            const response = await this.api.webhooks.list(params, spaceId);
            runInAction(() => {
                this.webhookList = response;
                this.loading.list = false;
            });
            return Promise.resolve();
        } catch (e) {
            runInAction(() => {
                this.webhookList = [];
                this.loading.list = false;
                this.error.list = WebhooksStore.retrieveErrorType(e, 'webhooksList');
            });
            return Promise.reject();
        }
    }

    @action.bound
    async create(spaceId: Space['spaceId'], params: SaveWebhookRequest): Promise<Webhook> {
        try {
            this.loading.create = true;
            const response = await this.api.webhooks.save(params, spaceId);
            runInAction(() => {
                this.loading.create = false;
            });
            return Promise.resolve(response);
        } catch (e) {
            runInAction(() => {
                this.loading.create = false;
                this.error.create = WebhooksStore.retrieveErrorType(e, 'webhookCreate');
            });
            return Promise.reject();
        }
    }

    @action.bound
    async update(spaceId: Space['spaceId'], params: UpdateWebhookRequest): Promise<void> {
        try {
            this.loading.update = true;
            const response = await this.api.webhooks.update(params, spaceId);
            await runInAction(() => {
                this.loading.update = false;
                this.webhook = response;
                return Promise.resolve();
            });
        } catch (e) {
            await runInAction(() => {
                this.loading.update = false;
                this.error.update = WebhooksStore.retrieveErrorType(e, 'webhookUpdateError');
                return Promise.reject();
            });
        }
    }

    @action.bound
    async delete(spaceId: Space['spaceId'], params: DeleteWebhookRequest): Promise<void> {
        try {
            this.loading.delete = true;
            await this.api.webhooks.delete(params, spaceId);
            runInAction(() => {
                this.loading.delete = false;
            });
            return Promise.resolve();
        } catch (e) {
            runInAction(() => {
                this.loading.delete = false;
                this.error.delete = WebhooksStore.retrieveErrorType(e, 'deleteWebhookError');
            });
            return Promise.reject();
        }
    }

    @action.bound
    async getPublicKey(): Promise<string> {
        try {
            this.loading.getPublicKey = true;
            const result = await this.api.webhooks.getPublicKey();
            runInAction(() => {
                this.loading.getPublicKey = false;
            });
            return result.key;
        } catch (e) {
            runInAction(() => {
                this.loading.getPublicKey = false;
            });
            return Promise.reject();
        }
    }

    async downloadSigningKey(): Promise<boolean> {
        try {
            const publicKey = await this.getPublicKey();
            downloadTxtFile('webhook.contentchef', publicKey);
            return true;
        } catch (error) {
            console.log(error);
            return Promise.reject();
        }
    }
}
