import Button from '@components/Button';
import { withDisabledPropertyInContentForm } from '@hoc/withDisabledPropertyInContentForm';
import { withPermissionsToInteract } from '@providers/UserPermissionsProvider';
import { Form, Popover } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { LabelWithHint } from '../../index';
import FragmentField from '../FragmentField/FragmentField';
import styles from './OneFragmentOfField.module.scss';
import { OneFragmentOfFieldProps, OneFragmentOfFieldState } from './OneFragmentOfFieldModel';
const FormItem = Form.Item;

@observer
class OneFragmentOfField extends Component<OneFragmentOfFieldProps, OneFragmentOfFieldState> {

    state: OneFragmentOfFieldState = {
        popoverVisible: false
    };

    handleCreateFragment = (name: string) => () => {
        this.props.fieldMetaData.createFragment(name);
        this.setState({ popoverVisible: false });
    }

    onCreateFragmentOfSelection = () => {
        this.setState({ popoverVisible: true });
    }

    onChangePopoverVisibility = (value: boolean) => {
        this.setState({ popoverVisible: value });
    }

    createFragmentOfSelectionButton = () => {
        const { fieldMetaData, hasPermissions, archived } = this.props;
        return (
            <Popover
                content={fieldMetaData.constraints.names.map((name, index) => {
                    return (
                        <div key={name} className={styles.OneFragmentOfFragmentTypeButtonContainer}>
                            <Button
                                className={styles.OneFragmentOfTypeButton}
                                block={true}
                                disabled={archived || !hasPermissions}
                                key={index}
                                onClick={this.handleCreateFragment(name)}
                            >
                                <div className={styles.OneFragmentOfTypeName}>{name}</div>
                            </Button>
                        </div>
                    );
                })}
                trigger="click"
                visible={archived || !hasPermissions ? false : this.state.popoverVisible}
                overlayClassName={styles.OneFragmentOfPopoverOverride}
                onVisibleChange={this.onChangePopoverVisibility}
            >
                <Button
                    type="primary"
                    icon="plus"
                    disabled={archived || !hasPermissions}
                    className={styles.OneFragmentOfCreateSelectionButton}
                    onClick={this.onCreateFragmentOfSelection}
                >
                    <FormattedMessage
                        id="OneFragmentOf.NewFragmentOfButton"
                        defaultMessage="Choose type"
                    />
                </Button>
            </Popover>
        );
    }

    render() {
        const { fieldMetaData, parentFieldId } = this.props;
        const { formatMessage, locale } = this.props.intl;
        const fieldValue = fieldMetaData.value.get();
        return (
            <FormItem
                htmlFor={fieldMetaData.id}
                required={fieldMetaData.constraints.required}
                className={styles.FragmentOfFormItem}
                label={fieldMetaData.hint
                    ? <LabelWithHint
                        label={fieldMetaData.labels[locale] || fieldMetaData.labels[fieldMetaData.locale]}
                        labelClassName={styles.OneFragmentOfSelectFragment}
                        hint={
                            fieldMetaData.hint[locale] || fieldMetaData.hint[fieldMetaData.locale]
                        }
                    />
                    : fieldMetaData.labels[locale] || fieldMetaData.labels[fieldMetaData.locale]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                help={
                    fieldMetaData.errors.length > 0
                        ? <div className={styles.OneFragmentOfErrorLabels}>
                            {fieldMetaData.getFieldErrorMessages(formatMessage, locale)}
                        </div>
                        : undefined
                }
            >
                <div
                    className={`
                        ${styles.OneFragmentOfContainer}
                        ${fieldMetaData.errors.length > 0 ? styles.OneFragmentOfErrorBorder : ''}
                    `}
                >
                    {fieldValue.name && fieldValue.fieldMeta
                        ? (
                            <FragmentField
                                parentFieldId={parentFieldId ?
                                    `${parentFieldId}.${fieldMetaData.id}`
                                    : fieldMetaData.id
                                }
                                fragmentMetaData={fieldValue.fieldMeta}
                                undoFragmentSelection={fieldMetaData.undoFragmentSelection}
                            />
                        )
                        : <div className={styles.OneFragmentOfSelectionContainer}>
                            {this.createFragmentOfSelectionButton()}
                        </div>
                    }
                </div>
            </FormItem>
        );
    }
}

export default withDisabledPropertyInContentForm(withPermissionsToInteract(injectIntl(OneFragmentOfField)));
