export const locales = [
    'af',
    'ak',
    'am',
    'ar',
    'as',
    'asa',
    'az',
    'be',
    'bem',
    'bez',
    'bg',
    'bm',
    'bn',
    'bo',
    'bs',
    'ca',
    'cgg',
    'chr',
    'cs',
    'cy',
    'da',
    'dav',
    'de',
    'ebu',
    'ee',
    'el',
    'en',
    'eo',
    'es',
    'et',
    'eu',
    'fa',
    'ff',
    'fi',
    'fil',
    'fo',
    'fr',
    'ga',
    'gl',
    'gsw',
    'gu',
    'guz',
    'gv',
    'ha',
    'haw',
    'he',
    'hi',
    'hr',
    'hu',
    'hy',
    'id',
    'ig',
    'ii',
    'is',
    'it',
    'ja',
    'jmc',
    'ka',
    'kab',
    'kam',
    'kde',
    'kea',
    'khq',
    'ki',
    'kk',
    'kl',
    'kln',
    'km',
    'kn',
    'ko',
    'kok',
    'kw',
    'lag',
    'lg',
    'lt',
    'luo',
    'luy',
    'lv',
    'mas',
    'mer',
    'mfe',
    'mg',
    'mk',
    'ml',
    'mr',
    'ms',
    'mt',
    'my',
    'naq',
    'nb',
    'nd',
    'ne',
    'nl',
    'nn',
    'nyn',
    'om',
    'or',
    'pa',
    'pl',
    'ps',
    'pt',
    'rm',
    'ro',
    'rof',
    'ru',
    'rw',
    'rwk',
    'saq',
    'seh',
    'ses',
    'sg',
    'shi',
    'si',
    'sk',
    'sl',
    'sn',
    'so',
    'sq',
    'sr',
    'sv',
    'sw',
    'ta',
    'te',
    'teo',
    'th',
    'ti',
    'to',
    'tr',
    'tzm',
    'uk',
    'ur',
    'uz',
    'vi',
    'vun',
    'xog',
    'yo',
    'zh',
    'zu'
];

export const localesDict = locales.reduce((l, k) => ({ ...l, [k]: k }), {});
