import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classes from './index.module.scss';
import { observer, inject } from 'mobx-react';
import { NotificationStoreModel } from '../../stores/notificationStore/notificationStoreModel';

type InjectedProps<T> = T & { notificationStore: NotificationStoreModel; };

function withAuthenticationErrorHandling<T>(WrappedComponent: React.ComponentType<T>) {

    @inject('notificationStore')
    @observer
    class WithAuthenticationErrorHandling extends Component<T> {
        get injected() {
            return this.props as InjectedProps<T>;
        }
        componentDidMount() {
            const searchParams = new URLSearchParams(window.location.search);
            if (searchParams.get('google_error')) {
                this.injected.notificationStore.openNotification({
                    message: (
                        <FormattedMessage
                            id="Authentication.googleError"
                            defaultMessage={`There was a problem signing-in with Google. 
                            You may already have an account with the same email. 
                            If that's not the case then try again.`}
                        />
                    ),
                    className: classes.ErrorNotificationProviderSignIn,
                    type: 'error'
                });
            }
        }

        render() {
            return (
                <WrappedComponent {...this.props} />
            );
        }
    }

    return WithAuthenticationErrorHandling;
}

export default withAuthenticationErrorHandling;
