export function isValidJsonObject(value: string): boolean {
    try {
        JSON.parse(value);
        return true;
    } catch {
        return false;
    }
}

export function isAnEmptyJsonObject(value: object): boolean {
    return Object.keys(value).length === 0;
}

export function isAValidSchemaObject(value: object): boolean {
    const schemaKeys = Object.keys(value);
    return schemaKeys.includes('lang') && schemaKeys.includes('fields') && schemaKeys.includes('fragments');
}
