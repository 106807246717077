import { Select } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { COMMON_LABELS } from '../../constants/common-internationalized-labels';
import { debounce } from '../../services/utils';
import styles from './FilterByTags.module.scss';
import { FilterByTagsProps, FilterByTagsState } from './FilterByTagsModel';
const Option = Select.Option;


@observer
class FilterByTags extends Component<FilterByTagsProps, FilterByTagsState> {
    debouncedOnSearchTags: (value?: string) => void;

    constructor(props: FilterByTagsProps) {
        super(props);
        this.debouncedOnSearchTags = debounce(this.props.onSearch);
    }

    render() {
        const { defaultValue, suggestedTags, onSearch, onChange, intl: { formatMessage } } = this.props;
        return (
            <Select
                className={styles.FilterByTagsWrapper}
                mode="tags"
                tokenSeparators={[',', ' ']}
                defaultValue={defaultValue}
                maxTagCount={2}
                onChange={onChange}
                onSearch={this.debouncedOnSearchTags}
                onFocus={onSearch}
                placeholder={formatMessage(COMMON_LABELS.tagsSelectPlaceholder)}
            >
                {suggestedTags.map((tag) => {
                    return (
                        <Option key={tag} value={tag}>
                            {tag}
                        </Option>
                    );
                })}
            </Select>
        );
    }
}

export default injectIntl(FilterByTags);
