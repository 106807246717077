import Button from '@components/Button';
import Paper from '@components/Paper';
import SettingsContentHeader from '@components/SettingsContentHeader';
import Typography from '@components/Typography';
import withEmailVerificationStores from '@hoc/withEmailVerificationStores/withEmailVerificationStores';
import UserManagementStore from '@stores/userManagementStore';
import { Checkbox, Form, Input, Modal, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Auth } from 'aws-amplify';
import { inject } from 'mobx-react';
import React, { Fragment } from 'react';
import { defineMessages, FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { withForm, withFormInitialization } from '../../../hoc';

export interface ProfileSecurityProps extends FormComponentProps, InjectedIntlProps { }

interface DecoratedProfileSecurityProps extends ProfileSecurityProps {
    userManagementStore: UserManagementStore;
}

interface ProfileSecurityState {
    confirmNewPassword: string;
    isChangingPassword: boolean;
    isRemovingUser: boolean;
    isUserDeleteConfirmChecked: boolean;
    isUserConfirmingSelfRemove: boolean;
    newPassword: string;
    oldPassword: string;
}

const labels = defineMessages({
    changePasswordMessage: {
        defaultMessage: 'Update your password',
        id: 'scenes.SettingsUsers.ProfileSecurity.message',
    },
    oldPassword: {
        defaultMessage: 'Old password',
        id: 'scenes.SettingsUsers.ProfileSecurity.input.oldPassword',
    },
    oldPasswordRequiredValidation: {
        defaultMessage: 'Old password is required',
        id: 'scenes.SettingsUsers.ProfileSecurity.validation.oldPassword.required',
    },
    newPassword: {
        defaultMessage: 'New password',
        id: 'scenes.SettingsUsers.ProfileSecurity.input.newPassword',
    },
    newPasswordRequiredValidation: {
        defaultMessage: 'New password is required',
        id: 'scenes.SettingsUsers.ProfileSecurity.validation.newPassword.required',
    },
    confirmNewPassword: {
        defaultMessage: 'Confirm new password',
        id: 'scenes.SettingsUsers.ProfileSecurity.input.confirmNewPassword',
    },
    confirmNewPasswordRequiredValidation: {
        defaultMessage: 'Confirm new password is required',
        id: 'scenes.SettingsUsers.ProfileSecurity.validation.confirmNewPassword.required',
    },
    deleteAccount: {
        id: 'scenes.Profile.ProfileSecurity.removeYourself.okText',
        defaultMessage: 'Delete my account'
    }
});

function getInitialState(): ProfileSecurityState {
    return {
        confirmNewPassword: '',
        isChangingPassword: false,
        isRemovingUser: false,
        isUserDeleteConfirmChecked: false,
        isUserConfirmingSelfRemove: false,
        newPassword: '',
        oldPassword: '',
    };
}

@inject('userManagementStore')
class ProfileSecurity extends React.Component<ProfileSecurityProps, ProfileSecurityState> {
    public get decoratedProps(): DecoratedProfileSecurityProps {
        return this.props as DecoratedProfileSecurityProps;
    }

    public state = getInitialState();

    public createChangeHandler(propkey: keyof ProfileSecurityState) {
        return (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ [propkey]: event.target.value } as any);
    }

    public handleChangePassword = () => {
        this.props.form.validateFields(async error => {
            if (error) {
                return;
            }

            const {
                oldPassword,
                newPassword,
            } = this.state;

            this.setState({ isChangingPassword: true });

            const user = await Auth.currentAuthenticatedUser();

            try {
                await Auth.changePassword(user, oldPassword, newPassword);

                notification.success({
                    message: (
                        <FormattedMessage
                            defaultMessage="Password changed"
                            id="scenes.SettingsUsers.ProfileSecurity.changeStatus.success"
                        />
                    )
                });

                this.setState(getInitialState());
            } catch (error: any) {
                notification.error({
                    message: (
                        <FormattedMessage
                            defaultMessage="{error}"
                            id="scenes.SettingsUsers.ProfileSecurity.changeStatus.error"
                            values={{ error: error.message }}
                        />
                    )
                });

                this.setState({ isChangingPassword: false });
            }
        });
    }

    public handleUserRemove = async () => {
        this.setState({ isRemovingUser: true });

        if (await this.decoratedProps.userManagementStore.removeCurrentUser()) {
            notification.success({
                message: (
                    <FormattedMessage
                        defaultMessage="Your request has been sent, you will be contacted soon"
                        id="scenes.Profile.ProfileSecurity.removeYourself.success"
                    />
                )
            });
            this.setState({
                isRemovingUser: false,
                isUserConfirmingSelfRemove: false,
                isUserDeleteConfirmChecked: false,
            });
        } else {
            notification.error({
                message: (
                    <FormattedMessage
                        defaultMessage="An error occurred while sending your request, please try again"
                        id="scenes.Profile.ProfileSecurity.removeYourself.error"
                    />
                )
            });
            this.setState({ isRemovingUser: false });
        }
    }

    public render() {
        const {
            form: {
                getFieldDecorator,
            },
            intl: {
                formatMessage,
            },
        } = this.props;
        const {
            isChangingPassword,
        } = this.state;

        const oldPasswordLabel = formatMessage(labels.oldPassword);
        const newPasswordLabel = formatMessage(labels.newPassword);
        const confirmNewPasswordLabel = formatMessage(labels.confirmNewPassword);
        const changePasswordMessage = formatMessage(labels.changePasswordMessage);
        return (
            <Fragment>
                <SettingsContentHeader
                    title={
                        <FormattedMessage
                            defaultMessage="Profile Security"
                            id="scenes.Profile.ProfileSecurity.title"
                        />
                    }
                />
                <Paper gutter={true}>
                    <Typography >
                        {changePasswordMessage}
                    </Typography>
                    <Form onSubmit={event => event.preventDefault()}>
                        <Form.Item>
                            <Typography variant="label">
                                {oldPasswordLabel}
                            </Typography>
                            {
                                getFieldDecorator(oldPasswordLabel, {
                                    initialValue: this.state.oldPassword,
                                    rules: [
                                        {
                                            message: formatMessage(labels.oldPasswordRequiredValidation),
                                            required: true,
                                        },
                                    ]
                                })(
                                    <Input
                                        onChange={this.createChangeHandler('oldPassword')}
                                        type="password"
                                    />
                                )
                            }
                        </Form.Item>
                        <Form.Item>
                            <Typography variant="label">
                                {newPasswordLabel}
                            </Typography>
                            {
                                getFieldDecorator(newPasswordLabel, {
                                    initialValue: this.state.newPassword,
                                    rules: [
                                        {
                                            message: formatMessage(labels.newPasswordRequiredValidation),
                                            required: true,
                                        },
                                    ],
                                })(
                                    <Input
                                        onChange={this.createChangeHandler('newPassword')}
                                        type="password"
                                    />
                                )
                            }
                        </Form.Item>
                        <Form.Item>
                            <Typography variant="label">
                                {confirmNewPasswordLabel}
                            </Typography>
                            {
                                getFieldDecorator(confirmNewPasswordLabel, {
                                    initialValue: this.state.confirmNewPassword,
                                    rules: [
                                        {
                                            message: formatMessage(labels.confirmNewPasswordRequiredValidation),
                                            required: true,
                                        },
                                    ]
                                })(
                                    <Input
                                        onChange={this.createChangeHandler('confirmNewPassword')}
                                        type="password"
                                    />
                                )
                            }
                        </Form.Item>
                        <Form.Item>
                            <Button
                                disabled={isChangingPassword}
                                htmlType="submit"
                                loading={isChangingPassword}
                                onClick={this.handleChangePassword}
                                type="primary"
                            >
                                <FormattedMessage
                                    defaultMessage="Update password"
                                    id="scenes.SettingsUsers.ProfileSecurity.button.label"
                                />
                            </Button>
                        </Form.Item>
                    </Form>

                </Paper>

                <SettingsContentHeader
                    gutter={true}
                    title={
                        <FormattedMessage
                            defaultMessage="Danger zone"
                            id="scenes.Profile.ProfileSecurity.dangerZone"
                        />
                    }
                />

                <Paper gutter={true}>
                    <Button onClick={() => this.setState({ isUserConfirmingSelfRemove: true })} type="danger">
                        <FormattedMessage
                            defaultMessage="Request account deletion"
                            id="scenes.Profile.ProfileSecurity.removeYourself"
                        />
                    </Button>
                </Paper>

                <Modal
                    cancelButtonProps={{
                        disabled: this.state.isRemovingUser,
                    }}
                    okButtonProps={{
                        disabled: !this.state.isUserDeleteConfirmChecked || this.state.isRemovingUser,
                        loading: this.state.isRemovingUser,
                    }}
                    onCancel={() => this.setState({ isUserConfirmingSelfRemove: false })}
                    onOk={this.handleUserRemove}
                    title={
                        <FormattedMessage
                            defaultMessage="You are about to delete your account!"
                            id="scenes.Profile.ProfileSecurity.removeYourself.confirmTitle"
                        />
                    }
                    visible={this.state.isUserConfirmingSelfRemove}
                    okText={
                        formatMessage(labels.deleteAccount)
                    }
                >
                    <Typography variant="paragraph">
                        <FormattedMessage
                            defaultMessage="Attention: This action is permanent and cannot be revoked!"
                            id="scenes.Profile.ProfileSecurity.removeYourself.confirmText1"
                        />
                    </Typography>
                    <Typography variant="paragraph">
                        <FormattedMessage
                            // tslint:disable-next-line:max-line-length
                            defaultMessage="Please note that we delete your account manually. It may take some time for the deletion to take effect."
                            id="scenes.Profile.ProfileSecurity.removeYourself.confirmText2"
                        />
                    </Typography>
                    <Typography variant="label">
                        <Checkbox
                            checked={this.state.isUserDeleteConfirmChecked}
                            onChange={event => this.setState({ isUserDeleteConfirmChecked: event.target.checked })}
                        />
                        <FormattedMessage
                            defaultMessage="I confirm that I want to delete all of my account information."
                            id="scenes.Profile.ProfileSecurity.removeYourself.confirmCheckbox"
                        />
                    </Typography>
                </Modal>

            </Fragment>
        );
    }
}

export default withFormInitialization(
    withForm(
        injectIntl(
            withEmailVerificationStores(
                ProfileSecurity
            )
        )
    )
);
