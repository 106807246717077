import React from 'react';
import { Provider, observer, inject } from 'mobx-react';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';
import { WithStoresComponentProps } from '../../constants/common-types';
import { SubscriptionStoreModel, SubscriptionStore } from '../../stores/subscriptionStore';

type InjectedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
};

export default function withSubscriptions<T>(Component: React.ComponentType<T>): React.ComponentType<T> {
    @inject('apiStore')
    @observer
    class WithSubscriptionHOC extends React.Component<T> {
        subscriptionStore: SubscriptionStoreModel;

        public constructor(props: T, context: any) {
            super(props, context);

            const {
                apiStore,
            } = props as InjectedProps<T>;

            this.subscriptionStore = new SubscriptionStore(apiStore.api);
        }

        public render() {
            return (
                <Provider
                    subscriptionStore={this.subscriptionStore}
                >
                    <Component
                        {
                        ... this.props
                        }
                    />
                </Provider>
            );
        }
    }

    return WithSubscriptionHOC;
}
