import { FieldEditTabProps } from '../../types';
import ListOfValues from '../Constraints/ListOfValues';
import React from 'react';
import { observer } from 'mobx-react';
import { NumberConstraints } from '../../FieldSerializer/fields/number';
import { listOfValuesMappedErrors } from '../../../../services/FormUtils/DefinitionFieldMappedErrors';

function ListOfNumberValuesDetails(props: FieldEditTabProps<NumberConstraints>) {
    if (!props.constraints.listOfValues) {
        return null;
    }
    return (
        <ListOfValues
            relatedTabKey={'detailsTab'}
            mappedErrors={listOfValuesMappedErrors}
            type={'number'}
            defaultLang={props.defaultLang}
            onAdd={() => props.fieldEditorStore.createI18nListOfValueItem()}
            onAddItem={(valueIndex: number, culture: string) =>
                props.fieldEditorStore.createI18nItemForListOfValue(valueIndex, culture)
            }
            onChangeItemLabel={(culture: string, valueIndex: number, label: string) =>
                props.fieldEditorStore.updateI18nItemForListOfValue(valueIndex, culture, label)
            }
            onChangeItemValue={(valueIndex: number, itemValue: number) =>
                props.fieldEditorStore.updateI18nListOfValuesValue(valueIndex, itemValue)
            }
            onRemove={valueIndex => props.fieldEditorStore.removeI18nListOfValuesValue(valueIndex)}
            value={props.constraints.listOfValues}
        />
    );
}

export default observer(ListOfNumberValuesDetails);
