import {
    ArraySchemaType,
    BooleanSchemaType,
    CloudinaryImageSchemaType,
    CloudinaryPublicIdSchemaType,
    CloudinaryRawSchemaType,
    CloudinaryVideoSchemaType,
    DateSchemaType,
    Field,
    FragmentSchemaType, FragmentsTable, LinkedContentSchemaType,
    LongTextSchemaType,
    MediaSchemaType,
    NumberSchemaType,
    OneFragmentOfSchemaType,
    OneLinkedContentOfSchemaType,
    SchemaTypeIds, SlugSchemaType, TextSchemaType
} from '@contentchef/contentchef-types';
import {
    createFragmentLabels,
    FieldArray,
    FieldBoolean, FieldCloudinaryImage,
    FieldCloudinaryPublicId, FieldCloudinaryRaw,
    FieldCloudinaryVideo, FieldDate, FieldFragment, FieldLinkedContent,
    FieldLongText, FieldNumber, FieldOneFragmentOf, FieldOneLinkedContentOf, FieldSlug,
    FieldText, SimpleField, SimpleFieldTypes, SimpleSchemaTypes
} from './FormFields';

export function FormFieldFactory(
    fragments: FragmentsTable,
    field: Field,
    definitionLocale: string,
    fromInit: boolean,
    value?: any,
    parentUniqueId?: string,
    isContentDefinitionLegacy: boolean = false
): (
        SimpleField<Field<SimpleSchemaTypes>, any> |
        FieldArray<Field<ArraySchemaType>> |
        FieldFragment | FieldOneFragmentOf |
        null
    ) {
    switch (field.type) {
        case SchemaTypeIds.TEXT:
            return new FieldText(
                field as Field<TextSchemaType>, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.LONG_TEXT:
            return new FieldLongText(
                field as Field<LongTextSchemaType>, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.NUMBER:
            return new FieldNumber(
                field as Field<NumberSchemaType>, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.BOOLEAN:
            return new FieldBoolean(
                field as Field<BooleanSchemaType>, definitionLocale, value, parentUniqueId)
                ;
        case SchemaTypeIds.DATE:
            return new FieldDate(
                field as Field<DateSchemaType>, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.LINKED_CONTENT:
            return new FieldLinkedContent(
                field as Field<LinkedContentSchemaType>, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.CLOUDINARY_PUBLICID:
            return new FieldCloudinaryPublicId(
                field as Field<CloudinaryPublicIdSchemaType>, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.MEDIA:
            return new FieldCloudinaryPublicId(
                field as Field<MediaSchemaType>, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.CLOUDINARY_IMAGE:
            return new FieldCloudinaryImage(
                field as Field<CloudinaryImageSchemaType>, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.CLOUDINARY_VIDEO:
            return new FieldCloudinaryVideo(
                field as Field<CloudinaryVideoSchemaType>, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.CLOUDINARY_RAW:
            return new FieldCloudinaryRaw(
                field as Field<CloudinaryRawSchemaType>, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.ONE_LINKED_CONTENT_OF:
            return new FieldOneLinkedContentOf(
                field as Field<OneLinkedContentOfSchemaType>, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.ARRAY:
            return new FieldArray(
                field as Field<ArraySchemaType>, definitionLocale, value, parentUniqueId, isContentDefinitionLegacy
            );
        case SchemaTypeIds.FRAGMENT:
            const fragmentNameLabels = createFragmentLabels(fragments, field.constraints.name, definitionLocale);
            return new FieldFragment(
                field as Field<FragmentSchemaType>, definitionLocale, fromInit,
                fragmentNameLabels, value, parentUniqueId, undefined, isContentDefinitionLegacy
            );
        case SchemaTypeIds.ONE_FRAGMENT_OF:
            return new FieldOneFragmentOf(
                field as Field<OneFragmentOfSchemaType>,
                definitionLocale, value, parentUniqueId, isContentDefinitionLegacy
            );
        case SchemaTypeIds.SLUG:
            return new FieldSlug(
                field as Field<SlugSchemaType>, definitionLocale, value, parentUniqueId
            );
        default:
            return null;
    }
}

export function translatableFieldFactory(
    field: Field, definitionLocale: string, value?: any, parentUniqueId?: string
): SimpleFieldTypes | null {
    switch (field.type) {
        case SchemaTypeIds.TEXT:
            return new FieldText(
                field as Field<TextSchemaType>, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.NUMBER:
            return new FieldNumber(
                field as Field<NumberSchemaType>, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.LONG_TEXT:
            return new FieldLongText(
                field as Field<LongTextSchemaType>, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.CLOUDINARY_PUBLICID:
            return new FieldCloudinaryPublicId(
                field as Field<CloudinaryPublicIdSchemaType>, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.MEDIA:
            return new FieldCloudinaryPublicId(
                field as Field<MediaSchemaType>, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.CLOUDINARY_IMAGE:
            return new FieldCloudinaryImage(
                field as Field<CloudinaryImageSchemaType>, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.CLOUDINARY_VIDEO:
            return new FieldCloudinaryVideo(
                field as Field<CloudinaryVideoSchemaType>, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.CLOUDINARY_RAW:
            return new FieldCloudinaryRaw(
                field as Field<CloudinaryRawSchemaType>, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.SLUG:
            return new FieldSlug(
                field as Field<SlugSchemaType>, definitionLocale, value, parentUniqueId
            );
        default:
            return null;
    }
}
