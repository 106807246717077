import React from 'react';
import CustomFieldEditorStore from './CustomFieldEditorStore';
import { Provider } from 'mobx-react';

export default function withCustomFieldEditorStore<T>(Component: React.ComponentType<T>) {
    class WithCustomFieldEditorStore extends React.Component<T> {
        customFieldEditorStore = new CustomFieldEditorStore();

        public render() {
            return (
                <Provider customFieldEditorStore={this.customFieldEditorStore}>
                    <Component {...this.props} />
                </Provider>
            );
        }
    }

    return WithCustomFieldEditorStore;
}
