import Button from '@components/Button';
import { CLOUDINARY_IMAGE, CLOUDINARY_RAW, CLOUDINARY_VIDEO } from '@services/cloudinaryUtils';
import { Media } from '@stores/Media';
import { Input } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { ChangeEvent } from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import { TagsSelectorWithoutPermission } from '../TagsSelector/TagsSelector';
import Typography from '../Typography';
import styles from './index.module.scss';


interface MediaToUploadProps extends InjectedIntlProps {
    media: Media;
    onRemoveMedia(media: Media): void;
}

const labels = defineMessages({
    publicIdNotUnique: {
        id: 'MediaToUpload.publicIdNotUnique',
        defaultMessage: 'Resource publicId must be unique'
    },
    publiIdLabel: {
        id: 'MediaToUpload.publicIdLabel',
        defaultMessage: 'Public Id'
    },
    tagsLabel: {
        id: 'MediaToUpload.tags',
        defaultMessage: 'Tags'
    }
});

function MediaToUpload({ media, intl, onRemoveMedia }: MediaToUploadProps) {

    const onChangeFileName = async (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        media.setFileName(value);
    };

    const onChangeTags = async (tags: string[]) => {
        media.setTags(tags);
    };

    const onSearchSuggestedTags = async (value: string) => {
        await media.setSuggestedTags(value);
    };

    const onRemove = () => {
        onRemoveMedia(media);
    };

    const openPreviewInAnotherTab = () => {
        window.open(media.mediaPreview, '_blank');
    };

    const cannotBeUploaded =
        media.validMediaPublicId !== null
        && !media.validMediaPublicId
        && !media.loader.validatingPublicId
        && !media.readyToUpload;

    const inputPublicIdClass = classNames({
        [styles.InvalidPublicId]: cannotBeUploaded
    });

    return (
        <div className={styles.MediaToUploadContainer}>
            <div
                className={styles.PreviewSection}
            >
                {media.fileType === CLOUDINARY_IMAGE && (
                    <img src={media.mediaPreview} alt={media.fileName} />
                )}
                {media.fileType === CLOUDINARY_VIDEO && (
                    <video src={media.mediaPreview} controls={true} />
                )}
                {media.fileType === CLOUDINARY_RAW && (
                    <object data={media.mediaPreview} aria-label={media.fileName} />
                )}
            </div>

            <div className={styles.InformationSection}>
                <div className={styles.CloudinaryImageUploadInputContainer}>
                    <Typography variant="label">
                        {intl.formatMessage(labels.publiIdLabel)}
                    </Typography>
                    <Input
                        id="media-name-input"
                        value={media.fileName}
                        size="small"
                        onChange={onChangeFileName}
                        addonAfter={media.fileExt}
                        className={inputPublicIdClass}
                    />
                    {
                        cannotBeUploaded &&
                        <p className={styles.PublicIdNotUnique}>
                            {intl.formatMessage(labels.publicIdNotUnique)}
                        </p>
                    }
                </div>
                <div className={styles.CloudinaryImageUploadSelectContainer}>
                    <Typography variant="label">
                        {intl.formatMessage(labels.tagsLabel)}
                    </Typography>
                    <TagsSelectorWithoutPermission
                        value={media.tags}
                        loading={media.loader.suggestedTags}
                        suggestedTags={media.suggestedTags}
                        onChange={onChangeTags}
                        onSearch={onSearchSuggestedTags}
                        hasPermissions={true}
                        size="small"
                    />
                </div>
            </div>
            <div className={styles.ActionSection}>
                <Button
                    icon="close"
                    type="danger"
                    size="large"
                    shape="circle"
                    onClick={onRemove}
                />
                <Button
                    icon="picture"
                    shape="circle"
                    type="primary"
                    size="large"
                    onClick={openPreviewInAnotherTab}
                />
            </div>
        </div>
    );
}

export default injectIntl(observer(MediaToUpload));
