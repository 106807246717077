import { SchemaFieldViolationCodes } from '@contentchef/contentchef-types';
import { defineMessages } from 'react-intl';

const definitionValidationErrorMessage = defineMessages({
    checkValuesBeforeToProceed: {
        id: 'ContentDefinitionFieldValidation.ListOfElementsWithInvalidValues',
        defaultMessage: 'your list contains invalid elements, check all values before to proceed'
    },
    unknownErrorCode: {
        id: 'ContentDefinitionFieldValidation.UnknownErrorCode',
        defaultMessage: 'errorCode is not defined. Please contact our support.'
    },
    unknownAttribute: {
        id: 'ContentDefinitionFieldValidation.UnknownAttribute',
        defaultMessage: '{attr} attribute is not permitted'
    },
    unknownFieldType: {
        id: 'ContentDefinitionFieldValidation.UnknownFieldType',
        defaultMessage: 'unrecognized type'
    },
    requiredAttributeMissing: {
        id: 'ContentDefinitionFieldValidation.RequiredAttributeMissing',
        defaultMessage: 'attribute is required'
    },
    invalidStringValue: {
        id: 'ContentDefinitionFieldValidation.InvalidStringValue',
        defaultMessage: 'value should be a string'
    },
    invalidCharactersInString: {
        id: 'ContentDefinitionFieldValidation.InvalidCharactersInString',
        defaultMessage: 'value contains invalid characters'
    },
    invalidRegExpValue: {
        id: 'ContentDefinitionFieldValidation.InvalidRegExpValue',
        defaultMessage: 'value should be a valid regular expression'
    },
    invalidStringValueInList: {
        id: 'ContentDefinitionFieldValidation.InvalidStringValueInList',
        defaultMessage: 'list contains invalid string values'
    },
    invalidDateValue: {
        id: 'ContentDefinitionFieldValidation.InvalidDateValue',
        defaultMessage: 'value should be a valid date'
    },
    invalidI18NValue: {
        id: 'ContentDefinitionFieldValidation.InvalidI18NValue',
        defaultMessage: 'value is not a valid translation'
    },
    invalidObjectValue: {
        id: 'ContentDefinitionFieldValidation.InvalidObjectValue',
        defaultMessage: 'value is not a valid object'
    },
    invalidBooleanValue: {
        id: 'ContentDefinitionFieldValidation.InvalidBooleanValue',
        defaultMessage: 'value is not a valid boolean'
    },
    invalidNumberValue: {
        id: 'ContentDefinitionFieldValidation.InvalidNumberValue',
        defaultMessage: 'value is not a valid number'
    },
    invalidNumberValueInList: {
        id: 'ContentDefinitionFieldValidation.InvalidNumberValueInList',
        defaultMessage: 'list contains invalid number values'
    },
    invalidArrayValue: {
        id: 'ContentDefinitionFieldValidation.InvalidArrayValue',
        defaultMessage: 'value is not a valid list'
    },
    invalidEmptyArray: {
        id: 'ContentDefinitionFieldValidation.InvalidEmptyArray',
        defaultMessage: 'list cannot be empty'
    },
    invalidListOfValuesValue: {
        id: 'ContentDefinitionFieldValidation.InvalidListOfValuesValue',
        defaultMessage: 'value is not a valid'
    },
    fragmentNameNotFound: {
        id: 'ContentDefinitionFieldValidation.FragmentNameNotFound',
        defaultMessage: 'custom field not found'
    },
    invalidSubType: {
        id: 'ContentDefinitionFieldValidation.InvalidSubType',
        defaultMessage: 'value is not a valid subType'
    },
    invalidType: {
        id: 'ContentDefinitionFieldValidation.InvalidType',
        defaultMessage: 'value is not a valid type'
    },
    invalidMinMax: {
        id: 'ContentDefinitionFieldValidation.InvalidMinMax',
        defaultMessage: 'min and max values are inconsistent'
    },
    badMinMaxLengths: {
        id: 'ContentDefinitionFieldValidation.BadMinMaxLengths',
        defaultMessage: 'minimum and maximum values are inconsistent'
    },
    badDefinitionIdMnemonicId: {
        id: 'ContentDefinitionFieldValidation.BadDefinitionIdMnemonicId',
        defaultMessage: 'at least definitionId or mnemonicId should be defined'
    },
    badDefinitionIdsMnemonicIds: {
        id: 'ContentDefinitionFieldValidation.BadDefinitionIdsMnemonicIds',
        defaultMessage: 'at least definitionIds or mnemonicIds should be defined'
    },
    badBeforeAfter: {
        id: 'ContentDefinitionFieldValidation.BadBeforeAfter',
        defaultMessage: 'from and to values are inconsistent'
    },
    missingDefinitionById: {
        id: 'ContentDefinitionFieldValidation.MissingDefinitionById',
        defaultMessage: 'no definition found for the given id'
    },
    missingDefinitionByIds: {
        id: 'ContentDefinitionFieldValidation.MissingDefinitionByIds',
        defaultMessage: 'no definitions found for the given ids.'
    },
    missingDefinitionByMnemonicId: {
        id: 'ContentDefinitionFieldValidation.MissingDefinitionByMnemonicId',
        defaultMessage: 'no definition found for the given mnemonic id'
    },
    missingDefinitionByMnemonicIds: {
        id: 'ContentDefinitionFieldValidation.MissingDefinitionByMnemonicIds',
        defaultMessage: 'no definition found fo the given mnemonic ids'
    },
    missingDefaultLabel: {
        id: 'ContentDefinitionFieldValidation.MissingDefaultLabel',
        defaultMessage: 'default language label missing'
    },
    enableFacetNotPermitted: {
        id: 'ContentDefinitionFieldValidation.EnableFacetNotPermitted',
        defaultMessage: 'field is not indexable'
    },
    translatableNotAllowed: {
        id: 'ContentDefinitionFieldValidation.translatableNotAllowed',
        defaultMessage: 'field can not be translatable'
    },
    labelValueInvalid: {
        id: 'ContentDefinitionFieldValidation.LabelValueInvalid',
        defaultMessage: 'invalid label value'
    },
    extensionNotFound: {
        id: 'ContentDefinitionFieldValidation.ExtensionNotFound',
        defaultMessage: 'Extension not available in your space'
    },
    extensionNotSupportedForType: {
        id: 'ContentDefinitionFieldValidation.ExtensionNotSupportedForType',
        defaultMessage: 'Extension not supported for current field type'
    },
    patternNotMatched: {
        id: 'ContentDefinitionFieldValidation.StringNotMatchingPattern',
        defaultMessage: 'field does not match the pattern'
    },
    compactCannotBeNested: {
        id: 'ContentDefinitionFieldValidation.compactCannotBeNested',
        defaultMessage: 'Field cannot be compact if in an array or custom field'
    }
});

export const getErrorByErrorCode = (errorCode: string) => {
    switch (errorCode) {
        case 'unknownAttribute':
            return definitionValidationErrorMessage.unknownAttribute;
        case 'unknownFieldType':
            return definitionValidationErrorMessage.unknownFieldType;
        case 'requiredAttributeMissing':
            return definitionValidationErrorMessage.requiredAttributeMissing;
        case 'invalidStringValue':
            return definitionValidationErrorMessage.invalidStringValue;
        case 'invalidRegExpValue':
            return definitionValidationErrorMessage.invalidRegExpValue;
        case 'invalidStringValueInList':
            return definitionValidationErrorMessage.invalidStringValueInList;
        case 'invalidCharactersInString':
            return definitionValidationErrorMessage.invalidCharactersInString;
        case 'invalidDateValue':
            return definitionValidationErrorMessage.invalidDateValue;
        case 'invalidI18NValue':
            return definitionValidationErrorMessage.invalidI18NValue;
        case 'invalidObjectValue':
            return definitionValidationErrorMessage.invalidObjectValue;
        case 'invalidBooleanValue':
            return definitionValidationErrorMessage.invalidBooleanValue;
        case 'invalidNumberValue':
            return definitionValidationErrorMessage.invalidNumberValue;
        case 'invalidNumberValueInList':
            return definitionValidationErrorMessage.invalidNumberValueInList;
        case 'invalidArrayValue':
            return definitionValidationErrorMessage.invalidArrayValue;
        case 'invalidEmptyArray':
            return definitionValidationErrorMessage.invalidEmptyArray;
        case 'invalidListOfValuesValue':
            return definitionValidationErrorMessage.invalidListOfValuesValue;
        case 'fragmentNameNotFound':
            return definitionValidationErrorMessage.fragmentNameNotFound;
        case 'invalidSubType':
            return definitionValidationErrorMessage.invalidSubType;
        case 'invalidType':
            return definitionValidationErrorMessage.invalidType;
        case 'invalidMinMax':
            return definitionValidationErrorMessage.invalidMinMax;
        case 'badMinMaxLengths':
            return definitionValidationErrorMessage.badMinMaxLengths;
        case 'badDefinitionIdMnemonicId':
            return definitionValidationErrorMessage.badDefinitionIdMnemonicId;
        case 'badDefinitionIdsMnemonicIds':
            return definitionValidationErrorMessage.badDefinitionIdsMnemonicIds;
        case 'badBeforeAfter':
            return definitionValidationErrorMessage.badBeforeAfter;
        case 'missingDefinitionById':
            return definitionValidationErrorMessage.missingDefinitionById;
        case 'missingDefinitionByIds':
            return definitionValidationErrorMessage.missingDefinitionByIds;
        case 'missingDefinitionByMnemonicId':
            return definitionValidationErrorMessage.missingDefinitionByMnemonicId;
        case 'missingDefinitionByMnemonicIds':
            return definitionValidationErrorMessage.missingDefinitionByMnemonicIds;
        case 'missingDefaultLabel':
            return definitionValidationErrorMessage.missingDefaultLabel;
        case 'enableFacetNotPermitted':
            return definitionValidationErrorMessage.enableFacetNotPermitted;
        case 'labelValueInvalid':
            return definitionValidationErrorMessage.labelValueInvalid;
        case 'checkValuesBeforeToProceed':
            return definitionValidationErrorMessage.checkValuesBeforeToProceed;
        case 'patternNotMatched':
            return definitionValidationErrorMessage.patternNotMatched;
        case SchemaFieldViolationCodes.extensionNotFound:
            return definitionValidationErrorMessage.extensionNotFound;
        case SchemaFieldViolationCodes.extensionNotSupportedForType:
            return definitionValidationErrorMessage.extensionNotSupportedForType;
        case SchemaFieldViolationCodes.translatableNotAllowed:
            return definitionValidationErrorMessage.translatableNotAllowed;
        case SchemaFieldViolationCodes.compactCannotBeNested:
            return definitionValidationErrorMessage.compactCannotBeNested;
        default:
            return definitionValidationErrorMessage.unknownErrorCode;
    }
};
