import React from 'react';

export const WebhookSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024" stroke="currentColor" strokeWidth="30">
        {/* tslint:disable-next-line:max-line-length */}
        <path d="M782.336 792.064c33.28 0 59.904-26.624 59.904-59.904s-26.624-59.904-59.904-59.904c-24.064 0-44.544 13.824-53.76 34.304H394.24c-14.336 0-25.6 11.264-25.6 25.6 0 69.632-56.832 126.464-126.464 126.464S115.2 801.792 115.2 732.16s56.832-126.464 126.464-126.464c14.336 0 25.6-11.264 25.6-25.6s-11.264-25.6-25.6-25.6c-98.304 0-177.664 79.872-177.664 177.664s79.872 177.664 177.664 177.664c89.6 0 163.328-66.048 176.128-152.064h310.784c9.216 19.968 29.696 34.304 53.76 34.304z" />
        {/* tslint:disable-next-line:max-line-length */}
        <path d="M398.336 428.544l-150.016 244.224c-2.048 0-4.096-0.512-6.656-0.512-33.28 0-59.904 26.624-59.904 59.904s26.624 59.904 59.904 59.904 59.904-26.624 59.904-59.904c0-12.288-3.584-23.04-9.728-32.768l162.816-265.216c7.168-12.288 3.584-27.648-8.192-35.328-1.536-1.024-3.584-2.048-5.632-2.56-34.816-23.04-55.296-62.464-55.296-104.448 0-69.632 56.832-126.464 126.464-126.464s126.464 56.832 126.464 126.464c0 15.872-3.072 31.232-8.192 45.568-5.12 13.312 1.536 28.16 14.848 33.28s28.16-1.536 33.28-14.848c7.68-20.48 11.776-41.984 11.776-64 0-98.304-79.872-177.664-177.664-177.664S334.336 194.048 334.336 291.84c0 53.248 23.552 102.912 64 136.704z" p-id="1881" />
        {/* tslint:disable-next-line:max-line-length */}
        <path d="M782.336 553.984c-24.576 0-48.128 5.12-70.144 14.336l-150.016-244.224c6.144-9.216 9.728-20.48 9.728-32.768 0-33.28-26.624-59.904-59.904-59.904-33.28 0-59.904 26.624-59.904 59.904 0 33.28 26.624 59.904 59.904 59.904 2.048 0 4.096 0 6.656-0.512l162.304 264.192c1.024 1.536 1.536 2.56 2.56 3.584 0 0.512 0.512 0.512 0.512 0.512 1.024 1.024 2.048 2.048 3.584 3.072 1.024 1.024 2.56 1.536 3.584 2.048 0.512 0 0.512 0 1.024 0.512 1.536 0.512 2.56 1.024 4.096 1.536h0.512c1.536 0.512 2.56 0.512 4.096 0.512H702.464c1.024 0 2.56 0 3.584-0.512 1.536 0 3.072-0.512 4.096-1.024 0.512 0 0.512 0 1.024-0.512 1.536-0.512 3.072-1.024 4.096-2.048 19.968-12.288 42.496-18.432 66.048-18.432 69.632 0 126.464 56.832 126.464 126.464s-56.832 126.464-126.464 126.464c-41.984 0-81.408-20.992-104.96-55.808-7.68-11.776-24.064-14.848-35.328-6.656-11.776 7.68-14.848 24.064-6.656 35.328 33.28 49.152 88.064 78.336 147.456 78.336 98.304 0 177.664-79.872 177.664-177.664s-79.36-176.64-177.152-176.64z" />
    </svg>
);
