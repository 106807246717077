import React from 'react';
import { Switch, Route } from 'react-router';
import { SUBSCRIPTION_UPGRADE_PLAN_PATH } from '../../../../constants/routing-constants';
import SubscriptionUpgrade from '../SubscriptionUpgrade';

interface SubscriptionRouterProps { }

const SubscriptionRouter = (props: SubscriptionRouterProps) => {
    return (
        <Switch>
            <Route
                path={SUBSCRIPTION_UPGRADE_PLAN_PATH}
                component={SubscriptionUpgrade}
            />
        </Switch>
    );
};

export default SubscriptionRouter;
