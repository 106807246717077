import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { SceneFullHeaderProps } from './SceneFullHeaderModel';

import styles from './SceneFullHeader.module.scss';

class SceneFullHeader extends Component<SceneFullHeaderProps> {

    render() {
        const {
            dataAttributes,
            name,
            color,
            summary,
            links
        } = this.props;
        const style = {
            background: color,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top center'
        };

        return (
            <div {...dataAttributes().Attributes}>
                <div
                    style={style}
                    className={styles.sceneDashboardHeader}
                >
                    <h2 className={styles.headerTitle}>{name}</h2>
                    <ul className={styles.headerLinks}>
                        {links.map((link, index) => (
                            <li key={index} className={styles.headerLinksElement}>
                                <Link data-link={link.label} to={link.to}>{link.label}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
                {!!summary && summary}
            </div>
        );
    }
}

export default SceneFullHeader;
