import { MediaAdapter } from '@services/Media/MediaConverterClass';
import { List, Modal } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import { Button } from '..';
import MediaDetails from '../MediaDetails/MediaDetails';
import styles from './index.module.scss';

const labels = defineMessages({
    deselect: {
        id: 'MediaListModal.deselect',
        defaultMessage: 'Remove from selection',
    }
});

interface Props extends InjectedIntlProps {
    visible: boolean;
    modalTitle: string;
    onCancel: () => any;
    onConfirm: () => any;
    listLayout?: 'vertical' | 'horizontal';
    onRestoreItem: (media: MediaAdapter) => any;
    list: MediaAdapter[];
}

function MediaListModal({
    visible, modalTitle, onCancel, onConfirm, listLayout = 'vertical', onRestoreItem, intl,
    list }: Props) {

    function onRestoreItemFromList(mediaToKeep: MediaAdapter) {
        return () => onRestoreItem(mediaToKeep);
    }

    return (
        <Modal
            title={modalTitle}
            centered={true}
            visible={visible}
            className={styles.MediaListModal}
            onCancel={onCancel}
            destroyOnClose={true}
            onOk={onConfirm}
        >
            <List
                itemLayout={listLayout}
                dataSource={list}
                bordered={true}
                style={{ overflow: 'auto', maxHeight: '60vh' }}
                renderItem={item => (
                    <List.Item
                        key={`${item.data.publicId}-${item.data.resourceType}`}
                        actions={[
                            <Button
                                icon="rollback"
                                key="remove-from-delete"
                                onClick={onRestoreItemFromList(item)}
                                type="primary"
                            >
                                {intl.formatMessage(labels.deselect)}
                            </Button>
                        ]}
                        extra={
                            <img
                                style={{ maxWidth: '200px', maxHeight: '200px' }}
                                alt="logo"
                                src={item.data.thumbnailUrl}
                            />
                        }
                    >
                        <MediaDetails
                            media={item}
                        />
                    </List.Item>
                )}
            />
        </Modal>
    );
}

export default injectIntl(observer(MediaListModal));
