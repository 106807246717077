import { ContentDefinitionResponse, ContentInfo, ContentResponse, Field } from '@contentchef/contentchef-types';
import { action, observable, runInAction } from 'mobx';
import {
    FieldChannels,
    FieldDynamic,
    FieldName,
    FieldOnlineRange,
    FieldPublicId, FieldRepository,
    FieldTags
} from '../../services/FormUtils/FormFields';
import { validationErrorMessage } from '../../services/FormUtils/FormValidationErrorMessages';
import {
    ChangedFieldsModel,
    CommonFieldsValue,
    FieldViolationModel,
    FormMetaModel,
    FormMetaStoreModel,
    GenericErrorModel,
    InitialCommonValuesModel
} from './formMetaStoreModel';

class FormMetaStore implements FormMetaStoreModel {
    @observable formMeta: FormMetaModel = {} as FormMetaModel;
    @observable genericErrors: GenericErrorModel = {
        contentDefinitionNotFound: undefined
    };
    @observable changedFields: ChangedFieldsModel[] = [];
    contentInfo: ContentInfo | null = null;
    inTranslation: boolean = false;
    isContentDefinitionLegacy: boolean = false;

    static isUnknownContentDefinitionError(errors: FieldViolationModel[]) {
        return errors[0].field === 'ContentDefinitionId';
    }

    @action
    initializeStore(
        contentDef: ContentDefinitionResponse,
        contentData?: ContentResponse,
        inTranslation: boolean = false
    ) {
        this.formMeta = {} as FormMetaModel;
        this.genericErrors = { contentDefinitionNotFound: undefined };
        this.inTranslation = inTranslation;

        this.changedFields = [];
        const contentDefSchema = contentDef.schema;
        this.isContentDefinitionLegacy = !Array.isArray(contentDef.trackableArrayFields)
            || (Array.isArray(contentDef.trackableArrayFields)
                && !!contentData && ((contentData as any)['fieldsToUnbox'] as any[]).length === 0);
        this.formMeta.language = contentDefSchema.lang;
        this.formMeta.fragments = contentDefSchema.fragments;
        this.contentInfo = {
            contentDefinitionId: contentDef.id,
            contentDefinitionMnemonicId: contentDef.mnemonicId,
        };

        if (contentData) {
            const initialCommonValue = {
                name: contentData.name,
                repository: contentData.repository.id,
                publicId: contentData.publicId,
                onlineRange: { onlineDate: contentData.onlineDate as any as string, offlineDate: contentData.offlineDate as any as string },
                tags: contentData.tags,
                channels: contentData.channels
            };
            this.contentInfo.repositoryId = contentData.repository.id;
            this.contentInfo.contentId = contentData.id;
            this.contentInfo.contentPublicId = contentData.publicId;
            this.createCommonFieldsModel(initialCommonValue, inTranslation);
            this.createDynamicFieldsModel(
                contentDefSchema.fields, contentDefSchema.lang, contentData.payload);
        } else {
            this.createCommonFieldsModel({} as InitialCommonValuesModel, inTranslation);
            this.createDynamicFieldsModel(contentDefSchema.fields, contentDefSchema.lang, undefined);
        }
    }

    @action
    addChangedFields(fieldToAdd: string) {
        if (!this.changedFields.find((field) => field.fieldUniqueId === fieldToAdd)) {
            this.changedFields.push({ fieldUniqueId: fieldToAdd });
        }
    }

    @action
    removeChangedFields(fieldToRemove: string) {
        this.changedFields = this.changedFields.filter((field) => field.fieldUniqueId !== fieldToRemove);
    }

    getCommonFieldsValue(): CommonFieldsValue {
        const { name, repository, publicId, onlineRange, tags, channels } = this.formMeta;
        return {
            name: name.value as string,
            repository: repository.value as number,
            publicId: publicId.value,
            ...onlineRange.getValues() as any,
            tags: tags.value.toJS() as string[],
            channels: channels.value.map(channel => channel.id)
        };
    }

    getDynamicFieldValues(): object {
        const { dynamicFields } = this.formMeta;
        return dynamicFields.getSubmittablePayload();
    }

    getFormValue(): object {
        return {
            ...this.getCommonFieldsValue(),
            payload: this.getDynamicFieldValues()
        };
    }

    @action
    setValidationErrors(errors: FieldViolationModel[]) {
        runInAction(() => {
            if (FormMetaStore.isUnknownContentDefinitionError(errors)) {
                this.genericErrors.contentDefinitionNotFound =
                    validationErrorMessage['generic.contentDefinitionNotFound'];
            } else {
                this.formMeta.dynamicFields.setFieldsValidationErrors(errors);
                this.genericErrors.contentDefinitionNotFound = undefined;
            }
        });
    }

    @action
    unsetGenericErrors() {
        this.genericErrors.contentDefinitionNotFound = undefined;
    }

    @action
    setClientSideErrors(errors: any) {
        this.formMeta.dynamicFields.setClientSideErrors(errors);
    }

    @action
    setFieldPathValue(fieldPath: string, value: any) {
        this.formMeta.dynamicFields.setFieldPathValue(fieldPath, value);
    }

    @action
    private createCommonFieldsModel(initialCommonValues: InitialCommonValuesModel, inTranslation: boolean = false) {
        this.formMeta.name = new FieldName(initialCommonValues.name, inTranslation);
        this.formMeta.repository = new FieldRepository(initialCommonValues.repository, inTranslation);
        this.formMeta.publicId = new FieldPublicId(initialCommonValues.publicId, inTranslation);
        this.formMeta.onlineRange = new FieldOnlineRange(initialCommonValues.onlineRange, inTranslation);
        this.formMeta.tags = new FieldTags(initialCommonValues.tags, inTranslation);
        this.formMeta.channels = new FieldChannels(initialCommonValues.channels, inTranslation);
    }

    @action
    private createDynamicFieldsModel(
        schemaFields: Field[], definitionLocale: string, payloadData?: object
    ) {
        this.formMeta.dynamicFields = new FieldDynamic(
            this.formMeta.fragments, schemaFields,
            definitionLocale, payloadData, this.isContentDefinitionLegacy);
    }
}

const formMetaStore = new FormMetaStore();
export default formMetaStore;
