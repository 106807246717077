import {
    CloudinaryPublicIdSchemaType,
    Field,
    SchemaTypeIds
} from '@contentchef/contentchef-types';

function imageReference(defaultLanguage: string, name: string): Field<CloudinaryPublicIdSchemaType> {
    return {
        name,
        type: SchemaTypeIds.CLOUDINARY_PUBLICID,
        labels: {
            [defaultLanguage]: name
        },
        placeholder: undefined,
        hint: undefined,
        constraints: {
            required: false,
            type: 'image'
        },
        compact: undefined
    };
}

function imageReferenceInArray(): CloudinaryPublicIdSchemaType {
    return {
        type: SchemaTypeIds.CLOUDINARY_PUBLICID,
        translatable: undefined,
        constraints: {
            type: 'image'
        }
    };
}

function videoReference(defaultLanguage: string, name: string): Field<CloudinaryPublicIdSchemaType> {
    return {
        name,
        type: SchemaTypeIds.CLOUDINARY_PUBLICID,
        labels: {
            [defaultLanguage]: name
        },
        placeholder: undefined,
        hint: undefined,
        constraints: {
            required: false,
            type: 'video'
        },
        compact: undefined
    };
}

function videoReferenceInArray(): CloudinaryPublicIdSchemaType {
    return {
        type: SchemaTypeIds.CLOUDINARY_PUBLICID,
        translatable: undefined,
        constraints: {
            type: 'video'
        }
    };
}

function rawReference(defaultLanguage: string, name: string): Field<CloudinaryPublicIdSchemaType> {
    return {
        name,
        type: SchemaTypeIds.CLOUDINARY_PUBLICID,
        labels: {
            [defaultLanguage]: name
        },
        placeholder: undefined,
        hint: undefined,
        constraints: {
            required: false,
            type: 'raw'
        },
        compact: undefined
    };
}

function rawReferenceInArray(): CloudinaryPublicIdSchemaType {
    return {
        type: SchemaTypeIds.CLOUDINARY_PUBLICID,
        translatable: undefined,
        constraints: {
            type: 'raw'
        }
    };
}

export const cloudinaryPublicIdSnippets = {
    asField: {
        imageReference,
        videoReference,
        rawReference
    },
    asArray: {
        imageReferenceInArray,
        videoReferenceInArray,
        rawReferenceInArray
    }
};
