import {
    Field, MediaSchemaType, SchemaTypeIds
} from '@contentchef/contentchef-types';

function imageWithMetadata(defaultLanguage: string, name: string): Field<MediaSchemaType> {
    return {
        name,
        type: SchemaTypeIds.MEDIA,
        labels: {
            [defaultLanguage]: name
        },
        placeholder: undefined,
        hint: undefined,
        constraints: {
            required: false,
            type: 'image'
        },
        compact: undefined
    };
}

function imageWithMetadataInArray(): MediaSchemaType {
    return {
        type: SchemaTypeIds.MEDIA,
        translatable: undefined,
        constraints: {
            type: 'image'
        }
    };
}

function videoWithMetadata(defaultLanguage: string, name: string): Field<MediaSchemaType> {
    return {
        name,
        type: SchemaTypeIds.MEDIA,
        labels: {
            [defaultLanguage]: name
        },
        placeholder: undefined,
        hint: undefined,
        constraints: {
            required: false,
            type: 'video'
        },
        compact: undefined
    };
}

function videoWithMetadataInArray(): MediaSchemaType {
    return {
        type: SchemaTypeIds.MEDIA,
        translatable: undefined,
        constraints: {
            type: 'video'
        }
    };
}

function rawWithMetadata(defaultLanguage: string, name: string): Field<MediaSchemaType> {
    return {
        name,
        type: SchemaTypeIds.MEDIA,
        labels: {
            [defaultLanguage]: name
        },
        placeholder: undefined,
        hint: undefined,
        constraints: {
            required: false,
            type: 'raw'
        },
        compact: undefined
    };
}

function rawWithMetadataInArray(): MediaSchemaType {
    return {
        type: SchemaTypeIds.MEDIA,
        translatable: undefined,
        constraints: {
            type: 'raw'
        }
    };
}

export const mediaSnippets = {
    asField: {
        imageWithMetadata,
        videoWithMetadata,
        rawWithMetadata
    },
    asArray: {
        imageWithMetadataInArray,
        videoWithMetadataInArray,
        rawWithMetadataInArray
    }
};
