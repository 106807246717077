import { Form, Input, Row } from 'antd';
import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { MissingPermissions } from '../';
import { withForm } from '../../hoc';
import { withPermissionsToInteract } from '../../providers/UserPermissionsProvider';
import { debounce } from '../../services/utils';
import styles from './PublishingChannelForm.module.scss';
import { PublishingChannelFormProps } from './PublishingChannelFormModel';


const FormItem = Form.Item;

const publishingChannelFormLabel = defineMessages({
    nameLabel: {
        id: 'PublishingChannelForm.nameLabel',
        defaultMessage: 'Name'
    },
    descriptionLabel: {
        id: 'PublishingChannelForm.descriptionLabel',
        defaultMessage: 'Mnemonic Id'
    },
    colorLabel: {
        id: 'PublishingChannelForm.colorLabel',
        defaultMessage: 'Color'
    },
    errorValidationMnemonicId: {
        id: 'PublishingChannelForm.errorValidationMnemonicId',
        defaultMessage: 'Mnemonic Id is in use already.'
    },
    errorPatternMnemonicId: {
        id: 'PublishingChannelForm.errorPatternMnemonicId',
        defaultMessage: 'Mnemonic Id has invalid characters.'
    }
});

class PublishingChannelForm extends Component<PublishingChannelFormProps> {
    randomHexColor: string;
    debouncedValidateMnemonicId: (rule: any, value: any, callback: any, source: any, options: any) => void;

    constructor(props: PublishingChannelFormProps) {
        super(props);

        this.randomHexColor = '#000000'.replace(/0/g, function() {
            // tslint:disable-next-line
            return (~~(Math.random() * 16)).toString(16);
        });

        this.debouncedValidateMnemonicId = debounce(this.validateMnemonicId, 200);
    }

    validateMnemonicId = async (rule: any, value: any, callback: any, source: any, options: any) => {
        const errors: string[] = [];
        const { validateMnemonicId } = this.props;

        const validMnemonicId = validateMnemonicId ? await validateMnemonicId(value) : true;

        if (!validMnemonicId) {
            errors.push(rule.message);
        }

        callback(errors);
    }

    trimValue: React.FocusEventHandler<HTMLInputElement> = ({ target }) => {
        const { id, value } = target;
        this.props.form.setFieldsValue({ [id]: value.trim() });
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { getFieldDecorator } = this.props.form;
        const { publishingChannel, mnemonicIdDisabled, hasPermissions } = this.props;
        if (!publishingChannel && !hasPermissions) {
            return <MissingPermissions />;
        }
        return (
            <Form>
                <Row type="flex">
                    <FormItem
                        label={formatMessage(publishingChannelFormLabel.nameLabel)}
                        className={styles.UpdatePublishingChannelFormItemContainer}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {getFieldDecorator('name', {
                            initialValue: publishingChannel ? publishingChannel.name : undefined,
                            rules: [{ required: true }]
                        })(<Input disabled={!hasPermissions} onBlur={this.trimValue} />)}
                    </FormItem>
                    <FormItem
                        label={formatMessage(publishingChannelFormLabel.descriptionLabel)}
                        className={styles.UpdatePublishingChannelFormItemContainer}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {getFieldDecorator('mnemonicId', {
                            initialValue: publishingChannel ? publishingChannel.mnemonicId : undefined,
                            rules: [
                                {
                                    required: true,
                                    type: 'string',
                                    max: 11
                                },
                                {
                                    pattern: /^[A-Za-z0-9_-]+$/,
                                    message: formatMessage(publishingChannelFormLabel.errorPatternMnemonicId)
                                },
                                {
                                    validator: this.debouncedValidateMnemonicId,
                                    message: formatMessage(publishingChannelFormLabel.errorValidationMnemonicId)
                                }
                            ]
                        })(<Input
                            disabled={!hasPermissions ? !hasPermissions : mnemonicIdDisabled}
                            onBlur={this.trimValue}
                        />)}
                    </FormItem>
                    <FormItem
                        label={formatMessage(publishingChannelFormLabel.colorLabel)}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {getFieldDecorator('color', {
                            initialValue: publishingChannel ? publishingChannel.color : this.randomHexColor,
                            rules: [
                                { required: true }
                            ]
                        })(<Input type="color" disabled={!hasPermissions} />)}
                    </FormItem>
                </Row>
            </Form>
        );
    }
}

export default withPermissionsToInteract(withForm(injectIntl(PublishingChannelForm)));
