import { observable, IObservableArray, action, computed } from 'mobx';

export interface NotificationIcon {
    type: string;
    className?: string;
}

export class Notification {
    constructor(
        public identifier: string,
        public label: React.ReactNode,
        public icon?: NotificationIcon,
        public cta?: string,
        public removable?: boolean
    ) { }
}

export class NotificationHubStore {
    @observable notifications: IObservableArray<Notification> = observable.array([]);
    @observable open: boolean = false;

    createNotification(
        identifier: string,
        label: React.ReactNode,
        icon?: NotificationIcon,
        cta?: string,
        removable?: boolean
    ): Notification {
        return new Notification(
            identifier,
            label,
            icon,
            cta,
            removable
        );
    }

    @action
    addNotification(notificationToAdd: Notification) {
        if (!this.notifications.find(notification => notificationToAdd.identifier === notification.identifier)) {
            this.notifications.push(notificationToAdd);
        }
    }

    @action
    toggleHub() {
        this.open = !this.open;
    }

    @action
    openHub() {
        this.open = true;
    }

    @action
    closeHub() {
        this.open = false;
    }

    @action
    removeNotification(identifier: string) {
        this.notifications.replace(this.notifications.filter(notification => notification.identifier !== identifier));
    }

    @action
    clearNotifications() {
        this.notifications.clear();
    }

    @computed
    get firstFiveNotifications() {
        return this.notifications.slice(0, 5);
    }
}

const notificationHubStore = new NotificationHubStore();

export default notificationHubStore;
