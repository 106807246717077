import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import {
    ListContentsItem
} from '@contentchef/contentchef-types';
import { IObservableArray } from 'mobx';
import { SearchParamsStoreModel } from '../searchParamsStore/searchParamsStoreModel';

export interface ContentListStoreModel {
    api: ContentChefClient;
    error: ErrorModel;
    linkableContents: ContentsListLinkedModel;
    linkedContents: ContentsListLinkedModel;
    contentListData: ContentListDataModel;
    searchParams: SearchParamsStoreModel;
    initContentList(): void;
    setContentsByRepo(id: string): void;
    setLinkableContents(filters: LinkableContentsFilters, skip?: number, take?: number): void;
    setLinkedContents(filters: LinkedContentsFilters, skip?: number, take?: number): void;
}

export enum ContentListUrlParams {
    byPage = 'page',
    bySize = 'size',
    byNameAndPublicId = 'search',
    repositoryId = 'repository_id',
    definitionId = 'definition_id',
    tags = 'tags',
    contentStatus = 'contentStatus',
    translation = 'translation'
}

export interface LinkedContentsFilters {
    contentDefinitionIds?: number[];
    definitionMnemonicIds?: string[];
    publicIds: string[];
}

export interface LinkableContentsFilters {
    name?: string;
    contentDefinitionIds?: number[];
    definitionMnemonicIds?: string[];
    publicIds?: string[];
    tags?: string[];
}

export interface ContentListDataModel {
    contentsByRepo: IObservableArray<ListContentsItem>;
    total: number;
}

export interface ContentsListLinkedModel {
    items: IObservableArray<ListContentsItem>;
    total: number;
}

interface ErrorModel {
    contentsForRepo: string;
    count: string;
    linkedContentList: string;
    linkableContentsList: string;
}
