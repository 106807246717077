import React, { Component } from 'react';
import { FolderCrumbProps, InjectedProps } from './FolderCrumbModel';
import { inject, observer } from 'mobx-react';

import styles from './FolderCrumb.module.scss';
import { withMediaNavigation, MediaNavigationContext } from '../../providers/MediaNavigationProvider';

@inject('mediaGalleryFlowStore')
@observer
class FolderCrumb extends Component<FolderCrumbProps & MediaNavigationContext> {
    get injected() {
        return this.props as InjectedProps;
    }

    onClick = async () => {
        const { folderPath } = this.injected.mediaGalleryFlowStore;
        const { folderDepth } = this.props;
        const splittedValue = folderPath.split('/', folderDepth + 1);
        if (this.props.onClickToFolder) {
            this.props.onClickToFolder(splittedValue.join('/'));
        }
        await this.injected.mediaGalleryFlowStore.reinitializeMediaAndFolders(splittedValue.join('/'));
    }

    render() {
        const { folderName } = this.props;
        return (
            <span className={styles.FolderCrumbFolderName} onClick={this.onClick}>
                {folderName}
            </span>
        );
    }
}

export default withMediaNavigation(FolderCrumb);
