import React from 'react';
import AccessTokenStore from '../../stores/accessTokenStore';
import { inject, observer } from 'mobx-react';
import { Popconfirm } from 'antd';
import { FormattedMessage } from 'react-intl';
import Button from '@components/Button';

interface AccessTokenCardExtraButtonProps {
    tokenHash: string;
}

interface AccessTokenCardExtraButtonInjected extends AccessTokenCardExtraButtonProps {
    accessTokenStore: AccessTokenStore;
}

const AccessTokenCardExtraButton = inject('accessTokenStore')
    (observer((props: AccessTokenCardExtraButtonProps) => {
        const injected = props as AccessTokenCardExtraButtonInjected;
        return (
            <Popconfirm
                title={
                    <FormattedMessage
                        id="AccessTokenCardExtraButton.RevokeConfirm"
                        defaultMessage="Revoke token?"
                    />
                }
                placement="left"
                onConfirm={
                    () => injected.accessTokenStore.revokeAccessToken(props.tokenHash)
                }
                onCancel={(e: any) => { e.stopPropagation(); }}
            >
                <Button
                    loading={injected.accessTokenStore.loaders.revokeAccessTokenLoading[props.tokenHash]}
                    type="primary"
                    icon="delete"
                />
            </Popconfirm>
        );
    }));

export default AccessTokenCardExtraButton;
