import logoSVG from '@assets/images/ContentChefMuseo.svg';
import Button from '@components/Button';
import { ROOT_ROUTE } from '@constants/routing-constants';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import styles from './NoSpacesFound.module.scss';

export interface NoSpacesFoundProps extends RouteComponentProps<{}> { }

class NoSpacesFound extends Component<NoSpacesFoundProps, {}> {

    onClick = () => {
        this.props.history.push(ROOT_ROUTE);
    }

    render() {
        return (
            <div className={styles.NoSpacesFound}>
                <div className={styles.NoSpacesFoundCard}>
                    <img src={logoSVG} className={styles.NoSpacesFoundLogo} alt='contentchef-logo' />
                    <p className={styles.NoSpacesFoundText}>
                        <FormattedMessage
                            id="NoSpacesFound.Text1"
                            defaultMessage={'At the moment you are not enabled to access to any space, ' +
                                'contact your space administrator or register at'
                            }
                        />

                    </p>
                    <br />
                    <p className={styles.NoSpacesFoundText}>
                        <a>https://www.contentchef.io</a>
                    </p>
                    <br />
                    <p className={styles.NoSpacesFoundText}>
                        <FormattedMessage
                            id="NoSpacesFound.Text2"
                            defaultMessage={'if you need help please drop an email to ' +
                                'our support team at support@contentchef.io'}
                        />
                    </p>

                    <Button
                        type="primary"
                        className={styles.NoSpacesFoundButton}
                        onClick={this.onClick}
                    >
                        <FormattedMessage
                            id="NoSpacesFound.Button"
                            defaultMessage={'Try to reload'}
                        />
                    </Button>
                </div>
            </div>
        );
    }
}

export default withRouter(NoSpacesFound);
