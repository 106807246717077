import React from 'react';
import classes from './index.module.scss';
import classNames from 'classnames';
import Button from '@components/Button';

export interface RoundedCardProps {
    buttonLabel?: React.ReactNode;
    children?: React.ReactNode;
    imageSrc?: string;
    overheadTitle?: string;
    className?: string;
    style?: React.CSSProperties;
    title?: string;
    onClick?(): any;
}

export default function RoundedCard(props: RoundedCardProps) {

    const roundedCardClassName = classNames(classes.RoundedCard, props.className);

    return (
        <div
            className={roundedCardClassName}
            style={props.style}
        >
            {
                props.imageSrc &&
                <div className={classes.RoundedCardImageContainer}>
                    <img src={props.imageSrc} alt={props.title} />
                </div>
            }
            <div className={classes.RoundedCardContentContainer}>
                {
                    props.overheadTitle &&
                    <div className={classes.RoundedCardOverheadTitle}>
                        {props.overheadTitle}
                    </div>
                }
                {
                    props.title &&
                    <h2 className={classes.RoundedCardTitle}>
                        {props.title}
                    </h2>
                }
                {props.children}
            </div>
            {
                props.onClick &&
                <div className={classes.RoundedCardActions}>
                    <Button
                        className={classes.RoundedCardButton}
                        onClick={props.onClick}
                        type="primary"
                    >
                        {
                            props.buttonLabel
                        }
                    </Button>
                </div>
            }
        </div>
    );
}
