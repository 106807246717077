export interface UserInfo {
    email_verified: boolean;
    name: string;
    username: string;
    email: string;
}

export type DecodedPayload = {
    email_verified?: boolean;
    name: string;
    username: string;
    email: string;
};

export function toUserInfo(decodedPayload: DecodedPayload): UserInfo {

    return {
        email: decodedPayload.email,
        email_verified:
            typeof decodedPayload.email_verified !== 'undefined' ? decodedPayload.email_verified : true,
        name: decodedPayload.name,
        username: decodedPayload.username
    };
}
