export interface ContentDefinitionValidationError {
    message: string;
    type: string;
    validation: object[];
}

export enum ContentDefinitionErrorTypes {
    FailedDefinitionFieldValidation = 'FailedDefinitionFieldValidation',
    FailedDefinitionFragmentValidation = 'FailedDefinitionFragmentValidation',
    FailedSchemaValidation = 'FailedSchemaValidation',
    UnexpectedDefinitionError = 'UnexpectedDefinitionError'
}
export class ContentDefinitionError {
    type: ContentDefinitionErrorTypes;
    violations: any;

    constructor(type: ContentDefinitionErrorTypes, violations: any) {
        this.type = type;
        this.violations = violations;
    }

    retrieveValidationErrors = () => {
        return this.violations;
    }
}

export class ContentDefinitionErrorFactory {
    static createContentDefinitionError(error: ContentDefinitionValidationError) {
        switch (error.type) {
            case ContentDefinitionErrorTypes.FailedDefinitionFieldValidation:
                return new ContentDefinitionError(
                    error.type,
                    error.validation
                );
            case ContentDefinitionErrorTypes.FailedDefinitionFragmentValidation:
                return new ContentDefinitionError(
                    error.type,
                    error.validation
                );
            case ContentDefinitionErrorTypes.FailedSchemaValidation:
                return new ContentDefinitionError(
                    error.type,
                    error.validation
                );
            default:
                return new ContentDefinitionError(
                    ContentDefinitionErrorTypes.UnexpectedDefinitionError,
                    []
                );
        }
    }
}
